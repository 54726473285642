import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    fbGraph = environment.facebook.graph + environment.facebook.version + '/me/messenger_profile';

    constructor(private http: HttpClient) { }

    getMessage(token) {
        const url = `${this.fbGraph}?fields=greeting&access_token=${token}`;
        return this.http.get(url);
    }

    setMessage(token: any, message: any) {
        const url = `${this.fbGraph}?access_token=${token}`
        return this.http.post(url, message);
    }

    setDomains(obj: any, token: string) {
        const url = `${this.fbGraph}?access_token=${token}`;
        return this.http.post(url, obj);
    }

    getDomains(token: string) {
        return this.http.get(`${this.fbGraph}?fields=whitelisted_domains&access_token=${token}`);
    }

    getButtonStart(token: string) {
        return this.http.get(`${this.fbGraph}?fields=get_started&access_token=${token}`);
    }

    setButtonStart(obj: any, token: string) {
        return this.http.post(`${this.fbGraph}?access_token=${token}`, obj);
    }

    getPersistentMenu(token: string) {
        return this.http.get(`${this.fbGraph}?fields=persistent_menu&access_token=${token}`);
    }

    setPersistentMenu(obj: any, token: string) {
        return this.http.post(`${this.fbGraph}?access_token=${token}`, obj);
    }


    deleteProperties(token,fields){
       /*  return this.http.delete(`${this.fbGraph}?access_token=${token}`,field); */

        return this.http.request('DELETE', `${this.fbGraph}?access_token=${token}`, {

            body: fields
        });

    }

}
