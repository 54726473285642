<a (click)="toggleMenu()" class="platform-link">
    <i class="material-icons">layers</i>
    <span>Plataformas</span>
</a>
<ul class="menu-platforms">
    <li *ngFor="let platform of platforms" [ngClass]="{'active': platform?.active}">
        <button (click)="changePlatform(platform)">
            <span>{{platform.channel.label}}</span>
            <img [src]="assets + 'img/platforms/' + platform.channel.name + '.svg'">
        </button>
    </li>
    <li>
        <button (click)="openModal('popup-add-platform')">
            <span>{{translate.sections.platforms.addPlatform}}</span>
            <i class="material-icons">add_circle</i>
        </button>
    </li>
</ul>

<!-- Start modal -->
<jw-modal id="popup-add-platform">
    <a class="close_modal" (click)="closeModal('popup-add-platform');">×</a>
    <h2 class="title">{{translate.sections.platforms.addPlatform}}</h2>
    <h3 class="description">{{translate.sections.platforms.selectPlatform}}</h3>
    <div class="divPopupPlatform">
        <form [formGroup]="platformsForm" novalidate>

            <div class="input">
                <ng-select
                [searchable]="false"
                [clearable]="false"
                [loading]="loadingPlatforms"
                [hideSelected]="true"
                loadingText="Cargando..."
                notFoundText="No hay elementos"
                formControlName="platform"
                labelForId="platform"
                class="select-platforms"
                name="platformFormName" ngDefaultControl>
                    <ng-option [value]="platform" *ngFor="let platform of platformsCatalog">
                        <img [src]="assets + 'img/platforms/' + platform.name + '.svg'" width="30px" height="30px">
                        <span [ngStyle]="{'color': '#333'}">{{platform.label}}</span>
                    </ng-option>
                </ng-select>
            </div>
        </form>
        <button class="btn-primary" (click)="addplatform()">{{translate.sections.platforms.Add}}</button>
    </div>
</jw-modal>
<!-- End modal -->
