import { Injectable } from '@angular/core';
import { AlertService } from '../alert.service';
import { InteractiveMessages, Section, Row } from '../../models/interactive-message';
import * as joint from "../../../vendor/rappid";
declare var translate: any;
@Injectable({
    providedIn: 'root'
})
export class ListMessageService {
    translate: any;
    private alertService: AlertService;
    private field: any;
    private fieldName: string;
    private listMessage: InteractiveMessages;
    showOutputs: boolean;

    constructor(alert) {
        this.alertService = alert;
        this.listMessage = new InteractiveMessages('list', this.alertService);
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.showOutputs = true;
    }

    getIndexById(list, id) {
        let idx = -1;
        const listArr = list.children();
        listArr.each(i => {
            const currentId = $(listArr[i]).data('id');
            if (id === currentId) {
                idx = i;
            }
        });
        return idx;
    }

    saveOnField() {
        const val = JSON.stringify({
            listMessage: this.listMessage.getJson(),
            type: 'listMessage',
            showOutputs: this.showOutputs
        });
        const field = $('[name="' + this.fieldName + '"]');
        field.val(val);
        this.field.val(val);
    }

    addOption(rowList, sectionIdx, optionIdx) {
        let row: Row = {
            id: {
                id: joint.util.uuid()
            },
            title: {
                title: ''
            },
            description: {
                description: ''
            }
        };

        if (optionIdx < this.listMessage.action.sections[sectionIdx].rows.length) {
            if (this.listMessage.action.sections[sectionIdx].rows[optionIdx]) {
                row = this.listMessage.action.sections[sectionIdx].rows[optionIdx];
            }
        } else {
            this.listMessage.action.sections[sectionIdx].rows.push(row);
        }
        const bodyListItem = $(`<li data-id="${row.id.id}" />`);
        const itemTitleInput = $(`<input class="title-item" type="text" placeholder="` + translate.sections.services.writeTitle + `"  maxlength="${this.listMessage.rowTitleMaxLen}" />`);
        const itemDescriptionInput = $(`<input class="desc-item" type="text" placeholder="` + translate.sections.services.writeDescription + `"  maxlength="${this.listMessage.rowDescriptionMaxLen}" />`);
        const deleteButton = $('<button class="delete-item">✕ <span></span>' + translate.sections.services.option + '</button>');

        itemTitleInput.val(row.title.title);
        if (row.hasOwnProperty('description')) {
            itemDescriptionInput.val(row.description.description);
            this.disableFixed(row.description, itemDescriptionInput);
        }
        this.disableFixed(row.title, itemTitleInput);
        this.disableFixed(row.title, deleteButton);

        deleteButton.on('click', (e) => {
            const dataId = $(e.delegateTarget).parent().data('id');
            let idx = this.getIndexById(rowList, dataId);
            if (this.listMessage.action.sections[sectionIdx].rows.length > this.listMessage.actionSectionsMinLen) {
                this.listMessage.action.sections[sectionIdx].rows.splice(idx, 1);
                bodyListItem.remove();
            } else {
                this.alertService.warning(`Las opciones tienen un mínimo de ${this.listMessage.actionSectionsMinLen} elemento(s)`);
            }
            this.saveOnField();
        });

        itemTitleInput.on('focusout', (e) => {
            const dataId = $(e.delegateTarget).parent().data('id');
            let idx = this.getIndexById(rowList, dataId);
            row.title.title = itemTitleInput.val().toString();
            this.listMessage.action.sections[sectionIdx].rows[idx] = row;
            this.saveOnField();
        });
        itemDescriptionInput.on('focusout', (e) => {
            const dataId = $(e.delegateTarget).parent().data('id');
            let idx = this.getIndexById(rowList, dataId);
            row['description'] = {
                description: itemDescriptionInput.val().toString()
            };
            this.listMessage.action.sections[sectionIdx].rows[idx] = row;
            this.saveOnField();
        });

        bodyListItem.append([itemTitleInput, itemDescriptionInput, deleteButton]);
        rowList.append(bodyListItem);
    }

    addSection(sectionList, sectionIdx) {
        let section: Section = {
            // id: joint.util.uuid(),
            title: {
                title: ''
            },
            rows: []
        };

        if (sectionIdx < this.listMessage.action.sections.length) {
            // section.id = this.listMessage.action.sections[sectionIdx].id;
            if (this.listMessage.action.sections[sectionIdx]) {
                section = this.listMessage.action.sections[sectionIdx];
            }
        } else {
            this.listMessage.action.sections.push(section);
        }

        const bodyListItem = $(`<li data-id="${joint.util.uuid()}" />`);
        // const bodyListItem = $(`<li/>`);
        const sectionTitleInput = $(`<input class="title-section" type="text" placeholder="` + translate.sections.services.titleSection + `"  maxlength="${this.listMessage.sectionTitleMaxLen}" />`);
        const deleteButton = $('<button class="delete-item">✕ <span>' + translate.sections.services.section + '</span></button>');
        const optionButton = $('<button class="add-option"><i class="material-icons" title="' + translate.sections.services.addOption + '">add</i> <span>' + translate.sections.services.option + '</span></button>');
        const rowList = $('<ul class="list-options" />');

        deleteButton.on('click', (e) => {
            const dataId = $(e.delegateTarget).parent().data('id');
            const idx = this.getIndexById(sectionList, dataId);
            if (this.listMessage.action.sections.length > this.listMessage.actionSectionsMinLen) {
                this.listMessage.action.sections.splice(idx, 1);
                bodyListItem.remove();
            } else {
                this.alertService.warning(`Las secciones tienen un mínimo de ${this.listMessage.actionSectionsMinLen} elemento(s)`);
            }
            this.saveOnField();
        });

        sectionTitleInput.on('focusout', (e) => {
            const dataId = $(e.delegateTarget).parent().data('id');
            const idx = this.getIndexById(sectionList, dataId);
            this.listMessage.action.sections[idx] = {
                // id: this.listMessage.action.sections[idx].id,
                title: {
                    title: sectionTitleInput.val().toString()
                },
                rows: this.listMessage.action.sections[idx].rows
            }
            this.saveOnField();
        });

        optionButton.on('click', (e) => {
            const dataId = $(e.delegateTarget).parent().data('id');
            const sectionIndex = this.getIndexById(sectionList, dataId);
            const optionIndex = this.listMessage.action.sections[sectionIndex].rows.length;
            this.addOption(rowList, sectionIndex, optionIndex);
            this.saveOnField();
        });

        if (section.hasOwnProperty('title') && section.title) {
            sectionTitleInput.val(section.title.title);
        }

        if (this.listMessage.action.sections[sectionIdx].hasOwnProperty('title') && this.listMessage.action.sections[sectionIdx].title) {
            this.disableFixed(this.listMessage.action.sections[sectionIdx].title, sectionTitleInput);
            this.disableFixed(this.listMessage.action.sections[sectionIdx].title, deleteButton);
        }

        if (this.listMessage.action.sections[sectionIdx].rows.length > 0) {
            this.listMessage.action.sections[sectionIdx].rows.forEach((option, index) => {
                this.addOption(rowList, sectionIdx, index);
            });
        } else {
            const optionIdx = this.listMessage.action.sections[sectionIdx].rows.length;
            this.addOption(rowList, sectionIdx, optionIdx);
        }

        bodyListItem.append([sectionTitleInput, deleteButton, rowList, optionButton]);
        sectionList.append(bodyListItem);
    }

    convertContraintsToValue(constraints) {
        const contraintsAux = {
            listMessage: {
                type: 'list',
                header: {
                    type: {
                        type: constraints.listMessage.header.type.type,
                        constraintType: constraints.listMessage.header.type.constraintType
                    },
                    text: {
                        text: constraints.listMessage.header.text.text,
                        constraintType: constraints.listMessage.header.text.constraintType
                    }
                },
                body: {
                    text: constraints.listMessage.body.text,
                    constraintType: constraints.listMessage.body.constraintType
                },
                action: {
                    button: {
                        button: constraints.listMessage.action.button.button,
                        constraintType: constraints.listMessage.action.button.constraintType
                    },
                    sections: []
                }
            }
        };

        if (contraintsAux.listMessage.hasOwnProperty('footer')) {
            contraintsAux.listMessage['footer'] = {
                text: constraints.listMessage.footer.text,
                constraintType: constraints.listMessage.footer.constraintType
            };
        }

        constraints.listMessage.action.sections.forEach((section, index1) => {
            contraintsAux.listMessage.action.sections.push({
                title: constraints.listMessage.action.sections[index1].title,
                rows: []
            });
            section.rows.forEach((row, index2) => {
                const rowAux = {
                    // id: joint.util.uuid(),
                    title: constraints.listMessage.action.sections[index1].rows[index2].title
                };
                if (constraints.listMessage.action.sections[index1].rows[index2].hasOwnProperty('id')) {
                    rowAux['id'] = constraints.listMessage.action.sections[index1].rows[index2].id.id;
                } else {
                    rowAux['id'] = joint.util.uuid();
                }
                if (constraints.listMessage.action.sections[index1].rows[index2].hasOwnProperty('description')) {
                    rowAux['description'] = constraints.listMessage.action.sections[index1].rows[index2].description;
                }

                contraintsAux.listMessage.action.sections[index1].rows.push(rowAux);
            });
        });

        return contraintsAux;
    }

    fillValue(val, messgeHeaderInput, messgeBodyInput, messgeFooterInput, messageButtonInput, listHeaderSpan, listSectionList) {
        const lm: InteractiveMessages = val.listMessage as InteractiveMessages;
        this.listMessage.action = lm.action;
        this.listMessage.header = lm.header;
        this.listMessage.body = lm.body;
        this.listMessage.footer = lm.footer;
        this.saveOnField();
        // this.listMessage = {...this.listMessage, ...lm };
        if (this.listMessage.header && this.listMessage.header.text.text) {
            messgeHeaderInput.val(this.listMessage.header.text.text);
        }
        messgeBodyInput.val(this.listMessage.body.text);
        if (this.listMessage.footer && this.listMessage.footer.text) {
            messgeFooterInput.val(this.listMessage.footer.text);
        }
        messageButtonInput.val(this.listMessage.action.button.button);
        listHeaderSpan.text(this.listMessage.action.button.button);

        if (this.listMessage.action.sections.length > 0) {
            this.listMessage.action.sections.forEach((section, index) => {
                this.addSection(listSectionList, index);
            });
        } else {
            const idx = this.listMessage.action.sections.length;
            this.addSection(listSectionList, idx);
        }
    }

    /**
     * Deshabilita las entradas del elemento seleccionado cuando existe el subatributo constraintType y
     * tiene un valor fixed
     * @param listMessageAttr attributo de listMessage
     * @param element un elemento input o button del dom
     */
    disableFixed(listMessageAttr, element) {
        if (listMessageAttr.hasOwnProperty('constraintType') && listMessageAttr.constraintType === 'fixed') {
            element.prop('disabled', true);
        }
    }

    getListMessage(field, constraints) {
        this.fieldName = field.attr('name');
        // FIXME: inicia prueba
        // constraints = {
        //     "listMessage": {
        //         "header": {
        //             "type": {
        //                 "type": "text",
        //                 "constraintType": "fixed"
        //             },
        //             "text": {
        //                 "text": "msg title"
        //             }
        //         },
        //         "body": {
        //             "text": "msg body",
        //             "constraintType": "fixed"
        //         },
        //         "footer": {
        //             "text": "msg footer",
        //             "constraintType": "fixed"
        //         },
        //         "action": {
        //             "button": {
        //                 "button": "msg btn",
        //                 "constraintType": "fixed"
        //             },
        //             "sections": [
        //                 {
        //                     "rows": [
        //                         {
        //                             "title": {
        //                                 "title": "option title"
        //                             }
        //                         }
        //                     ]
        //                 }
        //             ]
        //         }
        //     }
        // };
        // termina prueba
        const containerDiv = $('<div class="type_visual_box list-message" />');
        const messageDiv = $('<div class="message" />');
        const messgeHeaderInput = $(`<input type="text" class="message-header" placeholder="` + translate.sections.services.writeTitleMessage + `" maxlength="${this.listMessage.headerTextMaxLen}" />`);
        const messgeBodyInput = $(`<input type="text" class="message-body" placeholder="` + translate.sections.services.writeBodyMessage + `" maxlength="${this.listMessage.bodyTextMaxLen}" />`);
        const messgeFooterInput = $(`<input type="text" class="message-footer" placeholder="` + translate.sections.services.writeFooterMessage + `" maxlength="${this.listMessage.footerTextMaxLen}" />`);
        const messageHourSpan = $('<span class="message-hour">12:00</span>');
        const messageButtonInput = $(`<input type="text" class="options-button" placeholder="` + translate.sections.services.writeTextButton + `"  maxlength="${this.listMessage.buttonTitleMAxLen}" />`);
        const listDiv = $('<div class="list" />');
        const listHeaderDiv = $('<div class="list-header" />');
        const listHeaderSpan = $(`<span class="title-header">` + translate.sections.services.buttonText + `</span>`);
        const listBodyDiv = $('<div class="list-body" />');
        const listSectionButton = $('<button class="add-section"><i class="material-icons" title="' + translate.sections.services.addSection + '">add</i><span>' + translate.sections.services.section + '</span></button>');
        const listSectionList = $('<ul class="list-sections" />');
        const selectedOptionSpan = $(`<span class="title-selected-option">` + translate.sections.services.textOptionSelected + `</span>`);
        const sendButton = $(`<button class="send-button"><i class="material-icons">` + translate.sections.services.send + `</i></button>`);

        this.listMessage.action = {
            button: {
                button: ' '
            },
            sections: []
        };

        messgeHeaderInput.on('focusout', (e) => {
            const text = messgeHeaderInput.val().toString();
            if (text) {
                this.listMessage.header = {
                    type: {
                        type: 'text'
                    },
                    text: {
                        text
                    }
                };
            } else {
                this.listMessage.header = null;
                delete this.listMessage.header;
            }
            this.saveOnField();
        });

        messgeBodyInput.on('focusout', (e) => {
            const text = messgeBodyInput.val().toString();
            this.listMessage.body = { text };
            this.saveOnField();
        });

        messgeFooterInput.on('focusout', (e) => {
            const text = messgeFooterInput.val().toString();
            if (text) {
                this.listMessage.footer = { text: messgeFooterInput.val().toString() };
            } else {
                delete this.listMessage.footer;
            }
            this.saveOnField();
        });

        messageButtonInput.on('focusout', (e) => {
            this.listMessage.action = {
                button: {
                    button: messageButtonInput.val().toString()
                },
                sections: this.listMessage.action.sections
            }
            this.saveOnField();
        });

        messageButtonInput.on('keyup', (e) => {
            const text = messageButtonInput.val().toString();
            listHeaderSpan.text(text);
        });

        listSectionButton.on('click', (e) => {
            if (this.listMessage.action.sections.length < this.listMessage.actionSectionsMaxLen) {
                const idx = this.listMessage.action.sections.length;
                this.addSection(listSectionList, idx);
            } else {
                this.alertService.warning(`Las secciones tienen un máximo de ${this.listMessage.actionSectionsMaxLen} elementos`);
            }
        });

        if (constraints.hasOwnProperty('listMessage')) {
            this.disableFixed(constraints.listMessage.header.text, messgeHeaderInput);
            this.disableFixed(constraints.listMessage.body, messgeBodyInput);
            if (constraints.listMessage.hasOwnProperty('footer') && constraints.listMessage.footer) {
                this.disableFixed(constraints.listMessage.footer, messgeFooterInput);
            }
            this.disableFixed(constraints.listMessage.action.button, messageButtonInput);
        }

        this.field = field;

        const value = field.val();

        if (constraints && 'type' in constraints && 'noOutputs' === constraints.type && constraints.hasOwnProperty('listMessage')) {
            this.showOutputs = false;
        }

        if (value) {
            const valJson = JSON.parse(value);
            const val = this.convertOldToNew(valJson);
            this.fillValue(val, messgeHeaderInput, messgeBodyInput, messgeFooterInput, messageButtonInput, listHeaderSpan, listSectionList);

        } else if (constraints && constraints.hasOwnProperty('listMessage')) {
            const val = this.convertContraintsToValue(constraints);
            this.fillValue(val, messgeHeaderInput, messgeBodyInput, messgeFooterInput, messageButtonInput, listHeaderSpan, listSectionList);
        } else {
            const idx = this.listMessage.action.sections.length;
            this.addSection(listSectionList, idx);
        }

        messageDiv.append([messgeHeaderInput, messgeBodyInput, messgeFooterInput, messageHourSpan, messageButtonInput]);
        listHeaderDiv.append([listHeaderSpan]);
        listBodyDiv.append([listSectionList, listSectionButton]);
        listDiv.append([listHeaderDiv, listBodyDiv, selectedOptionSpan, sendButton]);

        const nameClass = field.attr('name').replace('#', '-');
        containerDiv.addClass(nameClass);
        containerDiv.append([messageDiv, listDiv, field]);

        return containerDiv;
    }

    convertOldToNew(val) {
        if (val.listMessage.header && typeof val.listMessage.header.type === 'string') {
            val.listMessage.header.type = {
                type: val.listMessage.header.type
            };
        }
        if (val.listMessage.header && typeof val.listMessage.header.text === 'string') {
            val.listMessage.header.text = {
                text: val.listMessage.header.text
            };
        }
        if (typeof val.listMessage.action.button === 'string') {
            val.listMessage.action.button = {
                button: val.listMessage.action.button
            };
        }
        val.listMessage.action.sections.forEach((section, idx) => {
            if (typeof section.title === 'string') {
                val.listMessage.action.sections[idx].title = {
                    title: section.title
                };
            }
            section.rows.forEach((row, idx2) => {
                if (typeof row.id === 'string') {
                    row.id = {
                        id: row.id
                    };
                }
                if (typeof row.description === 'string') {
                    row.description = {
                        description: row.description
                    };
                }
                if (typeof row.title === 'string') {
                    row.title = {
                        title: row.title
                    };
                }
                val.listMessage.action.sections[idx].rows[idx2] = row;
            });
        });
        const lm: InteractiveMessages = val.listMessage as InteractiveMessages;
        this.listMessage.action = lm.action;
        this.listMessage.header = lm.header;
        this.listMessage.body = lm.body;
        this.listMessage.footer = lm.footer;
        this.saveOnField();
        return val;
    }

}
