import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ChannelsService {

    fbGraph = environment.facebook.graph + environment.facebook.version;

    constructor(private http: HttpClient) { }

    create(slug: string, channel: any) {
        const url = '/coreapi/v2/tenantChannels';
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.post(url, channel, { headers });
    }

    delete(slug: string, id: any) {
        const url = `/coreapi/v2/tenantChannels/${id}`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.delete(url, { headers });
    }

    get(slug: string) {
        const url = '/coreapi/v2/tenantChannels';
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    supportChannels(slug) {
        const url = '/coreapi/v2/supportedChannels';
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });

    }

    update(slug: string, id: any, enabled: any) {
        const url = `/coreapi/v2/tenantChannels/${id}`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.put(url, enabled, { headers });
    }

    getPages(token) {
        const url = `${this.fbGraph}/me?fields=accounts{name,picture,category_list,access_token,instagram_business_account}&access_token=${token}`;
        return this.http.get(url);
    }

    getByUrl(url) {
        return this.http.get(url);
    }

    getInstagramPages(pageId, token) {
        const url = `${this.fbGraph}/${pageId}?fields=instagram_business_account&access_token=${token}`;
        return this.http.get(url);
    }

    addFacebook(slug: any, channel: any) {
        let env = environment.name;
        if (environment.name === 'pre') {
            env = 'predev';
        }
        const url = `/coreapi/v2/fbapp/${env}/token`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.post(url, channel, { headers });
    }

    addInstagram(slug: any, channel: any) {
        let env = environment.name;
        if (environment.name === 'pre') {
            env = 'predev';
        }
        const url = `/coreapi/v2/instagram/${env}/token`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.post(url, channel, { headers });
    }

    getSubtypes(slug) {
        const url = '/coreapi/v2/channelSubtypes';
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    getEnvironments(slug) {
        const url = '/coreapi/v2/tenantChannels/channelEnvironments';
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    updateHorary(channelId: string, payload: any, slug: string) {
        const url = `/coreapi/v2/tenantChannels/${channelId}`;

        const headers = new HttpHeaders({
            Slug: slug
        });

        return this.http.put(url, payload, { headers });
    }

    getSandbox(channelType: string, slug: string) {
        const url = `/coreapi/v2/_sandboxChannel/byChannelType?channel=${channelType}`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    createSandbox(sandboxId: string, phoneNumber: string, slug: string) {
        const url = `/coreapi/v2/smooch/sandbox/${sandboxId}/user/${phoneNumber}`;
        const headers = new HttpHeaders({
            Slug: slug
        });

        return this.http.post(url, null, { headers });
    }

    createWhatsappSandbox(sandboxId: string, phoneNumber: string, slug: string) {
        const url = `/coreapi/v2/whatsapp/sandbox/${sandboxId}/user/${phoneNumber}`;
        const headers = new HttpHeaders({
            Slug: slug
        });

        return this.http.post(url, null, { headers });
    }

    generateWhatsappToken(slug: string, channelOwnerId: string) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/login`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.post(url, {}, { headers });
    }

    generateWhatsappWebhook(slug: string, channelOwnerId: string) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/webhook`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.patch(url, {
            url: `https://wa-${environment.name}.ston.ai/v1/incoming/webhook/${channelOwnerId}`
        }, { headers });
    }

    updateWhatsappProfilePhoto(slug: string, channelOwnerId: string, urlPicture: string) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/profile/photo`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.post(url, { url: urlPicture }, { headers });
    }

    updateWhatsappAbout(slug: string, channelOwnerId: string, text: string) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/profile/about`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.post(url, { text }, { headers });
    }

    updateWhatsappInfo(slug: string, channelOwnerId: string, info: any) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/profile/info`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.post(url, info, { headers });
    }

    getWhatsappProfilePhoto(slug: string, channelOwnerId: string) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/profile/photo`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    getWhatsappAbout(slug: string, channelOwnerId: string) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/profile/about`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    getWhatsappInfo(slug: string, channelOwnerId: string) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/profile/info`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    getWhatsappCloudBusinessProfile(slug: string, channelOwnerId: string) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/cloud/whatsapp_business_profile?fields=about,address,description,email,profile_picture_url,websites,vertical`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    updateWhatsappCloudBusinessProfile(slug: string, channelOwnerId: string, profile: any) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/cloud/whatsapp_business_profile`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.patch(url, profile, { headers });
    }
}
