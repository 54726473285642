import { Component, OnInit, SecurityContext, ViewChild, ViewEncapsulation } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import { HsmMessageService } from '../../../services/hsm-message.service';
import { HsmMessage } from '../../../models/hsm-message';
import {
    HSMMediaType,
    HSMType,
    HSMButtonSetType,
    HSMTemplateType,
    HSMButtonType,
    HSMCategoryType,
    HSMButtonSetTypeLabel,
    HSMTemplateTypeLabel,
    HSMMediaTypeLabel,
    HSMCategoryTypeLabel,
    HSMLanguagesType,
    HSMLanguagesTypeLabel,
    HSMScopeType,
    HSMStatusType
} from '../../../enums/hsm-message';
import { CampaignService } from 'src/services/campaign.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/services/alert.service';
import { CustomerService } from 'src/services/customer.service';
import { Customer } from 'src/models/customer';
import { DataLayerService } from 'src/services/data-layer.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileService } from 'src/services/file.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NguCarousel, NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';
// import * as emojisList from '../../../assets/emoji.json';

declare var translate: any;
@Component({
    selector: 'app-validation-template',
    templateUrl: './validation-template.component.html',
    styleUrls: ['./validation-template.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ValidationTemplateComponent implements OnInit {
    channelSmooch;
    public Editor = ClassicEditor;
    currentCustomer: Customer;
    customerSlug: string;
    configEditor: any;
    existsEmojis: boolean;
    nButtons: number;
    buttons: any;
    buttonsAux: any;
    activeSlide: boolean[];
    templateType: HSMTemplateType;
    templateTypes: any[];
    buttonSetTypes: any[];
    mediaTypes: any[];
    categoryTypes: any[];
    channels: any[];
    disabledActions: boolean;
    languageTypes: any[];
    messagecontentLen: number;
    headerTextLen: number;
    footerTextLen: number;
    buttonTextLen1: number;
    buttonTextLen2: number;
    buttonTextLen3: number;
    button1Placeholder: string;
    button2Placeholder: string;
    button1MaxLength: number;
    button2MaxLength: number;
    currentChannelType: string;

    files: any[] = [];

    dataMessage: HsmMessage;

    existsWhatsappChannel: boolean;
    existsSmoochChannel: boolean;
    existsSmoochChannelSms:boolean;
    whatsappChannelOwnerId: string;
    translate: any;
    flagPath: string;

    categories: any[] = [];
    currentCategoryMessage = '';

    @ViewChild('myCarousel2') myCarousel2: NguCarousel<any>;
    carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 2, all: 0 },
        load: 1,
        velocity: 0.2,
        point: {
            visible: true
        },
        loop: false,
    };

    carouselTileItems: Array<any>;

    constructor(
        private hsmMessageService: HsmMessageService,
        private campaignService: CampaignService,
        private activateRoute: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private customerService: CustomerService,
        private dataLayerService: DataLayerService,
        private domSanitizer: DomSanitizer,
        private fileService: FileService,
        private recaptchaV3Service: ReCaptchaV3Service
    ) {

        this.carouselTileItems = [0];
        this.whatsappChannelOwnerId = '';
        this.existsWhatsappChannel = false;
        this.existsSmoochChannel = false;
        this.existsSmoochChannelSms=false;
        this.currentChannelType = 'gus';
        this.button1MaxLength = 25;
        this.button2MaxLength = 25;
        this.existsEmojis = false;
        this.nButtons = 0;
        this.messagecontentLen = 0;
        this.headerTextLen = 0;
        this.footerTextLen = 0;
        this.buttonTextLen1 = 0;
        this.buttonTextLen2 = 0;
        this.buttonTextLen3 = 0;
        this.buttons = {};
        this.buttonsAux = {};
        this.activeSlide = [
            true,
            false,
            false,
            false,
            false,
            false,
            false
        ];
        this.disabledActions = false;
        this.templateType = HSMTemplateType.STANDARD;
        this.buttonSetTypes = this.getListEnum(HSMButtonSetTypeLabel);
        this.templateTypes = this.getListEnum(HSMTemplateTypeLabel);
        this.mediaTypes = this.getListEnum(HSMMediaTypeLabel);
        this.categoryTypes = this.getListEnum(HSMCategoryTypeLabel);
        this.languageTypes = this.getListEnum(HSMLanguagesTypeLabel);

        this.setDefaultDataMessage();
        this.setDefaultButtons();
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = 'Campaña -> Validation -> ';
    }

    ngOnInit(): void {
        this.customerSlug = this.activateRoute.snapshot.paramMap.get('customer_slug');
        this.dataMessage.id = this.activateRoute.snapshot.paramMap.get('hsm_message_id');
        // this.campaignService.getOne(this.customerSlug, this.dataMessage.id, this.dataMessage.);
        this.getCurrentCustomer();
        this.editorSettingsWithEmojis();
        this.dataLayerService.setFlag(this.flagPath, 'start');

    }

    getHSMCategories() {
        this.hsmMessageService.getHSMCategories(this.customerSlug, this.currentCustomer.id).subscribe(
            (categories:any[]) => {
                this.categories = categories;
            }
        );
    }

    getCurrentHsmMessage() {
        this.hsmMessageService.getOne(this.customerSlug, this.dataMessage.id, this.dataMessage.tenantChannelId, this.currentCustomer.id).subscribe(
            (res: any) => {

                this.dataMessage = res;
                if (this.dataMessage.category == 'AUTHENTICATION') {
                    if (this.dataMessage.codeExpirationMinutes) {
                        this.dataMessage.codeExpiryDate = true;
                    } else {
                        this.dataMessage.codeExpiryDate = false;
                    }
                }

                this.templateType = HSMTemplateType.BUTTONS;
                this.nButtons = this.getButtonsCount();
                const slideNumber = this.getSlideNumberBySetTypeAndButtons();

                this.dataMessage.messageContent = this.messageToMarkup(this.dataMessage.messageContent);
                this.messagecontentLen = this.countCharacters(this.dataMessage.messageContent);
                this.headerTextLen = this.countCharacters(this.dataMessage.headerText);
                this.footerTextLen = this.countCharacters(this.dataMessage.footerText);
                this.buttonTextLen1 = this.countCharacters(this.dataMessage.button1);
                this.buttonTextLen2 = this.countCharacters(this.dataMessage.button2);
                this.buttonTextLen3 = this.countCharacters(this.dataMessage.button3);

                if (this.nButtons === 0) {
                    this.templateType = HSMTemplateType.STANDARD;
                }

                if (this.dataMessage.headerText || this.dataMessage.mediaContent) {
                    this.dataMessage.showHeader = true;
                } else {
                    this.dataMessage.showHeader = false;
                }

                if (this.dataMessage.footerText) {
                    this.dataMessage.showFooter = true;
                } else {
                    this.dataMessage.showFooter = false;
                }

                this.selectSlide(slideNumber, this.nButtons, null, false);
            },
            err => {
                console.error(err);
            }
        );
    }

    updateCategoryMessage() {
        for (const cat of this.categories) {
            if (cat.value === this.dataMessage.category) {
                this.currentCategoryMessage = cat.description[localStorage.getItem('lang')];
                break;
            }
        }
        if (this.dataMessage.category === 'AUTHENTICATION') {
            this.dataMessage.button1 = 'Y';
            this.dataMessage.button1Type = 'COPY_CODE';
            this.dataMessage.messageContent = 'N/A';
            this.dataMessage.params = ['{{code=123123}}'];
            this.dataMessage.buttonSetType = 'OTP';
            this.alertService.info(this.translate.sections.campaigns.validationTemplate.noteCode, { autoClose: false });
        }
    }
    getCurrentCustomer() {
        this.customerService.getBySlug(this.customerSlug, 'true').subscribe(
            (res: any) => {
                this.currentCustomer = res.tenant;

                // for (const chanel of this.currentCustomer.channels) {
                //     if (chanel.channel === 'WHATSAPP' || (chanel.channel === 'SMOOCH' && chanel.channelSubtype === 'WHATSAPP')) {
                //         this.dataMessage.tenantChannelId = chanel.id;
                //         this.currentChannelType = chanel.channel;
                //         // this.whatsappChannelOwnerId = chanel.channelOwnerId;
                //         // console.log(chanel);
                //     }
                //     if (chanel.channel === 'WHATSAPP') {
                //         this.existsWhatsappChannel = true;
                //         this.whatsappChannelOwnerId = chanel.channelOwnerId;
                //     } else if (chanel.channel === 'SMOOCH' && chanel.channelSubtype === 'WHATSAPP') {
                //         this.existsSmoochChannel = true;
                //     }
                // }

                const waChannel = this.currentCustomer.channels.find(channel => channel.channel === 'WHATSAPP' && channel.enabled);
                const channelSmoochSms = this.currentCustomer.channels.find(channel => channel.channel === 'SMOOCH_SMS' && channel.enabled)
                if (channelSmoochSms && 'channel' in channelSmoochSms) {
                    this.channelSmooch = channelSmoochSms.channel;
                }
                if (waChannel) {
                    this.dataMessage.tenantChannelId = waChannel.id;
                    this.currentChannelType = waChannel.channel;
                    this.existsWhatsappChannel = true;
                    this.existsSmoochChannel = false;
                    this.whatsappChannelOwnerId = waChannel.channelOwnerId;
                    // console.log(this.whatsappChannelOwnerId);
                }else if(channelSmoochSms){

                    this.dataMessage.tenantChannelId = channelSmoochSms.id;
                    this.currentChannelType = channelSmoochSms.channel;
                    this.existsWhatsappChannel = false;
                    this.existsSmoochChannelSms = true;
                    this.existsSmoochChannel = false;
                    this.whatsappChannelOwnerId = channelSmoochSms.channelOwnerId;

                    if (this.existsSmoochChannelSms) {
                        this.templateTypes = [
                            {
                                idx: HSMTemplateType.STANDARD,
                                value: HSMTemplateTypeLabel.STANDARD
                            }
                        ];
                    }
                } else {
                    const waChannel2 = this.currentCustomer.channels.find(channel => channel.channel === 'SMOOCH' && channel.channelSubtype === 'WHATSAPP');
                    if (waChannel2) {
                        this.dataMessage.tenantChannelId = waChannel2.id;
                        this.currentChannelType = waChannel2.channel;
                        this.existsWhatsappChannel = false;
                        this.existsSmoochChannel = true;
                    }
                }

                if (!this.dataMessage.tenantChannelId) {
                    const options = {
                        link: `/customers/${this.customerSlug}/platforms`,
                        secondsToClose: 10
                    };
                    this.alertService.warning(
                        translate.sections.campaigns.validationTemplate.alertMessageWhatsApp,
                        options
                    );
                    this.disabledActions = true;
                }

                if (this.dataMessage.id && this.dataMessage.tenantChannelId) {
                    this.getCurrentHsmMessage();
                }
                this.getHSMCategories();

            },
            err => {
                console.error(err);
            }
        );
    }

    onChangeMessageContent(e) {
        this.messagecontentLen = this.countCharacters(this.messageToWhatsStyle(this.dataMessage.messageContent));
    }

    onChangeHeaderText(e) {
        this.headerTextLen = this.countCharacters(this.messageToWhatsStyle(this.dataMessage.headerText));
    }

    onChangeFooterText(e) {
        this.footerTextLen = this.countCharacters(this.messageToWhatsStyle(this.dataMessage.footerText));
    }

    getListEnum(enumType) {
        const enumList = [];
        for (let item in enumType) {
            if (Number.isNaN(Number(item))) {
                enumList.push({ value: enumType[item], idx: item })
            }
        }
        return enumList;
    }

    setDefaultButtons() {
        this.buttons[HSMButtonSetType.QUICK_REPLY] = [
            {
                label: translate.sections.campaigns.validationTemplate.button + ' 1',
                key: 'button1',
                type: HSMButtonType.PAYLOAD,
                placeholder: translate.sections.campaigns.validationTemplate.exampleYes
            },
            {
                label: translate.sections.campaigns.validationTemplate.button + ' 2',
                key: 'button2',
                type: HSMButtonType.PAYLOAD,
                placeholder: translate.sections.campaigns.validationTemplate.exampleNot
            },
            {
                label: translate.sections.campaigns.validationTemplate.button + ' 3',
                key: 'button3',
                type: HSMButtonType.PAYLOAD,
                placeholder: translate.sections.campaigns.validationTemplate.exampleMoreInfo
            },
        ];
        this.buttons[HSMButtonSetType.CALL_TO_ACTION] = [
            {
                label: translate.sections.campaigns.validationTemplate.button + ' 1',
                key: 'button1',
                type: HSMButtonType.URL,
                placeholder: translate.sections.campaigns.validationTemplate.example + ': https://gus.chat'
            },
            {
                label: translate.sections.campaigns.validationTemplate.button + ' 2',
                key: 'button2',
                type: HSMButtonType.PHONE_NUMBER,
                placeholder: translate.sections.campaigns.validationTemplate.example + ': 525556581111'
            }
        ];
        this.buttonsAux = this.deepCopy(this.buttons);
    }

    setDefaultDataMessage() {
        this.dataMessage = {
            name: '',
            tenantChannelId: '',
            mediaType: HSMMediaType.NONE,
            messageType: HSMType.TEXT,
            footerText: '',
            languageCode: HSMLanguagesType.es_MX,
            showFooter: false,
            showHeader: false,
            category: 'MARKETING',
            button1Type: HSMButtonType.NONE,
            button2Type: HSMButtonType.NONE,
            button3Type: HSMButtonType.NONE,
            button1Value: '',
            button2Value: '',
            button3Value: '',
            buttonSetType: HSMButtonSetType.NONE,
            languagePolicy: 'deterministic',
            scope: HSMScopeType.CAMPAIGNS,
            codeExpiryDate: false,
            addSecurityRecommendation: false,
            codeExpirationMinutes: null
        } as HsmMessage;
    }

    changeTemplate() {
        this.dataLayerService.setFlag(this.flagPath, 'type');
        if (this.templateType === HSMTemplateType.BUTTONS) {
            this.carouselTileItems = [0, 1, 2];
            if ([null, '', undefined, HSMButtonSetType.NONE].includes(this.dataMessage.buttonSetType)) {
                this.dataMessage.buttonSetType = HSMButtonSetType.QUICK_REPLY;
                this.dataMessage.button1Type = HSMButtonType.PAYLOAD;
                this.dataMessage.button2Type = HSMButtonType.PAYLOAD;
                this.dataMessage.button3Type = HSMButtonType.PAYLOAD;
                this.nButtons = 3;
            }
            if (this.dataMessage.category == 'AUTHENTICATION') {
                this.dataMessage.category = 'MARKETING';
                this.dataMessage.messageContent = '';
                this.dataMessage.button1 = '';
                this.dataMessage.button1Value = '';
                this.dataMessage.button1Type = HSMButtonType.PAYLOAD;
                this.dataMessage.params = [];
                this.dataMessage.buttonSetType = HSMButtonSetType.QUICK_REPLY;
            }
        } else if (this.templateType === HSMTemplateType.STANDARD) {
            this.carouselTileItems = [0];
            this.myCarousel2.reset(true);
            this.dataMessage.messageType = HSMType.TEXT;
            if (this.dataMessage.showHeader === false){
                this.dataMessage.mediaType = HSMMediaType.NONE;
            } else {
                this.dataMessage.mediaType = HSMMediaType.TEXT;
            }
            this.dataMessage.buttonSetType = HSMButtonSetType.NONE;
            this.dataMessage.button1 = '';
            this.dataMessage.button1Type = HSMButtonType.NONE;
            this.dataMessage.button2 = '';
            this.dataMessage.button2Type = HSMButtonType.NONE;
            this.dataMessage.button3 = '';
            this.dataMessage.button3Type = HSMButtonType.NONE;
            this.nButtons = 0;
        }
        this.button1Placeholder = this.getPlaceholder(1, this.dataMessage.button1Type);
        this.button2Placeholder = this.getPlaceholder(2, this.dataMessage.button2Type);

        const nButtons = this.getButtonsCount();
        const slideNumber = this.getSlideNumberBySetType();
        this.selectSlide(slideNumber, nButtons);
    }

    changeLanguage() {
        this.dataLayerService.setFlag(this.flagPath, 'language');
    }

    selectButtonSetType(buttonSetType) {
        this.dataMessage.buttonSetType = HSMButtonSetType[buttonSetType];
        let nButtons = this.getButtonsCount();
        const slideNumber = this.getSlideNumberBySetType();
        if (this.dataMessage.buttonSetType === HSMButtonSetType.CALL_TO_ACTION) {
            nButtons = 2;
            this.dataMessage.button1Type = HSMButtonType.URL;
            this.dataMessage.button2Type = HSMButtonType.PHONE_NUMBER;
            this.dataMessage.button3Type = HSMButtonType.NONE;
            this.dataMessage.button1 = translate.sections.campaigns.validationTemplate.call;
            this.dataMessage.button2 = translate.sections.campaigns.validationTemplate.viewWeb;
            this.dataMessage.button3 = '';
            this.dataLayerService.setFlag(this.flagPath, 'action');
        } else if (this.dataMessage.buttonSetType === HSMButtonSetType.QUICK_REPLY) {
            nButtons = 3
            this.dataMessage.button1 = '';
            this.dataMessage.button2 = '';
            this.dataMessage.button3 = '';
            this.dataMessage.button1Type = HSMButtonType.PAYLOAD;
            this.dataMessage.button2Type = HSMButtonType.PAYLOAD;
            this.dataMessage.button3Type = HSMButtonType.PAYLOAD;
            this.dataLayerService.setFlag(this.flagPath, 'fast');
        }
        this.dataMessage.button1Value = '';
        this.dataMessage.button2Value = '';
        this.dataMessage.button3Value = '';

        this.selectSlide(slideNumber, nButtons);
        this.button1Placeholder = this.getPlaceholder(1, this.dataMessage.button1Type);
        this.button2Placeholder = this.getPlaceholder(2, this.dataMessage.button2Type);
    }

    changeShowHeader(showHeader: boolean) {
        this.dataMessage.showHeader = !showHeader;
        if (this.dataMessage.showHeader === false) {
            this.dataMessage.messageType = HSMType.TEXT;
            this.dataMessage.mediaType = HSMMediaType.NONE;
            this.dataMessage.mediaContent = '';
            this.dataMessage.headerText = '';
        } else {
            this.dataMessage.mediaType = HSMMediaType.TEXT;
        }
    }

    changeShowFooter(showFooter: boolean) {
        this.dataMessage.showFooter = !showFooter;
        if (this.dataMessage.showFooter === false) {
            this.dataMessage.footerText = '';
        }
    }

    getButtonsCount() {
        let nButtons = 0;
        let countButtons = 0;
        if (this.templateType === HSMTemplateType.BUTTONS) {

            // Establece valores por default
            if (this.dataMessage.buttonSetType === HSMButtonSetType.CALL_TO_ACTION) {
                nButtons = 2;
            } else if (this.dataMessage.buttonSetType === HSMButtonSetType.QUICK_REPLY) {
                nButtons = 3;
            }

            // Establece los valores pre existentes
            if (!([null, '', undefined, HSMButtonType.NONE].includes(this.dataMessage.button1Type))) {
                countButtons++;
            }
            if (!([null, '', undefined, HSMButtonType.NONE].includes(this.dataMessage.button2Type))) {
                countButtons++;
            }
            if (!([null, '', undefined, HSMButtonType.NONE].includes(this.dataMessage.button3Type))) {
                countButtons++;
            }

            if (countButtons > 0) {
                nButtons = countButtons;
            }
        }
        return nButtons;
    }

    getSlideNumberBySetType() {
        let slideNumber = 0;
        if (this.dataMessage.buttonSetType === HSMButtonSetType.QUICK_REPLY) {
            slideNumber = 1;
        } else if (this.dataMessage.buttonSetType === HSMButtonSetType.CALL_TO_ACTION) {
            slideNumber = 4;
        }
        return slideNumber;
    }

    getSlideNumberBySetTypeAndButtons() {
        let slideNumber = 0;
        if (this.dataMessage.buttonSetType === HSMButtonSetType.QUICK_REPLY) {
            slideNumber = 1 + 3 - this.nButtons;
        } else if (this.dataMessage.buttonSetType === HSMButtonSetType.CALL_TO_ACTION) {
            slideNumber = 4 + 2 - this.nButtons;
        }
        return slideNumber;
    }

    changeMediaType(mediaType: any) {
        this.dataMessage.mediaType = mediaType as HSMMediaType;

        if (this.dataMessage.mediaType === HSMMediaType.TEXT) {
            this.dataMessage.messageType = HSMType.TEXT;
        } else {
            this.dataMessage.messageType = HSMType.MEDIA;
        }
        this.dataMessage.mediaContent = '';
        this.files = [];

        const slideNumber = this.getSlideNumberBySetType();
        const nButtons = this.getButtonsCount();

        this.selectSlide(slideNumber, nButtons);
    }

    editorSettings(emojis) {
        this.configEditor = {
            width: 344,
            skin: 'minimalist,/js/minimalist/',
            placeholder: translate.sections.campaigns.validationTemplate.exampleMessage,
            language: 'es',
            emoji: emojis,
            toolbar: {
                items: [
                    'bold',
                    'italic',
                    'strikethrough',
                    'emoji',
                    // 'link',
                    // 'htmlEmbed'
                ]
            }
        }
    }

    editorSettingsWithEmojis() {
        if (localStorage.getItem('emojis')) {
            const emojis = JSON.parse(localStorage.getItem('emojis'));
            this.existsEmojis = true;
            this.editorSettings(emojis);
        } else {
            this.hsmMessageService.getJSON().subscribe(emojis => {
                this.existsEmojis = true;
                localStorage.setItem('emojis', JSON.stringify(emojis));
                this.editorSettings(emojis);
            });
        }
    }

    getActiveSlide(slideNumber: number) {
        this.activeSlide = this.activeSlide.map((val: boolean, idx: number) => {
            let result = false;
            if (slideNumber === idx) {
                result = true;
            }
            return result;
        });
    }

    selectSlide(slideNumber: number, nButtons: number, type = null, onclick=true) {
        this.getActiveSlide(slideNumber);
        this.nButtons = nButtons;

        if (this.nButtons === 3) {
            if (onclick) {
                this.dataMessage.button1 = '';
                this.dataMessage.button2 = '';
                this.dataMessage.button3 = '';
            }

            this.dataMessage.button1Type = HSMButtonType.PAYLOAD;
            this.dataMessage.button2Type = HSMButtonType.PAYLOAD;
            this.dataMessage.button3Type = HSMButtonType.PAYLOAD;
            this.button1MaxLength = 25;
            this.button2MaxLength = 25;
            this.button1Placeholder = this.getPlaceholder(1, this.dataMessage.button1Type);
            this.button2Placeholder = this.getPlaceholder(2, this.dataMessage.button2Type);
        } else if (this.nButtons === 2) {
            if (onclick) {
                this.dataMessage.button3 = '';
            }
            this.dataMessage.button3Type = HSMButtonType.NONE;
            if (this.dataMessage.buttonSetType === HSMButtonSetType.CALL_TO_ACTION) {
                if (onclick) {
                    this.dataMessage.button1 = translate.sections.campaigns.validationTemplate.call;
                    this.dataMessage.button2 = translate.sections.campaigns.validationTemplate.viewWeb;
                }
                this.dataMessage.button1Type = HSMButtonType.PHONE_NUMBER;
                this.dataMessage.button2Type = HSMButtonType.URL;
                this.button2MaxLength = 500;
            } else if (this.dataMessage.buttonSetType === HSMButtonSetType.QUICK_REPLY) {
                if (onclick) {
                    this.dataMessage.button1 = '';
                    this.dataMessage.button2 = '';
                }
                this.dataMessage.button1Type = HSMButtonType.PAYLOAD;
                this.dataMessage.button2Type = HSMButtonType.PAYLOAD;
                this.button1MaxLength = 25;
                this.button2MaxLength = 25;
            }
            this.button1Placeholder = this.getPlaceholder(1, this.dataMessage.button1Type);
            this.button2Placeholder = this.getPlaceholder(2, this.dataMessage.button2Type);
        } else if (this.nButtons === 1) {
            if (onclick) {
                this.dataMessage.button2 = '';
                this.dataMessage.button3 = '';
            }
            this.dataMessage.button2Type = HSMButtonType.NONE;
            this.dataMessage.button3Type = HSMButtonType.NONE;

            if (type === HSMButtonType.PHONE_NUMBER) {
                this.dataMessage.button1Type = HSMButtonType.PHONE_NUMBER;
                this.button1MaxLength = 25;
                this.dataMessage.button1 = translate.sections.campaigns.validationTemplate.call;
            } else if (type === HSMButtonType.URL) {
                this.dataMessage.button1Type = HSMButtonType.URL;
                this.button1MaxLength = 500;
                this.dataMessage.button1 = translate.sections.campaigns.validationTemplate.viewWeb;
            } else {
                this.dataMessage.button1Type = HSMButtonType.PAYLOAD;
            }
            this.button1Placeholder = this.getPlaceholder(1, this.dataMessage.button1Type);
        }
    }

    onFileDropped(event) {
        // console.log('function: onFileDropped');
        // console.log(event);
        let allowed = false;
        if (this.dataMessage.mediaType === HSMMediaType.IMAGE) {
            if (['image/jpeg', 'image/png'].includes(event[0].type)) {
                allowed = true;
            }
        } else if (this.dataMessage.mediaType === HSMMediaType.DOCUMENT) {
            if (['application/pdf'].includes(event[0].type)) {
                allowed = true;
            }
        }
        if (allowed) {
            this.prepareFilesList(event);
        } else {
            if (this.dataMessage.mediaType === HSMMediaType.IMAGE) {
                this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageImage);
            } else if (this.dataMessage.mediaType === HSMMediaType.DOCUMENT) {
                this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessagePDF);
            }
        }
    }

    fileBrowseHandler(event, video=false) {
        const files = event.target.files;
        this.prepareFilesList(files, video);
    }

    deleteFile(index: number) {
        this.files.splice(index, 1);
        this.dataMessage.mediaContent = '';
    }

    prepareFilesList(files: Array<any>, video=false) {
        for (const item of files) {
            this.files.push(item);
        }
        this.uploadFile(files, video);
    }

    formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    uploadFile(files, video=false) {
        // const filefind = $event.target.files[0];

        if (files.length > 0 && this.customerSlug) {
            const FormFiles = new FormData();


            if (video) {
                FormFiles.append('file', files[0]);
                this.recaptchaV3Service.execute('uploadVideo').subscribe((reToken) => {
                    this.campaignService.uploadAndEncodeVideo(this.customerSlug, this.whatsappChannelOwnerId, FormFiles, reToken).subscribe({
                        next: (data: any) => {
                            const fileSizeB = data.fileSize;
                            const maxSizeB = 16000000; // 16MB en bytes
                            if (fileSizeB < maxSizeB) {
                                this.dataMessage.mediaContent = data.url;
                                const player: HTMLMediaElement = $('video.video').get(0) as HTMLMediaElement;
                                player.load();
                            } else {
                                this.deleteFile(0);
                                this.alertService.warning(translate.sections.campaigns.validationTemplate.alertMessageCode);
                            }
                        },
                        error: err => {
                            this.deleteFile(0);
                            console.log(err);
                        }
                    });
                });
            } else {
                FormFiles.append('document', files[0]);
                this.recaptchaV3Service.execute('uploadFile').subscribe((reToken) => {
                    this.fileService.upload(this.customerSlug, FormFiles, reToken).subscribe({
                        next: (res: any) => {
                            this.dataMessage.mediaContent = res.url;
                        },
                        error: err => {
                            this.deleteFile(0);
                            console.log(err);
                        }
                    });
                });
            }

        }

    }

    deepCopy(variable) {
        if (variable) {
            return JSON.parse(JSON.stringify(variable));
        }
    }

    getButtonCTAType(buttonKey) {
        let buttonType = HSMButtonType.URL;

        if (/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(this.dataMessage[buttonKey + 'Value'])) {
            buttonType = HSMButtonType.URL;
        } else {
            buttonType = HSMButtonType.PHONE_NUMBER;
        }
        return buttonType;
    }

    buttonFocusOut(buttonKey) {
        if (this.dataMessage.buttonSetType === HSMButtonSetType.CALL_TO_ACTION) {
            this.dataMessage[buttonKey + 'Type'] = this.getButtonCTAType(buttonKey);
        }
    }

    listOrderChanged(buttons) {
        for (const idx in buttons) {
            const i = parseInt(idx);
            const btns = {
                button1: this.deepCopy(this.dataMessage.button1),
                button2: this.deepCopy(this.dataMessage.button2),
                button3: this.deepCopy(this.dataMessage.button3),
            }

            this.dataMessage['button' + i] = btns[buttons[idx].key];
            this.dataMessage['button' + i + 'Type'] = btns[buttons[idx].type];
        }
    }

    makeid(length) {
        const result = [];
        const characters = 'abcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
        }
       return result.join('');
    }

    createSlug(name: any) {
        return name.toLowerCase().replaceAll(/[^a-z]/g, '_').toLowerCase() + this.makeid(5);
    }

    getVars(str) {
        if (str) {
            // const regexp = /{{?[a-z]+.[a-z]*}?}}/gm;
            const regexp = /{{[^}]*}}/gm;
            const matches = str.matchAll(regexp);
            return [...matches].map((obj) => obj[0].replaceAll(/[{}]/g, ''));
        } else {
            return [];
        }
    }

    messageToMarkup(text) {
        text = text.replaceAll('&nbsp;', ' ');
        // text = this.cleanFormatSpaces(text);
        text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
        text = text.replace(/_(.*?)_/g, '<i>$1</i>');
        text = text.replace(/~(.*?)~/g, '<del>$1</del>');
        text = text.replace(/```(.*?)```/g, '<span class="">$1</span>');
        text = text.replace(/\n/g, '<br>');
        return text;
    }

    cleanAsteriskSpaces(str) {
        let strAux = JSON.parse(JSON.stringify(str));
        const regex = /\*([^\*].*?)\*/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            strAux = strAux.replace(m[0], ' *' + m[1].trim() + '* ');
        }
        return strAux;
    }

    cleanUnderscoreSpaces(str) {
        let strAux = JSON.parse(JSON.stringify(str));
        const regex = /_([^_].*?)_/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            strAux = strAux.replace(m[0], ' _' + m[1].trim() + '_ ');
        }
        return strAux;
    }

    cleanAccentSpaces(str) {
        let strAux = JSON.parse(JSON.stringify(str));
        const regex = /~([^\~].*?)~/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            strAux = strAux.replace(m[0], ' ~' + m[1].trim() + '~ ');
        }
        return strAux;
    }

    cleanMultipleSpaces(str) {
        return str.replace(/ +/g, ' ');
    }

    cleanAsteriskSpaces2(str) {
        let strAux = JSON.parse(JSON.stringify(str));
        const regex = /([a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü]?)(\*[^\*]*?\*)([a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü]?)/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            strAux = strAux.replace(m[0], m[1] + ' ' + m[2].trim() + ' ' + m[3]).trim();
        }
        return strAux;
    }
    cleanUnderscoreSpaces2(str) {
        let strAux = JSON.parse(JSON.stringify(str));
        const regex = /([a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü]?)(_[^_]*?_)([a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü]?)/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            strAux = strAux.replace(m[0], m[1] + ' ' + m[2].trim() + ' ' + m[3]).trim();
        }
        return strAux;
    }
    cleanAccentSpaces2(str) {
        let strAux = JSON.parse(JSON.stringify(str));
        const regex = /([a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü]?)(~[^~]*?~)([a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü]?)/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            strAux = strAux.replace(m[0], m[1] + ' ' + m[2].trim() + ' ' + m[3]).trim();
        }
        return strAux;
    }

    clean3Formats(str) {
        let strAux = JSON.parse(JSON.stringify(str));
        const regex = /(_ *~ *\*)([^\*_~]*?)(\* * ~ *_)/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            strAux = strAux.replace(m[0], m[1].replaceAll(' ', '') + m[2].trim() + m[3].replaceAll(' ', '')).trim();
        }
        return strAux;
    }

    cleanVarsUnderline(str) {
        let strAux = JSON.parse(JSON.stringify(str));
        const regex = /({{[^}].*?}})/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            strAux = strAux.replace(m[0], m[1].trim().replaceAll('_', '≤'));
        }
        return strAux;
    }

    recleanVarsUnderline(str) {
        let strAux = JSON.parse(JSON.stringify(str));
        const regex = /({{[^}].*?}})/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            strAux = strAux.replace(m[0], m[1].trim().replaceAll('≤', '_'));
        }
        return strAux;
    }

    cleanFormatSpaces(str) {
        let strAux = JSON.parse(JSON.stringify(str));
        const regex = /([\n]+ *)?([\*_~] *)?([\*_~] *)?([\*_~]{1})([^\*_~]*)([\*_~]? *)?([\*_~]? *)?([\*_~]{1})( *[\.,]{1})?/gm;
        let match;
        while ((match = regex.exec(str)) !== null) {
            if (match.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            let matchStr = '';
            match.forEach((m, i) => {
                if (m) {
                    if (i === 5) {
                        matchStr += m ? m.trim() : '';
                    } else if (i > 0) {
                        matchStr += m ? m.replaceAll(' ', '') : '';
                    }
                }
            });
            strAux = strAux.replace(match[0], matchStr.replace(/^ *(.*[^ *]) *$/g, '$1'));
        }
        return strAux;
    }

    messageToWhatsStyle(text) {
        if (text){
            // console.log(text);
            text = text.replaceAll(' ', '');
            text = text.replaceAll('<p><br data-cke-filler="true"></p>', '\n');
            text = text.replaceAll('<p> </p>', '\n');
            text = text.replaceAll('<p>&nbsp;</p>', '\n');
            text = text.replaceAll('&nbsp;', ' ');
            text = text.replace(/<[^\/>]*> *<\/[^>]*>/ig, '');
            text = text.replace(/<(\/?)strong\b((?:[^>"']|"[^"]*"|'[^']*')*)>/ig, '*');
            text = text.replace(/<(\/?)i\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '_');
            text = text.replace(/<(\/?)del\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '~');
            text = text.replace(/<(\/?)s\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '~');
            text = text.replace(/<(\/?)span\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '```');
            text = text.replace(/<p\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '');
            text = text.replace(/<\/p\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '\n');
            text = text.replace(/<(\/?)br\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '\n');
            text = text.replace(/(<([^>]+)>)/ig, '');

            // if (/\n{3,}/g.test(text)) {
            //     this.alertService.warning('El cuerpo del mensaje no puede tener más de 2 saltos de línea seguidos, se eliminaran los extras');
            // }

            text = text.replace(/\n{3,}/g, '\n\n');

            // console.log(text);
            // text = this.cleanAsteriskSpaces(text);
            // text = this.cleanUnderscoreSpaces(text);
            // text = this.cleanAccentSpaces(text);

            text = this.cleanVarsUnderline(text);

            text = this.cleanAsteriskSpaces2(text);
            text = this.cleanUnderscoreSpaces2(text);
            text = this.cleanAccentSpaces2(text);

            text = this.cleanFormatSpaces(text);

            text = this.recleanVarsUnderline(text);
            text = this.cleanMultipleSpaces(text);

            text = this.stripHtml(text);
            text = text.trim();
            // console.log(text);
        }
        return text;
    }

    stripHtml(html) {
        let tmp = document.createElement('div');
        tmp.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, html);
        return tmp.textContent || tmp.innerText || '';
    }

    // CRUD

    saveHSMMessage(success=null) {
        this.dataMessage.elementName = this.createSlug(this.dataMessage.name);
        if (this.dataMessage.category != 'AUTHENTICATION') {
            this.dataMessage.params = this.getVars(this.dataMessage.messageContent);
        }
        this.dataMessage.messageContent = this.messageToWhatsStyle(this.dataMessage.messageContent);
        if (this.dataMessage.button1) {
            this.dataMessage.button1 = this.dataMessage.button1.replace(/(<.*>)/ig, '');
        }
        if (this.dataMessage.button2) {
            this.dataMessage.button2 = this.dataMessage.button2.replace(/(<.*>)/ig, '');
        }
        if (this.dataMessage.button3) {
            this.dataMessage.button3 = this.dataMessage.button3.replace(/(<.*>)/ig, '');
        }

        const valid = this.validateFields();
        if (valid) {
            if (this.dataMessage.id) {
                this.dataMessage.editedBy = localStorage.getItem('email');
                this.hsmMessageService.update(this.dataMessage, this.customerSlug, this.dataMessage.id, this.dataMessage.tenantChannelId, this.currentCustomer.id).subscribe(
                    (res: any) => {
                        this.alertService.success(translate.sections.campaigns.validationTemplate.alertMessageUpdate);
                        if (typeof success === 'function') {
                            success(res);
                        }
                        this.dataLayerService.setFlag(this.flagPath, 'save');
                        this.dataMessage.messageContent = this.messageToMarkup(this.dataMessage.messageContent);
                    },
                    err => {
                        this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageNoUpdate);
                        this.dataMessage.messageContent = this.messageToMarkup(this.dataMessage.messageContent);
                    }
                );
            } else {
                this.dataMessage.createdBy = localStorage.getItem('email');
                if(this.channelSmooch === 'SMOOCH_SMS'){
                    this.dataMessage.authorizationStatus = HSMStatusType.APPROVED
                }

                this.hsmMessageService.create(this.dataMessage, this.customerSlug, this.dataMessage.tenantChannelId, this.currentCustomer.id).subscribe(
                    (res: any) => {
                        this.alertService.success(translate.sections.campaigns.validationTemplate.alertMessageCreated);
                        if (typeof success === 'function') {
                            success(res);
                        }
                        this.dataLayerService.setFlag(this.flagPath, 'save');
                        this.dataMessage.messageContent = this.messageToMarkup(this.dataMessage.messageContent);
                        if(this.existsSmoochChannelSms){
                            this.router.navigateByUrl(`/customers/${this.customerSlug}/campaigns/approvalStatus`);
                        }else{
                        this.router.navigate(['/', 'customers', this.customerSlug, 'campaigns', 'templateValidation', res.id]);
                    }
                    },
                    err => {
                        this.dataMessage.messageContent = this.messageToMarkup(this.dataMessage.messageContent);
                        this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageNoCreated);
                    }
                );
            }
        }
    }

    countCharacters(str) {
        let counter = 0;
        if (str) {
            counter = str.replace(/{{([^}]+)}}/g, '.....').length;
        }
        return counter;
    }

    availableChars(max, ) {

    }

    validateVars(str, type) {
        const valid = [];
        const regexp = /{{([^}]+)}}/g;
        const array = [...str.matchAll(regexp)];
        array.forEach(element => {
            if (type === 'WHATSAPP') {
                if (element[1].indexOf('=') > 0) {
                    valid.push(true);
                } else {
                    this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageVariable);
                    valid.push(false);
                }
                const chunks = element[1].split('=');
                const varName = chunks[0];
                if (varName.indexOf(' ') >= 0) {
                    this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageVariableName);
                    valid.push(false);
                } else {
                    valid.push(true);
                }
                if (/^[a-zA-Z\-_]+$/.test(varName)) {
                    valid.push(true);
                } else {
                    this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageVariableName2);
                    valid.push(false);
                }
            } else {
                if (element[1].indexOf(' ') >= 0) {
                    this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageVariableName3);
                    valid.push(false);
                } else {
                    valid.push(true);
                }
                if (/^[a-zA-Z\-_]+$/.test(element[1])) {
                    valid.push(true);
                } else {
                    this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageVariableName5);
                    valid.push(false);
                }
            }
        });
        return !valid.some((element) => element === false);
    }

    validateFields() {
        const valid = [];
        const messageContentCount = this.countCharacters(this.dataMessage.messageContent);
        const headerTextCount = this.countCharacters(this.dataMessage.headerText);
        const footerTextCount = this.countCharacters(this.dataMessage.footerText);

        if (this.dataMessage.messageContent && messageContentCount > 0) {
            if (messageContentCount <= 1024) {
                const validVars: boolean = this.validateVars(this.dataMessage.messageContent, this.currentChannelType);
                if (validVars) {
                    valid.push(true);
                } else {
                    valid.push(false);
                }

            } else {
                this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageCharacters);
                valid.push(false);
            }
        } else {
            this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageCharacters2);
            valid.push(false);
        }

        if (this.dataMessage.name) {
            valid.push(true);
        } else {
            this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageMessageName);
            valid.push(false);
        }

        if (this.dataMessage.showHeader) {
            if (this.dataMessage.mediaType === HSMMediaType.TEXT) {
                if (headerTextCount <= 60 && headerTextCount > 0) {
                    valid.push(true);
                } else {
                    this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageHeader);
                    valid.push(false);
                }
            } else if (this.dataMessage.mediaType === HSMMediaType.IMAGE) {
                if (this.dataMessage.mediaContent) {
                    valid.push(true);
                } else {
                    this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageHeaderImage);
                    valid.push(false);
                }
            } else if (this.dataMessage.mediaType === HSMMediaType.DOCUMENT) {
                if (this.dataMessage.mediaContent) {
                    valid.push(true);
                } else {
                    this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageHeaderDoc);
                    valid.push(false);
                }
            } else if (this.dataMessage.mediaType === HSMMediaType.VIDEO) {
                if (this.dataMessage.mediaContent) {
                    valid.push(true);
                } else {
                    this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageHeaderVideo);
                    valid.push(false);
                }
            }
        }

        if (this.dataMessage.showFooter) {
            if (this.dataMessage.footerText) {
                if (footerTextCount <= 60) {
                    valid.push(true);
                } else {
                    this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageFooter);
                    valid.push(false);
                }
            } else {
                this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageFooterRequired);
                valid.push(false);
            }
        }

        return !valid.some((element) => element === false);
    }

    sendToValidate() {
        this.saveHSMMessage((msg) => {
            if (this.existsWhatsappChannel) {
                this.hsmMessageService.validateWhats(this.customerSlug, msg.id, this.whatsappChannelOwnerId, this.currentCustomer.id).subscribe(
                    (res: any) => {
                        // console.log(res);
                        this.dataLayerService.setFlag(this.flagPath, 'send');
                        this.alertService.success(translate.sections.campaigns.validationTemplate.alertMessageSucceeded, {keepAfterRouteChange: true});
                        this.router.navigateByUrl(`/customers/${this.customerSlug}/campaigns/approvalStatus`);
                    },
                    err => {
                        this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageFailured);
                    }
                );
            } else if (this.existsSmoochChannel) {
                this.hsmMessageService.validateSmooch(this.customerSlug, msg.id, this.dataMessage.tenantChannelId, this.currentCustomer.id).subscribe(
                    (res: any) => {
                        // console.log(res);
                        this.dataLayerService.setFlag(this.flagPath, 'send');
                        this.alertService.success(translate.sections.campaigns.validationTemplate.alertMessageSucceeded);
                    },
                    err => {
                        this.alertService.error(translate.sections.campaigns.validationTemplate.alertMessageFailured);
                    }
                );
            }
        });
    }

    getPlaceholder(btnNumber, btnType) {
        if (btnType === HSMButtonType.PAYLOAD) {
            if (btnNumber === 1) {
                return translate.sections.campaigns.validationTemplate.exampleYes;
            } else if (btnNumber === 2) {
                return translate.sections.campaigns.validationTemplate.exampleNot;
            }
        } else if (btnType === HSMButtonType.URL) {
            return translate.sections.campaigns.validationTemplate.example + ': https://gus.chat';
        } else if (btnType === HSMButtonType.PHONE_NUMBER) {
            return translate.sections.campaigns.validationTemplate.example + ': 525556581111';
        } else {
            return '';
        }
    }

    testDataMessage (){
        console.log(this.dataMessage);
    }

    updateCodeExpirationMinutes() {
        if (this.dataMessage.codeExpiryDate) {
            this.dataMessage.codeExpirationMinutes = 1;
        } else {
            this.dataMessage.codeExpirationMinutes = null;
        }
    }

}
