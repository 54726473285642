import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from '../guards/auth.guard';
import { AuthGuardRol } from '../guards/rol.guard';
import { LoginComponent } from '../components/users/login/login.component';
import { SsoComponent } from '../components/users/sso/sso.component';
import { DiagramComponent } from '../components/diagram/diagram.component';
import { ShowCustomersComponent } from '../components/customers/show-customers/show-customers.component';
import { ConfigCustomersComponent } from '../components/customers/config-customers/config-customers.component';
import { FilterCustomersComponent } from '../components/customers/filter-customers/filter-customers.component';
import { FilterTypesComponent } from '../components/customers/filter-types/filter-types.component';

import { InviteComponent } from '../components/customers/invite/invite.component';
import { InvitationsComponent } from '../components/customers/invitations/invitations.component';
import { CustomerComponent } from '../components/customer/customer.component';
import { ShowPlatformsComponent } from '../components/platforms/show-platforms/show-platforms.component';
import { CampaingsComponent } from 'src/components/campaings/campaings.component';
import { ChannelsComponent } from 'src/components/channels/channels.component';
import { HoraryComponent } from 'src/components/horary/horary.component';
import { ConsolesComponent } from 'src/components/consoles/consoles.component';
import { IntercomComponent } from 'src/components/consoles/intercom/intercom.component';
import { SmoochComponent } from 'src/components/consoles/smooch/smooch.component';
import { ConfigComponent } from 'src/components/config/config.component';
import { ChatComponent } from 'src/components/chat/chat.component';
import { BlacklistComponent } from 'src/components/blacklist/blacklist.component';
import { ApiUsersComponent } from 'src/components/api-users/api-users.component';
import { WabaComponent } from 'src/components/waba/waba.component';
import { TicketsComponent } from 'src/components/tickets/tickets.component';
import { SystemUsersComponent } from 'src/components/waba/system-users/system-users.component';
import { ConversationDrivenDevelopmentComponent } from 'src/components/conversation-driven-development/conversation-driven-development.component';
import { BillingComponent } from 'src/components/billing/billing.component';
import { EcommerceComponent } from 'src/components/ecommerce/ecommerce.component';


const routes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'sso',
        component: SsoComponent
    },
    /*{
        path: 'user/superAdmin_da39a3ee5e6b4b0d3255bfef95601890afd80709',
        component: SuperAdminComponent,
        canActivate: [AuthGuard]
    },*/
    {
        path: 'customers',
        component: ShowCustomersComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: null
                }
            ],
            showSteps: true
        }
        // canActivate: [AuthGuardRol]
    },

    {
        path: 'customers/create',
        component: CustomerComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Crear nuevo bot',
                    link: null
                }
            ],
            showSteps: true
        }
        // canActivate: [AuthGuardRol]
    },
    {
        path: 'customers/:customer_slug/edit',
        component: CustomerComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Editar bot',
                    link: null
                }
            ]
        }
        // canActivate: [AuthGuardRol]
    },

    /*{
        path: 'customers/:customer_slug',
        component: ShowCustomersComponent,
        canActivate: [AuthGuard]
    },*/
    {
        path: 'customers/:customer_slug/config',
        component: ConfigCustomersComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Nombre del bot',
                    link: ['/', 'customers']
                }, {
                    label: 'Config',
                    link: null
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/config/blacklist',
        component: BlacklistComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Nombre del bot',
                    link: ['/', 'customers']
                }, {
                    label: 'Config',
                    link: ['/', 'customers']
                }, {
                    label: 'Blacklist',
                    link: null
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/platforms',
        component: ShowPlatformsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Plataformas',
                    link: null
                }
            ],
            showSteps: true
        }
    },
    {
        path: 'customers/:customer_slug/platforms/:platform_slug/channels',
        component: ChannelsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Plataformas',
                    link: ['/', 'customers', ':customer_slug', 'platforms']
                }, {
                    label: 'Canales',
                    link: null
                }
            ],
            showSteps: true
        }
    },
    {
        path: 'customers/:customer_slug/consoles',
        component: ConsolesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Consolas',
                    link: null
                }
            ]
        }
    },
    {
        path: 'console/intercom/callback',
        component: IntercomComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'console/intercom/callback2',
        component: IntercomComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'console/switchboard/callback',
        component: SmoochComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'customers/:customer_slug/channels/:id/horary',
        component: HoraryComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Plataformas',
                    link: ['/', 'customers', ':customer_slug', 'platforms']
                }, {
                    label: 'Horario',
                    link: null
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/config/menu/:id',
        component: ConfigComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Plataformas',
                    link: ['/', 'customers', ':customer_slug', 'platforms']
                }, {
                    label: 'Configurar menú',
                    link: null
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/config/chat/:id',
        component: ChatComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Plataformas',
                    link: ['/', 'customers', ':customer_slug', 'platforms']
                }, {
                    label: 'Configurar chat',
                    link: null
                }
            ],
            showSteps: true
        }
    },

    // route campaigns
    {
        path: 'customers/:customer_slug/campaigns/showCampaigns',
        component: CampaingsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                },
                {
                    label: 'Campañas',
                    link: null
                }
            ]
        }
    },

    {
        path: 'customers/:customer_slug/campaigns/templateValidation',
        component: CampaingsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                },
                {
                    label: 'Validación de plantillas',
                    link: null
                }
            ]
        }
    },

    {
        path: 'customers/:customer_slug/campaigns/templateValidation/:hsm_message_id',
        component: CampaingsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                },
                {
                    label: 'Validación de plantillas',
                    link: null
                }
            ]
        }
    },

    {
        path: 'customers/:customer_slug/campaigns/approvalStatus',
        component: CampaingsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                },
                {
                    label: 'Estatus de aprobación',
                    link: null
                }
            ]
        }
    },

    {
        path: 'customers/:customer_slug/campaigns/sendCampaign',
        component: CampaingsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                },
                {
                    label: 'Envío de campañas',
                    link: null
                }
            ]
        }
    },

    {
        path: 'customers/:customer_slug/api-keys',
        component: ApiUsersComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Llaves de API',
                    link: null
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/platforms/:platform_slug/diagram',
        component: DiagramComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Plataformas',
                    link: ['/', 'customers', ':customer_slug', 'platforms']
                }, {
                    label: 'Diagrama',
                    link: null
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/platforms/:platform_slug/diagram/:diagram_id',
        component: DiagramComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Plataformas',
                    link: ['/', 'customers', ':customer_slug', 'platforms']
                }, {
                    label: 'Diagrama',
                    link: null
                }
            ]
        }
    },
    {
        path: 'invite',
        component: InviteComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'invitations',
        component: InvitationsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/filters',
        component: FilterCustomersComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Filtros',
                    link: null
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/filters/types',
        component: FilterTypesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Filtros',
                    link: ['/', 'customers', ':customer_slug', 'filters']
                }, {
                    label: 'Tipos',
                    link: null
                }
            ]
        }
    },
    {
        path: 'waba',
        component: WabaComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Cuentas de WhatsApp',
                    link: null
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/tickets',
        component: TicketsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Tickets',
                    link: null
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/conversation-driven-development',
        component: ConversationDrivenDevelopmentComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Desarrollo impulsado por la conversación',
                    link: null
                }
            ]
        }
    },
    {
        path: 'customers/:customer_slug/billing',
        component: BillingComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Facturación WhatsApp',
                    link: null
                }
            ]
        }
    },

    {
        path: 'customers/:customer_slug/ecommerce',
        component: EcommerceComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: 'Mis bots',
                    link: ['/', 'customers']
                }, {
                    label: 'Ecommerce',
                    link: null
                }
            ]
        }
    },

    { path: '**', redirectTo: '/customers' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
