<div class="payment-notification" *ngIf="showPaymentNotification">
    <span class="message">{{translate.sections.customers.showCustomers.paymentNotification}}</span>
    <button class="discard" (click)="discard()">{{translate.sections.customers.showCustomers.discard}}</button>
</div>
<section class="sectionCostumers">

    <div class="wrapper-title">
        <h1 class="title">{{translate.sections.customers.showCustomers.myBots}}</h1>
        <ul class="actions">
            <!-- <li>
                <button class="btn-secondary" (click)="redirectWaba()">
                    <span>{{translate.sections.customers.showCustomers.waba}}</span>
                    <i class="material-icons md-24">pending</i>
                </button>
            </li> -->
            <li>
                <button class="btn-secondary" (click)="redirectInvitations()">
                    <span>{{translate.sections.customers.showCustomers.invitations}}</span>
                    <i class="material-icons md-24">pending</i>
                </button>
            </li>
            <li>
                <button class="btn-primary" (click)="redirectAdd()" *ngIf="showBtnNewBot">
                    <span>{{translate.sections.customers.showCustomers.createNewBot}}</span>
                    <i class="material-icons md-24">add_circle</i>
                </button>
            </li>
        </ul>
    </div>

    <div *ngIf="!existsCustomers" class="emptyCustomers">
        <img src="/assets/img/empty_state.png" alt="Bot">
        <h1>{{translate.sections.customers.showCustomers.noBotsYet}}</h1>
        <h4>{{translate.sections.customers.showCustomers.startMessage}}</h4>
    </div>

    <div class="divCustomers">
        <!-- CARD LIST -->
        <div class="card-row">
            <div *ngFor="let customer of customers" class="card-container">
                <div class="card-container-inner">
                    <div class="card-container-inner-box">
                        <a class="box-link-card" [routerLink]="['/customers', customer.slug, 'platforms']">
                            <div class="image-card" [ngStyle]="{'background-image': customer.image ? 'url(' + customer.image + ')' : 'url(/assets/img/customer.jpg)'}"></div>
                            <ul class="platforms">
                                <li *ngFor="let platform of customer?.platforms">
                                    <a [title]="platform.channel.label" (click)="redirectToDiagram('/customers/' + customer.slug + '/platforms/' + platform.channel.name + '/diagram')">
                                        <img data-tippy-content="Ir a diagrama" [src]="assets + 'img/platforms/' + platform.channel.name + '.svg'" [alt]="platform.channel.label">
                                    </a>
                                </li>
                            </ul>
                            <div class="has-paid" *ngIf="!customer.hasPaid" title="Presenta adeudo">
                                <i class="material-icons">credit_card_off</i>
                            </div>
                            <div class="info-card">
                                <span class="subtitle-card ellipsis" title="{{customer.botName}}">
                                    {{customer.botName}}
                                </span>
                                <span class="title-card ellipsis" title="{{customer.name}}">
                                    {{customer.name}}
                                </span>
                            </div>
                        </a>
                        <div class="actions-buttons-bottom">
                            <button *ngIf="customer.existsWhatsapp" class="btn-text" (click)="redirectCampaigns(customer.slug,customer.id)">
                                {{translate.sections.customers.showCustomers.campaign}}
                                <i class="material-icons">campaign</i>
                            </button>
                            <button class="btn-secondary" (click)="openModal('popup-add-guests-customers', customer)" data-tippy-content="Compartir">
                                <i class="material-icons">
                                    person_add_alt_1
                                </i>
                            </button>
                        </div>
                        <button class="actions" (click)="toggleActionsButtons($event)">
                            <i class="material-icons md-24">more_vert</i>
                        </button>
                        <div class="actions-buttons">
                            <ul>
                                <li class="edit">
                                    <!-- (click)="openModal('popup-new-bot', customer.slug)" -->
                                    <a (click)="redirectEdit(customer.slug)">
                                        {{translate.sections.customers.showCustomers.edit}}
                                        <i class="material-icons md24">edit</i>
                                    </a>
                                </li>

                                <li class="consoles">
                                    <a (click)="redirectConsoles(customer.slug)">
                                        {{translate.sections.customers.showCustomers.consoles}}
                                        <i class="material-icons md24">view_quilt</i>
                                    </a>
                                </li>

                                <li class="blacklist">
                                    <a (click)="redirectBlacklist(customer.slug)">
                                        {{translate.sections.customers.showCustomers.blacklist}}
                                        <i class="material-icons md24">block</i>
                                    </a>
                                </li>
                                <li class="apiKeys">
                                    <a (click)="redirectApiKeys(customer.slug)">
                                        {{translate.sections.customers.showCustomers.apiUsers}}
                                        <i class="material-icons md24">vpn_key</i>
                                    </a>
                                </li>
                                <li class="filters">
                                    <a (click)="redirectFilters(customer.slug)">
                                        {{translate.sections.customers.showCustomers.filters}}
                                        <i class="material-icons md24">filter_list</i>
                                    </a>
                                </li>
                                <li class="tickets" *ngIf="isGusUser()">
                                    <a (click)="redirectTickets(customer.slug)">
                                        {{translate.sections.customers.showCustomers.tickets}}
                                        <i class="material-icons md24">confirmation_number</i>
                                    </a>
                                </li>
                                <li class="cdd">
                                    <a (click)="redirectCDD(customer.slug)">
                                        Desarrollo por conversación
                                        <i class="material-icons md24">developer_mode</i>
                                    </a>
                                </li>
                                <li class="billing">
                                    <a (click)="redirectBiling(customer.slug)">
                                        Facturación
                                        <i class="material-icons md24">payments</i>
                                    </a>
                                </li>
                                <li class="ecommerce">
                                    <a (click)="redirectEcommerce(customer.slug)">
                                        {{translate.sections.customers.showCustomers.ecommerce}}
                                        <i class="material-icons md24">storefront</i>
                                    </a>
                                </li>
                                <li class="delete">
                                    <a (click)="deleteCustomer(customer.id, customer.slug)">
                                        {{translate.sections.customers.showCustomers.delete}}
                                        <i class="material-icons md24">delete</i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

 <!-- begin modal add guests -->
 <jw-modal id="popup-add-guests-customers">
    <a class="close_modal" (click)="closeModal('popup-add-guests-customers');">×</a>
    <div class="addGuests">
        <h2 class="title">{{translate.sections.customers.showCustomers.share}}</h2>

        <div class="full">
            <div class="field-box">
                <label for="">{{translate.sections.customers.showCustomers.addGuest}}</label>
                <div class="field-compound">
                    <input #newGuest type="text" placeholder="Ejemplo: gabino@gus.chat" (keyup.enter)="onEnterGuest($event)">
                    <button class="btn-Add-Admins" title="agregar administrador" (click)="addGuestView(newGuest)">
                        <i class="material-icons">add_circle</i>
                    </button>
                </div>
            </div>
            <div class="field-box full">
                <div>
                    <div class="dataConstructorUsers" *ngFor="let guest of guests;let idx=index">
                        <div class="chip" *ngIf="guest.email != superUserMail && guest.email != currentUserMail">
                            <span>{{guest.email}}</span>
                            <button class="btn-del-admin" title="eliminar administrador" (click)="deleteGuestView(idx)">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="guestsPending.length > 0">
                    <hr>
                    <p>{{translate.sections.customers.showCustomers.pendingInvitations}}</p>
                    <div class="dataConstructorUsersPending" *ngFor="let guest of guestsPending;let idx=index">
                        <div class="chip" *ngIf="guest.email != superUserMail && guest.email != currentUserMail">
                            <span>{{guest.email}}</span>
                            <button class="btn-del-admin" title="eliminar administrador" (click)="deleteGuestViewInvite(idx)">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-actions">
            <button (click)="cancelAddGuests()" class="btn-secondary">
                {{translate.sections.customers.showCustomers.cancel}}
            </button>
            <button
                #buttonsendC
                type="submit"
                (click)="addGuests()"
                class="btn-primary">
                {{translate.sections.customers.showCustomers.save}}
            </button>
        </div>
    </div>

</jw-modal>
<!-- end modal create campaign -->
