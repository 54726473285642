<div class="sectionEcommerce">

    <div class="wrapper-title">
        <h1 class="title">{{translate.sections.ecommerce.ecommerce}}</h1>
        <ul class="actions">

            <li>
                <button class="btn-primary" (click)="addEcommerce()">
                    <span>{{translate.sections.ecommerce.createNewEcommerce}}</span>
                    <i class="material-icons md-24">add_circle</i>
                </button>
            </li>
        </ul>
    </div>


    <!-- end list -->

    <div class="container-fluid" [ngStyle]="{'margin-top': '0px'}">
        <div class="divDelete">
        </div>
        <div class="container1" *ngFor="let ecommerce of ecommerces;let i=index;">
            <img (click)="delete(ecommerce)" class="delete" src="../../assets/img/delete.svg" alt="">
            <div class="view">

                <div class="info">
                    <p (click)="openPopupEdit(ecommerce)" class="main-title">{{ecommerce.name}}</p>
                    <p (click)="openPopupEdit(ecommerce)" class="small-title">{{ecommerce.serverUrl}}</p>
                </div>
            </div>

            <div class="options">


                <div class="data">
                    <span *ngIf="ecommerce.isEnabled" class="spanP">{{translate.sections.ecommerce.default}}</span>
                    <div class="onoffswitch">

                        <input [checked]='ecommerce.isEnabled==true'
                            (click)="activateDisableEcommerce(ecommerce.id,ecommerce)" type="checkbox" name="onoffswitch"
                            class="onoffswitch-checkbox" id="{{i}}" tabindex="0">
                        <label class="onoffswitch-label" for="{{i}}"></label>
                    </div>


                </div>

            </div>
        </div>
    </div>



    <!-- begin modal edit -->


    <!-- begin modal create ecommerce -->
    <jw-modal id="popup-edit">
        <a class="close_modal" (click)="closeModal('popup-edit');">×</a>
        <div class="createEcommerce">

            <div>

                <div *ngIf="ecommerce">
                    <h2>{{translate.sections.ecommerce.ecommerceEdit}}</h2>

                        <div class="formGroup">
                            <label for="">{{translate.sections.ecommerce.titleName}}</label>
                            <input id="name" [(ngModel)]="ecommerce.name"
                                placeholder={{translate.sections.ecommerce.ecommerceName}} #name
                                class="inputInspectorStyle" type="text" (change)="onChangeName()">
                        </div>

                        <div class="formGroup">
                            <label for="">{{translate.sections.ecommerce.titleLocation}}</label>
                            <input id="serverUrl" [(ngModel)]="ecommerce.serverUrl"
                                placeholder={{translate.sections.ecommerce.enterLocation}} #serverUrl
                                class="inputInspectorStyle" type="text" (change)="onChangeLocation()">
                        </div>

                        <div class="formGroup">
                            <label for="">consumerKey</label>
                            <input id="consumerKey" [(ngModel)]="ecommerce.consumerKey"
                                placeholder={{translate.sections.ecommerce.ecommerceCK}} #consumerKey
                                class="inputInspectorStyle" type="text" (change)="onChangeKey()">
                        </div>

                        <div class="formGroup">
                            <label for="">consumerSecret</label>
                            <input id="consumerSecret" [(ngModel)]="ecommerce.consumerSecret"
                                placeholder={{translate.sections.ecommerce.ecommerceCS}} #consumerSecret
                                class="inputInspectorStyle" type="text" (change)="onChangeSecret()">
                        </div>

                        <button (click)="loginEcommerce(ecommerce, serverUrl, name)" type="button"
                            class="btn-primary btn-save">{{translate.sections.ecommerce.ecommerceAPI}}</button>

                        <button (click)="saveEcommerce(name, serverUrl, consumerKey, consumerSecret)" type="button"
                            class="btn-primary btn-save">{{translate.sections.ecommerce.accept}}</button>
                </div>
            </div>
        </div>

    </jw-modal>
    <!-- end modal -->
    <!-- end modal -->
</div>
