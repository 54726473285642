
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ModalService } from '../../../components/modal/modal.service';
import { AuthServices } from '../../../services/auth.guard.service';
import { AllowedRolService } from '../../../services/rol-service.service';

import { CustomerService } from '../../../services/customer.service';
import { LoaderService } from '../../../services/loader.service';
import { AlertService } from '../../../services/alert.service';
import { environment } from '../../../environments/environment';
import { DataLayerService } from 'src/services/data-layer.service';

import tippy from 'tippy.js';

declare var translate: any;

@Component({
    selector: 'app-show-customers',
    templateUrl: './show-customers.component.html',
    styleUrls: ['./show-customers.component.scss']
})
export class ShowCustomersComponent implements OnInit {

    @ViewChild('loadImage') imageView: ElementRef;
    @ViewChild("newGuest") newGuest: ElementRef;

    slug: any = '';
    customerId: any = '';
    imgURL: any = '';
    // industries: any = [];
    customers: any = [];
    titleCustomer: string;
    descriptionCustomer: string;
    imageURL: string;

    formFiles: any;

    role: any;

    optionSelected: any;
    typeIndustryValue: any;
    emailsInvited: any = [];
    authorizeRol: boolean;

    urlParameter: any;

    existsCustomers = false;

    tempArr = Array;
    assets = environment.assets;

    guests = [];
    guestsPending = [];
    guestsDeleted = [];
    guestsPendingDeleted = [];
    currentUserMail = '';
    superUserMail = '';
    constUserId = '';

    showPaymentNotification;
    translate: any;
    flagPath: string;

    showBtnNewBot: boolean = false;

    constructor(
        private modalService: ModalService,
        private authServices: AuthServices,
        private loaderService: LoaderService,
        private router: Router,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private customerService: CustomerService,
        private allowedRolService: AllowedRolService,
        private dataLayerService: DataLayerService
    ) {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = '';
    }

    ngOnInit() {
        this.currentUserMail = localStorage.getItem('email');
        this.superUserMail = 'constructor@holagus.com';
        this.constUserId = localStorage.getItem('constructorUserId');

        if (this.currentUserMail.includes('@holagus.com') || this.currentUserMail.includes('@blip.ai')) {
            this.showBtnNewBot = true;
        }

        this.urlParameter = window.location.href;
        this.authServices.verifyToken(JSON.stringify({
            user_id: this.authServices.getIdGoogle(),
            token: this.authServices.getToken(),
            auth_type: localStorage.getItem('Auth-Type')
        })).subscribe(
            (res: any) => {
                // console.log(res);
                this.role = res.role.name;
                // console.log(this.role);

                if (res.status === 'ACTIVE' && res.role.name !== 'CUSTOM') {
                    localStorage.setItem('photo', res.avatar);
                    this.authorizeRol = this.allowedRolService.allowed(res.role.name, 'DEV');
                } else {
                    console.log('logout showcustomer1');
                    this.authServices.logout();
                }

            },
            err => {
                console.log(err);
                console.log('logout showcustomer2');
                this.authServices.logout();
            }
        );

        // this.getTypeOfIndustry();
        this.getListCustomers();

        // this.singIn('GOOGLE');

        // window['dataLayer'].push({
        //     'event': 'pageView',
        //     'section': 'Bots',
        //     'url': window.document.location.href,
        //     'userId': localStorage.getItem('constructorUserId')
        // });
    }

    discard() {
        this.showPaymentNotification = false;
    }

    redirectAdd() {
        this.router.navigate(['/customers', 'create']);
    }

    toggleActionsButtons(event) {
        const actions = event.target.parentElement;
        const actionsButtons = actions.parentElement.querySelector('.actions-buttons');
        document.querySelectorAll('.actions').forEach(function(btn) {
            btn.classList.remove('active');
        });

        if (actionsButtons.classList.contains('fadeIn')) {
            actionsButtons.classList.add('fadeOut');
            setTimeout(() => {
                actionsButtons.classList.remove('fadeIn');
            }, 500);
        } else {
            document.querySelectorAll('.actions-buttons').forEach(function(button) {
                button.classList.remove('fadeIn');
                button.classList.remove('fadeOut');
            });
            actions.classList.add('active');
            actionsButtons.classList.remove('fadeOut');
            actionsButtons.classList.add('fadeIn');
        }
    }


    /*getTypeOfIndustry() {
        this.customerService.getByTypeIndustry().subscribe(
            res => {
                console.log(res);
                this.industries = res;
                // this.loaderService.close();
            },
            err => {
                console.log(err);
            }
        );
    }*/

    getBySlug(slug: string) {
        this.customerService.getBySlug(slug, 'true').subscribe(
            (res: any) => {
                /*this.dataSlug = res;
                console.log('service by slug');
                console.log(this.dataSlug);
                this.customerId = res.tenant.id;
                // console.log(res.tenant.constructorUsers);

                const selectIndustry: any = document.getElementById('industrias');

                for (const option of selectIndustry) {
                    if (option.getAttribute('value') === res.tenant.serviceType.name) {
                        option.setAttribute('selected', 'selected');
                        this.optionSelected = option;
                        // console.log(this.optionSelected);
                    }
                }*/
                // this.loaderService.close();
                this.guests = res.tenant.constructorUsers;
                // console.log(this.guests);
            },
            err => {
                console.log(err);
                this.modalService.close('popup-add-guests-customers');
                // this.dataSlug.tenant = '';
            }
        );
    }

    getInvitedPendingBySlug(slug: string) {
        this.customerService.getInvitedPendingBySlug(slug).subscribe(
            (res: any) => {
                this.guestsPending = res;
                // console.log(this.guestsPending);
            },
            err => {
                console.log(err);
                this.modalService.close('popup-add-guests-customers');
                // this.dataSlug.tenant = '';
            }
        );
    }

    getListCustomers() {
        this.customerService.getByUserId(localStorage.getItem('constructorUserId')).subscribe(
            (res: any) => {
                this.customers = res.tenants;
                if (this.customers.length > 0) {
                    this.existsCustomers = true;
                    for (let i = 0; i < res.tenants.length; i++) {
                        const customer = res.tenants[i];
                        let platforms = [];
                        for (let j = 0; j < customer.platforms.length; j++) {
                            const platform = customer.platforms[j];
                            if (platform.status === 'ACTIVE') {
                                platforms.push(platform);
                                if (platform.channel.channelId.toLowerCase() === 'whatsapp') {
                                    this.customers[i].existsWhatsapp = true;
                                }
                            }
                        }

                        this.customers[i].platforms = platforms;

                        // Paid notification
                        if (customer.hasPaid === false) {
                            this.showPaymentNotification = !customer.hasPaid;
                        }
                    }
                    setTimeout(function(){
                        tippy('[data-tippy-content]', {
                            placement: 'right'
                        });
                    }, 1000);
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    openModal(id: string, customer: any) {
        this.customerId = customer.id;
        this.slug = customer.slug;
        this.modalService.open(id);
        this.getBySlug(customer.slug);
        this.getInvitedPendingBySlug(customer.slug);
    }
    closeModal(id: string) {
        this.modalService.close(id);
        this.guests = [];
        this.guestsPending = [];
        this.customerId = '';
        this.slug = '';
    }

    onEnterGuest(e) {
        this.addGuestView(e.target);
    }

    addGuests() {
        let countEmails = 0;
        let countEmailsErr = 0;
        const emails = [];
        const currentEmail: string = String(this.newGuest.nativeElement.value).toLowerCase();
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (currentEmail && re.test(currentEmail)) {
            this.addGuestView(this.newGuest.nativeElement);
        }

        for (const guest of this.guestsPending) {
            if (!guest.hasOwnProperty('id')) {
                emails.push(guest.email);
            }
        }

        if (emails.length > 0) {
            for (const email of emails) {
                this.customerService.addAdmin(email, this.slug).subscribe(
                    res => {
                        countEmails++;
                        // console.log(res);
                        // this.alertService.success('Se ha agregado el usuario');
                        this.dataLayerService.setFlag(this.flagPath, 'Invitar -> save');
                    },
                    err => {
                        countEmailsErr++;
                        console.log(err);
                        this.alertService.success(translate.sections.customers.showCustomers.updatedErrorMessage + email);
                    }
                );
            }
        }

        const intervalGuests = setInterval(() => {
            if (countEmails === emails.length) {
                this.alertService.success(translate.sections.customers.showCustomers.updatedMessage);
                clearInterval(intervalGuests);
            }
        }, 500);

        /*if (emails.length > 0) {
            this.customerService.addAdmins(this.customerId, emails, this.constUserId, this.slug).subscribe(
                res => {
                    // console.log(res);
                    this.alertService.success('Se ha actualizado la lista de invitados');
                },
                err => {
                    console.log(err);
                }
            );
        }*/

        this.deleteGuests();
    }

    deleteGuests() {
        if (this.guestsDeleted.length > 0) {
            this.customerService.deleteAdmins(this.customerId, this.guestsDeleted, this.constUserId, this.slug).subscribe(
                res => {
                    // console.log(res);
                    this.guestsDeleted = [];
                },
                err => {
                    console.log(err);
                }
            );
        }

        if (this.guestsPendingDeleted.length > 0) {
            this.guestsPendingDeleted.forEach(element => {
                this.rejectInvitation(element.id);
            });

        }
    }

    rejectInvitation(invitationId) {
        this.customerService.rejectInvitation(invitationId).subscribe(
            res => {
                const index = this.guestsPendingDeleted.find(invite => invite.id === invitationId);
                this.guestsPendingDeleted.splice(index, 1);
                this.alertService.success(translate.sections.customers.showCustomers.canceledInvitation);
            },
            err => {
                console.log(err);
            }
        );
    }

    addGuestView(inputEmail) {
        if (inputEmail.value !== '' && !this.guests.some(el => el.email === inputEmail.value)) {
            this.guestsPending.push({ email: inputEmail.value });
        }
        inputEmail.value = '';
        this.dataLayerService.setFlag(this.flagPath, 'Invitar -> email');
    }

    deleteGuestView(idx) {
        this.guestsDeleted.push(this.guests[idx].email);
        this.guests.splice(idx, 1);
    }

    deleteGuestViewInvite(idx) {
        this.guestsPendingDeleted.push(this.guestsPending[idx]);
        this.guestsPending.splice(idx, 1);
    }

    /*
    uploadFile($event) {
        this.formFiles = new FormData();
        this.formFiles.append('document', $event.target.files[0]);
        if (this.slug !== '') {
            this.customerService.uploadImage(this.slug, this.formFiles).subscribe(
                (res: any) => {
                    console.log(res);
                    this.imageURL = res.url;
                    this.imageView.nativeElement.setAttribute('src', this.imageURL);
                    // this.loaderService.close();
                },
                err => {
                    console.log(err);
                }
            );

        } else {

            const file = $event.target.files[0];
            const reader = new FileReader();
            const image = this.imageView;
            reader.onloadend = () => {
                image.nativeElement.setAttribute('src', reader.result);
            };
            if (file) {
                reader.readAsDataURL(file);
            } else {
                this.imageView.nativeElement.setAttribute('src', '');
            }
        }
    }

    // add invited emails
    addUser() {
        this.emailsInvited.push({});
        // console.log(this.emailsInvited);
    }

    // popup

    openModal(id: string, slug: any) {

        console.log('slug', slug);
        if (slug === undefined) {

            this.modalService.open(id);
            this.titleCustomer = 'Perfil de cliente nuevo';
            this.descriptionCustomer = 'Llena los datos para crear un nuevo cliente';

        } else {

            this.modalService.open(id);
            this.slug = slug;
            this.getBySlug(slug);
            this.titleCustomer = 'Perfil para editar cliente';
            this.descriptionCustomer = 'Llena los datos para editar un cliente';
        }
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.dataSlug.tenant = '';
        this.slug = '';
        this.customerId = '';
        this.emailsInvited.length = 0;
        this.imageView.nativeElement.setAttribute('src', '');

        if (this.optionSelected !== undefined) {

            this.optionSelected.removeAttribute('selected');
        }

        this.router.navigateByUrl('/customers');
        this.getListCustomers();

        // this.loaderService.close();
    }

    addCustomer(nameCompany, typeIndustry, botName, website) {

        const emails: any = document.querySelectorAll('.usersInvited input');
        const roles: any = document.querySelectorAll('.usersInvited select');

        console.log(this.slug);

        if (this.slug === '') {
            console.log('add customer');

            // service add customer
            const slug = this.createSlug(nameCompany.value);

            this.customerService.create({
                slug,
                botName: botName.value,
                name: nameCompany.value,
                image: this.imageURL,
                website: website.value
            }).subscribe(
                (responseCreate: any) => {
                    // load service Image
                    this.customerService.uploadImage(responseCreate.slug, this.formFiles).subscribe(
                        (res: any) => {
                            console.log(res);
                            this.imageURL = res.url;
                            const sendCustomerUpdate = {
                                botName: responseCreate.botName,
                                image: res.url,
                                name: responseCreate.name,
                                website: responseCreate.website,
                                serviceType: responseCreate.typeIndustry
                            };
                            this.imageView.nativeElement.setAttribute('src', res.url);
                            this.customerService.update(responseCreate.slug, responseCreate.id, sendCustomerUpdate).subscribe(
                                res2 => {
                                    console.log(res2);
                                    this.loaderService.close();
                                },
                                err => {
                                    console.log(err);
                                }
                            );
                        },
                        err => {
                            console.log(err);
                        }
                    );

                    // this.alertService.success('Cliente creado correctamente');
                    for (const email of emails) {
                        console.log(email.value);
                        this.customerService.addAdmins(responseCreate.id, [email.value],
                            localStorage.getItem('constructorUserId'), this.slug).subscribe(
                                res => {
                                    console.log(res);
                                    this.emailsInvited.length = 0;
                                },
                                err => {
                                    console.log(err);
                                    this.emailsInvited.length = 0;
                                }
                            );
                    }

                    this.closeModal('popup-new-bot');
                    this.alertService.success('Cliente creado correctamente');

                },
                err => {
                    this.alertService.error('error al crear el cliente');
                    console.log(err);
                }
            );


            nameCompany.value = '';
            botName.value = '';
            website.value = '';

        } else if (this.slug !== '') {

            console.log('service edit customer');

            // send to edit
            const objectCustomerEdit = {
                botName: botName.value,
                image: this.imageURL,
                name: nameCompany.value,
                website: website.value,
                serviceType: typeIndustry.value
            };
            // service update customer
            this.customerService.update(this.slug, this.customerId, objectCustomerEdit).subscribe(
                (res: any) => {

                    this.alertService.success('cliente editado');


                    for (const email of emails) {

                        console.log(email.value);
                        this.customerService.addAdmins(res.id, [email.value],
                            localStorage.getItem('constructorUserId'), this.slug).subscribe(
                                res3 => {
                                    this.alertService.success('Administradores Agregados');
                                    console.log(res3);
                                    this.emailsInvited.length = 0;

                                },
                                err => {
                                    console.log(err);
                                    this.emailsInvited.length = 0;
                                }
                            );
                    }


                    this.closeModal('popup-new-bot');

                },
                err => {
                    console.log(err);
                    this.alertService.error('Error al editar bot');

                }
            );

            nameCompany.value = '';
            botName.value = '';
            website.value = '';
        }
    }

    deleteAdmin(email: string) {

        console.log('email to delete');
        console.log(email);

        this.customerService.deleteAdmins(this.customerId, [email], localStorage.getItem('constructorUserId'), this.slug).subscribe(
            res => {
                this.alertService.success('Administrador Eliminado');
                console.log(res);
                this.emailsInvited.length = 0;

                this.getBySlug(this.slug);

            },
            err => {
                console.log(err);
                this.emailsInvited.length = 0;
            }
        );
    }

    deleteInput(index) {
        this.emailsInvited.splice(index, 1);
    }*/

    deleteCustomer(id, slug) {
        this.customerService.deleteCustomer(id, slug).subscribe(
            (res: any) => {
                const idx = this.customers.findIndex((customer) => customer.id === id);
                if (idx > -1) {
                    this.customers.splice(idx, 1);
                    // console.log(this.customers);
                    this.alertService.success(translate.sections.customers.showCustomers.removedBot);
                    this.dataLayerService.setFlag(this.flagPath, 'delete', {slug});
                }
            },
            err => {
                console.log(err);
                this.alertService.error(translate.sections.customers.showCustomers.removedErrorBot);

            }
        );
    }

    redirectInvitations() {
        this.router.navigate(['/invitations']);
    }

    redirectPlatforms(slug: any) {
        this.router.navigate(['/customers', slug, 'platforms']);
    }

    redirectEdit(slug: any) {
        this.router.navigate(['/customers', slug, 'edit']);
    }

    redirectChannels(slug: any, platformSlug) {
        this.router.navigate(['/customers', slug, 'platforms', platformSlug, 'channels']);
    }

    redirectConsoles(slug: any) {
        this.router.navigate(['/customers', slug, 'consoles']);
    }
    redirectBlacklist(slug: any) {
        this.router.navigate(['/customers', slug, 'config', 'blacklist']);
    }
    redirectCampaigns(slug: any, id: any) {
        this.router.navigate(['/customers', slug, 'campaigns', 'showCampaigns']);
    }
    redirectApiKeys(slug: string) {
        this.router.navigate(['/customers', slug, 'api-keys']);
    }
    redirectTickets(slug: string) {
        this.router.navigate(['/customers', slug, 'tickets']);
    }
    redirectConfig() {
        this.router.navigate(['/customers', this.slug, 'menu']);
    }
    redirectFilters(slug: string) {
        this.router.navigate(['/customers', slug, 'filters']);
    }
    redirectToDiagram(url){
        window.location.href = url;
    }

    redirectWaba() {
        this.router.navigate(['/waba']);
    }

    redirectCDD(slug: string) {
        this.router.navigate(['/customers', slug, 'conversation-driven-development']);
    }

    redirectBiling(slug: string) {
        this.router.navigate(['/customers', slug, 'billing']);
    }

    redirectEcommerce(slug: any) {
        this.router.navigate(['/customers', slug, 'ecommerce']);
    }

    isGusUser() {
        let isGus = false;
        const email = localStorage.getItem('email');
        if (email.includes('holagus.com')) {
            isGus = true;
        }
        return isGus;
    }

    createSlug(name: any) {
        const slug = name.toLowerCase().replace(/ /g, '-').replaceAll(/[^0-9a-z_\-]/g, '') + '-' + (new Date().getTime()).toString(36);
        return slug;
    }

    tempArrAux(customersLength: any) {
        // this.tempArr.fill(Math.ceil(customersLength / 3));
        return this.tempArr(Math.ceil(customersLength / 3));
    }

    cancelAddGuests() {
        this.dataLayerService.setFlag(this.flagPath, 'Invitar -> cancel');
        this.closeModal('popup-add-guests-customers');
    }

}
