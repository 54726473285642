import { NgxCookiebotConfig } from '@halloverden/ngx-cookiebot';

export class CookiebotConfig extends NgxCookiebotConfig {
  blockingMode: 'auto' | 'manual' | string = 'auto';
  cbId: string = '6a637e68-e40d-448c-a901-699951d24ee3';
  cdn: 'com' | 'eu' | string = 'com';
  culture?: string;
  framework?: string;
  level?: string;
  loadScript: boolean = true;
  type?: string;
  widgetEnabled?: boolean;
  widgetPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | string;
  widgetDistanceVertical?: number;
  widgetDistanceHorizontal?: number;
}
