export class Entity {
    id?: string;
    name: string;
    entityType?: string;
    endpoint?: string;
    regex?: string[];
    groups?: string[];
    main: boolean;
    type: string;
    state: string;

    regexTxt?: string;
    groupsTxt?: string;
    editing?: boolean;

    constructor() {
        this.editing = false;
        this.regexTxt = '';
        this.groupsTxt = '';
    }
}
