<div class="navbar-left" *ngIf="inDiagrammer">
    <nav>
        <ul>
            <li class="platform">
                <app-list-platforms></app-list-platforms>
            </li>
            <li class="entities">
                <a (click)="showEntities()">
                    <i class="material-icons">article</i>
                    <span>{{translate.sections.navbarLeft.entities}}</span>
                </a>
            </li>
            <!-- <li class="functionalities">
                <a>
                    <i class="material-icons">schema</i>
                    <span>Funcionalidades</span>
                </a>
            </li>
            <li class="table_chart">
                <a>
                    <i class="material-icons">table_chart</i>
                    <span>table_chart</span>
                </a>
            </li>
            <li class="try">
                <a>
                    <i class="material-icons">try</i>
                    <span>Try</span>
                </a>
            </li>
            <li class="school">
                <a>
                    <i class="material-icons">school</i>
                    <span>School</span>
                </a>
            </li>
            <li class="help">
                <a>
                    <i class="material-icons">help</i>
                    <span>Help</span>
                </a>
            </li> -->
            <!-- Habilitar al validación pruébame -->
            <!-- <li *ngIf="this.testingUrl !== ''" class="webchat">
                <a href="{{this.testingUrl}}" target="_blank">
                    <i class="material-icons">play_arrow</i>
                    <span>Probar</span>
                </a>
            </li> -->
        </ul>
    </nav>
</div>

<input type="hidden" class="nlp_entities" [(ngModel)]="nlpEntities">

<!-- Begin Modal entities -->
<jw-modal id="modal-entities" [bodyStyles]="modalBodyStyles">
    <a class="close_modal" (click)="closeModal('modal-entities');">×</a>

    <div class="entities">

        <div class="head clearfix">
            <h2>{{translate.sections.navbarLeft.flowEntities}}</h2>
            <button class="btn-primary" (click)="createEntity()">{{translate.sections.navbarLeft.newEntity}}</button>
        </div>

        <table *ngIf="entities.length > 0">
            <thead>
                <tr>
                    <th>{{translate.sections.navbarLeft.name}}</th>
                    <th>{{translate.sections.navbarLeft.type}}</th>
                    <th>{{translate.sections.navbarLeft.subtype}}</th>
                    <th class="main">{{translate.sections.navbarLeft.main}}</th>
                    <th>{{translate.sections.navbarLeft.actions}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let entity of entities">
                    <td>
                        <span *ngIf="!entity.editing">{{entity.name}}</span>
                        <input type="text" *ngIf="entity.editing" class="entity_name" [(ngModel)]="entity.name">
                        <label *ngIf="entity.editing">{{translate.sections.navbarLeft.regex}}</label>
                        <textarea *ngIf="entity.editing" [(ngModel)]="entity.regexTxt"></textarea>
                    </td>
                    <td>
                        <span *ngIf="!entity.editing">{{entity.entityType}}</span>
                        <select *ngIf="entity.editing" class="entity_name" [(ngModel)]="entity.entityType">
                            <option *ngFor="let entityTypeKey of entityTypesKeys" value="{{entityTypeKey}}" [selected]="entityTypeKey===entity.entityType">
                                {{entityTypeKey}}
                            </option>
                        </select>
                        <label *ngIf="entity.editing">{{translate.sections.navbarLeft.groups}}</label>
                        <textarea *ngIf="entity.editing" [(ngModel)]="entity.groupsTxt"></textarea>
                    </td>
                    <td class="endpoint">
                        <span *ngIf="!entity.editing" class="ellipsis">{{getSubtypeName(entity.type)}}</span>
                        <select *ngIf="entity.editing" class="entity_name" [(ngModel)]="entity.type">
                            <option selected value="entity">{{translate.sections.navbarLeft.entity}}</option>
                            <option [selected]="entity.type==='entity'" value="entity">{{translate.sections.navbarLeft.entity}}</option>
                            <option [selected]="entity.type==='slot'" value="slot">{{translate.sections.navbarLeft.memory}}</option>
                        </select>
                        <label *ngIf="entity.editing">{{translate.sections.navbarLeft.endpoint}}</label>
                        <input type="text" *ngIf="entity.editing" class="entity_endpoint" [(ngModel)]="entity.endpoint">
                    </td>
                    <td class="main">
                        <input *ngIf="entity.editing" type="checkbox" [(ngModel)]="entity.main" (change)="checkValueMain(entity)">
                        <i  *ngIf="!entity.editing && entity.main" class="material-icons">star_rate</i>
                    </td>
                    <td class="btns-actions">
                        <button *ngIf="!entity.editing" class="btn-icon" (click)="editEntity(entity)" title="Editar">
                            <i class="material-icons">edit</i>
                        </button>
                        <button *ngIf="entity.editing" class="btn-icon" (click)="updateEntity(entity)" title="Guardar">
                            <i class="material-icons">save</i>
                        </button>
                        <button class="btn-icon" (click)="deleteEntity(entity)">
                            <i class="material-icons">delete</i>
                        </button>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <!-- <div class="modal-actions">
        <button class="btn-primary" (click)="saveAll()">Guardar todas</button>
    </div> -->
</jw-modal>
<!-- End Modal entities -->
