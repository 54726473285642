export class Customer {

    // OBLIGATORIAS

    id?: string;
    name: string;
    description?: string;
    image: string;
    slug: string;
    active?: boolean;
    website: string;
    botName: string;
    hasPaid?: boolean;
    serviceType?: {
        label: string;
        name: string;
    };

    // TODO: pendiente horarios de atención  (se paso a channels)

    // OPCIONALES

    channels?: any;
    circuitBreakWindowTime?: number;
    countryCode?: string;
    errorCount?: number;
    errorCountWindowTime?: number;
    listMode?: string;
    loadConversations?: boolean;
    operatorTrigger?: string;
    speechToText?: boolean;
    ticketLife?: number;
    transactionEnabled?: boolean;
    transactionWaitTime?: number;
}
