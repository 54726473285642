import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IntercomService } from '../../../services/intercom.service';
import { ConsolesService } from '../../../services/consoles.service';
import { AlertService } from '../../../services/alert.service';

import { environment } from '../../../environments/environment';
declare var translate: any;
@Component({
    selector: 'app-intercom',
    templateUrl: './intercom.component.html',
    styleUrls: ['./intercom.component.scss']
})
export class IntercomComponent implements OnInit {
    translate: any;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private intercomService: IntercomService,
        private consolesService: ConsolesService,
        private alertService: AlertService
    ) { this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000); }

    ngOnInit() {
        if ('code' in this.route.snapshot.queryParams) {
            const code = this.route.snapshot.queryParams.code;
            this.intercomService.getToken(code).subscribe(
                (res: any) => {
                    const token = res.token;
                    this.intercomService.getAdmins(token).subscribe(
                        (res2: any) => {
                            let adminCounter = 0;
                            let adminBotExists = false;
                            res2.admins.forEach(admin => {
                                adminCounter += 1;
                                if (admin.name === 'Bot') {
                                    const customerSlug = localStorage.getItem('currentCustomerSlug');
                                    let consoleLocationExtra = localStorage.getItem('lastConsoleLocation');
                                    consoleLocationExtra = consoleLocationExtra.replace(environment.intercom.appUrl + '/', '');
                                    const chunks = consoleLocationExtra.split('/');
                                    const appId = chunks[2];
                                    this.consolesService.getByAccountId(customerSlug, appId).subscribe(
                                        res3 => {
                                            this.alertService.error(translate.sections.consoles.intercom.intercomMessage);
                                        },
                                        erro => {
                                            const payload = {
                                                isEnabled: false,
                                                consoleType: 'INTERCOM',
                                                consoleLocation: `${environment.intercom.appUrl}/a/apps/${appId}`,
                                                consoleUsername: admin.id,
                                                consoleAccountId: appId,
                                                consoleToken: token,
                                                token
                                            };
                                            const consoleId = localStorage.getItem('lastConsoleId');
                                            this.consolesService.add(customerSlug, payload).subscribe(
                                                (res4: any) => {
                                                    console.log(res4);
                                                    console.log('Se agregó la consola');
                                                    const currentCustomerSlug = localStorage.getItem('currentCustomerSlug');
                                                    this.alertService.success(translate.sections.consoles.intercom.intercomConsole);
                                                    this.consolesService.activate(customerSlug, res4.id, false).subscribe();
                                                    this.consolesService.delete(consoleId, customerSlug).subscribe();
                                                    this.router.navigateByUrl(`/customers/${currentCustomerSlug}/consoles`);
                                                },
                                                err => {
                                                    console.log(err);
                                                    this.alertService.error(translate.sections.consoles.intercom.intercomNoConsole);
                                                }
                                            );
                                        }
                                    );
                                    adminBotExists = true;
                                }
                            });
                            if (!adminBotExists && adminCounter === res2.admins.length) {
                                this.alertService.error(translate.sections.consoles.intercom.intercomAdminUser);
                            }
                        },
                        err => {
                            console.log(err);
                            this.alertService.error(translate.sections.consoles.intercom.intercomNoAdminUser);
                        }
                    );
                },
                err => {
                    console.log(err);
                    this.alertService.error(translate.sections.consoles.intercom.intercomError);
                }
            );
        }
    }

    private generate_random_string(N: number) {
        return Array(N + 1).join((Math.random().toString(36) + '00000000000000000').slice(2, 18)).slice(0, N);
    }
}
