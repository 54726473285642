import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HsmMessageService {

    sizePage: string;

    constructor(private http: HttpClient) {
        this.sizePage = '10';
    }

    // Obtiene los mensajes paginados
    get(slug, page, channelId, customerId) {
        const url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage?size=${this.sizePage}&page=${page - 1}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url, { headers });
    }

    // Obtiene un mensaje por id
    getOne(slug, messageId, channelId, customerId) {
        const url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage/${messageId}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url, { headers });
    }

    // Obtiene los mensajes paginados y filtrados por status
    getByStatus(channelID, status, slug, customerId, page) {
        const url = `/coreapi/v2/tenantChannels/${channelID}/whatsappOutboundMessage?status=${status}&size=10&page=${page - 1}`;

        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url, { headers });
    }

    // Obtiene los mensajes paginados y filtrados por fecha
    getByRangeDate(channelID, year, month, status, slug, customerId, page) {
        let url;
        if (status != 'ALL') {
            url = `/coreapi/v2/tenantChannels/${channelID}/whatsappOutboundMessage/byYearAndMonthAndStatus?year=${year}&month=${month}&status=${status}&size=${this.sizePage}&page=${page - 1}`;
        } else {
            url = `/coreapi/v2/tenantChannels/${channelID}/whatsappOutboundMessage/byYearAndMonthAndStatus?year=${year}&month=${month}&size=${this.sizePage}&page=${page - 1}`;
        }

        /* v1/tenantChannels/222f0ca1-f5c7-4220-9153-1ffc211b88f5/whatsappOutboundMessage/byYearAndMonthAndStatus?year=2021&month=5&status=APPROVED' */

        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url, { headers });
    }

    getByDateSms(channelID, year, month, slug, customerId, page){
        const url = `/coreapi/v2/tenantChannels/${channelID}/whatsappOutboundMessage/byYearAndMonthAndStatus?year=${year}&month=${month}&status=APPROVED`

        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url, { headers });
    }

    getByName(name, slug, channelId, page, customerId) {
        const url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage/byName?name=${name}&size=${this.sizePage}&page=${page - 1}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url, { headers });
    }

    getStatusSmooch(chanelId: string, slug: string, ids: string[], customerId: string) {
        const url = `/coreapi/v2/smooch/channel/${chanelId}/whatsappOutboundMessage/status`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.post(url, { ids }, { headers });
    }
    getSmoochSmsChannel(channelId: string, slug: string, ids: string[], customerId: string){
        const url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage/?status=APPROVED`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url, { headers });
    }
    getStatusWhats(channelOwnerId: string, slug: string, ids: string[], customerId: string) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/hsm`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.post(url, { ids }, { headers });
    }

    getByScopeAndAuthorizationStatus(status, scope, slug, channelId, page, customerId) {
        let url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage/byScopeAndAuthorizationStatus?scope=${scope}&size=${this.sizePage}&page=${page - 1}`;
        if (status !== 'ALL') {
            url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage/byScopeAndAuthorizationStatus?status=${status}&scope=${scope}&size=${this.sizePage}&page=${page - 1}`;
        }
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url, { headers });
    }

    getByNameAndScope(name, scope, slug, channelId, page, customerId) {
        const url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage/byNameAndScope?name=${name}&scope=${scope}&size=${this.sizePage}&page=${page - 1}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url, { headers });
    }

    getByScopeAndYearAndMonthAndStatus(year, month, status, scope, slug, channelId, page, customerId) {
        let url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage/byScopeAndYearAndMonthAndStatus?year=${year}&month=${month}&scope=${scope}&size=${this.sizePage}&page=${page - 1}`;
        if (status !== 'ALL') {
            url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage/byScopeAndYearAndMonthAndStatus?year=${year}&month=${month}&scope=${scope}&status=${status}&size=${this.sizePage}&page=${page - 1}`;
        }
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url, { headers });
    }

    // Crea un mensaje
    create(message, slug, channelId, customerId) {
        const url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.post(url, message, { headers });
    }

    // Mandar a validar un mensaje
    validateSmooch(slug, messageId, channelId, customerId) {
        const url = `/coreapi/v2/smooch/channel/${channelId}/whatsappOutboundMessage/${messageId}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.post(url, {}, { headers });
    }
    validateWhats(slug, messageId, channelOwnerId, customerId) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/hsm/${messageId}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.post(url, {}, { headers });
    }

    // Actualiza un mensaje
    update(message, slug, messageId, channelId, customerId) {
        const url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage/${messageId}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.patch(url, message, { headers });
    }

    // Borra un mensaje por id
    deleteSmooch(slug, messageId, channelId, customerId) {
        const url = `/coreapi/v2/smooch/channel/${channelId}/whatsappOutboundMessage/${messageId}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.delete(url, { headers });
    }

    deleteWhats(slug, messageId, channelOwnerId, customerId) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/hsm/${messageId}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.delete(url, { headers });
    }

    // Confirma un mensaje
    confirm(message, slug, messageId, channelId, customerId) {
        const url = `/coreapi/v2/tenantChannels/${channelId}/whatsappOutboundMessage/${messageId}/confirm`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.post(url, message, { headers });
    }

    getHSMCategories(slug, customerId, includeLegacy=false) {
        const url = `/coreapi/v2/HSMCategories?includeLegacy=${includeLegacy}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': customerId
        });
        return this.http.get(url,{ headers });
    }

    getJSON(): Observable<any> {
        return this.http.get("/assets/emoji.json");
    }

}
