

<div class="col-md-12">
    <div class='channel-time block'>
        <!-- <br> -->

        <div class="wrapper-title">
            <h1 *ngIf="current" class='title'>{{translate.sections.horary.horary}} {{current.tenantChannel.channelOwnerName}}
            </h1>


        </div>

        <!-- <br> -->
        <div class='field-area' *ngIf='loaded'>
            <div class="form-group" >
                <select  class="form-control timezone inputInspectorStyle" [(ngModel)]="timezone" (change)='save()'>
                    <option [value]="tz" *ngFor="let tz of alltz">{{ tz }}</option>
                </select>
            </div>

            <ul class='channel'>
                <li class="group-day" [ngStyle]="{'margin-top': '15px'}" *ngFor="let day of keys">
                  <div class="groupInfo">
                    <div class="dataDays">
                        <input type="checkbox" name="day{{day}}" id="day{{day}}" [checked]="dayCount[day]['present']" (change)='updateDaySelection(dayCount[day]); save(null, null)' >
                        <span class='day-name'>{{dayCount[day]['label']}}</span>
                       </div>
                        <div class="service"  *ngIf="!dayCount[day]['present']">
                            <span class='unavailable'>{{translate.sections.horary.withoutService}}</span>
                        </div>
                        <div *ngIf="dayCount[day]['present']">
                            <div class="info">
                                <div class="data1">
                                    <div class='time-selector from-time'>
                                        <span class="mr-10">{{translate.sections.horary.from}}</span>
                                        <select [disabled]="!dayCount[day]['present']" [(ngModel)]="dayCount[day]['from']['hour']" (change)='save(dayCount[day], "from")'>
                                            <option *ngFor="let m of hours" value="{{m}}">
                                                {{m}}
                                            </option>
                                        </select>
                                        <select [disabled]="!dayCount[day]['present']" [(ngModel)]="dayCount[day]['from']['minute']" (change)='save(dayCount[day], "from")'>
                                            <option *ngFor="let m of minutes"  value="{{m}}">
                                                {{m}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                               <div class="data1">
                                <div class="time-selector to-time">
                                    <span class="mr-10">{{translate.sections.horary.to}}</span>
                                    <select [disabled]="!dayCount[day]['present']" [(ngModel)]="dayCount[day]['to']['hour']" (change)='save(dayCount[day], "to")'>
                                        <option *ngFor="let m of hours"  value="{{m}}">
                                            {{m}}
                                        </option>
                                    </select>
                                    <select [disabled]="!dayCount[day]['present']" [(ngModel)]="dayCount[day]['to']['minute']" (change)='save(dayCount[day], "to")'>
                                        <option *ngFor="let m of minutes"  value="{{m}}">
                                            {{m}}
                                        </option>
                                    </select>
                                </div>
                               </div>
                            </div>
                            <div class='clear'></div>
                        </div>
                  </div>
                </li>
            </ul>
        </div>
    </div>

</div>
