import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConversationDrivenDevelopmentService {

    constructor(private http: HttpClient) { }

    getConversations(slug: string, searchIn: string, search: string, dateStart: string, dateEnd: string) {
        // date format: YYYY-MM-DD
        const url = `/coreapi/v2/cdd/conversations?searchIn=${searchIn}&search=${search}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
        let header = { Slug: slug };
        const headers = new HttpHeaders(header);
        return this.http.get(url, { headers });
    }

    getConversationsCursor(slug: string, cursor:string = null) {
        const url = `/coreapi/v2/cdd/conversations`;
        let header = { Slug: slug };
        const headers = new HttpHeaders(header);
        return this.http.post(url, { cursor }, { headers });
    }

    getMessages(slug: string, ticketId: string, dateStart: string, dateEnd: string, cursor:string = null) {
        // date format: YYYY-MM-DD
        const url = `/coreapi/v2/cdd/conversation/${ticketId}/messages?dateStart=${dateStart}&dateEnd=${dateEnd}`;
        let header = { Slug: slug };
        const headers = new HttpHeaders(header);
        return this.http.get(url, { headers });
    }

    getMessagesCursor(slug: string, ticketId: string, cursor:string = null) {
        const url = `/coreapi/v2/cdd/conversation/${ticketId}/messages`;
        let header = { Slug: slug };
        const headers = new HttpHeaders(header);
        return this.http.post(url, { cursor }, { headers });
    }

    addIncidenceType(slug:string, recordId:string, incidenceType: number) {
        const url = `/coreapi/v2/cdd/conversation/message/${recordId}`;
        let header = { Slug: slug, responseType: 'text' };
        const headers = new HttpHeaders(header);
        return this.http.patch(url, { incidenceType }, { headers });
    }

    downloadConversations(slug: string, searchIn: string, search: string, dateStart: string, dateEnd: string) {
        // date format: YYYY-MM-DD
        const url = `/coreapi/v2/cdd/conversations/download?searchIn=${searchIn}&search=${search}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
        let header = { Slug: slug, 'Content-Type': 'text/plain; charset=utf-8' };
        const headers = new HttpHeaders(header);
        return this.http.get(url, { headers, responseType: 'text' });
    }

    downloadConversationsEmail(slug: string, searchIn: string, search: string, dateStart: string, dateEnd: string) {
        // date format: YYYY-MM-DD
        const url = `/coreapi/v2/cdd/conversations/export?searchIn=${searchIn}&search=${search}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
        let header = { Slug: slug, 'Content-Type': 'text/plain; charset=utf-8' };
        const headers = new HttpHeaders(header);
        return this.http.get(url, { headers });
    }
}
