export class Platform {
    id: string;
    status: string;
    channel: {
        channelId: string;
        label: string;
        name: string;
    };
    show?: boolean;
    active?: boolean;
}
