<section class="md-ui component-data-table phones" *ngIf="phones">
    <div class="main-table-wrapper">
        <table class="main-table-content">
            <thead class="data-table-header">
                <tr class="data-table-row">
                    <td>{{translate['telephoneNumber']}}</td>
                    <td>{{translate['state']}}</td>
                    <td>
                        {{translate['qualityRating']}}
                        <i class="material-icons md-20 help white"
                            data-tippy-content="La calificación de calidad se basa en cómo recibieron los mensajes los destinatarios en los últimos siete días y se pondera por actividad reciente. Se determina mediante una combinación de señales de calidad de conversaciones entre negocios y usuarios, que pueden ser señales de respuesta de los usuarios, como bloqueos, reportes y los motivos que indican cuando bloquean a un negocio.">
                            help
                        </i>
                    </td>
                    <!-- <td>
                        Límite de mensajes
                        <i class="material-icons md-20 help white"
                            data-tippy-content="Cantidad de mensajes de notificación que puedes enviar desde un número de teléfono en un período de 24 horas.">
                            help
                        </i>
                    </td> -->
                    <td>{{translate['country']}}</td>
                    <td>{{translate['name']}}</td>
                    <!-- <td>Estado OPT</td> -->
                    <td>{{translate['registrationStatus']}}</td>
                    <!-- <td>Acciones</td> -->

                </tr>
            </thead>
            <tbody class="data-table-content">
                <!-- , pageBoundsCorrection:pageChanged()  -->
                <tr *ngFor="let phone of phones | paginate: { itemsPerPage: phonesPerPage, currentPage: phonesPage, totalItems:phonesTotal}"
                    [ngClass]="{'data-table-row': true, 'new': phone.new}">
                    <td>
                        <span class="countryFlag">{{getFlagEmoji(phone?.country_code)}}</span>
                        {{phone.display_phone_number}}</td>
                    <td>
                        <span class="material-icons">{{getIconByStatusPhone(phone.status)?.icon}}</span>
                        {{getIconByStatusPhone(phone.status)?.name}}
                    </td>
                    <td>
                        <span class="circle-status {{getIconQualityRating(phone?.quality_rating).key}}"></span>
                        <!-- <span class="circle-status {{getIconQualityScore(phone?.quality_score?.score).key}}"></span> -->
                        {{getIconQualityRating(phone?.quality_rating).name}}
                    </td>
                    <!-- <td>Desconocido</td> -->
                    <td>{{getCountry(phone?.country_code)}}</td>
                    <td>
                        <span class="circle-status {{getIconByStatusNamePhone(phone?.name_status).key}}"></span>
                        {{phone?.verified_name}}
                    </td>
                    <!-- <td>
                        <span class="material-icons">{{getIconByStatusBusiness(phone?.code_verification_status)?.icon}}</span>
                        {{getIconByStatusBusiness(phone?.code_verification_status).name}}
                    </td> -->
                    <td  class="registered">
                        <!-- {{phone?.certificate ? 'Ver certificado' : 'Registrar'}} -->
                        <button *ngIf="!phone?.hasOwner" (click)="openModal('popup-confirm-certificate', phone)" class="btn-secondary-alternate">Registrar</button>
                        <span *ngIf="phone?.hasOwner">
                            Registrado en
                            <a [routerLink]="'/customers/' + phone?.owner?.slug + '/platforms/WHATSAPP/channels'">
                                {{phone?.owner?.name}}
                            </a>
                        </span>
                    </td>
                    <!-- <td>
                        <button (click)="openModal('popup-confirmDelete', campaign.id)" title="Eliminar"
                            class="btn-icon">
                            <i class="material-icons md-20">delete</i>
                        </button>
                        eliminar
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <footer class="main-table-footer">
        <pagination-template #p="paginationApi" (pageChange)="phonesPageUpdate($event)">
            <ul class="pagination">
                <li [ngClass]="{'pagination-previous': true, 'disabled': p.isFirstPage()}">
                    <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
                        << Anterior </a>
                            <span *ngIf="p.isFirstPage()">
                                <span>
                                    << Anterior </span>
                                </span>
                </li>

                <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                    <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                        <span>{{ page.label }}</span>
                    </a>
                    <span *ngIf="p.getCurrent() === page.value">
                        <span>{{ page.label }}</span>
                    </span>
                </li>

                <li [ngClass]="{'pagination-next': true, 'disabled': p.isLastPage()}">
                    <a *ngIf="!p.isLastPage()" (click)="p.next()">
                        Siguiente >>
                    </a>
                    <span *ngIf="p.isLastPage()">
                        <span>Siguiente >></span>
                    </span>
                </li>
            </ul>
        </pagination-template>

    </footer> -->
</section>

<!-- begin modal confirm certificate -->
<jw-modal id="popup-confirm-certificate">
    <a class="close_modal" (click)="closeModal('popup-confirm-certificate');">×</a>
    <div class="createUser">
        <h2 class="title">{{translate['registerNumber']}}</h2>

        <div class="fileds">
            <!-- <div class="field-box">
                <label for="">Metodo de verificación</label>
                <select [(ngModel)]="registerCertMethod">
                    <option value="SMS">SMS</option>
                    <option value="VOICE">Voz</option>
                </select>
            </div>
            <div class="field-box">
                <label for="twoFactorsActive">¿Tienes authenticación de dos factores?</label>
                <input [(ngModel)]="twoFactorsActive" id="twoFactorsActive" type="checkbox">
            </div> -->
            <div class="field-box">
                <label for="pin">{{translate['enterPin']}}</label>
                <input [(ngModel)]="pin" id="twoFactorsPin" maxlength="6" type="text" placeholder="123456">
            </div>
            <div class="modal-actions">
                <button (click)="closeModal('popup-confirm-certificate');" class="btn-secondary">
                    {{translate['cancel']}}
                </button>
                <button (click)="registerPhone()" class="btn-primary">
                    {{translate['register']}}
                </button>
            </div>
        </div>

    </div>

</jw-modal>
<!-- end modal register certificate -->
