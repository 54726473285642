import { Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var translate: any;
@Component({
    selector: 'app-campaings',
    templateUrl: './campaings.component.html',
    styleUrls: ['./campaings.component.scss']
})
export class CampaingsComponent implements OnInit {

    activeTab;
    @Input() itemI: string;

    customerSlug: string;
    translate: any;
    constructor(
        private activateRoute: ActivatedRoute
    ) {
        this.activeTab = {
            showCampaigns: true,
            templateValidation: false,
            approvalStatus: false,
            sendCampaign: false
        };
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    ngOnInit() {
        this.customerSlug = this.activateRoute.snapshot.paramMap.get('customer_slug');
        const urlSegments = this.activateRoute.snapshot.url;
        if (urlSegments.length >= 4) {
            const tab = urlSegments[3].toString();
            this.changeActiveTab(tab);
        } else {
            this.changeActiveTab('showCampaigns');
        }
    }

    changeActiveTab(activeTab) {
        for (const tab of Object.keys(this.activeTab)) {
            if (this.activeTab.hasOwnProperty(tab)) {
                this.activeTab[tab] = false;
            }
        }
        this.activeTab[activeTab] = true;
    }
}
