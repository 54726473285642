<div class="container">
    <div class="container-fluid">

        <div class="wrapper-title">
            <h1 class="title" *ngIf="!customerId">{{translate.sections.customer.createNewBot}}</h1>
            <h1 class="title" *ngIf="customerId">{{translate.sections.customer.editBot}}</h1>
        </div>
        <div class="fields">
            <div class="left">
                <div class="field-box">
                    <label for="">{{translate.sections.customer.companyName}}</label>
                    <input autofocus #nameCompany value="{{dataSlug.tenant ? dataSlug.tenant.name : ''}}" type="text" placeholder={{translate.sections.customer.exampleCompanyName}} (change)="onChangeCompanyName()" [disabled]="customerId">
                </div>
                <div class="field-box">
                    <label for="">{{translate.sections.customer.botName}}</label>
                    <input #botName value="{{dataSlug.tenant ? dataSlug.tenant.botName : ''}}" type="text" placeholder={{translate.sections.customer.exampleChat}} (change)="onChangeBotName()">
                </div>
                <div class="field-box">
                    <label for="">{{translate.sections.customer.industry}}</label>
                    <select #typeIndustry id="industrias" (change)="onChangeIndustry()">
                        <option value="">{{translate.sections.customer.selectIndustry}}</option>
                        <option [selected]="dataSlug?.tenant?.serviceType?.name === industry.name" value="{{industry.name}}" *ngFor="let industry of industries">
                            {{industry.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="fields right">
                <div class="field-box">
                    <label for="">{{translate.sections.customer.url}}</label>
                    <input #website value="{{dataSlug.tenant ? dataSlug.tenant.website : ''}}" type="text" placeholder={{translate.sections.customer.exampleUrl}} (change)="onChangeWebsite()">
                </div>

                <!-- <div class="field-box">
                    <label for="">Imagen de la empresa</label>
                    <div class="divImageUpload">
                        <input hidden type="file" #uploader (change)="uploadFile($event)" />
                        <div id="viewImage">

                            <img #loadImage src="{{dataSlug.tenant ? dataSlug.tenant.image:''}}" alt="Logotipo">

                        </div>
                        <button (click)="uploader.click()" class="btn-primary">Subir</button>
                    </div>
                </div> -->

                <div class="field-box">
                    <label for="">{{translate.sections.customer.companyImage}}</label>
                    <div class="field-box upload-box images" appDnd (fileDropped)="onFileDropped($event)">
                        <div class="files-list" *ngIf="existsImage && files.length === 0">
                            <div class="single-file">
                                <div class="file-icon">
                                    <img src="{{imageURL}}" alt="Imagen de la empresa1">
                                </div>
                                <div class="file-help">
                                    <span class="file-name ellipsis">{{ imageURL }}</span>
                                </div>

                                <div class="file-delete" (click)="deleteFile(0)">
                                    <i class="material-icons md-20">delete_forever</i>
                                </div>
                            </div>
                        </div>
                        <div class="files-list" *ngIf="files.length > 0">
                            <div class="single-file" *ngFor="let file of files; let i = index">
                                <div class="file-icon">
                                    <img [ngClass]="{'hide': !existsImage}" src="{{imageURL}}" alt="Imagen de la empresa2">
                                </div>
                                <div class="file-help">
                                    <span class="file-name">{{ file?.name }}</span>
                                </div>

                                <div class="file-delete" (click)="deleteFile(i)">
                                    <i class="material-icons md-20">delete_forever</i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!existsImage" class="files-upload">
                            <i [ngClass]="{'material-icons': true}">image</i>
                            <input hidden type="file" accept=".jpg, .jpeg, .png" #uploader (change)="fileBrowseHandler($event)" />
                            <strong>{{translate.sections.customer.dragAndDrop}}</strong>
                            <strong>{{translate.sections.customer.o}}</strong>
                            <span>{{translate.sections.customer.click}} <button #buttonUploadC id="buttonUploadFile"  (click)="uploader.click()">{{translate.sections.customer.clickHereToUpload}}</button></span>
                        </div>
                    </div>
                    <span class="description">{{translate.sections.customer.acceptedFormats}}: .jpg  {{translate.sections.customer.and}} .png</span>
                </div>
            </div>
            <div class="full">
                <div class="field-box">
                    <label for="">{{translate.sections.customer.share}}</label>
                    <div class="field-compound">
                        <input #newGuest type="text" placeholder={{translate.sections.customer.exampleChat}} (keyup.enter)="onEnterGuest($event)">
                        <button class="btn-Add-Admins" title="agregar administrador" (click)="addGuestView(newGuest)">
                            <i class="material-icons">add_circle</i>
                        </button>
                    </div>
                </div>
                <div class="field-box full">
                    <div *ngIf="dataSlug.tenant"></div>
                    <div>
                        <div class="dataConstructorUsers" *ngFor="let guest of guests;let idx=index">
                            <div class="chip" *ngIf="guest.email != superUserMail && guest.email != currentUserMail">
                                <span>{{guest.email}}</span>
                                <button class="btn-del-admin" title="eliminar administrador" (click)="deleteGuestView(idx)">
                                    <i class="material-icons">delete_forever</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="full right">
                <!-- <button *ngIf="authorizeRol == true" class="btn-secondary" (click)="redirectConfig()">
                    <span>Configuración avanzada</span>
                    <i class="material-icons">
                        settings
                    </i>
                </button> -->

                <button id="buttonSaveProfile" type="submit" (click)="addCustomer(nameCompany,typeIndustry,botName,website)" class="btn-primary">
                    <span>{{translate.sections.customer.saveProfile}}</span>
                </button>
            </div>

        </div>

    </div>

</div>
