import { Injectable } from '@angular/core';
// import { AlertService } from './alert.service';
import * as joint from '../../../vendor/rappid';
import { ModalService } from './modal.service';

declare var $: any;
declare var Sortable: any;
declare var translate: any;
@Injectable({ providedIn: 'root' })
export class QuickreplyService {

    // private alertService: AlertService;
    private generalQuick: any;
    private modal: ModalService;
    translate: any;
    constructor() {
        this.modal = new ModalService();
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    getQRs(field, req, value, requirementKey) {
        let limitMax = 10;
        let $spanButtonReply: any;
        let padre: any;
        let typeReplys: any = '';
        let phoneValue: any = '';
        let emailValue: any = '';
        let idQr: any = '';
        let titleReplys: any = '';
        let valueTextareaQuick: any = '';
        let indexReplys: any = 0;
        let $divReplys: any;
        let $buttonReply: any;
        let $buttonDelete: any;

        this.generalQuick = {
            quick_replies: []
        };

        const divTitleSlider = $('<div class="mt-10"></div>');
        const labelTitle = $('<span>' + translate.sections.services.title + '</span>');


        const $buttonAddReplys = $('<button class="buttonMenuQuick">+ ' + translate.sections.services.quickReply + '</button>');

        // const $buttonsaveReplys = $('<button class="buttonSaveQuick">Guardar</button>');

        const divMainReplys = $('<div class="mainReplys"></div>');
        divMainReplys.append([$buttonAddReplys]);
        const inputReplys = $('<input type="text" placeholder="'+ translate.sections.services.titlet+ '" class="titleReplys">');
        const fieldsetRadios = $(`<fieldset class="fieldset mt20"></fieldset>`);
        const divReplies = $('<div class="boxReplies"></div>');
        const divRepliesExtra = $('<div class="boxRepliesExtra"></div>');

        divRepliesExtra.append(divReplies);
        divTitleSlider.append([labelTitle, inputReplys]);


        const divRadioPhone = $('<div></div>');

        const inputRadioPhone = $(`<input \
            class="user_phone_number" type="radio" name="type" value="user_phone_number">`);

        const labelRadioPhone = $('<label class="label-radio" for="user_phone_number">' + translate.sections.services.phone + '</label>');


        divRadioPhone.append([inputRadioPhone, labelRadioPhone]);

        const divRadioEmail = $('<div></div>');

        const inputRadioEmail = $('<input class="user_email" type="radio" name="type" value="user_email">');

        const labelRadioEmail = $('<label class="label-radio">Email</label>');

        divRadioEmail.append([inputRadioEmail, labelRadioEmail]);


        const divRadioPostback = $('<div></div>');
        const inputRadioPostback = $('<input class="text" type="radio" name="type" value="text">');
        // const inputRadioPostbackHidden = $('<input class="text" type="radio" name="type" value="text">');

        const labelRadioPostback = $('<label class="label-radio">' + translate.sections.services.conection + '</label>');

        divRadioPostback.append([inputRadioPostback, labelRadioPostback]);

        const divPhoneQR = $('<div class="mt-10"></div>');
        const labelPhoneQR = $('<span>' + translate.sections.services.phone + '</span>');

        const inputPhone = $('<input hidden type="text" placeholder="phone number" class="inputPhone">');


        divPhoneQR.append([labelPhoneQR, inputPhone]);

        divPhoneQR.hide();


        const divEmailQR = $('<div class="mt-10"></div>');

        const labelEmailQR = $('<span>Email</span>');

        const inputEmail = $('<input hidden type="text" placeholder="email" class="inputEmail">');


        divEmailQR.append([labelEmailQR, inputEmail]);

        divEmailQR.hide();

        const inputIdHidden = $('<input type="hidden" name="id" class="id">');

        const textareaQuick = $('<textarea placeholder="' + translate.sections.services.swipeTo + '" cols="2" rows="2" class="escape"></textarea>');

        const $containerDiv = $('<div class="type_visual_box replies"/>');

        const chunksQRFieldName = field.attr('name').split('#');

        const btnSaveQuick = $('<button class="buttonSaveQuick">Guardar</button>');
        const ValidateEmail = (mail) => {
            if (/\S+@\S+\.\S+/.test(mail)) {
                return true;
            }
            return false;
        };

        const ValidatePhone = (phone) => {
            if (/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(phone)) {
                return true;
            }
            return false;
        };

        textareaQuick.on('keyup', (e) => {
            this.generalQuick.text = e.target.value;
            const responseQRString2 = this.createResponseQRString(this.generalQuick, req);
            field.val(responseQRString2);
        });

        inputPhone.on('keyup', () => {
            // console.log('event keyup');
            if (ValidatePhone(inputPhone.val())) {
                inputPhone.removeClass('invalid-input');
                inputPhone.addClass('validate-input');
            } else {
                inputPhone.removeClass('validate-input');
                inputPhone.addClass('invalid-input');
            }
        });

        inputEmail.on('keyup', (e) => {
            // console.log('input email');
            if (ValidateEmail(e.target.value)) {
                inputEmail.removeClass('invalid-input');
                inputEmail.addClass('validate-input');
            } else {
                inputEmail.removeClass('validate-input');
                inputEmail.addClass('invalid-input');
            }
        });

        const modal = this.modal.create({
            onClose: (eClose, showOptions) => {
                inputReplys.val('');
                inputEmail.val('');
                inputPhone.val('');

                inputRadioPostback.show();
                inputRadioPhone.show();
                inputRadioEmail.show();
                divPhoneQR.hide();
                divEmailQR.hide();

                $('input[name="type"]').prop('checked', false).removeAttr('checked');
            }
        });

        const titleQuick = $(`<p class="titleQuick" >` + translate.sections.services.quickReply + `</p>`);

        modal.box.append(titleQuick);

        modal.box.append(divTitleSlider);
        modal.box.append(fieldsetRadios);
        modal.box.append(divPhoneQR);
        modal.box.append(divEmailQR);
        modal.box.append(inputIdHidden);
        modal.box.append(btnSaveQuick);

        $('body').append([modal.box, modal.blocker]);

        btnSaveQuick.on('click', (eClose) => {

            // console.log('onclose modal2 quick');
            eClose.preventDefault();
            eClose.stopPropagation();
            // console.log(indicequick);
            // console.log(indexReplys);
            typeReplys = $(eClose.target).parent().find('input[name="type"]:checked').val();
            titleReplys = $(eClose.target).parent().find('.titleReplys').val();
            emailValue = $(eClose.target).parent().find('.inputEmail').val();
            phoneValue = $(eClose.target).parent().find('.inputPhone').val();
            idQr = $(eClose.target).parent().find('.id').val();
            valueTextareaQuick = textareaQuick.val();


            // console.log(`data test ${phoneValue}`);

            if (idQr === '') {
                idQr = joint.util.uuid(); // Date.now();
            }

            const arrayReplysSaved = [];

            try {
                if (titleReplys !== '') {

                    const findDivsReplys = $containerDiv.find('.divReply');
                    findDivsReplys.each((e) => {
                        if (findDivsReplys.eq(e).parent()[0] === padre[0]) {
                            arrayReplysSaved.push(findDivsReplys.eq(e));
                        }
                    });
                    // console.log('value arrayreply');
                    // console.log(arrayReplysSaved);

                    arrayReplysSaved[+indexReplys].children().eq(0).text(titleReplys);

                }

            } catch (error) { }

            const objectReply: any = {
                content_type: typeReplys,
                title: titleReplys,
                value: '',
                id: idQr,
                block_id: idQr
            };

            if (phoneValue !== '') {
                objectReply.value = phoneValue;
                divPhoneQR.hide();
                inputPhone.hide();
            } else {
                objectReply.value = emailValue;
                inputEmail.hide();
                divEmailQR.hide();
            }

            if (typeReplys === 'user_phone_number' || typeReplys === 'user_email') {
                objectReply.payload = '';
            }

            this.generalQuick.quick_replies[+indexReplys] = objectReply;
            this.generalQuick.text = valueTextareaQuick;

            // console.log('json qr 0', JSON.stringify(generalQuick.quick_replies[0]))
            // console.log('json qr 1', JSON.stringify(generalQuick.quick_replies[1]))

            const responseQRString5 = this.createResponseQRString(this.generalQuick, req);
            field.val(responseQRString5);

            // console.log('value input hidden', field.val());

            // check
            // const spanReplies = showOptions.from.find('.spanButtonReply');
            // spanReplies.each(replyIndex => {
            //     if ($(this).text() === indexReplys) {
            //         $(this).siblings('.buttonBlock').text(titleReplys);
            //     }
            // });

            inputReplys.val('');
            inputEmail.val('');
            inputPhone.val('');

            inputRadioPostback.show();
            inputRadioPhone.show();
            inputRadioEmail.show();
            divPhoneQR.hide();
            divEmailQR.hide();

            $('input[name="type"]').prop('checked', false).removeAttr('checked');

            const widthDivReplies = this.calculateWidthDivReplies($containerDiv);
            if (widthDivReplies > 0) {
                $containerDiv.find('.boxReplies').width(widthDivReplies);
            }

            modal.hide();

        });

        labelRadioPhone.on('click', (e) => {
            inputRadioPhone.prop('checked', true);
            if (inputRadioPhone.is(':checked')) {
                divPhoneQR.show();
                inputPhone.show();
                inputEmail.val('');
                inputEmail.css('outline', 'none');
                inputEmail.hide();
                divEmailQR.hide();
            }
        });

        inputRadioPhone.on('click', (e) => {
            if ($(e.target).is(':checked')) {
                divPhoneQR.show();
                inputPhone.show();
                inputEmail.val('');
                inputEmail.css('outline', 'none');
                inputEmail.hide();
                divEmailQR.hide();
            }
        });


        labelRadioEmail.on('click', (e) => {
            inputRadioEmail.prop('checked', true);
            if (inputRadioEmail.is(':checked')) {
                divEmailQR.show();
                inputEmail.show();
                inputPhone.val('');
                inputPhone.css('outline', 'none');
                inputPhone.hide();
                divPhoneQR.hide();
            }
        });

        inputRadioEmail.on('click', (e) => {
            if ($(e.target).is(':checked')) {
                divEmailQR.show();
                inputEmail.show();
                inputPhone.val('');
                inputPhone.css('outline', 'none');
                inputPhone.hide();
                divPhoneQR.hide();
            }
        });

        const responseQRString = this.createResponseQRString(this.generalQuick, req);
        field.val(responseQRString);



        labelRadioPostback.on('click', (e) => {
            inputRadioPostback.prop('checked', true);
            if (inputRadioPostback.is(':checked')) {
                divPhoneQR.hide();
                inputPhone.hide();
                inputEmail.hide();
                divEmailQR.hide();
                inputPhone.val('');
                inputEmail.val('');
            }
        });

        inputRadioPostback.on('click', (e) => {
            if ($(e.target).is(':checked')) {
                divPhoneQR.hide();
                inputPhone.hide();
                inputEmail.hide();
                divEmailQR.hide();
                inputPhone.val('');
                inputEmail.val('');
            }
        });

        const nameClass = field.attr('name').replace('#', '-');
        $containerDiv.addClass(nameClass);

        $containerDiv.append([textareaQuick, divMainReplys, field, divRepliesExtra]);

        $containerDiv.data('result', value);


        $buttonAddReplys.on('click', (e) => {

            $divReplys = $('<div class="divReply"></div>');
            $buttonDelete = $('<a class="btnDelete">×</a>');
            $buttonReply = $(`<a class="buttonBlock show_modal"></a>`);
            $spanButtonReply = $(`<span hidden class="spanButtonReply"></span>`);

            $divReplys.append($buttonReply);
            $divReplys.append($buttonDelete);
            $divReplys.append($spanButtonReply);

            const btnLen = $containerDiv.find('.divReply').length;

            const pathUrl = document.location.pathname;
            if (pathUrl.includes('/platforms/SMOOCH_APP/diagram')) {
                limitMax = 15;
            }

            if (btnLen < limitMax) {


                $buttonReply.text(`Botón ${btnLen + 1}`);
                $spanButtonReply.text(btnLen);
                divReplies.append($divReplys);

                $buttonReply.on('click', (eventButtonReply) => {
                    // console.log('show modal');
                    padre = $(eventButtonReply.target).parent().parent();
                    modal.show({ from: padre });
                    // console.log('%c parent element case qr ', 'background: purple; color: white;', padre[0]);
                    // console.log(padre[0]);
                    indexReplys = $(eventButtonReply.target).siblings('.spanButtonReply').text();
                    // console.log(indexReplys);

                    try {
                        const dataButtonQuick = this.generalQuick.quick_replies[+indexReplys];
                        inputReplys.val(dataButtonQuick.title);

                        if (dataButtonQuick.content_type === 'text') {
                            inputRadioPostback.prop('checked', 'true');
                        } else if (dataButtonQuick.content_type === 'user_phone_number') {
                            inputRadioPhone.prop('checked', 'true');
                            divPhoneQR.show();
                            inputPhone.show();
                            inputPhone.val(dataButtonQuick.value);
                        } else if (dataButtonQuick.content_type === 'user_email') {
                            inputRadioEmail.prop('checked', 'true');
                            inputEmail.show();
                            divEmailQR.show();
                            inputEmail.val(dataButtonQuick.value);
                        }
                    } catch (error) { }

                });
            }

            // delete button reply
            $buttonDelete.on('click', (eventDeleteReply) => {
                const parentDiv = $(eventDeleteReply.target).parent();
                // console.log('index to delete');
                const indexDeleteArrayReplys = $(eventDeleteReply.target).siblings().eq(1).text();
                // console.log(indexDeleteArrayReplys);

                parentDiv.fadeOut('fast', () => {
                    parentDiv.remove();
                    $(eventDeleteReply.target).remove();
                    const parentContainerR: any = document.querySelector('.boxReplies').children;

                    for (let index = 0; index < parentContainerR.length; index++) {
                        parentContainerR[index].children[2].textContent = index;
                    }

                });

                this.generalQuick.quick_replies.splice(indexDeleteArrayReplys, 1);
                this.generalQuick.text = valueTextareaQuick;
                const responseQRString4 = this.createResponseQRString(this.generalQuick, req);
                field.val(responseQRString4);
            });

        });

        fieldsetRadios.append(divRadioPhone);
        fieldsetRadios.append(divRadioEmail);
        fieldsetRadios.append(divRadioPostback);

        const boxC1 = $containerDiv.find('.boxReplies');
        const sortable = Sortable.create(boxC1.get(0), {
            onEnd: (evt) => {
                // console.log('onend');
                const orderArrayG = this.arraymove(this.generalQuick.quick_replies, evt.oldIndex, evt.newIndex);
                this.generalQuick.quick_replies = orderArrayG;
                // console.log('value order array ', this.generalQuick.quick_replies);
                evt.target.children[0].children[2].textContent = evt.newIndex;

                for (let index = 0; index < evt.target.children.length; index++) {
                    evt.target.children[index].children[2].textContent = index;


                }

                const responseQRString5 = this.createResponseQRString(this.generalQuick, req);
                // console.log(responseQRString5);
                field.val(responseQRString5);
                // console.log('value asiggned to input hidden', field.val());

            }
        });

        // Begin manage constraints

        try {
            textareaQuick.text(req.constraints.message.value);
            switch (req.constraints.message.type) {
                case 'fixed':
                    textareaQuick.attr('disabled', 'disabled');
                    break;
                case 'suggest':
                    // nothing to do, for now
                    break;
            }

            switch (req.constraints.quick_replies.type) {
                case 'fixed':
                    $buttonAddReplys.hide();
                    break;
                case 'addFixed':
                case 'suggest':
                    // nothing to do, for now
                    break;
            }
        } catch (e) { }

        if (value === undefined) {
            if (req.type === 'jsonQR') {
                try {
                    this.generalQuick.text = req.constraints.message.value;
                    const idQr2 = joint.util.uuid();
                    req.constraints.quick_replies.value.forEach(qr => {
                        this.generalQuick.quick_replies.push({
                            content_type: qr.content_type.value,
                            title: qr.title.value,
                            value: '',
                            id: idQr2,
                            block_id: idQr2
                        });
                    });

                    const responseQRStringPre = this.createResponseQRString(this.generalQuick, req);
                    field.val(responseQRStringPre);

                    value = {};
                    const aux = {};
                    aux[chunksQRFieldName[1]] = {
                        value: responseQRStringPre
                    };
                    value[chunksQRFieldName[0]] = aux;
                } catch (e) { }
            }
        }

        // End manage constraints

        if (value !== undefined) {

            // console.log('show value qr');
            // console.log('%c value ', 'background: #007E33; color: white', value);
            Object.keys(value).forEach(key => {
                if ('req_name' !== key && '' !== key) {
                    const fieldValue = value[key];
                    Object.keys(fieldValue).forEach(key2 => {
                        const fv = fieldValue[key2];
                        let json;
                        try{
                            json = JSON.parse(fv.value);
                        } catch (e) { }

                        if (json && json.type === 'jsonQR') {


                            // console.log('%c value json parsed ', 'background: yellow; color: red', json);


                            const parentDiv = $buttonAddReplys.parent().parent();

                            try {
                                if (req.constraints.message.type !== 'fixed') {
                                    parentDiv.find('textarea').val(json.text);
                                }
                            } catch (e) {
                                parentDiv.find('textarea').val(json.text);
                            }

                            this.generalQuick.text = json.text;

                            // console.log('array entrante', json.quick_replies);
                            for (let index2 = 0; index2 < json.quick_replies.length; index2++) {
                                this.generalQuick.quick_replies = json.quick_replies;


                                $divReplys = $('<div class="divReply"></div>');
                                $buttonReply = $('<a class="buttonBlock show_modal"></a>');
                                $spanButtonReply = $('<span hidden class="spanButtonReply"></span>');

                                const $buttonDelete2 = $('<a class="deleteReply btnDelete">×</a>');
                                $divReplys.append($buttonReply);
                                $divReplys.append($buttonDelete2);
                                $divReplys.append($spanButtonReply);

                                $spanButtonReply.text(index2);
                                $buttonReply.text(json.quick_replies[index2].title);
                                divReplies.append($divReplys);

                                try {
                                    if (
                                        (req.constraints.quick_replies.type === 'fixed' ||
                                            req.constraints.quick_replies.type === 'addFixed') &&
                                        index2 + 1 <= req.constraints.quick_replies.value.length
                                    ) {
                                        $buttonDelete2.remove();
                                    }
                                } catch (e) { }

                                $buttonReply.on('click', (eventButtonReplyloaded) => {
                                    // console.log('click button reply in');
                                    padre = $(eventButtonReplyloaded.target).parent().parent();
                                    modal.show({ from: padre });
                                    indexReplys = $(eventButtonReplyloaded.target).siblings('.spanButtonReply').text();
                                    // console.log(indexReplys);
                                    // blockerDivReplys.show();
                                    // modalDivReplys.show();

                                    inputReplys.val(json.quick_replies[indexReplys].title);
                                    inputIdHidden.val(json.quick_replies[indexReplys].id);
                                    const radioCheck = json.quick_replies[indexReplys].content_type;
                                    inputRadioPostback.prop('checked', 'false');
                                    inputRadioPhone.prop('checked', 'false');
                                    inputRadioEmail.prop('checked', 'false');

                                    if (radioCheck === 'text') {
                                        inputRadioPostback.prop('checked', 'true');
                                    } else if (radioCheck === 'user_phone_number') {
                                        inputRadioPhone.prop('checked', 'true');
                                        divPhoneQR.show();
                                        inputPhone.show();
                                        inputPhone.val(json.quick_replies[indexReplys].value);
                                    } else if (radioCheck === 'user_email') {
                                        inputRadioEmail.prop('checked', 'true');
                                        inputEmail.show();
                                        divEmailQR.show();
                                        inputEmail.val(json.quick_replies[indexReplys].value);
                                    }

                                    try {
                                        if ((+indexReplys + 1) <= req.constraints.quick_replies.value.length) {
                                            if (req.constraints.quick_replies.value[+indexReplys].title.type === 'fixed') {
                                                inputReplys.attr('disabled', 'disabled');
                                            }
                                            if (req.constraints.quick_replies.value[+indexReplys].content_type.type === 'fixed') {
                                                inputRadioPostback.hide();
                                                inputRadioPhone.hide();
                                                inputRadioEmail.hide();
                                            }
                                        }

                                    } catch (e) { }
                                });
                                $buttonDelete2.on('click', (eventButtonDelete2) => {
                                    const parentDiv2 = $(eventButtonDelete2.target).parent();
                                    const indexDeleteArrayReplys = $(eventButtonDelete2.target).siblings().eq(1).text();

                                    $(parentDiv2).fadeOut('fast', () => {
                                        parentDiv2.remove();
                                        $(eventButtonDelete2.target).remove();

                                        const parentContainerR: any = document.querySelector('.boxReplies').children;

                                        for (let index = 0; index < parentContainerR.length; index++) {
                                            parentContainerR[index].children[2].textContent = index;
                                        }
                                    });
                                    this.generalQuick.text = json.text;
                                    this.generalQuick.quick_replies.splice(indexDeleteArrayReplys, 1);

                                    // console.log('check delete button qr');
                                    // console.log(JSON.stringify(generalQuick));

                                    // generalQuick.quick_replies[+indexReplys] = objectReply;
                                    // generalQuick.text = valueTextareaQuick;

                                    // console.log('json qr', JSON.stringify(generalQuick))


                                    const responseQRString2 = this.createResponseQRString(this.generalQuick, req);
                                    field.val(responseQRString2);
                                });
                            }

                            const responseQRString5 = this.createResponseQRString(this.generalQuick, req);
                            field.val(responseQRString5);
                        }
                    });
                }

            });
        }

        const interval = setInterval(() => {
            const widthDivReplies = this.calculateWidthDivReplies($containerDiv);
            if (widthDivReplies > 0) {
                $containerDiv.find('.boxReplies').width(widthDivReplies);
                clearInterval(interval);
            }
        }, 500);

        return $containerDiv;
    }

    private createResponseQRString(generalQuick, req) {
        const responseQR = {
            quick_replies: generalQuick.quick_replies,
            text: generalQuick.text,
            type: 'jsonQR',
            showOutputs: true
        };

        if (
            req.constraints &&
            typeof req.constraints === 'object' &&
            'type' in req.constraints
        ) {
            if ('noOutputs' === req.constraints.type) {
                responseQR.showOutputs = false;
            }
        }
        return JSON.stringify(responseQR);
    }

    private calculateWidthDivReplies($containerDiv) {
        let divRepliesWidth = 0;
        $containerDiv.find('.boxReplies').children().each((idx, elem) => {
            divRepliesWidth += (parseFloat($(elem).outerWidth()));
        });
        return divRepliesWidth;
    }

    private arraymove(arr, OldIndex, NewIndex) {
        const element = arr[OldIndex];
        arr.splice(OldIndex, 1);
        arr.splice(NewIndex, 0, element);
        return arr;
    }

}
