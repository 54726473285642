import { Component, OnInit } from '@angular/core';
import { ConsolesService } from '../../services/consoles.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '../../components/modal/modal.service';
import { environment } from '../../environments/environment';
import { DataLayerService } from 'src/services/data-layer.service';
import tippy from 'tippy.js';

declare var translate: any;
@Component({
    selector: 'app-consoles',
    templateUrl: './consoles.component.html',
    styleUrls: ['./consoles.component.scss']
})
export class ConsolesComponent implements OnInit {
    urlParameter: any;
    intercomUrl;
    consoleSelect: any;
    slug: any;
    consola: any;
    consoles: any = [];
    typesConsoles: any = [];
    routeImageChannel = './../assets/img/channels/';
    defaultImage = './../assets/img/channels/puzzle.svg';
    headers: any = [];
    zendeskUrl: any = false;
    translate: any;
    flagPath: string;

    constructor(
        private consoleService: ConsolesService,
        private activate: ActivatedRoute,
        private modalService: ModalService,
        private dataLayerService: DataLayerService
    ) {
        this.intercomUrl = this.getIntercomUrl();
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = 'Consoles -> ';
    }

    ngOnInit() {
        this.urlParameter = window.location.href;
        const parameters = this.activate.snapshot.params;

        if (parameters.customer_slug) {
            this.slug = parameters.customer_slug;
            localStorage.setItem('currentCustomerSlug', parameters.customer_slug);
            this.get(parameters.customer_slug);

            this.consoleService.types(this.slug).subscribe(
                res => {
                    this.typesConsoles = res;


                },
                err => {
                    console.log(err);

                }
            );
            this.dataLayerService.setFlag(this.flagPath, 'start');
        }
    }

    getIntercomUrl() {
        return this.intercomUrl = `${environment.intercom.appUrl}/oauth?client_id=${environment.intercom.clientId}&state=${environment.intercom.state}`;
    }

    addConsola() {

        const uuid: any = Date.now().toString();
        const timestamp = uuid.substring(uuid.length, uuid.length - 5);
        const object = {
            isNew: true,
            consoleLocation: 'http://new.gus.chat',
            isEnabled: false,
            consoleAccountId: `${this.slug}-${timestamp}`,
            consoleType: 'UNDEFINED'
        };

        this.consoleService.add(this.slug, object).subscribe(
            res => {
                this.get(this.slug);
                this.dataLayerService.setFlag(this.flagPath, 'create new');
            },
            err => {
                console.log(err);
            }
        );

    }



    get(slug) {
        this.consoleService.get(slug).subscribe(
            res => {
                this.consoles = res;
                let envStr = '-' + environment.name;
                if (environment.name === 'pre') {
                    envStr = 'predev';
                } else if (environment.name === 'prod') {
                    envStr = '';
                }
                this.consoles.forEach(elem => {
                    if (elem.consoleType === 'ZENDESK_SUPPORT_CONSOLE') {
                        this.zendeskUrl = `https://zd${envStr}.ston.ai/supportWebhook/tenant/${this.slug}/console/${elem.id}`;
                    }
                });
                setTimeout(() => {
                    tippy('.copy', {
                        content: 'Copiado',
                        trigger: 'click',
                    });
                });
            },
            err => {
                console.log(err);
            }
        );
    }

    delete(consola) {
        this.consoleService.delete(consola.id, this.slug).subscribe(
            res => {
                this.get(this.slug);
                this.dataLayerService.setFlag(this.flagPath, 'delete');
            },
            err => {
                console.log(err);

            }
        );

    }

    changeConsole(type) {
        this.consoleSelect = type.value;

        if (type.value === 'INTERCOM') {
            this.consola.consoleLocation = '';
        }
        if (type.value === 'THIRD') {
            this.headers = [];
        }

        this.dataLayerService.setFlag(this.flagPath, 'edit -> type');

    }


    saveConsoleFresh(type, consoleLocation, consoleAccountId, token) {
        const objeto = {

            consoleLocation: consoleLocation.value,
            consoleType: type.value,
            consoleAccountId: consoleAccountId.value,
            token: token.value,
            consoleToken: token.value,
        };

        this.consoleService.update(this.slug, this.consola.id, objeto).subscribe(
            res => {
                this.get(this.slug);
                this.modalService.close('popup-edit');
                this.dataLayerService.setFlag(this.flagPath, 'edit -> accept');
            },
            err => {
                console.log(err);

            }

        );


    }

    saveConsoleZSC(type, consoleLocation, consoleAccountId, consoleName, token) {
        const objeto = {

            consoleLocation: consoleLocation.value,
            consoleType: type.value,
            consoleAccountId: consoleAccountId.value,
            token: token.value,
            consoleToken: token.value,
            consoleUsername: consoleName.value
        };

        this.consoleService.update(this.slug, this.consola.id, objeto).subscribe(
            res => {
                this.get(this.slug);
                this.modalService.close('popup-edit');
                this.dataLayerService.setFlag(this.flagPath, 'edit -> accept');
            },
            err => {
                console.log(err);

            }

        );


    }

    saveConsole(type, consoleLocation, consoleAccountId) {
        const objeto = {

            consoleLocation: consoleLocation.value,
            consoleType: type.value,
            consoleAccountId: consoleAccountId.value,
        };

        this.consoleService.update(this.slug, this.consola.id, objeto).subscribe(
            res => {
                this.get(this.slug);
                this.modalService.close('popup-edit');
                this.dataLayerService.setFlag(this.flagPath, 'edit -> accept');
            },
            err => {
                console.log(err);

            }
        );

        // console.log(` type ${type.value} location: ${consoleLocation.value}, acoonunt: ${consoleAccountId.value}`);

    }

    saveConsoleS(type) {

        const objeto = {
            consoleType: type.value
        };
        localStorage.setItem('lastConsoleId', this.consola.id);
        localStorage.setItem('currentCustomerSlug', this.slug);
        this.consoleService.update(this.slug, this.consola.id, objeto).subscribe(
            res => {
                this.dataLayerService.setFlag(this.flagPath, 'edit -> accept');
                this.get(this.slug);
                this.modalService.close('popup-edit');
                const redirect = encodeURIComponent(`${environment.baseUrl}console/switchboard/callback`);
                document.location.href = `https://app.smooch.io/oauth/authorize?client_id=gus&response_type=code&redirect_uri=${redirect}`;
            },
            err => {
                console.log(err);

            }
        );

    }

    saveConsoleZC(type, consoleLocation, consoleAccountId) {


        // console.log(` type ${type.value} location: ${consoleLocation.value}, acoonunt: ${consoleAccountId.value}`);


    }
    saveConsoleI(type, consoleLocation) {

        const objeto = {
            consoleLocation: consoleLocation.value,
            consoleType: type.value
        };

        localStorage.setItem('lastConsoleId', this.consola.id);
        localStorage.setItem('lastConsoleLocation', consoleLocation.value);

        this.consoleService.update(this.slug, this.consola.id, objeto).subscribe(
            res => {
                this.dataLayerService.setFlag(this.flagPath, 'edit -> accept');
                this.get(this.slug);
                this.modalService.close('popup-edit');
                document.location.href = this.intercomUrl;
            },
            err => {
                console.log(err);

            }
        );

    }

    saveConsoleThird(type, consoleLocation, consoleAccountId, consoleUsername) {

        const jsonObject = {};
        for (var i = 0; i < this.headers.length; i++) {
            jsonObject[this.headers[i].key] = this.headers[i].value;
        }

        const objeto = {

            consoleLocation: consoleLocation.value,
            consoleType: type.value,
            consoleAccountId: consoleAccountId.value,
            headers: JSON.stringify(jsonObject),
            consoleUsername: consoleUsername.value
        };

        this.consoleService.update(this.slug, this.consola.id, objeto).subscribe(
            res => {
                this.get(this.slug);
                this.modalService.close('popup-edit');
                this.dataLayerService.setFlag(this.flagPath, 'edit -> accept');
            },
            err => {
                console.log(err);
            }
        );

    }
    activateConsole(id, consola) {
        if (consola.isEnabled === false) {
            this.consoleService.activate(this.slug, id).subscribe(
                res => {
                    this.consoleService.showActivate(this.slug).subscribe(
                        data => {

                        }
                    );

                    this.get(this.slug);

                },
                err => {
                    console.log(err);

                }
            );
        }

    }


    addHeader() {
        this.headers.push({});
    }

    removeHeader(i: any) {
        this.headers.splice(i, 1);
    }


    openModal(id: string, consola) {
        this.headers = [];

        this.consoleService.getOne(this.slug, consola.id).subscribe(
            (res: any) => {

                this.consoleSelect = res.consoleType;
                this.consola = res;

                if (this.consola.headers) {
                    try {
                        const json = JSON.parse(this.consola.headers);
                        const iterator = Object.keys(json);
                        for (const key of iterator) {
                            const header: any = {};
                            header.key = key;
                            header.value = json[key];
                            this.headers.push(header);

                        }

                        this.headers = Array.from(new Set(this.headers.map(JSON.stringify))).map((item: string) => {
                            return JSON.parse(item);
                        });
                        // this.headers.length = this.consola.headers.length;
                        this.dataLayerService.setFlag(this.flagPath, 'edit -> start');
                    } catch (e) {
                        console.log(e);

                    }
                } else {

                }


                this.modalService.open(id);

            },
            err => {
                console.log(err);

            }
        );



    }

    closeModal(id: string) {

        this.modalService.close(id);
    }

    copy(str: string) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    }

    onChangeEditLocation() {
        this.dataLayerService.setFlag(this.flagPath, 'edit -> location');
    }

    onChangeEditAccountId() {
        this.dataLayerService.setFlag(this.flagPath, 'edit -> id');
    }

}
