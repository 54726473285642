import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

import { ModalService } from 'src/components/modal/modal.service';
import { AlertService } from 'src/services/alert.service';
import { AuthServices } from 'src/services/auth.guard.service';
import { FilterCustomerService } from 'src/services/filter-customer.service';
import { AllowedRolService } from 'src/services/rol-service.service';
import { CustomerService } from 'src/services/customer.service';
declare var translate: any;
@Component({
    selector: 'app-filter-types',
    templateUrl: './filter-types.component.html',
    styleUrls: ['./filter-types.component.scss']
})
export class FilterTypesComponent implements OnInit {

    role: any;
    authorizeRol: boolean;

    slug: string;
    existsFilters: boolean;
    currentFilter: any;
    filters: any[];
    filterTypes: any[];
    deleteFilterId: string;

    paginationFilters: any;

    platformsBodyStyles: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    translate: any;
    tenantId: any;

    constructor(
        private modalService: ModalService,
        private filterCustomerService: FilterCustomerService,
        private authServices: AuthServices,
        private allowedRolService: AllowedRolService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private customerService: CustomerService
    ) {
        this.existsFilters = true;
        this.filters = [];
        this.filterTypes = [];
        this.deleteFilterId = null;
        this.currentFilter = {
            id: null,
            filterType: 'CUSTOM_GOOGLE_INFOTYPE',
            name: '',
            displayName: '',
            description: '',
            wordList: [],
            regex: ''
        };
        this.paginationFilters = {
            currentPage: 1,
            pageSize: 10,
            totalItems: 0
        };
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    ngOnInit() {
        this.auth();
        this.getUrlParam('customer_slug', (customerSlug) => {
            this.slug = customerSlug;
            this.getCustomerBySlug(customerSlug, () => {
                this.getFilters(this.paginationFilters.currentPage, this.paginationFilters.pageSize);
            });
        });
    }

    getCustomerBySlug(slug, success) {
        this.customerService.getBySlug(slug, 'true').subscribe(
            (res: any) => {
                this.tenantId = res.tenant.id;
                if (typeof(success) === 'function') {
                    success();
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    auth() {
        this.authServices.verifyToken(JSON.stringify({
            user_id: this.authServices.getIdGoogle(),
            token: this.authServices.getToken(),
            auth_type: localStorage.getItem('Auth-Type')
        })).subscribe(
            (res: any) => {
                if (res.status === 'ACTIVE' && res.role.name !== 'CUSTOM') {
                    localStorage.setItem('photo', res.avatar);
                    this.authorizeRol = this.allowedRolService.allowed(res.role.name, 'DEV');
                } else {
                    console.log('logout filter-types1');
                    this.authServices.logout();
                }

            },
            err => {
                // console.log(err);
                console.log('logout filter-types2');
                this.authServices.logout();
            }
        );
    }

    closeModal(id) {
        this.modalService.close(id);
    }

    openModal(id, filter = null) {
        this.modalService.open(id);
        if (id === 'popup-create-filter-types' && filter === null) {
            this.currentFilter = {
                id: null,
                filterType: 'CUSTOM_GOOGLE_INFOTYPE',
                name: '',
                displayName: '',
                description: '',
                wordList: [],
                regex: ''
            };
        } else if (id === 'popup-create-filter-types' && filter !== null) {
            this.currentFilter = filter;
        } else if (id === 'popup-filter-types-delete' && filter !== null) {
            this.deleteFilterId = filter;
        }
    }

    getFilters(page, size) {
        this.filterCustomerService.getFiltersPaged(page, size, this.slug, this.tenantId).subscribe(
            (res: any) => {
                this.filters = res.content;
                this.paginationFilters.totalItems = res.totalElements;
                if (this.filters.length > 0) {
                    this.existsFilters = true;
                }
            },
            err => {
                console.error(err);
            }
        );
    }

    pageFiltersUpdate(page) {
        this.paginationFilters.currentPage = page;
        this.getFilters(page, this.paginationFilters.pageSize);
    }

    getTypeName(type) {
        let typeName = 'Fijo';
        if (type.indexOf('CUSTOM') >= 0) {
            typeName = 'Perzonalizado';
        } else if (type.indexOf('REGEX') >= 0) {
            typeName = 'Regex';
        } else {
            typeName = 'Fijo';
        }
        return typeName;
    }

    addWord(event: MatChipInputEvent) {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            if (!('wordList' in this.currentFilter)){
                this.currentFilter.wordList = [];
            }
            this.currentFilter.wordList.push(value.trim());
        }

        if (input) {
            input.value = '';
        }
    }

    removeWord(word) {
        const index = this.currentFilter.wordList.indexOf(word);

        if (index >= 0) {
            this.currentFilter.wordList.splice(index, 1);
        }
    }

    saveFilter() {
        if (this.currentFilter.name === '' || this.currentFilter.displayName === '' || this.currentFilter.description === null) {
            this.alertService.error(translate.sections.customers.filterTypes.requiredFields);
        } else {
            if (this.currentFilter.id === null) {
                this.filterCustomerService.createFilter(this.currentFilter, this.slug, this.tenantId).subscribe(
                    (res: any) => {
                        this.alertService.success(translate.sections.customers.filterTypes.createdFilterType);
                        this.closeModal('popup-create-filter-types');
                        this.filters.unshift(res);
                    },
                    err => {
                        console.log(err);
                    }
                );
            } else {
                this.filterCustomerService.updateFilter(this.currentFilter, this.slug, this.tenantId).subscribe(
                    (res: any) => {
                        this.alertService.success(translate.sections.customers.filterTypes.updatedFilterType);
                        this.closeModal('popup-create-filter-types');
                        const idx = this.filters.findIndex(filter => filter.id === res.id);
                        if (idx >= 0) {
                            this.filters[idx] = res;
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            }
        }
    }

    deleteFilter() {
        if (this.deleteFilterId !== null) {
            this.filterCustomerService.deleteFilter(this.deleteFilterId, this.slug, this.tenantId).subscribe(
                (res: any) => {
                    this.alertService.success(translate.sections.customers.filterTypes.removedFilterType);
                    this.closeModal('popup-filter-types-delete');
                    const idx = this.filters.findIndex(filter => filter.id === this.deleteFilterId);
                    if (idx >= 0) {
                        this.filters.splice(idx, 1);
                    }
                    this.deleteFilterId = null;
                },
                err => {
                    console.log(err);
                }
            );
        }
    }

    getUrlParam(param: string, success) {
        const paramInterval = setInterval(() => {
            if (this.route !== null) {
                if (this.route.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.route.paramMap.subscribe(params => {
                        const p = params.get(param);
                        success(p);
                    });
                }
            }
        }, 500);
    }

}
