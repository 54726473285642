import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { FunctionalityService } from '../../services/functionality.service';
import { LoaderService } from '../../services/loader.service';
import { DiagramService } from '../../services/diagram.service';
// import { Subscription } from 'rxjs';
// import { DocumentService } from 'src/services/document.service';
import { AlertService } from '../../services/alert.service';

import { StencilService } from '../../services/stencil-service';
import { ToolbarService } from '../../services/toolbar-service';
import { InspectorService } from '../../services/inspector-service';
import { HaloService } from '../../services/halo-service';
import { KeyboardService } from '../../services/keyboard-service';
import RappidService from '../../services/kitchensink-service';

// import { Observable, Subscription } from 'rxjs';
import { Document } from '../../models/document';
// import { Pointer } from '../../models/pointer';
import { Diagram } from 'src/models/diagram';
import { AuthServices } from 'src/services/auth.guard.service';
import { BuildBotService } from 'src/services/build-bot.service';
import { CustomerService } from 'src/services/customer.service';
import { ModalService } from '../modal/modal.service';
import { TimezoneService } from 'src/services/inspector-gus/timezone.service';
import {FlowPermission} from "../../models/flow-permission";
import { DomSanitizer } from "@angular/platform-browser";
import { FileService } from 'src/services/file.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare global {
    interface Window {
        autosaveInterval: any;
    }
}

@Component({
    selector: 'app-diagram',
    templateUrl: './diagram.component.html',
    styleUrls: ['./diagram.component.scss']
})
export class DiagramComponent implements OnInit, OnDestroy {

    private rappid: any;

    diagram: any = {};
    diagramId: string = '';
    diagramSlug: string = '';
    currentDiagramName: string = '';
    customerSlug: string;
    platformSlug: string;

    // subscription: Subscription;

    // documents: Observable<string[]>;
    currentDoc: Document;
    // private documentSubscribed: Subscription;

    cursor1: any;

    tenantId: string;

    constructor(
        private element: ElementRef,
        // private documentService: DocumentService,
        private functionalityService: FunctionalityService,
        private route: ActivatedRoute,
        private loaderService: LoaderService,
        private diagramService: DiagramService,
        private alertService: AlertService,
        private authServices: AuthServices,
        private buildBotService: BuildBotService,
        private customerService: CustomerService,
        private modalService: ModalService,
        private router: Router,
        private location: Location,
        private timezoneService: TimezoneService,
        private domSanitizer: DomSanitizer,
        private fileService: FileService,
        private recaptchaV3Service: ReCaptchaV3Service
    ) {
        this.cursor1 = {
            left: '0px',
            top: '0px'
        };
        this.diagram.id = '';
        /*if (window.location.href.indexOf('autoreload') < 0) {
            window.location.href = window.location.href + '?autoreload=true';
        }*/
    }

    ngOnInit() {
        this.getUrlParam('customer_slug', (customerSlug) => {
            this.customerSlug = customerSlug;
            this.getTenantBySlug(customerSlug);
            this.getUrlParam('platform_slug', (platformSlug) => {
                this.platformSlug = platformSlug;
                this.getUrlParam('diagram_id', (diagramId) => {

                    if (diagramId) {
                        if (diagramId === 'new-diagram') {
                            this.getDiagramById(diagramId, customerSlug, platformSlug, () => {
                                this.openModal('popup-diagram-name');
                            });
                        } else {
                            this.diagramId = diagramId;
                            this.getDiagramById(diagramId, customerSlug, platformSlug, () => {
                                this.alertService.error('No se pudo obtener el diagrama por el id indicado');
                            });
                        }
                    }
                    else {
                        this.getMainDiagram(customerSlug, platformSlug, () => {
                            // this.currentDiagramName = 'Principal';
                            // this.createDiagram(true);
                            this.alertService.error('No se pudo obtener el diagrama Principal');
                        });
                    }
                });
            });
        });

        /*this.route.queryParams.subscribe(params => {
            // console.log(params.flowId);
            // const docId = url.searchParams.get('flowId');

            this.loadDoc(params.flowId);
            this.documentSubscribed = this.documentService.currentDocument.subscribe((doc: Document) => {
                // console.log(doc);
                this.currentDoc = doc;
            });
        });*/
    }

    openModal(id) {
        this.modalService.open(id);
    }

    closeModal(id) {
        this.modalService.close(id);
    }

    createDiagram(main = false) {
        if (this.currentDiagramName) {
            let slug = this.diagramSlug;
            if (!this.diagramSlug) {
                slug = this.currentDiagramName.toLowerCase().replace(/ /g, '-').replaceAll(/[^0-9a-z_\-]/g, '') + '-' + (new Date().getTime()).toString(36);
            }

            const diagram = new Diagram();
            diagram.json = '{}';
            diagram.name = this.currentDiagramName;
            diagram.label = '';
            diagram.slug = slug;
            diagram.isPrimary = main;
            diagram.description = '';
            diagram.platformCatalogId = this.platformSlug;
            this.diagramService.add(diagram, this.customerSlug, this.platformSlug).subscribe((diagram2: any) => {
                let uri = document.location.pathname;
                if (!this.diagramSlug) {
                    const chunks = uri.split('/diagram');
                    uri = chunks[0] + '/diagram';
                } else {
                    const chunks = uri.split('/diagram');
                    uri = chunks[0] + '/diagram/' + diagram2.id;
                }
                this.diagram = diagram2;
                this.diagramSlug = diagram2.slug;
                this.diagramId = diagram2.id;
                localStorage.setItem('flowId', diagram2.id);
                localStorage.setItem('flowSlug', diagram2.slug);
                localStorage.setItem('canEdit', '1');
                // this.getCatalogByPlatformId(this.customerSlug, this.platformSlug, diagram2);
                this.closeModal('popup-diagram-name');
                // this.router.navigateByUrl(uri);
                window.location.href = uri;

            }, err => {
                this.closeModal('popup-diagram-name');
            });
        } else {
            this.alertService.error('Debes establecer un nombre al diagrama');
        }
    }

    getCurrentUserPermissionsByDiagramSlug(diagramSlug: string, slug:string, platformSlug: string, isMain=false) {
        this.diagramService.getPermissionsBySlug(diagramSlug, platformSlug, slug).subscribe(
            (flowPermissions: FlowPermission ) => {
                localStorage.setItem('canEdit', '1');
                if (isMain) {
                    localStorage.setItem('canEditMain', '1');
                }
            },
            err => {
                localStorage.setItem('canEdit', '0');
                if (isMain) {
                    localStorage.setItem('canEditMain', '0');
                }
            }
        );
    }

    getDiagramById(diagramId, customerSlug, platformSlug, error = null) {
        this.diagramService.getById(diagramId, customerSlug, platformSlug).subscribe((diagram: any) => {
            this.diagram = diagram;
            this.diagramId = diagram.id;
            this.diagramSlug = diagram.slug;
            localStorage.setItem('flowId', diagram.id);
            localStorage.setItem('isFlowPrimary', diagram.isPrimary ? '1' : '0');
            localStorage.setItem('flowSlug', diagram.slug);
            localStorage.setItem('secondaryFlowPrebuild', JSON.stringify(diagram.secondaryFlowPrebuild));
            this.getCatalogByPlatformId(customerSlug, platformSlug, diagram);
            this.getCurrentUserPermissionsByDiagramSlug(diagram.slug, customerSlug, platformSlug, diagram.isPrimary)
        }, err => {
            if (error instanceof Function) {
                error();
            }
        });
    }

    getMainDiagram(customerSlug, platformSlug, error = null) {
        this.diagramService.getLast(customerSlug, platformSlug).subscribe((diagram: any) => {
            this.diagram = diagram;
            this.diagramSlug = diagram.slug;
            this.diagramId = diagram.id;
            localStorage.setItem('flowSlug', diagram.slug);
            localStorage.setItem('flowId', diagram.id);
            localStorage.setItem('secondaryFlowPrebuild', JSON.stringify(diagram.secondaryFlowPrebuild));
            localStorage.setItem('isFlowPrimary', diagram.isPrimary ? '1' : '0');
            this.getCurrentUserPermissionsByDiagramSlug(diagram.slug, customerSlug, platformSlug, true);
            this.getCatalogByPlatformId(customerSlug, platformSlug, diagram);
        }, err => {
            if (error instanceof Function) {
                error();
            }
        });
    }

    getTenantBySlug(slug) {
        this.customerService.getBySlug(slug).subscribe(
            (res: any) => {
                this.tenantId = res.tenant.id;
            },
            err => {
                console.log(err);
            }
        );
    }

    getCatalogByPlatformId(customerSlug, platformSlug, diagram) {
        this.functionalityService.getCatalogByPlatformId(
            platformSlug,
            customerSlug
        ).subscribe((functionalities: any) => {
            // console.log('functionalities', functionalities);
            this.rappid = new RappidService(
                this.element.nativeElement,
                new StencilService(),
                new ToolbarService(),
                new InspectorService(),
                new HaloService(),
                new KeyboardService(),
                this.alertService,
                this.timezoneService,
                this.loaderService,
                /*this.documentService,
                this.documentSubscribed,
                this.setCursor1.bind(this),*/
                functionalities,
                customerSlug,
                platformSlug,
                diagram,
                this.buildBotService,
                this.diagramService,
                this.customerService,
                this.authServices,
                this.router,
                this.tenantId,
                this.domSanitizer,
                this.fileService,
                this.recaptchaV3Service
            );
            this.rappid.startRappid();

            // this.loaderService.close();
        });
    }

    ngOnDestroy() {
        /*if (typeof this.subscription !== 'undefined') {
            this.subscription.unsubscribe();
        }
        this.documentSubscribed.unsubscribe();*/
        clearInterval(window.autosaveInterval);
    }

    getUrlParam(param: string, callback) {
        const paramInterval = setInterval(() => {
            // console.log(this.route);
            if (this.route.paramMap !== null) {
                clearInterval(paramInterval);
                let success = false;
                this.route.paramMap.subscribe(params => {
                    if (params.has(param)) {
                        const p = params.get(param);
                        if (p != null) {
                            success = true;
                            callback(p);
                        }
                    }
                });
                if (!success) {
                    callback(null);
                }
            }
        }, 500);
    }

    /*setCursor1(coordinates) {
        this.cursor1.left = coordinates.clientX + 'px';
        this.cursor1.top = coordinates.clientY + 'px';
    }

    editDoc(doc: Document) {
        this.documentService.editDocument(doc);
    }

    loadDoc(id: string) {
        this.documentService.getDocument(id);
    }

    newDoc() {
        this.documentService.newDocument();
    }

    onMouseMove(event: MouseEvent) {
        const pointer = new Pointer();
        pointer.clientX = event.clientX;
        pointer.clientY = event.clientY;

        const pointerDoc = new Document();
        pointerDoc.pointer = pointer;
        // console.log(this.currentDoc);
        if (typeof this.currentDoc !== 'undefined') {
            pointerDoc.id = this.currentDoc.id;
            this.editDoc(pointerDoc);
        }
    }*/

    goToDiagram(e) {
        let uri = document.location.pathname;
        const diagramId = e.target.value;
        const chunks = uri.split('/diagram');
        if (chunks.length >= 1) {
            if (this.diagram.secondaryFlowPrebuild.some(d => diagramId === d.id && d.isPrimary === true)) {
                uri = chunks[0] + '/diagram';
            } else {
                uri = chunks[0] + '/diagram/' + diagramId;
            }
        }
        window.location.href = uri;
        // this.router.navigate([uri]);
    }
}
