import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { BlacklistService } from '../../services/blacklist.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataLayerService } from 'src/services/data-layer.service';
declare var translate: any;
@Component({
    selector: 'app-blacklist',
    templateUrl: './blacklist.component.html',
    styleUrls: ['./blacklist.component.scss']
})
export class BlacklistComponent implements OnInit {

    slug: any;
    id: any;
    canales: any = [];
    blockedUsers: any = [];
    modeSelected: any;
    routeImageChannel = './../assets/img/channels/';
    urlParameter: any;
    translate: any;
    flagPath: string;

    constructor(
        private router: Router,
        private activate: ActivatedRoute,
        private customerService: CustomerService,
        private blackListService: BlacklistService,
        private dataLayerService: DataLayerService
    ) {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = 'Blacklist -> ';
    }

    ngOnInit() {
        this.urlParameter = window.location.href;
        this.slug = this.activate.snapshot.paramMap.get('customer_slug');
        console.log(this.slug);

        this.getCurrentCustomer(this.slug);
        this.supportChannels();

        this.getList();
        this.dataLayerService.setFlag(this.flagPath, 'start');
    }


    delete(id) {

        this.blackListService.delete(id, this.slug).subscribe(
            res => {
                console.log(res);
                this.getList();

            },
            err => {
                console.log(err);

            }

        );


    }


    supportChannels() {
        this.blackListService.supportChannels(this.slug).subscribe(
            (res: any[]) => {
                const hidden = [
                    'WHATSAPP_API',
                    'LIVEPERSON',
                    'WAVY',
                    'TESTING',
                    'GENERIC',
                    'SMS',
                    'ZENDESK_CHAT',
                    'ALEXA',
                    'GOOGLE_ASSISTANT',
                    'CHUBB',
                    'MAIL'
                ];
                this.canales = res.filter(channel => !hidden.includes(channel));
                this.canales.sort();
            },
            err => {
                console.log(err);

            }
        );
    }



    save(id, channel) {

        const object = {

            channelOwnerId: id.value,
            channel: channel.value,
            isNew: true
        };


        console.log(object);



        this.addUser(this.slug, object);

    }

    modList(mod) {

        this.modeSelected = mod.value;
        console.log(this.modeSelected);

        const object = {

            listMode: mod.value


        };

        this.update(this.slug, this.id, object);
        this.dataLayerService.setFlag(this.flagPath, 'mode');
    }

    addUser(slug, obj) {
        this.blackListService.add(slug, obj).subscribe(
            res => {
                this.getList();
                $('#telUser').val('');
                this.dataLayerService.setFlag(this.flagPath, 'add');
            },
            err => {
                console.log(err);

            }
        );

    }

    getList() {
        this.blackListService.get(this.slug).subscribe(
            res => {
                console.log(res);
                this.blockedUsers = res;

            },
            err => {
                console.log(err);
            }
        );
    }

    update(slug, id, obj) {
        this.blackListService.update(slug, id, obj).subscribe(
            res => {
                console.log(res);

            },
            err => {
                console.log(err);

            }
        );
    }
    getCurrentCustomer(slug) {
        this.customerService.getBySlug(slug).subscribe(
            (res: any) => {
                console.log(res);

                this.id = res.tenant.id;
                console.log(this.id);

                this.modeSelected = res.tenant.listMode;
                console.log(this.modeSelected);


            },
            err => {
                console.log(err);

            }
        );
    }
    onChangeUser() {
        this.dataLayerService.setFlag(this.flagPath, 'user');
    }

    onChangeChannel() {
        this.dataLayerService.setFlag(this.flagPath, 'channel');
    }

}
