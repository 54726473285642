import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ModalService } from '../../modal/modal.service';
import { PlatformService } from '../../../services/platform.service';

import { PlatformCatalog } from '../../../models/platform-catalog';
import { LoaderService } from '../../../services/loader.service';
import { Platform } from '../../../models/platform';

import { environment } from '../../../environments/environment';
declare var translate: any;

@Component({
    selector: 'app-list-platforms',
    templateUrl: './list-platforms.component.html',
    styleUrls: ['./list-platforms.component.scss']
})
export class ListPlatformsComponent implements OnInit {
    translate: any;
    currentPlatform: Platform = new Platform();
    platforms: Platform[] = [];
    assets = environment.assets;
    loadingPlatforms = true;


    platformsCatalog: PlatformCatalog[] = [];

    platformsForm: UntypedFormGroup;



    constructor(
        private fb: UntypedFormBuilder,
        private platformService: PlatformService,
        private modalService: ModalService,
        private loaderService: LoaderService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
     }

    ngOnInit() {
        this.getPlatformsCatalog();
        // this.getCurrentPlatform();
        this.getPlatforms();

        this.platformsForm = this.fb.group({
            platform: '',
            agree: null
        });

    }


    getPlatformsCatalog() {

        this.getUrlParam('customer_slug', (slug) => {
            // console.log(slug);
            this.platformService.getCatalog(slug).subscribe(
                res => {
                    // console.log(res);
                    // this.platformsCatalog = res;
                    for (const plat of res) {
                        if ((plat.hasOwnProperty('show') && plat.show) || !plat.hasOwnProperty('show')) {
                            this.platformsCatalog.push(plat);
                        }
                    }
                },
                // err => {
                //     console.log(err);
                // }
            );
        });

    }

    setImageDefault(e) {
        e.target.src = this.assets + 'img/user_default.svg';
        console.log('error', e.target.src);
    }

    getPlatforms() {
        this.getUrlParam('customer_slug', (currentCustomerSlug) => {
            this.platformService.getAll(
                currentCustomerSlug
            ).subscribe((platforms: any) => {
                this.getUrlParam('platform_slug', (currentPlatformSlug) => {
                    for (const plat of platforms) {
                        // if (plat.channel.channelId === 'smooch') {
                        //     plat.channel.label = 'Whatsapp';
                        // }
                        // if (plat.channel.channelId !== 'whatsapp') {
                            if (currentPlatformSlug === plat.channel.name) {
                                plat.active = true;
                            } else {
                                plat.active = false;
                            }
                            this.platforms.push(plat);
                        // }
                    }
                    this.loadingPlatforms = false;
                });
            });
        });
    }

    getCurrentPlatform() {
        this.getUrlParam('customer_slug', (currentCustomerSlug) => {
            this.getUrlParam('platform_slug', (platformSlug) => {
                this.platformService.getByName(
                    platformSlug,
                    currentCustomerSlug
                ).subscribe((platform: any) => {
                    this.currentPlatform = platform;
                    console.log('current platform');
                    // console.log(this.currentPlatform);
                    this.platformsForm = this.fb.group({
                        platform: platform.channel.label + '#' + platform.channel.name,
                        agree: null
                    });

                    // this.loaderService.close();
                });
            });
        });
    }

    addplatform() {

        /*const platform = new Platform();
        platform.channel = {
            name: this.platformsForm.value.platform.name,
            channelId: this.platformsForm.value.platform.name.toLowerCase(),
            label: this.platformsForm.value.name
        };
        platform.status = 'ACTIVE';*/

        const platformPayload = {
            name: this.platformsForm.value.platform.name,
            // label: this.platformsForm.value.name,
            status: 'ACTIVE'
        };

        this.getUrlParam('customer_slug', (slug) => {
            // console.log(slug);
            this.platformService.add(platformPayload, slug).subscribe(
                (res: Platform) => {
                    // console.log(res);
                    this.platformsForm = this.fb.group({
                        platform: res.channel.label + '#' + res.channel.name,
                        agree: null
                    });
                    this.platforms.push(res);

                    // this.loaderService.close();
                    this.closeModal('popup-add-platform');

                    this.getCurrentPlatform();

                    // test redirect
                    this.getUrlParam('customer_slug', (customerSlug) => {
                        window.location.href = window.location.origin + '/customers/' + customerSlug + '/platforms/' + res.channel.name + '/diagram';
                    });

                    // end test

                    // redirect to platform
                    // this.getUrlParam('customer_slug', (currentCustomerSlug) => {
                    //     this.router.navigate([
                    //         '/customers',
                    //         currentCustomerSlug,
                    //         'platforms',
                    //         res.channel.name,
                    //         'diagram'
                    //     ]
                    //     /*, {
                    //         queryParams: {
                    //             flowId: 'pHjdk'
                    //         }
                    //     }*/);
                    // });

                    // end
                },
                err => {
                    console.log(err);
                }
            );
        });


        // this.platformsCatalog.forEach(platform => {
            // if (this.platformsForm.value.platform === platform.name) {

            // }
        // });

    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    toggleMenu(){
        const menu = document.querySelector('.menu-platforms');
        if (menu.classList.contains('fadeIn')) {
            menu.classList.add('fadeOut');
            menu.classList.remove('fadeIn');
        } else {
            menu.classList.remove('fadeOut');
            menu.classList.add('fadeIn');
        }

    }

    changePlatform(platform) {
        if (!platform.active) {
            this.loaderService.show();
            this.getUrlParam('customer_slug', (customerSlug) => {
                const platformSlug = platform.channel.name;
                window.location.href = window.location.origin + '/customers/' + customerSlug + '/platforms/' + platformSlug + '/diagram';
            });
        }
    }

    getUrlParam(param: string, callback) {
        const paramInterval = setInterval(() => {
            if (this.route.firstChild !== null) {
                if (this.route.firstChild.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.route.firstChild.paramMap.subscribe(params => {
                        if (params.has(param)) {
                            const p = params.get(param);
                            if (p != null) {
                                callback(p);
                            }
                        }
                    });
                }
            }
        }, 500);
    }

}
