import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/services/alert.service';
import { ModalService } from '../modal/modal.service';

import { ConversationDrivenDevelopmentService } from 'src/services/conversation-driven-development.service';
import { CustomerService } from 'src/services/customer.service';

import { environment } from 'src/environments/environment';
import { DiagramService } from 'src/services/diagram.service';

import * as joint from '../../../vendor/rappid';
import { Entity } from 'src/models/entity';
import { DataLayerService } from 'src/services/data-layer.service';

@Component({
    selector: 'app-conversation-driven-development',
    templateUrl: './conversation-driven-development.component.html',
    styleUrls: ['./conversation-driven-development.component.scss']
})
export class ConversationDrivenDevelopmentComponent implements OnInit {

    conversationTypes = new UntypedFormControl('');
    conversationTypesList: any[] = [];
    conversations: any[] = [];
    currentConversation: any;
    messages: any[] = [];
    errorTags: any[] = [];
    message: any;
    range: any;
    customerSlug: string;
    conversationsCursor: string;
    messagesCursor: string;
    search: string;
    showExampleOptions: boolean;
    positionExampleOptions: any;
    entities: any[];
    lastSelectionPhrase: any;
    newEntity: any;
    nameIntention: string;
    cdd: any;
    currentPhraseId: any;

    deniedMsgsTypes: any[];

    @ViewChild("examplesList") examplesList: ElementRef;

    functionalityIntentions: any[];
    currentDiagram: any;
    currentIntentions: any;
    currentDiagramId: string;
    flagPath: string;

    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private cutomerService: CustomerService,
        private conversationDrivenDevelopmentService: ConversationDrivenDevelopmentService,
        private modalService: ModalService,
        private diagramService: DiagramService,
        private dataLayerService: DataLayerService
    ) {
        this.currentPhraseId = null;
        this.positionExampleOptions = {};
        this.showExampleOptions = false;
        this.entities = [];
        this.functionalityIntentions = [];
        const today = new Date();
        const month = today.getMonth();
        const year = today.getFullYear();
        const yesterday = today.getDate() - 1;

        this.range = new UntypedFormGroup({
            start: new UntypedFormControl(new Date(year, month, yesterday)),
            end: new UntypedFormControl(today),
        });

        const parameterCustomerSlug = this.route.snapshot.paramMap.get('customer_slug');
        // console.log(parameterCustomerSlug);

        if (parameterCustomerSlug) {
            this.customerSlug = parameterCustomerSlug;
        }

        this.deniedMsgsTypes = [
            'typing'
        ];

        this.cdd = {
            intentions: []
        };

        this.conversationTypesList = [
            {
                name: 'Ticket',
                key: '$ticketId'
            }, {
                name: 'Punto de Flujo',
                key: '$flow.name'
            }, {
                name: 'Punto de NLP',
                key: '$nlp.label'
            }, {
                name: 'Encuesta',
                key: '$grade.name'
            }, {
                name: 'Canal',
                key: '$channel'
            }, {
                name: 'Usuario',
                key: '$channelOwnerId'
            }, {
                name: 'Mensaje de usuario',
                key: '$message'
            }, {
                name: 'Mensaje de bot',
                key: '$botResponse'
            }, {
                name: 'Error',
                key: '$incidenceType'
            }, {
                name: 'Default',
                key: '$nlpStatus.enable'
            }
        ];
        this.errorTags = [
            {
                key: 'wrong_answer',
                name: 'Respuesta incorrecta',
                value: 'Descripción wrong_answer',
                link: 'https://guschat.atlassian.net/wiki/spaces/GCCS/pages/1675067427/Diagn+stico+de+Respuesta+incorrecta'
            }, {
                key: 'bot_not_answer',
                name: 'No hay respuesta',
                value: 'Descripción bot_not_answer',
                link: 'https://guschat.atlassian.net/wiki/spaces/GCCS/pages/1674281009/Diagnostico+al+error+No+hay+respuesta'
            }, {
                key: 'fatal_error',
                name: 'Error de procesamiento',
                value: 'Descripción fatal_error',
                link: 'https://guschat.atlassian.net/wiki/spaces/GCCS/pages/1674248230/Diagn+stico+de+Error+de+procesamiento'
            },  {
                key: 'copys_clutter',
                name: 'Copys en desorden',
                value: 'Descripción copys_clutter',
                link: 'https://guschat.atlassian.net/wiki/spaces/GCCS/pages/1675034691/Diagn+stico+de+Copys+en+desorden'
            }, {
                key: 'not_looking_branches',
                name: 'Reglas de negocio',
                value: 'Descripción not_looking_branches',
                link: 'https://guschat.atlassian.net/wiki/spaces/GCCS/pages/1685651465/Reglas+de+negocio'
            }, {
                key: 'copys_formatting',
                name: 'Formato del copy',
                value: 'Descripción copys_formatting',
                link: 'https://guschat.atlassian.net/wiki/spaces/GCCS/pages/1675034721/Diagn+stico+de+Formato+en+copies'
            }, {
                key: 'inappropriate_copy',
                name: 'Copy no adecuado',
                value: 'Descripción inappropriate_copy',
                link: 'https://guschat.atlassian.net/wiki/spaces/GCCS/pages/1685520397/Copy+no+adecuado'
            }, {
                key: 'bot_default',
                name: 'Respuesta default',
                value: 'Descripción bot_default',
                link: 'https://guschat.atlassian.net/wiki/spaces/GCCS/pages/1674248260/Diagn+stico+de+Respuesta+default'
            },
        ];
        this.flagPath = 'CDP -> ';
    }

    private phraseInitialize() {
        this.lastSelectionPhrase = {
            parent: null,
            selection: null,
            node: null,
            start: null,
            end: null,
            highlight: null,
            type: null
        }
    }

    ngOnInit() {
        const dateStart = this.range.value.start.toISOString().substring(0, 10);
        const dateEnd = this.range.value.end.toISOString().substring(0, 10);
        this.getConversations(null, null, dateStart, dateEnd);
        $(document.body).on('click', (e2) => {
            e2.stopPropagation();
            e2.preventDefault();
            const incidence = $(e2.target);
            if (!incidence.hasClass('incidence')) {
                $('.error-list').fadeOut();
            }
        });
    }

    isJson(str) {
        if (str === 1 || str === '1' || !str) {
            return false;
        } else {
            try {
                JSON.stringify(JSON.parse(str));
            } catch (e) {
                return false;
            }
            return true;
        }
    }

    getMessageUser(msg) {
        if (this.isJson(msg)) {
            const messageUserObj = JSON.parse(msg);
            if (messageUserObj && messageUserObj.hasOwnProperty('text')) {
                msg = messageUserObj.text;
            } else if (messageUserObj && messageUserObj.hasOwnProperty('title')) {
                msg = messageUserObj.title;
            }
        }
        return msg;
    }

    getConversations(searchIn: string, search: string, dateStart: string, dateEnd: string) {
        this.conversationDrivenDevelopmentService.getConversations(this.customerSlug, searchIn, search, dateStart, dateEnd).subscribe(
            (res: any) => {
                this.conversations = res.rows;

                this.conversations.forEach((conversation, idx) => {
                    if (this.isJson(conversation.messageUser)) {
                        const messageUserObj = JSON.parse(conversation.messageUser);
                        if (messageUserObj && messageUserObj.hasOwnProperty('text')) {
                            conversation.messageUser = messageUserObj.text;
                        } else if (messageUserObj && messageUserObj.hasOwnProperty('title')) {
                            conversation.messageUser = messageUserObj.title;
                        }
                    }
                    this.conversations[idx] = conversation;
                });

                if (res.hasOwnProperty('cursor')) {
                    this.conversationsCursor = res.cursor;
                } else {
                    this.conversationsCursor = null;
                }
                this.dataLayerService.setFlag(this.flagPath, 'Conversation -> read');
            }, (err: any) => {
                console.error(err);
            }
        );
    }

    showConversationMessages(e, conversation: any) {
        this.message = null;
        this.currentConversation = conversation;
        $('.conversation').removeClass('selected');
        $(e.target).closest('.conversation').addClass('selected');
        const dateStart = this.range.value.start.toISOString().substring(0, 10);
        const dateEnd = this.range.value.end.toISOString().substring(0, 10);
        this.getMessages(conversation.ticketId, dateStart, dateEnd);
        this.getEntities(this.currentConversation.channel);
        this.dataLayerService.setFlag(this.flagPath, 'Conversation -> selet message');
    }

    getMessages(conversationId: string, dateStart: string, dateEnd: string) {
        this.conversationDrivenDevelopmentService.getMessages(this.customerSlug, conversationId, dateStart, dateEnd).subscribe(
            (res: any) => {
                this.messages = res.rows;
                this.messages.forEach((message, index) => {
                    try {
                        this.messages[index].messageBot = JSON.parse(message.messageBot);
                        this.messages[index].messageBotType = JSON.parse(message.messageBotType);
                    } catch(e) {
                        console.error(e);
                        this.messages[index].messageBot = [];
                    }

                    if (this.isJson(message.messageUser)) {
                        const messageUserObj = JSON.parse(message.messageUser);
                        if (messageUserObj && messageUserObj.hasOwnProperty('text')) {
                            message.messageUser = messageUserObj.text;
                        }else if (messageUserObj && messageUserObj.hasOwnProperty('title')) {
                            message.messageUser = messageUserObj.title;
                        }
                    }
                    this.messages[index] = message;
                });
                if (res.hasOwnProperty('cursor')) {
                    this.messagesCursor = res.cursor;
                } else {
                    this.messagesCursor = null;
                }

            }, (err: any) => {
                console.error(err);
            }
        );
    }

    parseBotMsgs(messagesBot) {
        if (messagesBot) {
            if (typeof messagesBot === 'string' ) {
                messagesBot = JSON.parse(messagesBot);
            }
            messagesBot.forEach((messageBot, idx) => {
                if (this.isJson(messageBot)) {
                    const messageBotObj = JSON.parse(messageBot);
                    if (messageBotObj && messageBotObj.hasOwnProperty('type')) {
                        messageBot = messageBotObj;
                        if (messageBotObj.type === 'WHATSAPP_INTERACTIVE') {
                            messageBot.text = JSON.parse(messageBotObj.text);
                        }
                    }
                }
                messagesBot[idx] = messageBot;
            });
        }

        return messagesBot;
    }

    formatMessage(str) {
        return str.replaceAll(/\n/g, '<br>');
    }

    isVisual(messageBotObj) {
        if (messageBotObj && messageBotObj.hasOwnProperty('type')) {
            return true;
        }
        return false;
    }

    isVideo(str) {
        let response = false;
        const chunks = str.split('.');
        const extension = chunks.pop();
        const allowedExtensions = ['mp4', 'webm'];
        if (allowedExtensions.includes(extension)) {
            response = true;
        }
        return response;
    }
    isAudio(str) {
        let response = false;
        const chunks = str.split('.');
        const extension = chunks.pop();
        const allowedExtensions = ['mp3', 'wav', 'ogg'];
        if (allowedExtensions.includes(extension)) {
            response = true;
        }
        return response;
    }

    isMultimedia(str) {
        return this.isVideo(str) || this.isAudio(str);
    }

    showErrorTags(e) {
        // incidenceType
        const errorList = $(e.target).parent().parent().find('.error-list');
        errorList.fadeIn();

    }

    setError(recordId, error) {
        this.conversationDrivenDevelopmentService.addIncidenceType(this.customerSlug, recordId, error).subscribe(
            (res: any) => {
                this.message.incidenceType = error;
                this.alertService.success('Se ha actualizado el tipo de incidencia');
            }, (err: any) => {
                console.error(err);
            }
        );
    }

    getIncidenceDescByType(errorType) {
        let response = {name: '', link: ''};
        if (errorType !== null) {
            response = this.errorTags[errorType];
        }
        return response;
        // return this.errorTags.find((errorTag) => errorTag.key === errorType);
    }

    showMessage(message) {
        this.message = message;
    }

    conversationsLoadMore() {
        if (this.conversationsCursor) {
            this.conversationDrivenDevelopmentService.getConversationsCursor(this.customerSlug, this.conversationsCursor).subscribe(
                (res: any) => {
                    this.conversations = this.conversations.concat(res.rows);
                    if (res.hasOwnProperty('cursor')) {
                        this.conversationsCursor = res.cursor;
                    } else {
                        this.conversationsCursor = null;
                    }
                }, (err: any) => {
                    console.error(err);
                }
            );
        }
    }

    messagesLoadMore(ticketId: string) {
        if (this.messagesCursor) {
            this.conversationDrivenDevelopmentService.getMessagesCursor(this.customerSlug, ticketId, this.messagesCursor).subscribe(
                (res: any) => {
                    this.messages = this.messages.concat(res.rows);
                    this.messages.forEach((message, index) => {
                        try {
                            if (typeof message.messageBot === "string") {
                                this.messages[index].messageBot = JSON.parse(message.messageBot);
                            }
                            if (typeof message.messageBotType === "string") {
                                this.messages[index].messageBotType = JSON.parse(message.messageBotType);
                            }
                        } catch(e) {
                            console.error(e);
                            this.messages[index].messageBot = [];
                        }

                        if (this.isJson(message.messageUser)) {
                            const messageUserObj = JSON.parse(message.messageUser);
                            if (messageUserObj && messageUserObj.hasOwnProperty('text')) {
                                message.messageUser = messageUserObj.text;
                            }else if (messageUserObj && messageUserObj.hasOwnProperty('title')) {
                                message.messageUser = messageUserObj.title;
                            }
                        }
                        this.messages[index] = message;
                    });
                    if (res.hasOwnProperty('cursor')) {
                        this.messagesCursor = res.cursor;
                    } else {
                        this.messagesCursor = null;
                    }
                }, (err: any) => {
                    console.error(err);
                }
            );
        }
    }

    filter() {
        let searchIn;
        let dateStart;
        let dateEnd;
        if (this.conversationTypes.value) {
            searchIn = this.conversationTypes.value.map((x) => x.key).join(',');
        }
        if (this.range.value.start) {
            dateStart = this.range.value.start.toISOString().substring(0, 10);
        }
        if (this.range.value.end) {
            dateEnd = this.range.value.end.toISOString().substring(0, 10);
        }

        this.getConversations(searchIn, this.search, dateStart, dateEnd);
        this.messages = [];
        this.message = null;
        this.dataLayerService.setFlag(this.flagPath, 'Filter -> apply');
    }

    openTestBot() {
        if (this.currentConversation && this.currentConversation.hasOwnProperty('channel')) {
            this.cutomerService.getBySlug(this.customerSlug).subscribe(
                (res: any) => {
                    let url = '';
                    const currentChannel = res.tenant.channels.find(channel => channel.channel === this.currentConversation.channel);
                    if (currentChannel.channel === 'WEBSITE') {
                        url = environment.chatUrl + 'test.html?channel_owner_id=' + currentChannel.channelOwnerId;
                        url += '&guschat=open_box';
                    } else if (currentChannel.channel === 'FB_MESSENGER') {
                        url = 'https://www.facebook.com/messages/t/' + currentChannel.channelOwnerId;
                    } else if (currentChannel.channel === 'SMOOCH_SANDBOX' && currentChannel.channelSubtype === 'WHATSAPP') {
                        const chunks = currentChannel.label.split(' - ');
                        const phone = chunks[1];
                        url = 'https://wa.me/' + phone.replace(/\s/g, '');
                    } else if (currentChannel.channel === 'WHATSAPP_SANDBOX' && currentChannel.channelSubtype === 'WHATSAPP') {
                        const chunks = currentChannel.channelOwnerId.split('_');
                        const phone = chunks[1].replace(/\s/g, '');
                        url = 'https://wa.me/' + phone;
                    } else if (currentChannel.channel === 'WHATSAPP' && currentChannel.channelSubtype === 'CLOUD') {
                        const chunks = currentChannel.label.split(' ');
                        const phone = chunks[1].replace(/\s/g, '');
                        url = 'https://wa.me/' + phone;
                    } else if (currentChannel.channel === 'WHATSAPP' && currentChannel.channelSubtype === 'WHATSAPP') {
                        url = 'https://wa.me/' + currentChannel.channelOwnerId;
                    }
                    if (url !== '') {
                        window.open(url, '_blank');
                    } else {
                        this.alertService.warning('Ingresa directamente al canal');
                    }
                    this.dataLayerService.setFlag(this.flagPath, 'test');
                }, (err: any) => {
                    console.error(err);
                }
            );
        } else {
            this.alertService.warning('Debes seleccionar una conversación para probar')
        }
    }

    downloadMessages() {
        let searchIn;
        let dateStart;
        let dateEnd;
        if (this.conversationTypes.value) {
            searchIn = this.conversationTypes.value.map((x) => x.key).join(',');
        }
        if (this.range.value.start) {
            dateStart = this.range.value.start.toISOString().substring(0, 10);
        }
        if (this.range.value.end) {
            dateEnd = this.range.value.end.toISOString().substring(0, 10);
        }

        this.conversationDrivenDevelopmentService.downloadConversationsEmail(this.customerSlug, searchIn, this.search, dateStart, dateEnd).subscribe(
            (data: any) => {
                // const type = 'text/csv';
                // const name = 'gus_conversations.csv';

                // const { URL: { createObjectURL, revokeObjectURL }, setTimeout } = window;

                // const blob = new Blob([data], { type });
                // const url = createObjectURL(blob);

                // const anchor = document.createElement('a');
                // anchor.setAttribute('href', url);
                // anchor.setAttribute('download', name);
                // anchor.click();
                // document.body.removeChild(anchor);

                // setTimeout(() => { revokeObjectURL(url) }, 100)
                if (data.status === 'PROCESSING') {
                    this.modalService.open('popup-xlsx-email');
                    this.dataLayerService.setFlag(this.flagPath, 'export');
                } else {
                    this.alertService.error(data.status + ': Hubo un error al descargar las conversaciones');
                }
            },
            err => {
                console.log(err);
                this.alertService.error('Hubo un error al descargar las conversaciones');
            }
        );
    }

    redirectHelpCenter(incidenceType) {
        this.dataLayerService.setFlag(this.flagPath, 'Conversation -> help');
        const incidenceLink = this.getIncidenceDescByType(incidenceType).link;
        window.open(incidenceLink, '_blank');
    }

    openModal(id: string, username='') {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    openExamples() {
        if (this.lastSelectionPhrase && this.lastSelectionPhrase.hasOwnProperty('end')) {
            this.setPositionModal();
            this.showExampleOptions = true;
        }
    }

    closeExamples() {
        this.showExampleOptions = false;
    }

    selectElementContents(el) {
        el = el.target;
        const sel = window.getSelection();
        const range = sel.getRangeAt(0);
        if (range.startOffset !== range.endOffset) {
            this.lastSelectionPhrase = {
                parent: el,
                selection: sel,
                node: sel.focusNode,
                start: range.startOffset,
                end: range.endOffset
            };
        }
    }

    getTextOffset(parent, index) {
        if (parent.nodeType === 3)
            return {'node': parent, 'offset': index};
        var e = parent.childNodes[0], i = index, prev = null;
        while (1) {
            while (e.nodeType !== 3 || e === prev)
                if (e.childNodes && e.childNodes.length)
                    e = e.childNodes[0];
                else if (e.nextSibling)
                    e = e.nextSibling;
                else {
                    while (!e.nextSibling)
                        if (!e.parentNode || e.parentNode === parent)
                            throw RangeError('Index out of range');
                        else
                            e = e.parentNode;
                    e = e.nextSibling;
                }
            if (e.data.length < i)
                i -= e.data.length, prev = e;
            else
                return {'node': e, 'offset': i};
        }
    }

    visualLength(html) {
        var ruler = $(".ruler");
        ruler.html(html);
        return ruler.outerWidth();
    }

    setPositionModal() {
        const selection = this.lastSelectionPhrase.selection;
        let positionKey = selection.anchorOffset;
        if (selection.anchorOffset > selection.focusOffset) {
            positionKey = selection.focusOffset;
        }
        // const text = this.markupToString(textarea.html().toString());
        const text = selection.anchorNode.textContent;
        if (
            positionKey === 0 ||
            text.slice(positionKey - 1, positionKey) === ' ' ||
            text.slice(positionKey - 1, positionKey) === ' '
        ) {
            // console.log($(selection.anchorNode).closest('.examples-list-box'));
            const elem = $(selection.anchorNode.parentElement);
            let position = elem.offset();
            const visulaLengthAtSign = this.visualLength(text.slice(0, positionKey));
            let left = position.left - (($(document).outerWidth() - $('.add-intention').outerWidth()) / 2) + visulaLengthAtSign - 105;
            let top = position.top - 240;

            if (elem.hasClass('examples-list-box')) {
                left += 12;
                top += 12;
            }

            this.positionExampleOptions = `top: ${top}px; left: ${left}px`;
        }
    }

    selectEntityEvent(entity) {
        this.highlight(this.lastSelectionPhrase.node, this.lastSelectionPhrase.start, this.lastSelectionPhrase.end, this.lastSelectionPhrase.type);
        const dataEntityAtt = document.createAttribute("data-entity");
        dataEntityAtt.value = entity.name;
        this.lastSelectionPhrase.highlight.setAttributeNode(dataEntityAtt);
        this.lastSelectionPhrase.highlight.innerText += ': ' + entity.name
        this.showExampleOptions = false;
        this.phraseInitialize();
    }

    highlight(node, start, end, type="entity") {
        const id = joint.util.uuid();
        var r, o, hi;
        r = document.createRange()
        o = this.getTextOffset(node, start);
        r.setStart(o.node, o.offset);
        o = this.getTextOffset(node, end);
        r.setEnd(o.node, o.offset);
        hi = document.createElement('span');
        hi.classList.add(type + '-chip');
        hi.id = id;
        hi.contentEditable = false;
        hi.appendChild(r.extractContents());
        r.insertNode(hi);
        r.detach();

        const dataWordAtt = document.createAttribute("data-word");
        dataWordAtt.value = hi.innerText;
        hi.setAttributeNode(dataWordAtt);
        this.lastSelectionPhrase.highlight = hi;
        this.lastSelectionPhrase.type = type;
    }

    getIntentions() {
        this.currentDiagram.cells.forEach(cell => {
            if (cell.hasOwnProperty('attrs') && cell.attrs.hasOwnProperty('functionalityTemplate') && cell.attrs.functionalityTemplate){
                const key = Object.keys(cell.attrs.functionalityTemplate)[0];
                const funct = cell.attrs.functionalityTemplate[key];
                const requirements = funct.requerimientos;
                Object.keys(requirements).forEach(requirementKey => {
                    const requirement = requirements[requirementKey];
                    Object.keys(requirement).forEach(reqKey => {
                        const req = requirement[reqKey];
                        if (req && req.hasOwnProperty('type') && req.type === 'NLPExample') {
                            this.functionalityIntentions.push({
                                functKey: key,
                                functName: cell.attrs.bodyText.textWrap.text,
                                intentions: JSON.parse(req.value).intentions
                            });
                        }
                    });
                });
            }
        });
    }

    getIntentionByName(name) {
        this.currentIntentions = this.functionalityIntentions.filter(intention => {
            intention.intentions = intention.intentions.filter(i => {
                return name.split('|')[0] === i.name;
            });
            return intention.intentions.length > 0;
        });
    }

    getEntities(platformSlug, success=null) {
        this.diagramService.getLast(this.customerSlug, platformSlug).subscribe(
            (diagram: any) => {
                const jsonDiagram = JSON.parse(diagram.json);
                this.currentDiagram = jsonDiagram;
                this.currentDiagramId = diagram.id;
                if (jsonDiagram.hasOwnProperty('entities') && jsonDiagram.entities) {
                    this.entities = jsonDiagram.entities;
                }
                if (jsonDiagram.hasOwnProperty('cdd') && jsonDiagram.cdd) {
                    this.cdd = jsonDiagram.cdd;
                }
                if (success && typeof success === 'function'){
                    success(diagram);
                }
                this.getIntentions();
            }, err => {
                console.error(err);
            }
        );
    }

    showModalPhrase(intentionName) {
        this.getIntentionByName(intentionName);
        this.openModal('popup-add-phrase-cdd');
    }

    createEntity() {
        const entity: Entity = {
            id: joint.util.uuid(),
            editing: false,
            entityType: 'EXTRACTOR',
            groups: [],
            groupsTxt: '',
            main: false,
            name: '@' + this.newEntity,
            regex: ['##entidad'],
            regexTxt: '##entidad',
            state: 'trained',
            type: 'entity'
        };
        this.saveEntity(entity);
    }

    saveEntity(entity) {
        this.getEntities(this.currentConversation.channel, (diagram) => {
            const diagramJson = JSON.parse(diagram.json);
            diagramJson.entities.push(entity);
            const diagramJsonStr = JSON.stringify(diagramJson);
            const platformSlug = this.currentConversation.channel;
            this.diagramService.updateJsonById(diagramJsonStr, diagram.id, this.customerSlug, platformSlug).subscribe(
                (res: any) => {
                    this.entities.push(entity);
                    this.closeModal('popup-add-entity-cdd');
                }, err => {
                    console.error(err);
                }
            );
        });
    }

    private htmlToArrayExamples(htmlExamples) {
        const re = /<span class="entity-chip"[^>]*>(.*?)<\/span>/g;
        const matches = [...(htmlExamples.matchAll(re))];
        for (let index = 0; index < matches.length; index++) {
            const match = matches[index];
            const tempSpan = $(match[0]);
            const entity = tempSpan.data('entity');
            const word = tempSpan.data('word');
            htmlExamples = htmlExamples.replace(match[0], `[${word}](${entity})`);
        }
        if (htmlExamples.indexOf('<p') >= 0) {
            htmlExamples = htmlExamples.replace(/<p[^>]*>/g, '\n<p>');
            htmlExamples = htmlExamples.replace(/<p[^>]*><\/p>/g, '');
            htmlExamples = htmlExamples.replace(/<p[^>]*>/g, '');
            htmlExamples = htmlExamples.replaceAll('<\/p>', '\n');
        } else {
            htmlExamples += '\n';
        }
        const examples =  htmlExamples.split('\n');
        const regex = /(\[[^\[]+\])$|(\[[^\[]+\])[^\(]|(\[[^\[]+\]\(\))|(\[\]\(@[^\)]+\))|[^\]](\(@[^\)]+\))|^(\(@[^\)]+\))/g;
        const ex = examples.map((item) => {
            let str = item.replace(/<.*[^>]*>(.*)<\/.*[^>]*>/g, '$1').replace(/<br?\/?>/g, '').replace('&nbsp;', ' ');
            let m;

            while ((m = regex.exec(str)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                m.forEach((match, groupIndex) => {
                    if (match) {
                        str = str.replace(match, '');
                    }
                });
            }
            return str;
        });
        return ex.filter((item)=>item);
    }

    private arrayToHtmlExamples(examplesArr) {
        let result = '';
        const re = /\[([^\]]*)\]\((@[^\)]*)\)/g;
        for (let index = 0; index < examplesArr.length; index++) {
            let example = examplesArr[index];
            const matches = [...(example.matchAll(re))];
            for (let index = 0; index < matches.length; index++) {
                const id = joint.util.uuid();
                const match = matches[index][0];
                const word = matches[index][1]
                const entity = matches[index][2];
                example = example.replace(match, `<span class="entity-chip" id="${id}" data-word="${word}" data-entity="${entity}" contenteditable="false">${word}: ${entity}</span>`);
            }
            result += `<p>${example}</p>`;
        }
        return result;
    }

    private getEntitiesFromStr(str) {
        const re = /\((@[^\)]*)\)/g;
        const matches = [...(str.matchAll(re))];
        const entities = [];
        for (let index = 0; index < matches.length; index++) {
            const match = matches[index][1].replace('(', '').replace(')', '');
            const res = entities.find(entityAux => entityAux === match);
            if (!res) {
                entities.push(match);
            }
        }
        return entities;
    }

    deletePhrase() {
        const cdd = JSON.parse(JSON.stringify(this.cdd));
        cdd.intentions = cdd.intentions.filter(e => e.id !== this.currentPhraseId);
        this.getEntities(this.currentConversation.channel, (diagram) => {
            const diagramJson = JSON.parse(diagram.json);
            diagramJson.cdd = cdd;
            const diagramJsonStr = JSON.stringify(diagramJson);
            const platformSlug = this.currentConversation.channel;
            this.diagramService.updateJsonById(diagramJsonStr, diagram.id, this.customerSlug, platformSlug).subscribe(
                (res: any) => {
                    this.closeModal('popup-delete-phrase-cdd');
                    this.cdd = cdd;
                    this.currentPhraseId = '';
                    this.alertService.success('se ha eliminado la frase');
                }, err => {
                    console.error(err);
                }
            );
        });
    }

    saveDiagramPhrase() {
        // this.getEntities(this.currentConversation.channel, (diagram) => {
        this.saveDiagram(this.currentDiagramId, this.currentDiagram, (res) => {
            this.closeModal('popup-add-phrase-cdd');
            this.cdd = this.currentDiagram.cdd;
            this.currentPhraseId = '';
            this.changePhrase();
            this.alertService.success('Se ha guardado la frase');
        });
        // });
    }

    saveDiagram(diagramId, diagramJson, success=null) {
        const diagramJsonStr = JSON.stringify(diagramJson);
        const platformSlug = this.currentConversation.channel;
        this.diagramService.updateJsonById(diagramJsonStr, diagramId, this.customerSlug, platformSlug).subscribe(
            (res: any) => {
                if (res.updated === 1) {
                    if (typeof success === 'function') {
                        success(res);
                    }
                } else {
                    this.alertService.error('No se ha podido guardar la frase en el diagrama');
                }
            }, err => {
                this.alertService.error('No se ha podido guardar la frase en el diagrama');
                console.error(err);
            }
        );
    }

    changePhrase() {
        if (this.currentPhraseId) {
            const phraseObj = this.getPhraseById(this.currentPhraseId);
            this.nameIntention = phraseObj.name;
            const examplesHtml = this.arrayToHtmlExamples(phraseObj.ejemplos);
            $(this.examplesList.nativeElement).html(examplesHtml);
        } else {
            this.nameIntention = '';
            $(this.examplesList.nativeElement).text('');
        }
    }

    getPhraseById(id) {
        return this.cdd.intentions.find(e => e.id === id)
    }

    // getIntentions() {
    //     this.getEntities(this.currentConversation.channel);
    // }

    savePhrase() {
        const exStr = $(this.examplesList.nativeElement).html();
        const examples = this.htmlToArrayExamples(exStr);
        const entities = this.getEntitiesFromStr(examples.join(','));
        const intention = {
            name: this.nameIntention,
            ejemplos: examples,
            entidades: entities,
            id: joint.util.uuid()
        };
        if (this.currentPhraseId) {
            intention.id = this.currentPhraseId;
            const index = this.cdd.intentions.findIndex(e => e.id === this.currentPhraseId);
            if (index >= 0) {
                this.cdd.intentions[index] = intention;
            }
        } else {
            this.cdd.intentions.push(intention);
        }

        const cdd = JSON.parse(JSON.stringify(this.cdd));
        this.currentDiagram.cdd = cdd;
        this.saveDiagramPhrase();
    }

    isAllowedMessage(elasticMessage, i, type) {
        let response = false;
        if (type === 'bot') {
            if (elasticMessage.hasOwnProperty('messageBotType') && elasticMessage.messageBotType) {
                response = !this.deniedMsgsTypes.includes(elasticMessage.messageBotType[i])
            }
        } else if (type === 'operator') {

        }
        return response;
    }


    deleteFunctionalityPhrase(elem, example) {
        this.currentDiagram.cells.forEach((cell, cellIdx) => {
            if (cell.id === elem.functKey.split('|')[1]){
                const key = Object.keys(cell.attrs.functionalityTemplate)[0];
                const functionalityKey = Object.keys(cell.attrs.functionality)[0];
                const funct = cell.attrs.functionalityTemplate[key];
                const requirements = funct.requerimientos;
                Object.keys(requirements).forEach(requirementKey => {
                    const requirement = requirements[requirementKey];
                    Object.keys(requirement).forEach(reqKey => {
                        const req = requirement[reqKey];
                        if (req && req.hasOwnProperty('type') && req.type === 'NLPExample') {
                            // delete from diagram
                            const intentionsValue = JSON.parse(req.value);
                            elem.intentions.forEach((intention2, intentionIdx2) => {
                                const intentions2 = intentionsValue.intentions;
                                const intentionIdx = intentions2.findIndex((intention)=> elem.intentions[intentionIdx2].name === intention.name);
                                const examples = intentionsValue.intentions[intentionIdx].ejemplos;
                                intentionsValue.intentions[intentionIdx].ejemplos = examples.filter(example2 => example !== example2);
                            });
                            const intValue = JSON.stringify(intentionsValue);
                            this.currentDiagram.cells[cellIdx].attrs.functionalityTemplate[key].requerimientos[requirementKey][reqKey].value = intValue;
                            this.currentDiagram.cells[cellIdx].attrs.functionality[functionalityKey].requerimientos[requirementKey][reqKey].value = intValue;
                            this.currentDiagram.cells[cellIdx].attrs.functionalityValue[requirementKey][reqKey].value = intValue;

                            // deletefrom visualization
                            this.currentIntentions.forEach((element, intentionsIdx) => {
                                element.intentions.forEach((intention4, idx4) => {
                                    intention4.ejemplos = intention4.ejemplos.filter(e => example !== e);
                                    element.intentions[idx4].ejemplos = intention4.ejemplos;
                                });
                                this.currentIntentions[intentionsIdx].intentions = element.intentions;
                            });

                        }
                    });
                });
            }
        });
    }

    formatUserMsg(messageUser) {
        const object = {
            type: 'text',
            val: ''
        };
        const regexImg = /(https?:\/\/.*\.(?:png|jpg|jpe|jpeg))/i;
        if (regexImg.test(messageUser)) {
            object.type = 'image';
            object.val = messageUser;
        }
        const regexVideo = /(https?:\/\/.*\.(?:mp4|3gp))/i;
        if (regexVideo.test(messageUser)) {
            object.type = 'video';
            object.val = messageUser;
        }
        const regexAudio = /(https?:\/\/.*\.(?:aac|amr|mpeg|ogg))/i;
        if (regexAudio.test(messageUser)) {
            object.type = 'audio';
            object.val = messageUser;
        }
        if (object.type === 'text') {
            object.val = messageUser;
        }
        return object;
    }

    onChangeConversationType() {
        this.dataLayerService.setFlag(this.flagPath, 'Filter -> searchIn');
    }

    onChangeSearch() {
        this.dataLayerService.setFlag(this.flagPath, 'Filter -> keyword');
    }

    onChangeRange() {
        this.dataLayerService.setFlag(this.flagPath, 'Filter -> date');
    }
}
