import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

import { AuthServices } from '../services/auth.guard.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private AuthService: AuthServices
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.AuthService.loggedIn()) {
            return true;
        }

        if (!window.location.href.toString().includes('redirect_uri')) {
            console.log(localStorage);
            console.log('Redirect authguard');
            const pathname = window.location.pathname.substring(1);
            const search = window.location.search;
            const url = encodeURIComponent(pathname);
            if (window.location.href === environment.baseUrl) {
                window.location.href = '/';
            } else {
                // this.router.navigate(['/'], { queryParams: { redirect_uri: url } });
                if (url != '') {
                    window.location.href = '/?redirect_uri=' + url + search;
                } else {
                    window.location.href = '/' + search;
                }
            }
        }
        return false;
    }
}

