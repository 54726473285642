import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of, EMPTY } from 'rxjs';
import { map, concatMap, finalize } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { Account } from '../models/account';
import { AuthServices } from './auth.guard.service';
import { AlertService } from './alert.service';
import { DataLayerService } from 'src/services/data-layer.service';

declare const FB: any;
declare const fbq: any;

@Injectable({ providedIn: 'root' })
export class AccountService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;
    flagPath: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        private authService: AuthServices,
        private alertService: AlertService,
        private dataLayerService: DataLayerService
    ) {
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();
        this.flagPath = '';
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }

    login(extraScopes: string = '') {
        // login with facebook then authenticate with the API to get a JWT auth token
        this.facebookLogin(extraScopes)
            .pipe(concatMap(accessToken => this.apiAuthenticate(accessToken)))
            .subscribe(() => {
                // get return url from query parameters or default to home page
                // const returnUrl = this.route.snapshot.queryParams['redirect_uri'] || this.route.snapshot.queryParams['returnUrl'] || '/';
                // // this.router.navigateByUrl(returnUrl);
                // window.document.location.href = returnUrl;
            });
    }

    facebookLogin(scopes: string) {
        // login with facebook and return observable with fb access token on success
        return from(new Promise<any>(resolve => {
            fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {
                appId: environment.facebook.clientId,
                feature: 'whatsapp_embedded_signup'
            });

            const fbLoginOptions = {
                scope: environment.facebook.loginOptions.scope,
                return_scopes: environment.facebook.loginOptions.return_scopes,
                enable_profile_selector: environment.facebook.loginOptions.enable_profile_selector,
            };
            if (scopes !== '') {
                fbLoginOptions.scope = scopes;
                fbLoginOptions['extras'] = {
                    feature: 'whatsapp_embedded_signup',
                    setup: {}
                }
            }

            return FB.login(resolve, fbLoginOptions);
        })).pipe(concatMap(({ authResponse }) => {
                if (!authResponse) return EMPTY;
                return of(authResponse.accessToken);
        }));
    }

    apiAuthenticate(accessToken: string) {
        localStorage.setItem('fbToken', accessToken);
        // authenticate with the api using a facebook access token,
        // on success the api returns an account object with a JWT auth token
        // return this.http.post<any>(`${baseUrl}/authenticate`, { accessToken })
        //     .pipe(map(account => {
        //         this.accountSubject.next(account);
        //         this.startAuthenticateTimer();
        //         return account;
        //     }));
        const version = environment.facebook.version;
        const fields = environment.facebook.loginOptions.fields;
        const graph = environment.facebook.graph;
        const url = `${graph}${version}/me?fields=${fields}&access_token=${accessToken}`;
        return this.http.get(url).pipe(map((account: Account) => {
                this.accountSubject.next(account);
                // this.startAuthenticateTimer();
                if (!localStorage.getItem('token')) {
                    const userData = {
                        user_name: account.email,
                        email: account.email,
                        name: account.name,
                        user_id: account.id,
                        avatar: account.picture.data.url,
                        token: accessToken,
                        auth_type: 'FACEBOOK'
                    };
                    this.loginGus(userData);
                }
                return account;
            }));
    }

    loginGus(userData) {
        if (userData.email && userData.email !== '') {
            this.authService.registerGoogle(userData).subscribe(
                (res: any) => {
                    res.role.name = 'UX_DEV_BI_ADMIN';
                    if (
                        res.status === 'ACTIVE' &&
                        res.role.name !== 'CUSTOM'
                    ) {
                        window['dataLayer'].push({
                            'event': 'login',
                            'constructorUserId': res.id,
                            'name': res.name,
                            'email': res.email,
                            'authType': 'FACEBOOK'
                        });
                        localStorage.setItem('Auth-Type', userData.auth_type);
                        localStorage.setItem('payload', JSON.stringify(userData));
                        localStorage.setItem('token', res.currentToken.token);
                        localStorage.setItem('id', res.googleId);
                        localStorage.setItem('photo', res.avatar);
                        localStorage.setItem('name', res.name);
                        localStorage.setItem('email', res.email);
                        localStorage.setItem('constructorUserId', res.id);

                        this.dataLayerService.setFlag(this.flagPath, 'LogIn Facebook - email');
                        this.dataLayerService.setFlag(this.flagPath, 'LogIn Facebook - log');

                        this.alertService.success('Bienvenido!');

                        this.route.queryParams.subscribe(
                            (params) => {
                                if ('redirect_uri' in params) {
                                    const redirectUri = decodeURIComponent(
                                        params['redirect_uri']
                                    );
                                    window.location.href = environment.baseUrl + redirectUri;
                                } else {
                                    window.location.href = '/customers';
                                }
                            }
                        );
                    } else {
                        this.alertService.warning(
                            'Tu usuario no se encuentra activo aún, \
                        contacta con un admministrador para activarlo.'
                        );
                    }
                },
                (err) => {
                    this.alertService.error('No se ha podido realizar el login.');
                    this.logout();
                }
            );
        } else {
            this.alertService.error(
                'La cuenta con la que intentas entrar no tiene un email confimado, confirmalo y vuelva a intentar'
            );
        }
    }

    logout() {
        // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
        // FB.api('/me/permissions', 'delete', null, () => FB.logout());
        FB.logout();
        // this.stopAuthenticateTimer();
        this.accountSubject.next(null);
    }

    // helper methods

    // private authenticateTimeout;

    // private startAuthenticateTimer() {
    //     // parse json object from base64 encoded jwt token
    //     console.log(this.accountValue);
    //     const jwtToken = JSON.parse(atob(this.accountValue.token.split('.')[1]));

    //     // set a timeout to re-authenticate with the api one minute before the token expires
    //     const expires = new Date(jwtToken.exp * 1000);
    //     const timeout = expires.getTime() - Date.now() - (60 * 1000);
    //     const { accessToken } = FB.getAuthResponse();
    //     this.authenticateTimeout = setTimeout(() => {
    //         this.apiAuthenticate(accessToken).subscribe();
    //     }, timeout);
    // }

    // private stopAuthenticateTimer() {
    //     // cancel timer for re-authenticating with the api
    //     clearTimeout(this.authenticateTimeout);
    // }
}
