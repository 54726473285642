<div class="validation-template-box clearfix">
    <div class="instructions">
        <h4>
            {{translate.sections.campaigns.validationTemplate.startMessage}}
        </h4>
    </div>
    <div class="validation-template">
        <div class="clearfix two-fields"  *ngIf="channelSmooch != 'SMOOCH_SMS'">
            <div class="field-box half-1">
                <label>{{translate.sections.campaigns.validationTemplate.templateType}}</label>
                <select [(ngModel)]="templateType" (change)="changeTemplate()">
                    <option *ngFor="let templateTypeAux of templateTypes" [value]="templateTypeAux.idx">
                        {{templateTypeAux.value}}
                    </option>
                </select>
            </div>
            <div class="field-box half-2">
                <label>{{translate.sections.campaigns.validationTemplate.language}}</label>
                <select [(ngModel)]="dataMessage.languageCode" (change)="changeLanguage()">
                    <option *ngFor="let language of languageTypes" [value]="language.idx">
                        {{language.value}}
                    </option>
                </select>
            </div>
        </div>
        <div class="chips" *ngIf="templateType === 'BUTTONS'">
            <ul>
                <li *ngFor="let buttonSetType of buttonSetTypes">
                    <button (click)="selectButtonSetType(buttonSetType.idx)" [ngClass]="{'active': dataMessage.buttonSetType === buttonSetType.idx, 'chip': true}">
                        {{buttonSetType.value}}
                    </button>
                </li>
            </ul>
        </div>

        <div class="template-carousel" *ngIf="channelSmooch != 'SMOOCH_SMS'">

            <div class="slides">

                <div *ngIf="templateType === 'STANDARD'">
                    <div class="template-slide" [ngClass]="{'active': activeSlide[0]}" (click)="selectSlide(0, 0)">
                        <div class="message">
                            <div class="header" *ngIf="dataMessage.showHeader">
                                <div class="text" *ngIf="dataMessage.mediaType === 'TEXT' || channelSmooch != 'SMOOCH_SMS'">
                                    <strong>{{translate.sections.campaigns.validationTemplate.headerMessage}}</strong>
                                </div>
                                <div class="image" *ngIf="dataMessage.mediaType === 'IMAGE'">
                                    <img src="/assets/img/image.jpeg" alt="Imagen">
                                </div>
                                <div class="video" *ngIf="dataMessage.mediaType === 'VIDEO'">
                                    <img src="/assets/img/video.jpeg" alt="Video">
                                </div>
                                <div class="document" *ngIf="dataMessage.mediaType === 'DOCUMENT'">
                                    <div class="file-icon pdf"></div>
                                    <span class="ellipsis">nombre_doc.pdf</span>
                                </div>
                            </div>

                            <div class="body">
                                <p>
                                    {{translate.sections.campaigns.validationTemplate.templateMessage}}. <br>
                                    <strong>{{translate.sections.campaigns.validationTemplate.chooseMessage}}</strong>
                                </p>
                            </div>

                            <div class="footer" *ngIf="dataMessage.showFooter">
                                <p>{{translate.sections.campaigns.validationTemplate.footPageMessage}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <ngu-carousel #myCarousel2 [inputs]="carouselConfig" [dataSource]="carouselTileItems">
                    <div *nguCarouselDef="let item;" class="item">

                        <div *ngIf="templateType === 'BUTTONS' && dataMessage?.buttonSetType === 'QUICK_REPLY' && item === 0">
                            <div class="template-slide" [ngClass]="{'active': activeSlide[1]}" (click)="selectSlide(1, 3)">
                                <div class="message">
                                    <div class="header" *ngIf="dataMessage.showHeader">
                                        <div class="text" *ngIf="dataMessage.mediaType === 'TEXT'">
                                            <strong>{{translate.sections.campaigns.validationTemplate.headerMessage}}</strong>
                                        </div>
                                        <div class="image" *ngIf="dataMessage.mediaType === 'IMAGE'">
                                            <img src="https://gus.chat/wp-content/uploads/2019/03/arte-movilidad-nota2018.jpg" alt="Imagen">
                                        </div>
                                        <div class="document" *ngIf="dataMessage.mediaType === 'DOCUMENT'">
                                            <div class="file-icon pdf"></div>
                                            <span class="ellipsis">nombre_doc.pdf</span>
                                        </div>
                                    </div>

                                    <div class="body">
                                        <p>
                                            {{translate.sections.campaigns.validationTemplate.hi}}<br>
                                            {{translate.sections.campaigns.validationTemplate.templateMessage1}} <strong>3 {{translate.sections.campaigns.validationTemplate.templateMessage2}}.</strong>
                                        </p>
                                    </div>

                                    <div class="footer" *ngIf="dataMessage.showFooter">
                                        <p>{{translate.sections.campaigns.validationTemplate.footPageMessage}}</p>
                                    </div>
                                </div>
                                <div class="buttons">
                                    <div [ngClass]="['QUICK_REPLY', 'btn3']">
                                        <button>
                                            Button 1
                                        </button>
                                        <button>
                                            Button 2
                                        </button>
                                        <button>
                                            Button 3
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="templateType === 'BUTTONS' && dataMessage?.buttonSetType === 'QUICK_REPLY' && item === 1">
                            <div class="template-slide" [ngClass]="{'active': activeSlide[2]}" (click)="selectSlide(2, 2)">
                                <div class="message">
                                    <div class="header" *ngIf="dataMessage.showHeader">
                                        <div class="text" *ngIf="dataMessage.mediaType === 'TEXT'">
                                            <strong>{{translate.sections.campaigns.validationTemplate.headerMessage}}</strong>
                                        </div>
                                        <div class="image" *ngIf="dataMessage.mediaType === 'IMAGE'">
                                            <img src="https://gus.chat/wp-content/uploads/2019/03/arte-movilidad-nota2018.jpg" alt="Imagen">
                                        </div>
                                        <div class="document" *ngIf="dataMessage.mediaType === 'DOCUMENT'">
                                            <div class="file-icon pdf"></div>
                                            <span class="ellipsis">nombre_doc.pdf</span>
                                        </div>
                                    </div>

                                    <div class="body">
                                        <p>
                                            {{translate.sections.campaigns.validationTemplate.hi}}<br>
                                            {{translate.sections.campaigns.validationTemplate.templateMessage1}} <strong>2 {{translate.sections.campaigns.validationTemplate.templateMessage2}}.</strong>
                                        </p>
                                    </div>

                                    <div class="footer" *ngIf="dataMessage.showFooter">
                                        <p>{{translate.sections.campaigns.validationTemplate.footPageMessage}}</p>
                                    </div>
                                </div>
                                <div class="buttons">
                                    <div [ngClass]="['QUICK_REPLY', 'btn2']">
                                        <button>
                                            Button 1
                                        </button>
                                        <button>
                                            Button 2
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="templateType === 'BUTTONS' && dataMessage?.buttonSetType === 'QUICK_REPLY' && item === 2">
                            <div class="template-slide" [ngClass]="{'active': activeSlide[3]}" (click)="selectSlide(3, 1)">
                                <div class="message">
                                    <div class="header" *ngIf="dataMessage.showHeader">
                                        <div class="text" *ngIf="dataMessage.mediaType === 'TEXT'">
                                            <strong>{{translate.sections.campaigns.validationTemplate.headerMessage}}</strong>
                                        </div>
                                        <div class="image" *ngIf="dataMessage.mediaType === 'IMAGE'">
                                            <img src="https://gus.chat/wp-content/uploads/2019/03/arte-movilidad-nota2018.jpg" alt="Imagen">
                                        </div>
                                        <div class="document" *ngIf="dataMessage.mediaType === 'DOCUMENT'">
                                            <div class="file-icon pdf"></div>
                                            <span class="ellipsis">nombre_doc.pdf</span>
                                        </div>
                                    </div>

                                    <div class="body">
                                        <p>
                                            {{translate.sections.campaigns.validationTemplate.hi}}<br>
                                            {{translate.sections.campaigns.validationTemplate.templateMessage1}} <strong>1 {{translate.sections.campaigns.validationTemplate.templateMessage2}}.</strong>
                                        </p>
                                    </div>

                                    <div class="footer" *ngIf="dataMessage.showFooter">
                                        <p>{{translate.sections.campaigns.validationTemplate.footPageMessage}}</p>
                                    </div>
                                </div>
                                <div class="buttons">
                                    <div [ngClass]="['QUICK_REPLY', 'btn1']">
                                        <button>
                                            Button 1
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="templateType === 'BUTTONS' && dataMessage?.buttonSetType === 'CALL_TO_ACTION' && item === 0">
                            <div class="template-slide" [ngClass]="{'active': activeSlide[4]}" (click)="selectSlide(4, 2)">
                                <div class="message">
                                    <div class="header" *ngIf="dataMessage.showHeader">
                                        <div class="text" *ngIf="dataMessage.mediaType === 'TEXT'">
                                            <strong>{{translate.sections.campaigns.validationTemplate.headerMessage}}</strong>
                                        </div>
                                        <div class="image" *ngIf="dataMessage.mediaType === 'IMAGE'">
                                            <img src="https://gus.chat/wp-content/uploads/2019/03/arte-movilidad-nota2018.jpg" alt="Imagen">
                                        </div>
                                        <div class="document" *ngIf="dataMessage.mediaType === 'DOCUMENT'">
                                            <div class="file-icon pdf"></div>
                                            <span class="ellipsis">nombre_doc.pdf</span>
                                        </div>
                                    </div>

                                    <div class="body">
                                        <p>
                                            {{translate.sections.campaigns.validationTemplate.hi}}<br>
                                            {{translate.sections.campaigns.validationTemplate.templateMessage1}} <strong>2 {{translate.sections.campaigns.validationTemplate.templateMessage3}}.</strong>
                                        </p>
                                    </div>

                                    <div class="footer" *ngIf="dataMessage.showFooter">
                                        <p>{{translate.sections.campaigns.validationTemplate.footPageMessage}}</p>
                                    </div>
                                </div>
                                <div class="buttons">
                                    <div [ngClass]="['CALL_TO_ACTION', 'btn2']">
                                        <button class="phone">
                                            <i class="material-icons">phone</i> {{translate.sections.campaigns.validationTemplate.call}}
                                        </button>
                                        <button class="url">
                                            <i class="material-icons">launch</i> {{translate.sections.campaigns.validationTemplate.viewWeb}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="templateType === 'BUTTONS' && dataMessage?.buttonSetType === 'CALL_TO_ACTION' && item === 1">
                            <div class="template-slide" [ngClass]="{'active': activeSlide[5]}" (click)="selectSlide(5, 1, 'PHONE_NUMBER')">
                                <div class="message">
                                    <div class="header" *ngIf="dataMessage.showHeader">
                                        <div class="text" *ngIf="dataMessage.mediaType === 'TEXT'">
                                            <strong>{{translate.sections.campaigns.validationTemplate.headerMessage}}</strong>
                                        </div>
                                        <div class="image" *ngIf="dataMessage.mediaType === 'IMAGE'">
                                            <img src="https://gus.chat/wp-content/uploads/2019/03/arte-movilidad-nota2018.jpg" alt="Imagen">
                                        </div>
                                        <div class="document" *ngIf="dataMessage.mediaType === 'DOCUMENT'">
                                            <div class="file-icon pdf"></div>
                                            <span class="ellipsis">nombre_doc.pdf</span>
                                        </div>
                                    </div>

                                    <div class="body">
                                        <p>
                                            {{translate.sections.campaigns.validationTemplate.hi}}<br>
                                            {{translate.sections.campaigns.validationTemplate.templateMessage1}} <strong>1 {{translate.sections.campaigns.validationTemplate.templateMessage3}}.</strong>
                                        </p>
                                    </div>

                                    <div class="footer" *ngIf="dataMessage.showFooter">
                                        <p>{{translate.sections.campaigns.validationTemplate.footPageMessage}}</p>
                                    </div>
                                </div>
                                <div class="buttons">
                                    <div [ngClass]="['CALL_TO_ACTION', 'btn1']">
                                        <button class="phone">
                                            <i class="material-icons">phone</i> {{translate.sections.campaigns.validationTemplate.call}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="templateType === 'BUTTONS' && dataMessage?.buttonSetType === 'CALL_TO_ACTION' && item === 2">
                            <div class="template-slide" [ngClass]="{'active': activeSlide[6]}" (click)="selectSlide(6, 1, 'URL')">
                                <div class="message">
                                    <div class="header" *ngIf="dataMessage.showHeader">
                                        <div class="text" *ngIf="dataMessage.mediaType === 'TEXT'">
                                            <strong>{{translate.sections.campaigns.validationTemplate.headerMessage}}</strong>
                                        </div>
                                        <div class="image" *ngIf="dataMessage.mediaType === 'IMAGE'">
                                            <img src="https://gus.chat/wp-content/uploads/2019/03/arte-movilidad-nota2018.jpg" alt="Imagen">
                                        </div>
                                        <div class="document" *ngIf="dataMessage.mediaType === 'DOCUMENT'">
                                            <div class="file-icon pdf"></div>
                                            <span class="ellipsis">nombre_doc.pdf</span>
                                        </div>
                                    </div>

                                    <div class="body">
                                        <p>
                                            {{translate.sections.campaigns.validationTemplate.hi}}<br>
                                            {{translate.sections.campaigns.validationTemplate.templateMessage1}} <strong>1 {{translate.sections.campaigns.validationTemplate.templateMessage3}}.</strong>
                                        </p>
                                    </div>

                                    <div class="footer" *ngIf="dataMessage.showFooter">
                                        <p>{{translate.sections.campaigns.validationTemplate.footPageMessage}}</p>
                                    </div>
                                </div>
                                <div class="buttons">
                                    <div [ngClass]="['CALL_TO_ACTION', 'btn1']">
                                        <button class="url">
                                            <i class="material-icons">launch</i> {{translate.sections.campaigns.validationTemplate.viewWeb}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <button NguCarouselNext class="circle-left" *ngIf="carouselTileItems.length > 1">
                        <i class="material-icons">chevron_right</i>
                    </button>
                    <button NguCarouselPrev class="circle-right" *ngIf="carouselTileItems.length > 1">
                        <i class="material-icons">chevron_left</i>
                    </button>
                </ngu-carousel>
            </div>

            <div class="header clearfix" *ngIf="channelSmooch != 'SMOOCH_SMS'">
                <h4>
                    <i class="material-icons help">help</i>{{translate.sections.campaigns.validationTemplate.header}}
                    <input [checked]="dataMessage.showHeader" type="checkbox" class="green" (change)="changeShowHeader(dataMessage.showHeader)">
                </h4>
                <div class="contentArea">
                    <div class="mediaType">
                        <ul>
                            <li *ngFor="let mediaType of mediaTypes">
                                <button class="btn-media-type"
                                    [ngClass]="{'active': dataMessage.mediaType === mediaType.idx}"
                                    (click)="changeMediaType(mediaType.idx)"
                                    [disabled]="!dataMessage.showHeader">
                                    <i [ngClass]="['material-icons', mediaType.idx]"></i>
                                </button>
                                <span>{{mediaType.value}}</span>
                            </li>
                        </ul>
                    </div>

                    <textarea
                        [(ngModel)]="dataMessage.headerText"
                        [disabled]="!dataMessage.showHeader"
                        *ngIf="dataMessage.mediaType === 'TEXT' || dataMessage.mediaType === 'NONE'"
                        (keyup)="onChangeHeaderText($event)"
                        placeholder={{translate.sections.campaigns.validationTemplate.titleMessage}}>
                    </textarea>
                    <p *ngIf="dataMessage.mediaType === 'TEXT'">
                        {{translate.sections.campaigns.validationTemplate.characterMessage}}: <span [ngClass]="{'counter-chars': true, 'exceeded': (60 - headerTextLen) < 0}">{{60 - headerTextLen}}</span>
                    </p>

                    <div class="mediaContent" *ngIf="dataMessage.mediaType === 'IMAGE' || dataMessage.mediaType === 'DOCUMENT' || dataMessage.mediaType === 'VIDEO'">
                        <div class="field-box upload-box images" appDnd (fileDropped)="onFileDropped($event)">
                            <div class="files-list" *ngIf="dataMessage.mediaContent && dataMessage.mediaContent !== '' && files.length === 0">
                                <div class="single-file">
                                    <div class="file-icon" *ngIf="dataMessage.mediaType === 'IMAGE'">
                                        <img src="{{dataMessage.mediaContent}}" alt="Imagen de la empresa1">
                                    </div>
                                    <div class="file-help">
                                        <span class="file-name ellipsis">{{ dataMessage.mediaContent }}</span>
                                    </div>

                                    <div class="file-delete" (click)="deleteFile(0)">
                                        <i class="material-icons md-20">delete_forever</i>
                                    </div>
                                </div>
                            </div>
                            <div class="files-list" *ngIf="files.length > 0">
                                <div class="single-file" *ngFor="let file of files; let i = index">
                                    <div class="file-icon" *ngIf="dataMessage.mediaType === 'IMAGE'">
                                        <img [ngClass]="{'hide': !dataMessage.mediaContent || dataMessage.mediaContent === ''}" src="{{dataMessage.mediaContent}}" alt="Imagen de la empresa2">
                                    </div>
                                    <div class="file-icon" *ngIf="dataMessage.mediaType === 'VIDEO'">
                                        <video controls class="video">
                                            <source [src]="dataMessage.mediaContent" type="video/mp4">
                                        </video>
                                    </div>
                                    <div class="file-help">
                                        <span class="file-name">{{ file?.name }}</span>
                                    </div>

                                    <div class="file-delete" (click)="deleteFile(i)">
                                        <i class="material-icons md-20">delete_forever</i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="(!dataMessage.mediaContent || dataMessage.mediaContent === '') && dataMessage.mediaType === 'IMAGE'" class="files-upload">
                                <i [ngClass]="{'material-icons': true}">image</i>
                                <input hidden type="file" accept=".jpg, .jpeg, .png" #uploader (change)="fileBrowseHandler($event)" />
                                <strong>{{translate.sections.campaigns.validationTemplate.dragAndDropImage}}</strong>
                                <strong>{{translate.sections.campaigns.validationTemplate.o}}</strong>
                                <span>{{translate.sections.campaigns.validationTemplate.click}} <button #buttonUploadC id="buttonUploadFile"  (click)="uploader.click()">{{translate.sections.campaigns.validationTemplate.clickHereToUpload}}</button></span>
                            </div>
                            <div *ngIf="(!dataMessage.mediaContent || dataMessage.mediaContent === '') && dataMessage.mediaType === 'VIDEO'" class="files-upload">
                                <i [ngClass]="{'material-icons': true}">play_circle_outline</i>
                                <input hidden type="file" accept=".mp4" #uploader (change)="fileBrowseHandler($event, true)" />
                                <strong>{{translate.sections.campaigns.validationTemplate.dragAndDropVideo}}</strong>
                                <strong>{{translate.sections.campaigns.validationTemplate.o}}</strong>
                                <span>{{translate.sections.campaigns.validationTemplate.click}} <button #buttonUploadC id="buttonUploadFile"  (click)="uploader.click()">{{translate.sections.campaigns.validationTemplate.clickHereToUpload2}}</button></span>
                            </div>
                            <div *ngIf="(!dataMessage.mediaContent || dataMessage.mediaContent === '') && dataMessage.mediaType === 'DOCUMENT'" class="files-upload">
                                <i [ngClass]="{'material-icons': true}">attachment</i>
                                <input hidden type="file" accept=".pdf" #uploader (change)="fileBrowseHandler($event)" />
                                <strong>{{translate.sections.campaigns.validationTemplate.dragAndDropDoc}}</strong>
                                <strong>{{translate.sections.campaigns.validationTemplate.o}}</strong>
                                <span>{{translate.sections.campaigns.validationTemplate.click}} <button #buttonUploadC id="buttonUploadFile"  (click)="uploader.click()">{{translate.sections.campaigns.validationTemplate.clickHereToUpload2}}</button></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(!dataMessage.mediaContent || dataMessage.mediaContent === '') && dataMessage.mediaType === 'DOCUMENT'" class="">
                        <input type="text" [(ngModel)]="dataMessage.mediaContent" placeholder="URL" />
                    </div>
                </div>
            </div>
            <div class="footer clearfix"  *ngIf="channelSmooch != 'SMOOCH_SMS'">
                <h4>
                    <i class="material-icons help">help</i>{{translate.sections.campaigns.validationTemplate.footPage}}
                    <input [checked]="dataMessage.showFooter" type="checkbox" class="green" (change)="changeShowFooter(dataMessage.showFooter)">
                </h4>
                <div class="contentArea">
                    <textarea [(ngModel)]="dataMessage.footerText" [disabled]="!dataMessage.showFooter" [placeholder]="translate.sections.campaigns.validationTemplate.footerMessage" (keyup)="onChangeFooterText($event)"></textarea>
                    <p>
                        {{translate.sections.campaigns.validationTemplate.characterMessage}}: <span [ngClass]="{'counter-chars': true, 'exceeded': (60 - footerTextLen) < 0}">{{60 - footerTextLen}}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="clearfix two-fields">
            <div class="field-box half-1">
                <label>{{translate.sections.campaigns.validationTemplate.category}}</label>
                <select [(ngModel)]="dataMessage.category" (change) = "updateCategoryMessage()">
                    <option *ngFor="let category of categories" [value]="category.value" [disabled]="templateType == 'BUTTONS' && category.value == 'AUTHENTICATION'">
                        {{category.label}}
                    </option>
                </select>
                <p class="clearfix inner-message">{{currentCategoryMessage}}</p>

            </div>

            <div class="field-box half-2">
                <label>{{translate.sections.campaigns.validationTemplate.name}}</label>
                <input [(ngModel)]="dataMessage.name" type="text" [placeholder]="translate.sections.campaigns.validationTemplate.exampleName">
            </div>

        </div>

        <div class="clearfix two-fields" *ngIf="dataMessage.category !== 'AUTHENTICATION'">
            <div class="field-box ckeditor half-1">
                <label>{{translate.sections.campaigns.validationTemplate.messageBody}}</label>
                <ckeditor [(ngModel)]="dataMessage.messageContent" *ngIf="existsEmojis" [editor]="Editor" [config]="configEditor" (change)="onChangeMessageContent($event)"></ckeditor>
                <p>
                    {{translate.sections.campaigns.validationTemplate.characterMessage2}}: <span [ngClass]="{'counter-chars': true, 'exceeded': (1024 - messagecontentLen) < 0}">{{1024 - messagecontentLen}}</span>
                </p>
            </div>
            <div class="help-message half-2">
                <p><strong>{{translate.sections.campaigns.validationTemplate.excelMessage}}.</strong></p>
                <p *ngIf="currentChannelType==='SMOOCH'"><strong>{{translate.sections.campaigns.validationTemplate.exampleMessageSmooch}}</strong></p>
                <p *ngIf="currentChannelType==='WHATSAPP'"><strong>{{translate.sections.campaigns.validationTemplate.exampleMessageWhats}}</strong></p>
            </div>
        </div>
        <div class="template-full" *ngIf="dataMessage.category === 'AUTHENTICATION'">
            <p>
                {{translate.sections.campaigns.validationTemplate.mesajeBodyAuth}}
            </p>
        </div>
        <div class="clearfix two-fields" *ngIf="dataMessage.category === 'AUTHENTICATION'">
            <div class="field-box half-1">
                <label>{{translate.sections.campaigns.validationTemplate.buttonTex}}</label>
                <input [(ngModel)]="dataMessage.button1Value" type="text" [placeholder]="translate.sections.campaigns.validationTemplate.copyCode" maxlength="25">
            </div>
        </div>
        <div class="clearfix two-fields" *ngIf="dataMessage.category === 'AUTHENTICATION'">
            <div class="field-box half-1">
                <div class="check-box">
                    <input [(ngModel)]="dataMessage.addSecurityRecommendation" class="check-input" type="checkbox">
                    <label class="check-label">{{translate.sections.campaigns.validationTemplate.addSecurityRecommendation}}</label>
                </div>
                <div class="check-box">
                    <input [(ngModel)]="dataMessage.codeExpiryDate" (change)="updateCodeExpirationMinutes()" class="check-input" type="checkbox">
                    <label class="check-label">{{translate.sections.campaigns.validationTemplate.addCodeExpirydate}}</label>
                </div>
            </div>
        </div>
        <div class="clearfix two-fields" *ngIf="dataMessage.category === 'AUTHENTICATION' && dataMessage.codeExpiryDate === true">
            <div class="field-box half-1">
                <label>{{translate.sections.campaigns.validationTemplate.expiresIn}}</label>
                <input [(ngModel)]="dataMessage.codeExpirationMinutes" type="number" min="1" max="90" step="1">
            </div>
        </div>

        <div class="config-buttons" *ngIf="nButtons > 0">
            <div class="field-box" *ngIf="dataMessage.category !== 'AUTHENTICATION'">
                <label>{{translate.sections.campaigns.validationTemplate.buttonText}} 1</label>
                <input
                    *ngIf="dataMessage.button1Type === 'PAYLOAD'"
                    [(ngModel)]="dataMessage.button1" type="text" (blur)="buttonFocusOut('button1')"
                    [placeholder]="button1Placeholder" [maxlength]="button1MaxLength">
                <input
                    *ngIf="dataMessage.button1Type !== 'PAYLOAD'"
                    [(ngModel)]="dataMessage.button1" type="text" (blur)="buttonFocusOut('button1')"
                    placeholder={{translate.sections.campaigns.validationTemplate.buttonTextMessage1}} [maxlength]="button1MaxLength">
                <label *ngIf="dataMessage.button1Type !== 'PAYLOAD'">{{translate.sections.campaigns.validationTemplate.buttonValue}} 1</label>
                <input
                    *ngIf="dataMessage.button1Type !== 'PAYLOAD'"
                    [(ngModel)]="dataMessage.button1Value" type="text" (blur)="buttonFocusOut('button1')"
                    [placeholder]="button1Placeholder" [maxlength]="button1MaxLength">
                <p>
                    {{translate.sections.campaigns.validationTemplate.characterMessage3}}: <span [ngClass]="{'counter-chars': true, 'exceeded': (button1MaxLength - buttonTextLen1) < 0}">{{button1MaxLength - buttonTextLen1}}</span>
                </p>
            </div>

            <div class="field-box" *ngIf="nButtons >= 2">
                <label>{{translate.sections.campaigns.validationTemplate.button}} 2</label>
                <input
                    *ngIf="dataMessage.button2Type === 'PAYLOAD'"
                    [(ngModel)]="dataMessage.button2" type="text" (blur)="buttonFocusOut('button2')"
                    [placeholder]="button2Placeholder" [maxlength]="button2MaxLength">
                <input
                    *ngIf="dataMessage.button2Type !== 'PAYLOAD'"
                    [(ngModel)]="dataMessage.button2" type="text" (blur)="buttonFocusOut('button2')"
                    placeholder={{translate.sections.campaigns.validationTemplate.buttonTextMessage2}} [maxlength]="button2MaxLength">
                <label *ngIf="dataMessage.button2Type !== 'PAYLOAD'">{{translate.sections.campaigns.validationTemplate.buttonValue}} 2</label>
                <input
                    *ngIf="dataMessage.button2Type !== 'PAYLOAD'"
                    [(ngModel)]="dataMessage.button2Value" type="text" (blur)="buttonFocusOut('button2')"
                    [placeholder]="button2Placeholder" [maxlength]="button2MaxLength">
                <p>
                    {{translate.sections.campaigns.validationTemplate.characterMessage3}}: <span [ngClass]="{'counter-chars': true, 'exceeded': (button2MaxLength - buttonTextLen2) < 0}">{{button2MaxLength - buttonTextLen2}}</span>
                </p>
            </div>

            <div class="field-box" *ngIf="nButtons === 3">
                <label>{{translate.sections.campaigns.validationTemplate.button}} 3</label>
                <input
                    [(ngModel)]="dataMessage.button3" type="text"
                    placeholder={{translate.sections.campaigns.validationTemplate.buttonTextMessage3}} maxlength="25">
                <p>
                    {{translate.sections.campaigns.validationTemplate.characterMessage3}}: <span [ngClass]="{'counter-chars': true, 'exceeded': (25 - buttonTextLen3) < 0}">{{25 - buttonTextLen3}}</span>
                </p>
            </div>
        </div>

    </div>

    <!-- <button (click)="testDataMessage()">Print dataMessage</button> -->
    <div class="preview">
        <h4>{{translate.sections.campaigns.validationTemplate.preview}}</h4>
        <app-preview-message [dataMessage]="dataMessage"></app-preview-message>
    </div>

    <div class="actions" *ngIf="channelSmooch != 'SMOOCH_SMS'">
        <button class="btn-secondary" (click)="saveHSMMessage()">{{translate.sections.campaigns.validationTemplate.saveAsDraft}}</button>
        <button class="btn-primary" [disabled]="disabledActions" (click)="sendToValidate()">{{translate.sections.campaigns.validationTemplate.sendForValidation}}</button>
    </div>
    <div class="actions actions-sms" *ngIf="channelSmooch == 'SMOOCH_SMS'">
        <button class="btn-primary" [disabled]="disabledActions" (click)="saveHSMMessage()">{{translate.sections.campaigns.validationTemplate.saveTemplates}}</button>
    </div>

</div>
