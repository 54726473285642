import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { codes } from 'country-calling-code';
import { countries } from 'src/enums/countries';
import { BillingService } from 'src/services/billing.service';
import { CustomerService } from 'src/services/customer.service';
import { DataLayerService } from 'src/services/data-layer.service';
declare var translate: any;

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

    customerSlug: string;
    totalWabaCost: any;
    totalFbCost: any;
    totalWebCost: any;
    wabaInfo: any;
    wabaConversationData: any[];
    showCostTableWhats: boolean;
    showCostTableFb: boolean;
    showCostTableWeb: boolean;
    currentMonth: number;
    selectedMonth: string;
    currentYear: number;
    months: any[];
    channels: any[];
    conversationsTotal: string;
    activeUsersTotal: string;
    translate: any;
    flagPath: string;
    whatsAppConversationTotal: number;

    constructor(
        private route: ActivatedRoute,
        private billingService: BillingService,
        private customerService: CustomerService,
        private dataLayerService: DataLayerService,
    ) {
        this.conversationsTotal = translate.sections.billing.noCalculate1;
        this.activeUsersTotal = translate.sections.billing.noCalculate2;
        this.totalWabaCost = translate.sections.billing.noCalculate3;
        this.totalFbCost = translate.sections.billing.noCalculate3;
        this.totalWebCost =translate.sections.billing.noCalculate3;
        this.showCostTableWhats = false;
        this.showCostTableFb = false;
        this.showCostTableWeb = false;
        this.wabaConversationData = [];
        const today = new Date()
        this.currentMonth = today.getMonth();
        this.currentYear = today.getFullYear();
        this.getMonths();
        this.selectedMonth = this.currentMonth.toString() + '-' + this.currentYear.toString();
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = '';
    }

    ngOnInit() {
        this.getUrlParam('customer_slug', (customerSlug) => {
            this.customerSlug = customerSlug;
            this.getChannels();
            this.getConversations();
            this.getActiveUsers();
        });
        this.dataLayerService.setFlag(this.flagPath, 'billing');
    }

    getChannels() {
        this.customerService.getBySlug(this.customerSlug).subscribe(
            (res: any) => {
                this.channels = res.tenant.channels;
                this.getWabaInfo();
            },
            (err: any) => {
                console.log(err);
            }
        );
    }

    existsChannel(channel: string, subtype: string = null) {
        let exists = false;
        if (this.channels) {
            exists = this.channels.some(c => c.channel === channel);
        }
        return exists;
    }

    getConversations() {
        const chunks = this.selectedMonth.split('-');
        const month = (parseInt(chunks[0]) + 1).toString();
        const year = chunks[1];
        this.billingService.getTotalConversations('America/Mexico_City', this.customerSlug, month, year).subscribe(
            (res: any) => {
                this.conversationsTotal = res.total.toString();
            },
            err => {
                console.log(err);
            }
        );
    }

    getActiveUsers() {
        const chunks = this.selectedMonth.split('-');
        const month = (parseInt(chunks[0]) + 1).toString();
        const year = chunks[1];
        this.billingService.getActiveUsers('America/Mexico_City', this.customerSlug, month, year).subscribe(
            (res: any) => {
                this.activeUsersTotal = res.total.toString();
            },
            err => {
                console.log(err);
            }
        );
    }

    getWabaInfo() {
        if (this.existsChannel('WHATSAPP')) {
            this.billingService.getWabaInfo(this.customerSlug).subscribe(
                (res: any) => {
                    this.wabaInfo = res;
                    this.getWabaBilling();
                },
                err => {
                    console.log(err);
                }
            );
        }
    }

    formatBillingWabaResult(data_point) {
        data_point.conversation_direction = 'BUSINESS_INITIATED' === data_point.conversation_direction ? translate.sections.billing.business: translate.sections.billing.user;
        switch(data_point.conversation_type) {
            case 'FREE_ENTRY_POINT':
            case 'FREE_ENTRY':
                data_point.conversation_type = translate.sections.billing.free1;
                break;
            case 'FREE_TIER':
                data_point.conversation_type = translate.sections.billing.free2;
                break;
            case 'REGULAR':
                data_point.conversation_type = translate.sections.billing.pay;
                break;
        }
        data_point.cost = this.formatCost(data_point.cost, 'en-US', 'USD');
        if (countries.hasOwnProperty(data_point.country)) {
            data_point.country = countries[data_point.country];
        } else {
            data_point.country = 'Country code ' + data_point.country;
        }
        return data_point;
    }

    sortBillingWabaByDate() {
        this.wabaConversationData.sort((a: any, b: any) => a.start - b.start);
    }

    formatBillingWabaDate(lang) {
        const dateOptions: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };

        for (let data_point of this.wabaConversationData) {
            data_point.start = new Date(data_point.start * 1000).toLocaleDateString(lang, dateOptions); // epoch date
            data_point.end = new Date(data_point.end * 1000).toLocaleDateString(lang, dateOptions); // epoch date
        }
    }

    formatCost(cost: number, lang: string, currency: string) {
        return new Intl.NumberFormat(lang, { style: 'currency', currency: currency }).format(cost);
    }

    calcRevenue(conversations_count) {
        return conversations_count * 0.005;
    }

    changeRangeDate(){
        this.getWabaBilling();
        this.getConversations();
        this.getActiveUsers();
    }

    getWabaBilling() {
        const chunks = this.selectedMonth.split('-');
        const month = parseInt(chunks[0]);
        const year = parseInt(chunks[1]);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let dateStart = new Date(`${monthNames[month]} 01 ${year} 00:00:00`).getTime() / 1000; // epoch date
        let nextMonth = month + 1 > 11 ? 0 : month + 1;
        let nextYear = nextMonth == 0 ? year + 1 : year;
        let dateEnd = new Date(`${monthNames[nextMonth]} 01 ${nextYear} 00:00:00`).getTime() / 1000; // epoch date
        const currentDate = new Date().toString();
        if (this.wabaInfo &&
            'country' in this.wabaInfo &&
            this.wabaInfo.country === 'MX' &&
            currentDate.includes("GMT-0600") &&
            month < 6 &&
            year <= 2023
        ) {
            dateStart = new Date(`${monthNames[month]} 01 ${year} 00:00:00 GMT-05:00`).getTime() / 1000; // epoch date
            dateEnd = new Date(`${monthNames[nextMonth]} 01 ${nextYear} 00:00:00 GMT-05:00`).getTime() / 1000; // epoch date
        }
        this.wabaConversationData = [];
        this.totalWabaCost = 0;
        this.billingService.getWabaConversationAnalytics(dateStart, dateEnd, this.customerSlug).subscribe(
            (res: any) => {

                // this.wabaConversationData = res;
                if (res.hasOwnProperty('conversation_analytics')) {
                    const lang = localStorage.getItem('lang');
                    if (typeof(this.totalWabaCost) === 'string') {
                        this.totalWabaCost = 0;
                    }
                    let conversation_counter = 0;
                    for (let data of res.conversation_analytics.data) {
                        for (let data_point of data.data_points) {
                            conversation_counter += data_point.conversation;
                            if (data_point.conversation_type.indexOf('FREE') < 0) {
                                const revenue = this.calcRevenue(data_point.conversation);
                                data_point.cost += revenue;
                            }
                            this.totalWabaCost += data_point.cost;
                            data_point = this.formatBillingWabaResult(data_point);
                            this.wabaConversationData.push(data_point);
                        }
                    }
                    this.whatsAppConversationTotal = conversation_counter;
                    // console.log("🚀 ~ file: billing.component.ts:228 ~ BillingComponent ~ getWabaBilling ~ conversation_counter:", conversation_counter)
                    this.sortBillingWabaByDate();
                    this.formatBillingWabaDate(lang);
                    this.totalWabaCost = this.formatCost(this.totalWabaCost, 'en-US', 'USD');
                }
            },
            err => {
                console.log(err);
            }
        );

    }

    toggleCostBreakdown(type) {
        switch(type){
            case 'WHATSAPP':
                this.showCostTableWhats = this.showCostTableWhats ? false : true;
                break;
            case 'FB_MESSENGER':
            case 'WEBSITE':
                this.showCostTableFb = this.showCostTableFb ? false : true;
                break;
        }
    }

    getMonths() {
        const fullMonths = [
            {
                name: translate.sections.billing.months.jan,
                index: '0'
            }, {
                name: translate.sections.billing.months.feb,
                index: '1'
            }, {
                name: translate.sections.billing.months.mar,
                index: '2'
            }, {
                name: translate.sections.billing.months.apr,
                index: '3'
            }, {
                name: translate.sections.billing.months.may,
                index: '4'
            }, {
                name: translate.sections.billing.months.jun,
                index: '5'
            }, {
                name: translate.sections.billing.months.jul,
                index: '6'
            }, {
                name: translate.sections.billing.months.aug,
                index: '7'
            }, {
                name: translate.sections.billing.months.sep,
                index: '8'
            }, {
                name: translate.sections.billing.months.oct,
                index: '9'
            }, {
                name: translate.sections.billing.months.nov,
                index: '10'
            }, {
                name: translate.sections.billing.months.dec,
                index: '11'
            }
        ];
        const currentMonth = new Date().getMonth();
        this.months = fullMonths.slice(0, currentMonth + 1);
        this.months = this.months.map(month => {
            month.index = month.index + '-' + this.currentYear.toString();
            month.name = month.name + ' ' + this.currentYear.toString();
            return month;
        });
        if (this.months.length < 12) {
            const diffMonths = 12 - this.months.length;
            let restMonths = fullMonths.slice(-diffMonths, 12);
            restMonths = restMonths.map(month => {
                month.index = month.index + '-' + (this.currentYear - 1).toString();
                month.name = month.name + ' ' + (this.currentYear - 1).toString();
                return month;
            });
            this.months = restMonths.concat(this.months);
            this.months = this.months.filter(mounthObj => {
               const year = parseInt(mounthObj.index.split('-')[1]);
               if (year > 2022 || mounthObj.index === '11-2022') {
                return mounthObj;
               }
            });
        }
    }

    getUrlParam(param: string, success) {
        const paramInterval = setInterval(() => {
            if (this.route !== null) {
                if (this.route.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.route.paramMap.subscribe(params => {
                        const p = params.get(param);
                        success(p);
                    });
                }
            }
        }, 500);
    }

    downloadDetail(type) {
        switch(type) {
            case 'WHATSAPP':

                const type = 'text/csv';
                const name = 'gus_billing.csv';

                const { URL: { createObjectURL, revokeObjectURL }, setTimeout } = window;
                const data = this.convertToCSV(this.wabaConversationData);
                const blob = new Blob([data], { type });
                const url = createObjectURL(blob);

                const anchor = document.createElement('a');
                anchor.setAttribute('href', url);
                anchor.setAttribute('download', name);
                anchor.click();
                // document.body.removeChild(anchor);

                setTimeout(() => { revokeObjectURL(url) }, 100)
                break;
            case 'FB_MESSENGER':
                break;
            case 'WEBSITE':
                break;
        }
    }

    convertToCSV(objArray) {
        const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = 'Conversations,Conversation category,Conversation type,Cost (' + this.wabaInfo.currency + '),Country,Date end,Phone,Date start\r\n';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (const index in array[i]) {
                if ('conversation_direction' !== index) {
                    if (line != '') line += ','

                    line += `"${array[i][index]}"`;
                }
            }

            str += line + '\r\n';
        }

        return str;
    }


}
