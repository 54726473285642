import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../environments/environment';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BuildBotService {

    constructor(private http: HttpClient) { }

    run(platformId: string, flow: any, currentUser: string, slug: string): Observable<any> {
        platformId = platformId === 'APP' ? 'SMOOCH_APP' : platformId;
        const url = `/coreapi/v2/constructor/platforms/${platformId}/flowPrebuild/${flow.id}/compile`;

        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });

        const data = {
            flowType: flow.type,
            name: flow.name,
            slug: flow.slug,
            image: flow.image,
            createdBy: currentUser
        };

        return this.http.post<any>(url, JSON.stringify(data),  { headers });
    }

    runAll(platformId: string, flow: any, currentUser: string, constructorUserId: string, slug: string): Observable<any> {
        platformId = platformId === 'APP' ? 'SMOOCH_APP' : platformId;
        const url = `/coreapi/v2/constructor/platforms/${platformId}/flowPrebuild/${flow.id}/fullcompile`;

        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });

        const data = {
            flowType: flow.type,
            name: flow.name,
            slug: flow.slug,
            image: flow.image,
            createdBy: currentUser,
            constructor_user_id: constructorUserId
        };

        return this.http.post<any>(url, JSON.stringify(data), { headers });
    }

    validateNlp(platformId: string, flow: any, currentUser: string, slug: string): Observable<any> {
        platformId = platformId === 'APP' ? 'SMOOCH_APP' : platformId;
        const url = `/coreapi/v2/constructor/platforms/${platformId}/NLPPrebuild/${flow.id}/compile`;

        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });

        const data = {
            flowType: flow.type,
            name: flow.name,
            slug: flow.slug,
            image: flow.image,
            createdBy: currentUser
        };

        return this.http.post<any>(url, JSON.stringify(data), { headers });
    }

    getNlpStatus(platformId: string, flow: any, slug: string): Observable<any> {
        const url = `/coreapi/v2/constructor/platforms/${platformId}/NLPPrebuild/${flow.id}/status`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });
        return this.http.get<any>(url, { headers });
    }

    test(platformId: string, flow: any, currentUser: string, constructorUserId, slug: string): Observable<any> {
        platformId = platformId === 'APP' ? 'SMOOCH_APP' : platformId;
        const url = `/coreapi/v2/constructor/platforms/${platformId}/flowPrebuild/${flow.id}/compile`;

        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });

        const data = {
            flowType: flow.type,
            name: flow.name,
            slug: flow.slug,
            image: flow.image,
            createdBy: currentUser,
            channelDefault: false,
            constructor_user_id: constructorUserId
        };

        return this.http.post<any>(url, JSON.stringify(data),  { headers });
    }

    testFull(platformId: string, flow: any, currentUser: string, constructorUserId: string, slug: string): Observable<any> {
        platformId = platformId === 'APP' ? 'SMOOCH_APP' : platformId;
        const url = `/coreapi/v2/constructor/platforms/${platformId}/flowPrebuild/${flow.id}/fullcompile`;

        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });

        const data = {
            flowType: flow.type,
            name: flow.name,
            slug: flow.slug,
            image: flow.image,
            createdBy: currentUser,
            channelDefault: false,
            constructor_user_id: constructorUserId
        };

        return this.http.post<any>(url, JSON.stringify(data),  { headers });
    }

    setDefaultChannel(channel: string, chatFlowId: string, slug: string) {
        const url = `/coreapi/v2/chatFlows/${chatFlowId}`;

        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });

        const data = {
            channel,
            channelDefault: true
        };

        return this.http.put<any>(url, JSON.stringify(data),  { headers });
    }

}
