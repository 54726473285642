import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ChannelsService } from '../../services/channels.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';

import { ModalService } from '../../components/modal/modal.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { LoaderService } from '../../services/loader.service';

import { environment } from '../../environments/environment';

import tippy from 'tippy.js';
import { ApiUserService } from 'src/services/api-user.service';
import { CustomerService } from 'src/services/customer.service';
import { FileService } from 'src/services/file.service';
@Component({
    selector: 'app-channels',
    templateUrl: './channels.component.html',
    styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit, AfterViewInit {

    pages: any = [];
    channels: any = [];
    canalId: any;
    nameChannel: any;
    platformsForm: UntypedFormGroup;
    loadingChannels: true;
    ChannelCatalog: any = [];
    environments: any = [];

    subtypesChannels: any = [];
    channelSelect: any;
    routeImageChannel = './../assets/img/channels/';
    routeImageSubtype = './../assets/img/channelSubtype/';

    idPage: any;
    idInstagram: any;
    slug: any;

    newChannel = [];
    urlParameter: any;
    currentPlatformSlug: string;

    nextPageUrl = '';
    prevPageUrl = '';

    sandbox = false;
    sandboxList: any = [];
    selectedSandbox: any;
    phoneSandbox = '';
    countrySandbox: string;
    sandboxToken = '';
    ownerName = '';
    files: any;
    showCopyMenu = false;
    channelIdSelected = '';

    blip = false;
    isTunnel = false;
    isHidden = false;
    blipSubchannel = '';

    // @ViewChild('channelSelecter') channelSelecter: any;
    @ViewChild('containerPagesF') containerPagesF;
    @ViewChild('containerPagesI') containerPagesI;

    @ViewChild('blipChannelOwnerId') blipChannelOwnerId;
    @ViewChild('blipChannelOwnerName') blipChannelOwnerName;
    @ViewChild('blipAccessSecret') blipAccessSecret;
    @ViewChild('blipPhoneNumber') blipPhoneNumber;

    constructor(
        private channelService: ChannelsService,
        private modalService: ModalService,
        private activate: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private router: Router,
        private alertService: AlertService,
        private loaderService: LoaderService,
        private apiUser: ApiUserService,
        private customerService: CustomerService,
        private fileService: FileService,
        private recaptchaV3Service: ReCaptchaV3Service
    ) {
            this.countrySandbox = '521';
        }

    ngOnInit() {
        this.urlParameter = window.location.href;
        // console.log(this.arrayChannel);
        const params = this.activate.snapshot.params;

        if (params.customer_slug) {
            // console.log(params.customer_slug);
            this.slug = params.customer_slug;
            this.supportChannels();
            this.subtypes();
            this.channelService.getEnvironments(this.slug).subscribe(
                res => {
                    // console.log(res);
                    this.environments = res;

                },
                err => {
                    console.log(err);

                }
            );
            //if (params.customer_slug === 'FB_MESSENGER') {
                this.getPages();
            //}

        }

        this.platformsForm = this.fb.group({
            platform: ' ',
            name: '',
            agree: null
        });

        this.getUrlParam('platform_slug', (platformSlug) => {
            this.currentPlatformSlug = platformSlug;
            this.getChannels();
        });
    }
    ngAfterViewInit() {
        // this.channelSelecter.open();
        tippy('[data-tippy-content]');
    }

    savePage(page, elemento, type) {
        // console.log(page);
        // console.log(elemento);
        $(elemento).toggleClass('selectDiv');
        this.idPage = page.id;
        if ('instagram_business_account' in page) {
            this.idInstagram = page.instagram_business_account.id;
        } else if (type === 'INSTAGRAM') {
            this.idInstagram = null;
            this.alertService.warning('No esta vinculada tu cuenta de instagram con Facebook Business');
        }

    }

    changeCustomer(channelSelected) {
        // console.log('validate channel');

        // console.log(channelSelected);
        this.channelSelect = channelSelected;
        this.modalService.open('popup-new-channel');
    }

    setPaging(res) {
        this.pages = res.data;
        if ('paging' in res){
            if ('next' in res.paging) {
                this.nextPageUrl = res.paging.next;
            } else {
                this.nextPageUrl = '';
            }
            if ('previous' in res.paging) {
                this.prevPageUrl = res.paging.previous;
            } else {
                this.prevPageUrl = '';
            }
        }
        if (this.currentPlatformSlug === 'INSTAGRAM'){
            this.containerPagesI.nativeElement.scrollTop = 0;
        } else if (this.currentPlatformSlug === 'FB_MESSENGER'){
            this.containerPagesF.nativeElement.scrollTop = 0;
        }
    }

    getPages() {
        const token = localStorage.getItem('token');
        this.channelService.getPages(token).subscribe(
            (res: any) => {
                this.pages = res.accounts.data;
                if ('paging' in res.accounts){
                    if ('next' in res.accounts.paging) {
                        this.nextPageUrl = res.accounts.paging.next;
                    } else {
                        this.nextPageUrl = '';
                    }
                    if ('previous' in res.accounts.paging) {
                        this.prevPageUrl = res.accounts.paging.previous;
                    } else {
                        this.prevPageUrl = '';
                    }
                }
            }, err => {
                console.log(err);
            }
        );
    }

    getByUrl(url) {
        this.channelService.getByUrl(url).subscribe(
            (res: any) => {
                this.setPaging(res);
            }, err => {
                console.log(err);
                this.alertService.error('El token ha caducado, por favor refresca la página y vuelva intentar');
            }
        );
    }

    getNextPages() {
        if (this.nextPageUrl !== '') {
            this.getByUrl(this.nextPageUrl);
        }
    }

    getPrevPages() {
        if (this.prevPageUrl !== '') {
            this.getByUrl(this.prevPageUrl);
        }
    }

    getChannels() {
        this.channelService.get(this.slug).subscribe(
            res => {
                // console.log('list channels');
                // this.channels = res;
                // console.log(res);
                // if (this.channels.length > 0) {
                //     // this.channelSelecter.close();
                // }
                this.channels = [];
                const resArr = res;
                // for (const [idx, v] of (res as any[]).entries()) {
                // for (let idx = 0; idx < resArr.length; idx++) {
                for (const idx in res) {
                    Object.freeze(Object.prototype);
                    Object.freeze(Object);
                    res[idx].channel = res[idx].channel.replace('WAVY', 'WHATSAPP');

                    if (res[idx].channel.indexOf('SANDBOX') >= 0){
                        res[idx]['sandbox'] = true;
                    } else {
                        res[idx]['sandbox'] = false;
                    }

                    if (res[idx].channel==="BLIP"){
                        res[idx]['blip'] = true;
                    } else {
                        res[idx]['blip'] = false;
                    }
                    // console.log(this.currentPlatformSlug +' - '+ res[idx].channel);
                    if (
                        (this.currentPlatformSlug === 'SMOOCH_APP' && (res[idx].channel === 'SMOOCH_IOS' || res[idx].channel === 'SMOOCH_ANDROID'))
                        ||
                        (res[idx].channel !== 'SMOOCH_IOS' && res[idx].channel !== 'SMOOCH_ANDROID' &&
                        (this.currentPlatformSlug === 'WAVY' && res[idx].channel === 'WHATSAPP' ||
                        res[idx].channel.indexOf(this.currentPlatformSlug) >= 0 || 
                        (res[idx].channel === 'BLIP' && this.currentPlatformSlug === res[idx].channelSubtype) ||
                        (res[idx].channel === 'BLIP' && res[idx].channelSubtype === 'FACEBOOK' && this.currentPlatformSlug === 'FB_MESSENGER') ||
                        (res[idx].channel === 'BLIP' && res[idx].channelSubtype === 'TWITTER' && this.currentPlatformSlug === 'TWITTER_DM')))
                    ) {
                        this.channels.push(res[idx]);
                    }
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    existsWhatsappChannel() {

        if (
            this.channels.some(channel => channel.channel === 'WHATSAPP' &&
            (channel.channelSubtype === "CLOUD" || channel.channelSubtype === 'WHATSAPP'))
        ) {
            return true;
        }
        return false;
    }


    supportChannels() {
        this.channelService.supportChannels(this.slug).subscribe(
            res => {
                // console.log('channels supported');
                // console.log(res);
                this.ChannelCatalog = res;
                // console.log('lista de canales');

                // console.log(this.ChannelCatalog);


            },
            err => {
                console.log(err);

            }
        );
    }


    generate_guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x1000000).toString(36).substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + (new Date().getTime()).toString(16);
    }

    subtypes() {
        this.channelService.getSubtypes(this.slug).subscribe(
            res => {
                // console.log('subtypes channels');
                // console.log(res);
                this.subtypesChannels = res;
                // console.log('lista de subtypes');

                // console.log(this.subtypesChannels);


            },
            err => {
                console.log(err);

            }
        );
    }


    openChatW(channel_owner_id) {
        const url = `${environment.chatUrl}/test.html?channel_owner_id=${channel_owner_id}&guschat=open_box`;
        window.open(url, '_blank');
    }

    openChatF(idPage) {
        // get idpage channel

        const id = "100785021675290";
        const url = `https://www.facebook.com/messages/t/${idPage}`;
        window.open(url, "_blank");

    }

    openChatSS(label) {
        try {
            const chunks = label.split(' - ');
            const phone = chunks[1];
            const url = `https://wa.me/${phone}`;
            window.open(url, "_blank");
        } catch(e) {
            this.alertService.error('No se pudo obtener el número de teléfono');
        }
    }

    openChatCloud(label) {
        try {
            const chunks = label.split(' ');
            const phone = chunks[1];
            const url = `https://wa.me/${phone}`;
            window.open(url, "_blank");
        } catch(e) {
            this.alertService.error('No se pudo obtener el número de teléfono');
        }
    }

    openChatWhats(phone) {
        try {
            const url = `https://wa.me/${phone}`;
            window.open(url, "_blank");
        } catch(e) {
            this.alertService.error('No se pudo obtener el número de teléfono');
        }
    }

    saveChannelT(nameChannel, idBot, IDApp, token, environment) {

        // console.log(`name: ${nameChannel.value} idbot: ${idBot.value} idapp: ${IDApp.value} token: ${token.value} entorno: ${environment.value}`);

        let object = {
            jsonDataParsed: {},
            authenticationType: 'BASIC',
            channelSubtype: 'NONE',
            serviceOperationTimeParse: {},
            outboundMessages: [],
            channel: this.channelSelect,
            channelOwnerName: nameChannel.value,
            applicationId: IDApp.value,
            accessSecret: token.value,
            channelOwnerId: idBot.value,
            environmentId: environment.value,
            consumerKey: '',
            consumerSecret: '',
            jsonData: '{}'
        };

        this.saveChannel(object);


    }

    saveChannelSmooch(owner, AppID, idIntegrate, typeIntegrate, scope, role, keyID, keySecret, namespace) {

        const jsonDataParsedSend = {
            scope: scope.value,
            userType: role.value
        };
        let object = {
            jsonDataParsed: {
                scope: scope.value,
                userType: role.value
            },
            authenticationType: 'BASIC',
            channelSubtype: typeIntegrate.value,
            serviceOperationTimeParse: {},
            outboundMessages: [],
            channel: this.channelSelect,
            channelOwnerName: owner.value,
            applicationId: AppID.value,
            channelOwnerId: idIntegrate.value,
            consumerKey: keyID.value,
            consumerSecret: keySecret.value,
            namespace: namespace.value,
            jsonData: JSON.stringify(jsonDataParsedSend)
        };

        this.saveChannel(object);

    }
    saveChannelSmoochSms(owner, AppID, idIntegrate, scope, role, keyID, keySecret) {

        const jsonDataParsedSend = {
            scope: scope.value,
            userType: role.value
        };
        let object = {
            jsonDataParsed: {
                scope: scope.value,
                userType: role.value
            },
            authenticationType: 'BASIC',
            serviceOperationTimeParse: {},
            outboundMessages: [],
            channel: this.channelSelect,
            channelOwnerName: owner.value,
            applicationId: AppID.value,
            channelOwnerId: idIntegrate.value,
            consumerKey: keyID.value,
            consumerSecret: keySecret.value,
            jsonData: JSON.stringify(jsonDataParsedSend)
        };

        this.saveChannel(object);

}

    saveChannelSmoochApp(typeChannel, owner, AppID, idIntegrate, scope, role, keyID, keySecret) {

        const jsonDataParsedSend = {
            scope: scope.value,
            userType: role.value,
            loadProperties: 1
        };
        let object = {
            jsonDataParsed: {
                scope: scope.value,
                userType: role.value,
                loadProperties: 1
            },
            authenticationType: 'BASIC',
            serviceOperationTimeParse: {},
            outboundMessages: [],
            channel: 'SMOOCH_' + typeChannel.value,
            channelSubtype: typeChannel.value,
            channelOwnerName: owner.value,
            applicationId: AppID.value,
            channelOwnerId: idIntegrate.value,
            consumerKey: keyID.value,
            consumerSecret: keySecret.value,
            jsonData: JSON.stringify(jsonDataParsedSend)
        };

        this.saveChannel(object);

    }

    saveChannelW(nameS) {
        const uuid = this.generate_guid();
        const object =
        {
            jsonDataParsed: {},
            authenticationType: 'BASIC',
            channelSubtype: 'NONE',
            serviceOperationTimeParse: {},
            outboundMessages: [],
            channel: this.channelSelect,
            channelOwnerName: nameS.value,
            channelOwnerId: uuid,
            accessSecret: '',
            webhook: '',
            serverUrl: '',
            consumerKey: '',
            consumerSecret: '',
            mailSubject: '',
            username: '',
            environmentId: null,
            applicationId: '',
            jsonData: '{}'
        };

        this.saveChannel(object);

    }

    handleFiles(event) {
        this.files = event.target.files;
        // this.uploadFile(this.files, (res) => {
        //     console.log(res);
        // }, (error) => {
        //     console.log(error);
        // });
    }

    uploadFile(files, success, error) {
        const formFiles = new FormData();
        formFiles.append('document', files[0]);

        this.recaptchaV3Service.execute('uploadFile').subscribe((reToken) => {
            this.fileService.upload(this.slug, formFiles, reToken).subscribe({
                next: (res: any) => {
                    success(res);
                },
                error: err => {
                    error(err);
                }
            });
        });
    }

    generateTokenAndWebhook(channelOwnerId) {
        this.channelService.generateWhatsappToken(this.slug, channelOwnerId).subscribe(
            res => {

                this.channelService.generateWhatsappWebhook(this.slug, channelOwnerId).subscribe(
                    res => {
                        this.alertService.success('Canal creado correctamente');
                    },
                    err => {
                        this.alertService.error('Error al generar el webhook');
                        console.log(err);
                    }
                );
            },
            err => {
                this.alertService.error('Error al generar el token');
                console.log(err);
            }
        );
    }

    saveChannelWhatsapp(owner, lada, cel, serverUrl, whatsappBusinessId, whatsappToken, whatsappNamespace, username, password, alias) {

        const channel = {
            channel: this.channelSelect,
            channelOwnerName: owner.value,
            channelOwnerId: lada.value + cel.value,
            channelSubtype: "WHATSAPP",
            username: username.value,
            password: password.value,
            applicationId: whatsappBusinessId.value,
            serverUrl: serverUrl.value,
            encrypted: true,
            alias: alias.value,
            authenticationType: "BASIC",
            accessSecret: whatsappToken.value,
            namespace: whatsappNamespace.value
        };

        this.channelService.create(this.slug, channel).subscribe(
            res => {
                // console.log(res);
                // console.log('creado');
                this.getChannels();
                this.modalService.close('popup-new-channel');
                this.platformsForm = this.fb.group({
                    platform: 'Canal',
                    name: '',
                    agree: null
                });
                localStorage.setItem('step3', 'true');
                this.router.navigate([], {queryParams: {steps: 'updated'}});

                this.generateTokenAndWebhook(owner.value);
            },
            err => {
                this.alertService.error('error al crear el canal');
                console.log(err);

            }
        );

    }

    saveChannelWHATS(owner, cel) {

        const object =
        {
            jsonDataParsed: {},
            authenticationType: 'BASIC',
            channelSubtype: 'NONE',
            serviceOperationTimeParse: {},
            outboundMessages: [],
            channel: this.channelSelect,
            channelOwnerName: owner.value,
            channelOwnerId: cel.value,
            accessSecret: '',
            webhook: '',
            serverUrl: '',
            consumerKey: '',
            consumerSecret: '',
            mailSubject: '',
            username: '',
            environmentId: null,
            applicationId: '',
            jsonData: '{}'
        };

        this.saveChannel(object);

    }
    saveChannelI() {
        if (this.idInstagram){
            // assing token local
            const object = {
                token: localStorage.getItem('token'),
                pageId: this.idPage,
                instagramId: this.idInstagram
            };
            // console.log(object);
            // const object =
            // {
            //     jsonDataParsed: {},
            //     authenticationType: 'BASIC',
            //     channelSubtype: 'NONE',
            //     serviceOperationTimeParse: {},
            //     outboundMessages: [],
            //     channel: this.channelSelect,
            //     channelOwnerName: nameS.value,
            //     channelOwnerId: idAccount.value,
            //     accessSecret: token.value,
            //     webhook: '',
            //     serverUrl: '',
            //     consumerKey: '',
            //     consumerSecret: '',
            //     mailSubject: '',
            //     username: '',
            //     environmentId: environment.value,
            //     applicationId: '',
            //     jsonData: '{}'
            // };

            this.channelService.addInstagram(this.slug, object).subscribe(
                res => {
                    // console.log('save channel instagram');
                    // console.log(res);
                    this.getChannels();
                    this.modalService.close('popup-new-channel');
                    // alert save
                    this.alertService.success('canal creado correctamente');
                    this.platformsForm = this.fb.group({
                        platform: 'Canal',
                        name: '',
                        agree: null
                    });
                },
                err => {
                    console.log(err);
                    this.alertService.error('error al crear el canal');
                    this.platformsForm = this.fb.group({
                        platform: 'Canal',
                        name: '',
                        agree: null
                    });

                }
            );

            // console.log(object);
        } else {
            this.alertService.error('No se puede guardar una cuenta de instagram no vinvulada a Facebook Business');
        }
    }
    saveChannelF() {



        // assing token local
        const object = {
            token: localStorage.getItem('token'),
            pageId: this.idPage
        };
        // console.log(object);
        // const object =
        // {
        //     jsonDataParsed: {},
        //     authenticationType: 'BASIC',
        //     channelSubtype: 'NONE',
        //     serviceOperationTimeParse: {},
        //     outboundMessages: [],
        //     channel: this.channelSelect,
        //     channelOwnerName: nameS.value,
        //     channelOwnerId: idAccount.value,
        //     accessSecret: token.value,
        //     webhook: '',
        //     serverUrl: '',
        //     consumerKey: '',
        //     consumerSecret: '',
        //     mailSubject: '',
        //     username: '',
        //     environmentId: environment.value,
        //     applicationId: '',
        //     jsonData: '{}'
        // };

        this.channelService.addFacebook(this.slug, object).subscribe(
            res => {
                // console.log('save channel facebook');
                // console.log(res);
                this.getChannels();
                this.modalService.close('popup-new-channel');
                // alert save
                this.alertService.success('canal creado correctamente');
                this.platformsForm = this.fb.group({
                    platform: 'Canal',
                    name: '',
                    agree: null
                });
            },
            err => {
                console.log(err);
                this.alertService.error('error al crear el canal');
                this.platformsForm = this.fb.group({
                    platform: 'Canal',
                    name: '',
                    agree: null
                });

            }
        );

        // console.log(object);


    }

    redirectHorary(id) {
        // console.log('id channel');
        this.router.navigate(['/customers', this.slug, 'channels', id, 'horary']);

        // console.log(id);

    }

    redirectConfig(id) {
        // console.log('id channelowner');
        this.router.navigate(['/customers', this.slug, 'config', 'menu', id]);

        // console.log(id);

    }

    redirectConfigChat(id) {
        // console.log('id channelowner');
        this.router.navigate(['/customers', this.slug, 'config', 'chat', id]);
        // console.log(id);


    }

    saveChannel(channel) {


        // const data: any = JSON.stringify(object);
        this.channelService.create(this.slug, channel).subscribe(
            res => {
                // console.log(res);
                // console.log('creado');
                this.getChannels();
                this.modalService.close('popup-new-channel');
                this.alertService.success('canal creado correctamente');
                this.platformsForm = this.fb.group({
                    platform: 'Canal',
                    name: '',
                    agree: null
                });
                localStorage.setItem('step3', 'true');
                this.router.navigate([], {queryParams: {steps: 'updated'}});
            },
            err => {
                this.alertService.error('error al crear el canal');
                console.log(err);

            }
        );
    }


    delete(id: any) {
        this.channelService.delete(this.slug, id).subscribe(
            res => {
                // console.log(res);
                // console.log('channel deleted');
                this.modalService.close('popup-confirm');
                this.getChannels();


            },
            err => {
                console.log(err);

            }
        );
    }

    enable(id, elemento) {
        // console.log(id);

        if ($(elemento).prop('checked')) {
            // console.log('habilitar');
            this.update(id, true);

        } else {
            // console.log('inhabilitar');
            this.update(id, false);
        }



    }
    update(id: any, isActivated) {

        const enabled: any = {
            enabled: isActivated

        };
        // const object: any = JSON.stringify(enabled);
        this.channelService.update(this.slug, id, enabled).subscribe(
            res => {
                // console.log('service enable/disabled channel');

                // console.log(res);

            },
            err => {
                console.log(err);

            }
        );

    }

    deleteChannel() {
        // console.log(this.canalId);
        this.delete(this.canalId);
    }

    functionCancel(id) {
        this.modalService.close(id);

    }

    getChannelImage(routeImageChannel, channel) {
        let img = routeImageChannel + channel + '.svg';
        img = img.replace('_SANDBOX', '');
        img = img.replace('BLIP', this.currentPlatformSlug);
        return img;
    }

    toggleForm() {
        this.sandbox = !this.sandbox;
        if (this.sandbox) {
            const channelType = this.currentPlatformSlug + '_SANDBOX';
            this.channelService.getSandbox(channelType, this.slug).subscribe(
                res => {
                    this.sandboxList = res;
                    tippy('[data-tippy-content]');
                },
                err => {
                    console.log(err);
                    this.alertService.error('Hubo un problema al leer los usuarios');
                }
            );
        }
    }

    saveChannelSmoochSandbox() {
        if (this.selectedSandbox && 'accountHandler' in this.selectedSandbox) {
            const phone = this.countrySandbox + this.phoneSandbox;
            this.channelService.createSandbox(this.selectedSandbox.id, phone, this.slug).subscribe(
                res => {
                    // console.log(res);
                    this.getChannels();
                    this.alertService.success('Se ha creado el canal sandbox');
                    this.phoneSandbox = '';
                    this.selectedSandbox = null;
                    this.sandbox = false;
                    this.sandboxList = [];
                    this.modalService.close('popup-new-channel');
                },
                err => {
                    console.log(err);
                    // this.alertService.error('Hubo un problema en la creación del canal sandbox');
                }
            );
        } else {
            this.alertService.error('Todos los campos son requeridos');
        }
    }

    saveChannelWhatsappSandbox() {
        if (this.selectedSandbox && 'accountHandler' in this.selectedSandbox) {
            const phone = this.countrySandbox + this.phoneSandbox;
            this.channelService.createWhatsappSandbox(this.selectedSandbox.channelOwnerId, phone, this.slug).subscribe(
                res => {
                    // console.log(res);
                    this.getChannels();
                    this.alertService.success('Se ha creado el canal sandbox');
                    this.phoneSandbox = '';
                    this.selectedSandbox = null;
                    this.sandbox = false;
                    this.sandboxList = [];
                    this.modalService.close('popup-new-channel');
                },
                err => {
                    console.log(err);
                    // this.alertService.error('Hubo un problema en la creación del canal sandbox');
                }
            );
        } else {
            this.alertService.error('Todos los campos son requeridos');
        }
    }

    copy(str: string) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    }

    saveChannelInstagramSandbox() {
        // console.log(this.selectedSandbox);
        if (this.selectedSandbox && 'accountHandler' in this.selectedSandbox && this.ownerName !== '') {
            const channel = {
                channelOwnerName: this.ownerName,
                sandboxId: this.selectedSandbox.id,
                channel: "INSTAGRAM_SANDBOX",
                accountId: this.selectedSandbox.accountHandler
            };
            this.channelService.create(this.slug, channel).subscribe(
                (res: any) => {
                    // console.log(res);
                    this.getChannels();
                    this.alertService.success('Se ha creado el canal sandbox');
                    this.phoneSandbox = '';
                    this.selectedSandbox = null;
                    this.sandbox = false;
                    this.sandboxList = [];
                    this.sandboxToken = res.sandboxToken;
                    this.modalService.close('popup-new-channel');
                    this.modalService.open('popup-instagram-sandbox');

                    tippy('.copy', {
                        content: 'Copiado',
                        trigger: 'click',
                    });
                },
                err => {
                    console.log(err);
                    this.alertService.error('Hubo un problema en la creación del canal sandbox');
                }
            );
        } else {
            this.alertService.error('Todos los campos son requeridos');
        }
    }

    // popup
    openModal(id: string, canalId, nameChannel) {
        this.modalService.open(id);
        this.canalId = canalId;
        this.nameChannel = nameChannel;
    }

    closeModal(id: string) {
        this.modalService.close(id);
        if (id === 'popup-new-channel') {
            this.sandbox = false;
            this.sandboxList = [];
            this.phoneSandbox = '';
            this.selectedSandbox = null;
        }
    }
    //

    redirectDiagrammer() {
        this.loaderService.show();
        this.getUrlParam('customer_slug', (customerSlug) => {
            window.location.href = window.location.origin + '/customers/' + customerSlug + '/platforms/' + this.currentPlatformSlug + '/diagram';
        });
    }

    goToWABA() {
        const newWhatsappChannel = {
            customerSlug: this.slug,
            platformSlug: this.currentPlatformSlug
        };
        localStorage.setItem('newWhatsappChannel', JSON.stringify(newWhatsappChannel));
        this.router.navigate(['/waba']);
    }

    getUrlParam(param: string, success) {
        const paramInterval = setInterval(() => {
            if (this.activate !== null) {
                if (this.activate.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.activate.paramMap.subscribe(params => {
                        const p = params.get(param);
                        success(p);
                    });
                }
            }
        }, 500);
    }

    toggleCopyMenu(channelIdSelected) {
        this.showCopyMenu = !this.showCopyMenu;
        this.channelIdSelected = channelIdSelected;
    }

    toggleBlipForm() {
        this.blip = !this.blip;
        this.isHidden = !this.isHidden;
        switch(this.currentPlatformSlug){
            case 'WHATSAPP':
                this.blipSubchannel = 'WHATSAPP';
                break;
            case 'FB_MESSENGER': 
                this.blipSubchannel = 'FACEBOOK';
                break;
            case 'INSTAGRAM':
                this.blipSubchannel = 'INSTAGRAM';
                break;
            case 'TWITTER_DM':
                this.blipSubchannel = 'TWITTER';
                break;
            default:
                this.blipSubchannel = '';
        }
    }

    saveChannelBlip(channelOwnerId, channelOwnerName, serverUrl, accessSecret) {
        const jsonMainAccount = {
            channelOwnerName: this.isTunnel ? this.blipChannelOwnerName.nativeElement.value : channelOwnerName.value,
            accessSecret: this.isTunnel ? this.blipAccessSecret.nativeElement.value : accessSecret.value,
            channelOwnerId: this.isTunnel ? this.blipChannelOwnerId.nativeElement.value : channelOwnerId.value
        };
        const jsonData = {
            mainAccount: jsonMainAccount,
            isTunnel: this.isTunnel
        };
        let object = {
            channelOwnerId: channelOwnerId.value,
            channelOwnerName: channelOwnerName.value,
            accessSecret: accessSecret.value,
            serverUrl: serverUrl.value,
            isSubbot: this.isTunnel,
            router: jsonData,
            channel: "BLIP",
            channelSubtype: this.blipSubchannel
        };
        if(this.currentPlatformSlug === "WHATSAPP"){
            object["phoneNumber"] = this.blipPhoneNumber.nativeElement.value; 
        }    
        this.saveChannel(object);
    
    }

}
