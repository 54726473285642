import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class BlacklistService {

  constructor(private http: HttpClient) { }



  // https://flux-pre.gus.chat/api/v1/blackList?outputType=simpleMap
  get(slug: string) {
    const url = '/coreapi/v2/blackList?outputType=simpleMap';
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.get(url, { headers });
  }


  supportChannels(slug) {
    const url = '/coreapi/v2/supportedChannels';
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.get(url, { headers });

  }

  // https://flux-pre.gus.chat/api/v1/tenant/12b94b09-24ed-41a1-b593-4b0f259f6e83
  update(slug, id, obj) {

    const url = `/coreapi/v2/tenant/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.patch(url, obj, { headers });

  }

  // https://flux-pre.gus.chat/api/v1/blackList

  add(slug, obj) {

    const url = '/coreapi/v2/blackList';
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.post(url, obj, { headers });

  }

  // https://flux-pre.gus.chat/api/v1/blackList/2d5bde48-015f-40f8-b95f-408f01d6554a

  delete(id: any, slug: any) {

    const url = `/coreapi/v2/blackList/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.delete(url, { headers });


  }
}
