import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CustomerService } from '../../services/customer.service';
import { LoaderService } from '../../services/loader.service';
import { AuthServices } from '../../services/auth.guard.service';
import { AllowedRolService } from '../../services/rol-service.service';
import { AlertService } from '../../services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataLayerService } from 'src/services/data-layer.service';
import { FileService } from 'src/services/file.service'
declare var translate: any;
@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
    // @ViewChild('imageView', null) imageView: ElementRef;
    slug: any = '';
    customerId: any = '';
    imgURL: any = '';
    industries: any = [];
    customers: any = [];
    dataSlug: any = [];
    titleCustomer: string;
    descriptionCustomer: string;
    imageURL: string;
    urlParameter: any;
    formFiles: any;

    role: any;

    optionSelected: any;
    typeIndustryValue: any;
    emailsInvited: any = [];
    authorizeRol: boolean;

    files: any[] = [];
    existsImage = false;

    guests = [];
    guestsDeleted = [];
    currentUserMail = '';
    superUserMail = '';
    constUserId = '';
    translate: any;
    flagPath: string;

    constructor(
        private customerService: CustomerService,
        private loaderService: LoaderService,
        private authServices: AuthServices,
        private allowedRolService: AllowedRolService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private dataLayerService: DataLayerService,
        private fileService: FileService,
        private recaptchaV3Service: ReCaptchaV3Service
    ) {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = 'Nuevo bot -> ';
    }

    ngOnInit() {
        this.urlParameter = window.location.href;
        this.titleCustomer = translate.sections.customer.titleCustomer;
        this.descriptionCustomer = translate.sections.customer.fillInfo;

        this.currentUserMail = localStorage.getItem('email');
        this.superUserMail = 'constructor@holagus.com';
        this.constUserId = localStorage.getItem('constructorUserId');

        const parameterCustomerSlug = this.route.snapshot.paramMap.get('customer_slug');
        // console.log(parameterCustomerSlug);

        if (parameterCustomerSlug) {
            this.slug = parameterCustomerSlug;
            this.titleCustomer = translate.sections.customer.titleCustomerEdit;
            this.descriptionCustomer = translate.sections.customer.fillEditInfo;
            this.getBySlug(this.slug);
        } else {
            this.dataLayerService.setFlag(this.flagPath, 'start');
        }

        this.authServices.verifyToken(JSON.stringify({
            user_id: this.authServices.getIdGoogle(),
            token: this.authServices.getToken(),
            auth_type: localStorage.getItem('Auth-Type')
        })).subscribe({
            next: (res: any) => {
                // console.log(res);
                this.role = res.role.name;
                // console.log(this.role);

                if (res.status === 'ACTIVE' && res.role.name !== 'CUSTOM') {
                    localStorage.setItem('photo', res.avatar);
                    this.authorizeRol = this.allowedRolService.allowed(res.role.name, 'DEV');
                } else {
                    this.authServices.logout();
                }

            },
            error: err => {
                console.log(err);
                this.authServices.logout();
            }
        });

        this.getTypeOfIndustry();
        this.getListCustomers();
        // const constructorUserId = 'fe196270-1e4e-40f6-82d3-de966190a717';
        // console.log(constructorUserId);
        // this.customerService.getByUserId(
        //     constructorUserId
        // ).subscribe(response => {
        //     console.log(response);
        // });
    }

    getTypeOfIndustry() {
        this.customerService.getByTypeIndustry().subscribe({
            next: res => {
                // console.log(res);
                this.industries = res;
                // this.loaderService.close();
            },
            error: err => {
                console.log(err);
            }
        });
    }

    getBySlug(slug: string) {
        this.customerService.getBySlug(slug).subscribe({
            next: (res: any) => {
                this.dataSlug = res;
                this.guests = res.tenant.constructorUsers;
                // console.log('service by slug');
                // console.log(this.dataSlug);
                this.customerId = res.tenant.id;
                // console.log('value customerid', this.customerId);

                // console.log(res.tenant.constructorUsers);

                const selectIndustry: any = document.getElementById('industrias');

                for (const option of selectIndustry) {
                    if (option.getAttribute('value') === res.tenant.serviceType.name) {
                        option.setAttribute('selected', 'selected');
                        this.optionSelected = option;
                        // console.log(this.optionSelected);
                    }
                }
                // this.loaderService.close();
                this.imageURL = res.tenant.image;
                if (this.imageURL === '') {
                    this.existsImage = false;
                } else {
                    this.existsImage = true;
                }
                // console.log(res.tenant.image);
            },
            error: err => {
                console.log(err);
                // this.modalService.close('popup-new-bot');
                this.dataSlug.tenant = '';
                this.imageURL = '';
                this.existsImage = false;
            }
        });
    }

    getListCustomers() {
        this.customerService.getByUserId(localStorage.getItem('constructorUserId')).subscribe({
            next: (res: any) => {
                this.customers = res.tenants;
                // console.log(this.customers);
            },
            error: err => {
                console.log(err);
            }
        });
    }


    uploadFile(files) {
        this.formFiles = new FormData();
        this.formFiles.append('document', files[0]);
        // console.log('slug: ', this.slug);
        if (this.slug !== '') {
            this.recaptchaV3Service.execute('uploadFile').subscribe((reToken) => {
                this.fileService.upload(this.slug, this.formFiles, reToken).subscribe({
                    next: (res: any) => {
                        // console.log(res);
                        this.existsImage = true;
                        this.imageURL = res.url;
                        // this.imageView.nativeElement.setAttribute('src', this.imageURL);
                        // this.loaderService.close();
                    },
                    error: err => {
                        console.log(err);
                        this.existsImage = false;
                        this.imageURL = '';
                    }
                });
            });
        } else {

            const file = files[0];
            const reader = new FileReader();
            // const image = this.imageView;
            reader.onloadend = () => {
                this.existsImage = true;
                this.imageURL = reader.result.toString();
            };
            if (file) {
                reader.readAsDataURL(file);
            } else {
                this.existsImage = false;
                this.imageURL = '';
            }
            this.dataLayerService.setFlag(this.flagPath, 'image');
        }
    }


    // add invited emails
    addUser() {
        this.emailsInvited.push({});
        // console.log(this.emailsInvited);
    }

    addCustomer(nameCompany, typeIndustry, botName, website) {
        const emails: any = document.querySelectorAll('.usersInvited input');
        const roles: any = document.querySelectorAll('.usersInvited select');

        let industryType = {
            name: 'UNDEFINED',
            label: translate.sections.customer.unspecified
        }

        if (typeIndustry.value !== '') {
            industryType = typeIndustry.value;
        }

        if (this.slug === '') {
            // console.log('add customer');

            // service add customer
            const slug = this.createSlug(nameCompany.value);

            this.customerService.create({
                slug,
                botName: botName.value,
                name: nameCompany.value,
                image: '',
                website: website.value,
                serviceType: industryType
            }).subscribe({
                next: (responseCreate: any) => {
                    // console.log(responseCreate);
                    this.customerId = responseCreate.id;

                    this.addGuests();

                    localStorage.setItem('step1', 'true');

                    // this.closeModal('popup-new-bot');
                    // this.alertService.success('Cliente creado correctamente');
                    // this.imageView.nativeElement.setAttribute('src', '');
                    // this.router.navigate(['/customers']);
                    this.existsImage = false;

                    // load service Image
                    // console.log(this.formFiles);
                    if (this.formFiles) {
                        this.recaptchaV3Service.execute('uploadFile').subscribe((reToken) => {
                            this.fileService.upload(responseCreate.slug, this.formFiles, reToken).subscribe({
                                next: (res: any) => {
                                    // console.log(res);
                                    this.imageURL = res.url;
                                    this.existsImage = true;
                                    const sendCustomerUpdate = {
                                        botName: responseCreate.botName,
                                        image: res.url,
                                        name: responseCreate.name,
                                        website: responseCreate.website,
                                        serviceType: typeIndustry.value
                                    };
                                    // this.imageView.nativeElement.setAttribute('src', res.url);
                                    this.customerService.update(responseCreate.slug, responseCreate.id, sendCustomerUpdate).subscribe({
                                        next: res => {
                                            // console.log(res);
                                            this.loaderService.close();
                                            // window['dataLayer'].push({
                                            //     'event': 'created',
                                            //     'name': 'Bot'
                                            // });
                                            this.dataLayerService.setFlag(this.flagPath, 'create');
                                            this.alertService.success(translate.sections.customer.botCreated);
                                            this.router.navigate(['/customers']);
                                        },
                                        error: err => {
                                            console.log(err);
                                        }
                                    });
                                }
                            });
                        });
                    } else {
                        // window['dataLayer'].push({
                        //     'event': 'created',
                        //     'name': 'Bot'
                        // });
                        this.dataLayerService.setFlag(this.flagPath, 'create');
                        this.alertService.success(translate.sections.customer.botCreated);
                        this.router.navigate(['/customers']);
                    }

                },
                error: err => {
                    this.alertService.error(translate.sections.customer.botNoCreated);
                    console.log(err);
                }
            });

        } else if (this.slug !== '') {

            // console.log('service edit customer');

            // send to edit
            const objectCustomerEdit = {
                botName: botName.value,
                image: this.imageURL,
                name: nameCompany.value,
                website: website.value,
                serviceType: typeIndustry.value
            };
            // service update customer
            this.customerService.update(this.slug, this.customerId, objectCustomerEdit).subscribe({
                next: (res: any) => {

                    this.alertService.success(translate.sections.customer.botEdited);
                    // this.router.navigate(['/customers']);

                    this.addGuests();
                    this.deleteGuests();

                    // this.closeModal('popup-new-bot');
                    this.dataLayerService.setFlag(this.flagPath, 'edit');
                },
                error: err => {
                    console.log(err);
                    this.alertService.error(translate.sections.customer.botErrorEdited);

                }
            });
        }
    }

    deleteAdmin(email: string) {
        this.customerService.deleteAdmins(this.customerId, [email], localStorage.getItem('constructorUserId'), this.slug).subscribe({
            next: res => {
                this.alertService.success(translate.sections.customer.adminRemoved);
                // console.log(res);
                this.emailsInvited.length = 0;

                this.getBySlug(this.slug);

            },
            error: err => {
                console.log(err);
                this.emailsInvited.length = 0;
            }
        });
    }

    redirectConfig() {
        this.router.navigate(['/customers', this.slug, 'menu']);
    }
    deleteInput(index) {
        this.emailsInvited.splice(index, 1);
    }
    createSlug(name: any) {
        const slug = name.toLowerCase().replace(/ /g, '-').replaceAll(/[^0-9a-z_\-]/g, '') + '-' + (new Date().getTime()).toString(36);
        return slug;
    }

    onEnterGuest(e) {
        this.addGuestView(e.target);
    }

    addGuests() {
        const emails = [];
        for (const guest of this.guests) {
            if (!guest.hasOwnProperty('id')) {
                emails.push(guest.email);
            }
        }
        if (emails.length > 0) {
            this.customerService.addAdmins(this.customerId, emails, this.constUserId, this.slug).subscribe({
                next: res => {
                    // console.log(res);
                },
                error: err => {
                    console.log(err);
                }
            });
        }
    }

    deleteGuests() {
        this.customerService.deleteAdmins(this.customerId, this.guestsDeleted, this.constUserId, this.slug).subscribe({
            next: res => {
                // console.log(res);
            },
            error: err => {
                console.log(err);
            }
        });
    }

    addGuestView(inputEmail) {
        if (inputEmail.value !== '' && !this.guests.some(el => el.email === inputEmail.value)) {
            this.guests.push({ email: inputEmail.value });
            this.dataLayerService.setFlag(this.flagPath, 'share');
        }
        inputEmail.value = '';
    }

    deleteGuestView(idx) {
        this.guestsDeleted.push(this.guests[idx].email);
        this.guests.splice(idx, 1);
    }

    onFileDropped($event) {
        // console.log('function: onFileDropped');
        // console.log($event);
        this.prepareFilesList($event);
    }

    fileBrowseHandler(event) {
        const files = event.target.files;
        // console.log('function: fileBrowseHandler');
        // console.log(files);
        this.prepareFilesList(files);
    }

    deleteFile(index: number) {
        $('.urlS3').text('');
        this.files.splice(index, 1);
    }

    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            this.files.push(item);
        }
        this.uploadFile(files);
    }

    formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    onChangeCompanyName() {
        this.dataLayerService.setFlag(this.flagPath, 'companyName');
    }

    onChangeBotName() {
        this.dataLayerService.setFlag(this.flagPath, 'botName');
    }

    onChangeIndustry() {
        this.dataLayerService.setFlag(this.flagPath, 'industry');
    }
    onChangeWebsite() {
        this.dataLayerService.setFlag(this.flagPath, 'website');
    }
}
