import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { AlertService } from '../../../services/alert.service';
import { AuthServices } from '../../../services/auth.guard.service';
import { AllowedRolService } from '../../../services/rol-service.service';
import { LoaderService } from '../../../services/loader.service';
declare var translate: any;
@Component({
    selector: 'app-config-customers',
    templateUrl: './config-customers.component.html',
    styleUrls: ['./config-customers.component.scss']
})
export class ConfigCustomersComponent implements OnInit {

    configData: any = [];
    slug: string;
    authorizeRol = false;

    currentRole: any;
    translate: any;
    constructor(
        private activate: ActivatedRoute,
        private customerService: CustomerService,
        private authservice: AuthServices,
        private loaderService: LoaderService,
        private alertService: AlertService,
        private allowedRoleService: AllowedRolService,
        private router: Router,
        private authServices: AuthServices) {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    ngOnInit() {

        this.authservice.verifyToken(JSON.stringify({
            user_id : this.authservice.getIdGoogle(),
            token: this.authservice.getToken(),
            auth_type: localStorage.getItem('Auth-Type')
        })).subscribe(
            (res: any) => {
                console.log(res);
                this.currentRole = res.role.name;
                if (res.status === 'ACTIVE' && res.role.name !== 'CUSTOM') {
                    localStorage.setItem('photo', res.avatar);
                    this.authorizeRol = this.allowedRoleService.allowed(res.role.name, 'DEV');
                } else {
                    console.log('logout config-customer1');
                    this.authservice.logout();
                }
            },
            err => {
                console.log(err);
                console.log('logout config-customer2');
                this.authServices.logout();
            }
        );

        const parameterCustomerSlug = this.activate.snapshot.paramMap.get('customer_slug');
        console.log('parameter slug');
        console.log(parameterCustomerSlug);
        if (parameterCustomerSlug) {

            this.slug = parameterCustomerSlug;
            this.customerService.getBySlug(parameterCustomerSlug).subscribe(

                res => {
                    this.configData = res;
                    console.log('config', this.configData);
                    // this.loaderService.close();
                },
                err => {
                    console.log(err);
                }
            );
        }
    }

    saveConfig(ticketLife, listMode, loadConversations, errorCount,
               errorCountWindowTime, circuitBreakWindowTime, speechToText, transactionEnabled, transactionWaitTime, countryCode) {


        const configCustomer = {
            ticketLife : ticketLife.value,
            listMode : listMode.value,
            loadConversations : loadConversations.value,
            errorCount: errorCount.value,
            errorCountWindowTime: errorCountWindowTime.value,
            circuitBreakWindowTime: circuitBreakWindowTime.value,
            speechToText : speechToText.value,
            transactionEnabled : transactionEnabled.value,
            transactionWaitTime : transactionWaitTime.value,
            countryCode : countryCode.value,

        };

        this.customerService.update(this.slug, this.configData.tenant.id, configCustomer).subscribe(
            res => {
                console.log(res);
                this.alertService.success(translate.sections.customers.configCustomers.editedSetting);
                setTimeout(() => {
                    this.router.navigateByUrl('/customers');
                }, 900);
            },
            err => {
                console.log(err);
                this.alertService.error(err);
            }
        );
    }
}
