import { Component, OnInit } from '@angular/core';
import { EcommerceService } from '../../services/ecommerce.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '../../components/modal/modal.service';
import { environment } from '../../environments/environment';
import { DataLayerService } from 'src/services/data-layer.service';
import tippy from 'tippy.js';

declare var translate: any;
@Component({
    selector: 'app-ecommerce',
    templateUrl: './ecommerce.component.html',
    styleUrls: ['./ecommerce.component.scss']
})
export class EcommerceComponent implements OnInit {
    urlParameter: any;
    woocommerceUrl;
    slug: any;
    ecommerce: any;
    ecommerces: any = [];
    typesEcommerce: any = [];
    routeImageChannel = './../assets/img/channels/';
    defaultImage = './../assets/img/channels/puzzle.svg';
    headers: any = [];
    zendeskUrl: any = false;
    translate: any;
    flagPath: string;

    constructor(
        private ecommerceService: EcommerceService,
        private activate: ActivatedRoute,
        private router: Router,
        private modalService: ModalService,
        private dataLayerService: DataLayerService
    ) {
        //this.woocommerceUrl = this.getWoocommerceUrl();
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = 'Ecommerce -> ';
    }

    ngOnInit() {
        this.urlParameter = window.location.href;
        const parameters = this.activate.snapshot.params;

        if (parameters.customer_slug) {
            this.slug = parameters.customer_slug;
            localStorage.setItem('currentCustomerSlug', parameters.customer_slug);
            this.get(parameters.customer_slug);

        }
        this.dataLayerService.setFlag(this.flagPath, 'start');
    }

    getWoocommerceUrl(ecommerce, serverUrl) {
        return this.woocommerceUrl = `${serverUrl}/wc-auth/v1/authorize/?app_name=Guschat&user_id=${ecommerce.id}&return_url=${window.location.href}&callback_url=${environment.coreV2Url}woocommerce/${this.slug}/activate&scope=read_write`;
    }

    addEcommerce() {

        const uuid: any = Date.now().toString();
        const timestamp = uuid.substring(uuid.length, uuid.length - 5);
        const object = {
            name: 'Ecommerce',
            serverUrl: 'http://ecommerce.gus.chat/',
            isEnabled: false,
            status: 'ACTIVE'
        };

        this.ecommerceService.add(this.slug, object).subscribe(
            res => {
                this.get(this.slug);
                this.dataLayerService.setFlag(this.flagPath, 'add');
            },
            err => {
                console.log(err);
            }
        );

    }



    get(slug) {
        this.ecommerceService.get(slug).subscribe(
            res => {
                this.ecommerces = res;
                let envStr = '-' + environment.name;
                if (environment.name === 'pre') {
                    envStr = 'predev';
                } else if (environment.name === 'prod') {
                    envStr = '';
                }

                setTimeout(() => {
                    tippy('.copy', {
                        content: 'Copiado',
                        trigger: 'click',
                    });
                });
            },
            err => {
                console.log(err);
            }
        );
    }

    delete(ecommerce) {
        const objeto = {
            status: 'DELETED',
            isEnabled: false
        }
        this.ecommerceService.delete(ecommerce.id, this.slug, objeto).subscribe(
            res => {
                this.get(this.slug);
                this.dataLayerService.setFlag(this.flagPath, 'delete');
            },
            err => {
                console.log(err);

            }
        );

    }

    saveEcommerce(name, serverUrl, consumerKey, consumerSecret) {

        serverUrl.value = serverUrl.value.endsWith("/") ? serverUrl.value : serverUrl.value + "/"
        const objeto = {

            name: name ? name.value : 'Ecommerce',
            serverUrl: serverUrl ? serverUrl.value: 'http://ecommerce.gus.chat/',
            consumerKey: consumerKey ? consumerKey.value: null,
            consumerSecret: consumerSecret ? consumerSecret.value : null,
            isEnabled: this.ecommerce.isEnabled
        };

        this.ecommerceService.update(this.slug, this.ecommerce.id, objeto).subscribe(
            res => {
                this.get(this.slug);
                this.modalService.close('popup-edit');
                this.dataLayerService.setFlag(this.flagPath, 'Edit -> accept');
            },
            err => {
                console.log(err);

            }
        );

    }

    loginEcommerce(ecommerce, serverUrl, name) {

        const urlRedirect = this.getWoocommerceUrl(ecommerce, serverUrl.value);
        console.log(urlRedirect);
        this.saveEcommerce(name, serverUrl, null, null);
        this.dataLayerService.setFlag(this.flagPath, 'Edit -> apiLog');
        window.location.href = urlRedirect;

    }

    activateDisableEcommerce(id, ecommerce) {

        if (ecommerce.isEnabled === false){
            this.ecommerceService.enable(this.slug, id).subscribe(
                res => {
                    this.get(this.slug);
                },
                err => {
                    console.log(err);
                    const object = {
                        isEnabled: true
                    }
                    this.ecommerceService.activate(this.slug, id, object).subscribe(
                        res => {
                            this.get(this.slug);
                        },
                        err => {
                                console.log(err);
                        }
                    );

                }
            );

        } else {
            const object = {
                isEnabled: ecommerce.isEnabled === true ? false : true
            }
            this.ecommerceService.activate(this.slug, id, object).subscribe(
                res => {
                    this.get(this.slug);
                },
                err => {
                        console.log(err);
                }
            );
        }
    }


    addHeader() {
        this.headers.push({});
    }

    removeHeader(i: any) {
        this.headers.splice(i, 1);
    }


    openModal(id: string, ecommerce) {
        this.headers = [];

        this.ecommerceService.getOne(this.slug, ecommerce.id).subscribe(
            (res: any) => {
                this.ecommerce = res;

                if (this.ecommerce.headers) {
                    try {
                        const json = JSON.parse(this.ecommerce.headers);
                        const iterator = Object.keys(json);
                        for (const key of iterator) {
                            const header: any = {};
                            header.key = key;
                            header.value = json[key];
                            this.headers.push(header);

                        }

                        this.headers = Array.from(new Set(this.headers.map(JSON.stringify))).map((item: string) => {
                            return JSON.parse(item);
                        });
                        // this.headers.length = this.consola.headers.length;
                    } catch (e) {
                        console.log(e);

                    }
                } else {

                }


                this.modalService.open(id);

            },
            err => {
                console.log(err);

            }
        );



    }

    closeModal(id: string) {

        this.modalService.close(id);
    }

    copy(str: string) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    }


    openPopupEdit(ecommerce) {
        this.dataLayerService.setFlag(this.flagPath, 'Edit -> start');
        this.openModal('popup-edit', ecommerce);
    }

    onChangeName() {
        this.dataLayerService.setFlag(this.flagPath, 'Edit -> name');
    }

    onChangeLocation() {
        this.dataLayerService.setFlag(this.flagPath, 'Edit -> location');
    }

    onChangeKey() {
        this.dataLayerService.setFlag(this.flagPath, 'Edit -> key');
    }

    onChangeSecret() {
        this.dataLayerService.setFlag(this.flagPath, 'Edit -> secret');
    }
}
