import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  getByOwnerId(id: string, slug: string) {
    const url = `/coreapi/v2/tenantChannels/${id}/chats`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.get(url, { headers });
  }


  create(chat: object, id: string, slug: string) {
    const url = `/coreapi/v2/tenantChannels/${id}/chats`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.post(url, chat, { headers });
  }




  update(objeto: any, slug: string, ownerID: any, id: any) {
    const url = `/coreapi/v2/tenantChannels/${ownerID}/chats/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.patch(url, objeto, { headers });
  }




  delete(slug: string, ownerID: any, id: any) {
    const url = `/coreapi/v2/tenantChannels/${ownerID}/chats/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.delete(url, { headers });
  }







}
