<div class="main">
  <div class="sectionConfig">
    <div class="container" [ngStyle]="{'margin-top': '40px'}">
        <h2 class="info">{{translate.sections.config.startMessage}}</h2>
      <input [(ngModel)]="welcomeMessage" class="inputInspectorStyle" type="text" placeholder="Ingresa el texto de bienvenida">
      <div class="content-buttons">
        <button (click)="saveMessage()" class="btn-primary btn-accept">{{translate.sections.config.save}}</button>
      <button  (click)= "deleteProperty('greeting')" class="btn-primary btn-accept">{{translate.sections.config.delete}}</button>
      </div>

    </div>



    <!-- test -->

    <div class="container">

      <h2 class="info">{{translate.sections.config.startButton}}</h2>
      <p>{{translate.sections.config.configMessage}}.</p>
      <div>

          <div>
            <div id="jsoneditor2"></div>
              <!-- <json-editor id="jsoneditor2" [options]="editorOptions2" [data]="data2" #editor2></json-editor> -->
          </div>
          <br>
         <div class="content-buttons">
          <button (click)="saveStartButton()" class="btn-primary btn-accept" type="button">
            {{translate.sections.config.save}}
        </button>
        <button (click)= "deleteProperty('get_started')" class="btn-primary btn-accept">{{translate.sections.config.delete}}</button>
         </div>
      </div>
  </div>

    <!-- end -->


    <!-- main per -->

    <div class="container">
      <h2 class="info">{{translate.sections.config.persistentMenu}}</h2>
      <p>{{translate.sections.config.configMessage}}.</p>
      <div>
          <div>
            <div id="jsoneditor"></div>
              <!-- <json-editor [options]="editorOptions1" [data]="data1" #editor1></json-editor> -->
          </div>
          <br>
          <div class="content-buttons">
            <button type="button" class="btn-primary btn-accept" (click)="savePersistentMenu()">{{translate.sections.config.save}}
            </button>

            <button (click)= "deleteProperty('persistent_menu')" class="btn-primary btn-accept">{{translate.sections.config.delete}}</button>
          </div>
      </div>
  </div>

    <!-- end -->

    <div class="container" [ngStyle]="{'margin-top': '40px'}">
      <h2 class="info">{{translate.sections.config.approvedDomainList}}
      </h2>
      <textarea [ngStyle]="{'height': '50px'}" [(ngModel)]="domains" class="inputInspectorStyle" name="" id="" cols="30" rows="10"></textarea>

  <div class="content-buttons2">
    <button (click)="saveDomains()" class="btn-primary btn-accept">{{translate.sections.config.save}}</button>
    <button (click)="saveDefault()" class="btn-primary btn-accept">{{translate.sections.config.loadDefault}}</button>
  </div>
  </div>
</div>

</div>
