import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/services/alert.service';
import { AuthServices } from 'src/services/auth.guard.service';
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { HttpClient } from '@angular/common/http';
import { DataLayerService } from 'src/services/data-layer.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
declare var translate: any;

@Component({
    selector: 'app-sso',
    templateUrl: './sso.component.html',
    styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

    translate: any;

    email: string;
    providers: any[];
    flagPath: string;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private authService: AuthServices,
        private alertService: AlertService,
        private router: Router,
        public auth: AngularFireAuth,
        private dataLayerService: DataLayerService
    ) {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.providers = [];
        // this.getCognitoProviders();
        this.getGoogleProviders();

        // access_token
        // id_token
        // token_type
        // expires_in
        const params = this.getUrlHashParams();
        if (params && 'access_token' in params) {
            this.signInCognito(params);
        }
        this.flagPath = '';
    }

    ngOnInit() {
        this.dataLayerService.setFlag(this.flagPath, 'LogIn SSO - start');
    }

    signInGoogleIdentityProvider(provider) {
        this.auth.signInWithPopup(new firebase.auth.SAMLAuthProvider(provider.id))
        .then((result) => {
            // console.log(JSON.stringify(result));
            result.user.getIdTokenResult().then(
                (r)=>{
                    const payload: any = {
                        user_name: r.claims.email,
                        email: r.claims.email,
                        name: r.claims.email,
                        user_id: r.claims.user_id,
                        avatar: '',
                        auth_type: 'GOOGLE_FIREBASE',
                        token: r.token
                    };
                    this.authService.registerGoogle(payload).subscribe(
                        (res: any) => {
                            if (res.status === 'ACTIVE') {
                                // console.log('user logged firebase!!');
                                localStorage.setItem('token', res.currentToken.token);
                                localStorage.setItem('id', res.googleId);
                                localStorage.setItem('photo', res.avatar);
                                localStorage.setItem('name', res.name);
                                localStorage.setItem('email', res.email);
                                localStorage.setItem('constructorUserId', res.id);
                                localStorage.setItem('Auth-Type','GOOGLE_FIREBASE');
                                localStorage.setItem('payload',JSON.stringify(payload));

                                this.dataLayerService.setFlag(this.flagPath, 'LogIn SSO - email');
                                this.dataLayerService.setFlag(this.flagPath, 'LogIn SSO - log');

                                this.alertService.success('Bienvenido!');

                                this.route.queryParams.subscribe(params => {
                                    if ('redirect_uri' in params) {
                                        // console.log('llego redirect');
                                        const redirectUri = decodeURIComponent(params['redirect_uri']);
                                        // console.log(redirectUri);
                                        window.location.href = environment.baseUrl + redirectUri;
                                    } else {
                                        // console.log('llego redirect otro');
                                        window.location.href = '/customers';
                                    }
                                });

                            } else {
                                this.alertService.warning('Tu usuario no se encuentra activo aún, \
                                    contacta con un admministrador para activarlo.');
                            }
                        },
                        err => {
                            this.alertService.error('No se ha podido realizar el login.');
                        }

                    );
                    // User is signed in.
                    // Identity provider data available in result.additionalUserInfo.profile,
                    // or from the user's ID token obtained from result.user.getIdToken()
                    // as an object in the firebase.sign_in_attributes custom claim
                    // This is also available from result.user.getIdTokenResult()
                    // idTokenResult.claims.firebase.sign_in_attributes.
                }
            ).catch((error) => {
                // Handle error.
                console.log("Error obteniedo datos de usuario de google", error);
            });
        })
        .catch((error) => {
            // Handle error.
            console.log(error);
        });
    }

    getGoogleProviders() {
        this.providers = [
            {
                id: 'saml.flux-2',
                domain: 'holagus.com'
            }, {
                id: 'saml.azure-gus',
                domain: 'gus.chat'
            }, {
                id: 'saml.sura',
                domain: 'suramexico.com'
            }, {
                id: 'saml.kueski',
                domain: 'kueski.com'
            }, {
                id: 'saml.prosegur',
                domain: 'prosegur.com'
            }, {
                id: 'saml.prosegur',
                domain: 'prosegur.com.br'
            }
        ];
        if (environment.name === 'stage') {
            this.providers.push({
                id: 'saml.metlife-qa',
                domain: 'metlife.com'
            });
            this.providers.push({
                id: 'saml.metlife-qa',
                domain: 'metlife.com.mx'
            });
        } else {
            this.providers.push({
                id: 'saml.metlife',
                domain: 'metlife.com'
            });
            this.providers.push({
                id: 'saml.metlife',
                domain: 'metlife.com.mx'
            });
        }
    }

    signInCognito(params) {
        const payload: any = {
            auth_type: 'COGNITO',
            token: params.access_token,
            id_token: params.id_token,
        };
        // console.log(payload);
        this.authService.registerSSO(payload).subscribe(
            (res: any) => {
                if (res.status === 'ACTIVE') {
                    // console.log('user logged cognito!!');
                    // console.log(JSON.stringify(res.currentToken));
                    localStorage.setItem('id_token', params.id_token);
                    localStorage.setItem('token', res.currentToken.token);
                    localStorage.setItem('id', res.id);
                    localStorage.setItem('photo', res.avatar);
                    localStorage.setItem('name', res.name);
                    localStorage.setItem('email', res.email);
                    localStorage.setItem('constructorUserId', res.id);
                    localStorage.setItem('Auth-Type','COGNITO');

                    this.dataLayerService.setFlag(this.flagPath, 'LogIn SSO - email');
                    this.dataLayerService.setFlag(this.flagPath, 'LogIn SSO - log');

                    this.alertService.success('Bienvenido!');

                    this.route.queryParams.subscribe(params => {
                        if ('redirect_uri' in params) {
                            const redirectUri = decodeURIComponent(params['redirect_uri']);
                            window.location.href = environment.baseUrl + redirectUri;
                        } else {
                            // console.log('llego redirect otro');
                            window.location.href = '/customers';
                        }
                    });

                } else {
                    this.alertService.warning('Tu usuario no se encuentra activo aún, \
                        contacta con un admministrador para activarlo.');
                    this.router.navigate(['/sso']);
                }
            },
            err => {
                this.alertService.error('No se ha podido realizar el login.');
                this.router.navigate(['/sso']);
            }
        );
    }

    getCognitoProviders() {
        const providersId = [
            {
                id: 'Azure-Gus',
                domain: 'gus.chat'
            }, {
                id: 'Google-Gus',
                domain: 'holagus.com'
            }
        ];
        providersId.forEach(provider => {
            const providerName = provider.id.replaceAll('-', ' ');
            const newProvider = {
                domain: provider.domain,
                name: providerName,
                url: `${environment.cognito.url}/oauth2/authorize?response_type=${environment.cognito.responseType}&identity_provider=${provider.id}&redirect_uri=${environment.cognito.redirectUri}&client_id=${environment.cognito.clientId}&scope=${environment.cognito.scope}`
            };
            this.providers.push(newProvider);
        });
    }

    getUrlHashParams() {
        if (window.location.hash !== ''){
            const hash = window.location.hash.substr(1);
            return hash.split('&').reduce(function (res, item) {
                const parts = item.split('=');
                res[parts[0]] = parts[1];
                return res;
            }, {});
        } else {
            return null;
        }
    }

    getDomain(email) {
        const chunks = email.split('@');
        return chunks[1];
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    login() {
        localStorage.setItem('authTypeB', 'GOOGLE_FIREBASE');
        if (this.email !== '' && this.validateEmail(this.email)) {
            const domain = this.getDomain(this.email);
            let existsDomain = false;

            for (let index = 0; index < this.providers.length; index++) {
                const provider = this.providers[index];
                if (domain === provider.domain) {
                    this.signInGoogleIdentityProvider(provider);
                    existsDomain = true;
                }
            }

            if (!existsDomain) {
                this.alertService.error('No existe ningun proveedor asociado a este email');
            }
        } else {
            this.alertService.error('Debes ingresar un email válido');
        }
    }

    redirectLogin() {
        if (this.email !== '' && this.validateEmail(this.email)) {
            const domain = this.getDomain(this.email);
            let existsDomain = false;

            for (let index = 0; index < this.providers.length; index++) {
                const provider = this.providers[index];
                if (domain === provider.domain) {
                    window.location.href = provider.url;
                    existsDomain = true;
                }
            }

            if (!existsDomain) {
                this.alertService.error('No existe ningun proveedor asociado a este email');
            }
        } else {
            this.alertService.error('Debes ingresar un email válido');
        }
    }
}
