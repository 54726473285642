<div class="sectionChannels">
    <div class="wrapper-title">
        <h1 class="title">Canales</h1>
        <ul class="actions">
            <li>
                <button class="btn-primary" (click)="changeCustomer(currentPlatformSlug)">
                    <span>Agregar nuevo canal</span>
                    <i class="material-icons md-24">add_circle</i>
                </button>
            </li>
            <li *ngIf="existsWhatsappChannel()">
                <button class="btn-secondary-alternate" (click)="goToWABA()">
                    <span>WABA</span>
                </button>
            </li>
            <li>
                <button class="btn-secondary-alternate" (click)="redirectDiagrammer()">
                    <span>Ir a diagramador</span>
                    <i class="material-icons md-24">mediation</i>
                </button>
            </li>
        </ul>
    </div>

    <div class="container-fluid" [ngStyle]="{'margin-top': '40px'}">
        <div class="container1" *ngFor="let canal of channels;let i=index;">
            <!-- <img (click)="openModal('popup-confirm',canal.id,canal.channelOwnerName)" class="delete"
                src="../../assets/img/delete.svg" alt=""> -->
            <!-- <button (click)="openModal('popup-confirm',canal.id,canal.channelOwnerName)"  class="delete">eliminar</button> -->
            <div class="onoffswitch">
                <input [checked]='canal.enabled==true' #allow (click)="enable(canal.id,allow)" type="checkbox"
                    name="onoffswitch" class="onoffswitch-checkbox" id="{{i}}" tabindex="0">
                <label class="onoffswitch-label" for="{{i}}"></label>
            </div>
            <div [ngClass]="{'view': true, 'sandbox-icon': canal.sandbox, 'blip-icon': canal.blip}">

                <img class="image" [src]='getChannelImage(routeImageChannel, canal.channel)' alt="nombre de la imagen">
                <div class="info">
                    <span class="main-title">{{canal.channel}}</span>
                    <span class="small-title">{{canal.channelOwnerName}}</span>
                    <span class="small-title">[ {{canal.channelOwnerId}} ]</span>
                    <span class="small-title">Entorno: {{canal.environment.name}}</span>
                </div>
            </div>

            <div class="options">


                <div class="data">
                    <div class="subdata">
                        <button (click)="toggleCopyMenu(canal.id)" class="btn-icon"
                            title="Copiar info">
                            <i class="material-icons">content_copy</i>
                        </button>
                        <div *ngIf="showCopyMenu && channelIdSelected == canal.id" class="copy-menu">
                            <ul>
                                <li>
                                    <a (click)="copy(canal.id)">Copiar id</a>
                                </li>
                                <li>
                                    <a (click)="copy(canal.channelOwnerId)">Copiar channel owner id</a>
                                </li>
                            </ul>
                        </div>

                        <button class="btn-icon" (click)="redirectHorary(canal.id)" title="Horarios">
                            <i class="material-icons">access_time</i>
                        </button>
                        <button (click)="openChatW(canal.channelOwnerId)" *ngIf="canal.channel === 'WEBSITE' "
                            class="btn-icon" title="Chat">
                            <i class="material-icons">chat</i>
                        </button>

                        <button (click)="openChatF(canal.channelOwnerId)"
                            *ngIf="canal.channel === 'FB_MESSENGER'" class="btn-icon" title="Chat">
                            <i class="material-icons">chat</i>
                        </button>

                        <button (click)="openChatWhats(canal.channelOwnerId)"
                            *ngIf="canal.channel === 'WHATSAPP' && canal.channelSubtype === 'WHATSAPP'" class="btn-icon" title="Chat">
                            <i class="material-icons">chat</i>
                        </button>

                        <button (click)="openChatSS(canal.label)"
                            *ngIf="canal.channel === 'SMOOCH_SANDBOX' && canal.channelSubtype === 'WHATSAPP'" class="btn-icon" title="Chat">
                            <i class="material-icons">chat</i>
                        </button>

                        <button (click)="openChatSS(canal.channelOwnerName)"
                            *ngIf="canal.channel === 'WHATSAPP_SANDBOX' && canal.channelSubtype === 'WHATSAPP'" class="btn-icon" title="Chat">
                            <i class="material-icons">chat</i>
                        </button>

                        <button (click)="openChatCloud(canal.label)"
                            *ngIf="canal.channel === 'WHATSAPP' && canal.channelSubtype === 'CLOUD'" class="btn-icon" title="Chat">
                            <i class="material-icons">chat</i>
                        </button>

                        <button *ngIf="canal.channel === 'FB_MESSENGER'" (click)="redirectConfig(canal.channelOwnerId)"
                            class="btn-icon" title="Configuración">
                            <i class="material-icons">settings</i>
                        </button>
                        <button
                            *ngIf="canal.channel === 'WEBSITE' || canal.channel === 'WHATSAPP' || canal.channelSubtype === 'WHATSAPP'"
                            (click)="redirectConfigChat(canal.channelOwnerId)" class="btn-icon" title="Configuración">
                            <i class="material-icons">settings</i>
                        </button>

                        <!-- <button (click)="generateTokenAndWebhook(canal.channelOwnerId)" *ngIf="canal.channel === 'WHATSAPP'" class="btn-icon" title="Generar token y webhook">
                            <i class="material-icons">token</i>
                        </button> -->

                        <button (click)="openModal('popup-confirm',canal.id,canal.channelOwnerName)" class="btn-icon"
                            title="Eliminar">
                            <i class="material-icons">delete</i>
                        </button>

                    </div>

                </div>

            </div>
        </div>
    </div>

    <!-- <button (click)="create()">crear</button>  -->
    <!-- <button (click)="delete('fc417c5d-b72a-4c55-ae61-06275712461b')">delete channel</button>
    <button (click)="update('fc417c5d-b72a-4c55-ae61-06275712461b')">actualizar</button>
    <button (click)="create()">crear</button> -->

    <!-- modal confirm -->

    <jw-modal id="popup-confirm">
        <a class="close_modal" (click)="closeModal('popup-confirm');">×</a>
        <h3 [ngStyle]="{'text-align': 'center'}">¿Está seguro que desea eliminar la integración con {{nameChannel}} ?
        </h3>

        <div class="containerConfirm">
            <button (click)="deleteChannel()" class="btn-primary btn-alert">Confirmar</button>
            <button (click)="functionCancel('popup-confirm')" class="btn-primary btn-alert">Cancelar</button>
        </div>

    </jw-modal>

    <!-- end modal confirm-->


    <!-- modal create channel -->

    <!-- begin modal create channel -->
    <jw-modal id="popup-new-channel">
        <a class="close_modal" (click)="closeModal('popup-new-channel');">×</a>
        <div class="createCampaign">
            <h2 class="title2">Agregar Canal de {{channelSelect}} </h2>

            <div *ngIf="channelSelect=='WHATSAPP' || channelSelect=='FB_MESSENGER' || channelSelect=='INSTAGRAM' || channelSelect=='TWITTER_DM'" class="formGroup">
                <label>Blip</label>
                <input [(ngModel)]="blip" (click)="toggleBlipForm()" type="checkbox"
                    [ngModelOptions]="{standalone: true}">
            </div>
        
            <form *ngIf="channelSelect=='INSTAGRAM' && !isHidden">
                <!-- *ngIf="channelSelect=='WEBSITE'" -->


                <!-- <div class="formGroup">
                    <label for="">Nombre del canal</label>
                    <input #nameChannel class="inputInspectorStyle" type="text">
                </div> -->

                <!-- <div class="formGroup">
                    <label for="">ID de cuenta</label>
                    <input #idAccount class="inputInspectorStyle" type="text">
                </div> -->

                <!-- <div class="formGroup">
                    <label for="">Token</label>
                    <input #token class="inputInspectorStyle" type="text">
                </div> -->



                <div class="formGroup">
                    <label>Sandbox</label>
                    <input [(ngModel)]="sandbox" (click)="toggleForm()" type="checkbox"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div *ngIf="sandbox" class="sandbox">
                    <div class="formGroup">
                        <label>Cuenta</label>
                        <select [(ngModel)]="selectedSandbox" [ngModelOptions]="{standalone: true}">
                            <option value="">Seleccionar</option>
                            <option [ngValue]="s" *ngFor="let s of sandboxList">{{s.channelOwnerName}}</option>
                        </select>
                    </div>
                    <div class="formGroup">
                        <label>Nombre</label>
                        <input [(ngModel)]="ownerName" type="text" [ngModelOptions]="{standalone: true}">
                    </div>

                    <div class="formGroup">
                        <button (click)="saveChannelInstagramSandbox()" type="button"
                            class="btn-primary btn-save">Aceptar</button>
                    </div>
                </div>
                <div *ngIf="!sandbox" class="noSandbox">
                    <div class="formGroup">
                        <label for="">Selecciona la fan page que será asignada a este canal</label>
                        <div class="containerPages" #containerPagesI>
                            <div #elementPage class="dataPages" *ngFor="let pageF of pages"
                                (click)="savePage(pageF,elementPage,'INSTAGRAM')">
                                <img [src]='pageF.picture.data.url' alt="">
                                <span class="ml-10">{{pageF.name}}</span>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="nextPageUrl !== '' || prevPageUrl !== ''" class="paging clearfix">
                        <button *ngIf="prevPageUrl !== ''" class="prev btn-secondary" (click)="getPrevPages()">
                            < Anteriores</button>
                                <button *ngIf="nextPageUrl !== ''" class="next btn-secondary"
                                    (click)="getNextPages()">Siguientes ></button>
                    </div>

                    <div [ngStyle]="{'margin-top': '20px'}" class="formGroup">
                        <label for="">Entorno</label>
                        <select #environment name="">
                            <!-- <option value="">Seleccionar</option> -->

                            <option [value]="environment.id" *ngFor="let environment of environments"
                                [selected]="environment.name == 'default'">{{environment.name}}
                            </option>


                        </select>

                    </div>

                    <button (click)="saveChannelI()" type="button" class="btn-primary btn-save">Aceptar</button>
                </div>
            </form>

            <form *ngIf="channelSelect=='FB_MESSENGER' && !isHidden">
                <!-- *ngIf="channelSelect=='WEBSITE'" -->


                <!-- <div class="formGroup">
                    <label for="">Nombre del canal</label>
                    <input #nameChannel class="inputInspectorStyle" type="text">
                </div> -->

                <!-- <div class="formGroup">
                    <label for="">ID de cuenta</label>
                    <input #idAccount class="inputInspectorStyle" type="text">
                </div> -->

                <!-- <div class="formGroup">
                    <label for="">Token</label>
                    <input #token class="inputInspectorStyle" type="text">
                </div> -->




                <div class="formGroup">
                    <label for="">Selecciona la fan page que será asignada a este canal</label>
                    <div class="containerPages" #containerPagesF>
                        <div #elementPage class="dataPages" *ngFor="let pageF of pages"
                            (click)="savePage(pageF,elementPage,'FB_MESSENGER')">
                            <img [src]='pageF.picture.data.url' alt="">
                            <span class="ml-10">{{pageF.name}}</span>
                        </div>
                    </div>

                </div>

                <div *ngIf="nextPageUrl !== '' || prevPageUrl !== ''" class="paging clearfix">
                    <button *ngIf="prevPageUrl !== ''" class="prev btn-secondary" (click)="getPrevPages()">
                        < Anteriores</button>
                            <button *ngIf="nextPageUrl !== ''" class="next btn-secondary"
                                (click)="getNextPages()">Siguientes ></button>
                </div>

                <div [ngStyle]="{'margin-top': '20px'}" class="formGroup">
                    <label for="">Entorno</label>
                    <select #environment name="">
                        <!-- <option value="">Seleccionar</option> -->

                        <option [value]="environment.id" *ngFor="let environment of environments"
                            [selected]="environment.name == 'default'">{{environment.name}}
                        </option>


                    </select>

                </div>

                <!-- <div class="formGroup form-list-pages">
                    <label for="">Selecciona la fan page que será asignada a este canal</label>
                    <select #idPage name="" id="">
                        <option value="">Seleccionar</option>

                        <option [value]="page.id" *ngFor="let page of pages">{{page.name}}
                        </option>

                    </select>

                </div> -->




                <button (click)="saveChannelF()" type="button" class="btn-primary btn-save">Aceptar</button>
            </form>


            <form *ngIf="channelSelect=='TEAMS'">

                <div class="formGroup">
                    <label for="">Nombre del canal</label>
                    <input #nameChannel class="inputInspectorStyle" type="text">
                </div>

                <div class="formGroup">
                    <label for="">ID del bot</label>
                    <input #idBot class="inputInspectorStyle" type="text">
                </div>

                <div class="formGroup">
                    <label for="">ID de aplicación</label>
                    <input #IDApp class="inputInspectorStyle" type="text">
                </div>

                <div class="formGroup">
                    <label for="">Token</label>
                    <input #token class="inputInspectorStyle" type="text">
                </div>


                <div [ngStyle]="{'margin-top': '20px'}" class="formGroup">
                    <label for="">Entorno</label>
                    <select #environment name="">
                        <!-- <option value="">Seleccionar</option> -->

                        <option [value]="environment.id" *ngFor="let environment of environments"
                            [selected]="environment.name == 'default'">{{environment.name}}
                        </option>


                    </select>

                </div>


                <button (click)="saveChannelT(nameChannel,idBot,IDApp,token,environment)" type="button"
                    class="btn-primary btn-save">Aceptar</button>
            </form>


            <form *ngIf="channelSelect=='WEBSITE'">
                <!-- *ngIf="channelSelect=='WEBSITE'" -->
                <div class="formGroup">
                    <label for="">Sitio</label>
                    <input #nameSite class="inputInspectorStyle" type="text">
                </div>

                <button (click)="saveChannelW(nameSite)" type="button" class="btn-primary btn-save">Aceptar</button>
            </form>


            <form *ngIf="blip" class="blip-form">
                <div *ngIf="blip">
                    <div class="formGroup">
                        <label for="">ID del propietario</label>
                        <input #channelOwnerId class="inputInspectorStyle" type="text" placeholder="channelOwnerId">
                    </div>
                    <div class="formGroup">
                        <label for="">Propietario o nombre del canal</label>
                        <input #channelOwnerName class="inputInspectorStyle" type="text" placeholder="channelOwnerName">
                    </div>
                    <div class="formGroup">
                        <label for="">URL del server</label>
                        <input #serverUrl class="inputInspectorStyle" type="text" placeholder="serverUrl">
                    </div>
                    <div class="formGroup">
                        <label for="">Key del bot</label>
                        <input #accessSecret class="inputInspectorStyle" type="text" placeholder="accessSecret">
                    </div>
                    <div *ngIf="channelSelect=='WHATSAPP'" class="formGroup">
                        <label for="">Número telefónico</label>
                        <input #blipPhoneNumber class="inputInspectorStyle" type="text" placeholder="phoneNumber">
                    </div>
                    <hr>
                    <div class="formGroup">
                        <label>Subbot</label>
                        <input [(ngModel)]="isTunnel" type="checkbox"
                            [ngModelOptions]="{standalone: true}">
                    </div>
                    
                    <div *ngIf="isTunnel" class="formGroup">
                        <label for="">Identificador del enrutador</label>
                        <input #blipChannelOwnerId class="inputInspectorStyle" type="text" placeholder="blipChannelOwnerId">
                    </div>
                    <div *ngIf="isTunnel" class="formGroup">
                        <label for="">Nombre del enrutador</label>
                        <input #blipChannelOwnerName class="inputInspectorStyle" type="text" placeholder="blipChannelOwnerName">
                    </div>
                    <div *ngIf="isTunnel" class="formGroup">
                        <label for="">Key del enrutador</label>
                        <input #blipAccessSecret class="inputInspectorStyle" type="text" placeholder="blipAccessSecret">
                    </div>
                    <button (click)="saveChannelBlip(channelOwnerId, channelOwnerName, serverUrl, accessSecret)" type="button"
                        class="btn-primary btn-save">Aceptar</button>
                </div>
            </form>


            <!-- add whatsapp -->
            <form *ngIf="channelSelect=='WHATSAPP' && !isHidden">

                <div class="formGroup">
                    <label>Sandbox</label>
                    <input [(ngModel)]="sandbox" (click)="toggleForm()" type="checkbox"
                        [ngModelOptions]="{standalone: true}">
                </div>

                <!-- *ngIf="channelSelect=='WHATSAPP'" -->
                <!-- <div *ngIf="!sandbox" class="sandbox">
                    <div class="formGroup">
                        <label for="">Propietario</label>
                        <input #owner class="inputInspectorStyle" type="text">
                    </div>
                    <div class="formGroup">
                        <label for="">Número de teléfono</label>
                        <div class="country-phone-box">
                            <select #lada class="country">
                                <option value="34">España</option>
                                <option value="351">Portugal</option>
                                <option value="33">Francia</option>
                                <option value="521">México (521)</option>
                                <option value="52" selected>México (52)</option>
                                <option value="44">Reino Unido</option>
                                <option value="93">Afganistán</option>
                                <option value="355">Albania</option>
                                <option value="49">Alemania</option>
                                <option value="376">Andorra</option>
                                <option value="244">Angola</option>
                                <option value="966">Arabia Saudita</option>
                                <option value="213">Argelia</option>
                                <option value="54">Argentina</option>
                                <option value="374">Armenia</option>
                                <option value="297">Aruba</option>
                                <option value="61">Australia</option>
                                <option value="43">Austria</option>
                                <option value="994">Azerbaiyán</option>
                                <option value="597">Bandera de Surinam Surinam</option>
                                <option value="880">Bangladés</option>
                                <option value="973">Baréin</option>
                                <option value="501">Belice</option>
                                <option value="229">Benín</option>
                                <option value="375">Bielorrusia</option>
                                <option value="591">Bolivia</option>
                                <option value="387">Bosnia y Herzegovina</option>
                                <option value="267">Botsuana</option>
                                <option value="55">Brasil</option>
                                <option value="673">Brunéi</option>
                                <option value="359">Bulgaria</option>
                                <option value="226">Burkina Faso</option>
                                <option value="257">Burundi</option>
                                <option value="975">Bután</option>
                                <option value="32">Bélgica</option>
                                <option value="238">Cabo Verde</option>
                                <option value="855">Camboya</option>
                                <option value="237">Camerún</option>
                                <option value="1">Canadá</option>
                                <option value="974">Catar</option>
                                <option value="235">Chad</option>
                                <option value="56">Chile</option>
                                <option value="86">China</option>
                                <option value="357">Chipre</option>
                                <option value="379">Ciudad del Vaticano</option>
                                <option value="57">Colombia</option>
                                <option value="850">Corea del Norte</option>
                                <option value="82">Corea del Sur</option>
                                <option value="506">Costa Rica</option>
                                <option value="225">Costa de Marfil</option>
                                <option value="385">Croacia</option>
                                <option value="53">Cuba</option>
                                <option value="599">Curazao y Bonaire</option>
                                <option value="45">Dinamarca</option>
                                <option value="593">Ecuador</option>
                                <option value="20">Egipto</option>
                                <option value="503">El Salvador</option>
                                <option value="971">Emiratos Árabes Unidos</option>
                                <option value="291">Eritrea</option>
                                <option value="421">Eslovaquia</option>
                                <option value="386">Eslovenia</option>
                                <option value="1">Estados Unidos</option>
                                <option value="372">Estonia</option>
                                <option value="251">Etiopía</option>
                                <option value="63">Filipinas</option>
                                <option value="358">Finlandia</option>
                                <option value="679">Fiyi</option>
                                <option value="241">Gabón</option>
                                <option value="220">Gambia</option>
                                <option value="995">Georgia</option>
                                <option value="233">Ghana</option>
                                <option value="350">Gibraltar</option>
                                <option value="30">Grecia</option>
                                <option value="502">Guatemala</option>
                                <option value="594">Guayana Francesa</option>
                                <option value="224">Guinea</option>
                                <option value="245">Guinea</option>
                                <option value="240">Guinea Ecuatorial</option>
                                <option value="592">Guyana</option>
                                <option value="509">Haití</option>
                                <option value="504">Honduras</option>
                                <option value="852">Hong Kong</option>
                                <option value="36">Hungría</option>
                                <option value="91">India</option>
                                <option value="62">Indonesia</option>
                                <option value="964">Irak</option>
                                <option value="353">Irlanda</option>
                                <option value="98">Irán</option>
                                <option value="247">Isla Ascensión</option>
                                <option value="246">Isla Diego García</option>
                                <option value="354">Islandia</option>
                                <option value="682">Islas Cook</option>
                                <option value="298">Islas Feroe</option>
                                <option value="500">Islas Malvinas</option>
                                <option value="692">Islas Marshall</option>
                                <option value="677">Islas Salomón</option>
                                <option value="972">Israel</option>
                                <option value="39">Italia</option>
                                <option value="81">Japón</option>
                                <option value="962">Jordania</option>
                                <option value="7">Kazajistán</option>
                                <option value="254">Kenia</option>
                                <option value="996">Kirguistán</option>
                                <option value="686">Kiribati</option>
                                <option value="383">Kosovo (desde enero 2015)</option>
                                <option value="965">Kuwait</option>
                                <option value="856">Laos</option>
                                <option value="266">Lesoto</option>
                                <option value="371">Letonia</option>
                                <option value="231">Liberia</option>
                                <option value="423">Liechtenstein</option>
                                <option value="370">Lituania</option>
                                <option value="352">Luxemburgo</option>
                                <option value="853">Macao</option>
                                <option value="389">Macedonia</option>
                                <option value="261">Madagascar</option>
                                <option value="60">Malasia</option>
                                <option value="265">Malaui</option>
                                <option value="960">Maldivas</option>
                                <option value="356">Malta</option>
                                <option value="223">Malí</option>
                                <option value="212">Marruecos</option>
                                <option value="230">Mauricio</option>
                                <option value="222">Mauritania</option>
                                <option value="262">Mayotte</option>
                                <option value="691">Micronesia</option>
                                <option value="373">Moldavia</option>
                                <option value="976">Mongolia</option>
                                <option value="382">Montenegro</option>
                                <option value="258">Mozambique</option>
                                <option value="95">Myanmar Myanmar</option>
                                <option value="377">Mónaco</option>
                                <option value="264">Namibia</option>
                                <option value="674">Nauru</option>
                                <option value="977">Nepal</option>
                                <option value="505">Nicaragua</option>
                                <option value="234">Nigeria</option>
                                <option value="683">Niue</option>
                                <option value="672">Norfolk</option>
                                <option value="47">Noruega</option>
                                <option value="687">Nueva Caledonia</option>
                                <option value="64">Nueva Zelanda</option>
                                <option value="227">Níger</option>
                                <option value="968">Omán</option>
                                <option value="92">Pakistán</option>
                                <option value="680">Palaos</option>
                                <option value="970">Palestina</option>
                                <option value="507">Panamá</option>
                                <option value="675">Papúa Nueva Guinea</option>
                                <option value="595">Paraguay</option>
                                <option value="31">Países Bajos</option>
                                <option value="51">Perú</option>
                                <option value="689">Polinesia Francesa</option>
                                <option value="48">Polonia</option>
                                <option value="236">República Centroafricana</option>
                                <option value="420">República Checa</option>
                                <option value="243">República Democrática del Congo</option>
                                <option value="242">República del Congo</option>
                                <option value="250">Ruanda</option>
                                <option value="40">Rumania</option>
                                <option value="7">Rusia</option>
                                <option value="685">Samoa</option>
                                <option value="590">San Bartolomé</option>
                                <option value="378">San Marino</option>
                                <option value="290">Santa Elena, Ascensión y Tristán de Acuña</option>
                                <option value="239">Santo Tomé y Príncipe</option>
                                <option value="221">Senegal</option>
                                <option value="381">Serbia</option>
                                <option value="248">Seychelles</option>
                                <option value="232">Sierra Leona</option>
                                <option value="65">Singapur</option>
                                <option value="963">Siria</option>
                                <option value="596">Snake Martinica</option>
                                <option value="252">Somalia</option>
                                <option value="94">Sri Lanka</option>
                                <option value="268">Suazilandia</option>
                                <option value="27">Sudáfrica</option>
                                <option value="249">Sudán</option>
                                <option value="211">Sudán del Sur</option>
                                <option value="46">Suecia</option>
                                <option value="41">Suiza</option>
                                <option value="66">Tailandia</option>
                                <option value="886">Taiwán</option>
                                <option value="255">Tanzania</option>
                                <option value="992">Tayikistán</option>
                                <option value="670">Timor Oriental</option>
                                <option value="228">Togo</option>
                                <option value="690">Tokelau</option>
                                <option value="676">Tonga</option>
                                <option value="993">Turkmenistán</option>
                                <option value="90">Turquía y Norte de Chipre</option>
                                <option value="688">Tuvalu</option>
                                <option value="216">Túnez</option>
                                <option value="380">Ucrania</option>
                                <option value="256">Uganda</option>
                                <option value="598">Uruguay</option>
                                <option value="998">Uzbekistán</option>
                                <option value="678">Vanuatu</option>
                                <option value="58">Venezuela</option>
                                <option value="84">Vietnam</option>
                                <option value="681">Wallis y Futuna</option>
                                <option value="967">Yemen</option>
                                <option value="253">Yibuti</option>
                                <option value="260">Zambia</option>
                                <option value="263">Zimbabue</option>
                                <option value="218">ibia</option>
                                <option value="269">omoras</option>
                                <option value="961">íbano</option>
                            </select>
                            <input #cel class="inputInspectorStyle phone" type="text">
                        </div>
                    </div>

                    <div class="formGroup">
                        <label for="">Url del servidor</label>
                        <input #serverUrl class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">Nombre de usuario de whatsapp business</label>
                        <input #username class="inputInspectorStyle" type="text">
                    </div>
                    <div class="formGroup">
                        <label for="">Contraseña de whatsapp business</label>
                        <input #password class="inputInspectorStyle" type="password">
                    </div>

                    <div class="formGroup">
                        <label for="">Id de whatsapp business</label>
                        <input #whatsappBusinessId class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">Token de whatsapp business</label>
                        <input #whatsappToken class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">Alias</label>
                        <input #alias class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">Namespace</label>
                        <input #whatsappNamespace class="inputInspectorStyle" type="text">
                    </div>

                    <button
                        (click)="saveChannelWhatsapp(owner, lada, cel, serverUrl, whatsappBusinessId, whatsappToken, whatsappNamespace, username, password, alias)"
                        type="button" class="btn-primary btn-save">Aceptar</button>
                </div> -->
                <div *ngIf="!sandbox && !blip" class="sandbox">
                    <button (click)="goToWABA()" type="button" class="btn-primary btn-save">Crear canal de Whatsapp</button>
                </div>
                <div *ngIf="sandbox" class="sandbox">
                    <div class="formGroup">
                        <label>Cuenta</label>
                        <select [(ngModel)]="selectedSandbox" [ngModelOptions]="{standalone: true}">
                            <option value="">Seleccionar</option>
                            <option [ngValue]="s" *ngFor="let s of sandboxList">{{s.channelOwnerName}}</option>
                        </select>
                    </div>
                    <div class="formGroup">
                        <label>
                            Dame tu número de teléfono
                            <i class="material-icons md-20 help"
                                data-tippy-content="Este sandbox solo podrá ser usado por este número de teléfono">
                                help
                            </i>
                        </label>
                        <div class="country-phone-box">
                            <select [(ngModel)]="countrySandbox" [ngModelOptions]="{standalone: true}" class="country">
                                <option value="34">España</option>
                                <option value="351">Portugal</option>
                                <option value="33">Francia</option>
                                <option value="521">México (521)</option>
                                <option value="44">Reino Unido</option>
                                <option value="93">Afganistán</option>
                                <option value="355">Albania</option>
                                <option value="49">Alemania</option>
                                <option value="376">Andorra</option>
                                <option value="244">Angola</option>
                                <option value="966">Arabia Saudita</option>
                                <option value="213">Argelia</option>
                                <option value="54">Argentina</option>
                                <option value="374">Armenia</option>
                                <option value="297">Aruba</option>
                                <option value="61">Australia</option>
                                <option value="43">Austria</option>
                                <option value="994">Azerbaiyán</option>
                                <option value="597">Bandera de Surinam Surinam</option>
                                <option value="880">Bangladés</option>
                                <option value="973">Baréin</option>
                                <option value="501">Belice</option>
                                <option value="229">Benín</option>
                                <option value="375">Bielorrusia</option>
                                <option value="591">Bolivia</option>
                                <option value="387">Bosnia y Herzegovina</option>
                                <option value="267">Botsuana</option>
                                <option value="55">Brasil</option>
                                <option value="673">Brunéi</option>
                                <option value="359">Bulgaria</option>
                                <option value="226">Burkina Faso</option>
                                <option value="257">Burundi</option>
                                <option value="975">Bután</option>
                                <option value="32">Bélgica</option>
                                <option value="238">Cabo Verde</option>
                                <option value="855">Camboya</option>
                                <option value="237">Camerún</option>
                                <option value="1">Canadá</option>
                                <option value="974">Catar</option>
                                <option value="235">Chad</option>
                                <option value="56">Chile</option>
                                <option value="86">China</option>
                                <option value="357">Chipre</option>
                                <option value="379">Ciudad del Vaticano</option>
                                <option value="57">Colombia</option>
                                <option value="850">Corea del Norte</option>
                                <option value="82">Corea del Sur</option>
                                <option value="506">Costa Rica</option>
                                <option value="225">Costa de Marfil</option>
                                <option value="385">Croacia</option>
                                <option value="53">Cuba</option>
                                <option value="599">Curazao y Bonaire</option>
                                <option value="45">Dinamarca</option>
                                <option value="593">Ecuador</option>
                                <option value="20">Egipto</option>
                                <option value="503">El Salvador</option>
                                <option value="971">Emiratos Árabes Unidos</option>
                                <option value="291">Eritrea</option>
                                <option value="421">Eslovaquia</option>
                                <option value="386">Eslovenia</option>
                                <option value="1">Estados Unidos</option>
                                <option value="372">Estonia</option>
                                <option value="251">Etiopía</option>
                                <option value="63">Filipinas</option>
                                <option value="358">Finlandia</option>
                                <option value="679">Fiyi</option>
                                <option value="241">Gabón</option>
                                <option value="220">Gambia</option>
                                <option value="995">Georgia</option>
                                <option value="233">Ghana</option>
                                <option value="350">Gibraltar</option>
                                <option value="30">Grecia</option>
                                <option value="502">Guatemala</option>
                                <option value="594">Guayana Francesa</option>
                                <option value="224">Guinea</option>
                                <option value="245">Guinea</option>
                                <option value="240">Guinea Ecuatorial</option>
                                <option value="592">Guyana</option>
                                <option value="509">Haití</option>
                                <option value="504">Honduras</option>
                                <option value="852">Hong Kong</option>
                                <option value="36">Hungría</option>
                                <option value="91">India</option>
                                <option value="62">Indonesia</option>
                                <option value="964">Irak</option>
                                <option value="353">Irlanda</option>
                                <option value="98">Irán</option>
                                <option value="247">Isla Ascensión</option>
                                <option value="246">Isla Diego García</option>
                                <option value="354">Islandia</option>
                                <option value="682">Islas Cook</option>
                                <option value="298">Islas Feroe</option>
                                <option value="500">Islas Malvinas</option>
                                <option value="692">Islas Marshall</option>
                                <option value="677">Islas Salomón</option>
                                <option value="972">Israel</option>
                                <option value="39">Italia</option>
                                <option value="81">Japón</option>
                                <option value="962">Jordania</option>
                                <option value="7">Kazajistán</option>
                                <option value="254">Kenia</option>
                                <option value="996">Kirguistán</option>
                                <option value="686">Kiribati</option>
                                <option value="383">Kosovo (desde enero 2015)</option>
                                <option value="965">Kuwait</option>
                                <option value="856">Laos</option>
                                <option value="266">Lesoto</option>
                                <option value="371">Letonia</option>
                                <option value="231">Liberia</option>
                                <option value="423">Liechtenstein</option>
                                <option value="370">Lituania</option>
                                <option value="352">Luxemburgo</option>
                                <option value="853">Macao</option>
                                <option value="389">Macedonia</option>
                                <option value="261">Madagascar</option>
                                <option value="60">Malasia</option>
                                <option value="265">Malaui</option>
                                <option value="960">Maldivas</option>
                                <option value="356">Malta</option>
                                <option value="223">Malí</option>
                                <option value="212">Marruecos</option>
                                <option value="230">Mauricio</option>
                                <option value="222">Mauritania</option>
                                <option value="262">Mayotte</option>
                                <option value="691">Micronesia</option>
                                <option value="373">Moldavia</option>
                                <option value="976">Mongolia</option>
                                <option value="382">Montenegro</option>
                                <option value="258">Mozambique</option>
                                <option value="95">Myanmar Myanmar</option>
                                <option value="377">Mónaco</option>
                                <option value="264">Namibia</option>
                                <option value="674">Nauru</option>
                                <option value="977">Nepal</option>
                                <option value="505">Nicaragua</option>
                                <option value="234">Nigeria</option>
                                <option value="683">Niue</option>
                                <option value="672">Norfolk</option>
                                <option value="47">Noruega</option>
                                <option value="687">Nueva Caledonia</option>
                                <option value="64">Nueva Zelanda</option>
                                <option value="227">Níger</option>
                                <option value="968">Omán</option>
                                <option value="92">Pakistán</option>
                                <option value="680">Palaos</option>
                                <option value="970">Palestina</option>
                                <option value="507">Panamá</option>
                                <option value="675">Papúa Nueva Guinea</option>
                                <option value="595">Paraguay</option>
                                <option value="31">Países Bajos</option>
                                <option value="51">Perú</option>
                                <option value="689">Polinesia Francesa</option>
                                <option value="48">Polonia</option>
                                <option value="236">República Centroafricana</option>
                                <option value="420">República Checa</option>
                                <option value="243">República Democrática del Congo</option>
                                <option value="242">República del Congo</option>
                                <option value="250">Ruanda</option>
                                <option value="40">Rumania</option>
                                <option value="7">Rusia</option>
                                <option value="685">Samoa</option>
                                <option value="590">San Bartolomé</option>
                                <option value="378">San Marino</option>
                                <option value="290">Santa Elena, Ascensión y Tristán de Acuña</option>
                                <option value="239">Santo Tomé y Príncipe</option>
                                <option value="221">Senegal</option>
                                <option value="381">Serbia</option>
                                <option value="248">Seychelles</option>
                                <option value="232">Sierra Leona</option>
                                <option value="65">Singapur</option>
                                <option value="963">Siria</option>
                                <option value="596">Snake Martinica</option>
                                <option value="252">Somalia</option>
                                <option value="94">Sri Lanka</option>
                                <option value="268">Suazilandia</option>
                                <option value="27">Sudáfrica</option>
                                <option value="249">Sudán</option>
                                <option value="211">Sudán del Sur</option>
                                <option value="46">Suecia</option>
                                <option value="41">Suiza</option>
                                <option value="66">Tailandia</option>
                                <option value="886">Taiwán</option>
                                <option value="255">Tanzania</option>
                                <option value="992">Tayikistán</option>
                                <option value="670">Timor Oriental</option>
                                <option value="228">Togo</option>
                                <option value="690">Tokelau</option>
                                <option value="676">Tonga</option>
                                <option value="993">Turkmenistán</option>
                                <option value="90">Turquía y Norte de Chipre</option>
                                <option value="688">Tuvalu</option>
                                <option value="216">Túnez</option>
                                <option value="380">Ucrania</option>
                                <option value="256">Uganda</option>
                                <option value="598">Uruguay</option>
                                <option value="998">Uzbekistán</option>
                                <option value="678">Vanuatu</option>
                                <option value="58">Venezuela</option>
                                <option value="84">Vietnam</option>
                                <option value="681">Wallis y Futuna</option>
                                <option value="967">Yemen</option>
                                <option value="253">Yibuti</option>
                                <option value="260">Zambia</option>
                                <option value="263">Zimbabue</option>
                                <option value="218">ibia</option>
                                <option value="269">omoras</option>
                                <option value="961">íbano</option>
                            </select>
                            <input [(ngModel)]="phoneSandbox" class="inputInspectorStyle phone" type="tel"
                                [ngModelOptions]="{standalone: true}" maxlength="12" minlength="5"
                                placeholder="Número de teléfono">
                        </div>
                    </div>

                    <button (click)="saveChannelWhatsappSandbox()" type="button"
                        class="btn-primary btn-save">Aceptar</button>
                </div>
            </form>

            <form *ngIf="channelSelect=='SMOOCH'">
                <div class="formGroup">
                    <label>Sandbox</label>
                    <input [(ngModel)]="sandbox" (click)="toggleForm()" type="checkbox"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <!-- *ngIf="channelSelect=='WEBSITE'" -->
                <div *ngIf="sandbox" class="sandbox">
                    <div class="formGroup">
                        <label>Cuenta</label>
                        <select [(ngModel)]="selectedSandbox" [ngModelOptions]="{standalone: true}">
                            <option value="">Seleccionar</option>
                            <option [ngValue]="s" *ngFor="let s of sandboxList">{{s.channelOwnerName}}</option>
                        </select>
                    </div>
                    <div class="formGroup">
                        <label>
                            Dame tu número de teléfono
                            <i class="material-icons md-20 help"
                                data-tippy-content="Este sandbox solo podrá ser usado por este número de teléfono">
                                help
                            </i>
                        </label>
                        <div class="country-phone-box">
                            <select [(ngModel)]="countrySandbox" [ngModelOptions]="{standalone: true}" class="country">
                                <option value="34">España</option>
                                <option value="351">Portugal</option>
                                <option value="33">Francia</option>
                                <option value="521">México (521)</option>
                                <option value="52">México (52)</option>
                                <option value="44">Reino Unido</option>
                                <option value="93">Afganistán</option>
                                <option value="355">Albania</option>
                                <option value="49">Alemania</option>
                                <option value="376">Andorra</option>
                                <option value="244">Angola</option>
                                <option value="966">Arabia Saudita</option>
                                <option value="213">Argelia</option>
                                <option value="54">Argentina</option>
                                <option value="374">Armenia</option>
                                <option value="297">Aruba</option>
                                <option value="61">Australia</option>
                                <option value="43">Austria</option>
                                <option value="994">Azerbaiyán</option>
                                <option value="597">Bandera de Surinam Surinam</option>
                                <option value="880">Bangladés</option>
                                <option value="973">Baréin</option>
                                <option value="501">Belice</option>
                                <option value="229">Benín</option>
                                <option value="375">Bielorrusia</option>
                                <option value="591">Bolivia</option>
                                <option value="387">Bosnia y Herzegovina</option>
                                <option value="267">Botsuana</option>
                                <option value="55">Brasil</option>
                                <option value="673">Brunéi</option>
                                <option value="359">Bulgaria</option>
                                <option value="226">Burkina Faso</option>
                                <option value="257">Burundi</option>
                                <option value="975">Bután</option>
                                <option value="32">Bélgica</option>
                                <option value="238">Cabo Verde</option>
                                <option value="855">Camboya</option>
                                <option value="237">Camerún</option>
                                <option value="1">Canadá</option>
                                <option value="974">Catar</option>
                                <option value="235">Chad</option>
                                <option value="56">Chile</option>
                                <option value="86">China</option>
                                <option value="357">Chipre</option>
                                <option value="379">Ciudad del Vaticano</option>
                                <option value="57">Colombia</option>
                                <option value="850">Corea del Norte</option>
                                <option value="82">Corea del Sur</option>
                                <option value="506">Costa Rica</option>
                                <option value="225">Costa de Marfil</option>
                                <option value="385">Croacia</option>
                                <option value="53">Cuba</option>
                                <option value="599">Curazao y Bonaire</option>
                                <option value="45">Dinamarca</option>
                                <option value="593">Ecuador</option>
                                <option value="20">Egipto</option>
                                <option value="503">El Salvador</option>
                                <option value="971">Emiratos Árabes Unidos</option>
                                <option value="291">Eritrea</option>
                                <option value="421">Eslovaquia</option>
                                <option value="386">Eslovenia</option>
                                <option value="1">Estados Unidos</option>
                                <option value="372">Estonia</option>
                                <option value="251">Etiopía</option>
                                <option value="63">Filipinas</option>
                                <option value="358">Finlandia</option>
                                <option value="679">Fiyi</option>
                                <option value="241">Gabón</option>
                                <option value="220">Gambia</option>
                                <option value="995">Georgia</option>
                                <option value="233">Ghana</option>
                                <option value="350">Gibraltar</option>
                                <option value="30">Grecia</option>
                                <option value="502">Guatemala</option>
                                <option value="594">Guayana Francesa</option>
                                <option value="224">Guinea</option>
                                <option value="245">Guinea</option>
                                <option value="240">Guinea Ecuatorial</option>
                                <option value="592">Guyana</option>
                                <option value="509">Haití</option>
                                <option value="504">Honduras</option>
                                <option value="852">Hong Kong</option>
                                <option value="36">Hungría</option>
                                <option value="91">India</option>
                                <option value="62">Indonesia</option>
                                <option value="964">Irak</option>
                                <option value="353">Irlanda</option>
                                <option value="98">Irán</option>
                                <option value="247">Isla Ascensión</option>
                                <option value="246">Isla Diego García</option>
                                <option value="354">Islandia</option>
                                <option value="682">Islas Cook</option>
                                <option value="298">Islas Feroe</option>
                                <option value="500">Islas Malvinas</option>
                                <option value="692">Islas Marshall</option>
                                <option value="677">Islas Salomón</option>
                                <option value="972">Israel</option>
                                <option value="39">Italia</option>
                                <option value="81">Japón</option>
                                <option value="962">Jordania</option>
                                <option value="7">Kazajistán</option>
                                <option value="254">Kenia</option>
                                <option value="996">Kirguistán</option>
                                <option value="686">Kiribati</option>
                                <option value="383">Kosovo (desde enero 2015)</option>
                                <option value="965">Kuwait</option>
                                <option value="856">Laos</option>
                                <option value="266">Lesoto</option>
                                <option value="371">Letonia</option>
                                <option value="231">Liberia</option>
                                <option value="423">Liechtenstein</option>
                                <option value="370">Lituania</option>
                                <option value="352">Luxemburgo</option>
                                <option value="853">Macao</option>
                                <option value="389">Macedonia</option>
                                <option value="261">Madagascar</option>
                                <option value="60">Malasia</option>
                                <option value="265">Malaui</option>
                                <option value="960">Maldivas</option>
                                <option value="356">Malta</option>
                                <option value="223">Malí</option>
                                <option value="212">Marruecos</option>
                                <option value="230">Mauricio</option>
                                <option value="222">Mauritania</option>
                                <option value="262">Mayotte</option>
                                <option value="691">Micronesia</option>
                                <option value="373">Moldavia</option>
                                <option value="976">Mongolia</option>
                                <option value="382">Montenegro</option>
                                <option value="258">Mozambique</option>
                                <option value="95">Myanmar Myanmar</option>
                                <option value="377">Mónaco</option>
                                <option value="264">Namibia</option>
                                <option value="674">Nauru</option>
                                <option value="977">Nepal</option>
                                <option value="505">Nicaragua</option>
                                <option value="234">Nigeria</option>
                                <option value="683">Niue</option>
                                <option value="672">Norfolk</option>
                                <option value="47">Noruega</option>
                                <option value="687">Nueva Caledonia</option>
                                <option value="64">Nueva Zelanda</option>
                                <option value="227">Níger</option>
                                <option value="968">Omán</option>
                                <option value="92">Pakistán</option>
                                <option value="680">Palaos</option>
                                <option value="970">Palestina</option>
                                <option value="507">Panamá</option>
                                <option value="675">Papúa Nueva Guinea</option>
                                <option value="595">Paraguay</option>
                                <option value="31">Países Bajos</option>
                                <option value="51">Perú</option>
                                <option value="689">Polinesia Francesa</option>
                                <option value="48">Polonia</option>
                                <option value="236">República Centroafricana</option>
                                <option value="420">República Checa</option>
                                <option value="243">República Democrática del Congo</option>
                                <option value="242">República del Congo</option>
                                <option value="250">Ruanda</option>
                                <option value="40">Rumania</option>
                                <option value="7">Rusia</option>
                                <option value="685">Samoa</option>
                                <option value="590">San Bartolomé</option>
                                <option value="378">San Marino</option>
                                <option value="290">Santa Elena, Ascensión y Tristán de Acuña</option>
                                <option value="239">Santo Tomé y Príncipe</option>
                                <option value="221">Senegal</option>
                                <option value="381">Serbia</option>
                                <option value="248">Seychelles</option>
                                <option value="232">Sierra Leona</option>
                                <option value="65">Singapur</option>
                                <option value="963">Siria</option>
                                <option value="596">Snake Martinica</option>
                                <option value="252">Somalia</option>
                                <option value="94">Sri Lanka</option>
                                <option value="268">Suazilandia</option>
                                <option value="27">Sudáfrica</option>
                                <option value="249">Sudán</option>
                                <option value="211">Sudán del Sur</option>
                                <option value="46">Suecia</option>
                                <option value="41">Suiza</option>
                                <option value="66">Tailandia</option>
                                <option value="886">Taiwán</option>
                                <option value="255">Tanzania</option>
                                <option value="992">Tayikistán</option>
                                <option value="670">Timor Oriental</option>
                                <option value="228">Togo</option>
                                <option value="690">Tokelau</option>
                                <option value="676">Tonga</option>
                                <option value="993">Turkmenistán</option>
                                <option value="90">Turquía y Norte de Chipre</option>
                                <option value="688">Tuvalu</option>
                                <option value="216">Túnez</option>
                                <option value="380">Ucrania</option>
                                <option value="256">Uganda</option>
                                <option value="598">Uruguay</option>
                                <option value="998">Uzbekistán</option>
                                <option value="678">Vanuatu</option>
                                <option value="58">Venezuela</option>
                                <option value="84">Vietnam</option>
                                <option value="681">Wallis y Futuna</option>
                                <option value="967">Yemen</option>
                                <option value="253">Yibuti</option>
                                <option value="260">Zambia</option>
                                <option value="263">Zimbabue</option>
                                <option value="218">ibia</option>
                                <option value="269">omoras</option>
                                <option value="961">íbano</option>
                            </select>
                            <input [(ngModel)]="phoneSandbox" class="inputInspectorStyle phone" type="tel"
                                [ngModelOptions]="{standalone: true}" maxlength="12" minlength="5"
                                placeholder="Número de teléfono">
                        </div>
                    </div>

                    <button (click)="saveChannelSmoochSandbox()" type="button"
                        class="btn-primary btn-save">Aceptar</button>
                </div>
                <div *ngIf="!sandbox" class="noSandbox">
                    <div class="formGroup">
                        <label for="">Propietario o nombre de canal</label>
                        <input #owner class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">App ID</label>
                        <input #AppID class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">ID de integración</label>
                        <input #idIntegrate class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">Tipo de integración</label>
                        <select #typeIntegrate name="">
                            <option value="">Seleccionar</option>
                            <option [value]="type" *ngFor="let type of subtypesChannels">{{type}}</option>
                        </select>
                    </div>

                    <div class="formGroup">
                        <label for="">Scope</label>
                        <select #scope>
                            <option value="">Seleccionar</option>
                            <option value="app">Aplicación</option>
                            <option value="account">Cuenta</option>
                        </select>
                    </div>

                    <div class="formGroup">
                        <label for="">Rol de usuario</label>
                        <select #role name="">
                            <option value="">Seleccionar</option>
                            <option value="appMaker">Dueño de la app</option>
                            <option value="appUser">usuario de la app</option>
                        </select>
                    </div>

                    <div class="formGroup">
                        <label for="">Key ID</label>
                        <input #keyID class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">Key Secret</label>
                        <input #keySecret class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">Namespace</label>
                        <input #namespace class="inputInspectorStyle" type="text">
                    </div>

                    <button
                        (click)="saveChannelSmooch(owner,AppID,idIntegrate,typeIntegrate,scope,role,keyID,keySecret,namespace)"
                        type="button" class="btn-primary btn-save">Aceptar</button>
                </div>
            </form>
            <form *ngIf="channelSelect=='SMOOCH_SMS'">

                <div *ngIf="!sandbox" class="noSandbox">
                    <div class="formGroup">
                        <label for="">Propietario o nombre de canal</label>
                        <input #owner class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">App ID</label>
                        <input #AppID class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">ID de integración</label>
                        <input #idIntegrate class="inputInspectorStyle" type="text">
                    </div>



                    <div class="formGroup">
                        <label for="">Scope</label>
                        <select #scope>
                            <option value="">Seleccionar</option>
                            <option value="app">Aplicación</option>
                            <option value="account">Cuenta</option>
                        </select>
                    </div>

                    <div class="formGroup">
                        <label for="">Rol de usuario</label>
                        <select #role name="">
                            <option value="">Seleccionar</option>
                            <option value="appMaker">Dueño de la app</option>
                            <option value="appUser">usuario de la app</option>
                        </select>
                    </div>

                    <div class="formGroup">
                        <label for="">Key ID</label>
                        <input #keyID class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">Key Secret</label>
                        <input #keySecret class="inputInspectorStyle" type="text">
                    </div>


                    <button
                        (click)="saveChannelSmoochSms(owner,AppID,idIntegrate,scope,role,keyID,keySecret)"
                        type="button" class="btn-primary btn-save">Aceptar</button>
                </div>
            </form>

            <form *ngIf="channelSelect=='SMOOCH_APP'">

                <div *ngIf="!sandbox" class="noSandbox">
                    <div class="formGroup">
                        <label for="">Tipo de canal</label>
                        <select #typeChannel class="inputInspectorStyle" type="text">
                            <option value="ANDROID">Android</option>
                            <option value="IOS">IOS</option>
                        </select>
                    </div>
                    <div class="formGroup">
                        <label for="">Propietario o nombre de canal</label>
                        <input #owner class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">App ID</label>
                        <input #AppID class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">ID de integración</label>
                        <input #idIntegrate class="inputInspectorStyle" type="text">
                    </div>



                    <div class="formGroup">
                        <label for="">Scope</label>
                        <select #scope>
                            <option value="app">Aplicación</option>
                            <option value="account">Cuenta</option>
                        </select>
                    </div>

                    <div class="formGroup">
                        <label for="">Rol de usuario</label>
                        <select #role name="">
                            <option value="appMaker">Dueño de la app</option>
                            <option value="appUser">Usuario de la app</option>
                        </select>
                    </div>

                    <div class="formGroup">
                        <label for="">Key ID</label>
                        <input #keyID class="inputInspectorStyle" type="text">
                    </div>

                    <div class="formGroup">
                        <label for="">Key Secret</label>
                        <input #keySecret class="inputInspectorStyle" type="text">
                    </div>


                    <button
                        (click)="saveChannelSmoochApp(typeChannel,owner,AppID,idIntegrate,scope,role,keyID,keySecret)"
                        type="button" class="btn-primary btn-save">Aceptar</button>
                </div>
            </form>


        </div>

    </jw-modal>
    <!-- end modal create channel-->

    <!-- modal instagram -->

    <jw-modal id="popup-instagram-sandbox">
        <a class="close_modal" (click)="closeModal('popup-instagram-sandbox');">×</a>

        <div>
            <h2>Activa tu cuenta sandbox</h2>
            <center>
                <p>
                    Para activar tu sandbox manda a la cuenta
                    <a href="https://www.instagram.com/ing.guschat/" target="_blank">@ing.guschat</a>
                    el <a href="https://www.instagram.com/direct/new/" target="_blank">mensaje</a>:
                </p>
                <p>#sandboxActivation {{sandboxToken}}</p>
            </center>

        </div>

        <div class="modal-actions">
            <button (click)="copy('#sandboxActivation ' + sandboxToken)" class="btn-secondary copy">Copiar
                mensaje</button>
            <button (click)="functionCancel('popup-instagram-sandbox')" class="btn-primary btn-alert">Listo</button>
        </div>

    </jw-modal>

    <!-- end modal instagram-->

    <!-- end -->
</div>
