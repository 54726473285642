export class Diagram {
    id: string;
    description: string;
    json?: string;
    label: string;
    metaClass: {};
    name: string;
    platformCatalogId: string;
    slug: string;
    lastModifiedAt?: string;
    isPrimary: boolean;
    secondaryFlowPrebuild?: Diagram[];
}
