<div class="section-steps" *ngIf="showSteps && showStepsRoute">
    <div class="box-steps">
        <button class="btn-expand less" *ngIf="expand" (click)="expandMore()">
            <i class="material-icons">expand_less</i>
        </button>
        <button class="btn-expand more" *ngIf="!expand" (click)="expandLess()">
            <i class="material-icons">expand_more</i>
        </button>

        <div class="intro" *ngIf="expand">
            <span class="greetings">{{translate['hola']}} {{userName}},</span>
            <p class="desc">{{translate['welcome']}}</p>
        </div>
        <ul class="steps">
            <li class="step">
                <span [ngClass]="{'circle': true, 'process': newBotProcess, 'active': newBot}"></span>
                <span class="step-text">{{translate['creaBot']}}</span>
            </li>
            <li class="step">
                <span [ngClass]="{'circle': true, 'active': addPlatform}"></span>
                <span class="step-text">{{translate['agregaPlataformas']}}</span>
            </li>
            <li class="step">
                <span [ngClass]="{'circle': true, 'active': addChannel}"></span>
                <span class="step-text">{{translate['AgregaCanales']}}</span>
            </li>
            <li class="step">
                <span [ngClass]="{'circle': true, 'active': configChannel}"></span>
                <span class="step-text">{{translate['configCanal']}}</span>
            </li>
        </ul>
    </div>
</div>
