import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EMPTY } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class WabaService {

    private fbUrl: URL;
    private businessId: string;
    private whatsappBusinessAccountId: string;
    private accessToken: string;

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {

    }

    /**
     * Set the fbUrl attribute with facebook graph url
     * @param path Facebook api path
     * @param params Url params
     */
    setFbUrl(path, params={}) {
        this.fbUrl = new URL(`${environment.facebook.version}${path}`, environment.facebook.graph);
        for (const param of Object.keys(params)) {
            this.fbUrl.searchParams.set(param, params[param]);
        }
    }

    setAccessToken(accessToken) {
        this.accessToken = accessToken;
    }

    setBussinessId(businessId: string) {
        this.businessId = businessId;
    }

    setWhatsappBusinessAccountId(whatsappBusinessAccountId: string) {
        this.whatsappBusinessAccountId = whatsappBusinessAccountId;
    }

    getBussinessId() {
        return this.businessId;
    }

    getWhatsappBusinessAccountId() {
        return this.whatsappBusinessAccountId;
    }

    /**
     * Fetch the shared WhatsApp business account ID using the returned accessToken with the
     * Debug-Token endpoint
     * @returns
     */
    getSharedWABAIds(inputToken: string): Observable<any> {
        const path = '/debug_token';
        const params = {
            input_token: inputToken,
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    /**
     * Retrieves a list of all the WhatsApp business accounts assigned to/shared with your
     * Business Manager account once the embedded signup flow is completed
     * @returns
     */
    getListOfSharedWABAs(fields: string): Observable<any> {
        const path = `/${this.businessId}/client_whatsapp_business_accounts`;
        const params = {
            fields,
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    /**
     * Get a list of the WhatsApp business accounts that your business owns
     * @returns
     */
    getListOfOwnedWABAs(fields: string): Observable<any> {
        const path = `/${this.businessId}/owned_whatsapp_business_accounts`;
        const params = {
            fields,
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    /**
     * Filter client and owned WhatsApp business accounts based on their creation time
     * @param filtering Array of type { operator: string, value: timestamp }
     *      operator - Supported values: LESS_THAN, GREATER_THAN
     *      value - A type: UNIX timestamp
     * @returns
     */
    filterWABAsByCreationTime(filtering: any): Observable<any> {
        const path = `/${this.businessId}/owned_whatsapp_business_accounts`;
        filtering.forEach((element, index) => {
            filtering[index].field = 'creation_time'
        });
        const params = {
            filtering,
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    /**
     * Sort shared and owned WhatsApp business accounts based on their creation time
     * @param sort Only allow ASC and DESC
     * @returns
     */
    sortWABAsByCreationTime(sort: string): Observable<any> {
        const path = `/${this.businessId}/owned_whatsapp_business_accounts`;
        const allowed = ['creation_time_ascending', 'creation_time_descending'];
        const params = {
            sort: allowed.includes(sort) ? sort : allowed[0],
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    /**
     *
     * @param fields Allow account_review_status, primary_funding_id, owner_business_info
     * @returns
     */
    getWABAReviewStatus(fields: string[]): Observable<any> {
        const path = `/${this.whatsappBusinessAccountId}`;
        const params = {
            fields: fields.join(','),
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    /**************************************************
    Manage Phone Numbers and Certificates
    **************************************************/


    /**
     * Allows you to see the status of a phone number's display name and to retrieve the
     * certificate after a name change
     * @param fields Array only allow the fields display_phone_number, certificate,
     * name_status, new_certificate, new_name_status
     * @returns
     */
    getPhonesStatusAndCertificate(fields: string[]): Observable<any> {
        const path = `/${this.whatsappBusinessAccountId}/phone_numbers`;
        let allowed = true;
        // const allowedFields = [
        //     'display_phone_number',
        //     'certificate',
        //     'name_status',
        //     'new_certificate',
        //     'new_name_status'
        // ];
        // fields.forEach(field => {
        //     if (!allowedFields.includes(field)) {
        //         allowed = false;
        //     }
        // });

        if (allowed && fields.length > 0){
            const params = {
                fields: fields.join(','),
                access_token: this.accessToken
            };
            this.setFbUrl(path, params);
            return this.http.get<any>(this.fbUrl.toString());
        } else {
            return EMPTY;
        }
    }

    /**
     * See if a phone number has been verified via OTP (one-time password)
     * @returns
     */
    getPhonesOTPStatus(): Observable<any> {
        const path = `/${this.whatsappBusinessAccountId}/phone_numbers`;
        const params = {
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    /**
     * Filter them based on their account_mode
     * @param accountMode Only allow SANDBOX or LIVE
     * @returns
     */
    filterPhoneNumbersByAccountMode(accessToken: string, accountMode: string): Observable<any> {
        const path = `/${this.whatsappBusinessAccountId}/phone_numbers`;
        const allowed = ['SANDBOX', 'LIVE'];
        if (allowed.includes(accountMode)) {
            const filtering = [{
                field: 'account_mode',
                operator: 'EQUAL',
                value: accountMode
            }];
            const params = {
                filtering: JSON.stringify(filtering),
                access_token: this.accessToken
            };
            this.setFbUrl(path, params);
            return this.http.get<any>(this.fbUrl.toString());
        } else {
            return EMPTY;
        }
    }

    /**************************************************
    Manage System Users
    **************************************************/

    /**
     * Retrieve system user IDs
     * @returns
     */
    getSystemUserIds(): Observable<any> {
        const path = `/${this.businessId}/system_users`;
        const params = {
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    /**
     * Add system users to a WhatsApp business account
     * @param assignedUserId Use the system user ID returned from your /{business-id}/system_users call
     * @param permission Only allow MANAGE or DEVELOP
     * @returns
     */
    addSystemUsersToWhatsappBusinessAccount(assignedUserId: string, permission: string): Observable<any> {
        const path = `/${this.whatsappBusinessAccountId}/assigned_users`;
        const allowed = ['MANAGE', 'DEVELOP'];
        if (allowed.includes(permission)) {
            const params = {
                user: assignedUserId,
                tasks: [permission],
                access_token: this.accessToken
            };
            this.setFbUrl(path, params);
            return this.http.post<any>(this.fbUrl.toString(), {});
        } else {
            return EMPTY;
        }
    }

    /**
     * Fetch the assigned users of the WhatsApp business account to verify that the user was added
     * @returns
     */
    getAssignedUsers(): Observable<any> {
        const path = `/${this.whatsappBusinessAccountId}/assigned_users`;
        const params = {
            business: this.businessId,
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    addSystemUser(name: string, role: string): Observable<any> {
        const path = `/${this.businessId}/assigned_users`;
        const allowed = ['ADMIN', 'EMPLOYEE'];
        if (allowed.includes(role)) {
            const params = {
                name,
                role,
                access_token: this.accessToken
            };
            this.setFbUrl(path, params);
            return this.http.post<any>(this.fbUrl.toString(), {});
        } else {
            return EMPTY;
        }
    }

    /**************************************************
    Billing & Payment
    **************************************************/

    /**
     * Retrieve your business's credit Line ID
     * @returns
     */
    getBusinessCreditLineId(): Observable<any> {
        const path = `/${this.businessId}/extendedcredits`;
        const params = {
            fields: 'id,legal_entity_name',
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    /**
     * Share the credit line with your client’s business and attach it to the client’s WhatsApp
     * business account
     * @param extendedCreditId
     * @param wabaId
     * @param wabaCurrency
     * @returns
     */
    attachCreditLineToWhatsappBusinessAccount(extendedCreditId: string,
                                              wabaId: string, wabaCurrency: string): Observable<any> {
        const path = `/${extendedCreditId}/whatsapp_credit_sharing_and_attach`;
        const params = {
            waba_id: wabaId, // assigned-whatsapp-business-account-id
            waba_currency: wabaCurrency, //assigned-whatsapp-business-account-currency-code
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    /**
     *
     * @param allocationConfigId
     * @param fields receiving_business, request_status, receiving_credential{id}
     * @returns
     */
    getSharingByAllocationConfigId(allocationConfigId: string, fields: []): Observable<any> {
        const path = `/${allocationConfigId}`;
        const params = {
            fields: fields.join(','),
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    // verifyCreditLineWasSharedStep2(accessToken: string): Observable<any>  {
    //     const path = `/${this.whatsappBusinessAccountId}`;
    //     const params = {
    //         fields: 'primary_funding_id',
    //         access_token: accessToken
    //     };
    //     this.setFbUrl(path, params);
    //     return this.http.get<any>(this.fbUrl.toString());
    // }

    getCreditSharingRecord(extendedCreditId: string, clientsBusinessId: string): Observable<any> {
        const path = `/${extendedCreditId}/owning_credit_allocation_configs`;
        const params = {
            receiving_business_id: clientsBusinessId,
            fields: 'id,receiving_business',
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    revokeCreditSharing(allocationConfigId: string): Observable<any> {
        const path = `/${allocationConfigId}`;
        const params = {
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.delete<any>(this.fbUrl.toString());
    }

    // verifyCreditSharingWasRevoked(accessToken: string, allocationConfigId: string): Observable<any> {
    //     const path = `/${allocationConfigId}`;
    //     const params = {
    //         fields: 'receiving_business,request_status',
    //         access_token: accessToken
    //     };
    //     this.setFbUrl(path, params);
    //     return this.http.get<any>(this.fbUrl.toString());
    // }

    /**************************************************
    Webhooks
    **************************************************/

    subscribeToWhatsAppBusinessAccount(): Observable<any> {
        const path = `/${this.whatsappBusinessAccountId}/subscribed_apps`;
        const params = {
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.post<any>(this.fbUrl.toString(), {});
    }

    getAllSubscriptionsForWABA(): Observable<any> {
        const path = `/${this.whatsappBusinessAccountId}/subscribed_apps`;
        const params = {
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    deleteWABASubscription(): Observable<any> {
        const path = `/${this.whatsappBusinessAccountId}/subscribed_apps`;
        const params = {
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.delete<any>(this.fbUrl.toString());
    }

    /**************************************************
    Business
    **************************************************/

    getBusinessAccounts(fields: string = '') {
        const path = `/me/businesses`;
        const params = {
            fields,
            access_token: this.accessToken
        };
        this.setFbUrl(path, params);
        return this.http.get<any>(this.fbUrl.toString());
    }

    // getBusiness(fields: string = '') {
    //     const path = `/${this.businessId}`;
    //     const params = {
    //         fields,
    //         access_token: this.accessToken
    //     };
    //     this.setFbUrl(path, params);
    //     return this.http.get<any>(this.fbUrl.toString());
    // }

    registerPhone(payload, slug) {
        const url = `/coreapi/v2/whatsapp/cloud/channel/registration`;
        // return this.http.post(url, payload);
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.post(url, payload, { headers });
    }

    getCoreDataPhone(phones, slug) {
        const url = `/coreapi/v2/tenantChannels/ownedBy?channelOwnerIds=${phones}`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    setCreditLineExtension(slug, channelOwnerId, fb_app_name) {
        const url = `/coreapi/v2/whatsapp/${channelOwnerId}/cloud/credit_line_extension`;
        // return this.http.post(url, payload);
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.post(url, {fb_app_name}, { headers });
    }
}
