import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketService } from '../../services/ticket.service';
import { AlertService } from '../../services/alert.service';
import { DataLayerService } from 'src/services/data-layer.service';
declare var translate: any;
@Component({
    selector: 'app-tickets',
    templateUrl: './tickets.component.html',
    styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

    customerSlug: string;
    ticketId: string;
    entities: string;
    translate: any;
    flagPath: string;

    constructor(
        private ticketService: TicketService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private dataLayerService: DataLayerService
    ) {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        const email = localStorage.getItem('email');
        if (!email.includes('holagus.com')) {
            this.router.navigate(['/customers']);
        }
        this.flagPath = 'Tickets -> ';
    }

    ngOnInit() {
        this.getUrlParam('customer_slug', (customerSlug) => {
            this.customerSlug = customerSlug;
        });
        this.dataLayerService.setFlag(this.flagPath, 'start');
    }

    getUrlParam(param: string, success) {
        const paramInterval = setInterval(() => {
            if (this.route !== null) {
                if (this.route.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.route.paramMap.subscribe(params => {
                        const p = params.get(param);
                        success(p);
                    });
                }
            }
        }, 500);
    }

    getEntitiesByTicket(ticketId) {
        this.ticketService.getEntitiesByTicket(ticketId, this.customerSlug).subscribe(
            (res: any) => {
                // console.log(res);
                this.entities = res.intention.entities;
                this.dataLayerService.setFlag(this.flagPath, 'get entities');
            },
            err => {
                // console.error(err);
                this.alertService.error('No existe el ticket id');
            }
        );
    }

    getJsonString(json) {
        return JSON.stringify(json, undefined, 4);
    }

}
