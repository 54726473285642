<div class="platforms">

    <div class="wrapper-title">
        <h1 class="title">
            {{currentCustomerName}}
        </h1>
    </div>
    <div class="wrapper-title sub">
        <h2 class="title">
            <i class="material-icons">layers</i>
            {{translate.sections.platforms.platforms}}
        </h2>
        <ul class="actions">
            <!-- <li>
                <button class="btn-secondary-alternate">
                    <span>Nueva campaña</span>
                    <i class="material-icons md-24">campaign</i>
                </button>
            </li> -->
            <li>
                <button class="btn-primary" (click)="openModal('popup-add-platform')">
                    <span>{{translate.sections.platforms.addPlatform}}</span>
                    <i class="material-icons md-24">add_circle</i>
                </button>
            </li>
        </ul>
    </div>

    <div *ngIf="!existsPlatforms" class="emptyPlatforms">
        <!-- <img src="/assets/img/empty_state_campaigns.png" alt="Platforms"> -->
        <h1>{{translate.sections.platforms.noPlatform}}</h1>
        <h4>{{translate.sections.platforms.clickAdd}}</h4>
    </div>

    <div *ngIf="existsPlatforms" class="platforms-list">
        <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="platforms">
            <div *nguCarouselDef="let platform;" class="item">
                <div class="tile">
                    <div>
                        <img [src]="assets + 'img/platforms/' + platform.channel.name + '.svg'" [alt]="platform.channel.label">
                        <div class="subtile">{{platform.channel.label}}</div>
                    </div>
                    <div class="actions">
                        <button class="btn-circle to-channels" (click)="redirectChannels(platform.channel.name)">
                            <i class="material-icons md-24">call_split</i>
                        </button>
                        <button class="btn-circle to-diagram" (click)="redirectDiagrammer(platform.channel.name)">
                            <i class="material-icons md-24">mediation</i>
                        </button>
                    </div>
                </div>
            </div>
            <button NguCarouselNext class="circle-left" *ngIf="platforms.length > 4">
                <i class="material-icons">chevron_right</i>
            </button>
            <button NguCarouselPrev class="circle-right" *ngIf="platforms.length > 4">
                <i class="material-icons">chevron_left</i>
            </button>
            <ul class="myPoint" NguCarouselPoint>
                <li *ngFor="let j of myCarousel.pointNumbers; let i = index" [class.active]="i==myCarousel.activePoint" (click)="myCarousel.moveTo(i)"></li>
            </ul>
        </ngu-carousel>
    </div>

    <app-campaings [itemI]="currentItem" *ngIf="existsWhats"></app-campaings>

    <!-- <div class="list">
        <div *ngFor="let t of tempArrAux(platforms.length); let i = index" class="card-row">
            <div *ngFor="let platform of platforms.slice(3*i,3*i + 3);" class="card-container">
                <div class="card-container-inner">
                    <a class="box-link-card" (click)="redirectChannels(platform.channel.name)">
                        <div class="image-card" style="background-size: 100px; background-image: url({{assets}}img/platforms/{{platform.channel.name}}.svg)"></div>
                        <div class="info-card">
                            <span class="title-card">{{platform.channel.label}}</span>
                        </div>
                    </a>
                    <button class="actions" (click)="toggleActionsButtons($event)"></button>
                    <div class="actions_buttons">
                        <ul>
                            <li *ngIf="authorizeRol==true" class="edit">
                                <a>Editar</a>
                            </li>
                            <li class="outbound">
                                <a>Outbound</a>
                            </li>
                            <li class="diagram">
                                <a (click)="redirectDiagrammer(platform.channel.name)">Ir a diagrama</a>
                            </li>


                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div> -->

</div>

<!-- Start modal -->
<jw-modal id="popup-add-platform" [bodyStyles]="platformsBodyStyles">
    <a class="close_modal" (click)="closeModal('popup-add-platform');">×</a>

    <h2 class="modal-title">Agregar plataforma</h2>

    <div class="platforms-list-box">
        <div class="platform-item" *ngFor="let platform of platformsCatalog" (click)="selectPlatform(platform)" [ngClass]="{'active': platform.name === selectedPlatform?.name}">
            <img class="platform-icon" [src]="assets + 'img/platforms/' + platform.name + '.svg'" [alt]="platform.label">
            <span class="platform-label">{{platform.label}}</span>
        </div>
        <!--
        <form [formGroup]="platformsForm" novalidate>
            <div class="input">
                <ng-select
                [searchable]="false"
                [clearable]="false"
                [loading]="loadingPlatforms"
                [hideSelected]="true"
                loadingText="Cargando..."
                notFoundText="No hay elementos"
                formControlName="platform"
                labelForId="platform"
                class="select-platforms">
                    <ng-option [value]="platform" *ngFor="let platform of platformsCatalog">
                        <img [src]="assets + 'img/platforms/' + platform.name + '.svg'" width="30px" height="30px">
                        <span [ngStyle]="{'color': '#333'}">{{platform.label}}</span>

                    </ng-option>
                </ng-select>
            </div>
        </form>
        -->
    </div>
    <div class="modal-actions">
        <button class="btn-secondary" (click)="closeModal('popup-add-platform')">Cancelar</button>
        <button class="btn-primary" (click)="addplatform()">Agregar</button>
    </div>
</jw-modal>
<!-- End modal -->
