import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FilterCustomerService {

    constructor(private http: HttpClient) { }

    getFilters(slug, tenantId) {
        const url = `/coreapi/v2/filters`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId
        });
        return this.http.get(url, { headers });
    }

    getFiltersPaged(page, pageSize, slug, tenantId) {
        const url = `/coreapi/v2/filters/paged?page=${page - 1}&size=${pageSize}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId
        });
        return this.http.get(url, { headers });
    }

    createFilter(filter, slug, tenantId) {
        const url = `/coreapi/v2/filters`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId
        });
        return this.http.post( url, filter, { headers });
    }

    updateFilter(filter, slug, tenantId) {
        const url = `/coreapi/v2/filters/${filter.id}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId
        });
        return this.http.patch( url, filter, { headers });
    }

    deleteFilter(filterId, slug, tenantId) {
        const url = `/coreapi/v2/filters/${filterId}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId
        });
        return this.http.delete(url, { headers });
    }

    getFiltersCustomer(slug) {
        const url = `/coreapi/v2/tenantFilters`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    getFiltersCustomerPaged(page, pageSize, slug) {
        const url = `/coreapi/v2/tenantFilters/paged?page=${page - 1}&size=${pageSize}`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    createFilterCustomer(filter, slug) {
        const url = `/coreapi/v2/filters/${filter.filter.id}/tenantFilters`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.post( url, filter, { headers });
    }

    updateFilterCustomer(customerFilter, slug) {
        const url = `/coreapi/v2/tenantFilters/${customerFilter.id}`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.patch( url, customerFilter, { headers });
    }

    deleteFilterCustomer(customerFilterId, slug) {
        const url = `/coreapi/v2/tenantFilters/${customerFilterId}`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.delete(url, { headers });
    }
}
