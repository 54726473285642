<div [ngClass]="{'breadcrumbs-box': true, 'inner-component': existsDiagram}">
    <ul class="breadcrumbs">
        <li class="breadcrumb">
            <button [routerLink]="['/customers']" class="btn-breadcrumb">
                <i class="material-icons">home</i>
            </button>
        </li>
        <li *ngFor="let breadcrumb of breadcrumbs" [ngClass]="{'breadcrumb': true, 'current': isCurrent(breadcrumb)}">
            <span class="separator-breadcrumb">
                <i class="material-icons">navigate_next</i>
            </span>
            <button [routerLink]="breadcrumb.link" class="btn-breadcrumb" [attr.disabled]="!breadcrumb.link ? '' : null">
                <span>{{breadcrumb.label}}</span>
            </button>
        </li>
    </ul>
</div>
