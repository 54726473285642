<section class="sectionBilling">
    <div class="divBilling">
        <div class="wrapper-title">
            <h1 class="title">{{ translate.sections.billing.billing }}</h1>
            <select class="months" [(ngModel)]="selectedMonth" (change)="changeRangeDate()">
                <option *ngFor="let month of months" [value]="month.index">
                    {{ month.name }}
                </option>
            </select>
        </div>

        <div class="boxBilling whatsapp">
            <div class="boxCost" *ngIf="existsChannel('WHATSAPP')">
                <div class="costInfo">
                    <span class="totalCost">{{ totalWabaCost }} {{ wabaInfo?.currency }}</span>
                    <span class="titleCost">{{ translate.sections.billing.whatsappCost }}</span>
                </div>
                <button class="btn-primary details" (click)="toggleCostBreakdown('WHATSAPP')">
                    <i class="material-icons md-24">list</i> {{ translate.sections.billing.seeDetail }}
                </button>
                <button class="btn-primary download-details" (click)="downloadDetail('WHATSAPP')">
                    <i class="material-icons md-24">file_download</i> descargar
                </button>

                <div class="tableCost" *ngIf="showCostTableWhats">
                    <div class="wabInfo">
                        <span>{{ translate.sections.billing.whatsappBusinessAccount }}: {{ wabaInfo?.name }}</span>
                        <span>Zona horaria: {{ wabaInfo?.timezone?.name }}</span>
                    </div>
                    <div class="md-ui component-data-table">
                        <div class="main-table-wrapper">
                            <table class="main-table-content">
                                <thead class="data-table-header">
                                    <tr class="data-table-row">
                                        <td>{{ translate.sections.billing.date }}</td>
                                        <td class="conversations">{{ translate.sections.billing.conversations }}</td>
                                        <td>{{ translate.sections.billing.conversationType }}</td>
                                        <td>{{ translate.sections.billing.category }}</td>
                                        <td>{{ translate.sections.billing.country }}</td>
                                        <td>{{ translate.sections.billing.cost }} ({{ wabaInfo?.currency }})</td>
                                    </tr>
                                </thead>
                                <tbody class="data-table-content">
                                    <tr *ngFor="let wabaData of wabaConversationData" class="data-table-row">
                                        <td>
                                            <span>{{ wabaData?.start }}</span>
                                        </td>
                                        <td class="conversations">
                                            <span>{{ wabaData?.conversation }}</span>
                                        </td>
                                        <td>
                                            <span>{{ wabaData?.conversation_type }}</span>
                                        </td>
                                        <td>
                                            <span>{{ wabaData?.conversation_category }}</span>
                                        </td>
                                        <td>
                                            <span>{{ wabaData?.country }}</span>
                                        </td>
                                        <td>
                                            <span>{{ wabaData?.cost }}</span>
                                        </td>
                                    </tr>
                                    <tr class="data-table-row totals">
                                        <td>
                                            <span>{{ 'Totales' }}</span>
                                        </td>
                                        <td class="conversations">
                                            <span>{{ whatsAppConversationTotal }}</span>
                                        </td>
                                        <td colspan="3"></td>
                                        <td>
                                            <span>{{ totalWabaCost }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="boxBilling conversations">
            <div class="boxCost">
                <div class="costInfo">
                    <span class="totalCost">{{ conversationsTotal }} {{ translate.sections.billing.botConversations }}</span>
                </div>
            </div>
        </div>

        <div class="boxBilling activeUsers">
            <div class="boxCost">
                <div class="costInfo">
                    <span class="totalCost">{{ activeUsersTotal }} {{ translate.sections.billing.activeUsers }}</span>
                </div>
            </div>
        </div>

    </div>
</section>
