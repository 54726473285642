import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class CampaignService {

    constructor(private http: HttpClient) { }

    getChannels(slug: any) {
        const url = '/coreapi/v2/tenantChannels/byChannelTypes?channelTypes=SMOOCH_SMS,SMOOCH,WAVY,WHATSAPP&outputType=simpleMap';
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    // show list campaigns
    get(slug: any, page, tenantId) {
        const url = `/coreapi/v2/campaigns?outputType=map&campaignType=FLASH_CAMPAIGN&excludedStatus=NOT_CREATED&size=10&page=${page - 1}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId
        });
        return this.http.get(url, { headers });
    }

    getByStatus(status, slug: any, page, tenantId) {
        const url = `/coreapi/v2/campaigns/byStatusAndType?outputType=map&campaignType=FLASH_CAMPAIGN&status=${status}&size=10&page=${page - 1}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId
        });
        return this.http.get(url, { headers });
    }

    getOne(slug: any, id: any, tenantId) {
        const url = `/coreapi/v2/campaigns/${id}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId
        });
        return this.http.get(url, { headers });
    }

    delete(slug: any, id: any, tenantId) {
        const url = `/coreapi/v2/campaigns/${id}/logical`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId
        });
        return this.http.delete(url, { headers });
    }

    create(campaign: any, slug: any, tenantId) {
        const url = '/coreapi/v2/campaigns';

        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId,
            'Content-Type': 'application/json'
        });
        return this.http.post(url, campaign, { headers });
    }

    update(campaign: any, slug: any, id: any, tenantId) {
        const url = `/coreapi/v2/campaigns/${id}`;

        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId,
            'Content-Type': 'application/json'


        });
        return this.http.patch(url, campaign, { headers });
    }

    uploadFileCampaign(slug: string, file: any, channelId: string, hsmId: string, tenantId: string, reToken: string, campaignId: string = null) {
        const url = `/campaign/v1/channel/${channelId}/hsm/${hsmId}/validateAndLoad`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Tenant-Id': tenantId,
            'x-meta-acl': 'public-read',
            'Re-Token': reToken
        });
        return this.http.post(url, file, { headers });
    }

    uploadFileCampaign2(slug: string, file: any, channelId: string, hsmId: string, tenantId: string, campaignId: string, reToken: string) {
        const url = `/campaign/v1/channel/${channelId}/hsm/${hsmId}/campaign/${campaignId}/validateAndLoad`;
        const headers = new HttpHeaders({
            'Tenant-Id': tenantId,
            Slug: slug,
            'Re-Token': reToken
        });
        return this.http.post(url, file, { headers });
    }

    uploadAndEncodeVideo(slug: string, tenantChannelId, formData: any, reToken: string) {
        const url = `/coreapi/v2/whatsapp/${tenantChannelId}/encoder?source=form`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Re-Token': reToken
        });
        return this.http.post(url, formData, { headers });
    }

    getCampaignInsights(slug, campaignId, isWhatsappCloud) {
        const url = `/coreapi/v2/insights/campaign/${campaignId}?isWhatsappCloud=${isWhatsappCloud}`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    downloadReportByStatus(slug, campaignId, status, isWhatsappCloud) {
        const url = `/coreapi/v2/insights/campaign/${campaignId}/download/${status}?isWhatsappCloud=${isWhatsappCloud}`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    addRecommendationUsers(slug, campaignId, quantityNum) {
        const url = `/coreapi/v2/recEngine/campaign`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        const payload = {
            campaignId,
            quantityNum
        };
        return this.http.post(url, payload, { headers });
    }

}
