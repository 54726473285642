<div>
    <h2>Usuarios asignados</h2>
    <section class="md-ui component-data-table users" *ngIf="assignedUsers">
        <div class="main-table-wrapper">
            <table class="main-table-content">
                <thead class="data-table-header">
                    <tr class="data-table-row">
                        <td>Name</td>
                        <td>Tarea</td>
                        <td>Acciones</td>
                    </tr>
                </thead>
                <tbody class="data-table-content">
                    <!-- , pageBoundsCorrection:pageChanged()  -->
                    <tr *ngFor="let user of assignedUsers | paginate: { itemsPerPage: assignedUsersPerPage, currentPage: assignedUsersPage, totalItems: assignedUsersTotal}"
                        [ngClass]="{'data-table-row': true, 'new': user.new}">
                        <td>
                            {{user.name}}</td>
                        <td>
                            {{getTasks(user.tasks)}}
                        </td>
                        <td>
                            <!-- <button (click)="openModal('popup-confirmDelete', user)" title="Asignar"
                                class="btn-icon">
                                <i class="material-icons md-20">assignment_turned_in</i>
                            </button> -->
                            <button (click)="openModal('popup-confirm-delete-user', user)" title="Desasignar"
                                class="btn-icon">
                                <i class="material-icons md-20">remove_circle</i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <footer class="main-table-footer">
            <pagination-template #p="paginationApi" (pageChange)="phonesPageUpdate($event)">
                <ul class="pagination">
                    <li [ngClass]="{'pagination-previous': true, 'disabled': p.isFirstPage()}">
                        <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
                            << Anterior </a>
                                <span *ngIf="p.isFirstPage()">
                                    <span>
                                        << Anterior </span>
                                    </span>
                    </li>

                    <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                            <span>{{ page.label }}</span>
                        </a>
                        <span *ngIf="p.getCurrent() === page.value">
                            <span>{{ page.label }}</span>
                        </span>
                    </li>

                    <li [ngClass]="{'pagination-next': true, 'disabled': p.isLastPage()}">
                        <a *ngIf="!p.isLastPage()" (click)="p.next()">
                            Siguiente >>
                        </a>
                        <span *ngIf="p.isLastPage()">
                            <span>Siguiente >></span>
                        </span>
                    </li>
                </ul>
            </pagination-template>

        </footer> -->
    </section>
</div>
<div>
    <h2>Usuarios de sistema</h2>
    <div class="actions">
        <button (click)="openModal('popup-create-system-user', null)" title="Agregar" class="btn-secondary">
            Nuevo usuario de systema <i class="material-icons">add_circle</i>
        </button>
    </div>
    <section class="md-ui component-data-table users" *ngIf="systemUsers">
        <div class="main-table-wrapper">
            <table class="main-table-content">
                <thead class="data-table-header">
                    <tr class="data-table-row">
                        <td>Name</td>
                        <td>Rol</td>
                        <td>Acciones</td>
                    </tr>
                </thead>
                <tbody class="data-table-content">
                    <!-- , pageBoundsCorrection:pageChanged()  -->
                    <tr *ngFor="let user of systemUsers | paginate: { itemsPerPage: systemUsersPerPage, currentPage: systemUsersPage, totalItems: systemUsersTotal}"
                        [ngClass]="{'data-table-row': true, 'new': user.new}">
                        <td>
                            {{user.name}}</td>
                        <td>
                            {{user.role}}
                        </td>
                        <td>
                            <button (click)="openModal('popup-assign-user', user)" title="Asignar" class="btn-icon">
                                <i class="material-icons md-20">assignment_turned_in</i>
                            </button>
                            <!-- <button (click)="openModal('popup-confirm-delete-user', user)" title="Eliminar" class="btn-icon">
                                <i class="material-icons md-20">delete</i>
                            </button> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <footer class="main-table-footer">
            <pagination-template #p="paginationApi" (pageChange)="phonesPageUpdate($event)">
                <ul class="pagination">
                    <li [ngClass]="{'pagination-previous': true, 'disabled': p.isFirstPage()}">
                        <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
                            << Anterior </a>
                                <span *ngIf="p.isFirstPage()">
                                    <span>
                                        << Anterior </span>
                                    </span>
                    </li>

                    <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                            <span>{{ page.label }}</span>
                        </a>
                        <span *ngIf="p.getCurrent() === page.value">
                            <span>{{ page.label }}</span>
                        </span>
                    </li>

                    <li [ngClass]="{'pagination-next': true, 'disabled': p.isLastPage()}">
                        <a *ngIf="!p.isLastPage()" (click)="p.next()">
                            Siguiente >>
                        </a>
                        <span *ngIf="p.isLastPage()">
                            <span>Siguiente >></span>
                        </span>
                    </li>
                </ul>
            </pagination-template>

        </footer> -->
    </section>
</div>

<!-- begin modal create system user -->
<jw-modal id="popup-create-system-user">
    <a class="close_modal" (click)="closeModal('popup-create-system-user');">×</a>
    <h2>Crear usuario</h2>

    <div class="modal-body">
        <div>
            <label>Nombre</label>
            <input type="text">
        </div>
        <div>
            <label>Rol</label>
            <select>
                <option value="EMPLOYEE">Empleado</option>
                <option value="ADMIN">Administrador</option>
            </select>
        </div>
    </div>
    <div class="modal-actions">
        <button (click)="closeModal('popup-create-system-user');" class="btn-secondary">Cancelar</button>
        <button class="btn-primary">Asignar</button>
    </div>

</jw-modal>
<!-- end modal create system user -->

<!-- begin modal create system user -->
<jw-modal id="popup-assign-user">
    <a class="close_modal" (click)="closeModal('popup-assign-user');">×</a>
    <h2>Asignar usuario</h2>

    <div class="modal-body">
        <div>
            <label>Permiso</label>
            <select>
                <option value="MANAGE">Administrador</option>
                <option value="DEVELOP">Desarrollador</option>
            </select>
        </div>
    </div>
    <div class="modal-actions">
        <button (click)="closeModal('popup-assign-user');" class="btn-secondary">Cancelar</button>
        <button (click)="assignUser('DEVELOP')" class="btn-primary">Asignar</button>
    </div>

</jw-modal>
<!-- end modal create system user -->

<!-- begin modal confirm delete -->
<!-- <jw-modal id="popup-confirm-delete-user">
    <a class="close_modal" (click)="closeModal('popup-confirm-delete-user');">×</a>
    <h3>¿Esta seguro de que desear eliminar el usuario?</h3>

    <div class="modal-actions">
        <button class="btn-secondary">Cancelar</button>
        <button class="btn-primary">Borrar</button>
    </div>

</jw-modal> -->
<!-- end modal confirm delete -->
