import { Injectable } from '@angular/core';
import * as joint from '../../../vendor/rappid';

import { AlertService } from '../alert.service';
import { ModalService } from './modal.service';
declare var translate: any;
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class ObjectRegexService {
    translate: any;
    private alertService: AlertService;
    private modal: ModalService;

    constructor() {
        this.modal = new ModalService();
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    addObjectRegex(field, modal, entity, entities) {
        // Set main has star
        let classStr = '';
        if ('main' in entity && entity.main === true) {
            classStr = ' class="main" title="Principal"';
        }

        // Create entity
        const entityItem = $('<li' + classStr + ' data-id="' + entity.id + '"/>');
        const entityLink = $(`<a>${entity.name}</a>`);
        const entitySmall = $(`<small class="constraints">${entity.type}</small>`);
        const entityDelete = $('<a class="btnDelete">×</a>');

        entityItem.append([entityLink, entitySmall, entityDelete]);

        // Show all properties for edit
        entityLink.on('click', (eLink) => {

            entity.regex.forEach((re, reIdx) => {
                entity.regex[reIdx] = this.unescapeRegExp(re);
            });

            modal.show();
            modal.findAndVal('input[name="id"]', entity.id);
            modal.findAndVal('input[name="name"]', entity.name);
            modal.findAndVal('select[name="type"]', entity.type);
            modal.findAndVal('input[name="endpoint"]', entity.endpoint);
            modal.findAndVal('textarea[name="regex"]', entity.regex.join('\n'));
            modal.findAndVal('textarea[name="grupos"]', entity.grupos.join('\n'));

            if ('main' in entity && entity.main === true) {
                modal.box.find('input[name="main"]').prop('checked', true);
            }
        });

        // Delete entity
        entityDelete.on('click', (eDelete) => {
            const index = entityDelete.parent().index();
            entities.splice(index, 1);

            // const id = entityDelete.parent().data('id');
            entityItem.remove();
            // Save on hidden field
            field.val(JSON.stringify({ entidades: entities }));
        });

        return entityItem;
    }

    escapeRegExp(s) {
        return s.replace(/[.*+?^${}()|[\]\\]/g, '\\\\$&');
    }

    unescapeRegExp(s) {
        // return s.replace(/\\[.*+?^${}()|[\]\\]/g, '');
        let response = s;
        const re = /\\\\[.*+?^${}()|[\]\\\\]/g;
        let m;

        do {
            m = re.exec(s);
            if (m) {
                // console.log(m[0]);
                const strReplaced = m[0].replace('\\\\', '');
                response = response.replace(m[0], strReplaced);
            }
        } while (m);
        return response;
    }

    getObjectRegex(field, req, value) {
        // Add index to entity
        let entities = [];
        if (value) {
            // console.log(value);
            const valueJson = JSON.parse(value);
            if ('entidades' in valueJson) {
                // const entitiesAux = JSON.stringify(value.entidades);
                // entities = JSON.parse(entitiesAux);
                entities = valueJson.entidades;
                entities.forEach((entity, entityIdx) => {
                    if (!('id' in entity)) {
                        entities[entityIdx].id = joint.util.uuid();
                    }
                });
            }
            // entities = value.entidades;
        }

        const containerDiv = $('<div class="object-regex" />');
        const containerModalDiv = $('<div class="container-modal" />');
        const modalTitle = $('<h2>' + translate.sections.services.entities + '</h2>');
        const idInput = $('<input name="id" type="hidden" type="text" vlaue="">');
        const nameLabel = $('<label>' + translate.name+ '</label>');
        const nameInput = $('<input name="name" type="text">');
        const endpointLabel = $('<label>Endpoint</label>');
        const endpointInput = $('<input name="endpoint" type="text" placeholder="https://">');
        const mainLabel = $('<label class="label-check">Principal</label>');
        const mainCheck = $('<input name="main" type="checkbox" value="true">');
        const regexLabel = $('<label>Regex</label>');
        const regexTextarea = $('<textarea name="regex"></textarea>');
        const groupLabel = $('<label' + translate.sections.services.group + '</label>');
        const groupTextarea = $('<textarea name="grupos"></textarea>');
        const typeLabel = $('<label>' + translate.sections.services.type + '</label>');
        const typeSelect = $('<select name="type"></select>');
        const btnSaveEntity = $('<button class="buttonSaveEntity">' + translate.sections.services.save + '</button>');
        // FIXME: change by entityTypes enum
        const typeList = [
            'EXTRACTOR',
            'REGULAR_COLECTOR',
            'ENTITY_REF_EXTRACTOR',
            'POSITIONAL_REF_EXTRACTOR',
            'MATCH_REF_EXTRACTOR',
            'TIMING_REF_EXTRACTOR',
            'STAYTIME_REF_EXTRACTOR',
            'G_EXTRACTOR'
        ];
        const entitiesList = $('<ul></ul>');

        typeList.forEach(type => {
            const typeOption = `<option value="${type}">${type}</option>`;
            typeSelect.append(typeOption);
        });

        const modal = this.modal.create({
            onClose: (eClose) => {
                // Get values
                // const id = modal.findAndVal('input[name="id"]');
                // const name = modal.findAndVal('input[name="name"]');
                // const type = modal.findAndVal('select[name="type"]');
                // const endpoint = modal.findAndVal('input[name="endpoint"]');
                // const regexStr = modal.findAndVal('textarea[name="regex"]');
                // const gruposStr = modal.findAndVal('textarea[name="grupos"]');
                // const main = modal.findAndVal('input[name="main"]');

                // const regex = regexStr.length > 0 ? regexStr.split('\n') : [];
                // const grupos = gruposStr.length > 0 ? gruposStr.split('\n') : [];

                // regex.forEach((re, reIdx) => {
                //     regex[reIdx] = this.escapeRegExp(re);
                // });

                // let isNew = true;
                // let index = 0;

                // entities.forEach((entity, entityIdx) => {
                //     if (entity.id === id) {
                //         isNew = false;
                //         index = entityIdx;
                //     }
                // });

                // // Name, type and regex are required
                // if (name !== '' && type && type !== '' && regex !== '') {
                //     const entity = { id, name, type, endpoint, regex, grupos, main };
                //     if (isNew) {
                //         entity.id = joint.util.uuid();
                //         entities.push(entity);
                //         const entityItem = this.addObjectRegex(field, modal, entity, entities);
                //         entitiesList.append(entityItem);
                //     } else {
                //         entities[index].name = entity.name;
                //         entities[index].type = entity.type;
                //         entities[index].endpoint = entity.endpoint;
                //         entities[index].regex = entity.regex;
                //         entities[index].grupos = entity.grupos;
                //         entities[index].main = entity.main;

                //         const entityItem = entitiesList.find('li').get(index);
                //         $(entityItem).find('a:first-child').text(name);
                //         $(entityItem).find('small').text(type);
                //     }

                //     // Save on hidden field
                //     field.val(JSON.stringify({ entidades: entities }));
                // } else {
                //     this.alertService.warning('No se agregó la entidad. Los campos nombre, tipo y regex son obligatorios');
                // }
            }
        });

        // New entity
        const addBtn = $('<button class="btn-primary">+ entidad</button>');

        addBtn.on('click', (eBtn) => {
            modal.show();

            // Clear fields
            modal.findAndVal('input[name="id"]', '');
            modal.findAndVal('input[name="name"]', '');
            modal.findAndVal('select[name="type"]', '');
            modal.findAndVal('input[name="endpoint"]', '');
            modal.findAndVal('textarea[name="regex"]', '');
            modal.findAndVal('textarea[name="grupos"]', '');
            modal.findAndVal('input[name="main"]', false);
        });

        // Create DOM of modal
        containerModalDiv.append([
            idInput,
            nameLabel,
            nameInput,
            typeLabel,
            typeSelect,
            endpointLabel,
            endpointInput,
            regexLabel,
            regexTextarea,
            groupLabel,
            groupTextarea,
            mainLabel,
            mainCheck,
            btnSaveEntity
        ]);

        modal.box.append([modalTitle, containerModalDiv]);

        $('body').append([modal.box, modal.blocker]);

        btnSaveEntity.on('click', (e) => {
            const id = modal.findAndVal('input[name="id"]');
            const name = modal.findAndVal('input[name="name"]');
            const type = modal.findAndVal('select[name="type"]');
            const endpoint = modal.findAndVal('input[name="endpoint"]');
            const regexStr = modal.findAndVal('textarea[name="regex"]');
            const gruposStr = modal.findAndVal('textarea[name="grupos"]');
            const main = modal.findAndVal('input[name="main"]');

            const regex = regexStr.length > 0 ? regexStr.split('\n') : [];
            const grupos = gruposStr.length > 0 ? gruposStr.split('\n') : [];

            regex.forEach((re, reIdx) => {
                regex[reIdx] = this.escapeRegExp(re);
            });

            let isNew = true;
            let index = 0;

            entities.forEach((entity, entityIdx) => {
                if (entity.id === id) {
                    isNew = false;
                    index = entityIdx;
                }
            });

            // Name, type and regex are required
            if (name !== '' && type && type !== '' && regex !== '') {
                const entity = { id, name, type, endpoint, regex, grupos, main };
                if (isNew) {
                    entity.id = joint.util.uuid();
                    entities.push(entity);
                    const entityItem = this.addObjectRegex(field, modal, entity, entities);
                    entitiesList.append(entityItem);
                } else {
                    entities[index].name = entity.name;
                    entities[index].type = entity.type;
                    entities[index].endpoint = entity.endpoint;
                    entities[index].regex = entity.regex;
                    entities[index].grupos = entity.grupos;
                    entities[index].main = entity.main;

                    const entityItem = entitiesList.find('li').get(index);
                    $(entityItem).find('a:first-child').text(name);
                    $(entityItem).find('small').text(type);
                }

                // Save on hidden field
                field.val(JSON.stringify({ entidades: entities }));
            } else {
                this.alertService.warning(''+ translate.sections.services.noAdded + '');
            }
        });

        // Show entities
        entities.forEach((entity, entityIdx) => {
            const entityItem = this.addObjectRegex(field, modal, entity, entities);
            entitiesList.append(entityItem);
        });

        const nameClass = field.attr('name').replace('#', '-');
        containerDiv.addClass(nameClass);
        containerDiv.append([addBtn, entitiesList]);
        return containerDiv;
    }

}
