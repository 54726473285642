<section class="wrapperCampaign">
    <div *ngIf="!existsMessages" class="emptyMessages">
        <img src="/assets/img/empty_state_campaigns.png" alt="Campaigns" />
        <h1>{{translate.sections.campaigns.sendCampaigns.noMessages}}</h1>
    </div>

    <div [ngStyle]="{ display: 'none' }" class="divCampaign">
        <p class="text-info">
            {{translate.sections.campaigns.sendCampaigns.detailMessage}}
        </p>

        <form #formulario="ngForm" class="container">
            <div class="part1">
                <div class="field-box">
                    <label for="">{{translate.sections.campaigns.sendCampaigns.name}}</label>
                    <input #nameCampaignC value="" type="text" placeholder={{translate.sections.campaigns.sendCampaigns.exampleName}}/>
                </div>

                <div class="field-box">
                    <label for="">
                        {{translate.sections.campaigns.sendCampaigns.expiresIn}}
                        <i class="material-icons md-20 help"
                            data-tippy-content={{translate.sections.campaigns.sendCampaigns.timeMessage}}>
                            help
                        </i>
                    </label>
                    <div class="range-wrap">
                        <div #rangevalue1 id="rangevalue1" class="range-value">
                            <span></span>
                        </div>
                        <input #windowTimeC type="range" min="1" max="24" value="1" step="1" />
                    </div>
                    <div class="description">
                        {{translate.sections.campaigns.sendCampaigns.scrollMessage}}
                    </div>
                </div>

                <div class="field-box">
                    <label for="">
                        {{translate.sections.campaigns.sendCampaigns.automaticResponse}}
                        <i class="material-icons md-20 help"
                            data-tippy-content={{translate.sections.campaigns.sendCampaigns.responseMessage}}>
                            help
                        </i>
                    </label>
                    <textarea #messageC
                        placeholder={{translate.sections.campaigns.sendCampaigns.exampleMessage}}></textarea>
                </div>

                <div class="field-box">
                    <label for="">
                        {{translate.sections.campaigns.sendCampaigns.keyword}}
                        <i class="material-icons md-20 help"
                            data-tippy-content={{translate.sections.campaigns.sendCampaigns.keyWordMessage}}>
                            help
                        </i>
                    </label>
                    <input #keywordRegexC type="text" placeholder={{translate.sections.campaigns.sendCampaigns.examplePromotion}} />
                </div>

                <!-- <div class="field-box">
                    <label for="">Línea por la que se enviarán tus mensajes</label>
                    <select (change)="getHsmId(selectChannelC.value)" #selectChannelC>
                        <option value="">Seleccionar</option>
                        <option *ngFor="let channel of channels" value="{{ channel.id }}">
                            {{ channel.label }}
                        </option>
                    </select>
                </div> -->
            </div>

            <div class="part2">
                <div class="field-box">
                    <label for="">{{translate.sections.campaigns.sendCampaigns.selectMessage}}</label>
                    <select (change)="getParams(selectHsmIdC.value, true, true)" #selectHsmIdC>
                        <option value="">{{translate.sections.campaigns.sendCampaigns.select}}</option>
                        <option *ngFor="let hsm of arrayHsm" value="{{ hsm.id }}" [selected]="hsm.selected">
                            {{ hsm.name }} ( {{ hsm.elementName }} )
                        </option>
                    </select>
                </div>

                <div class="field-box upload-box excel" appDnd (fileDropped)="onFileDropped($event, 'excel')">
                    <div class="files-upload">
                        <figure>
                            <img src="../../assets/img/excel.svg" alt="Excel" />
                        </figure>
                        <input hidden type="file" accept=".xlsx" #uploader (change)="fileBrowseHandler($event, 'excel')" />
                        <strong>{{translate.sections.campaigns.sendCampaigns.userList}}</strong>
                        <span>{{translate.sections.campaigns.sendCampaigns.click}}
                            <button #buttonUploadC id="buttonUploadFile" (click)="openPopupLocalFiles(uploader)">
                                {{translate.sections.campaigns.sendCampaigns.clickHereToUpload}}
                            </button>
                            {{translate.sections.campaigns.sendCampaigns.xlsMessage}}</span>
                    </div>
                    <div class="files-list" *ngIf="files.length > 0">
                        <div class="single-file">
                            <div class="file-icon">
                                <i class="material-icons md-20">insert_drive_file</i>
                            </div>
                            <div class="file-help">
                                <span class="file-name">{{ files[0]?.name }}</span>
                                <span class="file-size">{{
                                    formatBytes(files[0].size, 0)
                                    }}</span>
                            </div>

                            <div class="file-delete" (click)="deleteFile(0, 'excel')">
                                <i class="material-icons md-20">delete_forever</i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="associateParamsColumns" *ngIf="showAssociation">
                    <p>{{translate.sections.campaigns.sendCampaigns.paramsAssociation}}</p>
                    <!-- <p *ngFor="let param of arrayParamsMessageColumn; index as i">
                        <span class="param">{{getNameParam(param)}}</span> -> <span class="colum">{{columnsCount[arrayParamsMessageColumnVal[i]]}}</span>
                    </p> -->
                    <p>
                        <span>{{translate.sections.campaigns.sendCampaigns.param}} -> {{translate.sections.campaigns.sendCampaigns.columnFileExcel}}</span>
                        <span *ngFor="let param of arrayParamsMessageColumn; index as i">
                            {{getNameParam(param)}} -> {{columnsCount[arrayParamsMessageColumnVal[i]]}}
                        </span>
                    </p>
                </div>

                <div class="field-box upload-box video" appDnd (fileDropped)="onFileDropped($event, 'video')" *ngIf="this.dataMessage.mediaType === 'VIDEO'">
                    <div class="files-upload">
                        <!-- <figure>
                            <img src="../../assets/img/excel.svg" alt="Video" />
                        </figure> -->
                        <i class="material-icons video">play_circle_outline</i>
                        <input hidden type="file" accept=".mp4" #uploader2 (change)="fileBrowseHandler($event, 'video')" />
                        <strong>{{translate.sections.campaigns.sendCampaigns.dragAndDropVideo}}</strong>
                        <strong>{{translate.sections.campaigns.sendCampaigns.o}}</strong>
                        <span>{{translate.sections.campaigns.sendCampaigns.click}}
                            <button #buttonUploadC2 id="buttonUploadFile" (click)="uploader2.click()">
                                {{translate.sections.campaigns.sendCampaigns.clickHereToUpload}}
                            </button>
                            {{translate.sections.campaigns.sendCampaigns.clickHereToUpload2}}</span>
                    </div>
                    <div class="preview">
                        <video controls class="video">
                            <source [src]="videoUrl" type="video/mp4">
                        </video>
                    </div>
                    <div class="files-list" *ngIf="files2.length > 0">
                        <div class="single-file" *ngFor="let file2 of files2; let i2 = index">
                            <div class="file-help">
                                <span class="file-name">{{ file2?.name }}</span>
                                <span class="file-size">{{
                                    formatBytes(file2?.size, 0)
                                    }}</span>
                            </div>

                            <div class="file-delete" (click)="deleteFile(i2, 'video')">
                                <i class="material-icons md-20">delete_forever</i>
                            </div>
                        </div>
                    </div>
                </div>

                <h4>{{translate.sections.campaigns.sendCampaigns.note}}:</h4>
                <span>{{translate.sections.campaigns.sendCampaigns.noteText}}
                    <a href="mailto:soporte@gus.chat">{{translate.sections.campaigns.sendCampaigns.us}}</a>.</span>
            </div>

            <div class="part3">
                <p>{{translate.sections.campaigns.sendCampaigns.preview}}</p>

                <app-preview-message [dataMessage]="dataMessage"></app-preview-message>
                <div class="modal-actions">
                    <button class="btn-secondary" (click)="openModal('popup-schedule', 'programar')"
                        [disabled]="sendCampaignBtn">
                        {{translate.sections.campaigns.sendCampaigns.schedule}}
                    </button>
                    <button class="btn-secondary" (click)="openModal('popup-confirm', 'confirm')"
                        [disabled]="sendCampaignBtn">
                        {{translate.sections.campaigns.sendCampaigns.sendNow}}
                    </button>
                    <button #buttonsendC type="submit" (click)="
                            saveCampaign(
                                nameCampaignC,
                                windowTimeC,
                                messageC,
                                keywordRegexC,
                                selectHsmIdC
                            )
                        " class="btn-primary">
                        {{translate.sections.campaigns.sendCampaigns.save}}
                    </button>
                </div>
            </div>
        </form>
    </div>
    <!-- <button (click)="printCampaign()">print campaign</button> -->
</section>

<!-- begin modal date scheduled -->
<jw-modal id="popup-schedule">
    <a class="close_modal" (click)="closeModal('popup-schedule')">×</a>
    <div class="createSchedule">
        <h2 class="title titleProgramar">{{translate.sections.campaigns.sendCampaigns.scheduleDate}}</h2>
        <input hidden #idCampaignControl type="text" value="{{ idCampaign }}" />

        <div class="divScheduleSelected">
            <div class="field-box container-calendar">
                <input disabled #loadDate class="showDate" type="text" />

                <a (click)="openCalendar()" class="c-btn c-datepicker-btn">
                    <span class="material-icon"><img class="icon-calendar"
                            src="../../assets/img/icons-campaigns/SCHEDULED.svg" alt="" /></span>
                </a>
            </div>

            <div class="field-box">
                <label for="">{{translate.sections.campaigns.sendCampaigns.selectTimeZone}}</label>

                <select id="selectTime" #selectTimeZone>
                    <option [value]="tz" *ngFor="let tz of alltz">{{ tz }}</option>
                </select>
            </div>
        </div>

        <div class="modal-actions">
            <button (click)="functionCancel('popup-schedule')" class="btn-secondary">
                {{translate.sections.campaigns.sendCampaigns.cancel}}
            </button>
            <button (click)="functionSendDate(selectTimeZone)" class="btn-primary">
                {{translate.sections.campaigns.sendCampaigns.send}}
            </button>
        </div>
    </div>
</jw-modal>
<!-- end modal date scheduled -->

<!-- begin modal date scheduled now -->
<jw-modal id="popup-schedule-now">
    <a class="close_modal" (click)="closeModal('popup-schedule-now')">×</a>
    <div class="createSchedule">
        <h2 class="title titleProgramar">{{translate.sections.campaigns.sendCampaigns.sendNow}}</h2>
        <input hidden #idCampaignControl type="text" value="{{ idCampaign }}" />

        <div class="divScheduleSelected">
            <div class="field-box">
                <label for="">{{translate.sections.campaigns.sendCampaigns.selectTimeZone}}</label>

                <select id="selectTime" [(ngModel)]="selectedTz">
                    <option [value]="tz" *ngFor="let tz of alltz">{{ tz }}</option>
                </select>
            </div>
        </div>

        <div class="modal-actions">
            <button (click)="functionCancel('popup-schedule-now')" class="btn-secondary">
                {{translate.sections.campaigns.sendCampaigns.cancel}}
            </button>
            <button (click)="functionSendNow()" class="btn-primary">
                {{translate.sections.campaigns.sendCampaigns.send}}
            </button>
        </div>
    </div>
</jw-modal>
<!-- end modal date scheduled now -->

<!-- begin modal confirm send now -->
<jw-modal id="popup-confirm">
    <a class="close_modal" (click)="closeModal('popup-confirm')">×</a>
    <h3>{{translate.sections.campaigns.sendCampaigns.sendMessage}}</h3>

    <div class="modal-actions">
        <button (click)="functionCancel('popup-confirm')" class="btn-secondary">
            {{translate.sections.campaigns.sendCampaigns.cancel}}
        </button>
        <button (click)="functionSendNow()" class="btn-primary">
            {{translate.sections.campaigns.sendCampaigns.confirm}}
        </button>
    </div>
</jw-modal>
<!-- end modal confirm send now -->

<!-- begin modal validate xlsx -->
<jw-modal id="popup-validate-xlsx" [bodyStyles]="validateXlsxBodyStyles">
    <a class="close_modal" (click)="closeValidateXlsModal()">×</a>
    <h3>{{translate.sections.campaigns.sendCampaigns.associateParamsToColumns}}</h3>

    <div class="full">
        <section class="md-ui component-data-table">
            <div class="main-table-wrapper">
                <table class="main-table-content">
                    <thead class="data-table-header">
                        <tr class="data-table-row">
                            <td>{{translate.sections.campaigns.sendCampaigns.param}}</td>
                            <td>{{translate.sections.campaigns.sendCampaigns.columnFileExcel}}</td>
                        </tr>
                    </thead>
                    <tbody class="data-table-content">
                        <tr *ngFor="let param of arrayParamsMessageColumn; let i = index;" class="data-table-row">
                            <td>
                                {{ getNameParam(param) }}
                            </td>
                            <td class="column">
                                <select [(ngModel)]="arrayParamsMessageColumnVal[i]">
                                    <option value="">{{translate.sections.campaigns.sendCampaigns.selectColumn}}</option>
                                    <option [value]="j" *ngFor="let char of this.columnsCount; let j = index;">
                                        {{ char }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>

        <section class="md-ui component-data-table">
            <h4 class="subtitle">{{translate.sections.campaigns.sendCampaigns.fileExtract}}</h4>
            <div class="main-table-wrapper">
                <table class="main-table-content">
                    <thead  class="data-table-header">
                        <tr class="data-table-row">
                            <td *ngFor="let column of this.columnsCount">
                                {{ column }}
                            </td>
                        </tr>
                    </thead>
                    <tbody class="data-table-content">
                        <tr *ngFor="let row of dataExcel; let i=index" class="data-table-row">
                            <td *ngFor="let cell of row; let j=index">
                                {{ cell }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </div>

    <div class="modal-actions">
        <button (click)="closeValidateXlsModal()" class="btn-secondary">
            {{translate.sections.campaigns.sendCampaigns.cancel}}
        </button>
        <button (click)="openModal('popup-add-recommendation', '')" class="btn-secondary-alternate">
            {{translate.sections.campaigns.sendCampaigns.addRecommendationBtn}}
        </button>
        <button (click)="validateXlsx()" class="btn-primary">
            {{translate.sections.campaigns.sendCampaigns.validateExcel}}
        </button>
    </div>
</jw-modal>
<!-- end modal validate xlsx -->


<!-- begin modal confirm send now -->
<jw-modal id="popup-add-recommendation">
    <a class="close_modal" (click)="closeModal('popup-add-recommendation')">×</a>
    <h3>{{translate.sections.campaigns.sendCampaigns.addRecommendationTitle}}</h3>
    <div>
        <div class="field-box">
            <label for="">{{translate.sections.campaigns.sendCampaigns.quantityNum}}</label>
            <input class="quantityNum" type="number" [(ngModel)]="quantityNum" placeholder="> 0" />
        </div>
    </div>

    <div class="modal-actions">
        <button (click)="functionCancel('popup-add-recommendation')" class="btn-secondary">
            {{translate.sections.campaigns.sendCampaigns.cancel}}
        </button>
        <button (click)="addRecommendationUsers()" class="btn-primary">
            {{translate.sections.campaigns.sendCampaigns.addBtn}}
        </button>
    </div>
</jw-modal>
<!-- end modal confirm send now -->

<!-- begin modal success recommendation -->
<jw-modal id="popup-success-recommendation">
    <a class="close_modal" (click)="closeModal('popup-success-recommendation')">×</a>
    <h3>{{translate.sections.campaigns.sendCampaigns.successRecommendationTitle}}</h3>
    <div>
        <p>
            {{ successRecommendationMsg }}
        </p>
    </div>

    <div class="modal-actions">
        <button (click)="functionCancel('popup-success-recommendation')" class="btn-secondary">
            {{ translate.sections.campaigns.sendCampaigns.close }}
        </button>
        <a *ngIf="successRecommendationLink" [href]="successRecommendationLink" target="_blank" class="btn-primary">
            <span>
                {{ translate.sections.campaigns.sendCampaigns.download }}
            </span>
            <i class="material-icons">download</i>
        </a>
    </div>
</jw-modal>
<!-- end modal confirm success recommendation -->
