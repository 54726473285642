import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { CustomerService } from "../../services/customer.service";

import { ChannelsService } from "../../services/channels.service";
import { TimezoneService } from 'src/services/inspector-gus/timezone.service';
declare var translate: any;
@Component({
    selector: 'app-horary',
    templateUrl: './horary.component.html',
    styleUrls: ['./horary.component.scss']
})
export class HoraryComponent implements OnInit {
    urlParameter: any;
    currentCustomer: any;
    current: any;

    channelService: any;

    disabled: boolean = true;
    timezone: string = 'America/Mexico_City';
    loaded: boolean = false;
    dayCount: any = {
        "1": {
            "label": translate.sections.horary.monday,
            "from": { "hour": "09", "minute": "00" },
            "to": { "hour": "18", "minute": "00" },
            "present": true
        },
        "2": {
            "label": translate.sections.horary.tuesday,
            "from": { "hour": "09", "minute": "00" },
            "to": { "hour": "18", "minute": "00" },
            "present": true
        },
        "3": {
            "label": translate.sections.horary.wednesday,
            "from": { "hour": "09", "minute": "00" },
            "to": { "hour": "18", "minute": "00" },
            "present": true
        },
        "4": {
            "label": translate.sections.horary.thursday,
            "from": { "hour": "09", "minute": "00" },
            "to": { "hour": "18", "minute": "00" },
            "present": true
        },
        "5": {
            "label": translate.sections.horary.friday,
            "from": { "hour": "09", "minute": "00" },
            "to": { "hour": "18", "minute": "00" },
            "present": true
        },
        "6": {
            "label": translate.sections.horary.saturday,
            "from": { "hour": "09", "minute": "00" },
            "to": { "hour": "18", "minute": "00" },
            "present": true
        },
        "7": {
            "label": translate.sections.horary.sunday,
            "from": { "hour": "09", "minute": "00" },
            "to": { "hour": "18", "minute": "00" },
            "present": true
        }
    };
    minutes: String[] = [];
    hours: String[] = [];
    keys: string[] = [];
    // headers: Header [] = [];
    channel_id: string;
    translate: any;
    alltz: string[] = [];

    constructor(
        private router: Router,
        private activate: ActivatedRoute,
        private customerService: CustomerService,
        private channelsService: ChannelsService,
        private timezoneService: TimezoneService
    ) { this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000); }

    ngOnInit() {
        this.urlParameter = window.location.href;
        const parameter = this.activate.snapshot.params;
        if (parameter.id) {
            console.log(parameter.id);
            console.log(parameter.customer_slug);


            this.getCurrentCustomer(parameter.customer_slug, parameter.id);


            this.keys = Object.keys(this.dayCount);
            for (let i = 0; i < 60; i++) {
                this.minutes.push(i < 10 ? "0" + i : i.toString());
            }
            for (let i = 0; i < 24; i++) {
                this.hours.push(i < 10 ? "0" + i : i.toString());
            }

            this.getAllTz(parameter.customer_slug);

        }
    }

    getAllTz(slug) {
        this.timezoneService.getAll(slug).subscribe(
            (res: string[]) => {
                this.alltz = res;
            },
            err => {
                console.log(err);
            }
        );
    }


    getCurrentCustomer(slug: string, channel_id: string) {
        this.customerService.getBySlug(slug).subscribe(
            (customer: any) => {
                this.currentCustomer = customer.tenant;
                console.log('value  de currentcustomer', this.currentCustomer);

                console.log(customer.tenant.channels);


                for (const item of customer.tenant.channels) {
                    if (item.id === channel_id) {
                        this.channelService = item;
                    }
                }

                console.log(this.channelService);


                // cl1pwjie-ovlp-cp9c-cii9-17262b3d16a OWNERID
                this.customerService.getChannelCustomerByChannelId(slug, this.channelService.channelOwnerId).subscribe(channel => {
                    this.current = channel;

                    console.log('valor de current normal', this.current);



                    // if (this.current.serviceOperationTime) {
                    console.log(this.current.tenantChannel.serviceOperationTime);


                    try {
                        var serviceTime = JSON.parse(this.current.tenantChannel.serviceOperationTime);
                        console.log('valor de service time', serviceTime);

                        if ('timezone' in serviceTime) {
                            this.timezone = serviceTime['timezone'];


                        }
                        if ('dayMap' in serviceTime) {
                            this.dayCount = serviceTime['dayMap'];



                        }

                    } catch (e) {


                        // this.save(null, null);
                    }
                    // } else {

                    //     // this.save(null, null);
                    // }
                    this.loaded = true;


                }, err => {

                });
            }, err => {



            });
    }


    save(currentDay: any = null, origin: string = null) {
        if (currentDay) {
            let _from = currentDay['from'];
            let _to = currentDay['to'];
            let _from_hour = parseInt(_from['hour'] + _from['minute']);
            let _to_hour = parseInt(_to['hour'] + _to['minute']);
            if (_from_hour > _to_hour) {
                let destiny = origin == 'from' ? 'to' : 'from';
                currentDay[destiny]['hour'] = currentDay[origin]['hour'];
                currentDay[destiny]['minute'] = currentDay[origin]['minute'];

            }

        }
        var payload = {
            "timezone": this.timezone,
            "dayMap": this.dayCount
        };


        console.log('valor de slug', this.currentCustomer.slug);
        console.log('valor de id channel', this.current.tenantChannel.id);


        this.channelsService.updateHorary(this.current.tenantChannel.id, { "serviceOperationTime": JSON.stringify(payload) }, this.currentCustomer.slug).subscribe(
            (res) => {
                console.log('actualizado')
            },
            err => {

            }
        );
    }

    updateDaySelection(day: any) {
        day['present'] = !day['present'];
    }

}
