import { Component, OnInit, Input } from '@angular/core';

import { environment } from '../../environments/environment';

import { Loader } from '../../models/loader';
import { LoaderService } from '../../services/loader.service';
declare var translate: any;

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html'
})
export class LoaderComponent implements OnInit {

    @Input() id: string;
    translate: any;
    isOpen: boolean;
    trailerUrl: string;
    assets: string = environment.assets;

    constructor(private loaderService: LoaderService) {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
     }

    ngOnInit() {
        this.loaderService.getLoader(this.id).subscribe((trailer: Loader) => {
            this.isOpen = trailer.show;
        });
    }

    show() {
        this.isOpen = true;
    }

    close() {
        this.isOpen = false;
    }

}
