import { Injectable } from '@angular/core';
import * as joint from '../../../vendor/rappid';
declare var translate: any;
declare var $: any;
declare var Sortable: any;

@Injectable({
    providedIn: 'root'
})
export class WamService {
    translate: any;
    constructor() {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
     }

    getWAM(field) {
        const containerDiv = $('<div class="wam" />');

        const contentsDiv = $('<div class="contentsWAM" />');
        const addBtn = $('<button class="btn-primary">+ '+ translate.sections.services.option+ '</button>');
        let jsonWAM = [];

        addBtn.on('click', (eBtn) => {
            const id = joint.util.uuid();
            const contentBoxWAM = $(`<div id="${id}" class="contentBoxWAM" data-id="${id}" data-idx="${jsonWAM.length}" />`);
            const contentInput = $('<input type="text" class="contentWAM">');
            const deleteBtn = $('<a class="btnDelete">×</a>');
            contentBoxWAM.append([contentInput, deleteBtn]);

            jsonWAM.push({});

            deleteBtn.on('click', (eBtnDel) => {
                const parent = $(eBtnDel.target).parent();
                const idx = parent.data('id');
                jsonWAM = jsonWAM.filter(btn => btn.index !== idx);
                parent.remove();
                field.val(JSON.stringify({
                    Botones: jsonWAM,
                    type: 'jsonWAM',
                    showOutputs: true
                }));
            });

            contentInput.focusout((element) => {
                const elem = element.target;
                const parent = $(elem).parent();
                const idx = parent.data('idx');
                const item = {
                    content: $(elem).val(),
                    index: $(parent).attr('id'),
                    name: $(elem).val()
                };
                jsonWAM.splice(idx, 1, item);
                field.val(JSON.stringify({
                    Botones: jsonWAM,
                    type: 'jsonWAM',
                    showOutputs: true
                }));
            });

            field.val(JSON.stringify({
                Botones: jsonWAM,
                type: 'jsonWAM',
                showOutputs: true
            }));

            contentsDiv.append(contentBoxWAM);
        });

        Sortable.create(contentsDiv.get(0), {
            onEnd: (evt) => {
                const jsonWAMAux = [];
                evt.from.childNodes.forEach((elem, idx) => {
                    $(elem).data('idx', idx);
                    jsonWAMAux.push({
                        content: $(elem).find('input').val(),
                        index: $(elem).attr('id'),
                        name: $(elem).find('input').val(),
                    });
                });
                field.val(JSON.stringify({
                    Botones: jsonWAMAux,
                    type: 'jsonWAM',
                    showOutputs: true
                }));
                jsonWAM = jsonWAMAux;
            }
        });

        const val = field.val();
        if (val !== '') {
            try {
                const value = JSON.parse(val);

                value.Botones.forEach(btn => {
                    const id = btn.index;
                    const contentBoxWAM = $(`<div id="${id}" class="contentBoxWAM" data-id="${id}" data-idx="${jsonWAM.length}" />`);
                    const contentInput = $(`<input type="text" class="contentWAM" value="${btn.content}">`);
                    const deleteBtn = $('<a class="btnDelete">×</a>');
                    contentBoxWAM.append([contentInput, deleteBtn]);

                    jsonWAM.push({
                        content: btn.content,
                        index: id,
                        name: btn.content,
                    });

                    deleteBtn.on('click', (eBtnDel) => {
                        const parent = $(eBtnDel.target).parent();
                        const idx = parent.data('id');
                        jsonWAM = jsonWAM.filter(btn => btn.index !== idx);
                        parent.remove();
                        field.val(JSON.stringify({
                            Botones: jsonWAM,
                            type: 'jsonWAM',
                            showOutputs: true
                        }));
                    });

                    contentInput.focusout((element) => {
                        const elem = element.target;
                        const parent = $(elem).parent();
                        const idx = parent.data('idx');
                        const item = {
                            content: $(elem).val(),
                            index: $(parent).attr('id'),
                            name: $(elem).val(),
                        };
                        jsonWAM.splice(idx, 1, item);
                        field.val(JSON.stringify({
                            Botones: jsonWAM,
                            type: 'jsonWAM',
                            showOutputs: true
                        }));
                    });

                    field.val(JSON.stringify({
                        Botones: jsonWAM,
                        type: 'jsonWAM',
                        showOutputs: true
                    }));

                    contentsDiv.append(contentBoxWAM);
                });
            } catch (e) {
                console.error(''+ translate.sections.services.menuWhatsapp+ '');
            }
        }

        const nameClass = field.attr('name').replace('#', '-');
        containerDiv.addClass(nameClass);
        containerDiv.append([addBtn, contentsDiv, field]);

        return containerDiv;
    }

}
