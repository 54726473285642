import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UseExistingWebDriver } from 'protractor/built/driverProviders';

@Injectable({
    providedIn: 'root'
})
export class ApiUserService {
    baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = '/coreapi/v2/apiUsers';
    }

    getToken(id: string, slug: string) {
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(this.baseUrl + '/' + id, { headers });
    }

    getAll(slug: string) {
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(this.baseUrl, { headers });
    }

    getRoles(slug: string) {
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(`${this.baseUrl}/authorities`, { headers });
    }

    create(user: any, slug: string) {
        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });
        return this.http.post(this.baseUrl, user, { headers });
    }

    delete(username: string, slug: string) {
        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });
        const options = {
            headers,
            body: {}
        };
        return this.http.delete(`${this.baseUrl}/${username}`, options);
    }

    createToken(user: any, slug: string) {
        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });
        return this.http.post(this.baseUrl, {}, { headers });
    }

    deleteToken(token: string, slug: string) {
        const headers = new HttpHeaders({
            Slug: slug,
            'Content-Type': 'application/json'
        });
        return this.http.delete(`/coreapi/v2/apiUser/token/${token}`, { headers });
    }

}
