import { Injectable } from '@angular/core';
declare var translate: any;
@Injectable({ providedIn: 'root' })
export class ModalService {
    translate: any;
    constructor() {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
     }

    create(options) {
        const blockerDiv = $('<div class="blocker"/>');
        const modalDiv = $('<div class="modal2"/>');
        const closeModalButton = $('<button class="close_modalI ">×</button>');
        let showOptions;

        closeModalButton.on('click', (e) => {
            if (options) {
                if ('onClose' in options) {
                    options.onClose(e, showOptions);
                }
            }
            modalDiv.hide();
            blockerDiv.hide();
        });

        modalDiv.append(closeModalButton);
        blockerDiv.append(modalDiv);

        return {
            blocker: blockerDiv,
            box: modalDiv,
            show: (showOptionsLink = undefined) => {
                showOptions = showOptionsLink;
                modalDiv.show();
                blockerDiv.show();
            },
            hide: () => {
                modalDiv.hide();
                blockerDiv.hide();
                if (options) {
                    if ('onClose' in options) {
                        options.onClose(null, showOptions);
                    }
                }
            },
            findAndVal: (selector, value = null) => {
                const field = modalDiv.find(selector);
                let ret;
                if (field.prop('type') === 'checkbox') {
                    if (value !== null) {
                        ret = field.prop('checked', value);
                    } else {
                        ret = field.prop('checked');
                    }
                    // TODO: for radio
                } else {
                    if (value || value === '') {
                        ret = field.val(value);
                    } else {
                        ret = field.val();
                    }
                }
                return ret;
            }
        };
    }

}
