import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConsolesService } from 'src/services/consoles.service';
import { AlertService } from 'src/services/alert.service';
import { OauthSmoochService } from 'src/services/oauth-smooch.service';
declare var translate: any;
@Component({
    selector: 'app-smooch',
    templateUrl: './smooch.component.html',
    styleUrls: ['./smooch.component.scss']
})
export class SmoochComponent implements OnInit {
    translate: any;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private consoleService: ConsolesService,
        private oauthSmoochService: OauthSmoochService
    ) { this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000); }

    ngOnInit() {
        if ('code' in this.route.snapshot.queryParams) {
            const code = this.route.snapshot.queryParams.code;
            const consoleId = localStorage.getItem('lastConsoleId');
            const currentCustomerSlug = localStorage.getItem('currentCustomerSlug');
            console.log('currentCustomerSlug: ', currentCustomerSlug);
            this.oauthSmoochService.switchboardConfiguration(code, consoleId, currentCustomerSlug).subscribe(
                (res: any) => {
                    const objeto = {
                        consoleLocation: res.appId,
                        consoleAccountId: 'guschat_zd-agentWorkspace',
                    };

                    this.consoleService.update(currentCustomerSlug, consoleId, objeto).subscribe(
                        res => {
                            localStorage.removeItem('lastConsoleId');
                            this.router.navigate(['/customers', currentCustomerSlug, 'consoles']);
                        },
                        err => {
                            console.log(err);
                            this.alertService.error(translate.sections.consoles.smooch.smoochNoConsole);
                        }
                    );
                    this.alertService.success(translate.sections.consoles.smooch.smoochConsole);
                }, (err: any) => {
                    console.log(err);
                    this.alertService.error(translate.sections.consoles.smooch.smoochError);
                }
            );
        }
    }
}
