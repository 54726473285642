import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConsolesService {

  constructor(private http: HttpClient) { }



  get(slug: string) {
    const url = '/coreapi/v2/tenantConsoles';
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.get(url, { headers });
  }


  // https://flux-pre.gus.chat/api/v1/tenantConsoles/0035aee8-67e4-463e-88db-75d181901cae
  getOne(slug, id) {
    const url = `/coreapi/v2/tenantConsoles/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.get(url, { headers });

  }

  // https://flux-pre.gus.chat/api/v1/consoleTypes
  types(slug) {

    const url = '/coreapi/v2/consoleTypes';
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.get(url, { headers });

  }

  // https://flux-pre.gus.chat/api/v1/tenantConsoles



  add(slug: any, consola: any) {
    const url = '/coreapi/v2/tenantConsoles';
    const headers = new HttpHeaders({
      Slug: slug,
      'Content-Type': 'application/json'
    });
    return this.http.post(url, consola, { headers });

  }



  // https://flux-pre.gus.chat/api/v1/tenantConsoles/14374f24-c7dd-4e20-9bba-99a86d0a33b6/activate


  activate(slug: any, id: any, showError = true) {
    const url = `/coreapi/v2/tenantConsoles/${id}/activate`;
    const headers = new HttpHeaders({
      Slug: slug,
      'Show-Error': showError.toString()
    });
    return this.http.post(url, {}, { headers });

  }

  // https://flux-pre.gus.chat/api/v1/constructorConsole/activeConsole

  showActivate(slug) {
    const url = '/coreapi/v2/constructorConsole/activeConsole';
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.get(url, { headers });
  }



  // https://flux-pre.gus.chat/api/v1/tenantConsoles/0035aee8-67e4-463e-88db-75d181901cae
  update(slug, id, consola) {

    const url = `/coreapi/v2/tenantConsoles/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.patch(url, consola, { headers });

  }


  delete(id: any, slug: any) {

    const url = `/coreapi/v2/tenantConsoles/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.delete(url, { headers });


  }

  getByAccountId(slug, accountId) {
    const url = `/coreapi/v2/tenantConsoles/search/byAccountId/${accountId}`;
    const headers = new HttpHeaders({
      Slug: slug,
      'Show-Error': 'false'
    });
    return this.http.get(url, { headers });
  }
}
