import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Customer } from '../models/customer';

import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private http: HttpClient) { }

    getByUserId(constructorUserId: string, showLoader = 'false') {
        const url = `/coreapi/v2/constructorUsers/${constructorUserId}`;
        let header;
        if (showLoader === 'false') {
            header = { 'Show-loader': showLoader };
        } else {
            header = {};
        }
        const headers = new HttpHeaders(header);
        return this.http.get(url, { headers });
    }

    getByUserIdEnv(constructorUserId: string, showLoader = 'false', env) {
        let url;


        const user = localStorage.getItem('id');
        const pass = localStorage.getItem('token');
        const auth = btoa(`${user}:${pass}`);


        if(env!='PROD'){
            url = `https://flux-v2-${env}.gus.chat/coreapi/v2/constructorUsers/${constructorUserId}?outputType=tenantSorted`;


        }else {
            url = `https://flux-v2.gus.chat/coreapi/v2/constructorUsers/${constructorUserId}?outputType=tenantSorted`;
        }



        let header;
        if (showLoader === 'false') {
            header = { 'Show-loader': showLoader };
        } else {
            header = {};
        }
        /* const headers = new HttpHeaders(header); */
        const headers = new HttpHeaders({
            ...header,
            'Auth-Type': localStorage.getItem('Auth-Type'),
            Authorization: 'Basic ' + auth,
        });
        return this.http.get(url, { headers });
    }


    getBySlug(slug: string, showLoader = 'false') {
        const url = `/coreapi/v2/tenant/${slug}`;
        let header;
        if (showLoader === 'false') {
            header = { 'Show-loader': showLoader };
        } else {
            header = {};
        }
        header.Slug = slug;
        const headers = new HttpHeaders(header);

        return this.http.get(url, { headers });
    }

    // getBySlugMin(slug: string, showLoader = 'false') {
    //     const url = `/coreapi/v2/tenant/${slug}?outputType=segmentMap`;
    //     let header;
    //     if (showLoader === 'false') {
    //         header = { 'Show-loader': showLoader };
    //     } else {
    //         header = {};
    //     }
    //     header.Slug = slug;
    //     const headers = new HttpHeaders(header);

    //     return this.http.get(url, { headers });
    // }

    getInvitedPendingBySlug(slug: string) {
        const url = `/coreapi/v2/tenantInvitations/byTenant?outputType=simpleMap`;
        let header = { Slug: slug };
        const headers = new HttpHeaders(header);

        return this.http.get(url, { headers });
    }

    getByTypeIndustry() {

        const url = '/coreapi/v2/serviceTypes';
        return this.http.get(url);
    }

    create(customer: Customer): Observable<Customer> {

        const url = '/coreapi/v2/tenant';
        return this.http.post<Customer>(url, customer);
    }



    update(slug: string, customerId: string, customer: object) {
        const url = `/coreapi/v2/tenant/${customerId}`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.patch(url, customer, { headers });
    }

    addAdmins(customerId: any, emailsOrIds: any, ownerId: string, slug: string) {
        const url = `/coreapi/v2/tenant/${customerId}/constructorUsers`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Show-Cause': 'true'
        });

        return this.http.post(url, { emailsOrIds, tenantOwnerId: ownerId }, { headers });

    }

    addAdmin(email: any, slug: string) {
        const url = `/coreapi/v2/tenantInvitations`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Show-Cause': 'true'
        });

        return this.http.post(url, { email }, { headers });
    }

    acceptInvitationOld(invitationId, slug) {
        const url = `/coreapi/v2/tenantInvitations/${invitationId}/acceptance`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Show-Cause': 'true'
        });

        return this.http.post(url, {}, { headers });
    }

    acceptInvitation(invitationId) {
        const url = `/coreapi/v2/tenantInvitations/${invitationId}/acceptance`;
        const headers = new HttpHeaders({
            'Show-Cause': 'true'
        });

        return this.http.post(url, {}, { headers });
    }

    rejectInvitation(invitationId) {
        const url = `/coreapi/v2/tenantInvitations/${invitationId}`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.delete(url, { headers });
    }

    getInvitations() {
        const url = '/coreapi/v2/tenantInvitations/byEmail';
        return this.http.get(url);
        //let header = { Slug: slug};
        //const headers = new HttpHeaders(header);

        //return this.http.get(url, { headers });
    }

    deleteAdmins(customerId: string, emailsOrIds: any, ownerId: string, slug: string) {

        const url = `/coreapi/v2/tenant/${customerId}/constructorUsers`;
        const headers = new HttpHeaders({
            Slug: slug

        });

        return this.http.request('DELETE', url, {
            headers,
            body: { emailsOrIds, tenantOwnerId: ownerId }
        });

    }

    deleteCustomer(id, slug) {
        const url = `/coreapi/v2/tenant/${id}`;
        const headers = new HttpHeaders({
            Slug: slug,
            'Auth-Type': localStorage.getItem('Auth-Type')
        });
        return this.http.request('DELETE', url, {
            headers
        });
    }


    getAccessInfo(slug: string, channelOwnerId: string) {
        const url = '/coreapi/v2/tenant/accessInfo?channelOwnerId';
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(`${url}=${channelOwnerId}`, { headers });
    }


    // /v1/tenantChannels/byId/
    // const url = `/coreapi/v2/tenantChannels/${customerId}`;
    getChannelCustomerByChannelId(slug: string, channelId: any) {
        const url = `/coreapi/v2/tenant/tenantChannels/${channelId}`;
        const headers = new HttpHeaders({
            Slug: slug

        });

        return this.http.get(`${url}`, { headers });

    }

}
