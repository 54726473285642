<div class="sectionBlacklist">



    <div class="wrapper-title">
        <h1 *ngIf="modeSelected==='BLACKLIST'" class="title">Black List</h1>
        <h1 *ngIf="modeSelected==='WHITELIST'" class="title">White List</h1>

    </div>




    <div class="container-fluid" [ngStyle]="{'margin-top': '0px'}">

        <div [ngStyle]="{'margin-top': '20px'}" class="formGroup">
            <h3 for="">{{translate.sections.blackList.mode}}</h3>
            <select (change)="modList(mod)" class="selectInspectorStyle mt-10" #mod name="" id="">

                <option value="">{{translate.sections.blackList.select}}</option>
                <option [selected]="modeSelected == 'BLACKLIST'" value="BLACKLIST">BLACKLIST</option>
                <option [selected]="modeSelected == 'WHITELIST'" value="WHITELIST">WHITELIST</option>

            </select>

        </div>

        <div class="formGroup">
            <h3 *ngIf="modeSelected==='BLACKLIST'" for="">{{translate.sections.blackList.reportUser}}</h3>
            <h3 *ngIf="modeSelected==='WHITELIST'" for="">{{translate.sections.blackList.authorizedUser}}
            </h3>

            <input id="telUser" placeholder={{translate.sections.blackList.userPhone}} #id class="inputInspectorStyle mt-10" type="text" (change)="onChangeUser()">
            <select #channel class="selectInspectorStyle mt-10" name="" id="" (change)="onChangeChannel()">
                <option [selected]="canal == 'FB_MESSENGER'" [value]="canal" *ngFor="let canal of canales">{{canal}}
                </option>
            </select>

            <!-- <button (click)="save(id,channel)" class="btn-primary mt-10 fr">Agregar</button> -->
            <ul class="actions">

                <li>
                    <button [ngStyle]="{'padding': '0px 5px'}" class="btn-primary  mt-10 fr" (click)="save(id,channel)">
                        <span>{{translate.sections.blackList.add}}</span>
                        <i class="material-icons md-24">add_circle</i>
                    </button>
                </li>
            </ul>
        </div>

        <div class="formGroup">
            <h3 *ngIf="modeSelected==='BLACKLIST'" for="">{{translate.sections.blackList.blockedUsers}}</h3>
            <h3 *ngIf="modeSelected==='WHITELIST'" for="">{{translate.sections.blackList.authorizedUsers}}</h3>


            <!--  test -->

            <section class="md-ui component-data-table">
                <div class="main-table-wrapper">
                    <table class="main-table-content">
                        <thead class="data-table-header">
                            <tr class="data-table-row">
                                <td class="text-bold">ID/{{translate.sections.blackList.telephone}}</td>
                                <td class="text-bold">{{translate.sections.blackList.channel}}</td>

                                <td></td>

                            </tr>
                        </thead>


                        <tbody  class="data-table-content" *ngIf="blockedUsers.length > 0">
                            <tr *ngFor="let blockUser of blockedUsers; let i=index" class="data-table-row">
                                <td>
                                    {{blockUser.channelOwnerId}}
                                </td>
                                <td>
                                    <!-- {{blockUser.channel}} -->
                                    <img class="channel-img" [title]="blockUser.channel" [src]='routeImageChannel+blockUser.channel+".svg"' width="30px" height="30px">
                                </td>
                                <td>

                                    <a class="deleteB" (click)="delete(blockUser.id)">❌</a>
                                </td>
                            </tr>

                        </tbody>


                    </table>
                </div>

            </section>

            <!-- end -->

        </div>



    </div>




</div>
