import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { CustomerService } from '../../../services/customer.service';
import { LoaderService } from '../../../services/loader.service';
import { Customer } from '../../../models/customer';

import { environment } from '../../../environments/environment';
declare var translate: any;
@Component({
    selector: 'app-list-customers',
    templateUrl: './list-customers.component.html',
    styleUrls: ['./list-customers.component.scss']
})
export class ListCustomersComponent implements OnInit {

    currentCustomer: Customer = new Customer();
    customers: Customer[] = [];
    assets = environment.assets;
    loadingCustomers = true;

    customersForm: UntypedFormGroup;
    translate: any;
    constructor(
        private fb: UntypedFormBuilder,
        private customerService: CustomerService,
        private loaderService: LoaderService,
        private router: Router,
        private route: ActivatedRoute
    ) {this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);}

    ngOnInit() {
        this.getCurrentCustomer();
        this.getCustomers();

        this.customersForm = this.fb.group({
            customer: '',
            agree: null
        });
    }

    setImageDefault(e) {
        e.target.src = this.assets + 'img/user_default.svg';
        console.log('error', e.target.src);
    }

    getCustomers() {
        const constructorUserId = window.localStorage.getItem('constructorUserId'); // 'fe196270-1e4e-40f6-82d3-de966190a717';
        this.customerService.getByUserId(
            constructorUserId,
            'true'
        ).subscribe((customers: any) => {
            this.customers = customers.tenants;
            this.loadingCustomers = false;
            // this.loaderService.close();
        });
    }

    getCurrentCustomer() {
        this.getUrlParam('customer_slug', (param) => {
            this.customerService.getBySlug(
                param,
                'true'
            ).subscribe((customer: any) => {
                this.currentCustomer = customer.tenant;
                this.customersForm = this.fb.group({
                    customer: this.currentCustomer.name + '#' + this.currentCustomer.slug,
                    agree: null
                });
                // this.loaderService.close();
            });
        });
    }

    changeCustomer(e) {
        const chunks = e.split('#');
        const slug = chunks[1];
        const redirecTo = ['/customers', slug, 'platforms'];
        // console.log(redirecTo);
        this.router.navigate(redirecTo);
    }

    getUrlParam(param: string, callback) {
        const paramInterval = setInterval(() => {
            if (this.route.firstChild !== null) {
                if (this.route.firstChild.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.route.firstChild.paramMap.subscribe(params => {
                        if (params.has(param)) {
                            const p = params.get(param);
                            if (p != null) {
                                callback(p);
                            }
                        }
                    });
                }
            }
        }, 500);
    }

}
