import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, ActivationStart, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
declare var translate: any;

@Component({
    selector: 'app-steps',
    templateUrl: 'steps.component.html',
    styleUrls: ['steps.component.scss']
})
export class StepsComponent implements OnInit, OnDestroy {
    routeSubscription: Subscription;
    @Input() showSteps: boolean;
    translate: any;
    expand: boolean;
    userName: string;
    newBotProcess: boolean;
    newBot: boolean;
    addPlatform: boolean;
    addChannel: boolean;
    configChannel: boolean;
    showStepsRoute: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.userName = '';
        this.expand = true;
        this.newBotProcess = false;
        this.newBot = false;
        this.addPlatform = false;
        this.addChannel = false;
        this.configChannel = false;
        this.showStepsRoute = false;
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    ngOnInit() {
        this.getUserName();
        this.routeSubscription = this.router.events.subscribe(event => {
            if (event instanceof ActivationStart) {
                if (event.snapshot.data && 'showSteps' in event.snapshot.data && event.snapshot.data.showSteps) {
                    this.showStepsRoute = true;
                } else {
                    this.showStepsRoute = false;
                }

                if (event.snapshot.routeConfig.path === 'customers/create') {
                    this.newBotProcess = true;
                } else {
                    this.newBotProcess = false;
                }
            }
            if (event instanceof NavigationStart) {
                this.getSteps();
            }
        });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    getSteps () {
        // console.log('steps');
        const step1 = localStorage.getItem('step1');
        const step2 = localStorage.getItem('step2');
        const step3 = localStorage.getItem('step3');
        const step4 = localStorage.getItem('step4');

        // console.log(step1);
        this.newBot = step1 === 'true' ? true : false;
        this.expand = !this.newBot;
        this.addPlatform = step2 === 'true' ? true : false;
        this.addChannel = step3 === 'true' ? true : false;
        this.configChannel = step4 === 'true' ? true : false;

        if (this.newBot && this.addPlatform && this.addChannel && this.configChannel) {
            localStorage.setItem('stepsCompleted', 'true');
        }
    }

    getUserName() {
        this.userName = localStorage.getItem('name');
    }

    expandLess() {
        this.expand = true;
    }

    expandMore() {
        this.expand = false;
    }
}
