import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { AlertService } from '../services/alert.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthServices } from '../services/auth.guard.service';
import { ErrorService } from '../services/error.service'


declare var errorCatalog: any;
declare var translate: any;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    dateTiemeError500: any = null;
    dateTiemeError401: any = null;
    translate: any;

    constructor(
        private router: Router,
        private loaderService: LoaderService,
        private alertService: AlertService,
        private authService: AuthServices,
        private errorService: ErrorService,
    ) {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // console.log(request);
            // console.log(err.status);
            // this.loaderService.close();
            // console.log(err);
            if (err.status === 401) {
                console.log('logout interceptor error');

                // TODO: try google reload login

                this.authService.logout();
                const msg = '' + translate.sections.services.sessionExpired + '';

                if (this.dateTiemeError401 === null) {
                    this.dateTiemeError401 = new Date();
                    this.alertService.warning(msg);
                } else {
                    const now = new Date();
                    const diffDatesInSec = (now.getTime() - this.dateTiemeError401.getTime()) / 1000;
                    if (diffDatesInSec > 5) {
                        this.dateTiemeError401 = null;
                        this.alertService.warning(msg);
                    }
                }

                if (!window.location.href.toString().includes('redirect_uri')) {
                    setTimeout(() => {
                        const pathname = window.location.pathname.substring(1);
                        const search = window.location.search;
                        const url = encodeURIComponent(pathname);
                        console.log('Redirect by 401 interceptor error');
                        if (window.location.href === environment.baseUrl) {
                            window.location.href = '/';
                        } else {
                            if ( url != '') {
                                window.location.href = '/?redirect_uri=' + url + search;
                            } else {
                                window.location.href = '/' + search;
                            }
                        }
                    }, 2000);
                }

            } else if (err.status >= 500) {
                let showError = true;
                if (request.headers.has('Show-Error')) {
                    const showErrorVal = request.headers.get('Show-Error');
                    if (showErrorVal === 'false') {
                        showError = false;
                    }
                    request.headers.delete('Show-Error');
                }
                if (showError) {
                    let msg = '' + translate.sections.services.serverError + '';

                    if (
                        err.error.hasOwnProperty('response_body') &&
                        err.error.response_body.hasOwnProperty('exception')
                    ) {
                        const exeptionStr = err.error.response_body.exception.replaceAll('\'', '"');
                        if (this.isJson(exeptionStr)) {
                            const exeptionObj = JSON.parse(exeptionStr);
                            const found = errorCatalog.find(error => error.code === exeptionObj.error_code);

                            for (const key in exeptionObj.params) {
                                if (Object.prototype.hasOwnProperty.call(exeptionObj.params, key)) {
                                    const param = exeptionObj.params[key];
                                    found.template = found.template.replaceAll(`{{${key}}}`, `<strong>${param}</strong>`);
                                }
                            }

                            msg = found.template;

                            // FIXME: with service by code
                            // this.errorService.getByCode(exeptionObj.error_code).subscribe(
                            //     (found: any) => {
                            //         for (const key in exeptionObj.params) {
                            //             if (Object.prototype.hasOwnProperty.call(exeptionObj.params, key)) {
                            //                 const param = exeptionObj.params[key];
                            //                 found.template = found.template.replaceAll(`{{${key}}}`, `<strong>${param}</strong>`);
                            //             }
                            //         }
                            //         msg = found.template;
                            //         console.log(msg);
                            //     },
                            //     err => {
                            //         console.log(err);
                            //     }
                            // );
                        }
                    } else if (err.error.hasOwnProperty('json_response') && err.error.json_response.hasOwnProperty('messages')) {
                        const msgs = err.error.json_response.messages;
                        msg = `<strong>${err.error.json_response.cause}</strong><br>`;
                        msgs.forEach(msg1 => {
                            Object.keys(msg1).forEach(val => {
                                msg += `${val}: ${msg1[val]}<br>`;
                            });
                            msg += '<br>';
                        });
                    }

                    if (this.dateTiemeError500 === null) {
                        this.dateTiemeError500 = new Date();
                        this.alertService.error(msg);
                    } else {
                        const now = new Date();
                        const diffDatesInSec = (now.getTime() - this.dateTiemeError500.getTime()) / 1000;
                        if (diffDatesInSec > 3) {
                            this.dateTiemeError500 = null;
                            this.alertService.error(msg);
                        }
                    }
                }
            } else if (err.status >= 400) {
                let showError = true;
                let showCause = false;
                let errorMessage = '';
                // console.log(request.headers);
                if (request.headers.has('Show-Error')) {
                    const showErrorVal = request.headers.get('Show-Error');
                    if (showErrorVal === 'false') {
                        showError = false;
                    }
                    request.headers.delete('Show-Error');
                }

                if (request.headers.has('Show-Cause')) {
                    const showCauseVal = request.headers.get('Show-Cause');
                    if (showCauseVal === 'true') {
                        showCause = true;
                    }
                    request.headers.delete('Show-Cause');
                }

                if (showError) {
                    // console.log(showCause);
                    // console.log(err.error.json_response.cause);
                    if (showCause) {
                        errorMessage = err.error.json_response.cause;
                    } else {
                        if ('json_response' in err.error && 'message' in err.error.json_response) {
                            errorMessage = err.error.json_response.message;
                        } else {
                            errorMessage = err.error.message;
                            if (
                                'json_response' in err.error &&
                                'error' in err.error.json_response && err.error.json_response.error &&
                                'description' in err.error.json_response.error && err.error.json_response.error.description
                            ) {
                                errorMessage += ' <br>' + err.error.json_response.error.description;
                            }
                        }
                    }

                    if (!(err.status === 404 && 'message' in err.error && err.error.message === 'Data not found')) {
                        this.alertService.error(errorMessage);
                    }
                }
            }

            let error = '';
            if (err.error instanceof ErrorEvent) {
                error = `Error: ${err.error.message}`;
            } else {
                error = `Error Code: ${err.status}, Error Status: ${err.statusText},  Message: ${err.message}`;
            }

            return throwError(() => new Error(error));
        }));
    }
}
