import { Injectable } from '@angular/core';
import { Entity } from 'src/models/entity';
import * as joint from '../../../vendor/rappid';
import { AlertService } from '../alert.service';
import { ModalService } from './modal.service';
declare var translate: any;
// declare var $: any;
declare var lastSelectionPhrase: any;
declare var Sortable: any;

@Injectable({ providedIn: 'root' })
export class NlpFormService {
    translate: any;
    private alertService: AlertService;
    private modal: ModalService;
    private modal2: ModalService;
    fullEntities = [];

    constructor() {
        this.modal = new ModalService();
        this.modal2 = new ModalService();
        this.phraseInitialize();
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    private phraseInitialize() {
        lastSelectionPhrase = {
            parent: null,
            selection: null,
            node: null,
            start: null,
            end: null,
            highlight: null,
            type: null
        }
    }

    private visualLength(html) {
        var ruler = $(".ruler");
        ruler.html(html);
        return ruler.outerWidth();
    }

    private createResponseNlpString(intentions) {
        const responseNlp = {
            intentions,
            type: 'NLPForm',
            showOutputs: true
        };

        return JSON.stringify(responseNlp);
    }

    private getMemories(field, memories) {
        const memoriesObj = [];
        const memoriesObjOrdered = [];
        let slots = [];
        let slotsAux = [];
        const value = field.val();

        if (value) {
            const phrase = JSON.parse(value);
            if (phrase.hasOwnProperty('intentions')){
                slots = phrase.intentions[0].slots;
            }
        }

        for (let index = 0; index < memories.length; index++) {
            let id = joint.util.uuid();
            const memory = memories[index];
            for (let index = 0; index < slots.length; index++) {
                const slot = slots[index];
                if (slot.slotName === memory) {
                    id = slot.id;
                }
            }

            if (!slotsAux.includes(memory)) {
                slotsAux.push(memory);
                memoriesObj.push({order: index + 1, slotName: memory, id});
            }
        }
        const memoriesOrdered = $('.memories-list').children();
        const size = memoriesOrdered.length;
        memoriesOrdered.each((index2, element) => {
            for (let index = 0; index < memoriesObj.length; index++) {
                const memory2 = memoriesObj[index];
                if (memory2.slotName === $(element).text().replace('drag_indicator ', '')) {
                    memory2.order = index2 + 1;
                    memoriesObjOrdered.push(memory2);
                }
            }
        });

        let id = joint.util.uuid();
        if (value) {
            const lastSlot = slots.slice(-1)[0];
            if (lastSlot.slotName === 'End') {
                id = lastSlot.id;
            }
        }

        const endSlot = {
            id,
            order: size + 1,
            slotName: 'End'
        };

        memoriesObjOrdered.push(endSlot);

        return memoriesObjOrdered;
    }

    private saveModalEvent(saveBtn, field, modal) {
        saveBtn.on('click', (event) => {
            const nameIntention = $(event.target).parent().parent().find('.name-intention');
            const examplesIntention = $(event.target).parent().parent().find('.examples-list-box')[0].innerHTML;
            const examples = this.htmlToArrayExamples(examplesIntention);
            const entities = this.getEntitiesFromStr(examples.join(','), 'entity');
            const memories = this.getEntitiesFromStr(examples.join(','), 'slot');
            const memoriesObj = this.getMemories(field, memories);

            const intention = {
                name: nameIntention.val(),
                ejemplos: examples,
                entidades: entities,
                slots: memoriesObj
            };

            const responseNlpStr = this.createResponseNlpString([intention]);
            field.val(responseNlpStr);

            modal.hide();
        });
    }

    private cancelModalEvent(cancelBtn, modal) {
        cancelBtn.on('click', () => {
            modal.hide();
            this.phraseInitialize();
        });
    }

    private getTextOffset(parent, index) {
        if (parent.nodeType === 3)
            return {'node': parent, 'offset': index};
        var e = parent.childNodes[0], i = index, prev = null;
        while (1) {
            while (e.nodeType !== 3 || e === prev)
                if (e.childNodes && e.childNodes.length)
                    e = e.childNodes[0];
                else if (e.nextSibling)
                    e = e.nextSibling;
                else {
                    while (!e.nextSibling)
                        if (!e.parentNode || e.parentNode === parent)
                            throw RangeError('Index out of range');
                        else
                            e = e.parentNode;
                    e = e.nextSibling;
                }
            if (e.data.length < i)
                i -= e.data.length, prev = e;
            else
                return {'node': e, 'offset': i};
        }
    }

    private highlight(node, start, end, type) {
        const id = joint.util.uuid();
        var r, o, hi;
        r = document.createRange()
        o = this.getTextOffset(node, start);
        r.setStart(o.node, o.offset);
        o = this.getTextOffset(node, end);
        r.setEnd(o.node, o.offset);
        hi = document.createElement('span');
        hi.classList.add(type + '-chip');
        hi.id = id;
        hi.contentEditable = false;
        hi.appendChild(r.extractContents());
        r.insertNode(hi);
        r.detach();

        const dataWordAtt = document.createAttribute("data-word");
        dataWordAtt.value = hi.innerText;
        hi.setAttributeNode(dataWordAtt);
        lastSelectionPhrase.highlight = hi;
        lastSelectionPhrase.type = type;
    }

    private htmlToArrayExamples(htmlExamples) {
        const re = /<span[^>]*>(.*?)<\/span>/g;
        const matches = [...(htmlExamples.matchAll(re))];
        for (let index = 0; index < matches.length; index++) {
            const match = matches[index];
            const tempSpan = $(match[0]);
            const entity = tempSpan.data('entity') || tempSpan.data('slot');
            const word = tempSpan.data('word');
            htmlExamples = htmlExamples.replace(match[0], `[${word}](${entity})`);
        }
        if (htmlExamples.indexOf('<p') >= 0) {
            htmlExamples = htmlExamples.replace(/<p[^>]*>/g, '\n<p>');
            htmlExamples = htmlExamples.replace(/<p[^>]*><\/p>/g, '');
            htmlExamples = htmlExamples.replace(/<p[^>]*>/g, '');
            htmlExamples = htmlExamples.replaceAll('<\/p>', '\n');
        } else {
            htmlExamples += '\n';
        }
        // htmlExamples = htmlExamples.replace(/<.*[^>]*>/g, '').replace(/<\/.*>/g, '').replace(/<br ?\/?>/g, '');

        const examples =  htmlExamples.split('\n');
        const regex = /(\[[^\[]+\])$|(\[[^\[]+\])[^\(]|(\[[^\[]+\]\(\))|(\[\]\(@[^\)]+\))|[^\]](\(@[^\)]+\))|^(\(@[^\)]+\))/g;
        const ex = examples.map((item) => {
            let str = item.replace(/<.*[^>]*>(.*)<\/.*[^>]*>/g, '$1').replace(/<br?\/?>/g, '').replace('&nbsp;', ' ');
            let m;

            while ((m = regex.exec(str)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                m.forEach((match, groupIndex) => {
                    if (match) {
                        str = str.replace(match, '');
                    }
                });
            }
            return str;
        });
        return ex.filter((item)=>item);
    }

    private arrayToHtmlExamples(examplesArr) {
        let result = '';
        const re = /\[([^\]]*)\]\((@[^\)]*)\)/g;
        const re2 = /\[([^\]]*)\]\(('[^\)]*)\)/g;
        for (let index = 0; index < examplesArr.length; index++) {
            let example = examplesArr[index];
            const matches = [...(example.matchAll(re))];
            for (let index = 0; index < matches.length; index++) {
                const id = joint.util.uuid();
                const match = matches[index][0];
                const word = matches[index][1]
                const entity = matches[index][2];
                example = example.replace(match, `<span class="entity-chip" id="${id}" data-word="${word}" data-entity="${entity}" title="${word} [${entity}]" contenteditable="false">${word}: ${entity}</span>`);
            }
            const matches2 = [...(example.matchAll(re2))];
            for (let index = 0; index < matches2.length; index++) {
                const id = joint.util.uuid();
                const match = matches2[index][0];
                const word = matches2[index][1]
                const entity = matches2[index][2];
                example = example.replace(match, `<span class="slot-chip" id="${id}" data-word="${word}" data-slot="${entity}" title="${word} [${entity}]" contenteditable="false">${word}: ${entity}</span>`);
            }
            result += `<p>${example}</p>`;
        }
        return result;
    }

    private getEntitiesFromStr(str, type) {
        let re = /\((@[^\)]*)\)/g;
        if (type === 'slot') {
            re = /\(('[^\)]*)\)/g;
        }
        const matches = [...(str.matchAll(re))];
        const entities = [];
        for (let index = 0; index < matches.length; index++) {
            const match = matches[index][1].replace('(', '').replace(')', '');
            const res = entities.find(entityAux => entityAux === match);
            if (!res) {
                if (type === 'slot') {
                    entities.push(match.replaceAll('\'slot\': \'', '').replaceAll('\'', ''));
                } else {
                    entities.push(match);
                }
            }
        }
        return entities;
    }

    private showOrderedMemories(examplesModalMemoriesBox, slots=null) {
        if (examplesModalMemoriesBox) {
            examplesModalMemoriesBox.empty();
            const examplesIntention = examplesModalMemoriesBox.parents('.add-intention').find('.examples-list-box')[0].innerHTML;
            const examples = this.htmlToArrayExamples(examplesIntention);
            let memories = this.getEntitiesFromStr(examples.join(','), 'slot');

            if (slots) {
                memories = slots;
            }

            const examplesModalSlotsText = $('<p>' + translate.sections.services.orderMemory + '');
            const examplesModalFieldBox = $('<ul class="memories-list" />');
            const slotsAux = [];

            for (let index = 0; index < memories.length; index++) {
                let memory = memories[index];
                let end = false;
                if (slots) {
                    memory = memories[index].slotName;
                    if (memory === 'End') {
                        end = true;
                    }
                }
                if (!slotsAux.includes(memory) && !end) {
                    slotsAux.push(memory);
                    const examplesModalSlot = $(`<li class="slot-item"><i class="material-icons">drag_indicator</i> ${memory}</li>`);
                    examplesModalFieldBox.append(examplesModalSlot);
                }
            }

            examplesModalMemoriesBox.append([examplesModalSlotsText, examplesModalFieldBox]);

            Sortable.create(examplesModalFieldBox.get(0));
        }
    }

    private selectEntityEvent(entityItem, entity, type, field, examplesModalMemoriesBox) {
        entityItem.on('click', (event)=> {
            console.log(type);
            this.highlight(lastSelectionPhrase.node, lastSelectionPhrase.start, lastSelectionPhrase.end, type);
            const dataEntityAtt = document.createAttribute("data-" + type);
            if (type === 'slot') {
                dataEntityAtt.value = `'slot': '${entity}'`;
            } else {
                dataEntityAtt.value = entity;
            }
            lastSelectionPhrase.highlight.setAttributeNode(dataEntityAtt);
            lastSelectionPhrase.highlight.innerText += ': ' + entity
            this.showOrderedMemories(examplesModalMemoriesBox);

            $(event.target).parent().parent().parent().remove();
            this.phraseInitialize();
        });
    }

    private cancelNewEntityEvent(entityCancelBtn, modal2) {
        entityCancelBtn.on('click', (e) => {
            modal2.hide();
        });
    }
    private saveNewEntityEvent(entitySaveBtn, modal2, type, field, examplesModalMemoriesBox) {
        entitySaveBtn.on('click', (e) => {
            const fullBox = $(e.target).parents('.new-entity-box');
            let entityName = fullBox.find('.entity-name').val().toString();
            if (entityName.indexOf('@') !== 0) {
                entityName = '@' + entityName;
            }
            const entity: Entity = {
                id: joint.util.uuid(),
                editing: false,
                entityType: '',
                groups: [],
                groupsTxt: '',
                main: false,
                name: entityName,
                regex: [],
                regexTxt: '',
                state: 'trained',
                type: type
            };
            const entitiesStr = $('.nlp_entities').val().toString();
            let entities = [];
            if (entitiesStr) {
                entities = JSON.parse(entitiesStr);
                entities.push(entity);
            }
            this.fullEntities.push(entity.name);
            $('.nlp_entities').val(JSON.stringify(entities));
            $('.save_diagram').trigger( "click" );
            $('.entities-list').empty();
            this.setEntities($('.entities-list'), type, field, examplesModalMemoriesBox);
            modal2.hide();
        });
    }

    private createNewEntityBox(modal2, type, field, examplesModalMemoriesBox) {
        $('.new-entity-box').remove();
        const entityBox = $('<div class="new-entity-box" />');
        const entityTitle = $('<h2 class="title">' + translate.sections.services.newEntity + '</h2>');
        const entityFullBox = $('<div class="full" />');
        const entityFieldBox1 = $('<div class="field-box" />');
        const entityNameLabel = $('<label for="">' + translate.name + '</label>');
        const entityNameInput = $('<input type="text" class="entity-name" />');
        const entityActionsBox = $('<div class="modal-actions" />');
        const entitySaveBtn = $('<button class="btn-primary">' + translate.sections.services.saveEntity + '</button>');
        const entityCancelBtn = $('<button class="btn-secondary">' + translate.cancel + '</button>');

        this.saveNewEntityEvent(entitySaveBtn, modal2, type, field, examplesModalMemoriesBox);
        this.cancelNewEntityEvent(entityCancelBtn, modal2);

        entityActionsBox.append([entityCancelBtn, entitySaveBtn]);
        entityFieldBox1.append([entityNameLabel, entityNameInput]);
        entityFullBox.append(entityFieldBox1);
        entityBox.append([entityTitle, entityFullBox, entityActionsBox]);

        modal2.box.append(entityBox);
        modal2.box.css({
            'width': '440px',
            'zIndex': '7',
            'maxHeight': 'initial'
        });
        modal2.blocker.css('zIndex', '6');
        modal2.show();
    }

    private newEntityEvent(entityNewAction, modal2, type, field, examplesModalMemoriesBox) {
        entityNewAction.on('click', (e) => {
            this.createNewEntityBox(modal2, type, field, examplesModalMemoriesBox);
        });
    }

    private getEntities(type, field) {
        let allEntities = [];
        let entities = [];
        const entitiesStr = $('.nlp_entities').val().toString();
        const value = field.val();

        if(entitiesStr) {
            allEntities = JSON.parse(entitiesStr);
            for (let index = 0; index < allEntities.length; index++) {
                const entity: Entity = allEntities[index];
                if (entity.type === type) {
                    entities.push(entity.name);
                }
            }
        }

        if (value) {
            const phrase = JSON.parse(value);
            if (type === 'entity') {
                const entities2 = phrase.intentions[0].entidades;
                entities = [...new Set([...entities, ...entities2])];
                this.fullEntities = entities;
            } else if (type === 'slot') {
                if (phrase.intentions[0].slots) {
                    let entities2 = phrase.intentions[0].slots;
                    const slots = [];
                    for (let index = 0; index < entities2.length; index++) {
                        slots.push(entities2[index].slotName);
                    }
                    entities2 = [...new Set([...entities2, ...slots])];
                }
            }

        }

        return entities;
    }

    private setEntities(entitiesList, type, field, examplesModalMemoriesBox) {
        this.fullEntities.forEach(entity => {
            const entityItem = $(`<li class="entity-item">${entity}</li>`);
            this.selectEntityEvent(entityItem, entity, type, field ,examplesModalMemoriesBox);
            entitiesList.append(entityItem);
        });
    }

    private cancelEntityEvent(entityCancelAction) {
        entityCancelAction.on('click', (event) => {
            $(event.target).parents('.example-options-box').remove();
        });
    }

    private manageModalEntities(textarea, field, type, modal2, examplesModalMemoriesBox) {
        $('.example-options-box').remove();
        const fieldBox = textarea.parent();
        const exampleOptionsBox = $('<div class="example-options-box" />');
        const optionSelect = $('<select class="example-options"><option value="entity">' + translate.sections.services.entity + '</option><option value="slot">Memoria</option></select>');
        const entitiesBox = $('<div class="entities-box" />');
        const entitiesList = $('<ul class="entities-list" />');
        const entityActions = $('<div class="actions" />');
        const entityCancelAction = $('<button class="btn-secondary" />');
        const entityCancelText = $('<span>' + translate.cancel + '</span>');
        const entityNewAction = $('<button class="btn-primary" />');
        const entityNewText = $('<span>+ ' + translate.sections.services.entities + '</span>');

        this.fullEntities = this.getEntities(type, field);
        this.setEntities(entitiesList, type, field, examplesModalMemoriesBox);
        this.cancelEntityEvent(entityCancelAction);
        this.newEntityEvent(entityNewAction, modal2, type, field, examplesModalMemoriesBox);

        optionSelect.val(type);

        entityCancelAction.append(entityCancelText);
        entityNewAction.append(entityNewText);
        entityActions.append([entityCancelAction, entityNewAction]);
        entitiesBox.append([entitiesList, entityActions]);
        exampleOptionsBox.append([optionSelect, entitiesBox]);

        fieldBox.append(exampleOptionsBox);
    }

    private setPositionModal(textarea, type, field, modal2, examplesModalMemoriesBox) {
        const selection = lastSelectionPhrase.selection;
        const positionKey = selection.anchorOffset;
        // const text = this.markupToString(textarea.html().toString());
        const text = selection.anchorNode.textContent;
        if (
            positionKey === 0 ||
            text.slice(positionKey - 1, positionKey) === ' ' ||
            text.slice(positionKey - 1, positionKey) === ' '
        ) {
            let position = $(selection.anchorNode.parentElement).offset();
            const visulaLengthAtSign = this.visualLength(text.slice(0, positionKey));
            const left = position.left - (($(document).outerWidth() - $('.modal2').outerWidth()) / 2);
            const top = position.top - ($(document).outerHeight() - $('.modal2').outerHeight()) / 2;

            this.manageModalEntities(textarea, field, type, modal2, examplesModalMemoriesBox);
            $('.example-options-box').css({
                top: top - 232 + 40,
                left: left + visulaLengthAtSign - 48 -104
            });
        }
    }

    private selectElementContents(el) {
        const sel = window.getSelection();
        const range = sel.getRangeAt(0);
        if (range.startOffset !== range.endOffset) {
            lastSelectionPhrase = {
                parent: el,
                selection: sel,
                node: sel.focusNode,
                start: range.startOffset,
                end: range.endOffset
            };
        }
    }

    private examplesSelectionEvent(textarea) {

        // textarea.on('mousedown', (e) => {
        //     this.selectElementContents(textarea.get(0));
        // });

        textarea.on('mouseup', (e) => {
            this.selectElementContents(textarea.get(0));
        });
    }

    private addEntitySlotEvent(btn, type, field, modal2, examplesModalMemoriesBox=null) {
        btn.on('click', (e) => {
            // this.highlight(lastSelectionPhrase.node, lastSelectionPhrase.start, lastSelectionPhrase.end, type);
            this.setPositionModal($(lastSelectionPhrase.parent), type, field, modal2, examplesModalMemoriesBox);
        });
    }

    private manageModalAddIntention(modal, modal2, field) {
        const examplesModalBox = $('<div class="add-intention" />');
        const examplesModalTitle = $('<h2 class="title">' + translate.sections.services.phrase + '</h2>');
        const examplesModalFullBox = $('<div class="full" />');
        const examplesModalFieldBox = $('<div class="field-box" />');
        const examplesModalNameLabel = $('<label for="">' + translate.name + '</label>');
        const examplesModalNameInput = $('<input type="text" class="name-intention" />');
        const examplesModalFieldBox2 = $('<div class="field-box" />');
        const examplesModalExamplesLabel = $('<label for="">' + translate.sections.services.examples + '</label>');
        const examplesModalExamplesEntityBtn = $('<button class="btn-base entity">+ ' + translate.sections.services.entities + '</button>');
        const examplesModalExamplesSlotBtn = $('<button class="btn-base slot">+ ' + translate.sections.services.memory + '</button>');
        const examplesModalTextareaBox = $('<div contenteditable="true" class="examples-list-box" />');
        const examplesModalMemoriesBox = $('<div class="memories" />');
        const examplesModalActionsBox = $('<div class="modal-actions" />');
        const examplesModalCancelBtn = $('<button class="btn-secondary">' + translate.cancel+ '</button>');
        const examplesModalSaveBtn = $('<button type="submit" class="btn-primary">' + translate.sections.services.save + ' frase</button>');

        this.cancelModalEvent(examplesModalCancelBtn, modal);
        this.saveModalEvent(examplesModalSaveBtn, field, modal);

        const value = field.val();
        let intention;

        if (value) {
            const phrase = JSON.parse(value);
            intention = phrase.intentions[0];
            examplesModalNameInput.val(intention.name);
            const examplesHtml = this.arrayToHtmlExamples(intention.ejemplos);
            examplesModalTextareaBox.html(examplesHtml);
        }

        examplesModalActionsBox.append([examplesModalCancelBtn, examplesModalSaveBtn]);
        examplesModalFieldBox.append([examplesModalNameLabel, examplesModalNameInput]);

        this.examplesSelectionEvent(examplesModalTextareaBox);
        document.execCommand("DefaultParagraphSeparator", false, "p");

        examplesModalTextareaBox.on('focusout', (e) => {
            this.showOrderedMemories(examplesModalMemoriesBox);
        });


        this.addEntitySlotEvent(examplesModalExamplesEntityBtn, 'entity', field, modal2);
        this.addEntitySlotEvent(examplesModalExamplesSlotBtn, 'slot', field, modal2, examplesModalMemoriesBox);

        examplesModalFieldBox2.append([
            examplesModalExamplesLabel,
            examplesModalExamplesEntityBtn,
            examplesModalExamplesSlotBtn,
            examplesModalTextareaBox
        ]);

        examplesModalFullBox.append([examplesModalFieldBox, examplesModalFieldBox2]);
        examplesModalBox.append([examplesModalTitle, examplesModalFullBox, examplesModalMemoriesBox, examplesModalActionsBox]);

        if (intention) {
            this.showOrderedMemories(examplesModalMemoriesBox, intention.slots);
        }

        modal.box.append(examplesModalBox);
        modal.box.css('width', '540px');
        modal.show();
    }

    addIntentionEvent() {

    }

    getNlpForm(field) {
        const id = joint.util.uuid();
        const containerDiv = $('<div class="intentions" />');
        const contentsDiv = $('<div class="content-intentions" />');
        const intentionBox = $(`<div id="${id}" class="intention" />`);
        const intentionBtn = $(`<button class="btn-secondary"><span>` + translate.sections.services.setPhrase + `</span></button>`);
        const ruler = $('<span class="ruler"></span>');
        const modal = this.modal.create({
            onClose: ()=> {
                modal.box.parent().find('.add-intention').remove();
            }
        });
        const modal2 = this.modal2.create({});

        intentionBox.append(intentionBtn);

        intentionBtn.on('click', (eIntentionBtn) => {
            this.manageModalAddIntention(modal, modal2, field);
        });

        $('body').append([modal.box, modal.blocker, modal2.box, modal2.blocker]);
        contentsDiv.append(intentionBox);

        const nameClass = field.attr('name').replace('#', '-');
        containerDiv.addClass(nameClass);
        containerDiv.append([ruler, contentsDiv, field]);

        return containerDiv;
    }
}
