import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EcommerceService {

  constructor(private http: HttpClient) { }



  get(slug: string) {
    const url = '/coreapi/v2/ecommerce/active';
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.get(url, { headers });
  }


  // https://flux-pre.gus.chat/api/v1/ecommerce/0035aee8-67e4-463e-88db-75d181901cae
  getOne(slug, id) {
    const url = `/coreapi/v2/ecommerce/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.get(url, { headers });

  }

  // https://flux-pre.gus.chat/api/v1/ecommerceTypes
  //types(slug) {

  //  const url = '/coreapi/v2/ecommerceTypes';
  //  const headers = new HttpHeaders({
  //    Slug: slug
  //  });
  //  return this.http.get(url, { headers });

  //}


  // https://flux-pre.gus.chat/api/v1/ecommerce

  add(slug: any, ecommerce: any) {
    const url = '/coreapi/v2/ecommerce';
    const headers = new HttpHeaders({
      Slug: slug,
      'Content-Type': 'application/json'
    });
    return this.http.post(url, ecommerce, { headers });

  }



  // https://flux-pre.gus.chat/api/v1/ecommerce/14374f24-c7dd-4e20-9bba-99a86d0a33b6


  activate(slug: any, id: any, ecommerce) {
    const url = `/coreapi/v2/ecommerce/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.patch(url, ecommerce, { headers });
  }

  enable(slug: any, id: any) {
    const url = `/coreapi/v2/ecommerce/enable/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.post(url, {}, { headers });
  }


  // https://flux-pre.gus.chat/api/v1/ecommerce/0035aee8-67e4-463e-88db-75d181901cae
  update(slug, id, ecommerce) {

    const url = `/coreapi/v2/ecommerce/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.patch(url, ecommerce, { headers });

  }


  delete(id: any, slug: any, ecommerce) {

    const url = `/coreapi/v2/ecommerce/${id}`;
    const headers = new HttpHeaders({
      Slug: slug
    });
    return this.http.patch(url, ecommerce, { headers });


  }

}
