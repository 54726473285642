import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ExporterService {

  constructor(private http:HttpClient) { }

  create(data: any, slug: any) {
    const url = '/coreapi/v2/constructor/exporter';

    const headers = new HttpHeaders({
        Slug: slug,
        'Content-Type': 'application/json'
    });
    return this.http.post(url, data, { headers });
}

}
