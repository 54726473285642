import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, ActivationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { Breadcrumb } from '../../models/breadcrumb';
declare var translate: any;

@Component({ selector: 'app-breadcrumb', templateUrl: 'breadcrumb.component.html' })
export class BreadcrumbComponent implements OnInit, OnDestroy {
    breadcrumbs: Breadcrumb[] = [];
    routeSubscription: Subscription;
    translate: any;
    @Input() existsDiagram: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {

        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    ngOnInit() {
        this.routeSubscription = this.router.events.subscribe(event => {
            if (event instanceof ActivationStart) {
                if (event.snapshot.data && 'breadcrumbs' in event.snapshot.data) {
                    const bs = event.snapshot.data.breadcrumbs;
                    this.breadcrumbs = JSON.parse(JSON.stringify(bs)) as Breadcrumb[];
                    // console.log(event.snapshot.data.breadcrumbs);
                    this.replaceVars();
                    // console.log(this.breadcrumbs);
                }
            }
        });
    }

    replaceVars() {
        if (this.breadcrumbs){
            for (let index = 0; index < this.breadcrumbs.length; index++) {
                const breadcrumb = this.breadcrumbs[index];
                // console.log(breadcrumb);
                if (breadcrumb.link){
                    for (let index2 = 0; index2 < breadcrumb.link.length; index2++) {
                        const link = breadcrumb.link[index2];
                        // console.log(link);
                        if (link.indexOf(':') === 0) {
                            this.getUrlParam(link.replace(':', ''), (varValue) => {
                                // console.log('%c cambio el slug', 'background: orange; color white;');
                                // console.log(varValue);
                                this.breadcrumbs[index.toString()].link[index2.toString()] = varValue;
                            });
                        }
                    }
                }
            }
        }
    }

    isCurrent(breadcrumb: Breadcrumb) {
        return breadcrumb.link === null ? true : false;
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    getUrlParam(param: string, callback) {
        const paramInterval = setInterval(() => {
            if (this.route.firstChild !== null) {
                if (this.route.firstChild.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.route.firstChild.paramMap.subscribe(params => {
                        if (params.has(param)) {
                            const p = params.get(param);
                            if (p != null) {
                                callback(p);
                            }
                        }
                    });
                }
            }
        }, 500);
    }
}
