<section *ngIf="configData.tenant" class="configCostumers">
    <p class="title">{{translate.sections.customers.configCustomers.customerSettings}}</p>

    <div class="divContainer">
        <label for="">ticketLife</label>
        <input value="{{configData.tenant.ticketLife == '' ? '' : configData.tenant.ticketLife}}" #ticketLife
            class="input-form" type="text" placeholder="ticketLife">
    </div>

    <div class="divContainer">
        <label for="">listMode</label>
        <input value="{{configData.tenant.listMode == '' ? '' : configData.tenant.listMode}}" #listMode
            class="input-form" type="text" placeholder="listMode">
    </div>

    <div class="divContainer">
        <label for="">loadConversations</label>
        <input value="{{configData.tenant.loadConversations}}" #loadConversations class="input-form" type="text"
            placeholder="loadConversations">
    </div>

    <div class="divContainer">
        <label for="">errorCount</label>
        <input value="{{configData.tenant.errorCount}}" #errorCount class="input-form" type="text"
            placeholder="errorCount">
    </div>

    <div class="divContainer">
        <label for="">errorCountWindowTime</label>
        <input value="{{configData.tenant.errorCountWindowTime}}" #errorCountWindowTime class="input-form" type="text"
            placeholder="errorCountWindowTime">
    </div>

    <div class="divContainer">
        <label for="">circuitBreakWindowTime</label>
        <input value="{{configData.tenant.circuitBreakWindowTime}}" #circuitBreakWindowTime class="input-form"
            type="text" placeholder="circuitBreakWindowTime">
    </div>

    <div class="divContainer">
        <label for="">speechToText</label>
        <input value="{{configData.tenant.speechToText}}" #speechToText class="input-form" type="text"
            placeholder="speechToText">
    </div>

    <div class="divContainer">
        <label for="">transactionEnabled</label>
        <input value="{{configData.tenant.transactionEnabled}}" #transactionEnabled class="input-form" type="text"
            placeholder="transactionEnabled">
    </div>

    <div class="divContainer">
        <label for="">transactionWaitTime</label>
        <input value="{{configData.tenant.transactionWaitTime}}" #transactionWaitTime class="input-form" type="text"
            placeholder="transactionWaitTime">
    </div>

    

    <div class="divContainer">
        <label for="">countryCode</label>
        <input value="{{configData.tenant.countryCode}}" #countryCode class="input-form" type="text"
            placeholder="countryCode">
    </div>


    <button
        (click)="saveConfig(ticketLife,listMode,loadConversations,errorCount,errorCountWindowTime,circuitBreakWindowTime,speechToText,transactionEnabled,transactionWaitTime,countryCode)"
        class="btn-primary">{{translate.sections.customers.configCustomers.accept}}</button>


</section>