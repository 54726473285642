<section id="sectionlogin">
    <div class="absolute-center">
        <h1>{{translate['bienvenidoaFlux']}}</h1>
        <h4>{{translate['hagamosConversaciones']}}</h4>

        <div class="whitebox">
            <h2>{{translate['iniciarSesion']}}</h2>
            <div id="google-sign-in" class="googleBtn"></div>
            <!-- <button id="buttonGoogle" (click)="singInGoogle()">
                <span class="icon google"></span>
                <span>{{translate['iniciarSesionConGoogle']}}</span>
            </button> -->
            <button id="buttonFacebook" (click)="singInFacebook()">
                <span class="icon facebook"></span>
                <span>{{translate['iniciarSesionConFacebook']}}</span>
            </button>
            <button id="buttonMicrosoft" (click)="singInMicrosoft()">
                <span class="icon microsoft"></span>
                <span>{{translate['iniciarSesionConMicrosoft']}}</span>
            </button>
            <button id="buttonSSO" (click)="redirectSSO()">
                <span>{{translate['iniciarSesionConSSO']}}</span>
            </button>
            <p class="power">
                {{translate['aunNoDescubres']}}
            </p>
            <button class="btn-secondary btn-demo" (click)="toDemo()">
                <span>{{translate['tengamosDemo']}}!</span>
            </button>
        </div>
    </div>
</section>
