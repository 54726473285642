import { Injectable } from '@angular/core';
import { AlertService } from '../alert.service';
import { FileService } from '../file.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
// import { ModalService } from './modal.service';

@Injectable({
    providedIn: 'root'
})
export class MultimediaService {
    private alertService: AlertService;
    // private modal: ModalService;

    private customerSlug: string;
    private field: any;
    private maxSize: number;

    private allowedMimeTypes: any;
    private fileService: FileService;
    private recaptchaV3Service: ReCaptchaV3Service;

    constructor(customerSlug, alert, fileService, recaptchaV3Service) {
        // this.modal = new ModalService();
        this.recaptchaV3Service = recaptchaV3Service;
        this.fileService = fileService;
        this.customerSlug = customerSlug;
        this.alertService = alert;
        this.maxSize = 16000000; // 16MB
        this.allowedMimeTypes = {
            image: [
                'image/jpeg',
                'image/png'
            ],
            sticker: [
                'image/webp'
            ],
            video: [
                'video/mp4',
                'video/3gpp'
            ],
            audio: [
                'audio/aac',
                'audio/mp4',
                'audio/amr',
                'audio/mpeg',
                'audio/ogg',
                'audio/opus'
            ]
        }
    }

    dropHandler(event, previewImage, previewVideo, previewLink, previewLoaderImage, previewAudio, dropZoneDiv, previewDiv, acceptedFiles, constraints, chunkText) {
        event.preventDefault();
        const ev = event.originalEvent;

        if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    const file = ev.dataTransfer.items[i].getAsFile();
                    // console.log(file);
                    let accepted: any = true;
                    if (constraints[0].key !== 'document'){
                        if (acceptedFiles.find(element => element == file.type) === undefined) {
                            accepted = false;
                        }
                    }

                    if (accepted){
                        // 16MB maximo
                        if (file.size <= this.maxSize) {
                            previewLoaderImage.fadeIn();
                            dropZoneDiv.fadeOut();
                            previewDiv.fadeIn();
                            previewLink.text(file.name);
                            previewLink.removeAttr('href');
                            const type = this.getTypeFileByMimeType(file.type);
                            this.uploadFile(file, previewImage, previewVideo, previewLoaderImage, previewAudio, previewLink, dropZoneDiv, previewDiv, type);
                        } else {
                            this.removeDragData(ev);
                            this.alertService.error(`El archivo debe pesar menos de 16MB`);
                        }
                    } else {
                        this.removeDragData(ev);
                        this.alertService.error(`El archivo seleccionado no es ${chunkText}`);
                    }
                }
            }
        } else {
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                let accepted: any = true;
                if (constraints[0].key !== 'document'){
                    // console.log(ev.dataTransfer.files[i]);
                    if (acceptedFiles.find(element => element == ev.dataTransfer.files[i].type) === undefined) {
                        accepted = false;
                    }
                }

                if (accepted){
                    if (ev.dataTransfer.files[i].size <= this.maxSize) {
                        previewLoaderImage.fadeIn();
                        dropZoneDiv.fadeOut();
                        previewDiv.fadeIn();
                        previewLink.text(ev.dataTransfer.files[i].name);
                        previewLink.removeAttr('href');
                        const type = this.getTypeFileByMimeType(ev.dataTransfer.files[i].type);
                        this.uploadFile(ev.dataTransfer.files[i], previewImage, previewVideo, previewLoaderImage, previewAudio, previewLink, dropZoneDiv, previewDiv, type);
                    } else {
                        this.removeDragData(ev);
                        this.alertService.error(`El archivo debe pesar menos de 16MB`);
                    }
                } else {
                    this.removeDragData(ev);
                    this.alertService.error(`El archivo seleccionado no es ${chunkText}`);
                }
            }
        }
        // this.removeDragData(ev)
    }

    dragOverHandler(ev) {
        ev.preventDefault();
    }

    removeDragData(ev) {
        if (ev.dataTransfer.items) {
            ev.dataTransfer.items.clear();
        } else {
            ev.dataTransfer.clearData();
        }
    }

    removeFile(previewImage, previewVideo, previewAudio, previewLink, dropZoneDiv, previewDiv) {
        previewImage.removeAttr('src');
        previewLink.text('');
        previewLink.removeAttr('href');
        previewVideo.removeAttr('src');
        previewImage.hide();
        previewVideo.hide();
        previewAudio.removeAttr('src');
        previewAudio.hide();
        previewDiv.fadeOut();
        dropZoneDiv.fadeIn();
        this.field.val('');
    }

    uploadFile(file, previewImage, previewVideo, previewLoaderImage, previewAudio, previewLink, dropZoneDiv, previewDiv, type) {
        previewImage.hide();
        previewVideo.hide();
        previewAudio.hide();

        const formFiles = new FormData();
        formFiles.append('document', file);
        this.recaptchaV3Service.execute('uploadFile').subscribe((reToken) => {
            this.fileService.upload(this.customerSlug, formFiles, reToken).subscribe({
                next: (response: any) => {
                    if (response.url) {
                        if (type.includes('image')) {
                            previewImage.attr('src', response.url);
                            previewImage.show();
                        } else if (type.includes('video')) {
                            previewVideo.attr('src', response.url);
                            previewVideo.show();
                        } else if (type.includes('audio')) {
                            previewAudio.attr('src', response.url);
                            previewAudio.show();
                        }
                        previewLoaderImage.fadeOut();
                        const responseField = {
                            url: response.url,
                            type
                        };
                        // console.log(responseField);
                        this.field.val(JSON.stringify(responseField));
                    } else {
                        this.alertService.error('No se permite subir este formato de archivo, pruebe con otro');
                        this.removeFile(previewImage, previewVideo, previewAudio, previewLink, dropZoneDiv, previewDiv);
                    }
                },
                error: (err) => {
                    this.alertService.error('Hubo un problema al subir el documento.');
                    console.error('Error:', err);
                }
            });
        });
    }

    getAcceptedFiles(constraints) {
        const acceptedFiles = [];

        constraints.forEach(constaint => {
            for (const typeName in this.allowedMimeTypes) {
                if (Object.prototype.hasOwnProperty.call(this.allowedMimeTypes, typeName)) {
                    if (typeName === constaint.key) {
                        const type = this.allowedMimeTypes[typeName];
                        type.forEach(mimeType => {
                            acceptedFiles.push(mimeType);
                        });
                    }
                }
            }
        });

        return acceptedFiles;
    }

    getTypeFileByMimeType(mimeType) {
        // const mimeType = file.type;
        let fileType = '';

        for (const typeName in this.allowedMimeTypes) {
            if (Object.prototype.hasOwnProperty.call(this.allowedMimeTypes, typeName)) {
                const type = this.allowedMimeTypes[typeName];
                type.forEach(mt => {
                    if (mimeType === mt) {
                        fileType = typeName;
                    }
                });
            }
        }

        if (fileType === '') {
            fileType = 'document';
        }

        return fileType;
    }

    getIconFile(constraints) {
        let icon = 'attachment';
        if (constraints.length == 1) {
            switch(constraints[0].key) {
                case 'image':
                    icon = 'image';
                    break;
                case 'video':
                    icon = 'play_circle_outline';
                    break;
                case 'document':
                    icon = 'description';
                    break;
                case 'audio':
                    icon = 'audiotrack';
                    break;
                case 'sticker':
                    icon = 'mood';
                    break;
            }
        }

        return icon;
    }

    getTextFile(constraints) {
        const texts = [];
        constraints.forEach(constaint => {
            switch(constaint.key) {
                case 'image':
                    texts.push('una imagen');
                    break;
                case 'video':
                    texts.push('un video');
                    break;
                case 'document':
                    texts.push('un documento');
                    break;
                case 'sticker':
                    texts.push('un sticker');
                    break;
                case 'audio':
                    texts.push('un audio');
                    break;
            }
        });
        return texts.join(' o ');
    }

    openFileSelector(previewImage, previewVideo, previewLink, previewLoaderImage, previewAudio, dropZoneDiv, previewDiv, acceptedFiles, constraints) {
        let input = document.createElement('input');
        input.type = 'file';
        if (constraints[0].key !== 'document') {
            const accept = acceptedFiles.join(', ');
            $(input).attr('accept', accept);
        }
        input.onchange = _ => {
            let files = Array.from(input.files);
            if (files[0].size <= this.maxSize) {
                previewLoaderImage.fadeIn();
                dropZoneDiv.fadeOut();
                previewDiv.fadeIn();
                previewLink.text(files[0].name);
                previewLink.removeAttr('href');
                // console.log(files[0]);
                const type = this.getTypeFileByMimeType(files[0].type);
                this.uploadFile(files[0], previewImage, previewVideo, previewLoaderImage, previewAudio, previewLink, dropZoneDiv, previewDiv, type);
            } else {
                this.alertService.error(`El archivo debe pesar menos de 16MB`);
            }
        };
        input.click();
    }

    getMultimedia(field, constraints) {
        const containerDiv = $('<div class="multimedia" />');
        const dropZoneDiv = $('<div class="drop-zone" />');
        const iconImg = $('<i class="material-icons" />');
        const dragStrong = $('<strong />');
        const oStrong = $('<strong>o</strong>');
        const clickSpan = $('<span>Da </span>');
        const uploadButton = $('<button class="button-upload-file">clic aquí para seleccionar</button>');
        const previewDiv = $('<div class="preview" />');
        const previewImage = $('<img class="image" />');
        const previewVideo = $('<video controls />');
        const previewAudio = $('<audio controls />');
        const previewLoaderImage = $('<img class="loader" src="/assets/img/loading_dark.svg" />');
        const previewLink = $('<a target="_blank" />');
        const previewButton = $('<button />');
        const previewBtnIcon = $('<i class="material-icons">delete_forever</i>');

        // constraints = [{name:'Imagen', key:'image'}, {name:'Video', key: 'video'}, {name:'Audio', key: 'audio'}];
        // console.log(constraints);
        // constraints = [{name:'PDF', key:'document'}];
        const acceptedFiles = this.getAcceptedFiles(constraints);
        const icon = this.getIconFile(constraints);
        const chunkText = this.getTextFile(constraints);
        const text = `Arrastra y suelta ${chunkText} aquí`;

        iconImg.text(icon);
        dragStrong.text(text);

        dropZoneDiv.on('drop', (event: any) => {
            this.dropHandler(event, previewImage, previewVideo, previewLink, previewLoaderImage, previewAudio, dropZoneDiv, previewDiv, acceptedFiles, constraints, chunkText);
        });

        dropZoneDiv.on('dragover', (event: any) => {
            this.dragOverHandler(event);
        });

        uploadButton.on('click', (event: any) => {
            this.openFileSelector(previewImage, previewVideo, previewLink, previewLoaderImage, previewAudio, dropZoneDiv, previewDiv, acceptedFiles, constraints);
        });

        previewButton.on('click', (event: any) => {
            this.removeFile(previewImage, previewVideo, previewAudio, previewLink, dropZoneDiv, previewDiv);
        });

        previewButton.append(previewBtnIcon);

        dropZoneDiv.append([iconImg, dragStrong, oStrong, clickSpan, uploadButton]);
        previewDiv.append([previewLoaderImage, previewImage, previewVideo, previewAudio, previewLink, previewButton]);
        containerDiv.append([dropZoneDiv, previewDiv, field]);

        const value = field.val();

        if (value) {
            const jsonValue = JSON.parse(value);
            dropZoneDiv.hide();
            previewDiv.css('display', 'block');
            previewLoaderImage.hide();
            previewLink.text(jsonValue.url);
            previewLink.attr('href', jsonValue.url);
            // const fileType = this.getTypeFileByMimeType(jsonValue.url);
            previewImage.hide();
            previewVideo.hide();
            previewAudio.hide();
            if (jsonValue.type === 'image') {
                previewImage.attr('src', jsonValue.url);
                previewImage.show();
            } else if (jsonValue.type === 'video') {
                previewVideo.attr('src', jsonValue.url);
                previewVideo.show();
            } else if (jsonValue.type === 'audio') {
                previewAudio.attr('src', jsonValue.url);
                previewAudio.show();
            }
        }

        this.field = field;

        return containerDiv;
    }

}
