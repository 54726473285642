import { Component, OnInit } from '@angular/core';



import { AlertService } from 'src/services/alert.service';
import { LoaderService } from 'src/services/loader.service';

import { WabaService } from 'src/services/waba.service';
import { AccountService } from 'src/services/account.service';
import { ModalService } from '../modal/modal.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
declare var translate: any;

@Component({
    selector: 'app-waba',
    templateUrl: './waba.component.html',
    styleUrls: ['./waba.component.scss']
})
export class WabaComponent implements OnInit {

    accessToken = '';
    businessIds = [];
    wabas = [];
    sharedIds: any;
    ownedIds: any;

    currentWabaId: string = '';
    currentWabaId2: string = '';
    currentBusinessId: string = '';
    accountReviewStatus: any;
    businessReviewStatus: any;

    currentSelection: string = 'phones';
    translate: any;

    active: any = {
        phones: true,
        webhooks: false,
        systemUsers: false,
        creditLines: false
    }

    constructor(
        private wabaService: WabaService,
        private alertService: AlertService,
        private accountService: AccountService,
        private loaderService: LoaderService,
        private modalService: ModalService,
    ) {
        // console.log(codes);
        // this.regionNames = new Intl.DisplayNames(['es'], {type: 'region'});
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    ngOnInit() {
        if (localStorage.getItem('Auth-Type') === 'FACEBOOK') {
            this.getBusiness();
        } else {
            this.alertService.warning('A esta sección debes ingresar con Facebook');
        }
    }

    /**
     * Obtengo los business ids
     */
    getBusiness() {
        const accessToken = localStorage.getItem('token');
        this.wabaService.setAccessToken(accessToken);
        this.wabaService.getBusinessAccounts('id,name,verification_status').subscribe(
            (res: any) => {
                if (res.hasOwnProperty('data') && res.data.length > 0) {
                    this.businessIds = res.data;
                }
            }, (error: any)=>{
                console.error(error);
            }
        );
    }

    onSelectBusiness() {
        if (this.currentBusinessId) {
            this.wabaService.setBussinessId(this.currentBusinessId);
            const currentBusiness = this.businessIds.find(business => business.id === this.currentBusinessId);
            if (currentBusiness) {
                this.businessReviewStatus = this.getIconByStatusBusiness(currentBusiness.verification_status);
                this.getWabas();
            } else {
                this.currentBusinessId = '';
                this.businessReviewStatus = null;
                this.wabas = [];
            }
        } else {
            this.businessReviewStatus = null;
            this.wabas = [];
        }
    }

    getWabas() {
        this.wabaService.getListOfSharedWABAs('id,name,account_review_status').subscribe(
            (sharedIds: any) => {
                if (sharedIds.hasOwnProperty('data')) {
                    this.sharedIds = sharedIds;
                    this.wabaService.getListOfOwnedWABAs('id,name,account_review_status').subscribe(
                        (ownedIds: any) => {
                            this.loaderService.close();
                            if (ownedIds.hasOwnProperty('data')) {
                                this.ownedIds = ownedIds;
                                this.wabas = [...this.sharedIds.data, ...this.ownedIds.data];
                            } else {
                                this.alertService.error('Hubo un problema al obtener los shared waba ids else');
                            }
                        },
                        (error) => {
                            console.error(error);
                            this.alertService.error('Hubo un problema al obtener los shared waba ids');
                        }
                    );
                } else {
                    this.alertService.error('Hubo un problema al obtener los owned waba ids else');
                }
            },
            (error) => {
                console.error(error);
                this.alertService.error('Hubo un problema al obtener los owned waba ids');
            }
        );
    }

    onSelectWaba() {
        if (this.currentWabaId) {
            this.wabaService.setWhatsappBusinessAccountId(this.currentWabaId);
            const currentWaba = this.wabas.find(waba => waba.id === this.currentWabaId);
            if (currentWaba) {
                this.currentWabaId2 = currentWaba.id;
                this.accountReviewStatus = this.getIconByStatusWaba(currentWaba.account_review_status);
            } else {
                this.currentWabaId = '';
                this.accountReviewStatus = null;
            }
        } else {
            this.accountReviewStatus = null;
        }
    }

    getIconByStatusWaba(status: string) {
        //PENDING, APPROVED, and REJECTED
        // pending_actions, thumb_up_alt, thumb_down_alt
        const icons = {
            PENDING: {
                icon: 'pending_actions',
                name: 'pendiente',
                key: 'pending'
            },
            APPROVED: {
                icon: 'thumb_up_alt',
                name: 'aprobada',
                key: 'approved'
            },
            REJECTED: {
                icon: 'thumb_down_alt',
                name: 'rechazada',
                key: 'rejected'
            }
        };
        status = status.toString().toUpperCase();
        return icons[status];
    }

    getIconByStatusBusiness(status: string) {
        //VERIFIED, UNVERIFIED, and PENDING
        // pending_actions, thumb_up_alt, thumb_down_alt
        const icons = {
            PENDING: {
                icon: 'pending_actions',
                name: 'pendiente',
                key: 'pending'
            },
            VERIFIED: {
                icon: 'thumb_up_alt',
                name: 'verificado',
                key: 'verified'
            },
            NOT_VERIFIED: {
                icon: 'thumb_down_alt',
                name: 'no verificado',
                key: 'not_verified'
            }
        };
        status = status.toString().toUpperCase();
        return icons[status];
    }

    singInFacebook() {
        this.accountService.login('business_management,whatsapp_business_management');
    }

    select(select: string) {
        if (this.currentWabaId && this.currentBusinessId && this.currentSelection === select) {
            return true;
        } else {
            return false;
        }
    }

    cleanActive() {
        this.active = {
            phones: false,
            webhooks: false,
            systemUsers: false,
            creditLines: false
        }
    }

    setSelect(select: string) {
        if (this.currentWabaId && this.currentBusinessId) {
            this.cleanActive();
            this.currentSelection = select;
            this.active[select] = true;
        } else {
            this.alertService.error('Debes seleccionar un negocio y una cuenta de WhatsApp');
        }
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    test() {
        console.log('lleho');
    }
}
