import {
	Component,
	OnInit,
	AfterViewInit,
	ElementRef,
	ViewChild,
} from "@angular/core";
import * as XLSX from 'xlsx';
import { CampaignService } from "../../../services/campaign.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { HsmMessage } from '../../../models/hsm-message';

import tippy from "tippy.js";
import { LoaderService } from "../../../services/loader.service";


import { AlertService } from "../../../services/alert.service";
import { CustomerService } from "src/services/customer.service";
import { ReCaptchaV3Service } from 'ng-recaptcha';

import * as MaterialDatetimePicker from '../../../assets/libraries/material-datetime-picker.js';
declare var MaterialDatetimePicker: any;
type AOA = any[][];


import {
	HSMMediaType,
	HSMType,
	HSMButtonSetType,
	HSMTemplateType,
	HSMButtonType,
	HSMCategoryType,
	HSMStatusType,
	HSMButtonSetTypeLabel,
	HSMTemplateTypeLabel,
	HSMMediaTypeLabel,
	HSMCategoryTypeLabel
} from './../../../enums/hsm-message';
import { ModalService } from "src/components/modal/modal.service";
import { TimezoneService } from "src/services/inspector-gus/timezone.service";
import { DataLayerService } from "src/services/data-layer.service";


declare var translate: any;
@Component({
	selector: "app-send-campaigns",
	templateUrl: "./send-campaigns.component.html",
	styleUrls: ["./send-campaigns.component.scss"],
})
export class SendCampaignsComponent implements OnInit, AfterViewInit {

	existsMessages = false;
	dataMessage: HsmMessage;
	channelIDD: any;
    channelOwnerId: any;
	hsmID: any;
	tenantID: any;

	messageType: any;
	mediaType: any;

	mensajeContent: any = "";

	campaigns: any = [];

	channels: any = [];

	arrayHsm: any = [];

	arrayParamsMessage: any = [];
    arrayParamsMessageColumn: any = [];
    arrayParamsMessageColumnVal: any = [];


	slug: any;
	customerId: string;
	urlFile: any;
	idCampaign: any;

    currentChannel: any;

	getCampaign = {
		name: "",
		windowTime: 0,
		defaultMessage: "",
		keywordRegex: "",
		fileUrl: "",
		hsmId: "",
		status: "",
		managerId: "",
		tenantChannelId: "",
		campaignType: "FLASH_CAMPAIGN",
	};

	picker = new MaterialDatetimePicker({});
    selectedTz: string;

	@ViewChild("windowTimeC") windowTimeC: ElementRef;
	@ViewChild("rangevalue1") rangevalue1: ElementRef;
	@ViewChild("uploader") uploader: ElementRef;
    @ViewChild("uploader2") uploader2: ElementRef;
	@ViewChild("nameCampaignC") nameCampaignC: ElementRef;
	@ViewChild("messageC") messageC: ElementRef;
	@ViewChild("keywordRegexC") keywordRegexC: ElementRef;
	/* @ViewChild("selectChannelC") selectChannelC: ElementRef; */
	@ViewChild("selectHsmIdC") selectHsmIdC: ElementRef;

	@ViewChild("buttonsendC") buttonsendC: ElementRef;

	files: any[] = [];
    files2: any[] = [];

	sendCampaignBtn: boolean;

    videoUrl = '';

    translate: any;
    alltz: string[] = [];

    dataExcel: AOA;
    alphabet: string[] = [];
    validateXlsxBodyStyles: any;
    columnsCount: any[];
    currentMapping: any;
    showAssociation: boolean;

    quantityNum: string;
    flagPath: string;

    successRecommendationMsg: string;
    successRecommendationLink: string;

	ngAfterViewInit() {
		document.addEventListener(
			"DOMContentLoaded",
			this.setValueRange.bind(this)
		);
		this.windowTimeC.nativeElement.addEventListener(
			"input",
			this.setValueRange.bind(this)
		);

		this.windowTimeC.nativeElement.value = '1';
		this.setValueRange(1);

		tippy("[data-tippy-content]");
		if ('messageId' in this.activateRoute.snapshot.queryParams) {
            this.selectHsmIdC.nativeElement.value = this.activateRoute.snapshot.queryParams.messageId;
        }
	}

	constructor(
		private campaignService: CampaignService,
		private activateRoute: ActivatedRoute,
		private router: Router,
		private alertService: AlertService,
		private loaderService: LoaderService,
		private customerService: CustomerService,
		private modalService: ModalService,
        private timezoneService: TimezoneService,
        private dataLayerService: DataLayerService,
        private recaptchaV3Service: ReCaptchaV3Service
	) {
		this.customerId = "";
		this.sendCampaignBtn = true;
		this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);

        const alpha = Array.from(Array(26)).map((e, i) => i + 65);
        this.alphabet = alpha.map((x) => String.fromCharCode(x));

        this.validateXlsxBodyStyles = {
            width: '1000px'
        };
        this.columnsCount = [];
        this.currentMapping = {};
        this.showAssociation = false;
        this.selectedTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.flagPath = 'Campaña -> Send -> ';
        this.successRecommendationLink = null;
	}

	ngOnInit(): void {
		this.testFullDocument3Buttons();

		const parameterCustomerSlug = this.activateRoute.snapshot.paramMap.get('customer_slug');
        this.idCampaign = this.activateRoute.snapshot.queryParams.campaignId;
		if (parameterCustomerSlug) {
			this.slug = parameterCustomerSlug;

			this.customerService.getBySlug(this.slug).subscribe(
				(res: any) => {
					this.customerId = res.tenant.id;
					this.showChannels();

				},
				(err) => {
					/* console.log(err); */
				}
			);


		}
        this.getAllTz();
        this.dataLayerService.setFlag(this.flagPath, 'start');

	}

    getAllTz() {
        this.timezoneService.getAll(this.slug).subscribe(
            (res: string[]) => {
                this.alltz = res;
            },
            err => {
                console.log(err);
            }
        );
    }

	getCurrentCampaign() {
		if ('campaignId' in this.activateRoute.snapshot.queryParams && 'tenantId' in this.activateRoute.snapshot.queryParams) {
			const campaignId = this.activateRoute.snapshot.queryParams.campaignId;
			this.tenantID = this.activateRoute.snapshot.queryParams.tenantId;

			this.campaignService.getOne(this.slug, campaignId, this.tenantID).subscribe(
				(res: any) => {
					this.channelIDD = res.hsm.tenantChannelId;
					this.selectHsmIdC.nativeElement.value = res.hsm.id;
					this.keywordRegexC.nativeElement.value = res.keywordRegex;
					this.messageC.nativeElement.value = res.defaultMessage;
					this.nameCampaignC.nativeElement.value = res.name;
					this.windowTimeC.nativeElement.value = res.windowTime / 60;
					this.setValueRange(res.windowTime);
					this.urlFile = res.fileUrl;
                    this.videoUrl = res.mediaUrl;

					this.files = [];
                    this.files2 = [];

					// this.buttonsendC.nativeElement.disabled = true;
					this.getParams(res.hsm.id);
				},
				(err) => {
					console.log(err);
				}
			);
		}
	}


	testFullDocument3Buttons() {
		this.dataMessage = {
			name: '',
			tenantChannelId: '',
			messageContent: '',
			mediaType: HSMMediaType.DOCUMENT,
			mediaContent: '',
			messageType: HSMType.MEDIA,
			button1: '',
			button2: '',
			button3: '',
			footerText: '',
			buttonSetType: HSMButtonSetType.QUICK_REPLY
		}
	}

	showChannels() {
		this.campaignService.getChannels(this.slug).subscribe(
			(res: any) => {
				this.channels = res;
				/* console.log(res); */


				if (res.length > 0) {

					this.existsMessages = true;
					$('.divCampaign').css('display','block');

					let arrayHsm2 = [];
                    const waChannel = res.find(channel => channel.channel === 'WHATSAPP');
                    if (waChannel) {
                        this.channelOwnerId = waChannel.channelOwnerId;
                        this.channelIDD = waChannel.id;
                        this.currentChannel = waChannel;
                        arrayHsm2 = [...waChannel.outboundMessages];
                    } else {
                        const waChannel2 = res.find(channel => (channel.channel === 'SMOOCH_SMS' || (channel.channel === 'SMOOCH' && channel.channelSubtype === 'WHATSAPP')) || channel.channel === 'WAVY');
                        if (waChannel2) {
                            this.channelIDD = waChannel2.id;
                            this.currentChannel = waChannel2;
                            arrayHsm2 = [...waChannel2.outboundMessages];
                        }
                    }

                    for (const [i, hsm] of arrayHsm2.entries()) {
                        if ('messageId' in this.activateRoute.snapshot.queryParams && this.activateRoute.snapshot.queryParams.messageId === hsm.id) {
                            this.selectHsmIdC.nativeElement.value = hsm.id;
                            arrayHsm2[i].selected = true;
                            this.getParams(hsm.id);
                            this.dataMessage = arrayHsm2[i];
                        } else {
                            arrayHsm2[i].selected = false;
                        }

                        if (hsm.authorizationStatus === HSMStatusType.APPROVED) {
                            this.arrayHsm.push(hsm);
                        }
                    }
					this.getCurrentCampaign();

				}else {
					this.existsMessages = false;
				}







			},
			(err) => {
				/* console.log(err); */
			}
		);
	}

	getHsmId(channelId) {

		this.testFullDocument3Buttons();
		this.channelIDD = channelId;

		for (const channel of this.channels) {
			if (channel.id === channelId) {
				this.arrayHsm = [...channel.outboundMessages];
				break;
			} else {
				// this.arrayHsm.length = 0;
			}
		}
        // this.selectHsmIdC.nativeElement.disabled = false;
		// console.log('array outboundMessages');
		// console.log(this.arrayHsm);
	}

	typeMessage(typeMessage) {
	}


	validateDataCampaign(dataCampaign) {
		let error = '';
		if (!dataCampaign.name) {
			error += translate.sections.campaigns.sendCampaigns.nameRequired;
		}
		if (!dataCampaign.defaultMessage) {
			error += translate.sections.campaigns.sendCampaigns.answerRequired;
		}
		if (!dataCampaign.keywordRegex) {
			error += translate.sections.campaigns.sendCampaigns.keywordRequired;
		}
		if (!dataCampaign.fileUrl) {
			error += translate.sections.campaigns.sendCampaigns.exelRequired;
		}
		if (!dataCampaign.hsmId) {
			error += translate.sections.campaigns.sendCampaigns.messageRequired;
		}
        if (this.dataMessage.mediaType === 'VIDEO' && !this.videoUrl) {
			error += translate.sections.campaigns.sendCampaigns.fileRequired;
		}
		if (error) {
			this.alertService.error(error);
			return false;
		}
		return true;
	}

    saveEmptyCampaign(success) {
        const nameCampaign = this.nameCampaignC.nativeElement.value;
        const windowTime = this.windowTimeC.nativeElement.value;
        const defaultMessage = this.messageC.nativeElement.value;
        const keywordRegex = this.keywordRegexC.nativeElement.value;
        const hsmId = this.selectHsmIdC.nativeElement.value;
        const dataCampaign: any = {
            name: nameCampaign,
			windowTime: windowTime * 60,
			defaultMessage: defaultMessage,
			keywordRegex: keywordRegex,
            hsmId: hsmId,
			managerId: localStorage.getItem("constructorUserId"),
			tenantChannelId: this.channelIDD,
            deliveryType: "NOTIFICATION",
			campaignType: "FLASH_CAMPAIGN",
            mediaUrl: this.videoUrl,
            config: JSON.stringify(this.currentMapping)
		};
        if (this.idCampaign) {
            this.campaignService
                .update(JSON.stringify(dataCampaign), this.slug, this.idCampaign, this.customerId)
                .subscribe(
                    (res: any) => {
                        if (typeof(success) === 'function') {
                            success();
                        }
                    },
                    (err) => {
                        /* console.log(err); */
                        this.alertService.error(translate.sections.campaigns.sendCampaigns.campaignNoCreated);
                    }
                );
        } else {
            this.campaignService.create(JSON.stringify(dataCampaign), this.slug, this.customerId).subscribe(
                (res: any) => {
                    this.idCampaign = res.id;
                    if (typeof(success) === 'function') {
                        success();
                    }
                }, (err)=>{
                    this.alertService.error(translate.sections.campaigns.sendCampaigns.campaignNoCreated);
                }
            );
        }
    }


	saveCampaign(
		nameCampaign,
		windowTime,
		defaultMessage,
		keywordRegex,
		hsmId,
		success=null
	) {
		const dataCampaign: any = {
			name: nameCampaign.value,
			windowTime: windowTime.value * 60,
			defaultMessage: defaultMessage.value,
			keywordRegex: keywordRegex.value,
			fileUrl: this.urlFile,
			hsmId: hsmId.value,
			managerId: localStorage.getItem("constructorUserId"),
			tenantChannelId: this.channelIDD,
			deliveryType: "NOTIFICATION",
			campaignType: "FLASH_CAMPAIGN",
            mediaUrl: this.videoUrl,
            config: JSON.stringify(this.currentMapping)
		};

		if (this.validateDataCampaign(dataCampaign)) {
			dataCampaign.status = "CREATED";
			this.campaignService
				.update(JSON.stringify(dataCampaign), this.slug, this.idCampaign, this.customerId)
				.subscribe(
					(res: any) => {
						this.alertService.success(translate.sections.campaigns.sendCampaigns.campaignCreated);
						if (success && typeof success === 'function') {
							success();
						}
						this.sendCampaignBtn = false;
                        this.dataLayerService.setFlag(this.flagPath, 'start');
                        this.router.navigateByUrl(`/customers/${this.slug}/campaigns/sendCampaign?campaignId=${this.idCampaign}&tenantId=${this.tenantID}`);
					},
					(err) => {
						/* console.log(err); */
						this.alertService.error(translate.sections.campaigns.sendCampaigns.campaignNoCreated);
					}
				);
		}
	}

	getParams(idMessage, deleteFile=false, selectedMsg=false) {
		this.hsmID = idMessage;
		document.querySelector("#buttonUploadFile").removeAttribute("disabled");

		for (const outboundMessage of this.arrayHsm) {
			if (outboundMessage.id === idMessage) {
				this.dataMessage = outboundMessage;
                if(outboundMessage.mediaType === HSMMediaType.VIDEO) {
                    this.dataMessage.mediaContent = '';
                }

				this.tenantID = outboundMessage.tenantChannelId;
				this.mediaType = outboundMessage.mediaType;
				this.messageType = outboundMessage.messageType;
				this.mensajeContent = outboundMessage.messageContent;
				this.arrayParamsMessage = [...outboundMessage.params];
                this.arrayParamsMessageColumn = [...outboundMessage.params];
                if (this.dataMessage.category === 'AUTHENTICATION' && selectedMsg) {
                    this.alertService.info(this.translate.sections.campaigns.validationTemplate.noteCode);
                }
			} else {
				// this.arrayParamsMessage.length = 0;
			}
		}

        if (deleteFile) {
            this.arrayParamsMessageColumn = [];
            this.dataExcel = null;
            this.columnsCount = [];
            this.deleteFile(0, 'excel');
            this.uploader.nativeElement.value = "";
        }

        if (this.messageType === HSMType.MEDIA && this.mediaType !== HSMMediaType.VIDEO) {
            this.arrayParamsMessage.push("media_url");
            this.arrayParamsMessageColumn.push("media_url");
        }

        this.arrayParamsMessageColumn.unshift(translate.sections.campaigns.sendCampaigns.phone);
        this.arrayParamsMessageColumnVal = Array.apply(null, Array(this.arrayParamsMessageColumn.length)).map(() => '');
	}




	setValueRange(ev) {
		const windowTimeC = this.windowTimeC.nativeElement;
		const rangevalue1 = this.rangevalue1.nativeElement;
		const newValue = Number(
			((windowTimeC.value - windowTimeC.min) * 100) /
			(windowTimeC.max - windowTimeC.min)
		);
		const newPosition = 10 - newValue * 0.2;
		rangevalue1.querySelector("span").innerHTML =
			`${windowTimeC.value} ` +
			(windowTimeC.value === "1" ? "hr" : "hrs");
		rangevalue1.style.left = `calc(${newValue}% + (${newPosition}px))`;
	}



	/**
	 * Functions for drag and drop upload
	 */

	uploadFile(files, type, success=null, error=null) {
        if (files.length === 1) {
            this.saveEmptyCampaign(() => {
                if (type === 'excel') {
                    if (this.currentChannel.channel !== 'SMOOCH') {
                        const reader: FileReader = new FileReader();
                        reader.onload = (e: any) => {
                            const bstr: string = e.target.result;
                            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
                            const wsname: string = wb.SheetNames[0];
                            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                            const cellsRange = ws["!ref"];
                            const lastCell = cellsRange.includes(':') ? cellsRange.split(':')[1] : cellsRange;
                            const lastColumn = lastCell.replaceAll(/[0-9]*/g, '');
                            const regex = new RegExp('^[A-Z]$');

                            if (regex.test(lastColumn)) {
                                this.dataExcel = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
                                this.dataExcel = this.dataExcel.slice(0, 5);

                                const firstCell = cellsRange.split(':')[0];
                                const firstColumn = firstCell.replaceAll(/[0-9]*/g, '');
                                const alphabetIndexLast = this.alphabet.indexOf(lastColumn) + 1;
                                const alphabetIndexFisrt = this.alphabet.indexOf(firstColumn);
                                this.columnsCount = this.alphabet.slice(alphabetIndexFisrt, alphabetIndexLast);
                                this.getParams(this.selectHsmIdC.nativeElement.value);
                            } else {
                                this.alertService.error(translate.sections.campaigns.sendCampaigns.unsupportedColumn);
                                this.closeModal('popup-validate-xlsx');
                                this.deleteFile(0, 'excel');
                            }
                        };
                        reader.readAsBinaryString(files[0]);
                    }
                    if (this.currentChannel.channel === 'SMOOCH') {
                        const FormFiles = new FormData();
                        FormFiles.append("file", files[0]);
                        this.createCampaignAndUploadExcel(FormFiles, success, error);
                    } else {
                        this.openModal('popup-validate-xlsx', 'validate');
                    }
                } else if (type === 'video') {
                    this.uploadVideo(files);
                    this.uploader2.nativeElement.value = "";
                }
            });
        } else if (files.length > 1) {
            this.alertService.error(translate.sections.campaigns.sendCampaigns.errorUploadOne);
        } else if (files.length === 0) {
            this.alertService.error(translate.sections.campaigns.sendCampaigns.errorSendFile);
        }
    }

    createCampaignAndUploadExcel(FormFiles, success=null, error=null) {

        if (this.currentChannel.channel === 'SMOOCH') {
            this.uploadExcelOld(FormFiles, success, error)
        } else {
            this.uploadExcel(FormFiles, success, error);
        }

    }

    uploadExcelOld(FormFiles, success=null, error=null) {
        this.recaptchaV3Service.execute('uploadExcel').subscribe((reToken) => {
            this.campaignService.uploadFileCampaign(
                    this.slug,
                    FormFiles,
                    this.channelIDD,
                    this.hsmID,
                    this.customerId,
                    reToken,
                    this.idCampaign
            )
            .subscribe({
                next: (res: any) => {
                    this.urlFile = res.file.url;
                    if (res.skipped.length > 0) {
                        this.alertService.warning(`${translate.sections.campaigns.sendCampaigns.foundMessage}: ${res.validCount} ${translate.sections.campaigns.sendCampaigns.rows} <br> ${translate.sections.campaigns.sendCampaigns.rowInvalidate}: ${res.skipped}`);
                        this.alertService.warning(translate.sections.campaigns.sendCampaigns.continue);
                    } else {
                        this.alertService.success(translate.sections.campaigns.sendCampaigns.fileUploaded);
                    }
                    if (typeof(success) === 'function') {
                        success();
                    }
                    this.uploader.nativeElement.value = "";
                    this.closeModal('popup-validate-xlsx');
                },
                error: (err: any) => {
                    console.log(err);
                    this.uploader.nativeElement.value = "";
                    this.alertService.error(translate.sections.campaigns.sendCampaigns.invalidatedMessage);
                    this.deleteFile(0, 'excel');
                    if (typeof(error) === 'function') {
                        error();
                    }
                },
                complete: () => {
                    console.log('completed');
                }
            });
        });
    }

    uploadExcel(FormFiles, success=null, error=null) {
        const config = {
            config: JSON.stringify(this.currentMapping)
        };
        this.campaignService.update(config, this.slug, this.idCampaign, this.tenantID).subscribe({
            next: (res: any) => {
                this.recaptchaV3Service.execute('uploadExcel2').subscribe((reToken) => {
                    this.campaignService.uploadFileCampaign2(
                        this.slug,
                        FormFiles,
                        this.channelIDD,
                        this.hsmID,
                        this.customerId,
                        this.idCampaign,
                        reToken
                    )
                    .subscribe({
                        next: (res: any) => {
                            this.urlFile = res.file.url;
                            if (res.skipped.length > 0) {
                                this.alertService.warning(`${translate.sections.campaigns.sendCampaigns.foundMessage}: ${res.validCount} ${translate.sections.campaigns.sendCampaigns.rows} <br> ${translate.sections.campaigns.sendCampaigns.rowInvalidate}: ${res.skipped}`);
                                this.alertService.warning(translate.sections.campaigns.sendCampaigns.continue);
                            } else {
                                this.alertService.success(translate.sections.campaigns.sendCampaigns.fileUploaded);
                            }
                            if (typeof(success) === 'function') {
                                success();
                            }
                            this.uploader.nativeElement.value = "";
                            this.closeModal('popup-validate-xlsx');
                        },
                        error: (err: any) => {
                            console.log(err);
                            this.uploader.nativeElement.value = "";
                            this.alertService.error(translate.sections.campaigns.sendCampaigns.invalidatedMessage);
                            this.deleteFile(0, 'excel');
                            this.closeModal('popup-validate-xlsx');
                            if (typeof(error) === 'function') {
                                error();
                            }
                        },
                        complete: () => {
                            console.log('completed');
                        }
                    });
                });
            },
            error: (err: any) => {
                this.alertService.error('Hubo un error al actualizar los parámetros');
            }
        });
    }

    uploadVideo(files) {
        if (files.length > 0 && this.slug) {
            const FormFiles = new FormData();
            FormFiles.append('file', files[0]);
            this.recaptchaV3Service.execute('uploadVideo').subscribe((reToken) => {
                this.campaignService.uploadAndEncodeVideo(this.slug, this.channelOwnerId, FormFiles, reToken).subscribe({
                    next: (res: any) => {
                        // console.log(res);
                        const fileSizeB = res.fileSize;
                        const maxSizeB = 16000000; // 16MB en bytes
                        if (fileSizeB < maxSizeB) {
                            // this.dataMessage.mediaContent = data.url;
                            this.videoUrl = res.url;
                            this.uploader2.nativeElement.parentElement.hide = true;
                            $('.upload-box.video .preview').show();
                            $('.upload-box.video .files-upload').hide();
                            this.dataMessage.mediaContent = res.url;
                            const player: HTMLMediaElement = $(".upload-box.video video").get(0) as HTMLMediaElement;
                            player.load();
                        } else {
                            this.deleteFile(0, 'video');
                            this.alertService.warning(translate.sections.campaigns.sendCampaigns.codeMessage);
                        }
                    },
                    error: err => {
                        this.deleteFile(0, 'video');
                        console.error(err);
                    }
                });
            });
        }
    }

	onFileDropped($event, type) {
		// console.log('function: onFileDropped');
		// console.log($event);
        if (this.selectHsmIdC.nativeElement.value === '') {
            this.alertService.warning(translate.sections.campaigns.sendCampaigns.errorSelectMsgBeforeFile);
        } else {
            this.prepareFilesList($event, type);
        }
	}

	fileBrowseHandler(event, type) {
        const files = event.target.files;
		// console.log('function: fileBrowseHandler');
		// console.log(files);
		this.prepareFilesList(files, type);
	}

	deleteFile(index: number, type: string) {
		$(".urlS3").text("");
        if (type === 'video') {
            this.uploader2.nativeElement.parentElement.hide = true;
            $('.preview').hide();
            $('.upload-box.video .files-upload').show();
            // this.files2.splice(index, 1);
            this.files2 = [];
            this.dataMessage.mediaContent = '';
            this.videoUrl = '';
        } else if (type === 'excel') {
		    this.files = [];
            // this.dataMessage.mediaContent = '';
        }
	}

	prepareFilesList(files: Array<any>, type: string, success=null, error=null) {
        if (files.length > 0) {
            for (const item of files) {
                if (type === 'video') {
                    this.files2.push(item);
                    this.uploadFile(files, type, success, error);
                } else if (type === 'excel') {
                    this.files.push(item, type);
                    this.uploadFile(files, type, success, error);
                }
            }
        }
	}

	formatBytes(bytes, decimals) {
		if (bytes === 0) {
			return "0 Bytes";
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return (
			parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
		);
	}

	closeModal(id: string) {
        this.modalService.close(id);
	}

	openCalendar() {

        this.picker.on('submit', (d) => {
            const DateFormat1 = String(d._d);
            const DateFormat2 = DateFormat1.split('GMT');

            const date = new Date(d._d);
            // const dateConvert = date.toISOString();
            const dateConvert = this.formatDate(date);
            window.localStorage.setItem('dateScheduled', dateConvert);

            $('.showDate').val(DateFormat2[0]);
        });
        this.picker.open();
    }

	formatDate(date) {
        const day = this.pad(date.getDate(), 2);
        const month = this.pad(date.getMonth() + 1, 2);
        const year = date.getFullYear();

        const hour = this.pad(date.getHours(), 2);
        const min = this.pad(date.getMinutes(), 2);
        const sec = this.pad(date.getSeconds(), 2);

        let stringDateTime = year + '-' + month + '-' + day;
        stringDateTime += 'T' + hour + ':' + min + ':' + sec + '.0000';

        return stringDateTime;
    }

	pad(str, max) {
        str = str.toString();
        return str.length < max ? this.pad('0' + str, max) : str;
    }

	functionCancel(id) {
        this.modalService.close(id);
    }

	functionSendDate(timezone) {
		const dateScheduleLocal = localStorage.getItem('dateScheduled');
        let tz = timezone.options[timezone.selectedIndex].text;
        if (tz === 'America/Chihuahua') {
            tz = 'America/Mexico_City';
        }
		const dataSchedule = {
			schedule: dateScheduleLocal,
			tz,
			status: 'SCHEDULED'
		};

		this.campaignService.update(JSON.stringify(dataSchedule), this.slug, this.idCampaign, this.customerId).subscribe(
			res => {
                this.dataLayerService.setFlag(this.flagPath, 'schedule');
				this.alertService.success(translate.sections.campaigns.sendCampaigns.campaignAdded);
			},
			err => {
				this.alertService.error(translate.sections.campaigns.sendCampaigns.campaignNoAdded);
			}
		);

		$('.showDate').val('');
		timezone.value = '';
		this.modalService.close('popup-schedule');
    }

	functionSendNow() {
		const dateTimeNow = this.formatDate(new Date());

        if (this.selectedTz === 'America/Chihuahua') {
            this.selectedTz = 'America/Mexico_City';
        }
		const dataSchedule = {
			schedule: dateTimeNow,
			tz: this.selectedTz,
			status: 'SCHEDULED'
		};

		this.campaignService.update(JSON.stringify(dataSchedule), this.slug, this.idCampaign, this.customerId).subscribe(
			(res: any) => {
				this.alertService.success(translate.sections.campaigns.sendCampaigns.campaingProcess);
				this.sendCampaignBtn = true;
                this.dataLayerService.setFlag(this.flagPath, 'save');
                this.modalService.close('popup-confirm');
				this.router.navigate(['/customers', this.slug, 'campaigns', 'showCampaigns'], {queryParams: {new: res.id}});
			},
			err => {
				this.alertService.error(translate.sections.campaigns.sendCampaigns.campaingNoProcess);
			}
		);
    }

    getNameParam(param) {
        if (param === '{{code=123123}}') {
            param = 'Código de un uso=123123'
        }
        return param.split('=')[0];
    }

    validateXlsx(success=null, error=null) {
        let imageColumn = true;
        const mediaIndex = this.arrayParamsMessageColumn.indexOf('media_url');
        if (this.arrayParamsMessageColumnVal.some((p, i) => {
            let result = true;
            if (mediaIndex && this.mediaType === HSMMediaType.IMAGE && mediaIndex === i && this.arrayParamsMessageColumnVal[mediaIndex] === '') {
                result = false;
                imageColumn = false;
            } else {
                result = p === '';
            }
            return result;
        })) {
            if (this.messageType === HSMType.MEDIA && this.mediaType === HSMMediaType.IMAGE) {
                this.alertService.warning(translate.sections.campaigns.sendCampaigns.errorParams1);
            } else {
                this.alertService.warning(translate.sections.campaigns.sendCampaigns.errorParams2);
            }

        } else {
            const config = {
                mapping: {
                    phoneNumber: this.columnsCount[this.arrayParamsMessageColumnVal[0]]
                }
            };
            if (this.messageType === HSMType.MEDIA && this.mediaType !== HSMMediaType.VIDEO) {
                if (!(!imageColumn && this.mediaType === HSMMediaType.IMAGE)) {
                    config['mapping']['mediaUrl'] = this.columnsCount[this.arrayParamsMessageColumnVal[this.arrayParamsMessageColumnVal.length - 1]];
                }
            }
            this.arrayParamsMessageColumnVal.forEach((column, index) => {
                if (index > 0 && index) {
                    if (this.messageType === HSMType.MEDIA && this.mediaType !== HSMMediaType.VIDEO) {
                        if (index < this.arrayParamsMessageColumnVal.length - 1) {
                            config['mapping'][index - 1] = this.columnsCount[column];
                        }
                    } else {
                        config['mapping'][index - 1] = this.columnsCount[column];
                    }
                }
            });
            this.currentMapping = config;

            const FormFiles = new FormData();
            FormFiles.append("file", this.files[0]);
            this.createCampaignAndUploadExcel(FormFiles, success, error);
            this.showAssociation = true;
        }
    }

    closeValidateXlsModal () {
        this.arrayParamsMessageColumn = [];
        this.dataExcel = null;
        this.columnsCount = [];
        this.deleteFile(0, 'excel');
        this.uploader.nativeElement.value = "";
        this.functionCancel('popup-validate-xlsx');
        this.alertService.warning('Se deben asociar los parámetros con las columnas para poder guardar');
    }

    openPopupLocalFiles(fileField) {
        if (this.selectHsmIdC.nativeElement.value === '') {
            this.alertService.warning(translate.sections.campaigns.sendCampaigns.errorSelectMsgBeforeFile);
        } else {
            fileField.click();
        }
    }

	openModal(id: string, action: string='') {
		this.modalService.open(id);
		// this.saveCampaign(
		// 	this.nameCampaignC.nativeElement,
		// 	this.windowTimeC.nativeElement,
		// 	this.messageC.nativeElement,
		// 	this.keywordRegexC.nativeElement,
		// 	this.selectHsmIdC.nativeElement,
		// 	() => {
        // 		this.modalService.open(id);
		// 	}
		// );
    }

    addRecommendationUsers () {
        if (this.quantityNum) {
            this.validateXlsx(
                () => {
                    const config = {
                        fileUrl: this.urlFile
                    };
                    this.campaignService.update(config, this.slug, this.idCampaign, this.tenantID).subscribe(
                        (res: any) => {
                            this.campaignService.addRecommendationUsers(this.slug, this.idCampaign, this.quantityNum).subscribe(
                                (res: any) => {
                                    if (res.state === 'Success') {
                                        this.successRecommendationLink = res.url;
                                    }
                                    if (res.state === 'Success' || res.state === 'Fail') {
                                        this.successRecommendationMsg = res.info;
                                    } else {
                                        this.successRecommendationMsg = res;
                                    }
                                    this.closeModal('popup-add-recommendation');
                                    this.openModal('popup-success-recommendation');
                                },
                                (err) => {
                                    this.alertService.error(translate.sections.campaigns.sendCampaigns.recommendationGeneralError);
                                }
                            );
                        }, (err: any) => {
                            console.log('error en recomendador update campaign');
                        }
                    );
                }, () => {
                    console.log('error en recomendador validate xlsx');
                }
            );
        } else {
            this.alertService.error(translate.sections.campaigns.sendCampaigns.recommendationError);
        }
    }

    printCampaign() {
        console.log("🚀 ~ file: send-campaigns.component.ts:990 ~ SendCampaignsComponent ~ printCampaign ~ this.dataMessage:", this.dataMessage)
    }

}
