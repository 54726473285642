import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OauthSmoochService {

    constructor(private http: HttpClient) { }

    switchboardConfiguration(code: string, consoleId: string, currentCustomerSlug: string) {
        const url = `/switchboard/configure`;
        const payload = {
            code,
            consoleId,
            currentCustomerSlug,
        };
        const headers = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json'
        });
        return this.http.post(url, payload, { headers });
    }

}
