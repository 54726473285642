import { AlertService } from '../services/alert.service';

export class InteractiveMessages {
    private alertService: AlertService;

    private _type: string;
    private _header?: Header;
    private _body: Body;
    private _footer?: Footer;
    private _action: Action;

    readonly headerTextMaxLen = 60;
    readonly bodyTextMaxLen = 1024;
    readonly footerTextMaxLen = 60;

    readonly actionBtnTextMaxLen = 20;
    readonly actionSectionsMinLen = 1;
    readonly actionSectionsMaxLen = 10;

    readonly sectionTitleMaxLen = 24;

    readonly rowTitleMaxLen = 24;
    readonly rowIdMaxLen = 200;
    readonly rowDescriptionMaxLen = 72;

    readonly buttonsMinLen = 1;
    readonly buttonsMaxLen = 3;
    readonly buttonIdMAxLen = 256;
    readonly buttonTitleMAxLen = 20;

    readonly allowedHeaderTypes = ['text', 'video', 'image', 'document'];

    constructor(type: string, alert: AlertService) {
        this.type = type;
        this.alertService = alert;
    }

    get type(): string {
        return this._type;
    }

    get header(): Header {
        return this._header;
    }

    get body(): Body {
        return this._body;
    }

    get footer(): Footer {
        return this._footer;
    }

    get action(): Action {
        return this._action;
    }

    set type(newType: string) {
        this._type = newType;
    }

    set header(newHeader: Header) {
        const errors = [];
        if (newHeader) {
            if (newHeader.text && newHeader.text.text && newHeader.text.text.length > this.headerTextMaxLen) {
                errors.push(`El texto de la cabecera debe tener una longitud máxima de ${this.headerTextMaxLen}`);
                // throw new Error("header.text has a max length of " + this.headerTextMaxLen);
            }
            if (!newHeader.type.type) {
                errors.push('El tipo de la cabecera es requerido');
                // throw new Error("header.type is required");
            } else {
                if (this._type === 'list') {
                    if (newHeader.type.type !== 'text') {
                        errors.push(`Solo se permite el tipo texto`);
                        // throw new Error("header.type only allow text");
                    }
                } else if (!this.allowedHeaderTypes.includes(newHeader.type.type)) {
                    errors.push(`Solo se permiten los tipos ${this.allowedHeaderTypes.join(', ')}`);
                    // throw new Error("header.type only allow: " + this.allowedHeaderTypes.join(', '));
                }
            }
            // if (newHeader.type === 'text' && !newHeader.text) {
            //     errors.push(`El texto de la cabecera es requerido`);
            //     // throw new Error("header.text is required");
            // }
            // if (newHeader.type === 'video' && !newHeader.video.link) {
            //     errors.push(`El video es requerido`);
            //     // throw new Error("header.video is required");
            // }
            // if (newHeader.type === 'image' && !newHeader.image.link) {
            //     errors.push(`La imagen es requerida`);
            //     // throw new Error("header.image is required");
            // }
            // if (newHeader.document && !newHeader.document.filename) {
            //     errors.push(`El archivo es requerido`);
            //     // throw new Error("header.document.filename is required");
            // }
            // if (newHeader.type === 'document' && !newHeader.document.link) {
            //     errors.push(`El documento es requerido`);
            //     // throw new Error("header.document is required");
            // }
        }

        if (errors.length === 0) {
            this._header = newHeader;
        } else {
            this.showErrors(errors);
        }
    }

    set body(newBody: Body) {
        const errors = [];
        // if (!newBody.text) {
        //     errors.push(`El texto del cuerpo es requerido`);
        // }
        if (newBody.text && newBody.text.length > this.bodyTextMaxLen) {
            errors.push(`El texto del cuerpo debe tener una longitud máxima de ${this.bodyTextMaxLen}`);
            // throw new Error("body.text has a max length of " + this.bodyTextMaxLen);
        }

        if (errors.length === 0) {
            this._body = newBody;
        } else {
            this.showErrors(errors);
        }
    }

    set footer(newFooter: Footer) {
        if (newFooter && newFooter.text && newFooter.text.length > this.footerTextMaxLen) {
            this.alertService.error(`El texto del pie de página debe tener una longitud máxima de ${this.footerTextMaxLen}`);
            // throw new Error("footer.text has a max length of " + this.footerTextMaxLen);
        } else {
            this._footer = newFooter;
        }
    }

    set action(newAction: Action) {
        const errors = [];
        if (this._type === 'list') {
            // if (!newAction.button) {
            //     errors.push(`El texto del boton es requerido`);
            // }
            if (newAction.button && newAction.button.button && newAction.button.button.length > this.actionBtnTextMaxLen) {
                errors.push(`El texto del boton debe tener una longitud máxima de ${this.actionBtnTextMaxLen}`);
                // throw new Error("action.button has a max length of " + this.actionBtnTextMaxLen);
            }
            if (newAction.sections && newAction.sections.length < this.actionSectionsMinLen && newAction.sections.length > this.actionSectionsMaxLen) {
                errors.push(`Debe existir almenos un elemento en la sección y un máximo de  ${this.actionBtnTextMaxLen}`);
                // throw new Error("action.sections has a max length of " + this.actionBtnTextMaxLen + " and a min " + this.actionSectionsMinLen);
            }


            if (newAction.sections) {
                newAction.sections.forEach(section => {
                    if (section.title && section.title.title && section.title.title.length > this.sectionTitleMaxLen) {
                        errors.push(`El título de la sección debe tener una longitud máxima de ${this.sectionTitleMaxLen}`);
                        // throw new Error("action.sections[].title has a max length of " + this.sectionTitleMaxLen);
                    }
                    section.rows.forEach(row => {
                        if (row.id && row.id.id && row.id.id.length > this.rowIdMaxLen) {
                            errors.push(`El id de la fila d ela sección debe tener un máximo de ${this.rowIdMaxLen}`);
                            // throw new Error("action.sections[].rows[].id has a max length of " + this.rowIdMaxLen);
                        }
                        if (row.title.title && row.title.title.length > this.rowTitleMaxLen) {
                            errors.push(`El título de la fila d ela sección debe tener un máximo de ${this.rowTitleMaxLen}`);
                            // throw new Error("action.sections[].rows[].title has a max length of " + this.rowTitleMaxLen);
                        }
                        if (row.description && row.description.description && row.description.description.length > this.rowDescriptionMaxLen) {
                            errors.push(`La descripción de la fila d ela sección debe tener un máximo de ${this.rowDescriptionMaxLen}`);
                            // throw new Error("action.sections[].rows[].description has a max length of " + this.rowDescriptionMaxLen);
                        }
                    });
                });
            }
        }

        if (this._type === 'button') {
            if (newAction.buttons) {
                newAction.buttons.forEach(button => {
                    if (button.type !== 'reply') {
                        errors.push('El tipo del boton solo permite el tipo reply');
                        // throw new Error("button.type only allow reply");
                    }
                    if (button.reply.id && button.reply.id.length > this.buttonIdMAxLen) {
                        errors.push(`El id del boton debe tener un máximo de ${this.buttonIdMAxLen}`);
                        // throw new Error("action.buttons[].reply.id has a max length of " + this.buttonIdMAxLen);
                    }
                    if (button.reply.title && button.reply.title.length > this.buttonTitleMAxLen) {
                        errors.push(`El título del boton debe tener un máximo de ${this.buttonIdMAxLen}`);
                        // throw new Error("action.buttons[].reply.title has a max length of " + this.buttonTitleMAxLen);
                    }
                });
            }
        }

        if (errors.length === 0) {
            this._action = newAction;
        } else {
            this.showErrors(errors);
        }
    }

    getJson() {
        const obj: any = {
            type: this._type
        };

        if (this._header) {
            obj.header = this._header;
        }
        obj.body = this._body;
        if (this._footer) {
            obj.footer = this._footer;
        }
        obj.action = this._action;

        return obj;
    }

    private showErrors(errors) {
        this.alertService.error(errors.join('<br>'));
    }
}

export interface Action {
    button?: {
        button: string;
        constraintType?: string;
    };
    buttons?: Button[];
    sections?: Section[];
}

export interface Button {
    type: string;
    reply: {
        id: string;
        title: string;
        constraintType?: string;
    }
}

export interface Section {
    // id: string;
    title?: {
        title?: string;
        constraintType?: string;
    };
    rows: Row[];
}

export interface Row {
    id: {
        id: string;
    },
    title: {
        title: string;
        constraintType?: string;
    }
    description?: {
        description?: string;
        constraintType?: string;
    }
}

export interface Footer {
    text: string;
    constraintType?: string;
}

export interface Body {
    text: string;
    constraintType?: string;
}

export interface Header {
    type: {
        type: string;
        constraintType?: string;
    }
    text?: {
        text: string;
        constraintType?: string;
    }
    video?: Media;
    image?: Media;
    document?: Media;
}

export interface Media {
    id?: string;
    link: string;
    caption?: string;
    filename?: string;
    provider?: string;
    constraintType?: string;
}
