import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

import { ModalService } from 'src/components/modal/modal.service';
import { AlertService } from 'src/services/alert.service';
import { AuthServices } from 'src/services/auth.guard.service';
import { FilterCustomerService } from 'src/services/filter-customer.service';
import { AllowedRolService } from 'src/services/rol-service.service';
import { CustomerService } from 'src/services/customer.service';
declare var translate: any;
@Component({
    selector: 'app-filter-customers',
    templateUrl: './filter-customers.component.html',
    styleUrls: ['./filter-customers.component.scss']
})
export class FilterCustomersComponent implements OnInit {

    role: any;
    authorizeRol: boolean;

    slug: string;
    existsFilters: boolean;
    currentFilter: any;
    filters: any[];
    filterTypes: any[];
    deleteFilterId: string;

    paginationFilters: any;
    paginationFilterTypes: any;

    platformsBodyStyles: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    translate: any;
    tenantId: any;

    constructor(
        private modalService: ModalService,
        private filterCustomerService: FilterCustomerService,
        private authServices: AuthServices,
        private allowedRolService: AllowedRolService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private customerService: CustomerService
    ) {
        this.existsFilters = true;
        this.filters = [];
        this.filterTypes = [];
        this.deleteFilterId = null;
        this.currentFilter = {
            id: null,
            filter: null,
            name: '',
            mask: '',
            wordList: []
        };
        this.paginationFilterTypes = {
            currentPage: 1,
            pageSize: 10,
            totalItems: 0
        };
        this.paginationFilters = {
            currentPage: 1,
            pageSize: 10,
            totalItems: 0
        };

        this.platformsBodyStyles = {
            width: '764px',
            padding: '64px 32px 32px 32px',
            'background-color': '#f1f1f9'
        }
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    ngOnInit() {
        this.auth();
        this.getUrlParam('customer_slug', (customerSlug) => {
            this.slug = customerSlug;
            this.getCustomerBySlug(customerSlug, () => {
                this.getFilters(this.paginationFilters.currentPage, this.paginationFilters.pageSize);
            });
        });
    }

    getCustomerBySlug(slug, success) {
        this.customerService.getBySlug(slug, 'true').subscribe(
            (res: any) => {
                this.tenantId = res.tenant.id;
                if (typeof(success) === 'function') {
                    success();
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    auth() {
        this.authServices.verifyToken(JSON.stringify({
            user_id: this.authServices.getIdGoogle(),
            token: this.authServices.getToken(),
            auth_type: localStorage.getItem('Auth-Type')
        })).subscribe(
            (res: any) => {
                if (res.status === 'ACTIVE' && res.role.name !== 'CUSTOM') {
                    localStorage.setItem('photo', res.avatar);
                    this.authorizeRol = this.allowedRolService.allowed(res.role.name, 'DEV');
                } else {
                    console.log('logout filter-customer1');
                    this.authServices.logout();
                }

            },
            err => {
                // console.log(err);
                console.log('logout filter-customer2');
                this.authServices.logout();
            }
        );
    }

    closeModal(id) {
        this.modalService.close(id);
    }

    openModal(id, filter = null) {
        this.modalService.open(id);
        if (id === 'popup-filter-types') {
            this.getFilterTypes(this.paginationFilterTypes.currentPage, this.paginationFilterTypes.pageSize);
        } else if (id === 'popup-new-filter' && filter === null) {
            this.currentFilter = {
                id: null,
                filter: null,
                name: '',
                mask: ''
            };
        } else if (id === 'popup-new-filter' && filter !== null) {
            this.currentFilter = filter;
        } else if (id === 'popup-filter-delete' && filter !== null) {
            this.deleteFilterId = filter;
        }
    }

    getFilterTypes(page, size) {
        this.filterCustomerService.getFiltersPaged(page, size, this.slug, this.tenantId).subscribe(
            (res: any) => {
                this.filterTypes = res.content;
                this.paginationFilterTypes.totalItems = res.totalElements;
            },
            err => {
                console.log(err);
            }
        );
    }

    getFilters(page, size) {
        this.filterCustomerService.getFiltersCustomerPaged(page, size, this.slug).subscribe(
            (res: any) => {
                this.filters = res.content;
                this.paginationFilters.totalItems = res.totalElements;
            },
            err => {
                console.log(err);
            }
        );
    }

    pageFiltersUpdate(page) {
        this.paginationFilters.currentPage = page;
        this.getFilters(page, this.paginationFilters.pageSize);
    }

    pageFilterTypesUpdate(page) {
        this.paginationFilterTypes.currentPage = page;
        this.getFilterTypes(page, this.paginationFilterTypes.pageSize);
    }

    getFiltersCustomer(page, size) {
        this.filterCustomerService.getFiltersCustomerPaged(page, size, this.slug).subscribe(
            (res: any) => {
                this.filters = res.content;
                this.paginationFilters.totalItems = res.totalElements;
                if (this.filters.length > 0) {
                    this.existsFilters = true;
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    selectFilter(filter) {
        this.currentFilter.filter = filter
    }

    saveFilter() {
        if (this.currentFilter.name === '' || this.currentFilter.mask === '' || this.currentFilter.filter === null) {
            this.alertService.error(translate.sections.customers.filterCustomers.requiredFields);
        } else {
            if (this.currentFilter.id === null) {
                this.filterCustomerService.createFilterCustomer(this.currentFilter, this.slug).subscribe(
                    (res: any) => {
                        this.alertService.success(translate.sections.customers.filterCustomers.createdFilter);
                        this.closeModal('popup-new-filter');
                        this.filters.push(res);
                    },
                    err => {
                        console.log(err);
                    }
                );
            } else {
                this.filterCustomerService.updateFilterCustomer(this.currentFilter, this.slug).subscribe(
                    (res: any) => {
                        this.alertService.success(translate.sections.customers.filterCustomers.updatedFilter);
                        this.closeModal('popup-new-filter');
                        const idx = this.filters.findIndex(filter => filter.id === res.id);
                        if (idx >= 0) {
                            this.filters[idx] = res;
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            }
        }
    }

    saveFilterType() {
        if (this.currentFilter.filter === null) {
            this.alertService.error(translate.sections.customers.filterCustomers.typeFiler);
        } else {
            this.closeModal('popup-filter-types');
        }
    }

    deleteFilter() {
        if (this.deleteFilterId !== null) {
            this.filterCustomerService.deleteFilterCustomer(this.deleteFilterId, this.slug).subscribe(
                (res: any) => {
                    this.alertService.success(translate.sections.customers.filterCustomers.removedFilter);
                    this.closeModal('popup-filter-delete');
                    const idx = this.filters.findIndex(filter => filter.id === this.deleteFilterId);
                    if (idx >= 0) {
                        this.filters.splice(idx, 1);
                    }
                    this.deleteFilterId = null;
                },
                err => {
                    console.log(err);
                }
            );
        }
    }

    redirectTypes() {
        this.router.navigate(['/customers', this.slug, 'filters', 'types']);
    }

    getUrlParam(param: string, success) {
        const paramInterval = setInterval(() => {
            if (this.route !== null) {
                if (this.route.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.route.paramMap.subscribe(params => {
                        const p = params.get(param);
                        success(p);
                    });
                }
            }
        }, 500);
    }
}
