<div class="wrapper">
    <div>
        <app-alert></app-alert>
        <app-navbar-top></app-navbar-top>

        <div class="wrapper-content">
            <app-steps [showSteps]="showSteps"></app-steps>
            <app-breadcrumb *ngIf="showBreadcrumb" [existsDiagram]="existsDiagram"></app-breadcrumb>
            <router-outlet></router-outlet>
            <app-navbar-left></app-navbar-left>
        </div>
        <app-loader></app-loader>
    </div>
</div>
<span class="root-loaded" style="opacity: 0; width: 1px;; height: 1px; font-size: 1; display: block;">loaded</span>
<ngx-cookiebot-declaration></ngx-cookiebot-declaration>
<app-redirect></app-redirect>
