import { AccountService } from '../services/account.service';
import { environment } from '../environments/environment';

declare const FB: any;

export function appInitializer(accountService: AccountService) {
    return () => new Promise((resolve: any) => {
        // wait for facebook sdk to initialize before starting the angular app
        window['fbAsyncInit'] = function () {
            FB.init({
                appId: environment.facebook.clientId,
                cookie: true,
                xfbml: true,
                version: environment.facebook.loginOptions.version
            });

            // auto authenticate with the api if already logged in with facebook
            FB.getLoginStatus(({authResponse}) => {
                if (authResponse) {
                    accountService.apiAuthenticate(authResponse.accessToken)
                        .subscribe()
                        .add(resolve);
                    // accountService.apiAuthenticate(authResponse.accessToken).subscribe().add(resolve);
                } else {
                    resolve();
                }
            });
        };

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            js.nonce = 'nonce-gus-2756c7h21a';
            js.onerror = function() {alert('Debes permitir el rastreo en tu navegador para usar el login de Facebook');resolve();}
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    });
}
