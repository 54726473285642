import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class TimezoneService {

    constructor(private http: HttpClient) { }

    getAll(slug: string) {
        const url = '/coreapi/v2/tz';
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }
}
