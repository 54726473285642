import { Injectable } from '@angular/core';
import * as joint from '../../../vendor/rappid';

import autocomplete from 'autocompleter';
import tippy from 'tippy.js';

import { AlertService } from '../alert.service';
import { ModalService } from './modal.service';
declare var translate: any;
declare var $: any;
declare var Blockly: any;
declare var Blocklify: any;

@Injectable({
    providedIn: 'root'
})
export class NlpService {
    translate: any;
    private alertService: AlertService;
    private modal: ModalService;

    constructor() {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.modal = new ModalService();
    }

    getNlp(alert,field, req, value, customerSlug) {
        console.log('%c Se llamó getNlp ', 'background: purple; display: block; padding: 5px 10px; color: white;');

        this.alertService = alert;

        const $containerDiv = $('<div class="type_visual_box carousel"/>');
        let indiceCombination = 0;
        let indiceConditional = 0;
        let indiceNLP = 0;

        let arregloNlp: any = [];
        let arreglocombinations: any = [];
        let arregloConditionals: any = [];

        const arregloVariables: any = [];

        let selectEditNLP: any;
        let buttonModifyNLP: any;

        let Workspace: any;




        let valueEntities = [];
        if ($('.nlp_entities').val() !== '') {
            valueEntities = JSON.parse($('.nlp_entities').val());
            // console.log('value entities', valueEntities);
        }

        for (const item of valueEntities) {
            arregloVariables.push({ label: item.name, value: item.name });
        }


        const functionRemoveCharacter = (parameterString) => {

            const convertToarray = parameterString.split(' ');

            for (let index1 = 0; index1 < convertToarray.length; index1++) {

                if (convertToarray[index1].includes('_')) {
                    // const findCharacter = convertToarray[index1].replace('_', '@');
                    const findCharacter = convertToarray[index1].replace(/^((!)?(\()?)*@/g, '$1_');
                    convertToarray[index1] = findCharacter;
                }


            }

            let str = convertToarray.join(' ');
            str = str.substring(0, str.length - 1);


            // return convertToarray.join(' ');
            return str;


        };

        const convertCODETOJS = (parameterString) => {
            let convertToarray = [parameterString];
            if (parameterString.includes(' ')) {
                convertToarray = parameterString.split(' ');
                for (let index1 = 0; index1 < convertToarray.length; index1++) {

                    if (convertToarray.includes('or')) {
                        const findCharacter = convertToarray[index1].replace('or', '||');
                        convertToarray[index1] = findCharacter;
                    }

                    if (convertToarray.includes('and')) {
                        const findCharacter2 = convertToarray[index1].replace('and', '&&');
                        convertToarray[index1] = findCharacter2;
                    }

                    // if (convertToarray.includes('not')) {
                    //     // // const findCharacter3 = convertToarray[index1 + 1].replace('or', '||');
                    //     // convertToarray[index1 + 1] = '!' + convertToarray[index1 + 1];
                    //     // convertToarray.splice(index1, 1);
                    //     const findCharacter3 = convertToarray[index1].replace('not ', '!');
                    //     convertToarray[index1] = findCharacter3;
                    // }
                    const prevIndex = index1 - 1;
                    if (prevIndex > 0 && convertToarray[prevIndex].includes('not')) {
                        convertToarray[index1] = '!' + convertToarray[index1];
                    }

                }
                convertToarray = convertToarray.filter(str => str !== 'not');
            }

            for (let index1 = 0; index1 < convertToarray.length; index1++) {
                convertToarray[index1] = convertToarray[index1].replace(/^((!)?(\()?)*@/g, '$1_');
            }

            return convertToarray.join(' ');
        };



        const convertCODETOPYTHON = (parameterString) => {

            let convertToarray = [parameterString];
            if (parameterString.includes(' ')){
                convertToarray = parameterString.split(' ');

                for (let index1 = 0; index1 < convertToarray.length; index1++) {

                    if (convertToarray.includes('||')) {
                        const findCharacter = convertToarray[index1].replace('||', 'or');
                        convertToarray[index1] = findCharacter;
                    }

                    if (convertToarray.includes('&&')) {
                        const findCharacter2 = convertToarray[index1].replace('&&', 'and');
                        convertToarray[index1] = findCharacter2;
                    }

                    if (convertToarray[index1].includes('!')) {
                        // // const findCharacter3 = convertToarray[index1 + 1].replace('or', '||');
                        // // convertToarray[index1-1]  = 'not'
                        // convertToarray[index1] = convertToarray[index1].slice(1, convertToarray[index1].length);
                        // convertToarray.splice(index1, 0, 'not')
                        const findCharacter3 = convertToarray[index1].replace('!', 'not ');
                        convertToarray[index1] = findCharacter3;
                    }

                    if (convertToarray.includes('true')) {
                        const findCharacter5 = convertToarray[index1].replace('true', 'True');
                        convertToarray[index1] = findCharacter5;
                    }
                    if (convertToarray.includes('false')) {
                        const findCharacter6 = convertToarray[index1].replace('false', 'False');
                        convertToarray[index1] = findCharacter6;
                    }

                }
            }

            for (let index1 = 0; index1 < convertToarray.length; index1++) {
                // convertToarray[index1] = convertToarray[index1].replace(/^_/g, '@');
                convertToarray[index1] = convertToarray[index1].replace(/^((not )?(\()?)*_/g, '$1@');
                // const findCharacter = convertToarray[index1].replace(/^((!)?(\()?)*@/g, '$1_');
            }

            return convertToarray.join(' ');


        };

        const idDiv = 'div' + joint.util.uuid();

        const iddynamic = 'tab1' + joint.util.uuid();
        const iddynamic2 = 'tab2' + joint.util.uuid();

        let iddynamicI = joint.util.uuid();
        let iddynamicS = joint.util.uuid();
        let iddynamicP = joint.util.uuid();

        $('#toolbox2').remove();


        const modalNLP = this.modal.create({

            onClose: (eClose) => {
                if (eClose) {
                    eClose.preventDefault();
                    eClose.stopPropagation();
                }
                $('#toolbox2').remove();

                Workspace.dispose();
            }
        });


        $('body').append([modalNLP.box, modalNLP.blocker]);


        modalNLP.box.css('width', '80%');
        modalNLP.box.css('height', '90%');
        modalNLP.box.css('padding', '20px');
        modalNLP.box.css('overflow-y', 'scroll');


        const buttonEditNLP = $('<button class="btn-primary btn-large btn-actionBlocky">' + translate.sections.services.editar + '</button>');

        const buttonSaveAndCreate = $('<button class="btn-primary btn-large btn-actionBlocky">' + translate.sections.services.saveAdd + '</button>');

        buttonSaveAndCreate.prop('disabled', true);

        const previewArea = $('<textarea disabled id="textarea"></textarea>');
        const mainWork = $(`<div class="blocklyDiv" id="${idDiv}"></div>`);
        const toolboxblock = $(`<xml id="toolbox2">

            <button id="" text="crear variable" callbackKey="keyButtonCreateVariable"></button>
            <block type="logic_operation"></block>
            <block type="logic_negate"></block>
            <block type="logic_boolean"></block>
          </xml>`);



        // tabs
        const divCondition2 = $(`<div class="containerTabs">
            <ul class="tabs">
                <li class="tab-link current" data-tab="${iddynamic}">`+ translate.sections.services.basic+ `</li>
                <li class="tab-link" data-tab="${iddynamic2}">`+ translate.sections.services.advance+ `</li>
            </ul>
        </div>`);

        const tab1Basic = $(`<div id="${iddynamic}" class="tab-content current">
        </div>`);

        const tab2Advanced = $(`<div id="${iddynamic2}" class="tab-content">
        </div>`);

        const textareaAdvanced = $(`<textarea class="areaAdvanced" name="" cols="100" rows="10"></textarea>`);

        const titleNLP = $('<h2 class="titleNLP">NLP</h2>');

        tab1Basic.append([mainWork, toolboxblock, previewArea]);
        tab2Advanced.append(textareaAdvanced);

        divCondition2.append([tab1Basic, tab2Advanced]);

        selectEditNLP = $(`<select name="">
            <option value="">`+ translate.sections.services.select+ `</option>
        </select>`);

        buttonModifyNLP = $('<button class="btn-primary">'+ translate.sections.services.modify+ '</button>');

        buttonModifyNLP.prop('disabled', true);

        const buttonblocky = $('<button class="btn-primary">'+ translate.sections.services.add+ '</button>');

        selectEditNLP.hide();
        buttonModifyNLP.hide();

        const nameClass = field.attr('name').replace('#', '-');
        $containerDiv.addClass(nameClass);
        $containerDiv.append([selectEditNLP, buttonModifyNLP, buttonblocky, field]);


        const divClear = $(`<div class="clearfix"></div>`);

        const divNameNlp = $(`<div class="divNameNLP">
            <span>`+ translate.name+ `<span>
            </div>`);

        const divPriorityNlp = $(`<div class="divPriorityNLP">
            <span>`+ translate.sections.services.priority+ `<span>
            </div>`);

        divClear.append(divNameNlp);
        divClear.append(divPriorityNlp);


        const inputNameNLP = $('<input class="nofocus margin0 nameNLP" type="text"/>');
        const inputPriorityNLP = $('<input class="nofocus margin0 priorityNLP" type="number" value="1" min="1" max="3" step="1"/>');

        divNameNlp.append(inputNameNLP);
        divPriorityNlp.append(inputPriorityNLP);


        // const buttonxml = $('<button style="display:block">get code xml</button>');

        const divMainCombinaciones = $('<div class="mainCombinations"></div>');

        const divElementsCombinations = $('<div></div>');

        const buttonAddCombinations = $('<button class="ml10 btn-primary btn-small">'+ translate.sections.services.add+ '</button>');
        // const buttonAddCombinationChip = $('<button class="btn-primary btn-added">+</button>');
        const titleCombinaciones = $('<span>Combinaciones</span>');

        buttonAddCombinations.prop('disabled', true);

        divElementsCombinations.append([titleCombinaciones, buttonAddCombinations]);


        const divEditCombinations = $('<div class="w-30"></div>');
        divEditCombinations.hide();
        const selectEditCombination = $(`<select class="w-60"  name="">
            <option value="">seleccionar</option>
            </select>`);
        const buttonEditCombinations = $('<button class="ml10 btn-primary btn-small">Editar</button>');

        divEditCombinations.append([selectEditCombination, buttonEditCombinations]);

        divMainCombinaciones.append([divElementsCombinations, divEditCombinations]);

        const divInputCombinations = $('<div class="divCombinations"></div>');

        const divinputintentions1 = $('<div class="w-30"></div>');
        const titleintentions1 = $('<span>Intención</span>');
        const info1 = $('<img class="infointentions infoIcon" src="../../assets/img/info.svg" alt="">');

        const divButtonIntention = $('<div class="dFlex"</div>');
        const buttonIconAdd = $('<button class="icon-more">+</button>');

        const inputintentions = $(`<input id=${iddynamicI} class="intentions margin0 nofocus" type="text"/>`);

        divButtonIntention.append([inputintentions, buttonIconAdd]);

        divinputintentions1.append([titleintentions1, info1, divButtonIntention]);

        const divinputsubintentions = $('<div class="w-30"></div>');

        const divButtonSub = $('<div class="dFlex"></div>');
        const buttonIconAdd2 = $('<button class="icon-more">+</button>');


        const inputsubintentions = $(`<input id=${iddynamicS} class="nofocus subintentions margin0" type="text"/>`);
        divButtonSub.append([inputsubintentions, buttonIconAdd2]);

        const titlesubintentions = $('<span>Subintención</span>');

        const info2 = $('<img class="infosubintentions infoIcon" src="../../assets/img/info.svg" alt="">');

        divinputsubintentions.append([titlesubintentions, info2, divButtonSub]);


        const divinputpolarities = $('<div class="w-30"></div>');
        const titlepolarities = $('<span>Polaridad</span>');
        const info3 = $('<img class="infopolarities infoIcon" src="../../assets/img/info.svg" alt="">');

        const divButtonPolar = $('<div class="dFlex"></div>');
        const buttonIconAdd3 = $('<button class="icon-more">+</button>');

        const inputpolarities = $(`<input id=${iddynamicP} class="nofocus polarities margin0" type="text"/>`);

        divButtonPolar.append([inputpolarities, buttonIconAdd3]);


        divinputpolarities.append([titlepolarities, info3, divButtonPolar]);

        divInputCombinations.append([divinputintentions1, divinputsubintentions, divinputpolarities]);


        const divAreaChips = $('<div class="divMainchips"></div>');

        const div1 = $('<div class="chip-group dInline"></div>');
        const div2 = $('<div class="chip-group dInline"></div>');
        const div3 = $('<div class="chip-group dInline"></div>');

        divAreaChips.append([div1, div2, div3]);

        const divMainCondicionales = $('<div class="mainConditionals mt20"></div>');
        // const buttonAddConditionalsChip = $('<button class="btn-primary btn-added">+</button>');
        const divElementsConditionals = $('<div class="mt20"></div>');
        const buttonAddConditionals = $('<button class="ml10 btn-primary btn-small">Agregar</button>');
        const titleConditionals = $('<span>Condicionales</span>');

        // buttonAddConditionals.prop('disabled', true);
        divElementsConditionals.append([titleConditionals, buttonAddConditionals]);


        const divEditConditionals = $('<div class="w-30"></div>');

        const selectEditConditional = $(`<select class="w-60" name="">
            <option value="">seleccionar</option>
            </select>`);
        const buttonEditConditional = $('<button class="ml10 btn-primary btn-small">Editar</button>');

        divEditConditionals.append([selectEditConditional, buttonEditConditional]);

        divEditConditionals.hide();

        divMainCondicionales.append([divElementsConditionals, divEditConditionals]);



        // test

        const divInputConditionals = $('<div class="divConditionals"></div>');

        const divinputclearEntities = $('<div class="w-30"></div>')
        const titleEntities = $('<span>'+ translate.sections.services.cleanEntities+ '</span>');

        const info4 = $('<img  class="infoclearEntities infoIcon" src="../../assets/img/info.svg" alt="">');


        const divButtonE = $('<div class="dFlex"></div>');
        const buttonIconE = $('<button class="icon-more">+</button>');


        const inputclearEntities = $('<input class="inputEntities entities nofocus margin0" type="text"/>');

        divButtonE.append([inputclearEntities, buttonIconE]);
        divinputclearEntities.append([titleEntities, info4, divButtonE]);

        const divinputClearEntitiesNlp = $('<div class="w-30"></div>');

        const divButtonEN = $('<div class="dFlex"></div>');
        const buttonIconEN = $('<button class="icon-more">+</button>');

        const inputClearEntitiesNlp = $('<input class="nofocus clear_entities_nlp margin0" type="text"/>');
        divButtonEN.append([inputClearEntitiesNlp, buttonIconEN]);
        const titleClearEntitiesNlp = $('<span>'+ translate.sections.services.cleanEntities+ ' NLP</span>');
        const info5 = $(`<img class="infoIcon infoclearEntitiesNLP"
            src="../../assets/img/info.svg" alt="">`);

        divinputClearEntitiesNlp.append([titleClearEntitiesNlp, info5, divButtonEN]);

        const divHidden = $('<div class="w-30"></div>');

        const info6 = $(`<img class="infoIcon infoclearEntitiesInject"
            src="../../assets/img/info.svg" alt="">`);

        const divButtonEI = $('<div class="dFlex2"></div>');
        const buttonIconEI = $('<button class="icon-more">+</button>');

        const inputEIKey = $('<input placeholder="clave" class="nofocus entities_injectKey margin0 inputsInject" type="text"/>');
        const inputEIValue = $('<input placeholder="valor" class="nofocus entities_injectValue margin0 inputsInject" type="text"/>');
        divButtonEI.append([inputEIKey, inputEIValue, buttonIconEI]);

        const titleInject = $('<span>Entity injection</span>');

        divHidden.append([titleInject, info6, divButtonEI]);

        divInputConditionals.append([divinputclearEntities, divinputClearEntitiesNlp, divHidden]);

        const divAreaChips2 = $('<div class="divMainchips2"></div>');

        const divE1 = $('<div class="divEntities dInline chip-group"></div>');
        const divE2 = $('<div class="divEntities dInline chip-group"></div>');
        const divE3 = $('<div class="divEntities dInline chip-group"></div>');

        divAreaChips2.append([divE1, divE2, divE3]);

        // end


        const divCondition = $('<div class="mt20"></div>');
        const titleCondicion = $('<span>Condición</span>');
        const buttonBasic = $('<button class="ml10 btn-primary">Básico</button>');
        const buttonAdvanced = $('<button class="ml10 btn-primary">Avanzado</button>');


        divCondition.append([titleCondicion, buttonBasic, buttonAdvanced]);


        // end


        modalNLP.box.append(titleNLP);
        modalNLP.box.append(divClear);
        modalNLP.box.append(divMainCombinaciones);
        modalNLP.box.append(divInputCombinations);
        modalNLP.box.append(divAreaChips);
        modalNLP.box.append(divMainCondicionales);
        modalNLP.box.append(divInputConditionals);
        modalNLP.box.append(divAreaChips2);
        modalNLP.box.append(divCondition2);
        modalNLP.box.append(buttonSaveAndCreate);
        modalNLP.box.append(buttonEditNLP);


        // load variables


        buttonblocky.on('click', () => {

            selectEditCombination
                .empty()
                .append('<option selected="selected" value="">Seleccionar</option>');

            selectEditConditional
                .empty()
                .append('<option selected="selected" value="">Seleccionar</option>');

            divEditCombinations.hide();
            divEditConditionals.hide();

            $('.divMainchips').find('.chip').remove();
            $('.divMainchips2').find('.chip').remove();

            inputNameNLP.val('');
            inputPriorityNLP.val('1');

            tab1Basic.append([toolboxblock]);
            buttonSaveAndCreate.show();
            buttonEditNLP.hide();
            modalNLP.show();

            Workspace = Blockly.inject(idDiv,
                {
                    scrollbars: true,
                    trashcan: true,
                    toolbox: document.getElementById('toolbox2')
                });


            // let code = Blockly.Python.workspaceToCode(Workspace);


            function myUpdateFunction(event) {
                const code = Blockly.JavaScript.workspaceToCode(Workspace);

                const valueArrayTextarea = code.trim().split('\n');



                const lastString = valueArrayTextarea[valueArrayTextarea.length - 1];
                // console.log(lastString);

                const getOutPut = convertCODETOPYTHON(lastString);
                // console.log(getOutPut);


                previewArea.val(getOutPut);
            }
            Workspace.addChangeListener(myUpdateFunction);


            //     Workspace.registerButtonCallback('keyButtonCreateVariable', () => {

            //         const nameVar = prompt('nombre de variable');
            //         $('#toolbox2').append(`<block type="variables_get">
            //      <field name="VAR">${nameVar}</field>
            //    </block>`);

            //         Workspace.updateToolbox(document.getElementById('toolbox2'));

            //     });


            $('.nameNLP').focus();
        });



        const arrayVariables = ['@cartelera', '@pelicula', '@ubicacion', '@opt_cine', '@preventa', '@nom_cine', '@opt_pelicula', '@nom_pelicula', '@Req_date', '@mov_date'];

        for (const itemVar of arregloVariables) {
            $('#toolbox2').append(`<block type="variables_get">
            <field name="VAR">${itemVar.label}</field>
          </block>`);
        }


        buttonAddCombinations.on('click', (e) => {

            // indiceCombination++;
            // console.log(indiceCombination);
            arreglocombinations.push({
                "intentions": [
                ],
                "subintentions": [

                ],
                "polarities": [

                ]

            });



            div1.find('.textChips').each(function () {

                arreglocombinations[arreglocombinations.length - 1].intentions.push($(this).text());
            });


            div2.find('.textChips').each(function () {

                arreglocombinations[arreglocombinations.length - 1].subintentions.push($(this).text());
            });
            div3.find('.textChips').each(function () {

                arreglocombinations[arreglocombinations.length - 1].polarities.push($(this).text());
            });

            $('.divMainchips').find('.chip').remove();

            divEditCombinations.show();

            // clear chips combinations

            selectEditCombination.append(`<option value="${arreglocombinations.length - 1}">

                ${arreglocombinations[arreglocombinations.length - 1].intentions[0]},
                ${arreglocombinations[arreglocombinations.length - 1].subintentions[0]},
                ${arreglocombinations[arreglocombinations.length - 1].polarities[0]}
                </option>`);


            // console.log(arreglocombinations);

            // console.log('value arreglo NLP', arregloNlp);

            buttonAddCombinations.prop('disabled', true);


        });

        buttonAddConditionals.on('click', (e) => {

            arregloConditionals.push({
                "condition": "",
                "clear_entities": [
                ],
                "clear_entities_nlp": [
                ],
                "entity_injection": {
                }
            });


            // const xmlDom = Blockly.Xml.workspaceToDom(Workspace);
            // const xmlText = Blockly.Xml.domToPrettyText(xmlDom);

            // console.log(xmlText);

            // console.log(previewArea.val());


            if (textareaAdvanced.val() !== '') {

                arregloConditionals[arregloConditionals.length - 1].condition
                    = textareaAdvanced.val().trim();
            }



            textareaAdvanced.val('');

            const valueArrayTextarea = previewArea.val().trim().split('\n');
            const lastString = valueArrayTextarea[valueArrayTextarea.length - 1];

            // console.log(lastString);



            const getOutPut = functionRemoveCharacter(lastString);
            // console.log(getOutPut);

            if (previewArea.val() !== '') {
                arregloConditionals[arregloConditionals.length - 1].condition = getOutPut;

                // arregloConditionals[arregloConditionals.length - 1].xml = xmlText;
            }


            // Workspace.clear();
            // $('xml[xmlns="https://developers.google.com/blockly/xml"]').remove();

            divE1.find('.textChips').each(function () {

                arregloConditionals[arregloConditionals.length - 1].clear_entities.push($(this).text());
            });


            divE2.find('.textChips').each(function () {

                arregloConditionals[arregloConditionals.length - 1].clear_entities_nlp.push
                    ($(this).text());
            });


            divE3.find('.textChips').each(function () {

                const getTextInjection = $(this).text().split(':');
                // console.log('clave:', getTextInjection[0]);
                // console.log('valor:', getTextInjection[1]);
                arregloConditionals[arregloConditionals.length - 1].entity_injection[getTextInjection[0]] = getTextInjection[1];
            });


            $('.divMainchips2').find('.chip').remove();

            selectEditConditional.append(`<option value="${arregloConditionals.length - 1}">

                ${ arregloConditionals[arregloConditionals.length - 1].clear_entities[0]},
                ${ arregloConditionals[arregloConditionals.length - 1].clear_entities_nlp[0]}
                </option>`);


            // console.log(arregloConditionals);
            divEditConditionals.show();

            // console.log('value arreglo NLP', arregloNlp);

            // buttonAddConditionals.prop('disabled', true);

            buttonSaveAndCreate.prop('disabled', false);
            Workspace.clear();

        });



        selectEditCombination.on('change', (e) => {

            const indexCombination = $(e.target).val();
            // console.log(indexCombination);

            indiceCombination = indexCombination;
            $('.divMainchips').find('.chip').remove();

            if (indexCombination !== '') {
                const arregloIntentions = arreglocombinations[indexCombination].intentions;

                const arregloSubintentions = arreglocombinations[indexCombination].subintentions;
                const arregloPolarities = arreglocombinations[indexCombination].polarities;

                for (const itemI of arregloIntentions) {

                    const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                    const spanValue = (`<span class="textChips">${itemI}</span>`);
                    const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
              <span class = "material-icons">×</span></button>`);

                    divG.append([spanValue, buttonremove]);
                    divG.appendTo(div1);

                    buttonremove.on('click', function () {
                        $(this).parent().remove();
                    });

                }

                for (const itemI of arregloSubintentions) {

                    const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                    const spanValue = (`<span class="textChips">${itemI}</span>`);
                    const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
              <span class = "material-icons">×</span></button>`);

                    divG.append([spanValue, buttonremove]);
                    divG.appendTo(div2);

                    buttonremove.on('click', function () {
                        $(this).parent().remove();
                    });

                }

                for (const itemI of arregloPolarities) {

                    const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                    const spanValue = (`<span class="textChips">${itemI}</span>`);
                    const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
              <span class = "material-icons">×</span></button>`);

                    divG.append([spanValue, buttonremove]);
                    divG.appendTo(div3);

                    buttonremove.on('click', function () {
                        $(this).parent().remove();
                    });

                }


            }

        });


        buttonEditCombinations.on('click', (e) => {

            // clear arrays
            arreglocombinations[indiceCombination].intentions.length = 0;
            arreglocombinations[indiceCombination].subintentions.length = 0;
            arreglocombinations[indiceCombination].polarities.length = 0;
            //

            div1.find('.textChips').each(function () {

                arreglocombinations[indiceCombination].intentions.push($(this).text());
            });


            div2.find('.textChips').each(function () {

                arreglocombinations[indiceCombination].subintentions.push($(this).text());
            });
            div3.find('.textChips').each(function () {

                arreglocombinations[indiceCombination].polarities.push($(this).text());
            });


            // console.log(arregloNlp);

            $('.divMainchips').find('.chip').remove();
            selectEditCombination.val($('option[value = ""] option[selected]').val());


            // update text option
            selectEditCombination.find('option').eq(+indiceCombination + 1).text(`
                ${arreglocombinations[indiceCombination].intentions[0]},
                ${arreglocombinations[indiceCombination].subintentions[0]},
                ${arreglocombinations[indiceCombination].polarities[0]}
                `);

            // end

            buttonAddCombinations.prop('disabled', true);
        });



        // select edit conditionals
        selectEditConditional.on('change', (e) => {

            const indexConditional = $(e.target).val();

            indiceConditional = indexConditional;
            $('.divMainchips2').find('.chip').remove();

            textareaAdvanced.val('');

            // Workspace.clear();

            try {

                // $('xml[xmlns="https://developers.google.com/blockly/xml"]').remove();
            } catch (error) {
            }


            if (indexConditional !== '') {

                const arregloClearE = arregloConditionals[indexConditional].clear_entities;

                const arregloClearENLP = arregloConditionals[indexConditional].clear_entities_nlp;

                const objetoInjection = arregloConditionals[indexConditional].entity_injection;


                const convertArrayInyection = Object.entries(objetoInjection);

                // console.log('convert to array')
                // console.log(convertArrayInyection)


                for (const itemI of arregloClearE) {

                    const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                    const spanValue = (`<span class="textChips">${itemI}</span>`);
                    const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                  <span class = "material-icons">×</span></button>`);

                    divG.append([spanValue, buttonremove]);
                    divG.appendTo(divE1);

                    buttonremove.on('click', function () {
                        $(this).parent().remove();
                    });

                }

                for (const itemI of arregloClearENLP) {

                    const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                    const spanValue = (`<span class="textChips">${itemI}</span>`);
                    const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                  <span class = "material-icons">×</span></button>`);

                    divG.append([spanValue, buttonremove]);
                    divG.appendTo(divE2);

                    buttonremove.on('click', function () {
                        $(this).parent().remove();
                    });

                }


                for (const itemI of convertArrayInyection) {

                    // const key1 = ;
                    // const value1 =;
                    const keyValue = itemI[0] + ':' + itemI[1];
                    const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                    const spanValue = (`<span class="textChips">${keyValue}</span>`);
                    const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                  <span class = "material-icons">×</span></button>`);

                    divG.append([spanValue, buttonremove]);
                    divG.appendTo(divE3);

                    buttonremove.on('click', function () {
                        $(this).parent().remove();
                    });

                }


                textareaAdvanced.val(arregloConditionals[indexConditional].condition);
                // console.log(arregloConditionals[indexConditional].condition);


                // $('body').append(arregloConditionals[indexConditional].xml);
                // insert xml to body

                const codeConverted = convertCODETOJS(arregloConditionals[indexConditional].condition);

                // console.log(codeConverted);



                const xmlDom = Blocklify.JavaScript.importer.codeToDom(codeConverted, 'pattern');
                $(xmlDom).children().map(() => {
                    const block = $(this);
                    block.find('field').map(() => {
                        const field = $(this);
                        const text = field.text();
                        field.text(text.replace(/^((!)?(\()?)*_/g, '$1@'));
                        return field;
                    });
                    return block;
                });

                Blockly.Xml.domToWorkspace(xmlDom, Workspace);




                // $('xml[xmlns="https://developers.google.com/blockly/xml"]').css('display', 'none');
                // $('xml[xmlns="https://developers.google.com/blockly/xml"]').attr('id', 'blocklyDefault');

                // const defaultBlocks = document.getElementById('blocklyDefault');



            }
            // console.log(indexConditional);
        });

        // end


        // button edit conditional

        buttonEditConditional.on('click', (e) => {

            // clear arrays
            arregloConditionals[indiceConditional].clear_entities.length = 0;
            arregloConditionals[indiceConditional].clear_entities_nlp.length = 0;
            arregloConditionals[indiceConditional].entity_injection = {};
            //


            // const xmlDom = Blockly.Xml.workspaceToDom(Workspace);
            // const xmlText = Blockly.Xml.domToPrettyText(xmlDom);


            // arregloConditionals[indiceConditional].xml
            //     = xmlText;

            divE1.find('.textChips').each(function () {

                arregloConditionals[indiceConditional].clear_entities.push($(this).text());
            });


            divE2.find('.textChips').each(function () {

                arregloConditionals[indiceConditional].clear_entities_nlp.push($(this).text());
            });


            divE3.find('.textChips').each(function () {

                const getTextInjection = $(this).text().split(':');
                // console.log('clave:', getTextInjection[0]);
                // console.log('valor:', getTextInjection[1]);
                arregloConditionals[indiceConditional].entity_injection[getTextInjection[0]] = getTextInjection[1];
            });




            $('.divMainchips2').find('.chip').remove();
            selectEditConditional.val($('option[value = ""] option[selected]').val());


            // update text option
            selectEditConditional.find('option').eq(+indiceConditional + 1).text(`
                ${arregloConditionals[indiceConditional].clear_entities[0]},
                ${arregloConditionals[indiceConditional].clear_entities_nlp[0]}
                `);


            const valueArrayTextarea = previewArea.val().trim().split('\n');
            const lastString = valueArrayTextarea[valueArrayTextarea.length - 1];


            const getOutPut = functionRemoveCharacter(lastString);
            // console.log('%c salida de codigo', 'color:yellow', getOutPut);

            if (previewArea.val() !== '') {
                arregloConditionals[indiceConditional].condition = getOutPut;

                // arregloConditionals[arregloConditionals.length - 1].xml = xmlText;
            }


            // console.log(arregloConditionals);
            // end

            Workspace.clear();
            textareaAdvanced.val('');

            try {

                // $('xml[xmlns="https://developers.google.com/blockly/xml"]').remove();
            } catch (error) {
            }

            // buttonAddConditionals.prop('disabled', true);
        });

        // end


        // new object

        buttonSaveAndCreate.on('click', (e) => {
            // clear all data

            $('.divMainchips').find('.chip').remove();
            $('.divMainchips2').find('.chip').remove();

            // Workspace.clear();
            // $('xml[xmlns="https://developers.google.com/blockly/xml"]').remove();

            textareaAdvanced.val('');

            selectEditCombination
                .empty()
                .append('<option selected="selected" value="">'+ translate.sections.services.select+ '</option>');

            selectEditConditional
                .empty()
                .append('<option selected="selected" value="">'+ translate.sections.services.select+ '</option>');



            arregloNlp.push({
                prioridad: parseInt(inputPriorityNLP.val(), 10),
                combinations: arreglocombinations,
                conditions: arregloConditionals,
                name: inputNameNLP.val()
            });



            // console.log(arregloNlp);
            inputNameNLP.val('');
            inputPriorityNLP.val('1');
            arreglocombinations = [];
            arregloConditionals = [];



            selectEditNLP.append(`<option value="${arregloNlp.length - 1}">

                ${arregloNlp[arregloNlp.length - 1].name}
                </option>`);


            selectEditNLP.show();
            buttonModifyNLP.show();
            // buttonEditNLP.show();
            buttonSaveAndCreate.prop('disabled', true);

            divEditCombinations.hide();
            divEditConditionals.hide();

            buttonAddCombinations.prop('disabled', true);
            // buttonAddConditionals.prop('disabled', true);

            this.alertService.success('Creado');

            const objNlp = {
                nlp: arregloNlp
            };
            field.val(JSON.stringify(objNlp));



            // console.log(field.val());
            // console.log(field);
        });


        selectEditNLP.on('change', (event) => {


            const indexNlp = $(event.target).val();

            inputNameNLP.val('');
            inputPriorityNLP.val('1');

            divEditCombinations.hide();
            divEditConditionals.hide();

            selectEditCombination
                .empty()
                .append('<option selected="selected" value="">'+ translate.sections.services.select+ '</option>');

            selectEditConditional
                .empty()
                .append('<option selected="selected" value="">'+ translate.sections.services.select+ '</option>');

            if (indexNlp !== '') {
                divEditCombinations.show();
                divEditConditionals.show();
                buttonModifyNLP.prop('disabled', false);

                indiceNLP = indexNlp;
                // console.log(indexNlp);

                inputNameNLP.val(arregloNlp[indexNlp].name);
                inputPriorityNLP.val(arregloNlp[indexNlp].prioridad);


                arreglocombinations = arregloNlp[indexNlp].combinations;
                arregloConditionals = arregloNlp[indexNlp].conditions;


                // console.log('value arreglo reasinged', arreglocombinations);

                // cargar datos en select


                for (let index2 = 0; index2 < arregloNlp[indexNlp].combinations.length; index2++) {
                    const element = arregloNlp[indexNlp].combinations[index2];
                    selectEditCombination.append(`<option value="${index2}">

                    ${element.intentions[0]},
                    ${element.subintentions[0]},
                    ${element.polarities[0]}
                    </option>`);
                }


                for (let index3 = 0; index3 < arregloNlp[indexNlp].conditions.length; index3++) {
                    const element = arregloNlp[indexNlp].conditions[index3];
                    selectEditConditional.append(`<option value="${index3}">

                    ${element.clear_entities[0]},
                    ${element.clear_entities_nlp[0]}
                    </option>`);
                }
            }



        });

        buttonModifyNLP.on('click', () => {
            tab1Basic.append([toolboxblock]);
            buttonSaveAndCreate.hide();
            buttonEditNLP.show();

            modalNLP.show();

            Workspace = Blockly.inject(idDiv,
                {
                    move: {
                        scrollbars: true,
                        drag: true,
                        wheel: false
                    },
                    scrollbars: true,
                    trashcan: true,
                    toolbox: document.getElementById('toolbox2')
                });


            // let code = Blockly.Python.workspaceToCode(Workspace);

            function myUpdateFunction(event) {
                const code = Blockly.JavaScript.workspaceToCode(Workspace);

                const valueArrayTextarea = code.trim().split('\n');


                const lastString = valueArrayTextarea[valueArrayTextarea.length - 1];
                // console.log(lastString);

                const getOutPut = convertCODETOPYTHON(lastString);
                // console.log(getOutPut);


                previewArea.val(getOutPut);
            }
            Workspace.addChangeListener(myUpdateFunction);


            //         Workspace.registerButtonCallback('keyButtonCreateVariable', () => {

            //             const nameVar = prompt('nombre de variable');
            //             $('#toolbox2').append(`<block type="variables_get">
            //      <field name="VAR">${nameVar}</field>
            //    </block>`);

            //             Workspace.updateToolbox(document.getElementById('toolbox2'));

            //         });
        });


        buttonEditNLP.on('click', () => {
            console.log('entroooo2');
            // console.log('editar nlp');

            $('.divMainchips').find('.chip').remove();
            $('.divMainchips2').find('.chip').remove();

            // Workspace.clear();
            // $('xml[xmlns="https://developers.google.com/blockly/xml"]').remove();

            textareaAdvanced.val('');
            previewArea.val('');

            selectEditCombination
                .empty()
                .append('<option selected="selected" value="">'+ translate.sections.services.select+ 'r</option>');

            selectEditConditional
                .empty()
                .append('<option selected="selected" value="">'+ translate.sections.services.select+ '</option>');

            console.log(indiceNLP);
            console.log(arregloNlp);
            // inputNameNLP.val(arregloNlp[indexNlp].name);
            arregloNlp[indiceNLP].name = inputNameNLP.val();
            arregloNlp[indiceNLP].prioridad = parseInt(inputPriorityNLP.val(), 10);

            // console.log(arregloNlp);
            modalNLP.hide();
            this.alertService.success('Actualizado');

            selectEditNLP.find('option').eq(+indiceNLP + 1).text(`
                ${arregloNlp[indiceNLP].name}
                `);

            selectEditNLP.val($('option[value = ""] option[selected]').val());
            inputNameNLP.val('');
            inputPriorityNLP.val('1');
            arregloConditionals = [];
            arreglocombinations = [];

            divEditCombinations.hide();
            divEditConditionals.hide();

            buttonModifyNLP.prop('disabled', true);


            const objNlp = {
                nlp: arregloNlp
            };
            field.val(JSON.stringify(objNlp));

            // field.val(JSON.stringify(arregloNlp));


            // console.log(field.val());

            Workspace.dispose();
            // console.log(field);
        });




        tippy('.infointentions', {
            content: 'informacion intenciones....',
            placement: 'top',
            arrow: false
        });

        tippy('.infosubintentions', {
            content: 'informacion infosubintentions....',
            placement: 'top',
            arrow: false
        });

        tippy('.infopolarities', {
            content: 'informacion infopolarities....',
            placement: 'top',
            arrow: false
        });

        tippy('.infoclearEntities', {
            content: 'informacion infoclearEntities....',
            placement: 'top',
            arrow: false
        });

        tippy('.infoclearEntitiesNLP', {
            content: 'informacion infoclearEntitiesNLP....',
            placement: 'top',
            arrow: false
        });


        tippy('.infoclearEntitiesInject', {
            content: 'informacion Inject....',
            placement: 'top',
            arrow: false
        });





        const arrayIntentions: any = [];
        let uniqueArray: any;
        // let jsonObject: any;
        // let uniqueSet: any;
        // let result;

        let intentionsID: any = document.getElementById(iddynamicI);

        inputintentions.on('keyup', (e) => {

            // console.log($(e.target).val());

            const filterName = $(e.target).val();

            if (filterName.length === 2) {
                const user = localStorage.getItem('id');
                const pass = localStorage.getItem('token');
                const auth = btoa(`${user}:${pass}`);


                fetch(`coreapi/v2/regexLayers/INTENTION/autocomplete/${filterName}`, {
                    method: 'GET',
                    headers: {
                        'Auth-Type': localStorage.getItem('Auth-Type'),
                        Authorization: 'Basic ' + auth,
                        Slug: customerSlug,
                        'x-meta-acl': 'public-read'
                    }
                })
                    .then(response => response.json())
                    .catch(error => console.error('Error:', error))
                    .then(response => {



                        // for (let index = 0; index < response.length; index++) {

                        //     arrayIntentions.push({ label: response[index].name + '|' + response[index].synonyms, value: response[index].name, sinonimos:response[index].synonyms });

                        //     result = response[index].synonyms;

                        // }

                        for (const item of response) {
                            arrayIntentions.push({
                                label: item.name + '|' + item.synonyms,
                                value: item.name,
                                sinonimos: item.synonyms
                            });




                            // result = item.synonyms;
                        }




                        // console.log(arrayIntentions)


                        // jsonObject = arrayIntentions.map(JSON.stringify);
                        // uniqueSet = new Set(arrayIntentions.map(JSON.stringify));




                        uniqueArray = Array.from(new Set(arrayIntentions.map(JSON.stringify))).map((item: string) => {
                            return JSON.parse(item);
                        });
                        // console.log(uniqueArray);






                        autocomplete({
                            input: intentionsID,

                            minLength: 2,
                            render: (item: any, currentValue) => {
                                const div = document.createElement('div');
                                div.className = 'divRender';
                                const labelitem = document.createElement('span');
                                labelitem.className = 'labelAutoC';
                                labelitem.textContent = item.label;
                                const sinonimos = document.createElement('span');
                                sinonimos.className = 'sinonimosC';
                                sinonimos.textContent = item.sinonimos;

                                div.textContent = item.value;
                                // if (item.value !== item.sinonimos) {
                                div.append(sinonimos);
                                // }
                                return div;
                            },

                            fetch: (text, update) => {
                                try {
                                    text = (text).toLowerCase();
                                    const suggestions = uniqueArray.filter(n => n.label.toLowerCase().includes(text));
                                    update(suggestions);
                                } catch (error) {
                                }
                            },
                            onSelect: (item: any) => {
                                intentionsID.value = item.value;
                            },
                            debounceWaitMs: 0,


                        });




                        if (response.length === 0) {
                            try {
                                arrayIntentions.length = 0;
                                uniqueArray.length = 0;

                                // label: "viajar|viajar, recorrer, rodar"
                                // value: "viajar"
                                // sinonimos: "viajar, recorrer, rodar"

                                uniqueArray.push({
                                    label: 'none',
                                    value: 'none',
                                    sinonimos: 'none'
                                });

                            } catch (error) {

                            }
                        }



                    });

            }

            if (filterName === '') {
                try {
                    arrayIntentions.length = 0;
                    uniqueArray.length = 0;
                } catch (error) {

                }
            }
            // send request

            // console.log(arrayIntentions)


        });

        // const intentionsID: any = document.getElementsByClassName('intentions')[0];



        const arrayIntentions2: any = [];
        let uniqueArray2: any;
        // let jsonObject2: any;
        // let uniqueSet2: any;
        // let result2;

        inputsubintentions.on('keyup', (e) => {

            // console.log($(e.target).val());

            const filterName = $(e.target).val();


            if (filterName.length === 3) {
                const user = localStorage.getItem('id');
                const pass = localStorage.getItem('token');
                const auth = btoa(`${user}:${pass}`);


                fetch(`coreapi/v2/regexLayers/SUBINTENTION/autocomplete/${filterName}`, {
                    method: 'GET',
                    headers: {
                        'Auth-Type': localStorage.getItem('Auth-Type'),
                        Authorization: 'Basic ' + auth,
                        Slug: customerSlug,
                        'x-meta-acl': 'public-read'
                    }
                })
                    .then(response => response.json())
                    .catch(error => console.error('Error:', error))
                    .then(response => {

                        // console.log('respuesta', response);

                        for (const item of response) {
                            arrayIntentions2.push({
                                label: item.name + '|' + item.synonyms,
                                value: item.name,
                                sinonimos: item.synonyms
                            });

                            // result2 = item.synonyms;
                        }


                        // jsonObject2 = arrayIntentions2.map(JSON.stringify);
                        // uniqueSet2 = new Set(arrayIntentions2.map(JSON.stringify));



                        uniqueArray2 = Array.from(new Set(arrayIntentions2.map(JSON.stringify))).map((item: string) => {
                            return JSON.parse(item);
                        });



                        if (response.length === 0) {
                            try {
                                arrayIntentions2.length = 0;
                                uniqueArray2.length = 0;

                                uniqueArray2.push({
                                    label: 'none',
                                    value: 'none',
                                    sinonimos: 'none'
                                });
                            } catch (error) {

                            }
                        }

                    });

            }

            if (filterName === '') {
                try {
                    arrayIntentions2.length = 0;
                    uniqueArray2.length = 0;
                } catch (error) {

                }
            }
            // send request


        });


        // const subintentionsID: any = document.getElementsByClassName('subintentions')[0];
        let subintentionsID: any = document.getElementById(iddynamicS);

        autocomplete({
            input: subintentionsID,

            render: (item: any, currentValue) => {
                const div = document.createElement('div');
                div.className = 'divRender';
                const labelitem = document.createElement('span');
                labelitem.className = 'labelAutoC';
                labelitem.textContent = item.label;

                const sinonimos = document.createElement('span');
                sinonimos.className = 'sinonimosC';
                sinonimos.textContent = item.sinonimos;

                div.textContent = item.value;
                if (item.value !== item.sinonimos) {
                    div.append(sinonimos);
                }
                return div;
            },

            fetch: (text, update) => {
                try {
                    text = (text).toLowerCase();

                    const suggestions = uniqueArray2.filter(n => n.label.toLowerCase().includes(text));
                    update(suggestions);
                } catch (error) {
                }
            },
            onSelect: (item: any) => {
                subintentionsID.value = item.value;
            },
            debounceWaitMs: 1500,
        });

        const arrayIntentions3: any = [];
        let uniqueArray3: any;
        // let jsonObject3: any;
        // let uniqueSet3: any;
        // let result3;

        inputpolarities.on('keyup', (e) => {

            // console.log($(e.target).val());

            const filterName = $(e.target).val();


            //if (filterName.length === 3) {
                /*const user = localStorage.getItem('id');
                const pass = localStorage.getItem('token');
                const auth = btoa(`${user}:${pass}`);*/

                arrayIntentions3.push(
                    {
                        label: 'negative',
                        value: 'negative',
                        sinonimos: 'negative'
                    }
                );

                arrayIntentions3.push(
                    {
                        label: 'positive',
                        value: 'positive',
                        sinonimos: 'positive'
                    }
                );

                uniqueArray3 = Array.from(new Set(arrayIntentions3.map(JSON.stringify))).map((item: string) => {
                    return JSON.parse(item);
                });


                /*fetch(`coreapi/v2/regexLayers/POLARITY/autocomplete/${filterName}`, {
                    method: 'GET',
                    headers: {
                        'Auth-Type': localStorage.getItem('Auth-Type'),
                        Authorization: 'Basic ' + auth,
                        Slug: customerSlug,
                        'x-meta-acl': 'public-read'
                    }
                })
                    .then(response => response.json())
                    .catch(error => console.error('Error:', error))
                    .then(response => {

                        // console.log('respuesta', response);

                        for (const item of response) {
                            arrayIntentions3.push({
                                label: item.name + '|' + item.synonyms,
                                value: item.name,
                                sinonimos: item.synonyms
                            });

                            // result3 = item.synonyms;
                        }

                        arrayIntentions3.push(
                            {
                                label: 'negative',
                                value: 'negative',
                                sinonimos: 'negative'
                            }
                        );

                        // jsonObject3 = arrayIntentions3.map(JSON.stringify);
                        // uniqueSet3 = new Set(arrayIntentions3.map(JSON.stringify));



                        uniqueArray3 = Array.from(new Set(arrayIntentions3.map(JSON.stringify))).map((item: string) => {
                            return JSON.parse(item);
                        });



                        if (response.length === 0) {
                            try {
                                arrayIntentions3.length = 0;
                                uniqueArray3.length = 0;
                                uniqueArray3.push({
                                    label: 'positive',
                                    value: 'positive',
                                    sinonimos: 'positive'
                                },
                                    {
                                        label: 'negative',
                                        value: 'negative',
                                        sinonimos: 'negative'
                                    }
                                );
                            } catch (error) {

                            }
                        }

                    });
                */
            //}

            /*if (filterName === '') {
                try {
                    arrayIntentions3.length = 0;
                    uniqueArray3.length = 0;
                } catch (error) {

                }
            }*/
            // send request


        });


        // const polaritiesID: any = document.getElementsByClassName('polarities')[0];
        let polaritiesID: any = document.getElementById(iddynamicP)

        autocomplete({
            input: polaritiesID,

            render: (item: any, currentValue) => {
                const div = document.createElement('div');
                div.className = 'divRender';
                const labelitem = document.createElement('span');
                labelitem.className = 'labelAutoC';
                labelitem.textContent = item.label;
                const sinonimos = document.createElement('span');
                sinonimos.className = 'sinonimosC'
                sinonimos.textContent = item.sinonimos;

                div.textContent = item.value;
                if (item.value !== item.sinonimos) {
                    div.append(sinonimos);
                }
                return div;
            },

            fetch: (text, update) => {
                try {
                    text = (text).toLowerCase();

                    const suggestions = uniqueArray3.filter(n => n.label.toLowerCase().includes(text));
                    update(suggestions);
                } catch (error) {

                }
            },
            onSelect: (item: any) => {
                polaritiesID.value = item.value;
            },
            debounceWaitMs: 0,
        });



        const arrayIntentions4: any = [];
        let uniqueArray4: any;
        // let jsonObject4: any;
        // let uniqueSet4: any;
        // let result4;


        const clearEntitiesID: any = document.getElementsByClassName('inputEntities')[0];

        autocomplete({
            input: clearEntitiesID,

            render: (item: any, currentValue) => {
                const div = document.createElement('div');
                div.className = 'divRender';

                const labelitem = document.createElement('span');
                labelitem.className = 'labelAutoC';
                labelitem.textContent = item.label;

                const sinonimos = document.createElement('span');
                sinonimos.className = 'sinonimosC';
                sinonimos.textContent = item.sinonimos;

                div.textContent = item.value;
                if (item.value !== item.sinonimos) {
                    div.append(sinonimos);
                }
                return div;
            },

            fetch: (text, update) => {
                console.log(text);



                text = text.toLowerCase();
                const suggestions = arregloVariables.filter(n => n.label.toLowerCase().startsWith(text));
                update(suggestions);
                console.log(update);
            },
            onSelect: (item: any) => {
                clearEntitiesID.value = item.value;
            },
            debounceWaitMs: 1500
        });


        const clearEntitiesNLPID: any = document.getElementsByClassName('clear_entities_nlp')[0];


        autocomplete({
            input: clearEntitiesNLPID,

            render: (item: any, currentValue) => {
                const div = document.createElement('div');
                div.className = 'divRender';
                const labelitem = document.createElement('span');
                labelitem.className = 'labelAutoC';
                labelitem.textContent = item.label;
                const sinonimos = document.createElement('span');
                sinonimos.className = 'sinonimosC';
                sinonimos.textContent = item.sinonimos;

                div.textContent = item.value;
                if (item.value !== item.sinonimos) {
                    div.append(sinonimos);
                }
                return div;
            },

            fetch: (text, update) => {
                text = text.toLowerCase();
                const suggestions = arregloVariables.filter(n => n.label.toLowerCase().startsWith(text));
                update(suggestions);
                console.log(text);
                console.log(suggestions);

            },
            onSelect: (item: any) => {
                clearEntitiesNLPID.value = item.value;
            },
            debounceWaitMs: 1500,
        });


        buttonIconAdd.on('click', (e) => {

            const filter = inputintentions.val();
            const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
            const spanValue = (`<span class="textChips">${filter}</span>`);
            const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                  <span class = "material-icons">×</span></button>`);
            divG.append([spanValue, buttonremove]);
            // divG.appendTo(div1);

            const arrayCheckedI = [];

            div1.find('.textChips').each(function () {
                arrayCheckedI.push($(this).text());
            });

            const exist = arrayCheckedI.includes(filter);

            for (const item of arrayIntentions) {
                if (item.value === filter && exist === false) {
                    divG.appendTo(div1);
                }
            }


            buttonremove.on('click', function () {
                $(this).parent().remove();
            });

            inputintentions.val('');

        });

        inputintentions.keypress((e) => {
            const code = (e.keyCode ? e.keyCode : e.which);
            if (code === 13) {

                arrayIntentions.push({
                    label: 'none',
                    value: 'none',
                    sinonimos: 'none'
                });
                const filter = inputintentions.val();
                const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                const spanValue = (`<span class="textChips">${filter}</span>`);
                const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                      <span class = "material-icons">×</span></button>`);
                divG.append([spanValue, buttonremove]);
                // divG.appendTo(div1);


                const arrayCheckedI = [];

                div1.find('.textChips').each(function () {
                    arrayCheckedI.push($(this).text());
                });

                const exist = arrayCheckedI.includes(filter);

                for (const item of arrayIntentions) {
                    // console.log(item);
                    if (item.value === filter && exist === false) {
                        divG.appendTo(div1);
                    }
                }



                buttonremove.on('click', function () {
                    $(this).parent().remove();
                });

                inputintentions.val('');

            }
        });

        buttonIconAdd2.on('click', () => {




            const filter = inputsubintentions.val();
            const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
            const spanValue = (`<span class="textChips">${filter}</span>`);
            const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                  <span class = "material-icons">×</span></button>`);
            divG.append([spanValue, buttonremove]);
            // divG.appendTo(div2);



            const arrayCheckedS = [];
            arrayIntentions2.push({
                label: 'none',
                value: 'none',
                sinonimos: 'none'
            });

            div2.find('.textChips').each(function () {
                arrayCheckedS.push($(this).text());
            });

            const exist = arrayCheckedS.includes(filter);

            for (const item of arrayIntentions2) {
                if (item.value === filter && exist === false) {
                    divG.appendTo(div2);
                }
            }

            buttonremove.on('click', function () {
                $(this).parent().remove();
            });
            inputsubintentions.val('');
        });

        inputsubintentions.keypress((e) => {
            const code = (e.keyCode ? e.keyCode : e.which);
            if (code === 13) {

                arrayIntentions2.push({
                    label: 'none',
                    value: 'none',
                    sinonimos: 'none'
                });
                const filter = inputsubintentions.val();
                const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                const spanValue = (`<span class="textChips">${filter}</span>`);
                const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                      <span class = "material-icons">×</span></button>`);
                divG.append([spanValue, buttonremove]);
                // divG.appendTo(div2);



                const arrayCheckedS = [];

                div2.find('.textChips').each(function () {
                    arrayCheckedS.push($(this).text());
                });

                const exist = arrayCheckedS.includes(filter);

                for (const item of arrayIntentions2) {
                    if (item.value === filter && exist === false) {
                        divG.appendTo(div2);
                    }
                }

                buttonremove.on('click', function () {
                    $(this).parent().remove();
                });
                inputsubintentions.val('');
            }
        });

        buttonIconAdd3.on('click', () => {
            const filter = inputpolarities.val();
            const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
            const spanValue = (`<span class="textChips">${filter}</span>`);
            const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                  <span class = "material-icons">×</span></button>`);
            divG.append([spanValue, buttonremove]);
            // divG.appendTo(div3);


            const arrayCheckedP = [];

            div3.find('.textChips').each(function () {
                arrayCheckedP.push($(this).text());
            });

            const exist = arrayCheckedP.includes(filter);

            for (const item of arrayIntentions3) {
                if (item.value === filter && exist === false) {
                    divG.appendTo(div3);
                }
            }

            buttonremove.on('click', function () {
                $(this).parent().remove();

                if (divAreaChips.find('.textChips').length > 2) {

                    buttonAddCombinations.prop('disabled', false);
                } else {
                    buttonAddCombinations.prop('disabled', true);

                }
            });
            inputpolarities.val('');

            if (divAreaChips.find('.textChips').length > 2) {
                buttonAddCombinations.prop('disabled', false);
            }
        });

        inputpolarities.keypress((e) => {
            const code = (e.keyCode ? e.keyCode : e.which);
            if (code === 13) {


                arrayIntentions3.push(
                    {
                        label: 'positive',
                        value: 'positive',
                        sinonimos: 'positive'
                    },
                    {
                        label: 'negative',
                        value: 'negative',
                        sinonimos: 'negative'
                    }
                );
                // console.log($(this).val());

                const filter = inputpolarities.val();
                const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                const spanValue = (`<span class="textChips">${filter}</span>`);
                const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                      <span class = "material-icons">×</span></button>`);
                divG.append([spanValue, buttonremove]);
                // divG.appendTo(div3);



                const arrayCheckedP = [];

                div3.find('.textChips').each(function () {
                    arrayCheckedP.push($(this).text());
                });

                const exist = arrayCheckedP.includes(filter);


                for (const item of arrayIntentions3) {
                    if (item.value === filter && exist === false) {
                        divG.appendTo(div3);
                    }
                }

                buttonremove.on('click', function () {
                    $(this).parent().remove();

                    if (divAreaChips.find('.textChips').length > 2) {

                        buttonAddCombinations.prop('disabled', false);
                    } else {
                        buttonAddCombinations.prop('disabled', true);

                    }
                });
                inputpolarities.val('');

                if (divAreaChips.find('.textChips').length > 2) {
                    buttonAddCombinations.prop('disabled', false);
                }
            }
        });


        buttonIconE.on('click', () => {
            const filter = inputclearEntities.val();
            const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
            const spanValue = (`<span class="textChips">${filter}</span>`);
            const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                  <span class = "material-icons">×</span></button>`);
            divG.append([spanValue, buttonremove]);
            divG.appendTo(divE1);

            buttonremove.on('click', function () {
                $(this).parent().remove();
            });
            inputclearEntities.val('');
        });

        inputclearEntities.keypress((e) => {
            const code = (e.keyCode ? e.keyCode : e.which);
            if (code === 13) {
                // console.log($(this).val());
                const filter = inputclearEntities.val();
                const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                const spanValue = (`<span class="textChips">${filter}</span>`);
                const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                      <span class = "material-icons">×</span></button>`);
                divG.append([spanValue, buttonremove]);
                // divG.appendTo(divE1);


                const arrayCheckedEN = [];

                divE1.find('.textChips').each(function () {
                    arrayCheckedEN.push($(this).text());
                });

                const exist = arrayCheckedEN.includes(filter);


                if (exist === false) {
                    divG.appendTo(divE1);
                }

                buttonremove.on('click', function () {
                    $(this).parent().remove();
                });
                inputclearEntities.val('');

            }
        });


        buttonIconEN.on('click', () => {

            const filter = inputClearEntitiesNlp.val();
            const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
            const spanValue = (`<span class="textChips">${filter}</span>`);
            const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                      <span class = "material-icons">×</span></button>`);
            divG.append([spanValue, buttonremove]);
            // divG.appendTo(divE2);

            const arrayCheckedEN = [];

            divE2.find('.textChips').each(function () {
                arrayCheckedEN.push($(this).text());
            });

            const exist = arrayCheckedEN.includes(filter);


            if (exist === false) {
                divG.appendTo(divE2);
            }



            buttonremove.on('click', function () {
                $(this).parent().remove();

                if (divAreaChips2.find('.textChips').length > 1) {

                    // buttonAddConditionals.prop('disabled', false);
                } else {
                    // buttonAddConditionals.prop('disabled', true);
                }
            });
            inputClearEntitiesNlp.val('');


            if (divAreaChips2.find('.textChips').length > 1) {

                // buttonAddConditionals.prop('disabled', false);
            }

        });

        inputClearEntitiesNlp.keypress((e) => {
            const code = (e.keyCode ? e.keyCode : e.which);
            if (code === 13) {
                // console.log($(this).val());
                const filter = inputClearEntitiesNlp.val();
                const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
                const spanValue = (`<span class="textChips">${filter}</span>`);
                const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                      <span class = "material-icons">×</span></button>`);
                divG.append([spanValue, buttonremove]);
                // divG.appendTo(divE2);



                const arrayCheckedEN = [];

                divE2.find('.textChips').each(function () {
                    arrayCheckedEN.push($(this).text());
                });

                const exist = arrayCheckedEN.includes(filter);


                if (exist === false) {
                    divG.appendTo(divE2);
                }


                buttonremove.on('click', function () {
                    $(this).parent().remove();
                });
                inputClearEntitiesNlp.val('');


                // arregloNlp[arregloNlp.length -1].Condicionales[indiceConditional].clear_entities_nlp.push(filter);
                // console.log(arregloNlp);
            }
        });


        buttonIconEI.on('click', () => {
            const filter1 = inputEIKey.val();
            const filter2 = inputEIValue.val();

            const filter = filter1 + ':' + filter2;
            const divG = $(`<div class = "chip" tabindex = "-1"></div>`);
            const spanValue = (`<span class="textChips">${filter}</span>`);
            const buttonremove = $(`<button title="Remove chip" aria-label="Remove chip">
                      <span class = "material-icons">×</span></button>`);
            divG.append([spanValue, buttonremove]);
            divG.appendTo(divE3);

            buttonremove.on('click', function () {
                $(this).parent().remove();

                if (divAreaChips2.find('.textChips').length > 2) {

                    // buttonAddConditionals.prop('disabled', false);
                } else {
                    // buttonAddConditionals.prop('disabled', true);
                }
            });
            inputEIKey.val('');
            inputEIValue.val('');


            if (divAreaChips2.find('.textChips').length > 2) {

                // buttonAddConditionals.prop('disabled', false);
            }
        });



        $('ul.tabs li').click(function () {
            const tabId = $(this).attr('data-tab');

            $('ul.tabs li').removeClass('current');
            $('.tab-content').removeClass('current');

            $(this).addClass('current');
            $('#' + tabId).addClass('current');
        });

        // end



        // value

        if (value !== undefined && value !== '') {
            console.log('entra value nlp');

            console.log(value);
            const objNlp = JSON.parse(value);
            arregloNlp = objNlp.nlp;

            console.log('array nlp reasinged nlp', arregloNlp);

            for (let indice = 0; indice < arregloNlp.length; indice++) {

                selectEditNLP.append(`<option value="${indice}">
                    ${arregloNlp[indice].name}
                    </option>`);
            }

            selectEditNLP.show();
            buttonModifyNLP.show();
        }
        // end

        return $containerDiv;
    }

}
