<section class="concersation-driven-development">
    <div class="controls-box clearfix">
        <div class="filter">
            <mat-form-field class="conversation-type" appearance="fill">
                <mat-label>Buscar en</mat-label>
                <mat-select [formControl]="conversationTypes" multiple (valueChange)="onChangeConversationType()">
                    <mat-option *ngFor="let conversationType of conversationTypesList" [value]="conversationType">
                        {{ conversationType.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="search" appearance="fill">
                <mat-label>🔍 Búsqueda</mat-label>
                <input [(ngModel)]="search" matInput (change)="onChangeSearch()">
            </mat-form-field>
            <mat-form-field class="range-picker" appearance="fill">
                <mat-label>Rango de fechas</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate placeholder="Fecha inicio" formControlName="start" (change)="onChangeRange()">
                    <input matEndDate placeholder="Fecha fin" formControlName="end" (change)="onChangeRange()">
                </mat-date-range-input>
                <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <!-- <div class="search-box">
                <i class="material-icons search-icon">search</i>
                <input class="search" type="text" placeholder="Búsqueda" />
            </div> -->
            <button class="btn-primary filter-btn" (click)="filter()">
                <i class="material-icons">filter_alt</i>
                Filtrar
            </button>

        </div>
        <div class="actions">
            <button class="btn-primary" (click)="downloadMessages()">
                <i class="material-icons md-24">file_download</i> Descargar
            </button>
            <button class="btn-primary" (click)="openTestBot()">
                <i class="material-icons md-24">play_arrow</i>Probar bot
            </button>
        </div>
    </div>
    <div class="container clearfix">
        <div class="conversations">
            <div class="conversation" *ngFor="let conversation of conversations" (click)="showConversationMessages($event, conversation)">
                <div class="user-img">
                    <img src="/assets/img/user2.png" />
                </div>
                <div class="user-info">
                    <div class="mini-chip">
                        {{ conversation?.channel }}
                    </div>
                    <div class="user-name">
                        Conversación con {{ conversation?.owner }}
                    </div>
                    <div class="user-desc">
                        {{ getMessageUser(conversation?.messageUser) }}
                    </div>
                </div>
            </div>
            <div class="empty" *ngIf="!conversations || conversations.length === 0">
                No hay conversaciones o no has aplicado un filtro.
            </div>
            <div class="pagination clearfix">
                <button class="load-more" (click)="conversationsLoadMore()" *ngIf="conversationsCursor">Cargar más</button>
            </div>
        </div>
        <div class="messages">
            <div class="elastic-msg" *ngFor="let elasticMessage of messages">
                <div class="message user" *ngIf="elasticMessage?.messageUser">
                    <div class="user-img">
                        <img [src]="'/assets/img/user2.png'" alt="Usuario" />
                    </div>
                    <div class="msg-user">
                        <span *ngIf="formatUserMsg(elasticMessage.messageUser).type === 'text'">
                            {{ formatUserMsg(elasticMessage.messageUser).val }}
                        </span>
                        <img *ngIf="formatUserMsg(elasticMessage.messageUser).type === 'image'" [src]="formatUserMsg(elasticMessage.messageUser).val" class="conversation-image" />
                        <audio controls *ngIf="formatUserMsg(elasticMessage.messageUser).type === 'audio'">
                            <source [src]="formatUserMsg(elasticMessage.messageUser).val">
                            Your browser does not support the audio element.
                        </audio>
                        <video width="330" height="248" controls *ngIf="formatUserMsg(elasticMessage.messageUser).type === 'video'">
                            <source [src]="formatUserMsg(elasticMessage.messageUser).val">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="actions" *ngIf="elasticMessage?.nlpStatus">
                        <div class="phrases">
                            <button class="add btn-icon" (click)="showModalPhrase(elasticMessage?.nlpLabel)">
                                <i class="material-icons">settings</i>
                            </button>
                            <!-- <button (click)="openModal('popup-delete-phrase-cdd')" class="remove btn-icon">
                                <i class="material-icons">remove_circle</i>
                            </button> -->
                        </div>
                    </div>
                </div>
                <div *ngIf="elasticMessage?.messageBot">
                    <div *ngFor="let messageBot of parseBotMsgs(elasticMessage?.messageBot); let i = index;">
                        <div class="message bot" *ngIf="elasticMessage?.messageBot.length > 0 && isAllowedMessage(elasticMessage, i, 'bot')">
                            <div class="msg-bot-wrapper">
                                <div class="msg-bot" (click)="showMessage(elasticMessage)" *ngIf="!isVisual(messageBot) && !isMultimedia(messageBot)">
                                    <span [innerHTML]="formatMessage(messageBot)"></span>
                                </div>

                                <!-- Quick replies -->
                                <div *ngIf="isVisual(messageBot) && messageBot.type === 'QUICKREPLY_DYNAMIC'">
                                    <div class="boxRepliesExtra">
                                        <div class="boxReplies">
                                            <div class="divReply" *ngFor="let btn of messageBot.buttons">
                                                <a class="buttonBlock">{{ btn.text }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Carrusel -->
                                <div *ngIf="isVisual(messageBot) && messageBot.type === 'SLIDE_DYNAMIC'">
                                    <div class="msg-bot" *ngFor="let msg of messageBot.messages">
                                        <span>{{ msg.content }}</span>
                                    </div>
                                    <div class="contenedor" *ngIf="messageBot.type === 'SLIDE_DYNAMIC'">
                                        <div class="card" *ngFor="let item of messageBot.items">
                                            <img class="coverCard" [src]="item.image" alt="Imagen item carrusel" *ngIf="item.image">
                                            <span class="cardTitleSlide">{{ item.title }}</span>
                                            <span class="cardDescriptionSlide">{{ item.description }}</span>
                                            <div class="divButtonSlide" *ngFor="let btn of item.buttons">
                                                <span class="btnSlider">{{ btn.title }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Dynamic buttons -->
                                <div *ngIf="isVisual(messageBot) && messageBot.type === 'BUTTON_DYNAMIC'">
                                    <div class="type_visual_box buttons_dyn inicial-jsonBase">
                                        <div class="card">
                                            <div class="cardTitleSlide2 escape" placeholder="Title">{{ messageBot?.messages[0]?.content }}</div>
                                            <div class="divButtonSlide" *ngFor="let btn of messageBot?.buttons">
                                                <span class="btnSlider">{{ btn?.title }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Video -->
                                <div *ngIf="!isVisual(messageBot) && isMultimedia(messageBot) && isVideo(messageBot)">
                                    <video class="multimedia-video" controls>
                                        <source [src]="messageBot">
                                        Tu navegador no soporta video en HTML
                                    </video>
                                </div>

                                <!-- Audio -->
                                <div *ngIf="!isVisual(messageBot) && isMultimedia(messageBot) && isAudio(messageBot)">
                                    <audio controls>
                                        <source [src]="messageBot">
                                        Tu navegador no soporta audio en HTML
                                    </audio>
                                </div>

                                <!-- Interactive list whatsapp -->
                                <div class="list-message" *ngIf="isVisual(messageBot) && messageBot.type === 'WHATSAPP_INTERACTIVE' && messageBot?.text?.type === 'list'">
                                    <div class="message">
                                        <span class="message-header">{{ messageBot?.text?.header?.text }}</span>
                                        <span class="message-body">{{ messageBot?.text?.body?.text }}</span>
                                        <span class="message-footer">{{ messageBot?.text?.footer?.text }}</span>
                                        <span class="message-hour">12:00</span>
                                        <span class="options-button">
                                            <i class="material-icons">list</i>
                                            {{ messageBot?.text?.action?.button }}
                                        </span>
                                    </div>
                                </div>

                                <!-- Reply buttons -->

                                <div class="reply-buttons inicial-displaydefinition" *ngIf="isVisual(messageBot) && messageBot.type === 'WHATSAPP_INTERACTIVE' && messageBot?.text?.type === 'button'">
                                    <div class="wa-card">
                                        <div class="wa-card-header">
                                            <span class="wa-card-header-text" *ngIf="messageBot?.text?.header?.type === 'text'">
                                                {{ messageBot?.text?.header?.text }}
                                            </span>

                                            <div class="multimedia" *ngIf="messageBot?.text?.header?.type !== 'text'">
                                                <div class="preview">
                                                    <img class="image" [src]="messageBot?.text?.header?.image?.link" *ngIf="messageBot?.text?.header?.type === 'image'">
                                                    <video class="video" controls  *ngIf="messageBot?.text?.header?.type === 'video'">
                                                        <source [src]="messageBot?.text?.header?.video?.link">
                                                    </video>
                                                    <!-- TODO: Documento -->
                                                    <a class="document" [href]="messageBot?.text?.header?.document?.link" target="_blank" *ngIf="messageBot?.text?.header?.type === 'document'">
                                                        <i class="material-icons">file_present</i>
                                                        {{ messageBot?.text?.header?.document?.link }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="wa-card-body">{{ messageBot?.text?.body?.text }}</span>
                                        <span class="wa-card-footer">{{ messageBot?.text?.footer?.text }}</span>
                                        <span class="message-hour">12:00</span>
                                    </div>
                                    <ul class="wa-card-buttons">
                                        <li *ngFor="let btn of messageBot?.text?.action?.buttons">
                                            <span class="title-button">{{ btn?.reply?.title }}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div class="actions">
                                <div class="errors">
                                    <button (click)="showErrorTags($event)" [class]="{'show': true, 'btn-icon': true, 'active': elasticMessage?.incidenceType}">
                                        <i class="material-icons incidence">bookmark</i>
                                    </button>
                                    <div class="user-img">
                                        <img [src]="'/assets/img/bot2.png'" alt="Bot" />
                                    </div>
                                    <ul class="error-list">
                                        <li *ngFor="let errorTag of errorTags; let i = index">
                                            <button (click)="setError(elasticMessage?.id, i)">{{errorTag?.name}}</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="message operator" *ngIf="elasticMessage?.messageOperator">
                    <div class="msg-operator-wrapper">
                        <div class="msg-operator">
                            <span *ngIf="elasticMessage?.messageOperatorType === 'text'" [innerHTML]="formatMessage(elasticMessage?.messageOperator)"></span>
                            <img *ngIf="elasticMessage?.messageOperatorType === 'image'" [src]="elasticMessage?.messageOperator" class="conversation-image-op" />
                            <a *ngIf="elasticMessage?.messageOperatorType === 'document'" class="conversation-image-op">
                                {{elasticMessage?.messageOperator}}
                            </a>
                        </div>
                    </div>
                    <div class="actions">
                        <div class="errors">
                            <div class="user-img">
                                <img [src]="'/assets/img/operator2.png'" alt="Bot" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="empty" *ngIf="conversations.length > 0 && (!messages || messages.length === 0)">
                No hay mensajes o no has seleccionado una conversación.
            </div>
            <div class="pagination clearfix">
                <button class="load-more" (click)="messagesLoadMore(messages[0]?.ticketId)" *ngIf="messagesCursor">Cargar más</button>
            </div>
        </div>
        <div class="properties" *ngIf="message">
            <div class="nlp">
                <div class="header">
                    NLP
                </div>
                <div class="info">
                    <div class="field-box">
                        <input [checked]="message?.nlpStatus" class="green" type="checkbox" />
                        <label>Habilitado</label>
                    </div>
                    <div>
                        Intencion: {{ message?.nlpLabel }}
                    </div>
                    <div>
                        Confianza: {{ message?.nlpConfidence }}
                    </div>
                </div>
            </div>
            <div class="flow">
                <div class="header">
                    Flujo
                </div>
                <div class="info">
                    <div>
                        Nombre del flujo: {{ message?.flowName }}
                    </div>
                    <div>
                        Tipo: {{ message?.flowType }}
                    </div>
                    <div>
                        Profundidad: {{ message?.flowDeep }}
                    </div>
                    <div>
                        Origen: {{ message?.flowOrigin }}
                    </div>
                </div>
            </div>
            <div class="conversation">
                <div class="header">
                    Conversación
                </div>
                <div class="info">
                    Ticket: {{ message?.ticketId }}
                </div>
            </div>
            <div class="entity">
                <div class="header">
                    Entidades
                </div>
                <div class="info">
                    <div class="entity" *ngIf="message?.entity1Name">
                        <span class="key">{{ message?.entity1Name }}</span>: <span class="value">{{ message?.entity1Value }}</span>
                    </div>
                    <div class="entity" *ngIf="message?.entity2Name">
                        <span class="key">{{ message?.entity2Name }}</span>: <span class="value">{{ message?.entity2Value }}</span>
                    </div>
                    <div class="entity" *ngIf="message?.entity3Name">
                        <span class="key">{{ message?.entity3Name }}</span>: <span class="value">{{ message?.entity3Value }}</span>
                    </div>
                    <div class="entity" *ngIf="message?.entity4Name">
                        <span class="key">{{ message?.entity4Name }}</span>: <span class="value">{{ message?.entity4Value }}</span>
                    </div>
                    <div class="entity" *ngIf="message?.entity5Name">
                        <span class="key">{{ message?.entity5Name }}</span>: <span class="value">{{ message?.entity5Value }}</span>
                    </div>
                </div>
            </div>
            <div class="incidence-desc">
                <div class="header">
                    Descripción de la incidencia
                </div>
                <div class="info">
                    {{ getIncidenceDescByType(message?.incidenceType)?.name }}
                </div>
            </div>
            <div class="help-box">
                <button class="btn-primary" (click)="redirectHelpCenter(message?.incidenceType)">
                    <i class="material-icons">help</i>
                    <span>Ayuda</span>
                </button>
            </div>
        </div>
        <div class="properties" *ngIf="!message">
            <div class="empty" *ngIf="conversations.length > 0 && messages.length > 0">
                No hay propiedades o no has seleccionado un mensaje.
            </div>
        </div>
    </div>
</section>

<span class="ruler"></span>


<!-- begin add phrase cdd -->
<jw-modal id="popup-add-phrase-cdd">
    <a class="close_modal" (click)="closeModal('popup-add-phrase-cdd')">×</a>
    <div class="add-intention">
        <h2 class="title">Frase</h2>

        <div class="full">
            <div class="field-box">
                <label for="">Selecciona una frase existente (opcional)</label>
                <select [(ngModel)]="currentPhraseId" (change)="changePhrase()">
                    <option value="">Selecciona una frase</option>
                    <option *ngFor="let intention of cdd.intentions" [value]="intention.id">
                        {{ intention.name }}
                    </option>
                </select>
                <small><b>Nota:</b> si no seleccionas una frase se creará una nueva</small>
            </div>
            <div class="field-box">
                <label for="">Nombre</label>
                <input [(ngModel)]="nameIntention" type="text" class="name-intention">
            </div>
            <div class="field-box">
                <label for="">Ejemplos</label>
                <button class="btn-base entity" (click)="openExamples()">+ Entidad</button>
                <div contenteditable="true" class="examples-list-box" (mouseup)="selectElementContents($event)" #examplesList></div>

                <div class="example-options-box" *ngIf="showExampleOptions" [style]="positionExampleOptions">
                    <select class="example-options">
                        <option value="entity">Entidades</option>
                    </select>
                    <div class="entities-box">
                        <ul class="entities-list">
                            <li class="entity-item" *ngFor="let entity of entities" (click)="selectEntityEvent(entity)">
                                {{ entity.name }}
                            </li>
                        </ul>
                        <div class="actions">
                            <button class="btn-secondary" (click)="closeExamples()"><span>Cancel</span></button>
                            <button class="btn-primary" (click)="openModal('popup-add-entity-cdd')"><span>+ Entidades</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="example-list">
                <div *ngFor="let elem of this.currentIntentions">
                    <p>Funcionalidad {{ elem.functName }}</p>
                    <ul *ngFor="let intention of elem.intentions">
                        <li *ngFor="let example of intention.ejemplos">
                            <a class="btn-secondary btn-icon material-icons" (click)="deleteFunctionalityPhrase(elem, example)">
                                delete
                            </a>
                            {{ example }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="modal-actions">
            <button class="btn-secondary" (click)="closeModal('popup-add-phrase-cdd')">Cancelar</button>
            <button type="submit" class="btn-primary" (click)="savePhrase()">Guardar frase</button>
        </div>
    </div>

</jw-modal>
<!-- end add phrase cdd -->

<!-- begin delete phrase cdd -->
<jw-modal id="popup-delete-phrase-cdd">
    <a class="close_modal" (click)="closeModal('popup-delete-phrase-cdd')">×</a>
    <div class="add-intention">
        <h2 class="title">Eliminar frase</h2>

        <div class="full">
            <div class="field-box">
                <label for="">Frase</label>
                <select [(ngModel)]="currentPhraseId" (change)="changePhrase()">
                    <option value="">Selecciona una frase</option>
                    <option *ngFor="let intention of cdd.intentions" [value]="intention.id">
                        {{ intention.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="modal-actions">
            <button class="btn-secondary" (click)="closeModal('popup-delete-phrase-cdd')">Cancelar</button>
            <button type="submit" class="btn-primary" (click)="deletePhrase()">Eliminar frase</button>
        </div>
    </div>

</jw-modal>
<!-- end add phrase cdd -->

<!-- begin add entity -->
<jw-modal id="popup-add-entity-cdd">
    <a class="close_modal" (click)="closeModal('popup-add-entity-cdd')">×</a>
    <div class="full">
        <div class="new-entity-box">
            <h2 class="title">Nueva entidad</h2>
            <div class="full">
                <div class="field-box">
                    <label for="">Nombre</label>
                    <span class="arroba">@</span>
                    <input [(ngModel)]="newEntity"  type="text" class="entity-name">
                </div>
            </div>
        </div>
    </div>

    <div class="modal-actions">
        <button class="btn-secondary" (click)="closeModal('popup-add-entity-cdd')">Cancelar</button>
        <button type="submit" class="btn-primary" (click)="createEntity()">Guardar</button>
    </div>
</jw-modal>
<!-- end modal delete user -->

<!-- begin xslx email -->
<jw-modal id="popup-xlsx-email">
    <a class="close_modal" (click)="closeModal('popup-xlsx-email')">×</a>
    <div class="full">
        <div class="new-entity-box">
            <h2 class="title">Generación de archivo en proceso</h2>
            <div class="full">
                <p>
                    Se esta generando el archivo con las conversaciones solicitadas, en cuanto este listo llegará al correo de esta cuenta.
                </p>
            </div>
        </div>
    </div>

    <div class="modal-actions">
        <button class="btn-secondary" (click)="closeModal('popup-xlsx-email')">Cerrar</button>
    </div>
</jw-modal>
<!-- end modal xslx email -->
