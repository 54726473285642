<section class="sectionInvitations">

    <div class="wrapper-title">
        <h1 class="title">{{translate.sections.customers.invitations.pendingInvitations}}</h1>
    </div>

    <div *ngIf="!existsInvitations" class="emptyInvitations">
        <h1>{{translate.sections.customers.invitations.noGuests}}</h1>
        <h4>{{translate.sections.customers.invitations.invitationBotMessage}}</h4>
    </div>

    <div class="divInvitations">
        <!-- CARD LIST -->
        <div class="card-row">
            <div *ngFor="let invitation of invitations" class="card-container" [hidden]="!invitation.invitee">
                <div class="card-container-inner" *ngIf="invitation.invitee">
                    <div class="card-container-inner-box">
                        <div class="box-link-card">
                            <div class="image-card"
                                [ngStyle]="{'background-image': invitation.tenant.image ? 'url(' + invitation.tenant.image + ')' : 'url(/assets/img/customer.jpg)'}">
                            </div>
                            <div class="info-card">
                                <span class="subtitle-card" title="{{invitation.invitee.name}}">
                                    {{translate.sections.customers.invitations.invitedBy}}: <br>
                                    <img class="avatar" *ngIf="invitation.invitee.avatar && invitation.invitee.avatar !== ''" [src]="invitation.invitee.avatar">
                                    <span class="invitee">{{invitation.invitee?.name}}</span>
                                </span>
                                <span class="title-card ellipsis" title="{{invitation.tenant.name}}">
                                    {{invitation.tenant.name}}
                                </span>
                            </div>
                        </div>
                        <div class="actions-buttons-bottom">
                            <button class="btn-secondary" (click)="rejectInvitation(invitation.id)">
                                {{translate.sections.customers.invitations.decline}}
                            </button>
                            <button class="btn-primary" (click)="acceptInvitation(invitation.id)">
                                {{translate.sections.customers.invitations.accept}}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
