import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';

import { Platform } from '../../../models/platform';
import { PlatformCatalog } from '../../../models/platform-catalog';

import { AuthServices } from '../../../services/auth.guard.service';
import { LoaderService } from '../../../services/loader.service';
import { PlatformService } from '../../../services/platform.service';
import { CustomerService } from '../../../services/customer.service';
import { AllowedRolService } from '../../../services/rol-service.service';
import { ModalService } from '../../modal/modal.service';

import tippy from 'tippy.js';

import { environment } from '../../../environments/environment';
declare var translate: any;
@Component({
    selector: 'app-show-platforms',
    templateUrl: './show-platforms.component.html',
    styleUrls: ['./show-platforms.component.scss']
})
export class ShowPlatformsComponent implements OnInit {

    currentItem = 'callCampaign';
    translate: any;

    slideNo = 0;
    withAnim = true;
    resetAnim = true;
    @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
    carouselConfig: NguCarouselConfig = {
        grid: { xs: 2, sm: 3, md: 4, lg: 4, all: 0 },
        load: 3,
        velocity: 0.2,
        point: {
            visible: true
        }
    };

    assets = environment.assets;
    urlParameter: any;
    platforms: Platform[] = [];
    platformsCatalog: PlatformCatalog[] = [];
    platformsForm: UntypedFormGroup;
    loadingPlatforms: true;
    role: any;
    authorizeRol = false;
    currentCustomerSlug: string;
    currentCustomerName: string;
    tempArr = Array;

    platformsBodyStyles: any;
    selectedPlatform: any;
    existsPlatforms = false;
    existsWhats = false;

    constructor(
        private fb: UntypedFormBuilder,
        private authService: AuthServices,
        private platformService: PlatformService,
        private customerService:CustomerService,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private loaderService: LoaderService,
        private allowedRoleService: AllowedRolService,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {
        this.platformsBodyStyles = {
            width: '764px',
            height: '780px',
            padding: '64px 32px 32px 32px',
            'background-color': '#f1f1f9'
        }
        this.platforms = [];
        this.translate = translate;
    }

    ngOnInit() {
        this.urlParameter = window.location.href;
        this.authService.verifyToken(JSON.stringify({
            user_id: this.authService.getIdGoogle(),
            token: this.authService.getToken(),
            auth_type: localStorage.getItem('Auth-Type')
        })).subscribe(
            (res: any) => {
                this.role = res.role.name;
                if (res.status === 'ACTIVE' && res.role.name !== 'CUSTOM') {
                    localStorage.setItem('photo', res.avatar);
                    this.authorizeRol = this.allowedRoleService.allowed(res.role.name, 'DEV');
                } else {
                    this.authService.logout();
                }

            },
            err => {
                console.log(err);
                this.authService.logout();
            }
        );

        // console.log(this.platforms);
        this.platformsForm = this.fb.group({
            platform: 'Plataforma',
            name: '',
            agree: null
        });
        this.getPlatformsCatalog();
        this.getUrlParam('customer_slug', (customerSlug) => {
            this.currentCustomerSlug = customerSlug;
            this.getCustomerBySlug(customerSlug);
        });
        this.cdr.detectChanges();
    }

    getCustomerBySlug(slug) {
        this.customerService.getBySlug(slug).subscribe(
            (res: any) => {
                this.currentCustomerName = res.tenant.name;
                // this.loaderService.close();
            },
            err => {
                console.error(err);
            }
        );
    }

    toggleActionsButtons(event) {
        const actionsButtons = event.target.parentElement.querySelector('.actions-buttons');
        if (actionsButtons.classList.contains('fadeIn')) {
            actionsButtons.classList.add('fadeOut');
            setTimeout(() => {
                actionsButtons.classList.remove('fadeIn');
            }, 500);
        } else {
            actionsButtons.classList.remove('fadeOut');
            actionsButtons.classList.add('fadeIn');
        }
    }

    selectPlatform(platform: any) {
        this.selectedPlatform = platform;
    }

    print(platformsCatalog) {
        console.log(platformsCatalog);
    }

    getPlatforms() {
        // console.log('getPlatforms');
        this.getUrlParam('customer_slug', (slug) => {
            // console.log(slug);
            this.platformService.getAll(slug).subscribe(
                res => {
                    // console.log(res);
                    // this.platforms = res;
                    for (const plat of res) {
                        if (plat.channel.channelId === 'smooch' || plat.channel.channelId === 'whatsapp' || plat.channel.channelId ==='Smooch SMS') {
                            // plat.channel.label = 'Whatsapp';
                            this.existsWhats = true;
                        }
                        // if (plat.channel.channelId !== 'whatsapp') {
                            this.platforms.push(plat);
                        // }
                    }
                    if (res.length > 0) {
                        this.existsPlatforms = true;
                        setTimeout(() => {
                            tippy('.to-diagram', {
                                content: 'Ir al constructor',
                                placement: 'bottom'
                            });
                        }, 1000);
                        setTimeout(() => {
                            tippy('.to-channels', {
                                content: 'Canales',
                                placement: 'bottom'
                            });
                        }, 1000);
                    }
                    // this.loaderService.close();
                },
                err => {
                    console.log(err);
                }
            );
        });
    }

    getPlatformsCatalog() {

        this.getUrlParam('customer_slug', (slug) => {
            // console.log(slug);
            this.platformService.getCatalog(slug).subscribe(
                res => {
                    // console.log(res);
                    // this.platformsCatalog = res;
                    for (const plat of res) {
                        if ((plat.hasOwnProperty('show') && plat.show) || !plat.hasOwnProperty('show')) {
                            this.platformsCatalog.push(plat);
                        }
                    }
                    this.getPlatforms();
                },
                err => {
                    console.log(err);
                }
            );
        });

    }

    addplatform() {

        /*const platform = new Platform();
        platform.channel = {
            name: this.platformsForm.value.platform.name,
            channelId: this.platformsForm.value.platform.name.toLowerCase(),
            label: this.platformsForm.value.name
        };
        platform.status = 'ACTIVE';*/

        const platformPayload = {
            // name: this.platformsForm.value.platform.name,
            name: this.selectedPlatform.name,
            // label: this.platformsForm.value.name,
            status: 'ACTIVE'
        };

        this.getUrlParam('customer_slug', (slug) => {
            // console.log(slug);
            this.platformService.add(platformPayload, slug).subscribe(
                (res: Platform) => {
                    // console.log(res);
                    let existsPlat = false;
                    for (const plat of this.platforms) {
                        if (plat.channel.channelId === res.channel.channelId) {
                            existsPlat = true;
                        }
                    }
                    if (!existsPlat) {
                        // this.platforms.push(res);
                        this.platforms = [];
                        this.getPlatforms();
                        localStorage.setItem('step2', 'true');
                        this.router.navigate([], {queryParams: {steps: 'updated'}});
                    }
                    // this.loaderService.close();
                    this.closeModal('popup-add-platform');
                },
                err => {
                    console.log(err);
                }
            );
        });

        // this.platformsCatalog.forEach(platform => {
        // if (this.platformsForm.value.platform === platform.name) {

        // }
        // });

    }


    redirectDiagrammer(platformSlug) {
        /*
        this.getUrlParam('customer_slug', (slug) => {
            this.router.navigate([
                '/customers',
                slug,
                'platforms',
                platformName,
                'diagram'
            ]);
        });
        */
        this.getUrlParam('customer_slug', (customerSlug) => {
            window.location.href = window.location.origin + '/customers/' + customerSlug + '/platforms/' + platformSlug + '/diagram';
        });
        // TODO: redirect with href
    }

    redirectChannels(platformSlug) {
        this.router.navigate(['/customers', this.currentCustomerSlug, 'platforms', platformSlug, 'channels']);
    }

    // popup
    openModal(id: string) {
        this.modalService.open(id);
        $('.divPopupPlatform .ng-value-label').css('color', '#333');
    }


    closeModal(id: string) {
        this.modalService.close(id);
        this.selectedPlatform = {};
    }

    tempArrAux(customersLength: any) {
        // this.tempArr.fill(Math.ceil(customersLength / 3));
        return this.tempArr(Math.ceil(customersLength / 3));
    }

    getUrlParam(param: string, success) {
        const paramInterval = setInterval(() => {
            if (this.route !== null) {
                if (this.route.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.route.paramMap.subscribe(params => {
                        const p = params.get(param);
                        success(p);
                    });
                }
            }
        }, 500);
    }

}
