

export const allowed = (roleService: any, roleResponsible: any) => {

    const roles = roleService.split('_');

    const isAdmin = roles.some(rol => rol === 'ADMIN');

    if (isAdmin === true) {
        return true;

    } else {
        const existRol = roles.some(rol => rol === roleResponsible);
        return existRol;
    }

};

