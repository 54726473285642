<section class="campaigns-box">
    <div class="campaigns-header-box" [ngClass]="{'campaign-wrapper': itemI}">
        <div class="campaigns-header">
            <h1 *ngIf="!itemI" class="title">
                {{translate.sections.campaigns.campaignsTitle}}
            </h1>

            <h2 *ngIf="itemI" class="title">
                <i class="material-icons">campaign</i>
                {{translate.sections.campaigns.campaignsTitle}}
            </h2>
        
            <div class="tabs">
                <ul>
                    <li>
                        <a [routerLink]="['/', 'customers', this.customerSlug, 'campaigns', 'showCampaigns']" [ngClass]="{'active': activeTab.showCampaigns}">
                            {{translate.sections.campaigns.myCampaigns}}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/', 'customers', this.customerSlug, 'campaigns', 'templateValidation']" [ngClass]="{'active': activeTab.templateValidation}">
                            {{translate.sections.campaigns.templateValidation}}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/', 'customers', this.customerSlug, 'campaigns', 'approvalStatus']" [ngClass]="{'active': activeTab.approvalStatus}">
                            {{translate.sections.campaigns.approvalStatus}}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/', 'customers', this.customerSlug, 'campaigns', 'sendCampaign']" [ngClass]="{'active': activeTab.sendCampaign}">
                            {{translate.sections.campaigns.sendingCampaigns}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <app-show-campaings *ngIf="activeTab.showCampaigns" [itemI]="itemI"></app-show-campaings>
    <app-validation-template *ngIf="activeTab.templateValidation"></app-validation-template>
    <app-status-approval *ngIf="activeTab.approvalStatus"></app-status-approval>
    <app-send-campaigns *ngIf="activeTab.sendCampaign"></app-send-campaigns>

</section>