<section class="sectionFilters">
    <div class="wrapper-title">
        <h1 class="title">{{translate.sections.customers.filterTypes.myFilterTypes}}</h1>
        <ul class="actions">
            <li>
                <button class="btn-primary" (click)="openModal('popup-create-filter-types')">
                    <span>{{translate.sections.customers.filterTypes.createNewFilterType}}</span>
                    <i class="material-icons md-24">add_circle</i>
                </button>
            </li>
        </ul>
    </div>

    <div *ngIf="!existsFilters" class="emptyFilters">
        <h1>{{translate.sections.customers.filterTypes.noFilterTypes}}</h1>
        <h4>{{translate.sections.customers.filterTypes.startMessage}}</h4>
    </div>

    <div *ngIf="existsFilters" class="boxFilters">
        <table class="main-table-content">
            <thead class="data-table-header">
                <tr class="data-table-row">
                    <td>{{translate.sections.customers.filterTypes.displayName}}</td>
                    <td>{{translate.sections.customers.filterTypes.type}}</td>
                    <td>{{translate.sections.customers.filterTypes.regexWords}}</td>
                    <td>{{translate.sections.customers.filterTypes.actions}}</td>
                </tr>
            </thead>
            <tbody class="data-table-content">
                <tr *ngFor="let filter of filters | paginate: { itemsPerPage: paginationFilters.pageSize, currentPage: paginationFilters.currentPage, totalItems: paginationFilters.totalItems, id: 'first' }"
                    class="data-table-row">
                    <td>
                        {{filter.displayName}}
                    </td>
                    <td>
                        {{getTypeName(filter.filterType)}}
                    </td>
                    <td>
                        {{filter.regex || filter?.wordList?.join(', ')}}
                    </td>
                    <td>
                        <div *ngIf="filter.filterType !== 'GOOGLE_INFOTYPE'">
                            <button class="btn-icon" (click)="openModal('popup-create-filter-types', filter)" title="Editar">
                                <i class="material-icons md-20">edit</i>
                            </button>
                            <button class="btn-icon" (click)="openModal('popup-filter-types-delete', filter.id)" title="Eliminar">
                                <i class="material-icons md-20">delete</i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <pagination-template #p2="paginationApi" (pageChange)="pageFiltersUpdate($event)" id="first">
            <ul class="pagination">
                <li [ngClass]="{'pagination-previous': true, 'disabled': p2.isFirstPage()}">
                    <a *ngIf="!p2.isFirstPage()" (click)="p2.previous()">
                        << {{translate.sections.customers.filterTypes.previous}} </a>
                            <span *ngIf="p2.isFirstPage()">
                                <span>
                                    << {{translate.sections.customers.filterTypes.previous}} </span>
                                </span>
                </li>

                <li *ngFor="let page of p2.pages" [class.current]="p2.getCurrent() === page.value">
                    <a (click)="p2.setCurrent(page.value)" *ngIf="p2.getCurrent() !== page.value">
                        <span>{{ page.label }}</span>
                    </a>
                    <span *ngIf="p2.getCurrent() === page.value">
                        <span>{{ page.label }}</span>
                    </span>
                </li>

                <li [ngClass]="{'pagination-next': true, 'disabled': p2.isLastPage()}">
                    <a *ngIf="!p2.isLastPage()" (click)="p2.next()">
                        {{translate.sections.customers.filterTypes.next}} >>
                    </a>
                    <span *ngIf="p2.isLastPage()">
                        <span>{{translate.sections.customers.filterTypes.next}} >></span>
                    </span>
                </li>
            </ul>
        </pagination-template>
    </div>
</section>

<!-- begin modal create filter types -->
<jw-modal id="popup-create-filter-types">
    <a class="close_modal" (click)="closeModal('popup-create-filter-types');">×</a>
    <div class="content-modal">
        <h2 class="title">{{translate.sections.customers.filterTypes.createFilterType}}</h2>

        <div>
            <div class="field-box">
                <label>{{translate.sections.customers.filterTypes.selectFilterType}}</label>
                <select class="type" [(ngModel)]="currentFilter.filterType">
                    <option selected value="CUSTOM_GOOGLE_INFOTYPE">{{translate.sections.customers.filterTypes.customized}}</option>
                    <option value="REGEX_GOOGLE_INFOTYPE">{{translate.sections.customers.filterTypes.regex}}</option>
                </select>
            </div>

            <div class="field-box">
                <label>{{translate.sections.customers.filterTypes.name}}</label>
                <input type="text" [(ngModel)]="currentFilter.name">
            </div>

            <div class="field-box">
                <label>{{translate.sections.customers.filterTypes.displayName}}</label>
                <input type="text" [(ngModel)]="currentFilter.displayName">
            </div>

            <div class="field-box">
                <label>{{translate.sections.customers.filterTypes.description}}</label>
                <input type="text" [(ngModel)]="currentFilter.description">
            </div>

            <div class="field-box" *ngIf="currentFilter.filterType === 'REGEX_GOOGLE_INFOTYPE'">
                <label>{{translate.sections.customers.filterTypes.regex}}</label>
                <input type="text" [(ngModel)]="currentFilter.regex">
            </div>

            <div class="field-box" *ngIf="currentFilter.filterType === 'CUSTOM_GOOGLE_INFOTYPE'">
                <label>{{translate.sections.customers.filterTypes.addWordsMessage}}</label>
                <mat-form-field class="example-chip-list">
                    <mat-chip-list #chipList>
                        <mat-chip *ngFor="let word of currentFilter.wordList" [selectable]="selectable"
                            [removable]="removable" (removed)="removeWord(word)">
                            {{word}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder={{translate.sections.customers.filterTypes.newWords}} [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="addWord($event)" type="text">
                    </mat-chip-list>
                </mat-form-field>
            </div>
        </div>

        <div class="modal-actions">
            <button (click)="saveFilter()" class="btn-primary">
                <span>{{translate.sections.customers.filterTypes.continue}}</span>
            </button>
        </div>
    </div>
</jw-modal>
<!-- end modal create filter types -->

<!-- begin modal confirm delete -->
<jw-modal id="popup-filter-types-delete">
    <a class="close_modal" (click)="closeModal('popup-filter-types-delete');">×</a>
    <h3>{{translate.sections.customers.filterTypes.confirmationMessage}}</h3>

    <div class="modal-actions">
        <button (click)="closeModal('popup-filter-types-delete')" class="btn-secondary">{{translate.sections.customers.filterTypes.cancel}}</button>
        <button (click)="deleteFilter()" class="btn-primary">{{translate.sections.customers.filterTypes.confirm}}</button>
    </div>

</jw-modal>
<!-- end modal confirm delete -->