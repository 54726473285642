import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input } from '@angular/core';
import { CampaignService } from '../../../services/campaign.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Campaign } from '../../../models/campaign';
import { ModalService } from '../../../components/modal/modal.service';
import * as MaterialDatetimePicker from '../../../assets/libraries/material-datetime-picker.js';
declare var MaterialDatetimePicker: any;
import tippy from 'tippy.js';
import { LoaderService } from '../../../services/loader.service';
// import { FormGroup } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AlertService } from '../../../services/alert.service';
import { CustomerService } from 'src/services/customer.service';
import { environment } from 'src/environments/environment';
import { TimezoneService } from 'src/services/inspector-gus/timezone.service';
import { DataLayerService } from 'src/services/data-layer.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare var translate: any;
@Component({
    selector: 'app-show-campaings',
    templateUrl: './show-campaings.component.html',
    styleUrls: ['./show-campaings.component.scss']
})
export class ShowCampaingsComponent implements OnInit, AfterViewInit {

    @Input() itemI: string;
    urlParameter: any;
    channelIDD: any;
    hsmID: any;
    tenantID: any;

    messageType: any;
    mediaType: any;

    countryForm: UntypedFormGroup;

    mensajeContent: any = '';

    picker = new MaterialDatetimePicker({});
    dateSchedule: any;

    campaigns: any = [];

    channels: any = [];

    arrayHsm: any = [];

    arrayParamsMessage: any = [];

    optionTzSaved: HTMLElement;
    optionChannelSaved: HTMLElement;
    optionHsmIdSaved: HTMLElement;
    // optionTypeDelivery: HTMLElement;

    existsCampaigns = false;

    slug: any;
    customerId: string;
    urlFile: any;
    idCampaign: any;
    newIdCampaign: any;

    insightsCampaign: any;
    insights: any;

    getCampaign = {
        name: '',
        windowTime: 0,
        defaultMessage: '',
        keywordRegex: '',
        fileUrl: '',
        hsmId: '',
        status: '',
        managerId: '',
        tenantChannelId: '',
        campaignType: 'FLASH_CAMPAIGN'
    };

    buttonR: any;
    page = 0;
    total = 0;
    perPage = 10;

    @ViewChild('loadDate') myDiv: ElementRef;

    @ViewChild('windowTime') windowTime: ElementRef;
    @ViewChild('windowTimeC') windowTimeC: ElementRef;
    @ViewChild('rangevalue1') rangevalue1: ElementRef;
    @ViewChild('rangevalue2') rangevalue2: ElementRef;
    @ViewChild('uploader') uploader: ElementRef;
    @ViewChild('nameCampaignC') nameCampaignC: ElementRef;
    @ViewChild('messageC') messageC: ElementRef;
    @ViewChild('keywordRegexC') keywordRegexC: ElementRef;
    @ViewChild('selectChannelC') selectChannelC: ElementRef;
    @ViewChild('selectHsmIdC') selectHsmIdC: ElementRef;
    @ViewChild('nameCampaign') nameCampaign: ElementRef;
    @ViewChild('message') message: ElementRef;
    @ViewChild('keywordRegex') keywordRegex: ElementRef;
    @ViewChild('selectChannel') selectChannel: ElementRef;
    @ViewChild('selectHsmId') selectHsmId: ElementRef;
    @ViewChild('buttonsendC') buttonsendC: ElementRef;

    files: any[] = [];

    tabActive: string;

    alltz: string[] = [];

    ngAfterViewInit() {
        document.addEventListener('DOMContentLoaded', this.setValueRange.bind(this));
        this.windowTimeC.nativeElement.addEventListener('input', this.setValueRange.bind(this));

        document.addEventListener('DOMContentLoaded', this.setValueRange2.bind(this));
        this.windowTime.nativeElement.addEventListener('input', this.setValueRange2.bind(this));

        tippy('[data-tippy-content]');
    }

    translate: any;
    flagPath: string;

    constructor(
        private fb: UntypedFormBuilder,
        private campaignService: CampaignService,
        private activateRoute: ActivatedRoute,
        private router: Router,
        private modalService: ModalService,
        private alertService: AlertService,
        private loaderService: LoaderService,
        private customerService: CustomerService,
        private timezoneService: TimezoneService,
        private dataLayerService: DataLayerService,
        private recaptchaV3Service: ReCaptchaV3Service
    ) {
        this.customerId = '';
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = 'Campaña -> MyCampaigns -> ';
    }

    ngOnInit() {

        this.urlParameter = window.location.href;
        this.tabActive = 'ALL';

        const parameterCustomerSlug = this.activateRoute.snapshot.paramMap.get('customer_slug');
        if (parameterCustomerSlug) {

            this.slug = parameterCustomerSlug;

            this.customerService.getBySlug(this.slug).subscribe(
                (res: any) => {
                    // console.log(res);
                    this.customerId = res.tenant.id;
                    // console.log(this.customerId);
                },
                err => {
                    console.log(err);
                }
            );

            this.activateRoute.queryParams.subscribe(params => {
                this.page = parseInt(params.page, 10) || 1;
                this.tabActive = params.tabActive || 'ALL';
                if (this.tabActive === 'ALL') {
                    this.getCampaigns(this.page);
                } else {
                    this.getCampaignsByStatus( this.tabActive, this.page);
                }
                window.scrollTo(0, 0);
                this.newIdCampaign = params.new;
            });
        }
        this.getAllTz();
        // console.log(parameterCustomerSlug);
        this.dataLayerService.setFlag(this.flagPath, 'start');
    }

    getAllTz() {
        this.timezoneService.getAll(this.slug).subscribe(
            (res: string[]) => {
                this.alltz = res;
            },
            err => {
                console.log(err);
            }
        );
    }


    redirectTemplateValidation() {
        this.dataLayerService.setFlag(this.flagPath, 'newTemplate');
        this.router.navigate(['/customers', this.slug, 'campaigns', 'templateValidation']);
    }

    getCampaigns(page: number = 0) {
        this.campaignService.get(this.slug, page, this.customerId).subscribe((response: any) => {
            const camps: [] = response.content;
            this.campaigns = this.setStatusName(camps);
            this.total = response.totalElements;
            if (this.campaigns.length > 0) {
                this.existsCampaigns = true;
            }
            for (const [idx, campaign] of this.campaigns.entries()) {
                if (campaign.id === this.newIdCampaign) {
                    this.campaigns[idx].new = true;
                } else {
                    this.campaigns[idx].new = false;
                }
            }
            this.showChannels();
        });
    }

    getCampaignsByStatus(status, page: number = 0) {
        this.campaignService.getByStatus(status, this.slug, page, this.customerId).subscribe((response: any) => {
            const camps: [] = response.content;
            this.campaigns = this.setStatusName(camps);
            this.total = response.totalElements;
            if (this.campaigns.length > 0) {
                this.existsCampaigns = true;
            }
            setTimeout(() => {
                tippy('[data-tippy-content]');
            }, 1000);
        });
    }

    pageUpdate(page) {
        this.page = page;
        const tabActive = this.tabActive;
        const queryParams: Params = { page, tabActive };
        this.router.navigate(
            [],
            {
                relativeTo: this.activateRoute,
                queryParams
            }
        );

        if (this.tabActive === 'ALL') {
            this.getCampaigns(this.page);
        } else {
            this.getCampaignsByStatus( this.tabActive, this.page);
        }
    }
    showChannels() {
        this.campaignService.getChannels(this.slug).subscribe(
            (res: any[]) => {
                // console.log('obtener channels');
                this.channels = res.filter(channel => channel.enabled);
                // console.log(this.channels);
                tippy('[data-tippy-content]');
            },
            err => {
                console.log(err);
            }
        );
    }

    getHsmId(channelId) {

        this.channelIDD = channelId;


        for (const channel of this.channels) {

            if (channel.id === channelId) {

                this.arrayHsm = [...channel.outboundMessages];
                break;

            } else {
                // this.arrayHsm.length = 0;
            }
        }
        // console.log('array outboundMessages');
        // console.log(this.arrayHsm);
    }


    typeMessage(typeMessage) {
        // console.log(typeMessage);

    }

    pad(str, max) {
        str = str.toString();
        return str.length < max ? this.pad('0' + str, max) : str;
    }

    formatDate(date) {
        const day = this.pad(date.getDate(), 2);
        const month = this.pad(date.getMonth() + 1, 2);
        const year = date.getFullYear();

        const hour = this.pad(date.getHours(), 2);
        const min = this.pad(date.getMinutes(), 2);
        const sec = this.pad(date.getSeconds(), 2);

        let stringDateTime = year + '-' + month + '-' + day;
        stringDateTime += 'T' + hour + ':' + min + ':' + sec + '.0000';

        return stringDateTime;
    }

    openCalendar() {

        this.picker.on('submit', (d) => {
            const DateFormat1 = String(d._d);
            const DateFormat2 = DateFormat1.split('GMT');

            const date = new Date(d._d);
            // const dateConvert = date.toISOString();
            const dateConvert = this.formatDate(date);
            window.localStorage.setItem('dateScheduled', dateConvert);

            $('.showDate').val(DateFormat2[0]);
        });
        this.picker.open();
    }

    setStatusName(campaigns) {
        const statusList = {
            CREATED: 'Creada',
            NOT_CREATED: 'No creada',
            SCHEDULED: 'Programada',
            CANCELLED: 'Cancelada',
            ERROR: 'Error',
            PROCESSING: 'Procesando',
            PROCESSED: 'Procesada',
            PAUSED: 'Pausada'
        };
        campaigns = campaigns.map(campaign => {
            campaign.status_name = statusList[campaign.status];
            // console.log(campaign);
            return campaign;
        });
        return campaigns;
    }

    // showCampaigns() {
    //     this.campaignService.get(this.slug, 0).subscribe(
    //         (res: any) => {
    //             console.log('listado de campañas');
    //             console.log(res);
    //             const camps: [] = res.content;
    //             this.campaigns = this.setStatusName(camps);
    //             this.totalElements = res.numberOfElements;

    //             if (this.campaigns.length > 0) {
    //                 this.existsCampaigns = true;
    //             }
    //         },
    //         err => {
    //             console.log(err);
    //         }
    //     );
    // }

    editCampaign(name, time, message, keywordRegex, channel, id, urlFile, statusLoad) {
        const getCampaign: any = {
            name: name.value,
            deliveryType: 'NOTIFICATION',
            windowTime: time.value * 60,
            defaultMessage: message.value,
            keywordRegex: keywordRegex.value,
            // fileUrl: '',
            // status: statusLoad.textContent,
            managerId: localStorage.getItem('constructorUserId'),
            tenantChannelId: channel.value,
            campaignType: 'FLASH_CAMPAIGN'
        };

        if (id.value !== '') {

            getCampaign.hsmId = id.value;

        } else {
            getCampaign.hsmId = this.hsmID;
        }

        // console.log('%c', 'color:green', getCampaign);
        const checkObject = Object.values(getCampaign);

        if (checkObject.includes('')) {
            getCampaign.status = 'NOT_CREATED';
        } else {
            getCampaign.status = 'CREATED';
        }

        this.urlFile === undefined ? getCampaign.fileUrl = this.getCampaign.fileUrl : getCampaign.fileUrl = this.urlFile;


        // console.log(this.idCampaign);
        // console.log(getCampaign);

        this.campaignService.update(JSON.stringify(getCampaign), this.slug, this.idCampaign, this.customerId).subscribe(
            res => {
                // console.log('campaña editada');
                this.modalService.close('popup-edit-campaign');
                if (this.tabActive === 'ALL') {
                    this.getCampaigns(this.page);
                } else {
                    this.getCampaignsByStatus( this.tabActive, this.page);
                }
                // console.log(res);
            },
            err => {
                console.log(err);
            }
        );

    }

    saveCampaign(nameCampaign, windowTime, defaultMessage, keywordRegex, selectChannel, hsmId) {


        // let deliveryType: any = '';
        // if (typeMessage.value === '') {
        //     deliveryType = 'NOTIFICATION';
        // } else {
        //     deliveryType = typeMessage.value;
        // }
        const dataCampaign: any = {
            name: nameCampaign.value,
            windowTime: windowTime.value * 60,
            defaultMessage: defaultMessage.value,
            keywordRegex: keywordRegex.value,
            fileUrl: this.urlFile,
            hsmId: hsmId.value,
            managerId: localStorage.getItem('constructorUserId'),
            tenantChannelId: selectChannel.value,
            deliveryType: 'NOTIFICATION',
            campaignType: 'FLASH_CAMPAIGN'
        };

        const checkObject = Object.values(dataCampaign);

        if (checkObject.includes('')) {
            dataCampaign.status = 'NOT_CREATED';
        } else {
            dataCampaign.status = 'CREATED';
        }

        // console.log('value data', dataCampaign);



        // descomentar
        this.campaignService.create(JSON.stringify(dataCampaign), this.slug, this.customerId).subscribe(
            res => {
                // console.log(res);
                this.modalService.close('popup-new-campaign');
                if (this.tabActive === 'ALL') {
                    this.getCampaigns(this.page);
                } else {
                    this.getCampaignsByStatus( this.tabActive, this.page);
                }
                this.arrayHsm.length = 0;
                $('.showMessage').text('');
                $('.showMessageType').text('');
                $('.showMediaType').text('');
                this.arrayParamsMessage = [];

            },
            err => {
                console.log(err);
            }
        );


        nameCampaign.value = '';
        windowTime.value = 1;
        defaultMessage.value = '';
        keywordRegex.value = '';
        selectChannel.value = '';
        hsmId.value = '';
        this.urlFile = '';
        // typeMessage.value = 'NOTIFICATION';


    }

    getParams(idMessage) {
        // console.log(idMessage);

        this.hsmID = idMessage;

        document.querySelector('#buttonUploadFile').removeAttribute('disabled');

        for (const outboundMessage of this.arrayHsm) {
            if (outboundMessage.id === idMessage) {
                this.tenantID = outboundMessage.tenantChannelId;
                this.mediaType = outboundMessage.mediaType;
                this.messageType = outboundMessage.messageType;
                this.mensajeContent = outboundMessage.messageContent;
                this.arrayParamsMessage = [...outboundMessage.params];
                // console.log('showing params', this.arrayParamsMessage);

            } else {
                // this.arrayParamsMessage.length = 0;
            }
        }

        // console.log('tenant id', this.tenantID);


        $('.showMessageType').text(translate.sections.campaigns.showCampaings.messageType + `: ${this.messageType}`);

        if (this.messageType === 'MEDIA') {
            $('.showMediaType').text(translate.sections.campaigns.showCampaings.mediaType + `: ${this.mediaType}`);
            this.arrayParamsMessage.push('media_url');

        } else {
            $('.showMediaType').text('');
        }
        let msjContent = this.mensajeContent;
        msjContent = msjContent.replaceAll(/{{(.*?)}}/g, '<span class="variable">$&</span>');
        msjContent = msjContent.replaceAll(/\*(.*?)\*/g, '<strong>$&</strong>').replaceAll('*', '');
        msjContent = msjContent.replaceAll(/\\n/g, '<br>');
        $('.showMessage').html(msjContent);

    }

    openSimpleModal(id, campaign) {
        this.modalService.open(id);
        this.insightsCampaign = campaign;
        let isWhatsappCloud = false;
        if (campaign.hasOwnProperty('tenantChannel') && campaign.tenantChannel.channel === 'WHATSAPP' && campaign.tenantChannel.channelSubtype === 'CLOUD') {
            isWhatsappCloud = true;
        }
        this.getCampaignInsights(campaign.id, isWhatsappCloud);
    }

    openModal(id: string, idCampaignParameter: any, action=null, buttonR=null, buttonUploadC=null) {
        this.buttonR = buttonR;
        if (buttonR) {
            buttonR.setAttribute('disabled', 'true');
        }

        if (buttonUploadC) {
            buttonUploadC.setAttribute('disabled', 'true');
        }

        if (action === 'crear') {
            this.nameCampaignC.nativeElement.value = '';
            this.windowTimeC.nativeElement.value = '1';
            this.setValueRange(1);
            this.messageC.nativeElement.value = '';
            this.keywordRegexC.nativeElement.value = '';
            this.selectChannelC.nativeElement.value = '';
            this.selectHsmIdC.nativeElement.value = '';
            this.deleteFile(0);
            this.mensajeContent = null;
            this.arrayParamsMessage = [];
        }
        this.modalService.open(id);
        this.idCampaign = idCampaignParameter;

        if (idCampaignParameter !== '') {
            if (action === 'reprogramar') {
                $('.titleProgramar').text('Reprogramar Fecha');

                this.campaignService.getOne(this.slug, idCampaignParameter, this.customerId).subscribe(
                    (res: any) => {
                        const scheduleZone = res.schedule.split('-');
                        $('.showDate').val(scheduleZone.slice(0, 3));
                        const selectTime: any = document.getElementById('selectTime');

                        for (const option of selectTime) {
                            if (option.text === res.tz) {
                                option.setAttribute('selected', 'selected');
                                this.optionTzSaved = option;
                            }
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            } else {
                $('.titleProgramar').text(translate.sections.campaigns.showCampaings.scheduleDate);
                $('.showDate').val('');
                $('#selectTime').val('');
            }

            if (id === 'popup-confirm') {
                this.dataLayerService.setFlag(this.flagPath, 'send');
                this.idCampaign = idCampaignParameter;
                this.modalService.close('popup-schedule');
            } else if (id === 'popup-confirmDelete') {
                this.idCampaign = idCampaignParameter;
            } else if (id === 'popup-confirmCancelCampaign') {
                this.idCampaign = idCampaignParameter;
            } else if (id === 'popup-edit-campaign') {
                try {
                    this.campaignService.getOne(this.slug, idCampaignParameter, this.customerId).subscribe(
                        (res: any) => {
                            console.log(res.windowTime / 60);
                            this.windowTime.nativeElement.value = res.windowTime / 60;
                            this.setValueRange2(this.windowTime.nativeElement.value);
                            this.getCampaign.name = res.name;
                            this.getCampaign.windowTime = res.windowTime / 60;
                            this.getCampaign.defaultMessage = res.defaultMessage;
                            this.getCampaign.keywordRegex = res.keywordRegex;
                            this.getCampaign.fileUrl = res.fileUrl;
                            if (res.hsm !== null) {
                                this.getCampaign.hsmId = res.hsm.id;
                                this.getCampaign.tenantChannelId = res.hsm.tenantChannelId;

                                this.channelIDD = res.hsm.tenantChannelId;
                                this.hsmID = res.hsm.id;
                            }

                            this.getCampaign.status = res.status;
                            this.getCampaign.managerId = localStorage.getItem('constructorUserId');

                            if (res.hsm !== null) {
                                const selectChannelLoad: any = document.getElementById('selectchannels');

                                for (const option of selectChannelLoad) {
                                    if (option.value === res.hsm.tenantChannelId) {
                                        option.setAttribute('selected', 'selected');
                                        this.optionChannelSaved = option;
                                    }
                                }

                                for (const channel of this.channels) {
                                    if (channel.id === res.hsm.tenantChannelId) {
                                        // console.log(channel.outboundMessages);
                                        this.arrayHsm = [...channel.outboundMessages];
                                        // console.log(this.arrayHsm);
                                    }


                                }
                            }
                        },
                        err => {
                            console.log(err);
                        }
                    );

                } catch (error) {

                }

                const selectHsmidLoaded = document.getElementById('selectHsmId');
            }
        }
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.arrayHsm.length = 0;
        this.arrayParamsMessage.length = 0;
        if (this.optionTzSaved !== undefined) {

            this.optionTzSaved.removeAttribute('selected');
        }
        if (this.optionChannelSaved !== undefined) {

            this.optionChannelSaved.removeAttribute('selected');
        }
        if (this.optionHsmIdSaved !== undefined) {

            this.optionHsmIdSaved.removeAttribute('selected');
        }
        // if (this.optionTypeDelivery !== undefined) {

        //     this.optionTypeDelivery.removeAttribute('selected');
        // }
    }

    gotoEditCampaign(campaignId: string, tenantId: string) {
        this.dataLayerService.setFlag(this.flagPath, 'edit');
        this.router.navigate(['/customers', this.slug, 'campaigns', 'sendCampaign'], { queryParams: { campaignId, tenantId }});
    }

    timeConvertToHours(n) {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);

        if (rhours === 0) {
            return rminutes + ' min';
        } else if (rhours !== 0) {
            if ((rminutes === 0)) {
                if (rhours === 1) {
                    return rhours + ' hora';
                } else {
                    return rhours + ' horas';
                }
            } else {
                return rhours + ' horas ' + rminutes + ' min';
            }
        }

        // return rhours !== 0 ? rhours + " hora y " + rminutes + " minutos." : rminutes + " minutos." ;
    }

    convertDate(datetime) {
        const date = new Date(datetime);
        const year = date.getFullYear();
        let month: any = date.getMonth() + 1;
        let dt: any = date.getDate();

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        const getDateConvert = year + '-' + month + '-' + dt;
        return getDateConvert;
    }

    convertDateTime(dateParameter) {

        if (dateParameter) {
            try {
                const event = new Date(dateParameter);
                const options : any = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                };
                return event.toLocaleDateString(navigator.language, options);
            } catch (error) { }
        }
    }

    deleteCampaign() {
        this.campaignService.delete(this.slug, this.idCampaign, this.customerId).subscribe(
            res => {
                // console.log('deleted');
                this.modalService.close('popup-confirmDelete');
                // console.log(res);
                if (this.tabActive === 'ALL') {
                    this.getCampaigns(this.page);
                } else {
                    this.getCampaignsByStatus( this.tabActive, this.page);
                }
                this.dataLayerService.setFlag(this.flagPath, 'delete');
            },
            err => {
                console.log(err);
            }
        );
    }


    cancelCampaign() {

        this.campaignService.update(JSON.stringify({ status: 'CANCELLED' }), this.slug, this.idCampaign, this.customerId).subscribe(
            res => {
                // console.log('cancelled campaign');
                this.modalService.close('popup-confirmCancelCampaign');
                // console.log(res);
                if (this.tabActive === 'ALL') {
                    this.getCampaigns(this.page);
                } else {
                    this.getCampaignsByStatus( this.tabActive, this.page);
                }
                this.dataLayerService.setFlag(this.flagPath, 'cancel');
            },
            err => {
                console.log(err);
            }
        );
    }

    functionSendNow() {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const dateTimeNow = this.formatDate(new Date()); // .toISOString();
        // console.log(dateTimeNow);
        // const splitDate = dateTimeNow.split('.');

        // const removez = splitDate[0];
        // console.log('fecha enviar ahora');

        // console.log(removez);


        const dataSchedule = {
            schedule: dateTimeNow, // removez + '.0000',
            tz: timeZone,
            status: 'SCHEDULED'
        };
        // console.log(JSON.stringify(dataSchedule));
        // console.log(this.idCampaign);
        this.campaignService.update(JSON.stringify(dataSchedule), this.slug, this.idCampaign, this.customerId).subscribe(
            res => {
                // console.log(res);
                if (this.tabActive === 'ALL') {
                    this.getCampaigns(this.page);
                } else {
                    this.getCampaignsByStatus( this.tabActive, this.page);
                }
            },
            err => {
                console.log(err);
            }
        );
        this.modalService.close('popup-confirm');
    }

    functionSendDate(timezone) {

        const dateScheduleLocal = localStorage.getItem('dateScheduled');
        // const splitDate = dateScheduleLocal.split('.');

        // const removez = splitDate[0];


        const dataSchedule = {
            schedule: dateScheduleLocal, // removez + '.0000',
            tz: timezone.options[timezone.selectedIndex].text,
            status: 'SCHEDULED'
        };
        // console.log(JSON.stringify(dataSchedule));

        this.campaignService.update(JSON.stringify(dataSchedule), this.slug, this.idCampaign, this.customerId).subscribe(
            res => {
                // console.log(res);
                if (this.tabActive === 'ALL') {
                    this.getCampaigns(this.page);
                } else {
                    this.getCampaignsByStatus( this.tabActive, this.page);
                }
                this.dataLayerService.setFlag(this.flagPath, 'schedule');
            }
        );

        // console.log(this.idCampaign);
        $('.showDate').val('');
        timezone.value = '';
        this.modalService.close('popup-schedule');
    }

    functionCancel(id) {
        this.modalService.close(id);
    }

    setValueRange(ev) {
        const windowTimeC = this.windowTimeC.nativeElement;
        const rangevalue1 = this.rangevalue1.nativeElement;
        const newValue = Number((windowTimeC.value - windowTimeC.min) * 100 / (windowTimeC.max - windowTimeC.min));
        const newPosition = 10 - (newValue * 0.2);
        rangevalue1.querySelector('span').innerHTML = `${windowTimeC.value} ` + (windowTimeC.value === '1' ? 'hr' : 'hrs');
        rangevalue1.style.left = `calc(${newValue}% + (${newPosition}px))`;
    }

    setValueRange2(ev) {
        const windowTimeC = this.windowTime.nativeElement;
        const rangevalue2 = this.rangevalue2.nativeElement;
        const newValue = Number((windowTimeC.value - windowTimeC.min) * 100 / (windowTimeC.max - windowTimeC.min));
        const newPosition = 10 - (newValue * 0.2);
        rangevalue2.querySelector('span').innerHTML = `${windowTimeC.value} ` + (windowTimeC.value === '1' ? 'hr' : 'hrs');
        rangevalue2.style.left = `calc(${newValue}% + (${newPosition}px))`;
    }

    /**
     * Functions for drag and drop upload
     */

    uploadFile(files) {
        // const filefind = $event.target.files[0];

        if (files.length > 0) {
            const FormFiles = new FormData();
            FormFiles.append('file', files[0]);

            // console.log('%c archivo subiendo', 'color:red', files[0]);

            // console.log('%c archivo formdata','color:red',FormFiles);

            // console.log('%c archivo existentes', 'color:cyan', this.slug);
            // console.log('%c archivo existentes channel id', 'color:cyan', this.channelIDD);
            // console.log('%c archivo existentes hsmid', 'color:cyan', this.hsmID);

            // descomentar
            // console.log(this.customerId);
            this.recaptchaV3Service.execute('uploadExcel').subscribe((reToken) => {
                this.campaignService.uploadFileCampaign(this.slug, FormFiles, this.channelIDD, this.hsmID, this.customerId, reToken).subscribe({
                    next: (res: any) => {
                        // console.log(res);
                        let msg = '';
                        this.urlFile = res.file.url;
                        // $('.urlS3').text(`URL: ${res.file.url}`);
                        if (res.skipped.length > 0) {
                            res.validCount === 1 ? msg = translate.sections.campaigns.showCampaings.validRow : translate.sections.campaigns.showCampaings.validRows;
                            this.alertService.warning(translate.sections.campaigns.showCampaings.rowInvalidate + `
                            : ${res.skipped} ` + translate.sections.campaigns.showCampaings.foundMessage +
                            ` ${res.validCount}  ${msg}`);
                            this.uploader.nativeElement.value = '';
                            this.deleteFile(0);
                        } else {
                            this.alertService.success(translate.sections.campaigns.showCampaings.fileUploaded);
                            this.uploader.nativeElement.value = '';
                            this.buttonsendC.nativeElement.removeAttribute('disabled');
                        }
                    },
                    error: err => {
                        this.uploader.nativeElement.value = '';
                        this.alertService.error(translate.sections.campaigns.showCampaings.invalidatedMessage);
                        this.deleteFile(0);
                        console.log(err);
                    }
                });
            });
        }

    }

    onFileDropped($event) {
        // console.log('function: onFileDropped');
        // console.log($event);
        this.prepareFilesList($event);
    }

    fileBrowseHandler(event) {
        const files = event.target.files;
        // console.log('function: fileBrowseHandler');
        // console.log(files);
        this.prepareFilesList(files);
    }

    deleteFile(index: number) {
        $('.urlS3').text('');
        this.files.splice(index, 1);
    }

    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            this.files.push(item);
        }
        this.uploadFile(files);
    }

    formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    pageChanged() {
        tippy('[data-tippy-content]');
    }

    showTab(tab) {
        if (this.tabActive !== 'tab') {
            // console.log('load tab: ' + tab);
            switch (tab) {
                case 'ALL':
                    this.getCampaigns(0);
                    break;
                case 'PROCESSED':
                    this.dataLayerService.setFlag(this.flagPath, 'sended');
                case 'CANCELLED':
                    this.dataLayerService.setFlag(this.flagPath, 'cancelled');
                case 'SCHEDULED':
                    this.dataLayerService.setFlag(this.flagPath, 'scheduled');
                    this.getCampaignsByStatus(tab, 0);
                    break;
            }
            this.tabActive = tab;
            this.page = 0;
        }
    }

    getCampaignInsights(campaignId, isWhatsappCloud) {
        this.campaignService.getCampaignInsights(this.slug, campaignId, isWhatsappCloud).subscribe(
            (res: any) => {
                this.insights = res;
                this.dataLayerService.setFlag(this.flagPath, 'metrics');
            }, (err: any) => {
                this.alertService.error('Error al consultar las métricas');
            }
        );
    }

    downloadReportByStatus(campaign, status) {
        let isWhatsappCloud = false;
        if (campaign.hasOwnProperty('tenantChannel') && campaign.tenantChannel.channel === 'WHATSAPP' && campaign.tenantChannel.channelSubtype === 'CLOUD') {
            isWhatsappCloud = true;
        }
        this.campaignService.downloadReportByStatus(this.slug, campaign.id, status, isWhatsappCloud).subscribe(
            (res: any) => {
                const responseBlob = new Blob([res.csv]);
                const blobUrl = URL.createObjectURL(responseBlob);
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = `${status}-${campaign.id}.csv`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        );
    }

    pauseCampaign(idCampaign) {
        const payload = {
            status: 'PAUSED'
        };
        this.campaignService.update(JSON.stringify(payload), this.slug, idCampaign, this.customerId).subscribe(
            res => {
                this.alertService.success('Se ha pausado la campaña');
                if (this.tabActive === 'ALL') {
                    this.getCampaigns(this.page);
                } else {
                    this.getCampaignsByStatus( this.tabActive, this.page);
                }
            },
            err => {
                console.log(err);
                this.alertService.success('Hubo un erro al pausar la campaña');
            }
        );
    }
}
