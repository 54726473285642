import { Injectable } from '@angular/core';
import { AlertService } from '../alert.service';
import { DomSanitizer } from "@angular/platform-browser";
import { SecurityContext } from '@angular/core';
import { FileService } from '../file.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

var channels = [];
var currentData = {
    channelId: '',
    hsmId: '',
    channelOwnerId: '',
    params: []
};

@Injectable({
  providedIn: 'root'
})
export class HsmService {

    private alertService: AlertService;
    private field: any;
    private customerSlug: string;
    private maxSize: number;
    private allowedMimeTypes: any;
    private domSanitizer: DomSanitizer;
    private fileService: FileService;
    private recaptchaV3Service: ReCaptchaV3Service;

    constructor(customerSlug, alert, domSanitizer, fileService, recaptchaV3Service) {
        this.fileService = fileService;
        this.recaptchaV3Service = recaptchaV3Service;
        this.customerSlug = customerSlug;
        this.alertService = alert;
        this.domSanitizer = domSanitizer;
        this.maxSize = 16000000; // 16MB

        this.allowedMimeTypes = {
            image: [
                'image/jpeg',
                'image/png'
            ],
            sticker: [
                'image/webp'
            ],
            video: [
                'video/mp4',
                'video/3gpp'
            ],
            audio: [
                'audio/aac',
                'audio/mp4',
                'audio/amr',
                'audio/mpeg',
                'audio/ogg',
                'audio/opus'
            ]
        }
    }

    getFileTypeByExtension(url) {
        let fileType = '';
        const regexImgage = /\.jpeg|\.jpg|\.png|\.gif|\.svg$/;
        const regexAudio = /\.ogg|\.webm|\.mp4$/;
        const regexVideo = /\.wav|\.mp3$/;
        if (regexImgage.test(url)) {
            fileType = 'image';
        } else if (regexVideo.test(url)) {
            fileType = 'video';
        } else if (regexAudio.test(url)) {
            fileType = 'audio';
        }
        return fileType;
    }

    getChannels(channelSelect, hsmSelect, paramsDiv, previewButton, previewBtnIcon, dropZoneDiv, iconImg, dragStrong, oStrong, clickSpan, uploadButton, previewDiv, previewLoaderImage, previewImage, previewVideo, previewAudio, previewLink, multimediaDiv) {
        const url = '/coreapi/v2/tenantChannels/whatsappChannels?outputType=simpleMap';
        const currentObj = this;

        fetch(url, {
            method: 'GET',
            headers: {
                'Auth-Type': localStorage.getItem('Auth-Type'),
                'Authorization': 'Basic ' + btoa(localStorage.getItem('id') + ':' + localStorage.getItem('token')),
                'Slug': this.customerSlug

            }
        })
        .then((resp) => resp.json())
        .then((data) => {
            channels = data;
            channels.forEach(channel => {
                if (channel.channel === 'WHATSAPP') {
                    const channelLabel = this.domSanitizer.sanitize(SecurityContext.HTML, channel.label);
                    const channelId = this.domSanitizer.sanitize(SecurityContext.HTML, channel.id);
                    const option = `<option value="${channelId}">${channelLabel}</option>`;
                    channelSelect.append(option);
                }
            });

            channelSelect.removeAttr('disabled');
            hsmSelect.removeAttr('disabled');


            const value = currentObj.field.val();

            if (value) {
                const jsonValue = JSON.parse(value);
                currentData = jsonValue;
                channelSelect.find('option[value="' + jsonValue.channelId + '"]').prop('selected', true);
                const currentChannel = currentObj.getChannelById(jsonValue.channelId);

                hsmSelect.empty();
                paramsDiv.empty();

                currentChannel.outboundMessages.forEach(message => {
                    const option = `<option value="${message.id}">${message.name}</option>`;
                    hsmSelect.append(option);
                });

                hsmSelect.find('option[value="' + jsonValue.hsmId + '"]').prop('selected', true);

                const currentHsm = currentObj.getHsmByChannelIdAndHsmId(jsonValue.channelId, jsonValue.hsmId);

                paramsDiv.empty();

                const paramsLabel = $('<p class="label">Parámetros</p>');
                paramsDiv.append(paramsLabel);

                if (currentHsm.params.length > 0) {
                    currentHsm.params.forEach((param, idx) => {
                        const placeholder = this.domSanitizer.sanitize(SecurityContext.HTML, currentObj.setFormatParam(param));
                        const paramVal = this.domSanitizer.sanitize(SecurityContext.HTML, jsonValue.params[idx]);
                        const idx2 = this.domSanitizer.sanitize(SecurityContext.HTML, idx);
                        const paramInput = $('<input type="text" value="' + paramVal + '" data-idx="' + idx2 + '" placeholder="' + placeholder + '" />');
                        paramsDiv.append(paramInput);
                    });
                }
                if (currentHsm.messageType === 'MEDIA') {
                    previewButton.append(previewBtnIcon);

                    dropZoneDiv.append([iconImg, dragStrong, oStrong, clickSpan, uploadButton]);
                    previewDiv.append([previewLoaderImage, previewImage, previewVideo, previewAudio, previewLink, previewButton]);
                    multimediaDiv.append([dropZoneDiv, previewDiv]);
                    paramsDiv.append(multimediaDiv);

                    dropZoneDiv.hide();
                    previewDiv.css('display', 'block');
                    previewLoaderImage.hide();
                    // console.log(jsonValue.params);
                    const fileUrl = jsonValue.params[jsonValue.params.length-1];
                    // console.log(fileUrl);
                    previewLink.text(fileUrl);
                    previewLink.attr('href', fileUrl);

                    previewImage.hide();
                    previewVideo.hide();
                    previewAudio.hide();
                    // cambiar el tipo, obtenerlo por la extension del archivo

                    const fileType = currentObj.getFileTypeByExtension(fileUrl);
                    if (fileType === 'image') {
                        previewImage.attr('src', fileUrl);
                        previewImage.show();
                    } else if (fileType === 'video') {
                        previewVideo.attr('src', fileUrl);
                        previewVideo.show();
                    } else if (fileType === 'audio') {
                        previewAudio.attr('src', fileUrl);
                        previewAudio.show();
                    }
                }
            } else {
                currentData = {
                    channelId: '',
                    hsmId: '',
                    channelOwnerId: '',
                    params: []
                };
            }
        })
        .catch(function(error) {
            console.log(error);
            currentObj.alertService.error('Hubo un problema al cargar los canales');
        });
    }

    getChannelById(id) {
        let currentChannel = null;
        channels.forEach(channel => {
            if (channel.id === id) {
                currentChannel = channel;
            }
        });
        return currentChannel;
    }

    getHsmByChannelIdAndHsmId(channelId, hsmId) {
        let currentHsm = null;
        channels.forEach(channel => {
            if (channel.id === channelId) {
                channel.outboundMessages.forEach(hsm => {
                    if (hsm.id === hsmId) {
                        currentHsm = hsm;
                    }
                });
            }
        });

        return currentHsm;
    }

    setFormatParam(param) {
        param = param.replaceAll('_', ' ');
        param = param.replaceAll('=', ' = ');
        return 'Parámetro ' + param;
    }

    // Multimedia

    dropHandler(event, previewImage, previewVideo, previewLink, previewLoaderImage, previewAudio, dropZoneDiv, previewDiv) {
        event.preventDefault();
        const ev = event.originalEvent;

        if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    const file = ev.dataTransfer.items[i].getAsFile();
                    if (file.size <= this.maxSize) {
                        previewLoaderImage.fadeIn();
                        dropZoneDiv.fadeOut();
                        previewDiv.fadeIn();
                        previewLink.text(file.name);
                        previewLink.removeAttr('href');
                        const type = this.getTypeFileByMimeType(file.type);
                        this.uploadFile(file, previewImage, previewVideo, previewLoaderImage, previewAudio, type);
                    } else {
                        this.removeDragData(ev);
                        this.alertService.error(`El archivo debe pesar menos de 16MB`);
                    }
                }
            }
        } else {
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                if (ev.dataTransfer.files[i].size <= this.maxSize) {
                    previewLoaderImage.fadeIn();
                    dropZoneDiv.fadeOut();
                    previewDiv.fadeIn();
                    previewLink.text(ev.dataTransfer.files[i].name);
                    previewLink.removeAttr('href');
                    const type = this.getTypeFileByMimeType(ev.dataTransfer.files[i].type);
                    this.uploadFile(ev.dataTransfer.files[i], previewImage, previewVideo, previewLoaderImage, previewAudio, type);
                } else {
                    this.removeDragData(ev);
                    this.alertService.error(`El archivo debe pesar menos de 16MB`);
                }
            }
        }
    }

    dragOverHandler(ev) {
        ev.preventDefault();
    }

    removeDragData(ev) {
        if (ev.dataTransfer.items) {
            ev.dataTransfer.items.clear();
        } else {
            ev.dataTransfer.clearData();
        }
    }

    removeFile(previewImage, previewVideo, previewAudio, previewLink, dropZoneDiv, previewDiv) {
        previewImage.removeAttr('src');
        previewLink.text('');
        previewLink.removeAttr('href');
        previewVideo.removeAttr('src');
        previewImage.hide();
        previewVideo.hide();
        previewAudio.removeAttr('src');
        previewAudio.hide();
        previewDiv.fadeOut();
        dropZoneDiv.fadeIn();
        this.field.val('');
    }

    uploadFile(file, previewImage, previewVideo, previewLoaderImage, previewAudio, type) {

        previewImage.hide();
        previewVideo.hide();
        previewAudio.hide();
        const formFiles = new FormData();
        formFiles.append('document', file);

        this.recaptchaV3Service.execute('uploadFile').subscribe((reToken) => {
            this.fileService.upload(this.customerSlug, formFiles, reToken).subscribe({
                next: (response: any) => {
                    if (type.includes('image')) {
                        previewImage.attr('src', response.url);
                        previewImage.show();
                    } else if (type.includes('video')) {
                        previewVideo.attr('src', response.url);
                        previewVideo.show();
                    } else if (type.includes('audio')) {
                        previewAudio.attr('src', response.url);
                        previewAudio.show();
                    }
                    previewLoaderImage.fadeOut();

                    currentData.params[currentData.params.length - 1] = response.url;
                    this.field.val(JSON.stringify(currentData));
                },
                error: (err) => {
                    this.alertService.error('Hubo un problema al subir el documento.');
                    console.error('Error:', err);
                }
            });
        });
    }

    getTypeFileByMimeType(mimeType) {
        let fileType = '';

        for (const typeName in this.allowedMimeTypes) {
            if ('typeName' in this.allowedMimeTypes) {
                // console.log('this.allowedMimeTypes.typeName: ', this.allowedMimeTypes);
                const type = this.allowedMimeTypes[typeName];
                type.forEach(mt => {
                    if (mimeType === mt) {
                        fileType = typeName;
                    }
                });
            }
        }

        if (fileType === '') {
            fileType = 'document';
        }

        return fileType;
    }

    openFileSelector(previewImage, previewVideo, previewLink, previewLoaderImage, previewAudio, dropZoneDiv, previewDiv) {
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = _ => {
            let files = Array.from(input.files);
            if (files[0].size <= this.maxSize) {
                previewLoaderImage.fadeIn();
                dropZoneDiv.fadeOut();
                previewDiv.fadeIn();
                previewLink.text(files[0].name);
                previewLink.removeAttr('href');
                const type = this.getTypeFileByMimeType(files[0].type);
                this.uploadFile(files[0], previewImage, previewVideo, previewLoaderImage, previewAudio, type);
            } else {
                this.alertService.error(`El archivo debe pesar menos de 16MB`);
            }
        };
        input.click();
    }

    // Principal

    getHsm(field) {
        const containerDiv = $('<div class="hsm" />');

        const channelLabel = $('<p class="label">Canal</p>');
        const channelSelect = $('<select class="channelId" disabled="true" />');
        channelSelect.append('<option>Selecciona un canal</option>');
        const hsmLabel = $('<p class="label">Mensaje</p>');
        const hsmSelect = $('<select class="hsmId"  disabled="true" />');
        hsmSelect.append('<option>Selecciona un mensaje</option>');
        const paramsDiv = $('<div class="params" />');

        const multimediaDiv = $('<div class="multimedia" />');
        const dropZoneDiv = $('<div class="drop-zone" />');
        const iconImg = $('<i class="material-icons">description</i>');
        const dragStrong = $('<strong>Arrastra y suelta tu archivo aquí</strong>');
        const oStrong = $('<strong>o</strong>');
        const clickSpan = $('<span>Da </span>');
        const uploadButton = $('<button class="button-upload-file">clic aquí para seleccionar</button>');
        const previewDiv = $('<div class="preview" />');
        const previewImage = $('<img class="image" />');
        const previewVideo = $('<video controls />');
        const previewAudio = $('<audio controls />');
        const previewLoaderImage = $('<img class="loader" src="/assets/img/loading_dark.svg" />');
        const previewLink = $('<a target="_blank" />');
        const previewButton = $('<button />');
        const previewBtnIcon = $('<i class="material-icons">delete_forever</i>');

        this.getChannels(channelSelect, hsmSelect, paramsDiv, previewButton, previewBtnIcon, dropZoneDiv, iconImg, dragStrong,
                         oStrong, clickSpan, uploadButton, previewDiv, previewLoaderImage, previewImage, previewVideo, previewAudio,
                         previewLink, multimediaDiv);

        // Carga servicio para obtener canales y cargarlos en channelSelect

        channelSelect.on('change', (e)=> {
            const channel = $(e.target);
            const currentChannel = this.getChannelById(channel.val());

            // Carga servicio para obtener hsms y cargarlos en hsmSelect

            currentData.channelId = currentChannel.id;
            currentData.hsmId = '';
            currentData.channelOwnerId = currentChannel.channelOwnerId;
            currentData.params = [];
            this.field.val(JSON.stringify(currentData));

            hsmSelect.empty();
            paramsDiv.empty();

            hsmSelect.append('<option>Selecciona un mensaje</option>');

            currentChannel.outboundMessages.forEach(message => {
                const messageId = this.domSanitizer.sanitize(SecurityContext.HTML, message.id);
                const messageName = this.domSanitizer.sanitize(SecurityContext.HTML, message.name);
                const option = `<option value="${messageId}">${messageName}</option>`;
                hsmSelect.append(option);
            });
        });

        hsmSelect.on('change', (e)=> {
            const channelId = $('.hsm .channelId').val();
            const hsmId = $('.hsm .hsmId').val();
            const currentHsm = this.getHsmByChannelIdAndHsmId(channelId, hsmId);

            paramsDiv.empty();
            this.removeFile(previewImage, previewVideo, previewAudio, previewLink, dropZoneDiv, previewDiv);

            const paramsLabel = $('<p class="label">Parámetros</p>');
            paramsDiv.append(paramsLabel);

            currentData.hsmId = currentHsm.id;
            currentData.params = currentHsm.params;
            this.field.val(JSON.stringify(currentData));

            // Revisa los parametros del hsm y crearlos para que los llene el usuario
            if (currentHsm.params.length > 0) {
                currentHsm.params.forEach((param, idx) => {
                    const placeholder = this.domSanitizer.sanitize(SecurityContext.HTML, this.setFormatParam(param));
                    const idx2 = this.domSanitizer.sanitize(SecurityContext.HTML, idx);
                    const paramInput = $('<input type="text" data-idx="' + idx2 + '" placeholder="' + placeholder + '" />');
                    paramsDiv.append(paramInput);

                    currentData.params[idx] = paramInput.val();

                    paramInput.on('change', () => {
                        const index = paramInput.data('idx');
                        currentData.params[index] = paramInput.val();
                        this.field.val(JSON.stringify(currentData));
                    });
                });
            }
            if (currentHsm.messageType === 'MEDIA') {
                previewButton.append(previewBtnIcon);

                dropZoneDiv.append([iconImg, dragStrong, oStrong, clickSpan, uploadButton]);
                previewDiv.append([previewLoaderImage, previewImage, previewVideo, previewAudio, previewLink, previewButton]);
                multimediaDiv.append([dropZoneDiv, previewDiv]);
                const mediaParamLabel = $('<p class="label">Archivo multimedia</p>');
                paramsDiv.append([mediaParamLabel, multimediaDiv]);
            }
        });

        dropZoneDiv.on('drop', (event: any) => {
            this.dropHandler(event, previewImage, previewVideo, previewLink, previewLoaderImage, previewAudio, dropZoneDiv, previewDiv);
        });

        dropZoneDiv.on('dragover', (event: any) => {
            this.dragOverHandler(event);
        });

        uploadButton.on('click', (event: any) => {
            this.openFileSelector(previewImage, previewVideo, previewLink, previewLoaderImage, previewAudio, dropZoneDiv, previewDiv);
        });

        previewButton.on('click', (event: any) => {
            this.removeFile(previewImage, previewVideo, previewAudio, previewLink, dropZoneDiv, previewDiv);
        });

        const nameClass = field.attr('name').replace('#', '-');
        containerDiv.addClass(nameClass);
        containerDiv.append([channelLabel, channelSelect, hsmLabel, hsmSelect, paramsDiv, field]);

        this.field = field;

        return containerDiv;
    }
}
