import { Injectable } from '@angular/core';
import { Entity } from 'src/models/entity';
import * as joint from '../../../vendor/rappid';
import { AlertService } from '../alert.service';
import { ModalService } from './modal.service';
declare var translate: any;
// declare var $: any;
declare var lastSelectionPhrase: any;
declare var Sortable: any;

@Injectable({ providedIn: 'root' })
export class NlpParticularService {

    private alertService: AlertService;
    private modal: ModalService;
    private modal2: ModalService;
    private frasesCount: number;
    fullEntities: any = [];
    intentions: any = [];
    responseNlp: any;
    translate: any;

    constructor() {
        this.frasesCount = 0;
        this.modal = new ModalService();
        this.modal2 = new ModalService();
        this.phraseInitialize();
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    private phraseInitialize() {
        lastSelectionPhrase = {
            parent: null,
            selection: null,
            node: null,
            start: null,
            end: null,
            highlight: null,
            type: null
        }
    }

    private visualLength(html) {
        var ruler = $(".ruler");
        ruler.html(html);
        return ruler.outerWidth();
    }

    private createResponseNlpParticularString() {
        this.responseNlp = {
            intentions: this.intentions,
            type: 'jsonNLP',
            showOutputs: true
        };

        return JSON.stringify(this.responseNlp);
    }

    private saveModalEvent(saveBtn, field, modal, id) {
        saveBtn.on('click', (event) => {
            const nameIntention = $(event.target).parent().parent().find('.name-intention');
            const examplesIntention = $(event.target).parent().parent().find('.examples-list-box')[0].innerHTML;
            const examples = this.htmlToArrayExamples(examplesIntention);
            const entities = this.getEntitiesFromStr(examples.join(','));

            const intention = {
                name: nameIntention.val(),
                ejemplos: examples,
                entidades: entities
            };

            this.intentions.forEach((ex, idx) => {
                if (ex.id === id) {
                    this.intentions[idx] = {...ex, ...intention};
                }
            });

            field.val(this.createResponseNlpParticularString());

            modal.hide();
        });
    }

    private cancelModalEvent(cancelBtn, modal) {
        cancelBtn.on('click', () => {
            modal.hide();
            this.phraseInitialize();
        });
    }

    private getTextOffset(parent, index) {
        if (parent.nodeType === 3)
            return {'node': parent, 'offset': index};
        var e = parent.childNodes[0], i = index, prev = null;
        while (1) {
            while (e.nodeType !== 3 || e === prev)
                if (e.childNodes && e.childNodes.length)
                    e = e.childNodes[0];
                else if (e.nextSibling)
                    e = e.nextSibling;
                else {
                    while (!e.nextSibling)
                        if (!e.parentNode || e.parentNode === parent)
                            throw RangeError('Index out of range');
                        else
                            e = e.parentNode;
                    e = e.nextSibling;
                }
            if (e.data.length < i)
                i -= e.data.length, prev = e;
            else
                return {'node': e, 'offset': i};
        }
    }

    private highlight(node, start, end, type="entity") {
        const id = joint.util.uuid();
        var r, o, hi;
        r = document.createRange()
        o = this.getTextOffset(node, start);
        r.setStart(o.node, o.offset);
        o = this.getTextOffset(node, end);
        r.setEnd(o.node, o.offset);
        hi = document.createElement('span');
        hi.classList.add(type + '-chip');
        hi.id = id;
        hi.contentEditable = false;
        hi.appendChild(r.extractContents());
        r.insertNode(hi);
        r.detach();

        const dataWordAtt = document.createAttribute("data-word");
        dataWordAtt.value = hi.innerText;
        hi.setAttributeNode(dataWordAtt);
        lastSelectionPhrase.highlight = hi;
        lastSelectionPhrase.type = type;
    }

    private htmlToArrayExamples(htmlExamples) {
        const re = /<span class="entity-chip"[^>]*>(.*?)<\/span>/g;
        const matches = [...(htmlExamples.matchAll(re))];
        for (let index = 0; index < matches.length; index++) {
            const match = matches[index];
            const tempSpan = $(match[0]);
            const entity = tempSpan.data('entity');
            const word = tempSpan.data('word');
            htmlExamples = htmlExamples.replace(match[0], `[${word}](${entity})`);
        }
        if (htmlExamples.indexOf('<p') >= 0) {
            htmlExamples = htmlExamples.replace(/<p[^>]*>/g, '\n<p>');
            htmlExamples = htmlExamples.replace(/<p[^>]*><\/p>/g, '');
            htmlExamples = htmlExamples.replace(/<p[^>]*>/g, '');
            htmlExamples = htmlExamples.replaceAll('<\/p>', '\n');
        } else {
            htmlExamples += '\n';
        }
        const examples =  htmlExamples.split('\n');
        const regex = /(\[[^\[]+\])$|(\[[^\[]+\])[^\(]|(\[[^\[]+\]\(\))|(\[\]\(@[^\)]+\))|[^\]](\(@[^\)]+\))|^(\(@[^\)]+\))/g;
        const ex = examples.map((item) => {
            let str = item.replace(/<.*[^>]*>(.*)<\/.*[^>]*>/g, '$1').replace(/<br?\/?>/g, '').replace('&nbsp;', ' ');
            let m;

            while ((m = regex.exec(str)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                m.forEach((match, groupIndex) => {
                    if (match) {
                        str = str.replace(match, '');
                    }
                });
            }
            return str;
        });
        return ex.filter((item)=>item);
    }

    private arrayToHtmlExamples(examplesArr) {
        let result = '';
        const re = /\[([^\]]*)\]\((@[^\)]*)\)/g;
        for (let index = 0; index < examplesArr.length; index++) {
            let example = examplesArr[index];
            const matches = [...(example.matchAll(re))];
            for (let index = 0; index < matches.length; index++) {
                const id = joint.util.uuid();
                const match = matches[index][0];
                const word = matches[index][1]
                const entity = matches[index][2];
                example = example.replace(match, `<span class="entity-chip" id="${id}" data-word="${word}" data-entity="${entity}" contenteditable="false">${word}: ${entity}</span>`);
            }
            result += `<p>${example}</p>`;
        }
        return result;
    }

    private getEntitiesFromStr(str) {
        const re = /\((@[^\)]*)\)/g;
        const matches = [...(str.matchAll(re))];
        const entities = [];
        for (let index = 0; index < matches.length; index++) {
            const match = matches[index][1].replace('(', '').replace(')', '');
            const res = entities.find(entityAux => entityAux === match);
            if (!res) {
                entities.push(match);
            }
        }
        return entities;
    }

    private selectEntityEvent(entityItem, entity) {
        entityItem.on('click', (event)=> {
            this.highlight(lastSelectionPhrase.node, lastSelectionPhrase.start, lastSelectionPhrase.end, lastSelectionPhrase.type);
            const dataEntityAtt = document.createAttribute("data-entity");
            dataEntityAtt.value = entity;
            lastSelectionPhrase.highlight.setAttributeNode(dataEntityAtt);
            lastSelectionPhrase.highlight.innerText += ': ' + entity
            $(event.target).parent().parent().parent().remove();
            this.phraseInitialize();
        });
    }

    private cancelNewEntityEvent(entityCancelBtn, modal2) {
        entityCancelBtn.on('click', (e) => {
            modal2.hide();
        });
    }
    private saveNewEntityEvent(entitySaveBtn, modal2) {
        entitySaveBtn.on('click', (e) => {
            const fullBox = $(e.target).parents('.new-entity-box');
            let entityName = fullBox.find('.entity-name').val().toString();
            if (entityName.indexOf('@') !== 0) {
                entityName = '@' + entityName;
            }
            const entity: Entity = {
                id: joint.util.uuid(),
                editing: false,
                entityType: 'EXTRACTOR',
                groups: [],
                groupsTxt: '',
                main: false,
                name: entityName,
                regex: ['##entidad'],
                regexTxt: '##entidad',
                state: 'trained',
                type: 'entity'
            };
            const entitiesStr = $('.nlp_entities').val().toString();
            let entities = [];
            if (entitiesStr) {
                entities = JSON.parse(entitiesStr);
                entities.push(entity);
            }
            this.fullEntities.push(entity.name);
            $('.nlp_entities').val(JSON.stringify(entities));
            $('.save_diagram').trigger( "click" );
            $('.entities-list').empty();
            this.setEntities($('.entities-list'));
            modal2.hide();
        });
    }

    private createNewEntityBox(modal2) {
        $('.new-entity-box').remove();
        const entityBox = $('<div class="new-entity-box" />');
        const entityTitle = $('<h2 class="title">' + translate.sections.services.newEntity + '</h2>');
        const entityFullBox = $('<div class="full" />');
        const entityFieldBox1 = $('<div class="field-box" />');
        const entityNameLabel = $('<label for="">' + translate.name + '</label>');
        const entityNmaeSpan = $('<span class="arroba">@</span>');
        const entityNameInput = $('<input type="text" class="entity-name" />');
        const entityActionsBox = $('<div class="modal-actions" />');
        const entitySaveBtn = $('<button class="btn-primary">' + translate.sections.services.save+ '</button>');
        const entityCancelBtn = $('<button class="btn-secondary">' + translate.cancel + '</button>');

        this.saveNewEntityEvent(entitySaveBtn, modal2);
        this.cancelNewEntityEvent(entityCancelBtn, modal2);

        entityActionsBox.append([entityCancelBtn, entitySaveBtn]);
        entityFieldBox1.append([entityNameLabel, entityNmaeSpan, entityNameInput]);
        entityFullBox.append(entityFieldBox1);
        entityBox.append([entityTitle, entityFullBox, entityActionsBox]);

        modal2.box.append(entityBox);
        modal2.box.css({
            'width': '440px',
            'zIndex': '7',
            'maxHeight': 'initial'
        });
        modal2.blocker.css('zIndex', '6');
        modal2.show();
    }

    private newEntityEvent(entityNewAction, modal2) {
        entityNewAction.on('click', (e) => {
            this.createNewEntityBox(modal2);
        });
    }

    private getEntities(type, field, id) {
        let allEntities = [];
        let entities = [];
        const entitiesStr = $('.nlp_entities').val().toString();
        const value = field.val();

        if(entitiesStr) {
            allEntities = JSON.parse(entitiesStr);
            for (let index = 0; index < allEntities.length; index++) {
                const entity: Entity = allEntities[index];
                if (entity.type === type) {
                    entities.push(entity.name);
                }
            }
        }

        if (value) {
            const valueJson = JSON.parse(value);
            if (valueJson.intentions.length > 0) {
                for (const phrase of valueJson.intentions) {
                    if (id === phrase.id) {
                        if (phrase.hasOwnProperty('entidades')) {
                            const entities2 = phrase.entidades;
                            entities = [...new Set([...entities, ...entities2])];
                            this.fullEntities = entities;
                        }
                    }
                }
            }
        }

        return entities;
    }

    private setEntities(entitiesList) {
        this.fullEntities.forEach(entity => {
            const entityItem = $(`<li class="entity-item">${entity}</li>`);
            this.selectEntityEvent(entityItem, entity);
            entitiesList.append(entityItem);
        });
    }

    private cancelEntityEvent(entityCancelAction) {
        entityCancelAction.on('click', (event) => {
            $(event.target).parents('.example-options-box').remove();
        });
    }

    private manageModalEntities(textarea, field, type, modal2, id) {
        $('.example-options-box').remove();
        const fieldBox = textarea.parent();
        const exampleOptionsBox = $('<div class="example-options-box" />');
        const optionSelect = $('<select class="example-options"><option value="entity">' + translate.sections.services.entity + '</option></select>');
        const entitiesBox = $('<div class="entities-box" />');
        const entitiesList = $('<ul class="entities-list" />');
        const entityActions = $('<div class="actions" />');
        const entityCancelAction = $('<button class="btn-secondary" />');
        const entityCancelText = $('<span>' + translate.cancel + '</span>');
        const entityNewAction = $('<button class="btn-primary" />');
        const entityNewText = $('<span>+ ' + translate.sections.services.entity + '</span>');

        this.fullEntities = this.getEntities(type, field, id);
        this.setEntities(entitiesList);
        this.cancelEntityEvent(entityCancelAction);
        this.newEntityEvent(entityNewAction, modal2);

        entityCancelAction.append(entityCancelText);
        entityNewAction.append(entityNewText);
        entityActions.append([entityCancelAction, entityNewAction]);
        entitiesBox.append([entitiesList, entityActions]);
        exampleOptionsBox.append([optionSelect, entitiesBox]);

        fieldBox.append(exampleOptionsBox);
    }

    private setPositionModal(textarea, type, field, modal2, id) {
        const selection = lastSelectionPhrase.selection;
        let positionKey = selection.anchorOffset;
        if (selection.anchorOffset > selection.focusOffset) {
            positionKey = selection.focusOffset;
        }
        const text = selection.anchorNode.textContent;
        if (
            positionKey === 0 ||
            text.slice(positionKey - 1, positionKey) === ' ' ||
            text.slice(positionKey - 1, positionKey) === ' '
        ) {
            let position = $(selection.anchorNode.parentElement).offset();
            const visulaLengthAtSign = this.visualLength(text.slice(0, positionKey));
            const left = position.left - (($(document).outerWidth() - $('.modal2').outerWidth()) / 2);
            const top = position.top - ($(document).outerHeight() - $('.modal2').outerHeight()) / 2;

            this.manageModalEntities(textarea, field, type, modal2, id);

            $('.example-options-box').css({
                top: top - 232 + 40,
                left: left + visulaLengthAtSign - 48 -104
            });
        }
    }

    private selectElementContents(el) {
        const sel = window.getSelection();
        const range = sel.getRangeAt(0);
        if (range.startOffset !== range.endOffset) {
            lastSelectionPhrase = {
                parent: el,
                selection: sel,
                node: sel.focusNode,
                start: range.startOffset,
                end: range.endOffset
            };
        }
    }

    private examplesSelectionEvent(textarea) {
        textarea.on('mouseup', (e) => {
            this.selectElementContents(textarea.get(0));
        });
    }

    private addEntitySlotEvent(btn, type, field, modal2, id) {
        btn.on('click', (e) => {
            this.setPositionModal($(lastSelectionPhrase.parent), type, field, modal2, id);
        });
    }

    private manageModalAddIntention(modal, modal2, field, id, count) {
        const examplesModalBox = $('<div class="add-intention" />');
        const examplesModalTitle = $('<h2 class="title">' + translate.sections.services.phrase + '' + count + '</h2>');
        const examplesModalFullBox = $('<div class="full" />');
        const examplesModalFieldBox = $('<div class="field-box" />');
        const examplesModalNameLabel = $('<label for="">' + translate.name+ '</label>');
        const examplesModalNameInput = $('<input type="text" class="name-intention" />');
        const examplesModalFieldBox2 = $('<div class="field-box" />');
        const examplesModalExamplesLabel = $('<label for="">' + translate.sections.services.examples+ '</label>');
        const examplesModalExamplesEntityBtn = $('<button class="btn-base entity">+ ' + translate.sections.services.entities + '</button>');
        const examplesModalTextareaBox = $('<div contenteditable="true" class="examples-list-box" />');
        const examplesModalActionsBox = $('<div class="modal-actions" />');
        const examplesModalCancelBtn = $('<button class="btn-secondary">' + translate.cancel + '</button>');
        const examplesModalSaveBtn = $('<button type="submit" class="btn-primary">' + translate.sections.services.savePhrase + '</button>');

        this.cancelModalEvent(examplesModalCancelBtn, modal);
        this.saveModalEvent(examplesModalSaveBtn, field, modal, id);

        const value = field.val();
        if (value) {
            const valueJson = JSON.parse(value);
            if (valueJson.intentions.length > 0) {
                for (const phrase of valueJson.intentions) {
                    if (id === phrase.id) {
                        const intention = phrase;
                        if (intention.hasOwnProperty('name')) {
                            examplesModalNameInput.val(intention.name);
                        }
                        if (intention.hasOwnProperty('ejemplos')) {
                            const examplesHtml = this.arrayToHtmlExamples(intention.ejemplos);
                            examplesModalTextareaBox.html(examplesHtml);
                        }
                    }
                }
            }
        }

        examplesModalActionsBox.append([examplesModalCancelBtn, examplesModalSaveBtn]);

        examplesModalFieldBox.append([examplesModalNameLabel, examplesModalNameInput]);

        this.examplesSelectionEvent(examplesModalTextareaBox);
        examplesModalTextareaBox.on('paste', (e) => {
            e.preventDefault();
            const textHtml = e.originalEvent['clipboardData'].getData('text/HTML');
            const textPlain = e.originalEvent['clipboardData'].getData('text/plain');
            let text = '';
            let arr;
            if (textHtml.indexOf('<') > 0) {
                arr = this.htmlToArrayExamples(textHtml);
            } else {
                arr = textPlain.split('\n');
            }
            text = this.arrayToHtmlExamples(arr);
            document.execCommand("insertHTML", false, text);
        });
        document.execCommand("DefaultParagraphSeparator", false, "p");

        this.addEntitySlotEvent(examplesModalExamplesEntityBtn, 'entity', field, modal2, id);

        examplesModalFieldBox2.append([
            examplesModalExamplesLabel,
            examplesModalExamplesEntityBtn,
            examplesModalTextareaBox
        ]);

        examplesModalFullBox.append([examplesModalFieldBox, examplesModalFieldBox2]);
        examplesModalBox.append([examplesModalTitle, examplesModalFullBox, examplesModalActionsBox]);
        examplesModalBox.data('id-phrase', id);

        modal.box.append(examplesModalBox);
        modal.box.css('width', '540px');
        modal.show();
    }

    private getNlpExample(field, count, id) {
        const containerDiv = $('<div class="intentions" />');
        const contentsDiv = $('<div class="content-intentions" />');
        // const intentionBox = $(`<div id="${id}" class="intention" />`);
        const intentionBox = $(`<div class="intention" />`);
        const intentionBtn = $(`<button class="btn-secondary"><span>` + translate.sections.services.setPhrase + ` ${count}</span></button>`);
        const ruler = $('<span class="ruler"></span>');
        const modal = this.modal.create({
            onClose: ()=> {
                modal.box.parent().find('.add-intention').remove();
            }
        });
        const modal2 = this.modal2.create({});

        intentionBox.append(intentionBtn);

        intentionBtn.on('click', (eIntentionBtn) => {
            this.manageModalAddIntention(modal, modal2, field, id, count);
        });

        $('body').append([modal.box, modal.blocker, modal2.box, modal2.blocker]);
        contentsDiv.append(intentionBox);

        // const nameClass = field.attr('name').replace('#', '-');
        // containerDiv.addClass(nameClass);
        containerDiv.append([ruler, contentsDiv, field]);

        return containerDiv;
    }

    getNlpParticularExample(field) {
        const containerDiv = $('<div class="wam" />');

        const contentsDiv = $('<div class="contentsWAM" />');
        const addBtn = $('<button class="btn-primary">+ Frase</button>');

        addBtn.on('click', (eBtn) => {
            this.frasesCount++;
            const id = joint.util.uuid();
            const contentBoxWAM = $(`<div id="${id}" class="contentBoxWAM" data-idx="${this.intentions.length}" />`);
            const deleteBtn = $('<a class="btnDelete">×</a>');
            const phrase = this.getNlpExample(field, this.frasesCount, id);
            contentBoxWAM.append([phrase, deleteBtn]);

            this.intentions.push({id});

            deleteBtn.on('click', (eBtnDel) => {
                const parent = $(eBtnDel.target).parent();
                const idx = parent.data('idx');
                this.intentions.splice(idx, 1);
                parent.remove();
                field.val(this.createResponseNlpParticularString());
            });

            field.val(this.createResponseNlpParticularString());

            contentsDiv.append(contentBoxWAM);
        });

        // Sortable.create(contentsDiv.get(0), {
        //     onEnd: (evt) => {
        //         const jsonNLPAux = [];
        //         evt.from.childNodes.forEach((elem, idx) => {
        //             $(elem).data('idx', idx);
        //             jsonNLPAux.push({
        //                 // content: $(elem).find('input').val(),
        //                 // index: $(elem).attr('id'),
        //                 // name: $(elem).find('input').val(),
        //                 name: nameIntention.val(),
        //                 ejemplos: examples,
        //                 entidades: entities
        //             });
        //             // TODO: obtener los valores reales del lightbox
        //         });
        //         this.intentions = jsonNLPAux;
        //         field.val(this.createResponseNlpParticularString());
        //     }
        // });

        const val = field.val();
        if (val !== '') {
            try {
                const value = JSON.parse(val);
                this.intentions = value.intentions;
                value.intentions.forEach((btn, idx) => {
                    this.frasesCount++;
                    const id = btn.id;
                    const contentBoxWAM = $(`<div id="${id}" class="contentBoxWAM" data-idx="${idx}" />`);
                    // const contentInput = $(`<input type="text" class="contentWAM" value="${btn.content}">`);
                    const phrase = this.getNlpExample(field, this.frasesCount, id);
                    const deleteBtn = $('<a class="btnDelete">×</a>');
                    contentBoxWAM.append([phrase, deleteBtn]);

                    deleteBtn.on('click', (eBtnDel) => {
                        const parent = $(eBtnDel.target).parent();
                        // const idx = parent.data('idx');
                        this.intentions.splice(idx, 1);
                        parent.remove();
                        field.val(this.createResponseNlpParticularString());
                    });

                    field.val(this.createResponseNlpParticularString());

                    contentsDiv.append(contentBoxWAM);
                });
            } catch (e) {
                console.error('Hubo un problema con el menú de whatsapp');
            }
        }

        const nameClass = field.attr('name').replace('#', '-');
        containerDiv.addClass(nameClass);
        containerDiv.append([addBtn, contentsDiv, field]);

        return containerDiv;
    }
}
