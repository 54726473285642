import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/services/alert.service';
import { AuthServices } from 'src/services/auth.guard.service';
import { CustomerService } from 'src/services/customer.service';
import { DataLayerService } from 'src/services/data-layer.service';
import { AllowedRolService } from 'src/services/rol-service.service';
declare var translate: any;
@Component({
    selector: 'app-invitations',
    templateUrl: './invitations.component.html',
    styleUrls: ['./invitations.component.scss']
})
export class InvitationsComponent implements OnInit {

    slug: string;
    authorizeRol: boolean;
    existsInvitations: boolean;
    invitations: any[];
    translate: any;
    flagPath: string;

    constructor(
        private authServices: AuthServices,
        private alertService: AlertService,
        private customerService: CustomerService,
        private allowedRolService: AllowedRolService,
        private route: ActivatedRoute,
        private router: Router,
        private dataLayerService: DataLayerService
    ) {
        this.existsInvitations = false;
        this.invitations = [];
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = '';
    }

    ngOnInit() {

        this.authServices.verifyToken(JSON.stringify({
            user_id: this.authServices.getIdGoogle(),
            token: this.authServices.getToken(),
            auth_type: localStorage.getItem('Auth-Type')
        })).subscribe(
            (res: any) => {
                if (res.status === 'ACTIVE' && res.role.name !== 'CUSTOM') {
                    localStorage.setItem('photo', res.avatar);
                    this.authorizeRol = this.allowedRolService.allowed(res.role.name, 'DEV');
                } else {
                    this.authServices.logout();
                }

            },
            err => {
                // console.log(err);
                this.authServices.logout();
            }
        );

        this.getInvitations();

        this.dataLayerService.setFlag(this.flagPath, 'invitaciones');
    }

    getInvitations() {
        this.customerService.getInvitations().subscribe(
            (res: any) => {
                // console.log(res);
                this.invitations = res;
                if (res.length > 0) {
                    this.existsInvitations = true;
                } else {
                    this.existsInvitations = false;
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    removeInvitationFromArray(invitationId) {
        const index = this.invitations.find(invite => invite.id === invitationId);
        this.invitations.splice(index, 1);
    }

    acceptInvitation(invitationId) {
        this.customerService.acceptInvitation(invitationId).subscribe(
            res => {
                // console.log(res);
                // this.removeInvitationFromArray(invitationId);
                this.getInvitations();
                this.alertService.success(translate.sections.customers.invitations.createdInvitation);
            },
            err => {
                console.error(err);
            }
        );
    }

    rejectInvitation(invitationId) {
        this.customerService.rejectInvitation(invitationId).subscribe(
            res => {
                // console.log(res);
                this.removeInvitationFromArray(invitationId);
                this.alertService.success(translate.sections.customers.invitations.noCreatedInvitation);
            },
            err => {
                console.log(err);
            }
        );
    }

}
