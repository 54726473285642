export enum entityTypes {
    EXTRACTOR = 'EXTRACTOR',
    REGULAR_COLECTOR = 'REGULAR_COLECTOR',
    ENTITY_REF_EXTRACTOR = 'ENTITY_REF_EXTRACTOR',
    POSITIONAL_REF_EXTRACTOR = 'POSITIONAL_REF_EXTRACTOR',
    MATCH_REF_EXTRACTOR = 'MATCH_REF_EXTRACTOR',
    TIMING_REF_EXTRACTOR = 'TIMING_REF_EXTRACTOR',
    STAYTIME_REF_EXTRACTOR = 'STAYTIME_REF_EXTRACTOR',
    G_EXTRACTOR = 'G_EXTRACTOR'
}
