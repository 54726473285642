import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomerService } from '../../../services/customer.service';
import { AlertService } from '../../../services/alert.service';
import { AuthServices } from 'src/services/auth.guard.service';
import { AllowedRolService } from 'src/services/rol-service.service';

@Component({
    selector: 'app-invite',
    template: ''
})
export class InviteComponent implements OnInit {

    authorizeRol: boolean;

    constructor(
        private allowedRolService: AllowedRolService,
        private authServices: AuthServices,
        private alertService: AlertService,
        private customerService: CustomerService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.authServices.verifyToken(JSON.stringify({
            user_id: this.authServices.getIdGoogle(),
            token: this.authServices.getToken(),
            auth_type: localStorage.getItem('Auth-Type')
        })).subscribe(
            (res: any) => {
                if (res.status === 'ACTIVE' && res.role.name !== 'CUSTOM') {
                    localStorage.setItem('photo', res.avatar);
                    this.authorizeRol = this.allowedRolService.allowed(res.role.name, 'DEV');
                } else {
                    console.log('logout invite1');
                    this.authServices.logout();
                }

            },
            err => {
                // console.log(err);
                console.log('logout invite2');
                this.authServices.logout();
            }
        );

        this.route.queryParams.subscribe(params => {
            const invitationId = params['id'];
            this.acceptInvitation(invitationId);
        });
    }

    acceptInvitation(invitationId) {
        this.customerService.acceptInvitation(invitationId).subscribe(
            res => {
                // console.log(res);
                this.alertService.success('La invitación ha sido aceptada');
                this.router.navigate(['/customers']);
            },
            err => {
                // console.log(err);
                this.router.navigate(['/customers']);
            }
        );
    }

}
