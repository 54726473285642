import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TicketService {

    constructor(private http: HttpClient) { }

    getEntitiesByTicket(ticketId: string, slug: string) {
        const url = `/coreapi/v2/tickets/${ticketId}/entityModel`;
        const headers = new HttpHeaders({
          Slug: slug,
          'Show-Error': 'false'
        });
        return this.http.get(url, { headers });
      }

}
