import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AuthServices } from '../../../services/auth.guard.service';
import { AlertService } from '../../../services/alert.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Account } from 'src/models/account';
import { AccountService } from 'src/services/account.service';
import { DataLayerService } from 'src/services/data-layer.service';

declare var translate: any;
declare let google: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
    user: any;
    private loggedIn: boolean;
    private readonly _destroying$ = new Subject<void>();
    profile: any;

    account: Account;

    translate: any;
    authTypeB: string;
    flagPath: string;

    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: AuthServices,
        private alertService: AlertService,
        private route: ActivatedRoute,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authServiceMsal: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private accountService: AccountService,
        private dataLayerService: DataLayerService
    ) {
        this.authTypeB = localStorage.getItem('authTypeB');
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);

        if (this.authTypeB === 'FACEBOOK') {
            if (this.accountService.accountValue) {
                // console.log('login component');
                this.router.navigate(['/']);
            }
            this.accountService.account.subscribe((x) => {
                this.account = x;
            });
        }
        this.flagPath = '';
    }

    ngAfterViewInit() {
        const thisClass = this;
        const googleInterval = setInterval(() => {
            if (google) {
                google.accounts.id.initialize({
                    client_id: environment.google.clientId,
                    context: 'signin',
                    ux_mode: 'popup',
                    auto_select: true,
                    callback: (response: any) => {
                        thisClass.handleCredentialResponse(response);
                    }
                });
                google.accounts.id.renderButton(
                    document.getElementById("google-sign-in"),
                    {
                        theme: "outline",
                        type: 'standard',
                        shape: 'rectangular',
                        text: 'signin_with',
                        size: "large",
                        logo_alignment: 'left',
                        width: 240,
                        click_listener: thisClass.onClickHandler
                    }
                );
                clearInterval(googleInterval);
            }
        }, 100);
    }

    ngOnInit() {
        this.alertService.clear();

        if (this.authService.loggedIn()) {
            // console.log('is logged');
            this.router.navigateByUrl('customers');
        } else {
            // console.log('not logged');
        }

        // this.isIframe = window !== window.parent && !window.opener;

        switch (this.authTypeB) {
            case 'GOOGLE':
            case 'MICROSOFT':
                this.checkAccount();
                this.authServiceMsal.instance.enableAccountStorageEvents();
                this.msalBroadcastService.msalSubject$
                    .pipe(
                        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
                    )
                    .subscribe((result: EventMessage) => {
                        if (this.authServiceMsal.instance.getAllAccounts().length === 0) {
                            window.location.pathname = "/?error=login";
                        }
                    });

                this.msalBroadcastService.inProgress$
                    .pipe(
                        filter((status: InteractionStatus) => status === InteractionStatus.None),
                        takeUntil(this._destroying$)
                    )
                    .subscribe(() => {
                        this.checkAndSetActiveAccount();
                    })

                this.msalBroadcastService.msalSubject$
                    .pipe(
                        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                    )
                    .subscribe((result: EventMessage) => {
                        const payload = result.payload as AuthenticationResult;
                        this.authServiceMsal.instance.setActiveAccount(payload.account);
                        this.checkAccount();
                    });
                break;
        }

    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.authServiceMsal.instance.getActiveAccount();

        if (!activeAccount && this.authServiceMsal.instance.getAllAccounts().length > 0) {
            let accounts = this.authServiceMsal.instance.getAllAccounts();
            this.authServiceMsal.instance.setActiveAccount(accounts[0]);
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
    }

    getProfile(a) {
        // console.log(JSON.stringify(a));
        const url = environment.microsoft.apiUrl + 'me';
        this.http.get(url).subscribe((profile) => {
            this.profile = profile;
            // console.log(JSON.stringify(this.profile));
            this.user = {
                user_name: this.profile.displayName,
                email: this.profile.mail || this.profile.userPrincipalName,
                name: this.profile.givenName || this.profile.displayName,
                lastName: this.profile.surname,
                user_id: this.profile.id,
                auth_type: 'MICROSOFT',
                token: a.accessToken,
                id_token: a.idToken,
            };

            localStorage.setItem('Auth-Type', 'MICROSOFT');
            localStorage.setItem('payload', JSON.stringify(this.user));

            this.authService.registerGoogle(this.user).subscribe(
                (res: any) => {
                    res.role.name = 'UX_DEV_BI_ADMIN';
                    if (res.status === 'ACTIVE' && res.role.name !== 'CUSTOM') {
                        // console.log('user logged!!');
                        localStorage.setItem('id_token', a.idToken);
                        localStorage.setItem('token', a.accessToken);
                        localStorage.setItem('id', res.googleId);
                        localStorage.setItem('photo', res.avatar);
                        localStorage.setItem('name', res.name);
                        localStorage.setItem('email', res.email);
                        localStorage.setItem('constructorUserId', res.id);

                        this.dataLayerService.setFlag(this.flagPath, 'LogIn Microsoft - email');
                        this.dataLayerService.setFlag(this.flagPath, 'LogIn Microsoft - log');

                        this.alertService.success('Bienvenido!');

                        this.route.queryParams.subscribe(
                            (params) => {
                                if ('redirect_uri' in params) {
                                    const redirectUri = decodeURIComponent(
                                        params['redirect_uri']
                                    );
                                    window.location.href = environment.baseUrl + redirectUri;
                                } else {
                                    window.location.href = '/customers';
                                }
                            }
                        );

                    } else {
                        this.alertService.warning(
                            'Tu usuario no se encuentra activo aún, \
                            contacta con un admministrador para activarlo.'
                        );
                    }
                },
                (err) => {
                    this.alertService.error(
                        'No se ha podido realizar el login.'
                    );
                }
            );
        });
    }

    checkAccount() {
        this.loggedIn = this.authServiceMsal.instance.getAllAccounts().length > 0;
        if (!this.loggedIn) {
            // console.log('remove localstorage items login checkAccount');
            localStorage.removeItem('constructorUserId');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('id');
            localStorage.removeItem('photo');
            localStorage.removeItem('token');
            localStorage.removeItem('dateScheduled');
            localStorage.removeItem('Auth-Type');
            localStorage.removeItem('id_token');
            localStorage.removeItem('payload');
            localStorage.removeItem('currentCustomerSlug');
            localStorage.removeItem('functionalityTemplates');
            localStorage.removeItem('functionalityValues');
            localStorage.removeItem('md5Diagram');
            localStorage.removeItem('flowId');
            localStorage.removeItem('lastConsoleId');
            localStorage.removeItem('newWhatsappChannel');
            localStorage.removeItem('errorCatalog');
            localStorage.removeItem('errorCatalogDate');
            localStorage.removeItem('fbToken');
        }
    }

    singInMicrosoft() {
        this.dataLayerService.setFlag(this.flagPath, 'LogIn Microsoft - start');
        localStorage.setItem('authTypeB', 'MICROSOFT');
        if (this.msalGuardConfig.authRequest) {
            this.authServiceMsal.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.authServiceMsal.instance.setActiveAccount(response.account);
                    this.getProfile(response);
                    this.checkAccount();
                });
        } else {
            this.authServiceMsal.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.authServiceMsal.instance.setActiveAccount(response.account);
                    this.getProfile(response);
                    this.checkAccount();
                });
        }
    }

    singInFacebook() {
        localStorage.setItem('authTypeB', 'FACEBOOK');
        this.dataLayerService.setFlag(this.flagPath, 'LogIn Facebook - start');
        this.accountService.login();
    }


    loginGus(userData) {
        localStorage.setItem('Auth-Type', 'GOOGLE');
        localStorage.setItem('payload', JSON.stringify(userData));

        if (userData.email && userData.email !== '') {
            this.authService.registerGoogle(userData).subscribe(
                (res: any) => {
                    res.role.name = 'UX_DEV_BI_ADMIN';
                    if (
                        res.status === 'ACTIVE' &&
                        res.role.name !== 'CUSTOM'
                    ) {
                        localStorage.setItem('token', res.currentToken.token);
                        localStorage.setItem('id', res.googleId);
                        localStorage.setItem('photo', res.avatar);
                        localStorage.setItem('name', res.name);
                        localStorage.setItem('email', res.email);
                        localStorage.setItem('constructorUserId', res.id);

                        this.dataLayerService.setFlag(this.flagPath, 'LogIn Google - email');
                        this.dataLayerService.setFlag(this.flagPath, 'LogIn Google - log');

                        this.alertService.success('Bienvenido!');

                        this.route.queryParams.subscribe(
                            (params) => {
                                if ('redirect_uri' in params) {
                                    const redirectUri = decodeURIComponent(
                                        params['redirect_uri']
                                    );
                                    window.location.href = environment.baseUrl + redirectUri;
                                } else {
                                    window.location.href = '/customers';
                                }
                            }
                        );
                    } else {
                        this.alertService.warning(
                            'Tu usuario no se encuentra activo aún, \
                        contacta con un admministrador para activarlo.'
                        );
                    }
                },
                (err) => {
                    console.error(err);
                    this.alertService.error(
                        'No se ha podido realizar el login.'
                    );
                }
            );
        } else {
            this.alertService.error(
                'La cuenta con la que intentas entrar no tiene un email confimado, confirmalo y vuelva a intentar'
            );
        }
    }

    signOut(): void {
        // this.socioAuthServ.signOut();
        // console.log('logout login');
        this.authService.logout();
    }

    toDemo() {
        window.location.href = 'https://gus.chat/demo';
    }

    redirectSSO() {
        this.router.navigate(['/sso']);
    }

    logout() {
        this.accountService.logout();
    }

    handleCredentialResponse(response: any) {
        const jwtData = this.decodeJwtResponse(response.credential);
        const userData = {
            auth_type: 'GOOGLE',
            avatar: jwtData.picture,
            email: jwtData.email,
            token: response.credential,
            user_id: jwtData.sub,
            user_name: jwtData.email
        };
        this.loginGus(userData);
    }

    decodeJwtResponse(token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    onClickHandler() {
        // bandera start data layer
        // console.log('click google button');
    }
}
