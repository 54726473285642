import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, timer, retry } from 'rxjs';

export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // Retries available only for get method
        if (request.method === 'GET'){
            return next.handle(request).pipe(retry({ count: retryCount, delay: this.shouldRetry }));
        } else {
            return next.handle(request).pipe();
        }
    }

    shouldRetry(error: HttpErrorResponse) {
        if (error.status >= 500 || error.status == 0) {
            return timer(retryWaitMilliSeconds);
        }
        throw error;
    }
}
