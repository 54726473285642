import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { LoaderService } from '../services/loader.service';
import { environment } from '../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    countRequests = 0;
    countRequestsFinished = 0;
    constructor(private loaderService: LoaderService, private activate: ActivatedRoute) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.countRequests += 1;
        /*console.log('Requests count: ', this.countRequests);
        console.log(request);
        console.log(request.headers.get('Show-Loader'));
        console.log(request.body);
        console.log(request.params);
        */
        let showLoader = true;
        if (request.headers.has('Show-Loader')) {
            const showLoaderVal = request.headers.get('Show-Loader');
            if (showLoaderVal === 'false') {
                showLoader = false;
            }
            request.headers.delete('Show-Loader');
        }
        if (showLoader) {
            this.loaderService.show();
        }

        const setHeaders = {};

        // Only for gus
         if (request.url.indexOf('/coreapi') === 0) {
            if (request.url.indexOf('/coreapi/v2/login') === -1) {
                const user = localStorage.getItem('id');
                const pass = localStorage.getItem('token');
                const basic = btoa(`${user}:${pass}`);
                setHeaders['Authorization'] = `Basic ${basic}`;
                setHeaders['Auth-Type'] = localStorage.getItem('Auth-Type');
            }
            setHeaders['Cache-Control'] = 'no-cache';
        } else if (request.url.indexOf('/campaign') === 0) {
            // console.log(this.activate.snapshot['_routerState'].url);
            // const urlGet = this.activate.snapshot['_routerState'].url;
            // const id = urlGet.split('/');
            // const getValue = id[id.length - 1];
            // const tenantId = getValue.split('?')[0];

            const tenantId = request.headers.get('Tenant-Id');

            const user = 'api@' + tenantId;
            const pass = environment.apiPass;

            const basic = btoa(`${user}:${pass}`);
            setHeaders['Authorization'] = `Basic ${basic}`;
            setHeaders['Auth-Type'] = localStorage.getItem('Auth-Type');
            setHeaders['Cache-Control'] = 'no-cache';
        }

        request = request.clone({ setHeaders });
        // console.log(request);

        return next.handle(request).pipe(finalize(() => {
            this.countRequestsFinished += 1;
            // console.log('Requests finished count: ', this.countRequestsFinished);
            if (this.countRequests === this.countRequestsFinished) {
                this.loaderService.close();
            }
        }));
    }
}
