<div class="payment-notification" *ngIf="showPaymentNotification">
    <span class="message">{{translate.sections.navbarTop.paymentNotification}}</span>
    <button class="discard" (click)="discard()">{{translate.sections.navbarTop.discard}}</button>
</div>
<header [ngClass]="{ shadow: existsSlug }">
    <!-- <button *ngIf="mobile&&existsSlug" class="burger" (click)="toggleMenu()" (appClickOutside)="showMenu=false;"></button> -->
    <nav [ngClass]="{ mobile: false, show: showMenu }">
        <!-- <ul class="menu-customer" *ngIf="existsDiagram">
            <li>
                <button (click)="back()" class="back">←</button>
            </li>
            <li>
                <app-list-customers></app-list-customers>
            </li>
        </ul> -->
        <ul class="menu-login" *ngIf="!existsSlug">
            <li>
                <a class="btn-text" (click)="redirectHelp()" target="_blank">
                    <span>{{translate.sections.navbarTop.helpCenter}}</span>
                </a>
            </li>
            <li>
                <a class="btn-text" (click)="redirectDemo()">
                    <span>{{translate.sections.navbarTop.bookDemo}}</span>
                </a>
            </li>
            <li>
                <button class="btn-primary">
                    <span>{{translate.sections.navbarTop.signIn}}</span>
                </button>
            </li>
        </ul>
        <ul class="menu-diagram" *ngIf="existsDiagram ">
            <li>
                <button class="btn-icon save_diagram" data-tippy-content="Guardar">
                    <i class="material-icons md-24">save</i>
                </button>
            </li>
            <li *ngIf="showTryBtn()">
                <button class="btn-icon build-test-bot" data-tippy-content="Probar">
                    <i class="material-icons md-24">play_arrow</i>
                </button>
            </li>
            <li *ngIf="showValidateNLPBtn()">
                <button class="btn-icon validate-nlp" data-tippy-content="Validar NLP">
                    <i class="material-icons md-24">fact_check</i>
                </button>
            </li>
            <!-- Search by functionality type and name, extend to any property -->
            <li style="display: none">
                <button class="btn-icon diagram-search" data-tippy-content="Buscar">
                    <i class="material-icons md-24">search</i>
                </button>
            </li>
            <li *ngIf="showStructureUpdateBtn()">
                <button
                    class="btn-icon update-bot"
                    data-tippy-content="Actualizar estructura"
                >
                    <i class="material-icons md-24">update</i>
                </button>
            </li>
            <li>
                <button class="btn-icon versions" data-tippy-content="Versiones">
                    <i class="material-icons md-24">account_tree</i>
                </button>
            </li>
        </ul>
        <ul *ngIf="existsSlug ">
            <li *ngIf="existsDiagram">
                <button class="btn-icon" data-tippy-content="Exportar" (click)="openModalExport('popup-export')">
                    <i class="material-icons md-24">upload</i>
                </button>
            </li>
        </ul>
        <ul class="menu-user" *ngIf="existsSlug ">
            <li *ngIf="existsSlug && !isCustomers && !existsDiagram">
                <button class="btn-icon" data-tippy-content="Compartir" (click)="openModal('popup-add-guests')">
                    <i class="material-icons md-24">person_add_alt_1</i>
                </button>
            </li>
        </ul>
        <ul class="menu-user" *ngIf="existsSlug ">
            <li *ngIf="existsSlug && !isCustomers && existsDiagram">
                <button class="btn-icon" data-tippy-content="Eliminar diagrama" (click)="openDeleteDiagramModal('popup-confirm-diagram-deletion')">
                    <i class="material-icons md-24">delete</i>
                </button>
            </li>
        </ul>

        <ul class="menu-user" *ngIf="existsSlug ">
            <li *ngIf="existsSlug && !isCustomers && existsDiagram">
                <button class="btn-icon" data-tippy-content="Compartir diagrama" (click)="openDiagramPermissionModal('popup-add-diagram-guests')">
                    <i class="material-icons md-24">settings</i>
                </button>
            </li>
        </ul>



        <ul class="menu-diagram" *ngIf="existsDiagram ">
            <li *ngIf="showPublishBtn()">
                <button class="btn-secondary-invert build-bot">
                    {{translate.sections.navbarTop.publish}}
                </button>
            </li>
        </ul>
        <ul class="menu-user profile" *ngIf="existsSlug">
            <li class="dropdown">
                <button class="avatar">
                    <img [src]="getAvatar()" [alt]="userName" (error)="getAvatarError($event)" referrerpolicy="no-referrer" />
                    <!-- <span class="notify"></span> -->
                </button>
                <ul class="dropdown-content right">
                    <!-- <li>
                        <a (click)="goProfile()">Perfil</a>
                    </li> -->
                    <li class="name">
                        <span>{{translate.sections.navbarTop.hello}}, {{ userName }}</span>
                    </li>
                    <li>
                        <a (click)="logOut()">{{translate.sections.navbarTop.leave}}</a>
                    </li>
                </ul>
            </li>
        </ul>
        <!--
        <ul class="menu-diagram">
            <li *ngIf="existsSlug">
                <a href="https://help.gus.chat" target="_blank">
                    <figure>
                        <img [src]="assets + 'img/info.svg'" alt="Información">
                        <figcaption>Información</figcaption>
                    </figure>
                </a>
            </li>
            <li *ngIf="existsDiagram">
                <button class="save_diagram">
                    <figure>
                        <img [src]="assets + 'img/save.svg'" alt="Guardar" title="Guardar">
                        <figcaption>Guardar diagrama</figcaption>
                    </figure>
                </button>
            </li>
            <li *ngIf="existsDiagram">
                <button class="history" (click)="getHistory()">
                    <figure>
                        <img [src]="assets + 'img/history.svg'" alt="Versiones" title="Versiones">
                        <figcaption>Versiones</figcaption>
                    </figure>
                </button>
            </li>
            <li *ngIf="showExport && existsDiagram">
                <button class="export_to_mockup">
                    <figure>
                        <img [src]="assets + 'img/export.svg'" alt="Exportar" title="Exportar">
                        <figcaption>Exportar</figcaption>
                    </figure>
                </button>
            </li>
            <li *ngIf="existsDiagram">
                <button class="build" (click)="buildBot()">
                    <figure>
                        <img [src]="assets + 'img/build.svg'" alt="Construir" title="Construir">
                        <figcaption>Construir bot</figcaption>
                    </figure>
                </button>
            </li>
            <li>
                <a>
                    <figure>
                        <img [src]="assets + 'img/share.svg'" alt="Compartir" title='Compartir'>
                        <figcaption>Compartir</figcaption>
                    </figure>
                </a>
            </li>
            <li>
                <a>
                    <figure>
                        <img [src]="assets + 'img/diagram.svg'" alt="Diagrama">
                        <figcaption>Diagrama</figcaption>
                    </figure>
                </a>
            </li>
            <li *ngIf="existsSlug">
                <div [ngClass]="{'menu-user': true, 'dropdown': !mobile}">
                    <figure class="dropbtn">
                        <img [src]="userImage" (error)="onErrorImage($event)" alt="Perfil">
                    </figure>
                    <ul class="dropdown-content right">
                        <li>
                            <a (click)="goProfile()">Perfil</a>
                        </li>
                        <li>
                            <a (click)="logOut()">Salir</a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
        -->
    </nav>
</header>
<!-- begin modal create campaign -->
<jw-modal id="popup-add-guests">
    <a class="close_modal" (click)="closeModal('popup-add-guests')">×</a>
    <div class="createGuests">
        <h2 class="title">{{translate.sections.navbarTop.share}}</h2>
        <div class="full">
            <div class="field-box">
                <label for="">{{translate.sections.navbarTop.addGuest}}</label>
                <div class="field-compound">
                    <input #newGuest type="text" placeholder="Ejemplo: gabino@gus.chat"
                        (keyup.enter)="onEnterGuest($event)" />
                    <button class="btn-Add-Admins" title="agregar administrador" (click)="addGuestView(newGuest)">
                        <i class="material-icons">add_circle</i>
                    </button>
                </div>
            </div>
            <div class="field-box full">
                <div>
                    <div class="dataConstructorUsers" *ngFor="let guest of guests; let idx = index">
                        <div class="chip" *ngIf="
                                guest.email != superUserMail &&
                                guest.email != currentUserMail
                            ">
                            <span>{{ guest.email }}</span>
                            <button class="btn-del-admin" title="eliminar administrador" (click)="deleteGuestView(idx)">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="guestsPending.length > 0">
                <hr />
                <p>{{translate.sections.navbarTop.pendingInvitations}}</p>
                <div class="dataConstructorUsersPending" *ngFor="let guest of guestsPending; let idx = index">
                    <div class="chip" *ngIf="
                            guest.email != superUserMail &&
                            guest.email != currentUserMail
                        ">
                        <span>{{ guest.email }}</span>
                        <button class="btn-del-admin" title="eliminar administrador"
                            (click)="deleteGuestViewInvite(idx)">
                            <i class="material-icons">delete_forever</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-actions">
            <button (click)="closeModal('popup-add-guests')" class="btn-secondary">
                {{translate.sections.navbarTop.cancel}}
            </button>
            <button type="submit" (click)="addGuests()" class="btn-primary">
                {{translate.sections.navbarTop.save}}
            </button>
        </div>
    </div>
</jw-modal>


<jw-modal id="popup-add-diagram-guests">
    <a class="close_modal" (click)="closeModal('popup-add-diagram-guests')">×</a>
    <div class="createGuests">
        <h2 class="title">{{translate.sections.navbarTop.shareDiagram}}</h2>
        <div class="full">
            <div class="field-box full">
                <div>
                    <div class="dataConstructorUsers" *ngFor="let guest of guests; let idx = index">
                        <div class="chip" *ngIf="
                                guest.email != superUserMail &&
                                guest.email != currentUserMail
                            ">
                            <button *ngIf="guest.hasPermission && canEdit" class="btn-del-admin" title="Revocar acceso" (click)="revokePermissionsByDiagramSlug(guest)">
                                <i class="material-icons">check_box</i>
                            </button>

                            <button *ngIf="!guest.hasPermission && canEdit" class="btn-del-admin" title="Invitar" (click)="addPermissionsByDiagramSlug(guest)">
                                <i class="material-icons">check_box_outline_blank</i>
                            </button>
                            <span>{{ guest.email }}</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-actions">
            <button (click)="closeModal('popup-add-guests')" class="btn-secondary">
                {{translate.sections.navbarTop.cancel}}
            </button>
        </div>
    </div>
</jw-modal>
<!-- end modal create campaign -->
<!-- begin modal create export bots -->
<jw-modal id="popup-export">
    <a class="close_modal" (click)="closeModal('popup-export')">×</a>
    <div class="createGuests">
        <h2 class="title">{{translate.sections.navbarTop.exporter}}</h2>
        <div class="full">
            <p class="description-text">{{translate.sections.navbarTop.source}}</p>
            <div class="form-group">
                <div class="div-flex">
                    <span for="">Diagrama completo</span>
                    <input [checked]="true" (change)="toggleFulldiagram(fullDiagram)" #fullDiagram type="checkbox" value="true" />
                </div>
            </div>
            <div class="form-group">
                <div class="div-flex">
                    <span for="">Diagrama específico</span>
                    <!-- <select (change)="selectedDiagram(diagramSelect)" #diagramSelect class="selectInspectorStyle" disabled="true"> -->
                    <select #diagramSelect class="selectInspectorStyle" disabled="true">
                        <option>Seleccionar un diagrama</option>
                        <option *ngFor="let diagram of diagrams" value="{{diagram?.id}}">
                            {{diagram.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <span>{{translate.sections.navbarTop.botName}}:</span> <span> {{botName}}</span>
            </div>
            <div class="form-group">
                <span>{{translate.sections.navbarTop.platform}}:</span> <span> {{platformSelected}}</span>
            </div>
            <div class="form-group">
                <span>{{translate.sections.navbarTop.environment}}:</span> <span> {{envLoaded}}</span>
            </div>
            <p class="description-text">{{translate.sections.navbarTop.destination}}</p>
            <div class="form-group">
                <div class="div-flex">
                    <span for="">{{translate.sections.navbarTop.environment}}</span>
                    <select (change)="selectEnviroment(enviroment)" #enviroment class="selectInspectorStyle">
                        <option value="">{{translate.sections.navbarTop.select}}</option>
                        <option *ngFor="let enviroment of enviroments" value="{{enviroment.value}}">{{enviroment.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <div id="showBots" class="div-flex">
                    <span for="">{{translate.sections.navbarTop.botName}}</span>
                    <select (change)="selectBot()" [(ngModel)]="selectedBot" [disabled]="status === false"
                        class="selectInspectorStyle">
                        <option value="">{{translate.sections.navbarTop.select}}</option>
                        <option *ngFor="let bot of loadedBots" value="{{bot.slug}}">{{bot.name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <div *ngIf="showSelectBot" class="div-flex">
                    <span for="">{{translate.sections.navbarTop.platform}}</span>
                    <select [(ngModel)]="selectedPlatform" [disabled]="status===false" class="selectInspectorStyle">
                        <option value="">{{translate.sections.navbarTop.select}}</option>
                        <option *ngFor="let platform of listPlatforms" value="{{platform.name}}">{{platform.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="modal-actions">
            <button (click)="closeModal('popup-export')" class="btn-secondary">
                {{translate.sections.navbarTop.cancel}}
            </button>
            <button type="submit" (click)="export(enviroment)" class="btn-primary">
                {{translate.sections.navbarTop.export}}
            </button>
        </div>
    </div>
</jw-modal>
<!-- end modal -->
<!-- begin modal adjustments export -->
<jw-modal id="popup-adjustments-export">
    <a class="close_modal" (click)="closeModal('popup-adjustments-export');">×</a>
    <div class="createGuests">
        <h2 class="title">Adecuaciones después de exportar</h2>
        <div class="full">
            <p>
                Para que la construcción del bot exportado funcione correctamente en el bot destino se tienen que adecuar los siguientes elementos:
            </p>
            <div class="md-ui component-data-table" *ngFor="let adjust of dataExportAdjustments">
                <div class="main-table-wrapper">
                    <h4>Diagrama {{adjust.name}}</h4>
                    <table class="main-table-content">
                        <thead class="data-table-header">
                            <tr class="data-table-row">
                                <td>Nombre</td>
                                <td>Tipo</td>
                            </tr>
                        </thead>
                        <tbody class="data-table-content">
                            <tr *ngFor="let item of adjust.items" class="data-table-row">
                                <td>
                                    <span>{{item.name}}</span>
                                </td>
                                <td>
                                    <span>{{item.type}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-actions">
            <button (click)="closeModal('popup-adjustments-export');" class="btn-secondary">
                Cerrar
            </button>
        </div>
    </div>
</jw-modal>

<jw-modal id="popup-confirm-diagram-deletion">
    <a class="close_modal" (click)="closeModal('popup-confirm-diagram-deletion');">×</a>
    <h3>{{translate.sections.navbarTop.diagramActions.confirmDeletion}}</h3>

    <div class="modal-actions">
        <button (click)="this.closeModal('popup-confirm-diagram-deletion')" class="btn-secondary">{{translate.sections.navbarTop.cancel}}</button>
        <button (click)="this.deleteByDiagramSlug('popup-confirm-diagram-deletion')" class="btn-primary">{{translate.sections.navbarTop.delete}}</button>
    </div>

</jw-modal>
<!-- end modal -->
<!-- begin modal create tryme -->
<!-- <jw-modal id="popup-add-tryme">
    <a class="close_modal" (click)="closeModal('popup-add-tryme');">×</a>
    <div class="createGuests">
        <h2 class="title">Probar</h2>
        <div class="full">
            Para iniciar la prueba envía a <a [href]="linkToBot">tu bot</a> el mensaje:
            <p>#startFlow {{startFlowToken}}</p>
        </div>
        <div class="modal-actions">
            <button (click)="closeModal('popup-add-tryme');" class="btn-secondary">
                Cancelar
            </button>
            <button (click)="copy('#startFlow ' + startFlowToken)" class="btn-primary copy">
                Copiar mensaje
            </button>
        </div>
    </div>
</jw-modal> -->
