import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Loader } from '../models/loader';

@Injectable()
export class LoaderService {

    private subject = new Subject<Loader>();

    constructor() { }

    getLoader(loaderId?: string): Observable<Loader> {
        return this.subject.asObservable().pipe(filter((x: Loader) => x && x.loaderId === loaderId));
    }

    show() {
        this.loader(new Loader({ show: true }));
    }

    close() {
        this.subject.next(new Loader({ show: false }));
    }

    loader(loader: Loader) {
        this.subject.next(loader);
    }

}
