<div class="sectionConsoles">
    <!-- <h1 class="title">Consolas</h1> -->
    <!-- <h3 class="description">Integraciones</h3> -->


    <div class="wrapper-title">
        <h1 class="title">{{translate.sections.consoles.consoles}}</h1>
        <ul class="actions">

            <li>
                <button class="btn-primary" (click)="addConsola()">
                    <span>{{translate.sections.consoles.createNewConsole}}</span>
                    <i class="material-icons md-24">add_circle</i>
                </button>
            </li>
        </ul>
    </div>


    <!-- end list -->

    <div class="container-fluid" [ngStyle]="{'margin-top': '0px'}">
        <div class="divDelete">
            <!-- <a class="intercom btn-primary" href="{{intercomUrl}}">Conectar con Intercom</a> -->
            <!-- <button (click)="addConsola()" class="btn-primary">+ Agregar consola</button> -->
        </div>
        <div class="container1" *ngFor="let console of consoles;let i=index;">
            <img (click)="delete(console)" class="delete" src="../../assets/img/delete.svg" alt="">
            <!-- <button (click)="openModal('popup-confirm',canal.id,canal.channelOwnerName)"  class="delete">eliminar</button> -->
            <div class="view">

                <!-- <img class="image" [src]='routeImageChannel+console.consoleType+".svg"'
                    onError="this.src='../../assets/img/channels/puzzle.svg';" alt="nombre de la imagen"> -->
                <div class="info">
                    <p (click)="openModal('popup-edit',console)" class="main-title">{{console.consoleType}}</p>
                    <p (click)="openModal('popup-edit',console)" class="small-title">{{console.consoleAccountId}}</p>
                    <p class="small-title" *ngIf="console.consoleType === 'ZENDESK_SUPPORT_CONSOLE'">
                        <button (click)="copy(zendeskUrl)" class="btn-icon copy">
                            <i class="material-icons md-20">content_copy</i>
                        </button>
                        {{zendeskUrl}}
                    </p>
                    <small class="note" *ngIf="console.consoleType === 'ZENDESK_SUPPORT_CONSOLE'">
                        Copie y pegue esta url en el webhook de su consola zendesk
                    </small>
                </div>
            </div>

            <div class="options">


                <div class="data">
                    <span *ngIf="console.isEnabled" class="spanP">{{translate.sections.consoles.default}}</span>
                    <div class="onoffswitch">

                        <input [disabled]="console.isEnabled==true" [checked]='console.isEnabled==true'
                            (click)="activateConsole(console.id,console)" type="checkbox" name="onoffswitch"
                            class="onoffswitch-checkbox" id="{{i}}" tabindex="0">
                        <label class="onoffswitch-label" for="{{i}}"></label>
                    </div>


                </div>

            </div>
        </div>
    </div>



    <!-- begin modal edit -->


    <!-- begin modal create console -->
    <jw-modal id="popup-edit">
        <a class="close_modal" (click)="closeModal('popup-edit');">×</a>
        <div class="createConsole">

            <div>

                <div *ngIf="consola">
                    <h2>Editar consola</h2>

                    <div class="formGroup">

                        <label for="">Tipo</label>
                        <select (change)="changeConsole(typeConsole)" #typeConsole name="" id="selectType">
                            <!-- <option value="">Seleccionar</option> -->
                            <option [value]="type" *ngFor="let type of typesConsoles"
                                [selected]="type == consola.consoleType">{{type}}
                            </option>
                        </select>

                    </div>

                    <div *ngIf="consoleSelect=='UNDEFINED' || consoleSelect === 'GUS' || consoleSelect === 'MAILER' || consoleSelect === 'CHUBB'">

                        <div class="formGroup">
                            <label for="">Locación</label>
                            <input id="location" [(ngModel)]="consola.consoleLocation"
                                placeholder={{translate.sections.consoles.enterLocation}} #consoleLocation
                                class="inputInspectorStyle" type="text" (change)="onChangeEditLocation()">
                        </div>

                        <div class="formGroup">
                            <label for="">Id de la cuenta</label>

                            <input placeholder={{translate.sections.consoles.accountId}} [(ngModel)]="consola.consoleAccountId"
                                #consoleAccountId class="inputInspectorStyle" type="text" (change)="onChangeEditAccountId()">
                        </div>

                        <button (click)="saveConsole(typeConsole,consoleLocation,consoleAccountId)" type="button"
                            class="btn-primary btn-save">{{translate.sections.consoles.accept}}</button>
                    </div>

                    <div *ngIf="consoleSelect=='FB_HANDOVER'">

                        <div class="formGroup">
                            <label for="">Locación</label>
                            <input id="location" [(ngModel)]="consola.consoleLocation"
                                placeholder={{translate.sections.consoles.enterLocation}} #consoleLocation
                                class="inputInspectorStyle" type="text" (change)="onChangeEditLocation()">
                        </div>

                        <div class="formGroup">
                            <label for="">Id de la cuenta</label>

                            <input placeholder={{translate.sections.consoles.accountId}} [(ngModel)]="consola.consoleAccountId"
                                #consoleAccountId class="inputInspectorStyle" type="text" (change)="onChangeEditAccountId()">
                        </div>
                        <button (click)="saveConsole(typeConsole,consoleLocation,consoleAccountId)" type="button"
                            class="btn-primary btn-save">{{translate.sections.consoles.accept}}</button>
                    </div>



                    <div *ngIf="consoleSelect=='FRESHDESK'">

                        <div class="formGroup">
                            <label for="">Locación</label>
                            <input id="location" [(ngModel)]="consola.consoleLocation"
                                placeholder={{translate.sections.consoles.enterLocation}} #consoleLocation
                                class="inputInspectorStyle" type="text" (change)="onChangeEditLocation()">
                        </div>

                        <div class="formGroup">
                            <label for="">Id de la cuenta</label>

                            <input placeholder={{translate.sections.consoles.accountId}} [(ngModel)]="consola.consoleAccountId"
                                #consoleAccountId class="inputInspectorStyle" type="text" (change)="onChangeEditAccountId()">
                        </div>


                        <div class="formGroup">
                            <label for="">Token</label>

                            <input placeholder={{translate.sections.consoles.enterToken}} [(ngModel)]="consola.token"
                                #token class="inputInspectorStyle" type="text">
                        </div>
                        <button (click)="saveConsoleFresh(typeConsole,consoleLocation,consoleAccountId,token)" type="button"
                            class="btn-primary btn-save">{{translate.sections.consoles.accept}}</button>
                    </div>


                    <!-- zend support -->

                    <div *ngIf="consoleSelect=='ZENDESK_SUPPORT_CONSOLE'">

                        <div class="formGroup">
                            <label for="">Locación</label>
                            <input id="location" [(ngModel)]="consola.consoleLocation"
                                placeholder={{translate.sections.consoles.enterLocation}} #consoleLocation
                                class="inputInspectorStyle" type="text" (change)="onChangeEditLocation()">
                        </div>

                        <div class="formGroup">
                            <label for="">Id de la cuenta</label>

                            <input placeholder={{translate.sections.consoles.accountId}} [(ngModel)]="consola.consoleAccountId"
                                #consoleAccountId class="inputInspectorStyle" type="text" (change)="onChangeEditAccountId()">
                        </div>


                        <div class="formGroup">
                            <label for="">Nombre de usuario</label>

                            <input placeholder={{translate.sections.consoles.userName}} [(ngModel)]="consola.consoleUsername"
                                #consoleName class="inputInspectorStyle" type="text">
                        </div>

                        <div class="formGroup">
                            <label for="">Token</label>

                            <input placeholder={{translate.sections.consoles.enterToken}} [(ngModel)]="consola.token"
                                #token class="inputInspectorStyle" type="text">
                        </div>
                        <button (click)="saveConsoleZSC(typeConsole,consoleLocation,consoleAccountId,consoleName,token)" type="button"
                            class="btn-primary btn-save">{{translate.sections.consoles.accept}}</button>
                    </div>

                    <div *ngIf="consoleSelect=='THIRD'">

                        <div class="formGroup">
                            <label for="">Locación</label>
                            <input id="location" [(ngModel)]="consola.consoleLocation"
                                placeholder={{translate.sections.consoles.enterLocation}} #consoleLocation
                                class="inputInspectorStyle" type="text" (change)="onChangeEditLocation()">
                        </div>

                        <div class="formGroup">
                            <label for="">Id de la cuenta</label>

                            <input placeholder={{translate.sections.consoles.accountId}} [(ngModel)]="consola.consoleAccountId"
                                #consoleAccountId class="inputInspectorStyle" type="text" (change)="onChangeEditAccountId()">
                        </div>


                        <div class="formGroup">
                            <label for="">Nombre de usuario</label>
                            <input id="consoleUsername" [(ngModel)]="consola.consoleUsername"
                                placeholder={{translate.sections.consoles.userName}} #consoleUsername
                                class="inputInspectorStyle" type="text">
                        </div>

                        <h3 class="headerDiv">
                            Headers
                            <button type="button" class="btn-primary" (click)="addHeader()" >{{translate.sections.consoles.add}}
                            </button>

                        </h3>

                        <div  *ngFor="let h of headers; let i= index" class= "header" >
                            <input type="text"  [(ngModel)] = "h.key" placeholder="Key" class="inputInspectorStyle headers"/>
                            <input type="text" [(ngModel)] = "h.value" placeholder="Value" class="inputInspectorStyle headers"/>
                            <a (click)="removeHeader(i)" class="removeItem" >❌
                            </a>
                        </div>

                        <button (click)="saveConsoleThird(typeConsole,consoleLocation,consoleAccountId,consoleUsername)" type="button"
                            class="btn-primary btn-save">{{translate.sections.consoles.accept}}</button>
                    </div>

                    <div *ngIf="consoleSelect=='INTERCOM'">

                        <div class="formGroup">
                            <label for="">{{translate.sections.consoles.url}}</label>
                            <input id="location" [(ngModel)]="consola.consoleLocation"
                                placeholder={{translate.sections.consoles.enterUrl}}
                                #consoleLocation class="inputInspectorStyle" type="text" (change)="onChangeEditLocation()">
                        </div>

                        <button (click)="saveConsoleI(typeConsole, consoleLocation)" type="button"
                            class="btn-primary btn-save">{{translate.sections.consoles.connectWithIntercom}}</button>
                    </div>


                    <div *ngIf="consoleSelect=='ZENDESK_CHAT'">
                        <div class="formGroup">
                            <label for="">Locación</label>
                            <input id="location" [(ngModel)]="consola.consoleLocation"
                                placeholder={{translate.sections.consoles.enterLocation}} #consoleLocation
                                class="inputInspectorStyle" type="text" (change)="onChangeEditLocation()">
                        </div>

                        <div class="formGroup">
                            <label for="">Id de la cuenta</label>

                            <input placeholder={{translate.sections.consoles.accountId}} [(ngModel)]="consola.consoleAccountId"
                                #consoleAccountId class="inputInspectorStyle" type="text" (change)="onChangeEditAccountId()">
                        </div>
                        <input [disabled]="true" [value]="" class="inputInspectorStyle" type="text">
                        <button (click)="saveConsole(typeConsole,consoleLocation,consoleAccountId)" type="button"
                            class="btn-primary btn-save">{{translate.sections.consoles.accept}}</button>
                    </div>

                    <div *ngIf="consoleSelect=='SMOOCH_SWITCHBOARD'">

                        <div class="formGroup">
                            <label for="">Locación</label>
                            <input id="location" [(ngModel)]="consola.consoleLocation"
                                placeholder={{translate.sections.consoles.enterLocation}} #consoleLocation
                                class="inputInspectorStyle" type="text">
                        </div>

                        <div class="formGroup">
                            <label for="">Id de la cuenta</label>

                            <input placeholder={{translate.sections.consoles.accountId}} [(ngModel)]="consola.consoleAccountId"
                                #consoleAccountId class="inputInspectorStyle" type="text">
                        </div>
                        <button (click)="saveConsoleS(typeConsole)" type="button"
                            class="btn-primary btn-save">OAuth Login</button>
                        <button (click)="saveConsole(typeConsole,consoleLocation,consoleAccountId)" type="button"
                            class="btn-primary btn-save">{{translate.sections.consoles.accept}}</button>
                    </div>
                </div>
            </div>
        </div>

    </jw-modal>
    <!-- end modal -->
    <!-- end modal -->
</div>
