import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, Event } from '@angular/router';

import { ModalService } from '../../components/modal/modal.service';
// import { AlertService } from '../../services/alert.service';
import { CustomerService } from '../../services/customer.service';
import { AlertService } from 'src/services/alert.service';

import { Entity } from '../../models/entity';
import { entityTypes } from '../../enums/entity-types';

import { environment } from '../../environments/environment';

import * as $ from 'jquery';
declare var translate: any;

@Component({
    selector: 'app-navbar-left',
    templateUrl: './navbar-left.component.html',
    styleUrls: ['./navbar-left.component.scss']
})
export class NavbarLeftComponent implements OnInit {

    inDiagrammer = false;
    currentSlug = '';
    testingUrl = '';
    entityTypes;
    entityTypesKeys = [];

    entities: Entity[] = [];
    currentEntity = new Entity();
    nlpEntities = '';
    modalBodyStyles = {};

    translate: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private customerService: CustomerService,
        private alertService: AlertService,
        private modalService: ModalService
    ) {
        router.events.subscribe( (event: Event) => {
            if (event instanceof NavigationStart) {
                this.getUrlSegments((urlSegments) => {
                    const existsDiagrammer = [];
                    urlSegments.forEach(urlSegment => {
                        if (urlSegment.path === 'diagram') {
                            existsDiagrammer.push(true);
                            // console.log('getEntities');
                            this.getEntities();
                        } else {
                            existsDiagrammer.push(false);
                        }
                    });
                    if (existsDiagrammer.length > 0) {
                        this.inDiagrammer = existsDiagrammer.reduce((prev, next) => {
                            return prev || next;
                        });
                    }
                });

                this.getUrlParam('customer_slug', (customerSlug) => {
                    if (customerSlug !== null) {
                        this.currentSlug = customerSlug;
                        this.getUrlParam('platform_slug', (platformSlug) => {
                            // console.log('getBySlug ', platformSlug);
                            this.customerService.getBySlug(customerSlug).subscribe(
                                (res2: any) => {
                                    res2.tenant.channels.forEach(channel => {
                                        if (!platformSlug) {
                                            platformSlug = 'WHATSAPP';
                                        }
                                        if (channel.channel.includes(platformSlug)) {
                                            if (channel.channel === 'WEBSITE') {
                                                this.testingUrl = environment.chatUrl + 'test.html?channel_owner_id=' + channel.channelOwnerId;
                                                this.testingUrl += '&guschat=open_box';
                                            } else if (channel.channel === 'FB_MESSENGER') {
                                                this.testingUrl = 'https://www.facebook.com/messages/t/' + channel.channelOwnerId;
                                            } else if (channel.channel === 'SMOOCH_SANDBOX' && channel.channelSubtype === 'WHATSAPP' ||
                                                channel.channel === 'WHATSAPP_SANDBOX' && channel.channelSubtype === 'WHATSAPP') {
                                                const chunks = channel.label.split(' - ');
                                                const phone = chunks[1];
                                                this.testingUrl = 'https://wa.me/' + phone.replace(/\s/g, '');
                                            } else if (channel.channel === 'WHATSAPP' && channel.channelSubtype === 'CLOUD') {
                                                const chunks = channel.label.split(' ');
                                                this.testingUrl = 'https://wa.me/' + chunks[1].replace(/\s/g, '');
                                            } else if (channel.channel === 'WHATSAPP' && channel.channelSubtype === 'WHATSAPP') {
                                                this.testingUrl = 'https://wa.me/' + channel.channelOwnerId;
                                            }
                                        }
                                    });

                                },
                                err2 => {
                                    /*console.log(err2);
                                    this.alertService.error(
                                        'Error: no se pudo encontrar un canal donde mostrar el bot.'
                                    );*/
                                }
                            );
                        });
                    }
                });
            }
        });
        this.modalBodyStyles = {
            width: '940px'
        };
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    ngOnInit() {
        const intervalCount = setInterval(() => {
            if (this.currentSlug !== '') {
                // this.getEntities();
                this.entityTypes = entityTypes;
                this.entityTypesKeys = Object.keys(entityTypes);
                clearInterval(intervalCount);
            }
        }, 500);
    }

    sort(obj: any[], attr: string) {
        return obj.sort((a, b) => (a[attr] > b[attr]) ? 1 : ((b[attr] > a[attr]) ? -1 : 0));
    }

    checkValueMain(entity) {
        for (const [idx, entityAux] of this.entities.entries()) {
            if (entityAux.id === entity.id) {
                this.entities[idx] = entity;
            }
        }
    }

    showEntities() {
        this.getEntities();
        this.openModal('modal-entities');
    }

    getEntities() {
        const intervalEntities = setInterval(() => {
            const entitiesStr = $('.nlp_entities').val();
            if (entitiesStr !== '') {
                clearInterval(intervalEntities);
                this.entities = JSON.parse(entitiesStr.toString());
                this.entities = this.sort(this.entities, 'name');
                this.entities = this.entities.map((e: Entity) => {
                    if (e.regex) {
                        e.regexTxt = e.regex.join('\n');
                    }
                    if (e.groups) {
                        e.groupsTxt = e.groups.join('\n');
                    }
                    return e;
                });
                // console.log(this.entities);
            }
        }, 500);
    }

    editEntity(entity: Entity) {
        entity.editing = true;
        // console.log(entity);
    }

    deleteEntity(entity: Entity) {
        // console.log('Delete entity: ', entity);
        this.entities = this.entities.filter(ent => ent.id !== entity.id);
        this.entitiesToString();
        $('.nlp_entities').val(JSON.stringify(this.entities));
        $('.save_diagram').trigger( "click" );
    }

    createEntity() {
        // console.log('Create entity');
        const entity = new Entity();
        entity.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        entity.name = '';
        entity.main =  false;
        entity.editing = true;
        entity.entityType = 'EXTRACTOR';
        entity.type = '';
        this.entities.push(entity);
        this.entities = this.sort(this.entities, 'name');
    }

    updateEntity(entity: Entity) {
        // console.log('Update entity: ', entity);
        let error = false;

        if (entity.regexTxt !== '') {
            entity.regex = entity.regexTxt.split(/\n/);
        }
        if (entity.groupsTxt !== '') {
            entity.groups = entity.groupsTxt.split(/\n/);
        }

        if (!entity.name.trim()) {
            this.alertService.error(translate.sections.navbarLeft.requiredName);
            error = true;
        }
        if (!entity.regexTxt.trim()) {
            this.alertService.error(translate.sections.navbarLeft.requiredRegex);
            error = true;
        }
        if (!entity.entityType.trim()) {
            this.alertService.error(translate.sections.navbarLeft.requiredEntityType);
            error = true;
        }

        if (!error){
            entity.state = 'defined';
            entity.editing = false;
            this.entitiesToString();
            $('.nlp_entities').val(JSON.stringify(this.entities));
            $('.save_diagram').trigger( "click" );
            this.alertService.success(translate.sections.navbarLeft.savedEntity);
        }
    }

    entitiesToString() {
        this.nlpEntities = JSON.stringify(this.entities);
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    getUrlSegments(success) {
        const paramInterval = setInterval(() => {
            if (this.route.firstChild !== null) {
                if (this.route.firstChild.pathFromRoot !== null) {
                    clearInterval(paramInterval);
                    if (this.route.firstChild.pathFromRoot.length > 0) {
                        this.route.firstChild.pathFromRoot[1].url.subscribe(url => {
                            success(url);
                        });
                    }
                }
            }
        }, 500);
    }

    getUrlParam(param: string, success) {
        const paramInterval = setInterval(() => {
            if (this.route.firstChild !== null) {
                if (this.route.firstChild.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.route.firstChild.paramMap.subscribe(params => {
                        const p = params.get(param);
                        success(p);
                    });
                }
            }
        }, 500);
    }

    saveAll() {
        let errors = false;
        this.entities.forEach(entity => {
            let error = false;
            // console.log('Update entity: ', entity);

            if (entity.regexTxt !== '') {
                entity.regex = entity.regexTxt.split(/\n/);
            }
            if (entity.groupsTxt !== '') {
                entity.groups = entity.groupsTxt.split(/\n/);
            }

            if (!entity.name.trim()) {
                this.alertService.error(translate.sections.navbarLeft.requiredName);
                error = true;
                errors = true;
            }
            if (!entity.regexTxt.trim()) {
                this.alertService.error(translate.sections.navbarLeft.requiredRegex);
                error = true;
                errors = true;
            }
            if (!entity.entityType.trim()) {
                this.alertService.error(translate.sections.navbarLeft.requiredEntityType);
                error = true;
                errors = true;
            }
            if (!error){
                entity.state = 'defined';
                entity.editing = false;
            }
        });
        if (!errors){
            this.entitiesToString();
            $('.nlp_entities').val(JSON.stringify(this.entities));
            $('.save_diagram').trigger( "click" );
            this.alertService.success(translate.sections.navbarLeft.savedEntities);
        }

    }

    getSubtypeName(subtype) {
        let subtypeName = '';
        if (subtype === 'entity') {
            subtypeName = 'Entidad';
        } else if (subtype === 'slot') {
            subtypeName = 'Memoria';
        }
        return subtypeName;
    }

}
