import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class IntercomService {

    constructor(private http: HttpClient) { }

    getToken(code: string) {
        const clientId = environment.intercom.clientId;
        const state = environment.intercom.state;
        const url = `/api_intercom/auth/eagle/token`;
        const payload = {
            code,
            client_id: clientId,
            client_secret: state
        };
        const headers = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Host': 'flux-v2-pre.gus.chat'
        });
        return this.http.post(url, payload, { headers });
    }

    getAdmins(token) {
        const url = `/api_intercom/admins`;
        const headers = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        });
        return this.http.get(url, { headers });
    }
}
