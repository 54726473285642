export enum HSMMediaType {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    DOCUMENT = 'DOCUMENT',
    VIDEO = 'VIDEO',
    NONE = 'NONE'
};

export enum HSMType {
    TEXT = 'TEXT',
    MEDIA = 'MEDIA'
};

export enum HSMButtonType {
    PAYLOAD = 'PAYLOAD',
    PHONE_NUMBER = 'PHONE_NUMBER',
    URL = 'URL',
    NONE = 'NONE'
};

export enum HSMButtonSetType {
    QUICK_REPLY = 'QUICK_REPLY',
    CALL_TO_ACTION = 'CALL_TO_ACTION',
    NONE = 'NONE'
}

export enum HSMCategoryType {
    ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
    PAYMENT_UPDATE = 'PAYMENT_UPDATE',
    PERSONAL_FINANCE_UPDATE = 'PERSONAL_FINANCE_UPDATE',
    SHIPPING_UPDATE = 'SHIPPING_UPDATE',
    RESERVATION_UPDATE = 'RESERVATION_UPDATE',
    ISSUE_RESOLUTION = 'ISSUE_RESOLUTION',
    APPOINTMENT_UPDATE = 'APPOINTMENT_UPDATE',
    TRANSPORTATION_UPDATE = 'TRANSPORTATION_UPDATE',
    TICKET_UPDATE = 'TICKET_UPDATE',
    ALERT_UPDATE = 'ALERT_UPDATE',
    AUTO_REPLY = 'AUTO_REPLY'
}

export enum HSMStatusType {
    NOT_SENT = 'NOT_SENT',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    DISABLED = 'DISABLED',
    ARCHIVED = 'ARCHIVED',
    IN_APPEAL = 'IN_APPEAL',
    ERROR = 'ERROR'
}

export enum HSMLanguagesType {
    af = 'af',
    sq = 'sq',
    ar = 'ar',
    az = 'az',
    bn = 'bn',
    bg = 'bg',
    ca = 'ca',
    zh_CN = 'zh_CN',
    zh_HK = 'zh_HK',
    zh_TW = 'zh_TW',
    hr = 'hr',
    cs = 'cs',
    da = 'da',
    nl = 'nl',
    en = 'en',
    en_GB = 'en_GB',
    en_US = 'en_US',
    et = 'et',
    fil = 'fil',
    fi = 'fi',
    fr = 'fr',
    de = 'de',
    el = 'el',
    gu = 'gu',
    ha = 'ha',
    he = 'he',
    hi = 'hi',
    hu = 'hu',
    id = 'id',
    ga = 'ga',
    it = 'it',
    ja = 'ja',
    kn = 'kn',
    kk = 'kk',
    ko = 'ko',
    lo = 'lo',
    lv = 'lv',
    lt = 'lt',
    mk = 'mk',
    ms = 'ms',
    ml = 'ml',
    mr = 'mr',
    nb = 'nb',
    fa = 'fa',
    pl = 'pl',
    pt_BR = 'pt_BR',
    pt_PT = 'pt_PT',
    pa = 'pa',
    ro = 'ro',
    ru = 'ru',
    sr = 'sr',
    sk = 'sk',
    sl = 'sl',
    es = 'es',
    es_AR = 'es_AR',
    es_ES = 'es_ES',
    es_MX = 'es_MX',
    sw = 'sw',
    sv = 'sv',
    ta = 'ta',
    te = 'te',
    th = 'th',
    tr = 'tr',
    uk = 'uk',
    ur = 'ur',
    uz = 'uz',
    vi = 'vi',
    zu = 'zu'
}

export enum HSMScopeType {
    CAMPAIGNS = 'CAMPAIGNS',
    API = 'API',
    ONE_TO_ONE = 'ONE_TO_ONE',
    ALL = 'ALL'
}

// template types

export enum HSMTemplateType {
    BUTTONS = 'BUTTONS',
    STANDARD = 'STANDARD'
}

// Labels
export enum HSMButtonSetTypeLabel {
    QUICK_REPLY = 'Respuesta rápida',
    CALL_TO_ACTION = 'Llamada a la acción'
}

export enum HSMTemplateTypeLabel {
    BUTTONS = 'Con botones',
    STANDARD = 'Estándar'
}
export enum HSMMediaTypeLabel {
    TEXT = 'Texto',
    IMAGE = 'Imagen',
    DOCUMENT = 'Doc.',
    VIDEO = 'Video'
};

export enum HSMCategoryTypeLabel {
    ACCOUNT_UPDATE = 'Actualización de cuenta',
    PAYMENT_UPDATE = 'Actialización de pago',
    PERSONAL_FINANCE_UPDATE = 'Actualización de finanzas personales',
    SHIPPING_UPDATE = 'Actualización de envío',
    RESERVATION_UPDATE = 'Actualización de reservación',
    ISSUE_RESOLUTION = 'Resolución de problemas',
    APPOINTMENT_UPDATE = 'Actualización de cita',
    TRANSPORTATION_UPDATE = 'Actualización de transporte',
    TICKET_UPDATE = 'Actualización de tiquete',
    ALERT_UPDATE = 'Actualización de alerta',
    AUTO_REPLY = 'Respuesta automática'
}

export enum HSMStatusTypeLabel {
    NOT_SENT = 'Borrador',
    PENDING = 'Pendiente',
    APPROVED = 'Aprobada',
    REJECTED = 'Rechazada',
    DISABLED = 'Deshabilitada',
    ARCHIVED = 'Archivada',
    IN_APPEAL = 'En apelación',
    ERROR = 'Error'

}

export enum HSMLanguagesTypeLabel {
    af = 'Afrikaans',
    sq = 'Albanian ',
    ar = 'Arabic  ',
    az = 'Azerbaijani ',
    bn = 'Bengali ',
    bg = 'Bulgarian',
    ca = 'Catalan ',
    zh_CN = 'Chinese (CHN)',
    zh_HK = 'Chinese (HKG)',
    zh_TW = 'Chinese (TAI)',
    hr = 'Croatian ',
    cs = 'Czech',
    da = 'Danish  ',
    nl = 'Dutch',
    en = 'English ',
    en_GB = 'English (UK) ',
    en_US = 'English (US) ',
    et = 'Estonian ',
    fil = 'Filipino ',
    fi = 'Finnish ',
    fr = 'French  ',
    de = 'German  ',
    el = 'Greek',
    gu = 'Gujarati ',
    ha = 'Hausa',
    he = 'Hebrew  ',
    hi = 'Hindi',
    hu = 'Hungarian',
    id = 'Indonesian',
    ga = 'Irish',
    it = 'Italian',
    ja = 'Japanese',
    kn = 'Kannada',
    kk = 'Kazakh',
    ko = 'Korean',
    lo = 'Lao',
    lv = 'Latvian',
    lt = 'Lithuanian',
    mk = 'Macedonian',
    ms = 'Malay',
    ml = 'Malayalam',
    mr = 'Marathi',
    nb = 'Norwegian',
    fa = 'Persian',
    pl = 'Polish ',
    pt_BR = 'Portuguese (BR)',
    pt_PT = 'Portuguese (POR)',
    pa = 'Punjabi',
    ro = 'Romanian',
    ru = 'Russian',
    sr = 'Serbian',
    sk = 'Slovak ',
    sl = 'Slovenian',
    es = 'Español',
    es_AR = 'Español (ARG)',
    es_ES = 'Español (SPA)',
    es_MX = 'Español (MEX)',
    sw = 'Swahili ',
    sv = 'Swedish ',
    ta = 'Tamil',
    te = 'Telugu  ',
    th = 'Thai ',
    tr = 'Turkish ',
    uk = 'Ukrainian',
    ur = 'Urdu ',
    uz = 'Uzbek',
    vi = 'Vietnamese  ',
    zu = 'Zulu ',
}
