<div>
    <section class="md-ui component-data-table webhooks" *ngIf="webhooks">
        <div class="main-table-wrapper">
            <table class="main-table-content">
                <thead class="data-table-header">
                    <tr class="data-table-row">
                        <td>Name</td>
                        <td>Link</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody class="data-table-content">
                    <!-- , pageBoundsCorrection:pageChanged()  -->
                    <tr *ngFor="let webhook of webhooks | paginate: { itemsPerPage: webhooksPerPage, currentPage: webhooksPage, totalItems:webhooksTotal}"
                        [ngClass]="{'data-table-row': true, 'new': webhook.new}">
                        <td>{{webhook.whatsapp_business_api_data.name}}</td>
                        <td>
                            <a [href]="webhook.whatsapp_business_api_data.link">
                                {{webhook.whatsapp_business_api_data.link}}
                            </a>
                        </td>
                        <td>
                            <button (click)="openModal('popup-confirm-delete-subscription', webhook)" title="Eliminar"
                                class="btn-icon">
                                <i class="material-icons md-20">delete</i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <footer class="main-table-footer">
            <pagination-template #p="paginationApi" (pageChange)="phonesPageUpdate($event)">
                <ul class="pagination">
                    <li [ngClass]="{'pagination-previous': true, 'disabled': p.isFirstPage()}">
                        <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
                            << Anterior </a>
                                <span *ngIf="p.isFirstPage()">
                                    <span>
                                        << Anterior </span>
                                    </span>
                    </li>

                    <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                            <span>{{ page.label }}</span>
                        </a>
                        <span *ngIf="p.getCurrent() === page.value">
                            <span>{{ page.label }}</span>
                        </span>
                    </li>

                    <li [ngClass]="{'pagination-next': true, 'disabled': p.isLastPage()}">
                        <a *ngIf="!p.isLastPage()" (click)="p.next()">
                            Siguiente >>
                        </a>
                        <span *ngIf="p.isLastPage()">
                            <span>Siguiente >></span>
                        </span>
                    </li>
                </ul>
            </pagination-template>

        </footer> -->
    </section>
</div>

<!-- begin modal confirm delete -->
<jw-modal id="popup-confirm-delete-subscription">
    <a class="close_modal" (click)="closeModal('popup-confirm-delete-subscription');">×</a>
    <h3>¿Esta seguro de que desear eliminar la suscripción?</h3>

    <div class="modal-actions">
        <button (click)="closeModal('popup-confirm-delete-subscription');" class="btn-secondary">Cancelar</button>
        <button class="btn-primary">Eliminar</button>
    </div>

</jw-modal>
<!-- end modal confirm delete -->
