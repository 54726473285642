import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUserService } from 'src/services/api-user.service';
import { ModalService } from '../modal/modal.service';
import { AlertService } from '../../services/alert.service';
import { ApiUsersRoles } from '../../enums/api-users-roles';
import { DataLayerService } from 'src/services/data-layer.service';

import tippy from 'tippy.js';
declare var translate: any;
@Component({
    selector: 'app-api-users',
    templateUrl: './api-users.component.html',
    styleUrls: ['./api-users.component.scss']
})
export class ApiUsersComponent implements OnInit {

    existsUsers: boolean;
    userDelete: any;
    username: string;
    passw: string;
    hasToken: boolean;
    users: Array<any>;
    customerSlug: string;
    roles: Array<any>;
    selectedRole: string;
    translate: any;
    flagPath: string;

    constructor(
        private modalService: ModalService,
        private apiUser: ApiUserService,
        private activateRoute: ActivatedRoute,
        private alertService: AlertService,
        private dataLayerService: DataLayerService
    ) {
        this.existsUsers = false;
        this.userDelete = null;
        this.username = '';
        this.passw = '';
        this.hasToken = true;
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.selectedRole = '';
        this.roles = [];
        this.users = [];
        this.flagPath = 'Apikeys -> ';
    }

    ngOnInit() {
        this.customerSlug = this.activateRoute.snapshot.paramMap.get('customer_slug');
        this.getRoles();
        this.getUsers();
        this.dataLayerService.setFlag(this.flagPath, 'start');
    }

    openModal(id: string, username='') {
        this.modalService.open(id);
        if (username === '') {
            this.username = this.generateUser();
            this.passw = this.generatePassword();
            this.userDelete = null;
        } else {
            this.userDelete = username;
        }
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.username = '';
        this.passw = '';
    }

    copy(str: string) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    }

    copyPass(user) {
        this.getToken(user.id, (token) => {
            this.copy(token);
        });
    }

    copyBase64(user) {
        this.getTokenHidden(user.id, (token) =>{
            const auth = user.username+":"+token;
            this.copy(btoa(auth));
        });
    }

    setTooltipConf() {
        setTimeout(() => {
            tippy('.copy', {
                content: translate.sections.apiUsers.copied,
                trigger: 'click',
            });
        }, 2000);
    }

    getUsers() {
        this.apiUser.getAll(this.customerSlug).subscribe(
            (res: any) => {
                if (res.length > 0) {
                    this.existsUsers = true;
                    this.users = res;
                    // this.users.rolesNames = [];
                    res.forEach((user, i) => {
                        const rolesNames = [];
                        user.roles.forEach(role => {
                            rolesNames.push(ApiUsersRoles[role.name]);
                            this.users[i].rolesNames = rolesNames.join(', ');
                        });
                    });
                    this.setTooltipConf();
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    getToken(id, success=null) {
        this.apiUser.getToken(id, this.customerSlug).subscribe(
            (res: any) => {
                const idx = this.users.findIndex(u => u.id === id);
                this.users[idx].password = res.token.token;
                if (success && typeof success === 'function'){
                    success(res.token.token);
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    getTokenHidden(id, success=null) {
        this.apiUser.getToken(id, this.customerSlug).subscribe(
            (res: any) => {
                const idx = this.users.findIndex(u => u.id === id);
                if (success && typeof success === 'function'){
                    success(res.token.token);
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    generatePassword(length = 48) {
        let generatedPassword = '';
        const symbol = '!@#$%^&*(){}[]=<>/,.|~?_-';
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789' + symbol;
        for (var i = 0, n = charset.length; i < length; ++i) {
            generatedPassword += charset.charAt(Math.floor(Math.random() * n));
        }
        return generatedPassword;
    }

    generateUser(){
        let user = (new Date().getTime()).toString(36);
        return `api@${this.customerSlug}_${user}`;
    }

    saveUser() {
        if (this.selectedRole === '') {
            this.alertService.error(translate.sections.apiUsers.errorRole);
        } else {
            this.username = this.generateUser();
            this.passw = this.generatePassword();

            const user = {
                id: null,
                username: this.username,
                password: this.passw,
                createToken: this.hasToken,
                role: this.selectedRole
            }

            this.apiUser.create(user, this.customerSlug).subscribe(
                (res: any) => {
                    // console.log(res);
                    user.password = res.currentToken.token;
                    user.id = res.id;
                    this.users.unshift(user);
                    this.existsUsers = true;
                    this.modalService.close('popup-new-user');
                    this.setTooltipConf();
                    this.dataLayerService.setFlag(this.flagPath, 'new -> create');
                }, err => {
                    console.log(err);
                }
            );
        }
    }

    deleteUser(){
        this.apiUser.delete(this.userDelete.id, this.customerSlug).subscribe(
            (res: any) => {
                const idx = this.users.findIndex(v => v.username === this.userDelete.username);
                if (idx >= 0) {
                    this.users.splice(idx, 1);
                    if (this.users.length === 0) {
                        this.existsUsers = false;
                    }
                }
                this.dataLayerService.setFlag(this.flagPath, 'erase -> confirm');
                this.modalService.close('popup-delete-user');
                this.userDelete = null;
            }, err => {
                console.log(err);
            }
        );
    }

    getRoles() {
        this.apiUser.getRoles(this.customerSlug).subscribe(
            (res: any) => {
                let roles = [];
                this.roles = [];
                res.forEach(element => {
                    roles = roles.concat(element.allowedRoles);
                });
                roles = roles.filter((v, i, s) => s.indexOf(v) === i);
                roles.forEach(role => {

                    this.roles.push({
                        name: ApiUsersRoles[role],
                        key: role
                    });
                });
                this.selectedRole = '';
            },
            err => {
                console.log(err);
            }
        );
    }

    openDeletePopup(usr) {
        this.dataLayerService.setFlag(this.flagPath, 'erase -> start');
        this.openModal('popup-delete-user', usr);
    }

    openNewPopup() {
        this.dataLayerService.setFlag(this.flagPath, 'new -> start');
        this.openModal('popup-new-user');
    }
    cancelPopup() {
        this.dataLayerService.setFlag(this.flagPath, 'new -> cancel');
        this.closeModal('popup-new-user');
    }

    onChangeRole() {
        this.dataLayerService.setFlag(this.flagPath, 'new -> rol');
    }
}
