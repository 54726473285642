import { Component, OnInit } from "@angular/core";
import { ModalService } from "src/components/modal";
import { AlertService } from "src/services/alert.service";
import { WabaService } from 'src/services/waba.service';

@Component({
    selector: 'app-webhooks',
    templateUrl: './webhooks.component.html',
    styleUrls: ['./webhooks.component.scss']
})
export class WebhooksComponent implements OnInit {

    webhooks: any;
    webhooksPage = 0;
    webhooksTotal = 0;
    webhooksPerPage = 10;

    currentWebhook: any;

    constructor(
        private alertService: AlertService,
        private modalService: ModalService,
        private wabaService: WabaService,
    ) { }

    ngOnInit() {
        this.getWebhooks();
    }

    getWebhooks() {
        this.wabaService.getAllSubscriptionsForWABA().subscribe(
            (data) => {
                // this.webhooks = data.data;
                this.webhooks = [
                    {
                      "whatsapp_business_api_data": {
                        "link": "Application 1 link",
                        "name": "Application 1 Name",
                        "id": "7234002551525653"
                      }
                    },
                    {
                      "whatsapp_business_api_data": {
                        "link": "Application 2 link",
                        "name": "Application 2 Name",
                        "id": "3736565603394103"
                      }
                    }
                ];
            },
            (error) => {
                console.error(error);
                this.alertService.error("No se ha podido obtener las suscripciones");
            }
        );
    }

    closeModal(id) {
        this.modalService.close(id);
    }

    openModal(id: string, webhook: any = null) {
        this.modalService.open(id);
        if (webhook) {
            this.currentWebhook = webhook;
        }
    }

}
