export class Chat {
    id: string;
    botImage: string;
    botTitle: string;
    headerColor: string;
    closeImage: string;
    titleColor: string;
    msgBotTxtColor: string;
    msgBotBoxColor: string;
    msgUsrTxtColor: string;
    msgUsrBoxColor: string;
    buttonImage: string;
    inCookies: boolean;
    openChatOnLeavingPage: boolean;
    ratioAssignedPercent: number;
    scrollPercentToOpen: number
    secondsToOpen: number;
    version: number;
    createdAt: string;
    lastModifiedAt: string;
    showWelcomeGreeting: boolean;
    welcomeGreeting: string;
    dotColor: string;
    buttonColorBg: string;
    showWelcomeBotMsg: boolean;
    showWelcomeGreetingMobile: boolean;
    openChatOnLoadDesktop: boolean;
    openChatOnLoadMobile: boolean;
    showWelcomeBotMsgAlways: boolean;
    showWebviewOnClick: string;
    size: string;
    zindex: string;
    showCounterMessages: boolean;
    showFloatingMessages: boolean;
    showFloatingLastMessage: boolean;
    persistentMenu: string;
    allowTracking: boolean;
    allowTrackingLocation: boolean;
}
