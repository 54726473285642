<section class="sectionWaba">

    <div class="wrapper-title">
        <h1 class="title">{{translate['misCuentasWa']}}</h1>
        <ul class="actions">
            <li>
                <button class="btn-primary" (click)="openModal('popup-create-waba')">
                    <span>{{translate['crearCuentaoNumero']}}</span>
                    <i class="material-icons md-24">add_circle</i>
                </button>
            </li>
        </ul>
    </div>

    <!-- <div *ngIf="wabas.length === 0" class="emptyWaba">
        <h1>Aún no hay cuentas aquí</h1>
        <h4>Da clic en el botón para comenzar</h4>
        <button class="btn-primary" (click)="singInFacebook()">
            <span>Conectar cuenta</span>
            <i class="material-icons md-24">whatsapp</i>
        </button>
    </div> -->

    <div class="divWaba">
        <!-- <div class="status">
            <i class="material-icons" [title]="accountReviewStatus.name">{{accountReviewStatus.icon}}</i>
        </div> -->

        <div class="fileds">
            <div class="field-box">
                <label>{{translate['negocio']}}</label>
                <select [(ngModel)]="currentBusinessId" (change)="onSelectBusiness()">
                    <option value="">{{translate['selectNegocio']}}</option>
                    <option *ngFor="let business of businessIds" [value]="business.id">
                        {{business.name}}
                    </option>
                </select>
                <div class="status" *ngIf="businessReviewStatus?.icon">
                    <small class="{{businessReviewStatus?.icon}}">{{translate['negocio']}} {{businessReviewStatus?.name}}</small>
                    <small *ngIf="businessReviewStatus?.key === 'not_verified'"> - <a class="link" [href]="'https://business.facebook.com/settings/security/?business_id=' + currentBusinessId" target="_blank">Iniciar verificación</a></small>
                </div>
            </div>
        </div>

        <div class="fileds">
            <div class="field-box">
                <label>{{translate['cuentaWhatsapp']}}</label>
                <select [(ngModel)]="currentWabaId" (change)="onSelectWaba()">
                    <option value="">{{translate['cuentaWhatsapp']}}</option>
                    <option *ngFor="let waba of wabas" [value]="waba.id">
                        {{waba.name}}
                    </option>
                </select>
                <div class="status" *ngIf="accountReviewStatus?.icon">
                    <small class="{{accountReviewStatus?.icon}}">{{translate['cuentaWhatsapp']}} {{accountReviewStatus?.name}}</small>
                    <br>
                    <small class="info-not-verified" *ngIf="businessReviewStatus?.key === 'not_verified'">Para que se pueda revisar esta cuenta, el negocio debe aprobar tu solicitud para enviar mensajes en su nombre y completar el proceso de verificación.</small>
                </div>
            </div>
        </div>

        <div class="actions">
            <!-- <button [ngClass]="{'btn-primary': true, 'active': active.phones}" (click)="setSelect('phones')">
                <span>Números de teléfono</span>
                <i class="material-icons md-20">phone</i>
            </button> -->
            <!-- <button [ngClass]="{'btn-primary': true, 'active': active.systemUsers}" (click)="setSelect('systemUsers')">
                <span>Usuarios</span>
                <i class="material-icons md-20">people</i>
            </button>
            <button [ngClass]="{'btn-primary': true, 'active': active.creditLines}" (click)="setSelect('creditLines')">
                <span>Facturación y pagos</span>
                <i class="material-icons md-20">monetization_on</i>
            </button>
            <button [ngClass]="{'btn-primary': true, 'active': active.webhooks}" (click)="setSelect('webhooks')">
                <span>Webhooks</span>
                <i class="material-icons md-20">webhook</i>
            </button> -->
        </div>

        <app-phones *ngIf="select('phones')" [wabaId]="currentWabaId2" (change)="test()"></app-phones>
        <!-- <app-system-users *ngIf="select('systemUsers')" [wabaId]="currentWabaId"></app-system-users>
        <app-credit-lines *ngIf="select('creditLines')" [wabaId]="currentWabaId"></app-credit-lines>
        <app-webhooks *ngIf="select('webhooks')" [wabaId]="currentWabaId"></app-webhooks> -->

    </div>
</section>


<!-- begin modal create user -->
<jw-modal id="popup-create-waba">
    <a class="close_modal" (click)="closeModal('popup-create-waba');">×</a>
    <div class="createUser">
        <h2 class="title">{{translate['nuevaCuentaoNumero']}}</h2>

        <div class="fileds">
            <div class="field-box">
                <p>{{translate['fieldNewAccount']}}</p>
            </div>
            <!-- <div class="field-box">
                <label for="hasToken">Crear token</label>
                <input [(ngModel)]="hasToken" id="hasToken" type="checkbox">
            </div> -->
            <div class="modal-actions">
                <button (click)="closeModal('popup-create-waba');" class="btn-secondary">
                    {{translate['botonCerrar']}}
                </button>
                <button (click)="singInFacebook()" class="btn-primary">
                    {{translate['botonCrear']}}
                </button>
            </div>
        </div>

    </div>

</jw-modal>
<!-- end modal create user -->
