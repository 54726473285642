<!-- begin modal create campaign -->
<jw-modal id="popup-new-campaign">
    <a class="close_modal" (click)="closeModal('popup-new-campaign');">×</a>
    <div class="createCampaign">
        <h2 class="title">{{translate.sections.campaigns.showCampaings.newCampaing}}</h2>
        <!-- <h3 class="description">Lorem ipsum dolor sit amet consectetur adipisicing.</h3> -->

        <form #formulario="ngForm">
            <div class="field-box">
                <label for="">{{translate.sections.campaigns.showCampaings.name}}</label>
                <input #nameCampaignC value="" type="text" placeholder={{translate.sections.campaigns.showCampaings.exampleName}}>
            </div>

            <div class="field-box">
                <label for="">
                    {{translate.sections.campaigns.showCampaings.expiresIn}}
                    <i class="material-icons md-20 help"
                        data-tippy-content={{translate.sections.campaigns.showCampaings.timeMessage}}>
                        help
                    </i>
                </label>
                <div class="range-wrap">
                    <div #rangevalue1 id="rangevalue1" class="range-value"><span></span></div>
                    <input #windowTimeC type="range" min="1" max="24" value="1" step="1">
                </div>
                <div class="description">{{translate.sections.campaigns.showCampaings.scrollMessage}}</div>
            </div>

            <div class="field-box">
                <label for="">
                    {{translate.sections.campaigns.showCampaings.automaticResponse}}
                    <i class="material-icons md-20 help"
                        data-tippy-content={{translate.sections.campaigns.showCampaings.responseMessage}}>
                        help
                    </i>
                </label>
                <textarea #messageC
                    placeholder={{translate.sections.campaigns.showCampaings.exampleMessage}}></textarea>
            </div>

            <div class="field-box">
                <label for="">
                    {{translate.sections.campaigns.showCampaings.keyWord}}
                    <i class="material-icons md-20 help"
                        data-tippy-content={{translate.sections.campaigns.showCampaings.keyWordMessage}}>
                        help
                    </i>
                </label>
                <input #keywordRegexC type="text" placeholder={{translate.sections.campaigns.showCampaings.examplePromotion}}>

            </div>

            <!-- <div class="field-box">
                <label for="">Tipo de envío</label>
                <select #selectTypeMessageC>
                    <option value="">Seleccionar</option>
                    <option selected value="NOTIFICATION">Notificación</option>
                    <option value="MESSAGE">Mensaje</option>

                </select>
            </div> -->

            <div class="field-box">
                <label for="">{{translate.sections.campaigns.showCampaings.channelMessage}}</label>
                <select (change)="getHsmId(selectChannelC.value)" #selectChannelC>
                    <option value="">{{translate.sections.campaigns.showCampaings.select}}</option>
                    <option *ngFor="let channel of channels" value="{{channel.id}}">
                        {{channel.label}}</option>
                </select>
            </div>

            <div class="field-box">
                <label for="">{{translate.sections.campaigns.showCampaings.selectMessage}}</label>
                <select (change)="getParams(selectHsmIdC.value)" #selectHsmIdC>
                    <option value="">{{translate.sections.campaigns.showCampaings.select}}</option>
                    <option *ngFor="let hsm of arrayHsm" value="{{hsm.id}}">{{hsm.name}} ( {{hsm.elementName}} )
                    </option>
                </select>
            </div>

            <div class="field-box" [ngStyle]="{'display': mensajeContent?'block':'none'}">
                <div class="show-message-box">
                    <span class="description">
                        {{translate.sections.campaigns.showCampaings.messagePreview}}
                    </span>
                    <p class="showMessage"></p>
                </div>
                <span class="description">{{translate.sections.campaigns.showCampaings.previewText}}</span>
            </div>
            <!-- <div class="field-box" [ngStyle]="{'display': mensajeContent?'block':'none'}">
                <div class="show-message-data">
                    <p class="showMessageType"></p>
                    <p class="showMediaType"></p>
                    <p class="showParams">Parámetros requeridos por el mensaje: </p>
                    <ul>
                        <li *ngFor="let params of arrayParamsMessage">{{params}}</li>
                    </ul>
                </div>
            </div> -->

            <div class="field-box upload-box excel" appDnd (fileDropped)="onFileDropped($event)">
                <div class="files-upload">
                    <figure>
                        <img src="../../assets/img/excel.svg" alt="Excel">
                    </figure>
                    <input hidden type="file" accept=".xlsx" #uploader
                        (change)="fileBrowseHandler($event)" />
                    <strong>{{translate.sections.campaigns.showCampaings.userList}}</strong>
                    <span>{{translate.sections.campaigns.showCampaings.click}} <button #buttonUploadC id="buttonUploadFile" (click)="uploader.click()">{{translate.sections.campaigns.showCampaings.clickHereToUpload}}
                    </button> {{translate.sections.campaigns.showCampaings.xlsMessage}}</span>
                </div>
                <div class="files-list" *ngIf="files.length > 0">
                    <div class="single-file" *ngFor="let file of files; let i = index">
                        <div class="file-icon">
                            <i class="material-icons md-20">insert_drive_file</i>
                        </div>
                        <div class="file-help">
                            <span class="file-name">{{ file?.name }}</span>
                            <span class="file-size">{{ formatBytes(file?.size, 0) }}</span>
                        </div>

                        <div class="file-delete" (click)="deleteFile(i)">
                            <i class="material-icons md-20">delete_forever</i>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <img class="iconUpload" src="../../assets/img/interface.svg" alt=""> -->
            <!-- disabled insertar -->

            <h4>{{translate.sections.campaigns.showCampaings.note}}:</h4>
            <span>{{translate.sections.campaigns.showCampaings.noteText}} <a href="mailto:soporte@gus.chat">{{translate.sections.campaigns.showCampaings.us}}</a>.</span>

            <div class="modal-actions">
                <button (click)="closeModal('popup-new-campaign');" class="btn-secondary">
                    {{translate.sections.campaigns.showCampaings.cancel}}
                </button>
                <button #buttonsendC type="submit"
                    (click)="saveCampaign(nameCampaignC,windowTimeC,messageC,keywordRegexC,selectChannelC,selectHsmIdC)"
                    class="btn-primary">
                    {{translate.sections.campaigns.showCampaings.save}}
                </button>
            </div>
        </form>
    </div>

</jw-modal>
<!-- end modal create campaign -->


<!-- modal edit campaign -->
<jw-modal id="popup-edit-campaign">
    <a class="close_modal" (click)="closeModal('popup-edit-campaign');">×</a>
    <div class="createCampaign">
        <h2 class="title">{{translate.sections.campaigns.showCampaings.editCampaign}}</h2>
        <!-- <h3 class="description">Lorem ipsum dolor sit amet consectetur adipisicing.</h3> -->

        <form #campaignForm="ngForm">
            <div class="field-box">
                <label for="">{{translate.sections.campaigns.showCampaings.name}}</label>
                <input value="{{getCampaign.name}}" #nameCampaign type="text">
            </div>
            <div class="field-box">
                <label for="">
                    {{translate.sections.campaigns.showCampaings.expiresIn}}
                    <i class="material-icons md-20 help"
                        data-tippy-content={{translate.sections.campaigns.showCampaings.timeMessage}}>
                        help
                    </i>
                </label>
                <div class="range-wrap">
                    <div #rangevalue2 id="rangevalue2" class="range-value"><span></span></div>
                    <input #windowTime type="range" min="1" max="24" value="{{getCampaign.windowTime}}" step="1">
                </div>
                <div class="description">{{translate.sections.campaigns.showCampaings.scrollMessage}}</div>
            </div>
            <div class="field-box">
                <label for="">
                    {{translate.sections.campaigns.showCampaings.automaticResponse}}
                    <i class="material-icons md-20 help"
                        data-tippy-content={{translate.sections.campaigns.showCampaings.responseMessage}}>
                        help
                    </i>
                </label>
                <textarea value="{{getCampaign.defaultMessage}}" #message></textarea>
            </div>

            <div class="field-box">
                <label for="">
                    {{translate.sections.campaigns.showCampaings.keyWord}}
                    <i class="material-icons md-20 help"
                        data-tippy-content={{translate.sections.campaigns.showCampaings.keyWordMessage}}>
                        help
                    </i>
                </label>
                <input value="{{getCampaign.keywordRegex}}" #keywordRegex type="text">

            </div>


            <!-- <div class="field-box">
                <label for="">Tipo de envío</label>
                <select id="selectDeliveryE" #selectTypeMessageE>
                    <option value="">Seleccionar</option>
                    <option value="NOTIFICATION">Notificación</option>
                    <option value="MESSAGE">Mensaje</option>

                </select>
            </div> -->

            <div class="field-box">
                <label for="">{{translate.sections.campaigns.showCampaings.channelMessage}}</label>

                <select (change)="getHsmId(selectChannel.value)" id="selectchannels" #selectChannel>
                    <option value="">{{translate.sections.campaigns.showCampaings.select}}</option>
                    <option *ngFor="let channel of channels" value="{{channel.id}}">
                        {{channel.label}}</option>
                    <!-- <option value="9fb670ed-3537-4845-ab1c-fc6b8eefe0e8">sura</option> -->
                </select>
            </div>
            <div class="field-box">
                <label for="">{{translate.sections.campaigns.showCampaings.selectMessage}}</label>
                <select (change)="getParams(selectHsmId.value)" id="selectHsmId" #selectHsmId>
                    <option value="">{{translate.sections.campaigns.showCampaings.select}}</option>
                    <option *ngFor="let hsm of arrayHsm" value="{{hsm.id}}" [selected]="hsm.id == hsmID">{{hsm.name}} (
                        {{hsm.elementName}} )
                    </option>

                </select>
            </div>

            <div class="field-box" [ngStyle]="{'display': mensajeContent?'block':'none'}">
                <div class="show-message-box">
                    <span class="description">
                        {{translate.sections.campaigns.showCampaings.messagePreview}}
                    </span>
                    <p class="showMessage"></p>
                </div>
                <span class="description">{{translate.sections.campaigns.showCampaings.previewText}}</span>
            </div>

            <div class="field-box upload-box green" appDnd (fileDropped)="onFileDropped($event)">
                <p hidden #urlFileLoaded class="urlS3">{{getCampaign.fileUrl}}</p>
                <p hidden #loadStatus>{{getCampaign.status}}</p>
                <div class="files-upload">
                    <figure>
                        <img src="../../assets/img/excel.svg" alt="Excel">
                    </figure>
                    <input hidden type="file" accept=".xlsx" #uploader2
                        (change)="fileBrowseHandler($event)" />
                    <strong>{{translate.sections.campaigns.showCampaings.userList}}</strong>
                    <span>{{translate.sections.campaigns.showCampaings.click}} <button #buttonUpload (click)="uploader2.click()">{{translate.sections.campaigns.showCampaings.clickHereToUpload}}</button> {{translate.sections.campaigns.showCampaings.xlsMessage}}</span>
                </div>
                <div class="files-list" *ngIf="files.length > 0">
                    <div class="single-file" *ngFor="let file of files; let i = index">
                        <div class="file-icon">
                            <i class="material-icons md-20">insert_drive_file</i>
                        </div>
                        <div class="file-help">
                            <span class="file-name">{{ file?.name }}</span>
                            <span class="file-size">{{ formatBytes(file?.size, 0) }}</span>
                        </div>

                        <div class="file-delete" (click)="deleteFile(i)">
                            <i class="material-icons md-20">delete_forever</i>
                        </div>
                    </div>
                </div>
            </div>

            <h4>{{translate.sections.campaigns.showCampaings.note}}</h4>
            <p>{{translate.sections.campaigns.showCampaings.noteText}} <a href="mailto:soporte@gus.chat">{{translate.sections.campaigns.showCampaings.us}}</a>.</p>

            <div class="modal-actions">
                <button (click)="closeModal('popup-edit-campaign');" class="btn-secondary">
                    {{translate.sections.campaigns.showCampaings.cancel}}
                </button>
                <button
                    (click)="editCampaign(nameCampaign,windowTime,message,keywordRegex,selectChannel,selectHsmId, urlFileLoaded,loadStatus)"
                    type="submit" [disabled]="campaignForm.form.invalid" class="btn-primary">
                    {{translate.sections.campaigns.showCampaings.accept}}
                </button>
            </div>
        </form>

    </div>

</jw-modal>
<!-- end modal edit campaign -->

<!-- begin modal date scheduled -->
<jw-modal id="popup-schedule">
    <a class="close_modal" (click)="closeModal('popup-schedule');">×</a>
    <div class="createSchedule">
        <h2 class="title titleProgramar">{{translate.sections.campaigns.showCampaings.scheduleDate}}</h2>
        <input hidden #idCampaignControl type="text" value="{{idCampaign}}">

        <div class="divScheduleSelected">
            <div class="field-box container-calendar">
                <input disabled #loadDate class="showDate" type="text">

                <a (click)="openCalendar()" class="c-btn c-datepicker-btn">
                    <span class="material-icon"><img class="icon-calendar"
                            src="../../assets/img/icons-campaigns/SCHEDULED.svg" alt=""></span>
                </a>

            </div>

            <div class="field-box">
                <label for="">{{translate.sections.campaigns.showCampaings.selectTimeZone}}</label>

                <select id="selectTime" #selectTimeZone>
                    <option [value]="tz" *ngFor="let tz of alltz">{{ tz }}</option>
                </select>
            </div>
        </div>

        <div class="modal-actions">
            <button (click)="functionCancel('popup-schedule')" class="btn-secondary">{{translate.sections.campaigns.showCampaings.cancel}}</button>
            <button (click)="functionSendDate(selectTimeZone)" class="btn-primary">{{translate.sections.campaigns.showCampaings.send}}</button>
        </div>
    </div>
</jw-modal>
<!-- end modal date scheduled -->

<!-- begin modal confirm send now -->
<jw-modal id="popup-confirm">
    <a class="close_modal" (click)="closeModal('popup-confirm');">×</a>
    <h3>{{translate.sections.campaigns.showCampaings.sendMessage}}</h3>

    <div class="modal-actions">
        <button (click)="functionCancel('popup-confirm')" class="btn-secondary">{{translate.sections.campaigns.showCampaings.cancel}}</button>
        <button (click)="functionSendNow()" class="btn-primary">{{translate.sections.campaigns.showCampaings.confirm}}</button>
    </div>

</jw-modal>
<!-- end modal confirm send now -->

<!-- begin modal confirm delete -->
<jw-modal id="popup-confirmDelete">
    <a class="close_modal" (click)="closeModal('popup-confirmDelete');">×</a>
    <h3>{{translate.sections.campaigns.showCampaings.confirmationMessage}}</h3>

    <div class="modal-actions">
        <button (click)="functionCancel('popup-confirmDelete')" class="btn-secondary">{{translate.sections.campaigns.showCampaings.cancel}}</button>
        <button (click)="deleteCampaign()" class="btn-primary">{{translate.sections.campaigns.showCampaings.confirm}}</button>
    </div>

</jw-modal>
<!-- end modal confirm delete -->


<!-- begin modal confirm cancel campaign -->
<jw-modal id="popup-confirmCancelCampaign">
    <a class="close_modal" (click)="closeModal('popup-confirmCancelCampaign');">×</a>
    <h3>{{translate.sections.campaigns.showCampaings.cancelMessage}}</h3>

    <div class="modal-actions">
        <button (click)="functionCancel('popup-confirmCancelCampaign')" class="btn-secondary">{{translate.sections.campaigns.showCampaings.cancel}}</button>
        <button (click)="cancelCampaign()" class="btn-primary">{{translate.sections.campaigns.showCampaings.confirm}}</button>
    </div>

</jw-modal>
<!-- end modal confirm cancel campaign-->

<!-- begin modal insights campaign-->
<jw-modal id="popup-insights-campaign">
    <a class="close_modal" (click)="closeModal('popup-insights-campaign');">×</a>
    <h3>Métricas</h3>

    <div>
        <div>
            <strong>{{insightsCampaign?.name}}</strong>
            <p>{{convertDateTime(insightsCampaign?.schedule)}}</p>
        </div>
        <div class="insights clearfix">
            <div class="insight-box">
                <strong>{{insights?.processed}}</strong>
                <span>Procesados</span>
                <button (click)="downloadReportByStatus(insightsCampaign, 'processed')" class="btn-icon download-icon" title="Descargar mensajes procesados">
                    <i class="material-icons">file_download</i>
                </button>
            </div>
            <div class="insight-box">
                <strong>{{insights?.sent}}</strong>
                <span>Enviados</span>
                <button (click)="downloadReportByStatus(insightsCampaign, 'sent')" class="btn-icon download-icon" title="Descargar mensajes enviados">
                    <i class="material-icons">file_download</i>
                </button>
            </div>
            <div class="insight-box">
                <strong>{{insights?.rejected}}</strong>
                <span>Rechazados</span>
                <button (click)="downloadReportByStatus(insightsCampaign, 'rejected')" class="btn-icon download-icon" title="Descargar mensajes rechazados">
                    <i class="material-icons">file_download</i>
                </button>
            </div>
            <div class="insight-box">
                <strong>{{insights?.received}}</strong>
                <span>Recibidos</span>
                <button (click)="downloadReportByStatus(insightsCampaign, 'received')" class="btn-icon download-icon" title="Descargar mensajes recibidos">
                    <i class="material-icons">file_download</i>
                </button>
            </div>
            <div class="insight-box">
                <strong>{{insights?.read}}</strong>
                <span>Leídos</span>
                <button (click)="downloadReportByStatus(insightsCampaign, 'read')" class="btn-icon download-icon" title="Descargar mensajes leídos">
                    <i class="material-icons">file_download</i>
                </button>
            </div>
        </div>
        <div class="note">
            <p><strong>Nota:</strong> La descarga está limitada a 10,000 registros</p>
        </div>
    </div>

    <div class="modal-actions">
        <button (click)="closeModal('popup-insights-campaign')" class="btn-primary">Cerrar</button>
    </div>

</jw-modal>
<!-- end modal insights campaign-->

<section class="wrapperCampaigns" [ngClass]="{'wrapperCampaignsP' : !itemI}">

    <div class="wrapper-title sub">
        <!-- <h1 *ngIf="!itemI" class="title">
            Campañas: Plantillas de mensajes
        </h1>

        <h2 *ngIf="itemI" class="title">
            <i class="material-icons">campaign</i>
            Campañas: Plantillas de mensajes
        </h2> -->
        <h3 class="title">{{translate.sections.campaigns.showCampaings.manageCampaigns}}</h3>




        <ul class="actions" [ngClass]="{'ulChange' : !itemI}">
            <li>
                <button class="btn-secondary-alternate"
                    (click)="redirectTemplateValidation()">
                    <span>{{translate.sections.campaigns.showCampaings.newTemplate}}</span>
                    <i class="material-icons md-20">add_circle</i>
                </button>
                <!-- <button *ngIf="!itemI" class="btn-primary"
                    (click)="openModal('popup-new-campaign', '', 'crear', buttonsendC,buttonUploadC)">
                    <span>Nueva campaña</span>
                    <i class="material-icons md-20">add_circle</i>
                </button>

                <button *ngIf="itemI" class="btn-secondary-alternate"
                    (click)="openModal('popup-new-campaign', '', 'crear', buttonsendC,buttonUploadC)">
                    <span>Nueva campaña</span>
                    <i class="material-icons md-20">add_circle</i>
                </button> -->
            </li>
        </ul>
    </div>

    <div *ngIf="!existsCampaigns" class="emptyCampaigns">
        <img src="/assets/img/empty_state_campaigns.png" alt="Campaigns">
        <h1>{{translate.sections.campaigns.showCampaings.noCampaigns}}</h1>
        <h4>{{translate.sections.campaigns.showCampaings.startMessage}}</h4>
    </div>

    <div class="divCampaigns" *ngIf="existsCampaigns">
        <!-- load campaigns -->
        <div class="loadCampaigns">
            <div class="tabs">
                <ul>
                    <li>
                        <a (click)="showTab('ALL')" [ngClass]="{'active': tabActive == 'ALL'}">
                            {{translate.sections.campaigns.showCampaings.all}} <span *ngIf="tabActive == 'ALL'" class="number">{{total}}</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="showTab('SCHEDULED')" [ngClass]="{'active': tabActive == 'SCHEDULED'}">
                            {{translate.sections.campaigns.showCampaings.scheduled}} <span *ngIf="tabActive == 'SCHEDULED'" class="number">{{total}}</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="showTab('PROCESSED')" [ngClass]="{'active': tabActive == 'PROCESSED'}">
                            {{translate.sections.campaigns.showCampaings.sent}} <span *ngIf="tabActive == 'PROCESSED'" class="number">{{total}}</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="showTab('CANCELLED')" [ngClass]="{'active': tabActive == 'CANCELLED'}">
                            {{translate.sections.campaigns.showCampaings.cancelled}} <span *ngIf="tabActive == 'CANCELLED'" class="number">{{total}}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <section class="md-ui component-data-table">
                <div class="main-table-wrapper">
                    <table class="main-table-content">
                        <thead class="data-table-header">
                            <tr class="data-table-row">
                                <td>{{translate.sections.campaigns.showCampaings.status}}</td>
                                <td>{{translate.sections.campaigns.showCampaings.name}}</td>
                                <td>{{translate.sections.campaigns.showCampaings.releaseDate}}</td>
                                <td>{{translate.sections.campaigns.showCampaings.timeZone}}</td>
                                <td>
                                    {{translate.sections.campaigns.showCampaings.automaticResponse}}
                                    <i class="material-icons md-20 help white"
                                        data-tippy-content="Respuesta que se mostará hasta que el mensaje de difusión expire o el usuario ingresé la palabra clave">
                                        help
                                    </i>
                                </td>
                                <td>
                                    {{translate.sections.campaigns.showCampaings.expiresIn}}
                                    <i class="material-icons md-20 help white"
                                        data-tippy-content="Tiempo que vivirá el mensaje de la difusión para escribir la respuesta automática. Cuando el tiempo expira el bot principal entrará en acción">
                                        help
                                    </i>
                                </td>
                                <td>
                                    {{translate.sections.campaigns.showCampaings.keyword2}}
                                    <i class="material-icons md-20 help white"
                                        data-tippy-content={{translate.sections.campaigns.showCampaings.keyWordMessage}}>
                                        help
                                    </i>
                                </td>
                                <td>{{translate.sections.campaigns.showCampaings.actions}}</td>

                            </tr>
                        </thead>
                        <tbody class="data-table-content">
                            <!-- , pageBoundsCorrection:pageChanged()  -->
                            <tr *ngFor="let campaign of campaigns | paginate: { itemsPerPage: perPage, currentPage: page, totalItems:total}"
                                [ngClass]="{'data-table-row': true, 'new': campaign.new}">
                                <!-- <li *ngFor="let item of collection | paginate: { itemsPerPage: 10, currentPage: p, totalItems:count }">{{item.title}}</li> -->
                                <td>
                                    <!--
                                    <img title="{{campaign.status_name}}" alt="{{campaign.status_name}}"
                                        src="../../assets/img/icons-campaigns/{{campaign.status}}.svg">
                                    -->
                                    {{campaign.status_name}}
                                </td>
                                <td>
                                    <!-- <a (click)="openModal('popup-edit-campaign',campaign.id)" class="elipsis">{{campaign.name}}</a> -->
                                    {{campaign.name}}
                                </td>
                                <td>
                                    <span>
                                        {{convertDateTime(campaign.schedule)}}</span>
                                    <span *ngIf="campaign.schedule"> hrs</span>
                                </td>
                                <td>{{campaign.tz}}</td>
                                <td><span class="elipsis">{{campaign.defaultMessage}}</span></td>
                                <td><span class="window-time">{{timeConvertToHours(campaign.windowTime)}}</span></td>
                                <td>{{campaign.keywordRegex}}</td>

                                <td>
                                    <button [disabled]="!(campaign.status == 'CREATED' || campaign.status == 'SCHEDULED')"
                                        class="btn-icon" (click)="gotoEditCampaign(campaign?.id, campaign?.tenant?.id)"
                                        title="{{translate.sections.campaigns.showCampaings.editar}}">
                                        <i class="material-icons md-20">edit</i>
                                    </button>
                                    <button *ngIf="campaign.status == 'CREATED'" [disabled]="!(campaign.status == 'CREATED')" class="btn-icon"
                                        (click)="openModal('popup-schedule',campaign.id,'programar')" title="{{translate.sections.campaigns.showCampaings.schedule}}">
                                        <i class="material-icons md-20">event</i>
                                    </button>
                                    <button (click)="openModal('popup-schedule',campaign.id,'reprogramar')"
                                        class="btn-icon" *ngIf="campaign.status != 'CREATED'" [disabled]="!(campaign.status == 'SCHEDULED')" title="Reprogramar">
                                        <i class="material-icons md-20">event</i>
                                    </button>
                                    <button *ngIf="campaign.status == 'CREATED' || campaign.status === 'PAUSED'" class="btn-icon"
                                        (click)="openModal('popup-confirm',campaign.id)" title="{{translate.sections.campaigns.showCampaings.sendNow}}">
                                        <i class="material-icons md-20">play_circle</i>
                                    </button>
                                    <button [disabled]="!(campaign.status == 'SCHEDULED' || campaign.status === 'PROCESSING')"
                                        *ngIf="!(campaign.status == 'CREATED' || campaign.status === 'PAUSED')" class="btn-icon"
                                        (click)="pauseCampaign(campaign.id)" title="">
                                        <i class="material-icons md-20">pause_circle</i>
                                    </button>
                                    <button (click)="openModal('popup-confirmCancelCampaign', campaign.id,'cancelar')"
                                        [disabled]="!(campaign.status == 'SCHEDULED' || campaign.status === 'PROCESSING')
                                        "title="{{translate.sections.campaigns.showCampaings.cancel}}" class="btn-icon">
                                        <i class="material-icons md-20">block</i>
                                    </button>
                                    <button (click)="openSimpleModal('popup-insights-campaign', campaign)"
                                        [disabled]="!(campaign.status == 'PROCESSED')" title="Métricas" class="btn-icon">
                                        <i class="material-icons md-20">insights</i>
                                    </button>
                                    <button (click)="openModal('popup-confirmDelete', campaign.id)" title="{{translate.sections.campaigns.showCampaings.delete}}" class="btn-icon">
                                        <i class="material-icons md-20">delete</i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <footer class="main-table-footer">
                    <pagination-template #p="paginationApi" (pageChange)="pageUpdate($event)">
                        <ul class="pagination">
                            <li [ngClass]="{'pagination-previous': true, 'disabled': p.isFirstPage()}">
                                <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
                                    << {{translate.sections.campaigns.showCampaings.previous}} </a>
                                        <span *ngIf="p.isFirstPage()">
                                            <span>
                                                << {{translate.sections.campaigns.showCampaings.previous}} </span>
                                            </span>
                            </li>

                            <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                    <span>{{ page.label }}</span>
                                </a>
                                <span *ngIf="p.getCurrent() === page.value">
                                    <span>{{ page.label }}</span>
                                </span>
                            </li>

                            <li [ngClass]="{'pagination-next': true, 'disabled': p.isLastPage()}">
                                <a *ngIf="!p.isLastPage()" (click)="p.next()">
                                    {{translate.sections.campaigns.showCampaings.next}} >>
                                </a>
                                <span *ngIf="p.isLastPage()">
                                    <span>{{translate.sections.campaigns.showCampaings.next}} >></span>
                                </span>
                            </li>
                        </ul>
                    </pagination-template>

                </footer>
            </section>

        </div>
    </div>

</section>
