import { Component, OnInit, ViewChild } from "@angular/core";
import { MenuService } from "../../services/menu.service";
import { ChannelsService } from "../../services/channels.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertService } from "../../services/alert.service";

import * as JSONEditor from "../../assets/libraries/jsoneditor.min.js";
declare var JSONEditor: any;
declare var translate: any;
@Component({
    selector: "app-config",
    templateUrl: "./config.component.html",
    styleUrls: ["./config.component.scss"],
})
export class ConfigComponent implements OnInit {
    default_start_button: any;
    default_persistent_menu: any;
    channelOwnerId: any;
    domains: any;
    getMessage: any;
    selectChannel: any;
    listDomains: any;
    default_domains: any;

    welcomeMessage: any;

    editor: any;
    editor2: any;
    urlParameter: any;
    translate: any;
    constructor(
        private menuService: MenuService,
        private router: Router,
        private activate: ActivatedRoute,
        private channelService: ChannelsService,
        private alertService: AlertService
    ) {
        this.editor = {};
        this.editor2 = {};
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    ngOnInit() {
        this.urlParameter = window.location.href;
        this.default_start_button = {
            get_started: {
                payload: {
                    entities: {},
                    block_id: 0,
                },
            },
        };

        this.default_persistent_menu = {
            persistent_menu: [
                {
                    locale: "default",
                    composer_input_disabled: false,
                    call_to_actions: [
                        {
                            title: "Item1",
                            type: "nested",
                            call_to_actions: [
                                {
                                    title: "Subitem1",
                                    type: "postback",
                                    payload: {
                                        entities: {
                                            "@entidad1": [" _entidad1_"],
                                        },
                                        block_id: 0,
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    locale: "es_ES",
                    composer_input_disabled: false,
                },
            ],
        };

        const container = document.getElementById("jsoneditor");
        const options = {};
        this.editor = new JSONEditor(container, options);

        const container2 = document.getElementById("jsoneditor2");
        const options2 = {};
        this.editor2 = new JSONEditor(container2, options2);

        // this.editor2.set(this.default_start_button);

        const params = this.activate.snapshot.params;

        if (params.id) {
            // console.log(params.id);
            this.channelOwnerId = params.id;
            this.channelService.get(params.customer_slug).subscribe(
                (res: any) => {
                    // console.log(res);

                    this.selectChannel = res.filter(
                        (res) => res.channelOwnerId === this.channelOwnerId
                    );
                    console.log("value current channel", this.selectChannel);
                    // console.log(this.selectChannel[0].accessSecret);
                    // GET ACCESS SECRET TOKEN
                    this.getMessageWelcome(this.selectChannel[0].accessSecret);
                    this.getDomainsAllow(this.selectChannel[0].accessSecret);
                    this.getButtonStarted(this.selectChannel[0].accessSecret);
                    this.getMainPersistent(this.selectChannel[0].accessSecret);
                },
                (err) => {
                    console.log(err);
                }
            );
        }
    }

    getButtonStarted(token) {
        let b: any;
        this.menuService.getButtonStart(token).subscribe(
            (msg) => {
                // console.log(msg);
                b = msg;

                if (b.data.length > 0) {
                    b.data[0].get_started.payload = JSON.parse(
                        b.data[0].get_started.payload
                    );
                    // this.editor2.set(b.data[0]);
                    this.editor2.set(b.data[0]);
                } else {
                    this.editor2.set(this.default_start_button);
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    saveMessage() {
        // console.log(this.welcomeMessage);

        const obj = {
            greeting: [
                {
                    locale: "default",
                    text: this.welcomeMessage,
                },
            ],
        };

        this.setMessageWelcome(this.selectChannel[0].accessSecret, obj);
    }

    deleteProperty(field) {
        const obj = {
            fields: [field],
        };

        this.menuService
            .deleteProperties(this.selectChannel[0].accessSecret, obj)
            .subscribe(
                (res) => {
                    console.log(res);
                    this.alertService.success(
                        translate.sections.config.propertyDeleteMessage
                    );

                    if (field === "greeting") {
                        this.welcomeMessage = "";
                    } else if (field === "get_started") {
                        this.editor2.set(this.default_start_button);
                    } else if (field === "persistent_menu") {
                        this.editor.set(this.default_persistent_menu);
                    }
                },
                (err) => {
                    console.log(err);
                    this.alertService.error(translate.sections.config.propertyDeleteError);
                }
            );
    }

    setMessageWelcome(token, message) {
        this.menuService.setMessage(token, message).subscribe(
            (res) => {
                // console.log(res);
                this.alertService.success(translate.sections.config.assignedMessage);
            },
            (err) => {
                this.alertService.error(translate.sections.config.assignedErrorMessage);
                console.log(err);
            }
        );
    }

    getDomainsAllow(token) {
        this.menuService.getDomains(token).subscribe(
            (res: any) => {
                // console.log(res);
                this.domains = res.data[0].whitelisted_domains.join("\n");
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getMessageWelcome(token) {
        this.menuService.getMessage(token).subscribe(
            (res: any) => {
                // console.log(res);

                this.welcomeMessage = res.data[0].greeting[0].text;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    saveDomains() {
        const obj = {
            whitelisted_domains: this.domains.split("\n"),
        };

        // console.log(obj);

        this.menuService
            .setDomains(obj, this.selectChannel[0].accessSecret)
            .subscribe(
                (res) => {
                    // console.log(res);
                    this.alertService.success(translate.sections.config.assignedListMessage);
                },
                (err) => {
                    this.alertService.error(translate.sections.config.assignedListErrorMessage);
                }
            );
    }

    saveDefault() {
        let obj = {
            whitelisted_domains: this.default_domains,
        };

        this.menuService
            .setDomains(obj, this.selectChannel[0].accessSecret)
            .subscribe(
                (a) => {
                    // this.domains = this.default_domains.join("\n");
                    // this.alertService.success("Se han guardado los dominios default", "¡Guardado!");
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    saveStartButton() {
        // console.log(content);
        const content: any = this.editor2.get();
        content.get_started.payload = JSON.stringify(
            content.get_started.payload
        );

        // console.log(content);
        this.menuService
            .setButtonStart(content, this.selectChannel[0].accessSecret)
            .subscribe(
                (res) => {
                    // console.log(res);
                    this.alertService.success(
                        translate.sections.config.saveMessage
                    );
                },
                (err) => {
                    console.log(err);
                    this.alertService.error(
                        translate.sections.config.saveErrorMessage
                    );
                }
            );
    }

    savePersistentMenu() {
        let content: any = this.editor.get();
        content = this.changePayloadToString(content);
        this.menuService
            .setPersistentMenu(content, this.selectChannel[0].accessSecret)
            .subscribe(
                (a) => {
                    // console.log(a);
                    this.alertService.success(
                        translate.sections.config.saveMenuMessage
                    );
                },
                (err) => {
                    this.alertService.error(
                        translate.sections.config.saveMenuErrorMessage
                    );
                    console.log(err);
                }
            );
    }

    getMainPersistent(token) {
        let b: any;
        this.menuService.getPersistentMenu(token).subscribe(
            (msg) => {
                b = msg;
                if (b.data.length > 0) {
                    b.data = this.changeStringToPayload(b.data);
                    this.editor.set(b.data[0]);
                } else {
                    this.editor.set(this.default_persistent_menu);
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    changePayloadToString(obj: any) {
        for (let i in obj) {
            if (i === "payload") {
                obj[i] = JSON.stringify(obj[i]);
            } else {
                if (typeof obj[i] === "object") {
                    obj[i] = this.changePayloadToString(obj[i]);
                }
            }
        }
        return obj;
    }
    changeStringToPayload(obj: any) {
        for (const i in obj) {
            if (i === "payload") {
                obj[i] = JSON.parse(obj[i]);
            } else {
                if (typeof obj[i] === "object") {
                    obj[i] = this.changeStringToPayload(obj[i]);
                }
            }
        }
        return obj;
    }
}
