import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from './../models/user';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthServices {

    constructor(
        private http: HttpClient,
        private router: Router,
    ) { }

    registerGoogle(user: User): Observable<User> {
        return this.http.post<User>('/coreapi/v2/login', user);
    }

    registerLoginEnviroment(user:User, env: string): Observable<User> {
        let urlEnv = 'https://flux-v2.gus.chat/';
        if (env !== 'PROD') {
            urlEnv = `https://flux-v2-${env.toLowerCase()}.gus.chat/`;
        }
        const headers = new HttpHeaders({
            'Gus-Id': 'DXLnXSVDY65W9Y9ow'
        });
        return this.http.post<User>(`${urlEnv}coreapi/v2/login`, user, { headers });
    }



    registerSSO(payload: any): Observable<any> {
        const url = '/coreapi/v2/login'
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(url, payload, { headers });
    }

    keepAlive(objeto) {
        const objAux = JSON.parse(objeto);
        if (localStorage.getItem('id_token')) {
            objAux['id_token'] = localStorage.getItem('id_token');
        }
        objeto = JSON.stringify(objAux);
        const url = '/coreapi/v2/isTokenAlive';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Show-Loader': 'false'
        });
        return this.http.post(url, objeto, {headers});
    }

    verifyToken(objeto) {
        const objAux = JSON.parse(objeto);
        if (localStorage.getItem('id_token')) {
            objAux['id_token'] = localStorage.getItem('id_token');
        }
        objeto = JSON.stringify(objAux);
        const url = '/coreapi/v2/verifyToken';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return this.http.post(url, objeto, {headers});
    }

    logout() {
        console.log('remove localstorage items logout auth');
        localStorage.removeItem('constructorUserId');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('id');
        localStorage.removeItem('photo');
        localStorage.removeItem('token');
        localStorage.removeItem('dateScheduled');
        localStorage.removeItem('Auth-Type');
        localStorage.removeItem('id_token');
        localStorage.removeItem('payload');
        localStorage.removeItem('currentCustomerSlug');
        localStorage.removeItem('functionalityTemplates');
        localStorage.removeItem('functionalityValues');
        localStorage.removeItem('md5Diagram');
        localStorage.removeItem('flowId');
        localStorage.removeItem('lastConsoleId');
        localStorage.removeItem('newWhatsappChannel');
        localStorage.removeItem('errorCatalog');
        localStorage.removeItem('errorCatalogDate');
        localStorage.removeItem('fbToken');
        console.log('auth guard logout');
        this.router.navigate(['/']);
    }

    getToken() {
        return localStorage.getItem('token');
    }

    getIdGoogle() {
        return localStorage.getItem('id');
    }

    loggedIn() {
        // console.log('token', localStorage.getItem('token'));
        return !!localStorage.getItem('token');
    }

}
