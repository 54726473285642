import { Injectable } from "@angular/core";

declare var $: any;
declare var translate: any;

@Injectable({
    providedIn: "root",
})
export class NumericService {

    translate: any;

    constructor() {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    setBubble(range, bubble) {
        const val = range.val();
        const min = range.attr('min') ? range.attr('min') : 0;
        const max = range.attr('max') ? range.attr('max') : 100;
        const newVal = Number(((val - min) * 100) / (max - min));
        bubble.html(val);
        const widthRange = range.width() === 0 ? 264 : range.width();
        const left = ((widthRange * newVal) / 100) - ((newVal * 6) / 100);
        bubble.css('left', `${left}px`);
    }

    getNumeric(field, req) {
        const containerDiv = $('<div class="numeric" />');
        const bubble = $(`<output class="bubble-range"></output>`);
        let fieldType = 'number';

        if (req.hasOwnProperty('constraints')) {
            const constraints = req.constraints;
            if (Array.isArray(constraints) &&
                constraints.length > 0 &&
                constraints[0].hasOwnProperty('operador')
            ) {
                fieldType = 'range';
                field.attr('steps', '1');

                for (const constraint of constraints) {
                    switch (constraint.operador) {
                        case 'gt':
                            field.attr('min', parseInt(constraint.value, 10) - 1);
                            break;
                        case 'lt':
                            field.attr('max', parseInt(constraint.value, 10) - 1);
                            break;
                        case 'ge':
                            field.attr('min', parseInt(constraint.value, 10));
                            break;
                        case 'le':
                            field.attr('max', parseInt(constraint.value, 10));
                            break;
                    }
                }

                field.on('input', (e) => {
                    this.setBubble(field, bubble);
                });

                let rangeValue = 1;
                const value = field.val();
                if (value) {
                    rangeValue = value;
                } else if (req.recommended) {
                    rangeValue = req.recommended;
                }
                field.val(rangeValue);
                this.setBubble(field, bubble);
            }
        }
        field.attr('type', fieldType);
        containerDiv.append([field, bubble]);
        return containerDiv;
    }
}
