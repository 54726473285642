import { Component, OnInit } from "@angular/core";
import { CampaignService } from "../../../services/campaign.service";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { ModalService } from "../../../components/modal/modal.service";

import { LoaderService } from "../../../services/loader.service";

import { UntypedFormBuilder, FormGroup } from "@angular/forms";
import { AlertService } from "../../../services/alert.service";
import { CustomerService } from "src/services/customer.service";

import { HsmMessageService } from "../../../services/hsm-message.service";
import { DataLayerService } from 'src/services/data-layer.service';

import { HsmMessage } from '../../../models/hsm-message';
import {
    HSMMediaType,
    HSMType,
    HSMButtonSetType,
    HSMCategoryTypeLabel,
    HSMStatusTypeLabel,
    HSMLanguagesTypeLabel,
    HSMStatusType
} from '../../../enums/hsm-message';

import tippy from 'tippy.js';
import { log } from "console";
declare var translate: any;
@Component({
    selector: "app-status-approval",
    templateUrl: "./status-approval.component.html",
    styleUrls: ["./status-approval.component.scss"],
})
export class StatusApprovalComponent implements OnInit {
    campaigns: any = [];
    channelSmooch;
    channelOwnerIdSms;

    searchName = false;

    nameSearchInput;
    monthsYear = [];

    arrayDates = [];

    arrayMonthsConverted = [];

    dataMessage: HsmMessage[];
    idMessage;

    dataMessageG: any = {
        name: '',
        tenantChannelId: '',
        messageContent: '',
        mediaType: HSMMediaType.DOCUMENT,
        mediaContent: '',
        messageType: HSMType.MEDIA,
        button1: '',
        button2: '',
        button3: '',
        footerText: '',
        buttonSetType: HSMButtonSetType.QUICK_REPLY
    }

    months = [
        translate.sections.campaigns.statusApproval.jan,
        translate.sections.campaigns.statusApproval.feb,
        translate.sections.campaigns.statusApproval.mar,
        translate.sections.campaigns.statusApproval.apr,
        translate.sections.campaigns.statusApproval.may,
        translate.sections.campaigns.statusApproval.jun,
        translate.sections.campaigns.statusApproval.jul,
        translate.sections.campaigns.statusApproval.aug,
        translate.sections.campaigns.statusApproval.sep,
        translate.sections.campaigns.statusApproval.oct,
        translate.sections.campaigns.statusApproval.nov,
        translate.sections.campaigns.statusApproval.dec
    ]

    existsMessages = false;


    slug: any;
    customerId: string;

    page = 0;
    total = 0;
    perPage = 10;

    tabActive: string;

    channelID: any;

    existsSmoochChannel = false;
    existsWhatsappChannel = false;
    existsSmoochChannelSms=false;
    whatsappChannelOwnerId = '';
    translate: any;
    flagPath: string;
    currentCustomer: any;
    categories: any[];

    constructor(
        private fb: UntypedFormBuilder,
        private campaignService: CampaignService,
        private activateRoute: ActivatedRoute,
        private router: Router,
        private modalService: ModalService,
        private alertService: AlertService,
        private loaderService: LoaderService,
        private customerService: CustomerService,
        private hsmMessageService: HsmMessageService,
        private dataLayerService: DataLayerService
    ) {
        this.customerId = "";
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = 'Campaña -> Estatus -> ';
    }

    ngOnInit() {

        let meses = this.months;

        let currentdate = new Date();

        let currentMonth = new Date(
            currentdate.setMonth(currentdate.getMonth())
        );
        for (let index = 1; index <= 11; index++) {
            let beforeMonth = new Date(
                currentdate.setMonth(currentdate.getMonth() - 1)
            );
            this.arrayDates.push(beforeMonth.toLocaleDateString());

        }


        this.arrayDates.unshift(currentMonth.toLocaleDateString());
        this.convertDates(meses);




        /* let dateCurrent = new Date();
        this.monthsYear  = this.months.map(function(month) {
            return `${month} - ${dateCurrent.getFullYear()}`;
          });

          console.log(this.monthsYear); */

        this.tabActive = "ALL";

        const parameterCustomerSlug = this.activateRoute.snapshot.paramMap.get(
            "customer_slug"
        );
        if (parameterCustomerSlug) {
            this.slug = parameterCustomerSlug;

            this.customerService.getBySlug(this.slug).subscribe(
                (res: any) => {
                    this.customerId = res.tenant.id;
                    this.getHSMCategories(() => {

                        // for (const chanel of res.tenant.channels) {
                        //     if (chanel.channel === 'WHATSAPP' || (chanel.channel === 'SMOOCH' && chanel.channelSubtype === 'WHATSAPP')) {
                        //         this.channelID = chanel.id;
                        //         this.whatsappChannelOwnerId = chanel.channelOwnerId;
                        //     }
                        //     if (chanel.channel === 'WHATSAPP') {
                        //         this.existsWhatsappChannel = true;
                        //     } else if (chanel.channel === 'SMOOCH' && chanel.channelSubtype === 'WHATSAPP') {
                        //         this.existsSmoochChannel = true;
                        //     }
                        // }

                        const waChannel = res.tenant.channels.find(channel => channel.channel === 'WHATSAPP' && channel.enabled);
                        const smoochSmsChannel = res.tenant.channels.find(channel => channel.channel === 'SMOOCH_SMS' && channel.enabled);
                        if (smoochSmsChannel && 'channel' in smoochSmsChannel) {
                            this.channelSmooch = smoochSmsChannel.channel;
                        }
                        if (smoochSmsChannel && 'channelOwnerId' in smoochSmsChannel) {
                            this.channelOwnerIdSms = smoochSmsChannel.channelOwnerId;
                        }


                        if (waChannel) {
                            this.whatsappChannelOwnerId = waChannel.channelOwnerId;
                            this.channelID = waChannel.id;
                            this.existsSmoochChannel = false;
                            this.existsWhatsappChannel = true;
                            this.existsSmoochChannelSms=false;
                        } else if(smoochSmsChannel){
                            this.whatsappChannelOwnerId = smoochSmsChannel.channelOwnerId;
                            this.existsSmoochChannelSms=true;
                            this.channelID = smoochSmsChannel.id;
                            this.existsSmoochChannel = false;
                            this.existsWhatsappChannel = false;
                        }else {
                            const waChannel2 = res.tenant.channels.find(channel => channel.channel === 'SMOOCH' && channel.channelSubtype === 'WHATSAPP' && channel.enabled);
                            if (waChannel2) {
                                this.whatsappChannelOwnerId = waChannel2.channelOwnerId;
                                this.channelID = waChannel2.id;
                                this.existsSmoochChannel = true;
                                this.existsSmoochChannelSms=false;
                                this.existsWhatsappChannel = false;
                            }
                        }

                        this.activateRoute.queryParams.subscribe((params) => {
                            this.page = parseInt(params.page, 10) || 1;
                            this.tabActive = params.tabActive || "ALL";



                            if (this.searchName === true) {
                                this.searchByName2();
                            }

                            else if (this.tabActive === 'ALL' && this.searchName == false) {
                                this.getMessages(this.page);
                            }
                            else {
                                this.getMessagesByStatus(this.tabActive);
                            }


                            /*  if (this.tabActive === "ALL") {
                                console.log('desde aqui');

                                this.getMessages(this.page);

                            } else {
                                this.getMessagesByStatus(this.tabActive);

                            } */
                            window.scrollTo(0, 0);
                        });
                    });

                },
                (err) => {
                    /* console.log(err); */
                }
            );



        }

        this.dataLayerService.setFlag(this.flagPath, 'start');

    }

    getLanguageType(languageCode) {
        return HSMLanguagesTypeLabel[languageCode];
    }

    searchByName(event) {


        $('#selectDateT').val("");
        $('#selectDate').val("");
        const name = encodeURIComponent(event.target.value);
        this.nameSearchInput = name;
        this.hsmMessageService.getByName(this.nameSearchInput, this.slug, this.channelID, this.page, this.customerId).subscribe(
            (res: any) => {
                /* console.log(res); */
                /* this.dataMessage = res.content; */

                const messages: [] = res.content;
                this.dataMessage = this.setStatusName(messages);
                this.total = res.totalElements;
                this.searchName = true;

                if (this.dataMessage.length > 0) {
                    this.existsMessages = true;
                }

            }, err => {
                /* console.log(err); */
            }
        );
    }

    searchByName2() {


        this.hsmMessageService.getByName(this.nameSearchInput, this.slug, this.channelID, this.page, this.customerId).subscribe(
            (res: any) => {
                /* console.log(res); */
                /* this.dataMessage = res.content; */

                const messages: [] = res.content;
                this.dataMessage = this.setStatusName(messages);
                this.total = res.totalElements;
                this.searchName = true;

                if (this.dataMessage.length > 0) {
                    this.existsMessages = true;
                }

            }, err => {
                /* console.log(err); */
            }
        );
    }

    getMessages(page: number = 0) {
        this.hsmMessageService.get(this.slug, page, this.channelID, this.customerId).subscribe(
            (res: any) => {
                const ids = [];
                for (const message of res.content) {
                    if (message.id) {
                        ids.push(message.id);
                    }
                }
                const messages: [] = res.content;
                if (ids.length > 0) {
                    if (this.existsWhatsappChannel) {
                        this.hsmMessageService.getStatusWhats(this.whatsappChannelOwnerId, this.slug, ids, this.customerId).subscribe(
                            (res2: any) => {


                                /* console.log(res2); */

                                this.dataMessage = this.setStatusName(res2);

                                this.total = res.totalElements;
                                if (this.dataMessage.length > 0) {
                                    this.existsMessages = true;
                                }
                                setTimeout(()=> {
                                    tippy('[data-tippy-content]');
                                }, 5000)
                            }, err => {
                                console.log(err);
                            }
                        );
                    } else if (this.existsSmoochChannel) {
                        this.hsmMessageService.getStatusSmooch(this.channelID, this.slug, ids, this.customerId).subscribe(
                            (res2: any) => {


                                /* console.log(res2); */

                                this.dataMessage = this.setStatusName(res2);

                                this.total = res.totalElements;
                                if (this.dataMessage.length > 0) {
                                    this.existsMessages = true;
                                }
                            }, err => {
                                console.log(err);
                            }
                        );
                    }else if (this.existsSmoochChannelSms) {

                        this.hsmMessageService.getSmoochSmsChannel(this.channelID, this.slug, ids, this.customerId).subscribe(
                            (res2: any) => {


                                /* console.log(res2); */

                                this.dataMessage = this.setStatusName(res2.content);

                                this.total = res.totalElements;
                                if (this.dataMessage.length > 0) {
                                    this.existsMessages = true;
                                }
                            }, err => {
                                console.log(err);
                            }
                        );
                    }
                } else {
                    this.dataMessage = this.setStatusName(messages);


                    this.total = res.totalElements;
                    if (this.dataMessage.length > 0) {
                        this.existsMessages = true;
                    }
                }
            },

            err => {
                /* console.log(err); */

            }
        )
    }

    pageUpdate(page) {
        this.page = page;
        const tabActive = this.tabActive;
        const queryParams: Params = { page, tabActive };
        this.router.navigate(
            [],
            {
                relativeTo: this.activateRoute,
                queryParams
            }
        );

        if (this.searchName) {
            this.searchByName2();


        }

        else if (this.tabActive === 'ALL' && this.dataMessage.length === 0) {
            this.getMessages(this.page);
        }
        else if (this.dataMessage.length === 0) {


            this.getMessagesByStatus(this.tabActive);
        }
    }

    pageChanged() {
        tippy('[data-tippy-content]');
    }

    getCampaigns(page: number = 0) {
        this.campaignService
            .get(this.slug, page, this.customerId)
            .subscribe((response: any) => {
                const camps: [] = response.content;
                this.campaigns = this.setStatusName(camps);
                this.total = response.totalElements;
                if (this.campaigns.length > 0) {
                    this.existsMessages = true;
                }
            },
                err => {
                    /* console.log(err); */

                });

    }

    /* getCampaignsByStatus(status, page: number = 0) {
        this.campaignService
            .getByStatus(status, this.slug, page, this.customerId)
            .subscribe((response: any) => {
                const camps: [] = response.content;
                this.campaigns = this.setStatusName(camps);
                this.total = response.totalElements;
                if (this.campaigns.length > 0) {
                    this.existsMessages = true;
                }
            },
                err => {
                    console.log(err);

                });
    } */

    getMessagesByStatus(status, page: number = 0) {
        this.hsmMessageService.getByStatus(this.channelID, status, this.slug, this.customerId, page).subscribe(
            (res: any) => {

                const messages: [] = res.content;
                this.dataMessage = this.setStatusName(messages);
                this.total = res.totalElements;


                if (this.dataMessage.length > 0) {
                    this.existsMessages = true;
                }

            }, err => {
                console.log(err);

            }
        );
    }

    pad(str, max) {
        str = str.toString();
        return str.length < max ? this.pad("0" + str, max) : str;
    }

    formatDate(date) {
        const day = this.pad(date.getDate(), 2);
        const month = this.pad(date.getMonth() + 1, 2);
        const year = date.getFullYear();

        const hour = this.pad(date.getHours(), 2);
        const min = this.pad(date.getMinutes(), 2);
        const sec = this.pad(date.getSeconds(), 2);

        let stringDateTime = year + "-" + month + "-" + day;
        stringDateTime += "T" + hour + ":" + min + ":" + sec + ".0000";

        return stringDateTime;
    }

    setStatusName(messages) {

        messages = messages.map((message) => {
            // message.category = HSMCategoryTypeLabel[message.category];
            message.authorizationStatus = HSMStatusTypeLabel[message.authorizationStatus];

            const cat = this.categories.find(({ value }) => value === message.category);
            if (cat) {
                message.category = cat.label;
            } else {
                message.category = HSMCategoryTypeLabel[message.category];
            }
            /* message.languageCode = languaje[message.languageCode]; */
            /* console.log(message); */
            return message;
        });
        return messages;
    }

    timeConvertToHours(n) {
        const num = n;
        const hours = num / 60;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);

        if (rhours === 0) {
            return rminutes + " min";
        } else if (rhours !== 0) {
            if (rminutes === 0) {
                if (rhours === 1) {
                    return rhours + " " + translate.sections.campaigns.statusApproval.hour;
                } else {
                    return rhours + " " + translate.sections.campaigns.statusApproval.hours;
                }
            } else {
                return rhours + " " + translate.sections.campaigns.statusApproval.hours + rminutes + " min";
            }
        }

        // return rhours !== 0 ? rhours + " hora y " + rminutes + " minutos." : rminutes + " minutos." ;
    }

    /*   convertDate(datetime) {
          const date = new Date(datetime);
          const year = date.getFullYear();
          let month: any = date.getMonth() + 1;
          let dt: any = date.getDate();

          if (dt < 10) {
              dt = "0" + dt;
          }
          if (month < 10) {
              month = "0" + month;
          }
          const getDateConvert = year + "-" + month + "-" + dt;
          return getDateConvert;
      } */


    convertDate(datetime) {

        let dateConvert = new Date(datetime).toLocaleString().split(' ');
        return dateConvert[0];
    }




    convertDateTime(dateParameter, code) {
        if (dateParameter && code) {
            try {
                const codeLanguaje = code.split("_");
                const event = new Date(dateParameter);
                const options: any = {
                    // weekday: 'long',
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                };
                // console.log(event.toLocaleDateString('es-MX', options));
                return event.toLocaleDateString(
                    `${codeLanguaje[0]}-${codeLanguaje[1]}`,
                    options
                );
            } catch (error) { }
        }
    }



    openModal(id: string, idMessage) {

        /* console.log(id); */

        this.idMessage = idMessage;

        switch (id) {
            case "popup-confirmDelete":
                /* slug, messageId, channelId, customerId */
                /*  this.hsmMessageService.delete(this.slug, idMessage, this.channelID, this.customerId).subscribe(
                     res => {
                         console.log(res);

                     },
                     err => {
                         console.log(err);

                     }
                 ) */
                break;

            case "popup-preview":
                this.hsmMessageService.getOne(this.slug, idMessage, this.channelID, this.customerId).subscribe(
                    res => {
                        this.dataMessageG = res;
                        if (this.dataMessageG.category == 'AUTHENTICATION') {
                            if (this.dataMessageG.codeExpirationMinutes) {
                                this.dataMessageG.codeExpiryDate = true;
                            } else {
                                this.dataMessageG.codeExpiryDate = false;
                            }
                        }
                        /* console.log(res); */

                    }, err => {
                        /* console.log(err); */

                    }
                )

                break;

            default:
                break;
        }


        $('.jw-modal-body').css("padding", "40px");
        this.modalService.open(id);
        /* console.log(idMessage); */



    }

    deleteMessage() {


        /* console.log('delete message', this.idMessage); */
        if (this.existsWhatsappChannel) {
            this.hsmMessageService.deleteWhats(this.slug, this.idMessage, this.whatsappChannelOwnerId, this.customerId).subscribe(
                res => {
                    /* console.log(res); */

                    this.modalService.close('popup-confirmDelete');

                    if (this.tabActive === 'ALL') {
                        this.getMessages(this.page);
                    } else {
                        this.getMessagesByStatus(this.tabActive)
                        /* this.getCampaignsByStatus( this.tabActive, this.page); */
                    }
                    this.dataLayerService.setFlag(this.flagPath, 'delete');
                },
                err => {
                    /* console.log(err); */

                }
            );
        } else if (this.existsSmoochChannel || this.existsSmoochChannelSms) {
            this.hsmMessageService.deleteSmooch(this.slug, this.idMessage, this.channelID, this.customerId).subscribe(
                res => {
                    /* console.log(res); */

                    this.modalService.close('popup-confirmDelete');

                    if (this.tabActive === 'ALL') {
                        this.getMessages(this.page);
                    } else {
                        this.getMessagesByStatus(this.tabActive)
                        /* this.getCampaignsByStatus( this.tabActive, this.page); */
                    }
                    this.dataLayerService.setFlag(this.flagPath, 'delete');
                },
                err => {
                    /* console.log(err); */

                }
            );
        }

    }

    functionCancel(id) {
        this.modalService.close(id);
    }
    closeModal(id: string) {
        this.modalService.close(id);

    }

    goto(id) {
        this.dataLayerService.setFlag(this.flagPath, 'edit');
        this.router.navigateByUrl(`/customers/${this.slug}/campaigns/templateValidation/${id}`);
        /* path: 'customers/:customer_slug/campaigns/templateValidation/:hsm_message_id', */
    }

    goToSendCampaign(messageId) {
        this.dataLayerService.setFlag(this.flagPath, 'send');
        this.router.navigateByUrl(`/customers/${this.slug}/campaigns/sendCampaign?messageId=${messageId}`);
    }

    redirectTemplate() {
        /* https://localhost:4200/customers/campaas-k3uh2dsk/campaigns/templateValidation */
        this.router.navigateByUrl(`/customers/${this.slug}/campaigns/templateValidation`);
    }
    showTab(tab) {

        $('#selectDateT').val("");
        $('#selectDate').val("");
        this.nameSearchInput = '';
        $('.inputSearchName').val("");
        if (this.tabActive !== 'tab') {
            switch (tab) {
                case 'ALL':
                    this.getMessages(0);
                    break;
                case 'APPROVED':
                    this.dataLayerService.setFlag(this.flagPath, 'approved');
                case 'REJECTED':
                    this.dataLayerService.setFlag(this.flagPath, 'rejected');
                case 'PENDING':
                    this.dataLayerService.setFlag(this.flagPath, 'pending');
                case 'NOT_SENT':
                    this.getMessagesByStatus(tab);
                    this.dataLayerService.setFlag(this.flagPath, 'draft');
                    break;
            }
            this.tabActive = tab;
            this.page = 0;
        }
    }

    searchByDate(date) {

        this.nameSearchInput = '';
        $('.inputSearchName').val("");

        if (date) {


            let monthParameter = date.split('-')[1].trim();
            let yearParameter = date.split('-')[2].trim();

            let indexMonth = this.months.findIndex(element => element === monthParameter);


            this.hsmMessageService.getByRangeDate(this.channelID, yearParameter, indexMonth + 1, this.tabActive, this.slug, this.customerId, this.page).subscribe(
                (res: any) => {

                    const messages: [] = res.content;
                    this.dataMessage = this.setStatusName(messages);
                    this.total = res.totalElements;


                    if (this.dataMessage.length > 0) {
                        this.existsMessages = true;
                    }


                }, err => {
                    /* console.log(err); */

                }
            )
        }





    }

    searchByDateSms(date) {

        this.nameSearchInput = '';
        $('.inputSearchName').val("");

        if (date) {


            let monthParameter = date.split('-')[1].trim();
            let yearParameter = date.split('-')[2].trim();

            let monthNumber=0

            for(let i=0;i < this.months.length;i++){

                if(this.months[i].includes(monthParameter)){

                    monthNumber = i +1;

                }
            }

            this.hsmMessageService.getByDateSms(this.channelID, yearParameter, monthNumber, this.slug, this.customerId, this.page).subscribe(
                (res: any) => {

                    const messages: [] = res.content;
                    this.dataMessage = this.setStatusName(messages);
                    this.total = res.totalElements;


                    if (this.dataMessage.length > 0) {
                        this.existsMessages = true;
                    }


                }, err => {
                    /* console.log(err); */

                }
            )
        }





    }
    convertDates(meses) {
        this.arrayMonthsConverted = this.arrayDates.map(function (num) {
            let splitdate = num.split("/");

            return `${splitdate[0]
                } - ${meses[splitdate[1] - 1]} - ${splitdate[2]}`;
        });

    }

    isDisabled(compare1, compare2) {
        return !( compare1 == compare2);
    }
    isDisabledEdit(status) {
        return !(['Borrador', 'Error'].includes(status));
    }

    trackByFn() {

    }

    forceApprove(dataMessage2: HsmMessage, index) {
        const dataMessage3 = {
            authorizationStatus: HSMStatusType.APPROVED
        };
        this.hsmMessageService.update(dataMessage3, this.slug, dataMessage2.id, dataMessage2.tenantChannelId, dataMessage2.id).subscribe(
            (res: any) => {
                this.alertService.success(translate.sections.campaigns.statusApproval.messageUpdate);
                this.dataMessage[index].authorizationStatus = HSMStatusTypeLabel[HSMStatusType.APPROVED];
            },
            err => {
                this.alertService.error(translate.sections.campaigns.statusApproval.messageNoUpdate);
            }
        );
    }

    showForceApprove() {
        let show = false;
        const u = JSON.parse(localStorage['payload']);
        if(u.email.indexOf('holagus.com') > 0) {
            show = true;
        }
        return show;
    }

    copy(str: string) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    }

    getHSMCategories(success=null) {
        this.hsmMessageService.getHSMCategories(this.slug, this.customerId).subscribe(
            (categories: any[]) => {
                this.categories = categories;
                if (typeof success === 'function') {
                    success();
                }
            }
        );
    }
}
