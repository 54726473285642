<div class="sectionTickets">
    <div class="tickets">
        <h1>{{translate.sections.customers.showCustomers.tickets}}</h1>

        <div class="actions">
            <input [(ngModel)]="ticketId" type="text" />
            <button class="btn-primary" (click)="getEntitiesByTicket(ticketId)">{{translate.getEntities}}</button>
            <!-- <button>Conversaciones</button> -->
        </div>

        <div class="ticketData">
            <pre *ngIf="entities">{{ getJsonString(entities) }}</pre>
        </div>
    </div>
</div>
