import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// import { environment } from '../environments/environment';

import { Platform } from '../models/platform';
import { PlatformCatalog } from '../models/platform-catalog';


@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    constructor(private http: HttpClient) { }

    getAll(customerSlug: string): Observable<Platform[]> {
        const url = `/coreapi/v2/platforms`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.get<Platform[]>(url, { headers });
    }

    getById(id: string, customerSlug: string): Observable<Platform[]> {
        const url = `/coreapi/v2/platforms/${id}`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.get<Platform[]>(url, { headers });
    }

    getByName(name: string, customerSlug: string): Observable<Platform[]> {
        const url = `/coreapi/v2/platforms/byName/${name}`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.get<Platform[]>(url, { headers });
    }

    add(platform: any, slug: string) {
        const url = `/coreapi/v2/platforms`;

        const headers = new HttpHeaders({
            Slug: slug
        });

        return this.http.post(
            url,
            platform,
            { headers }
        );
    }

    // Catalog

    getCatalog(slug: string): Observable<PlatformCatalog[]> {
        const url = `/coreapi/v2/platformCatalogs`;

        const headers = new HttpHeaders({
            Slug: slug
        });

        return this.http.get<PlatformCatalog[]>(url, { headers });
    }


  

    getCatalogByEnviroment(slug: string,env): Observable<PlatformCatalog[]> {
        /* let url = `/coreapi/v2/platformCatalogs`; */
        
        const user = localStorage.getItem('id');
        const pass = localStorage.getItem('token');
        const auth = btoa(`${user}:${pass}`);
        let url;


        if(env!='PROD'){

            
            url = `https://flux-v2-${env}.gus.chat/coreapi/v2/platformCatalogs`;
        }
            
        else {
            url = `https://flux-v2.gus.chat/coreapi/v2/platformCatalogs`;
            
        }


       /*  const headers = new HttpHeaders({
            Slug: slug
        }); */

        const headers = new HttpHeaders({
            slug,
            'Auth-Type': localStorage.getItem('Auth-Type'),
            Authorization: 'Basic ' + auth,
        });

        return this.http.get<PlatformCatalog[]>(url, { headers });
    }

}
