import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    constructor(private http: HttpClient) { }

    getAll() {
        const lang = localStorage.getItem('lang').toUpperCase();
        const url = '/coreapi/v2/fluxError?language=' + lang;

        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.get(url, { headers });
    }

    getByCode(code: string) {
        const lang = localStorage.getItem('lang').toUpperCase();
        const url = `/coreapi/v2/fluxError/${code}/${lang}`;

        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.get(url, { headers });
    }
}
