import { Injectable } from "@angular/core";
import * as joint from "../../../vendor/rappid";
import { AlertService } from '../alert.service';
import { ModalService } from "./modal.service";

declare var $: any;
declare var translate: any;

@Injectable({
    providedIn: "root",
})
export class ButtonService {
    // // private alertService: AlertService;
    // private modal: ModalService;
    // buttonsLimit: number;
    // translate: any;
    // constructor() {
    //     this.translate = translate;
    //     this.modal = new ModalService();
    //     this.buttonsLimit = 3;
    //     if (window.location.href.toString().indexOf('WEBSITE') > 0) {
    //         this.buttonsLimit = 11;
    //     }
    // }

    // createResponseBtnString(objectSend, req) {
    //     const responseButtons = {
    //         elements: objectSend.elements,
    //         type: "jsonB",
    //         showOutputs: true,
    //     };

        // if (
        //     req.constraints &&
        //     typeof req.constraints === "object" &&
        //     "type" in req.constraints
        // ) {
        //     if ("noOutputs" === req.constraints.type) {
        //         responseButtons.showOutputs = false;
        //     }
        // }
    //     return JSON.stringify(responseButtons);
    // }

    // getButtons(fieldJM, req, value) {
    //     const containerDiv = $('<div class="type_visual_box buttons_dyn" />');
    //     let cardTitleSlide: any;
    //     let divButtonSlide: any;
    //     let $buttonSlider: any;
    //     let deleteButton: any;
    //     let lengthButtons: any;
    //     let spanButtonSlide: any;
    //     let indexbtnSlider: any;
    //     let typeSlider: any = "";
    //     let titleSlider: any = "";
    //     let titleSlider2: any = "";
    //     let titleWebview: any = "";
    //     // let valueTextarea: any = '';
    //     let phoneValue: any = "";
    //     let titleURL: any = "";
    //     let selectRatioValue: any = "";
    //     const objectSend: any = { elements: " " };
    //     let arrraybuttonsSlider: any = [];
    //     // const textAreaButtons = $('<textarea class="escape" placeholder="value" cols="2" rows="2"></textarea>');
    //     const addButtonsSlider = $(
    //         '<button class="addButtonsSlider">+ ' + translate.sections.services.button+' </button>'
    //     );
    //     const cardSlider = $(`<div class="card"></div>`);
    //     // const deleteCard = $('<a class="deleteCard">×</a>');
    //     const chunksBtnFieldName = fieldJM.attr("name").split("#");
    //     const name = chunksBtnFieldName[0];
    //     const inputCardTitleSlide = $(
    //         '<input \
    //         class="cardTitleSlide2" \
    //         name="' +
    //             name +
    //             '_title[]" \
    //         placeholder="Title" \
    //         type="text"/>'
    //     );

    //     inputCardTitleSlide.on('focusout', (e) => {
    //         const valueFieldStr = fieldJM.val();
    //         const valueFieldObj = JSON.parse(valueFieldStr);
    //         valueFieldObj.elements.title = inputCardTitleSlide.val();
    //         fieldJM.val(JSON.stringify(valueFieldObj));
    //     })

    //     // cardSlider.append(deleteCard);
    //     cardSlider.append(inputCardTitleSlide);
    //     cardSlider.append(addButtonsSlider);

    //     containerDiv.append([cardSlider, fieldJM]);

    //     const inputTitleSlider = $(
    //         '<input type="text" class="titleSlider inputInspectorStyle" placeholder="Title">'
    //     );
    //     const fieldSetModalButtons = $(
    //         '<fieldset class="fieldset"></fieldset>'
    //     );

    //     /* test */

    //     // test radioUrl

    //     const divRadioPhone = $("<div></div>");

    //     const radioPhone = $(`<input
    //         class="phone_number"  type="radio" name="typeSlider" value="phone_number">`);

    //     const labelRadioPhone = $(
    //         '<label class="label-radio" for="phone_number">Phone</label>'
    //     );

    //     divRadioPhone.append([radioPhone, labelRadioPhone]);

    //     /* test radio url */

    //     const divRadioURL = $("<div></div>");
    //     const radioURL = $(
    //         '<input  class="web_url" type="radio" name="typeSlider" value="web_url">'
    //     );
    //     const labelRadioURL = $(
    //         '<label class="label-radio" for=web_url>URL</label>'
    //     );
    //     divRadioURL.append([radioURL, labelRadioURL]);

    //     /* end */

    //     /* test radio webview */

    //     const divRadioWEB = $("<div></div>");
    //     const radioWebview = $(
    //         '<input class="web_view"  type="radio" name="typeSlider" value="web_view">'
    //     );

    //     const labelRadioWEB = $(
    //         '<label class="label-radio" for="web_view">WebView</label>'
    //     );
    //     divRadioWEB.append([radioWebview, labelRadioWEB]);

    //     /* end */

    //     /* test radio conexion */

    //     const divRadioConexion = $("<div></div>");

    //     const radioConexion = $(
    //         '<input class="postback"  type="radio" name="typeSlider" value="postback">'
    //     );
    //     const labelRadioConexion = $(
    //         '<label class="label-radio" for="conexion">' + translate.sections.services.conection+ '</label>'
    //     );
    //     divRadioConexion.append([radioConexion, labelRadioConexion]);

    //     const inputURL = $(
    //         '<input type="text" placeholder="URL" class="inputURL">'
    //     );
    //     const inputWebview = $(
    //         '<input type="text" placeholder="webview" class="inputWebview">'
    //     );

    //     /* end */

    //     const inputPhone = $(
    //         '<input hidden type="text" placeholder="' + translate.sections.services.phone+ '" class="input_phone_number">'
    //     );
    //     const selectHeightRatio = $(`<select hidden class="select_height_ratio" name="">
    //     <option value=""> `+ translate.sections.services.select+ `</option>
    //     <option value="compact">`+ translate.sections.services.compact+ `</option>
    //     <option value="tall">`+ translate.sections.services.high+ `</option>
    //     <option value="full">`+ translate.sections.services.complete+ `</option>
    //     </select>`);

    //     const buttonSaveB = $(
    //         '<button class="buttonSaveQuick">'+ translate.sections.services.save+ '</button>'
    //     );
    //     /*
    //     fieldSetModalButtons.append(radioURL);
    //     fieldSetModalButtons.append(radioWebview);
    //     fieldSetModalButtons.append(radioConexion);
    //     fieldSetModalButtons.append(radioPhone); */

    //     fieldSetModalButtons.append(divRadioURL);
    //     fieldSetModalButtons.append(divRadioWEB);
    //     fieldSetModalButtons.append(divRadioConexion);
    //     fieldSetModalButtons.append(divRadioPhone);

    //     // modal function
    //     const modal = this.modal.create({});

    //     modal.box.append(inputTitleSlider);
    //     modal.box.append(fieldSetModalButtons);
    //     modal.box.append(inputURL);
    //     modal.box.append(inputWebview);
    //     modal.box.append(inputPhone);
    //     modal.box.append(selectHeightRatio);
    //     modal.box.append(buttonSaveB);

    //     $("body").append([modal.box, modal.blocker]);

    //     buttonSaveB.on("click", (eClose) => {
    //         // console.log('onclose modal2');
    //         eClose.preventDefault();
    //         eClose.stopPropagation();
    //         // blockerDivButtons.hide();
    //         // modalDivButtons.hide();
    //         // console.log(indexbtnSlider);

    //         const parentClose = $(eClose.target).parent();
    //         // console.log(parentClose[0]);
    //         const id = joint.util.uuid(); // Date.now();

    //         typeSlider = $("input:radio[name=typeSlider]:checked").val();
    //         titleSlider = parentClose.find(".titleSlider").val();
    //         titleURL = parentClose.find(".inputURL").val();
    //         titleWebview = parentClose.find(".inputWebview").val();
    //         phoneValue = parentClose.find(".input_phone_number").val();
    //         selectRatioValue = parentClose.find(".select_height_ratio").val();
    //         cardTitleSlide = parentClose
    //             .parent()
    //             .find(".card")
    //             .eq(0)
    //             .find(".cardTitleSlide2")
    //             .val();
    //         // valueTextarea = textAreaButtons.val();

    //         // l$('.card').eq(indexCard);
    //         const findButtonS = $(".card")
    //             .eq(0)
    //             .find(".btnSlider")
    //             .eq(indexbtnSlider);
    //         // console.log(findButtonS);

    //         // console.log('type slider');
    //         // console.log(typeSlider);

    //         if (titleSlider !== "") {
    //             findButtonS.text(titleSlider);
    //         }
    //         const objectButton: any = {
    //             type: typeSlider,
    //             title: titleSlider,
    //             id,
    //         };

    //         if (typeSlider === "web_url") {
    //             objectButton.url = titleURL;
    //         } else if (typeSlider === "web_view") {
    //             objectButton.url = titleWebview;
    //             objectButton.webview_height_ratio = selectRatioValue;
    //             objectButton.messenger_extensions = true;
    //             objectButton.fallback_url = titleWebview;
    //         } else if (typeSlider === "phone_number") {
    //             objectButton.value = phoneValue;
    //         } else if (typeSlider === "postback") {
    //             objectButton.value = "";
    //             objectButton.block_id = id;
    //         }

    //         // console.log('datos del objeto');
    //         // console.log(objectButton);

    //         arrraybuttonsSlider[indexbtnSlider] = objectButton;
    //         const objectSliderButton: any = {
    //             id,
    //             title: titleSlider2,
    //             buttons: arrraybuttonsSlider,
    //         };

    //         // console.log('show object');
    //         // console.log(objectSliderButton);
    //         objectSend.elements = objectSliderButton;
    //         // objectSend.text = valueTextarea,
    //         // objectSend.elements.title = titleSlider;
    //         // console.log('convert in json send');

    //         // console.log(JSON.stringify(objectSend));

    //         const responseButtons = this.createResponseBtnString(
    //             objectSend,
    //             req
    //         );

    //         // console.log(responseButtons);

    //         fieldJM.val(responseButtons);
    //         // console.log(JSON.parse(fieldJM.val()));

    //         lengthButtons = $(".card").eq(0).find(".btnSlider");

    //         $('input[name="typeSlider"]').prop("checked", false);
    //         $(eClose.target).parent().find(".titleSlider").val("");
    //         $(eClose.target).parent().find(".inputURL").val("");
    //         $(eClose.target).parent().find(".inputWebview").val("");
    //         $(eClose.target).parent().find(".input_phone_number").val("");
    //         $(eClose.target).parent().find(".select_height_ratio").val("");

    //         inputWebview.hide();
    //         inputURL.hide();

    //         inputPhone.hide();

    //         selectHeightRatio.hide();

    //         modal.hide();
    //     });

    //     const validateURL = (url) => {
    //         if (
    //             /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
    //                 url
    //             )
    //         ) {
    //             return true;
    //         }
    //         return false;
    //     };

    //     const ValidatePhone = (phone) => {
    //         if (/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(phone)) {
    //             return true;
    //         }
    //         return false;
    //     };

    //     inputWebview.on("keyup", (e) => {
    //         if (validateURL(e.target.value)) {
    //             inputWebview.removeClass("invalid-input");
    //             inputWebview.addClass("validate-input");
    //         } else {
    //             inputWebview.removeClass("validate-input");
    //             inputWebview.addClass("invalid-input");
    //         }
    //     });

    //     inputURL.on("keyup", (e) => {
    //         if (validateURL(e.target.value)) {
    //             inputURL.removeClass("invalid-input");
    //             inputURL.addClass("validate-input");
    //         } else {
    //             inputURL.removeClass("validate-input");
    //             inputURL.addClass("invalid-input");
    //         }
    //     });

    //     inputPhone.on("keyup", (e) => {
    //         if (ValidatePhone(e.target.value)) {
    //             inputPhone.removeClass("invalid-input");
    //             inputPhone.addClass("validate-input");
    //         } else {
    //             inputPhone.removeClass("validate-input");
    //             inputPhone.addClass("invalid-input");
    //         }
    //     });

    //     // Begin manage constraints

    //     try {
    //         inputCardTitleSlide.val(req.constraints.text.value);
    //         switch (req.constraints.text.type) {
    //             case "fixed":
    //                 inputCardTitleSlide.attr("disabled", "disabled");
    //                 break;
    //             case "suggest":
    //                 // nothing to do, for now
    //                 break;
    //         }

    //         switch (req.constraints.buttons.type) {
    //             case "fixed":
    //                 addButtonsSlider.remove();
    //                 break;
    //             case "addFixed":
    //             case "suggest":
    //                 // nothing to do, for now
    //                 break;
    //         }
    //     } catch (e) {}

    //     if (value === undefined) {
    //         if (req.type === "jsonB") {
    //             try {
    //                 titleSlider2 = req.constraints.text.value;

    //                 // console.log(req.constraints);
    //                 req.constraints.buttons.value.forEach((btn) => {
    //                     const idBtn2 = joint.util.uuid();
    //                     const objectButton: any = {
    //                         type: btn.type,
    //                         title: btn.title,
    //                         id: idBtn2,
    //                     };
    //                     if (objectButton.type === "web_url") {
    //                         objectButton.url = btn.url;
    //                     } else if (objectButton.type === "web_view") {
    //                         objectButton.url = btn.url;
    //                         objectButton.webview_height_ratio =
    //                             btn.webview_height_ratio;
    //                         objectButton.messenger_extensions = true;
    //                         objectButton.fallback_url = btn.url;
    //                     } else if (objectButton.type === "phone_number") {
    //                         objectButton.value = btn.payload;
    //                     } else if (objectButton.type === "postback") {
    //                         objectButton.value = btn.value;
    //                         objectButton.block_id = idBtn2;
    //                     }
    //                     arrraybuttonsSlider.push(objectButton);
    //                 });
    //                 const idBtn3 = joint.util.uuid();
    //                 const objectSliderButton: any = {
    //                     id: idBtn3,
    //                     title: titleSlider2,
    //                     buttons: arrraybuttonsSlider,
    //                 };
    //                 // console.log(objectSliderButton);
    //                 objectSend.elements = objectSliderButton;
    //                 const responseBtnStringPre = this.createResponseBtnString(
    //                     objectSend,
    //                     req
    //                 );
    //                 // console.log(responseBtnStringPre);
    //                 fieldJM.val(responseBtnStringPre);

    //                 value = {};
    //                 const aux = {};
    //                 aux[chunksBtnFieldName[1]] = {
    //                     value: responseBtnStringPre,
    //                 };
    //                 value[chunksBtnFieldName[0]] = aux;
    //                 // console.log(value);
    //             } catch (e) {
    //                 // console.log(e);
    //             }
    //         }

    //         /* test aqui */


    //         /* termina test */
    //     }

    //     // End manage constraints

    //     // VALUE BUTTONS
    //     if (value !== undefined) {
    //         // console.log('entra el primer if value');

    //         // console.log(
    //         //     "%c value ",
    //         //     "background: #007E33; color: white",
    //         //     value
    //         // );
    //         Object.keys(value).forEach((key) => {
    //             // console.log('entrar el object keys');

    //             if ("req_name" !== key && "" !== key) {
    //                 const fieldValue = value[key];
    //                 Object.keys(fieldValue).forEach((key2) => {
    //                     const fv = fieldValue[key2];
    //                     let json;
    //                     try {
    //                         json = JSON.parse(fv.value);
    //                     } catch (e) {}

    //                     if (json && json.type === "jsonB") {
    //                         // console.log('entrar el jsonb');

    //                         // console.log(json);
    //                         if (json.elements) {
    //                             objectSend.elements = json.elements;
    //                             //objectSend.elements.title = json.elements.value;
    //                             // console.log('value textarea', json.text);

    //                             inputCardTitleSlide.val(json.elements.title);

    //                             let btns;
    //                             if (Array.isArray(json.elements)) {
    //                                 btns = json.elements[0].buttons;
    //                             } else {
    //                                 btns = json.elements.buttons;
    //                             }

    //                             arrraybuttonsSlider = btns;

    //                             for (
    //                                 let indexS = 0;
    //                                 indexS < btns.length;
    //                                 indexS++
    //                             ) {
    //                                 divButtonSlide = $(
    //                                     '<div class="divButtonSlide"></div>'
    //                                 );
    //                                 $buttonSlider = $(
    //                                     '<a class="show_modal btnSlider"></a>'
    //                                 );
    //                                 spanButtonSlide = $(`<span hidden></span>`);
    //                                 deleteButton = $(
    //                                     '<a class="deleteButtonSlider">×</a>'
    //                                 );
    //                                 divButtonSlide.append($buttonSlider);
    //                                 divButtonSlide.append(deleteButton);
    //                                 divButtonSlide.append(spanButtonSlide);

    //                                 cardSlider.append(divButtonSlide);

    //                                 const loadTitleSliderr = btns[indexS].title;

    //                                 // console.log('info buttons');
    //                                 // console.log(btns[indexS].title);

    //                                 lengthButtons = cardSlider.find(
    //                                     ".btnSlider"
    //                                 ).length;

    //                                 if (lengthButtons >= this.buttonsLimit) {
    //                                     addButtonsSlider.css("display", "none");
    //                                 } else {
    //                                     addButtonsSlider.css(
    //                                         "display",
    //                                         "block"
    //                                     );
    //                                 }

    //                                 cardSlider
    //                                     .find(".btnSlider")
    //                                     .eq(indexS)
    //                                     .text(loadTitleSliderr);

    //                                 spanButtonSlide.text(indexS);

    //                                 deleteButton.on(
    //                                     "click",
    //                                     (eventDeleteButtonLoaded) => {
    //                                         // console.log("button deleted in value");

    //                                         const cardBoxLoad = $(
    //                                             eventDeleteButtonLoaded.target
    //                                         ).parent();
    //                                         const listCardBoxLoad = cardBoxLoad
    //                                             .parent()
    //                                             .find(".divButtonSlide");
    //                                         const indexLoad = listCardBoxLoad.index(
    //                                             cardBoxLoad
    //                                         );
    //                                         // console.log(indexLoad);

    //                                         lengthButtons = cardSlider.find(
    //                                             ".btnSlider"
    //                                         ).length;
    //                                         // console.log(lengthButtons);
    //                                         if (lengthButtons >= 1) {
    //                                             addButtonsSlider.css(
    //                                                 "display",
    //                                                 "block"
    //                                             );
    //                                         } else {
    //                                             addButtonsSlider.css(
    //                                                 "display",
    //                                                 "none"
    //                                             );
    //                                         }

    //                                         indexbtnSlider = +$(
    //                                             eventDeleteButtonLoaded.target
    //                                         )
    //                                             .siblings()
    //                                             .eq(1)
    //                                             .text();

    //                                         // console.log('delete button number');
    //                                         // console.log(indexLoad);

    //                                         $(
    //                                             eventDeleteButtonLoaded.target
    //                                         ).slideUp("fast", () => {
    //                                             $(
    //                                                 eventDeleteButtonLoaded.target
    //                                             )
    //                                                 .parent()
    //                                                 .remove();
    //                                         });

    //                                         try {
    //                                             objectSend.elements.buttons.splice(
    //                                                 indexLoad,
    //                                                 1
    //                                             );
    //                                             objectSend.elements.title = json.elements.title;
    //                                             // console.log(objectSend.elements.buttons);
    //                                         } catch (error) {}

    //                                         const responseButtons = this.createResponseBtnString(
    //                                             objectSend,
    //                                             req
    //                                         );
    //                                         // console.log(responseButtons);

    //                                         fieldJM.val(responseButtons);
    //                                     }
    //                                 );

    //                                 $buttonSlider.on(
    //                                     "click",
    //                                     (eventButtonSliderLoaded) => {
    //                                         modal.show();
    //                                         indexbtnSlider = +$(
    //                                             eventButtonSliderLoaded.target
    //                                         )
    //                                             .parent()
    //                                             .find("span")
    //                                             .text();

    //                                         const cardBox = $(
    //                                             eventButtonSliderLoaded.target
    //                                         ).parent();
    //                                         const listCardBox = cardBox
    //                                             .parent()
    //                                             .find(".divButtonSlide");
    //                                         const indexB = listCardBox.index(
    //                                             cardBox
    //                                         );
    //                                         // console.log(indexB);
    //                                         /* aqui editar */

    //                                         // console.log('index button');
    //                                         // console.log(indexbtnSlider);

    //                                         const btns2 = json.elements.buttons;
    //                                         // console.log('load data"');
    //                                         // console.log(btns2);
    //                                         const loadTitleSlider =
    //                                             btns2[indexB].title;
    //                                         // load data
    //                                         const radioSelected =
    //                                             btns2[indexB].type;

    //                                         inputTitleSlider.val(
    //                                             loadTitleSlider
    //                                         );
    //                                         $(`.${radioSelected}`).prop(
    //                                             "checked",
    //                                             true
    //                                         );

    //                                         typeSlider = $(
    //                                             "input:radio[name=typeSlider]:checked"
    //                                         ).val();

    //                                         if (typeSlider === "web_url") {
    //                                             const loadTitleURL =
    //                                                 btns2[indexB].url;
    //                                             selectHeightRatio.hide();
    //                                             inputPhone.hide();
    //                                             inputWebview.hide();
    //                                             inputURL.show();
    //                                             inputURL.val(loadTitleURL);
    //                                         }
    //                                         if (typeSlider === "web_view") {
    //                                             const loadTitleWEB =
    //                                                 btns2[indexB].url;
    //                                             const loadOptionRatio =
    //                                                 btns2[indexB]
    //                                                     .webview_height_ratio;
    //                                             inputPhone.hide();
    //                                             inputURL.hide();
    //                                             inputWebview.show();
    //                                             selectHeightRatio.show();
    //                                             inputWebview.val(loadTitleWEB);
    //                                             selectHeightRatio.val(
    //                                                 loadOptionRatio
    //                                             );
    //                                         }
    //                                         if (typeSlider === "postback") {
    //                                             inputURL.hide();
    //                                             inputWebview.hide();
    //                                             selectHeightRatio.hide();
    //                                             inputPhone.hide();
    //                                             inputTitleSlider.val(
    //                                                 loadTitleSlider
    //                                             );
    //                                         }
    //                                         if (typeSlider === "phone_number") {
    //                                             const loadPhoneNumber =
    //                                                 btns2[indexB].value;
    //                                             inputURL.hide();
    //                                             inputWebview.hide();
    //                                             selectHeightRatio.hide();
    //                                             inputPhone.show();
    //                                             inputPhone.val(loadPhoneNumber);
    //                                         }
    //                                     }
    //                                 );

    //                                 try {
    //                                     if (
    //                                         (req.constraints.buttons.type ===
    //                                             "fixed" ||
    //                                             req.constraints.buttons.type ===
    //                                                 "addFixed") &&
    //                                         indexS + 1 <=
    //                                             req.constraints.buttons.value
    //                                                 .length
    //                                     ) {
    //                                         deleteButton.remove();
    //                                     }
    //                                 } catch (e) {}
    //                             }
    //                         }
    //                         // }
    //                     }
    //                 });
    //             }
    //         });
    //     }

    //     // END

    //     labelRadioWEB.on("click", (e) => {
    //         radioWebview.prop("checked", true);
    //         // $(this).siblings(1).prop('checked', true);
    //         if (radioWebview.is(":checked")) {
    //             inputWebview.show();
    //             selectHeightRatio.show();
    //             inputURL.hide();
    //             inputPhone.hide();
    //             inputURL.css("outline", "none");
    //             inputPhone.css("outline", "none");
    //             /* divRadioURL.hide();
    //             divRadioPhone.hide(); */
    //         }
    //     });

    //     labelRadioPhone.on("click", (e) => {
    //         // console.log("click en label radio phone");

    //         radioPhone.prop("checked", true);
    //         if (radioPhone.is(":checked")) {
    //             inputWebview.hide();
    //             inputPhone.show();
    //             selectHeightRatio.hide();
    //             inputWebview.css("outline", "none");
    //         }
    //     });

    //     labelRadioConexion.on("click", (e) => {
    //         radioConexion.prop("checked", true);
    //         if (radioConexion.is(":checked")) {
    //             inputWebview.hide();
    //             inputURL.hide();
    //             inputPhone.hide();
    //             selectHeightRatio.hide();
    //             inputURL.css("outline", "none");
    //             inputPhone.css("outline", "none");
    //         }
    //     });

    //     labelRadioURL.on("click", (e) => {
    //         radioURL.prop("checked", true);
    //         // $(this).siblings(1).prop('checked', true);
    //         if (radioURL.is(":checked")) {
    //             inputURL.show();
    //             inputWebview.hide();
    //             inputPhone.hide();
    //             selectHeightRatio.hide();
    //             inputWebview.css("outline", "none");
    //             inputPhone.css("outline", "none");
    //         }
    //     });

    //     radioURL.on("click", (e) => {
    //         if ($(e.target).is(":checked")) {
    //             inputURL.show();
    //             inputWebview.hide();
    //             inputPhone.hide();
    //             selectHeightRatio.hide();
    //             inputWebview.css("outline", "none");
    //             inputPhone.css("outline", "none");
    //         }
    //     });
    //     radioWebview.on("click", (e) => {
    //         if ($(e.target).is(":checked")) {
    //             inputWebview.show();
    //             selectHeightRatio.show();
    //             inputURL.hide();
    //             inputPhone.hide();
    //             inputURL.css("outline", "none");
    //             inputPhone.css("outline", "none");
    //         }
    //     });
    //     radioConexion.on("click", (e) => {
    //         if ($(e.target).is(":checked")) {
    //             inputWebview.hide();
    //             inputURL.hide();
    //             inputPhone.hide();
    //             selectHeightRatio.hide();
    //             inputURL.css("outline", "none");
    //             inputPhone.css("outline", "none");
    //         }
    //     });

    //     radioPhone.on("click", (e) => {
    //         if ($(e.target).is(":checked")) {
    //             inputWebview.hide();
    //             inputPhone.show();
    //             selectHeightRatio.hide();
    //             inputWebview.css("outline", "none");
    //         }
    //     });

    //     addButtonsSlider.on("click", (eventAddButtonsSlider) => {
    //         // console.log("add buttons");

    //         inputURL.val("");
    //         inputWebview.val("");
    //         inputPhone.val("");
    //         selectHeightRatio.val("");
    //         inputTitleSlider.val("");

    //         inputURL.hide("");
    //         inputWebview.hide("");
    //         inputPhone.hide("");
    //         selectHeightRatio.hide("");

    //         lengthButtons = cardSlider.find(".btnSlider");

    //         // console.log(lengthButtons.length + ' < ' + this.buttonsLimit);

    //         if (lengthButtons.length < this.buttonsLimit) {
    //             divButtonSlide = $('<div class="divButtonSlide"></div>');
    //             $buttonSlider = $('<a class="show_modal btnSlider"></a>');
    //             spanButtonSlide = $(
    //                 `<span hidden class="spanButtonReply"></span>`
    //             );
    //             deleteButton = $('<a class="deleteButtonSlider">×</a>');

    //             divButtonSlide.append($buttonSlider);
    //             divButtonSlide.append(deleteButton);
    //             divButtonSlide.append(spanButtonSlide);

    //             cardSlider.append(divButtonSlide);

    //             $buttonSlider.text(`Botón ${$(lengthButtons).length + 1}`);
    //             spanButtonSlide.text($(lengthButtons).length);

    //             deleteButton.on("click", (eventDeleteButtonSlider) => {
    //                 inputURL.val("");
    //                 inputWebview.val("");
    //                 inputPhone.val("");
    //                 selectHeightRatio.val("");
    //                 inputTitleSlider.val("");

    //                 inputURL.hide("");
    //                 inputWebview.hide("");
    //                 inputPhone.hide("");
    //                 selectHeightRatio.hide("");

    //                 const cardBox = $(eventDeleteButtonSlider.target).parent();
    //                 const listCardBox = cardBox.parent().find(".card");
    //                 const index = listCardBox.index(cardBox);
    //                 const lengthButtons2 = $(".card")
    //                     .eq(index)
    //                     .find(".btnSlider");
    //                 // console.log("tam buttons 2", lengthButtons2.length);

    //                 const cardBox2 = $(eventDeleteButtonSlider.target).parent();
    //                 const listCardBox2 = cardBox2
    //                     .parent()
    //                     .find(".divButtonSlide");
    //                 const indexB2 = listCardBox2.index(cardBox2);
    //                 // console.log(indexB2);

    //                 const indexButtonSlider: any = $(
    //                     eventDeleteButtonSlider.target
    //                 )
    //                     .siblings()
    //                     .eq(1)
    //                     .text();
    //                 // console.log('span hidden');
    //                 // console.log(indexButtonSlider);

    //                 $(eventDeleteButtonSlider.target).slideUp("fast", () => {
    //                     $(eventDeleteButtonSlider.target).parent().remove();
    //                 });
    //                 if (lengthButtons2.length - 1 <= this.buttonsLimit-1) {
    //                     addButtonsSlider.show();
    //                 }

    //                 try {
    //                     objectSend.elements.buttons.splice(indexB2, 1);

    //                     // console.log(objectSend);

    //                     const responseButtons = this.createResponseBtnString(
    //                         objectSend,
    //                         req
    //                     );
    //                     // console.log("valor response", responseButtons);

    //                     fieldJM.val(responseButtons);
    //                 } catch (error) {}
    //             });

    //             $buttonSlider.on("click", (eventButtonSlide) => {
    //                 modal.show();

    //                 // console.log("btoon click");

    //                 const cardBox = $(eventButtonSlide.target).parent();
    //                 const listCardBox = cardBox
    //                     .parent()
    //                     .find(".divButtonSlide");
    //                 const indexB = listCardBox.index(cardBox);
    //                 // console.log(indexB);

    //                 indexbtnSlider = +$(eventButtonSlide.target)
    //                     .siblings()
    //                     .eq(1)
    //                     .text();
    //                 // console.log('index button');
    //                 // console.log(indexbtnSlider);

    //                 try {
    //                     $('input[name="typeSlider"]').removeAttr("checked");
    //                     $('input[name="typeSlider"]').attr("checked", false);
    //                     const dataBntSlider =
    //                         objectSend.elements.buttons[indexB];
    //                     // console.log('datos cargados');
    //                     // console.log(dataBntSlider);

    //                     $(".titleSlider").val(dataBntSlider.title);
    //                     $(`.${dataBntSlider.type}`).prop("checked", "true");

    //                     if (dataBntSlider.type === "web_url") {
    //                         inputWebview.hide();
    //                         inputURL.show();
    //                         inputURL.val(dataBntSlider.url);
    //                         inputPhone.hide();
    //                         selectHeightRatio.hide();
    //                     } else if (dataBntSlider.type === "web_view") {
    //                         inputURL.hide();
    //                         inputWebview.show();
    //                         inputWebview.val(dataBntSlider.url);
    //                         selectHeightRatio.show();
    //                         selectHeightRatio.val(
    //                             dataBntSlider.webview_height_ratio
    //                         );
    //                     } else if (dataBntSlider.type === "phone_number") {
    //                         inputURL.hide();
    //                         inputWebview.hide();
    //                         inputPhone.show();
    //                         inputPhone.val(dataBntSlider.value);
    //                         selectHeightRatio.hide();
    //                     } else if (dataBntSlider.type === "postback") {
    //                         inputURL.hide();
    //                         inputWebview.hide();
    //                         inputPhone.hide();

    //                         selectHeightRatio.hide();
    //                     }
    //                 } catch (error) {}
    //             });
    //         }

    //         if (lengthButtons.length === this.buttonsLimit-1) {
    //             addButtonsSlider.hide("fast");
    //         }
    //     });

    //     return containerDiv;
    // }

    private modal: ModalService;
    private alertService: AlertService;

    translate: any;
    field: any;
    buttonsLimit: number;
    currentButton: any;
    buttonsResponse: any;
    buttonsCount: number;

    constructor() {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.modal = new ModalService();
        this.buttonsLimit = 3;
        if (window.location.href.toString().indexOf('WEBSITE') > 0) {
            this.buttonsLimit = 11;
        }
        this.buttonsResponse = {
            elements: {
                title : '',
                buttons: []
            },
            type: 'jsonB',
            showOutputs: true
        };
        this.buttonsCount = 0;
    }

    saveButtonsReponse() {
        this.field.val(JSON.stringify(this.buttonsResponse));
    }

    createModal(modal) {
        modal.box.css('width', 'auto');
        const modalTitle = $('<h2 class="modal-title">Configura el botón</h2>');
        const modalContentBox = $('<div class="modal-content" />');
        const modalActionsBox = $('<div class="modal-actions" />');
        const nameButtonBox = $('<div class="field-box" />');
        const titleButtonLabel = $('<label>Nombre del botón</label>');
        const titleButtonInput = $('<input type="text" class="title-button" placeholder="Ej. Continuar">');
        const typeButtonBox = $('<div class="field-box" />');
        const typeButtonLabel = $('<label>Tipo del botón</label>');
        const typeButtonSelect = $('<select class="type-button" />');
        const typeButtonOptions = [
            $('<option value="postback" selected>Conexión</option>'),
            $('<option value="phone">Teléfono</option>'),
            $('<option value="web_url">URL</option>'),
            $('<option value="web_view">Webview</option>')
        ];
        const typeButtonOptionsBox = $('<div />')
        const urlButtonBox = $('<div class="field-box" />');
        const urlButtonLabel = $('<label>Url a la que apuntará el botón</label>');
        const urlButtonInput = $('<input type="text" class="url-button" placeholder="Ej. https://gus.chat" />');
        const phoneButtonBox = $('<div class="field-box" />');
        const phoneButtonLabel = $('<label>Teléfono</label>');
        const phoneButtonInput = $('<input type="text" class="phone-button" placeholder="Ej. 525556581111" />');
        const webviewUrlButtonBox = $('<div class="field-box" />');
        const webviewUrlButtonLabel = $('<label>URL del webview a la que apuntará el botón</label>');
        const webviewUrlButtonInput = $('<input type="text" class="webview-url-button" placeholder="Ej. https://gus.chat" />');
        const webviewRatioButtonBox = $('<div class="field-box" />');
        const webviewRatioButtonLabel = $('<label>Relación de altura del webview</label>');
        const webviewRatioButtonSelect = $('<select class="webview-ratio-button" />');
        const webviewRatioButtonOptions = [
            $('<option value="compact">Compacto</option>'),
            $('<option value="tall" selected>Alto</option>'),
            $('<option value="full">Completo</option>')
        ];
        const modalActionsSaveButton = $('<button class="btn-primary">Guardar</button>');
        const modalActionsCloseButton = $('<button class="btn-secondary">Cerrar</button>');

        typeButtonSelect.on('change', () => {
            const selectedKey = typeButtonSelect.children('option:selected').val();
            this.showFieldsByBtnType(modal, selectedKey);
        });

        modalActionsSaveButton.on('click', () => {
            const title = titleButtonInput.val();
            const type = typeButtonSelect.val();
            const id = this.buttonsResponse.elements.buttons[this.currentButton].id;

            this.field.parent().find(`[data-id="${id}"]`).text(title);

            switch(typeButtonSelect.val()) {
                case 'postback':
                    this.buttonsResponse.elements.buttons[this.currentButton] = {
                        id,
                        block_id: id,
                        title,
                        type
                    }
                    break;
                case 'phone':
                    this.buttonsResponse.elements.buttons[this.currentButton] = {
                        id,
                        block_id: id,
                        title,
                        type,
                        value: phoneButtonInput.val()
                    }
                    break;
                case 'web_url':
                    this.buttonsResponse.elements.buttons[this.currentButton] = {
                        id,
                        block_id: id,
                        title,
                        type,
                        url: urlButtonInput.val()
                    }
                    break;
                case 'web_view':
                    this.buttonsResponse.elements.buttons[this.currentButton] = {
                        id,
                        block_id: id,
                        title,
                        type,
                        url: webviewUrlButtonInput.val(),
                        webview_height_ratio: webviewRatioButtonSelect.val(),
                        messenger_extensions: true,
                        fallback_url: webviewUrlButtonInput.val()
                    }
                    break;
            }

            this.saveButtonsReponse();
            modal.hide();
        });

        modalActionsCloseButton.on('click', () => {
            modal.hide();
        });

        webviewRatioButtonSelect.append(webviewRatioButtonOptions);
        typeButtonSelect.append(typeButtonOptions);

        webviewRatioButtonBox.append([webviewRatioButtonLabel, webviewRatioButtonSelect]);
        webviewUrlButtonBox.append([webviewUrlButtonLabel, webviewUrlButtonInput]);
        phoneButtonBox.append([phoneButtonLabel, phoneButtonInput]);
        urlButtonBox.append([urlButtonLabel, urlButtonInput]);
        nameButtonBox.append([titleButtonLabel, titleButtonInput]);
        typeButtonBox.append([typeButtonLabel, typeButtonSelect]);

        typeButtonOptionsBox.append([urlButtonBox, phoneButtonBox, webviewUrlButtonBox, webviewRatioButtonBox]);

        modalContentBox.append([nameButtonBox, typeButtonBox, typeButtonOptionsBox]);

        modalActionsBox.append([modalActionsCloseButton, modalActionsSaveButton]);

        modal.box.append([modalTitle, modalContentBox, modalActionsBox]);

        $('body').append([modal.box, modal.blocker]);
    }

    showFieldsByBtnType(modal, type) {
        switch(type) {
            case 'postback':
                modal.box.find('.phone-button').parent().hide();
                modal.box.find('.url-button').parent().hide();
                modal.box.find('.webview-url-button').parent().hide();
                modal.box.find('.webview-ratio-button').parent().hide();
                break;
            case 'phone':
                modal.box.find('.phone-button').parent().show();
                modal.box.find('.url-button').parent().hide();
                modal.box.find('.webview-url-button').parent().hide();
                modal.box.find('.webview-ratio-button').parent().hide();
                break;
            case 'web_url':
                modal.box.find('.phone-button').parent().hide();
                modal.box.find('.url-button').parent().show();
                modal.box.find('.webview-url-button').parent().hide();
                modal.box.find('.webview-ratio-button').parent().hide();
                break;
            case 'web_view':
                modal.box.find('.phone-button').parent().hide();
                modal.box.find('.url-button').parent().hide();
                modal.box.find('.webview-url-button').parent().show();
                modal.box.find('.webview-ratio-button').parent().show();
                break;
        }
    }

    isJson(str) {
        if (str === 1 || str === '1' || !str) {
            return false;
        } else {
            try {
                JSON.stringify(JSON.parse(str));
            } catch (e) {
                return false;
            }
            return true;
        }
    }

    addButtons(modal, cardBox, buttons=null) {
        if (buttons) {
            buttons.forEach((v, i)=> {
                buttons[i].id = joint.util.uuid();
            });
            this.buttonsResponse.elements.buttons = buttons.concat(this.buttonsResponse.elements.buttons);
        }
        this.buttonsResponse.elements.buttons.forEach(btn => {
            const cardButtonsBox2 = $('<div class="divButtonSlide"/>');
            const cardShowModalLink2 = $(`<a class="show_modal btnSlider" data-id="${btn.id}">${btn.title}</a>`);
            const cardDeleteButtonLink2 = $('<a class="deleteButtonSlider">×</a>');

            cardShowModalLink2.on('click', (e) => {
                const btnId = btn.id;
                this.currentButton = this.buttonsResponse.elements.buttons.findIndex(btn2 => btn2.id === btnId);

                modal.box.find('.title-button').val(btn.title);
                modal.box.find('.type-button').val(btn.type);

                this.showFieldsByBtnType(modal, btn.type);
                switch(btn.type) {
                    case 'phone':
                        modal.box.find('.phone-button').val(btn.value);
                        break;
                    case 'web_url':
                        modal.box.find('.url-button').val(btn.url);
                        break;
                    case 'web_view':
                        modal.box.find('.webview-url-button').val(btn.url);
                        modal.box.find('.webview-ratio-button').val(btn.webview_height_ratio);
                        break;
                }

                modal.show();
            });

            cardDeleteButtonLink2.on('click', (e) => {
                const btnId = cardShowModalLink2.data('id');
                this.buttonsResponse.elements.buttons = this.buttonsResponse.elements.buttons.filter(btn => btn.id !== btnId);
                $(e.target).parent().remove();
                this.buttonsCount--;
                this.saveButtonsReponse();
            });

            cardButtonsBox2.append([cardShowModalLink2, cardDeleteButtonLink2]);
            cardBox.append(cardButtonsBox2);
            this.buttonsCount++;
        });
        this.saveButtonsReponse();
    }

    getButtons(field, req, alert) {
        this.alertService = alert;
        this.field = field;
        const containerDiv = $('<div class="type_visual_box buttons_dyn" />');

        const cardBox = $('<div class="card"/>');
        const cardTitleInput = $('<textarea class="cardTitleSlide2 escape" placeholder="Title"></textarea>');
        const cardAddBtn = $('<button class="addButtonsSlider">+ botón </button>');
        const modal = this.modal.create({});
        this.createModal(modal);

        cardAddBtn.on('click', () => {
            if (this.buttonsCount < this.buttonsLimit) {
                const id = joint.util.uuid();
                const cardButtonsBox = $('<div class="divButtonSlide"/>');
                const cardShowModalLink = $(`<a class="show_modal btnSlider" data-id="${id}">Botón ${this.buttonsCount + 1}</a>`);
                const cardDeleteButtonLink = $('<a class="deleteButtonSlider">×</a>');
                const btnObj = {
                    id,
                    block_id: id,
                    title: '',
                    type: 'postback'
                };
                this.buttonsResponse.elements.buttons.push(btnObj);
                this.buttonsCount++;
                this.saveButtonsReponse();

                cardShowModalLink.on('click', () => {
                    const btnId = cardShowModalLink.data('id');
                    this.currentButton = this.buttonsResponse.elements.buttons.findIndex(btn => btn.id === btnId);
                    modal.box.find('.title-button').val(btnObj.title);
                    modal.box.find('.type-button').val(btnObj.type);
                    this.showFieldsByBtnType(modal, btnObj.type);
                    modal.show();
                });

                cardDeleteButtonLink.on('click', (e) => {
                    const btnId = cardShowModalLink.data('id');
                    this.buttonsResponse.elements.buttons = this.buttonsResponse.elements.buttons.filter(btn => btn.id !== btnId);
                    $(e.target).parent().remove();
                    this.buttonsCount--;
                    this.saveButtonsReponse();
                });

                cardButtonsBox.append([cardShowModalLink, cardDeleteButtonLink]);
                cardBox.append(cardButtonsBox);
            } else {
                this.alertService.warning(`Solo puedes tener un máximo de ${this.buttonsLimit} botones`);
            }
        });

        cardTitleInput.on('focusout', () => {
            this.buttonsResponse.elements.title = cardTitleInput.val();
            this.saveButtonsReponse();
        });

        cardBox.append([cardTitleInput, cardAddBtn]);

        const nameClass = field.attr('name').replace('#', '-');
        containerDiv.addClass(nameClass);

        containerDiv.append([cardBox, field]);

        this.buttonsCount = 0;

        if (req && req.hasOwnProperty('constraints') && req.constraints && typeof req.constraints === "object") {
            if (req.constraints.hasOwnProperty('type') && 'noOutputs' === req.constraints.type) {
                this.buttonsResponse.showOutputs = false;
            }
        }

        const value = field.val();
        if (value) {
            this.buttonsResponse = JSON.parse(value);
            cardTitleInput.val(this.buttonsResponse.elements.title);
            this.addButtons(modal, cardBox);
        } else  if (req && req.hasOwnProperty('constraints') && req.constraints && typeof req.constraints === "object") {
            const constraints = req.constraints;
            if (constraints.text.type === 'fixed') {
                cardTitleInput.attr('disabled', 'disabled');
            }
            if (constraints.buttons.type === 'fixed') {
                cardAddBtn.remove();
            }

            if (constraints.hasOwnProperty('text') && constraints.text.hasOwnProperty('value') && constraints.text.value) {
                this.buttonsResponse.elements.title = constraints.text.value;
                cardTitleInput.val(constraints.text.value);
            }

            this.addButtons(modal, cardBox, constraints.buttons.value);
        }

        return containerDiv;
    }
}
