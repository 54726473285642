<div class="list-customers">
    <form [formGroup]="customersForm" novalidate>
        <ng-select
            [searchable]="true"
            [clearable]="false"
            [loading]="loadingCustomers"
            [hideSelected]="true"
            loadingText="Cargando..."
            notFoundText="No hay elementos"
            (change)="changeCustomer($event)"
            formControlName="customer"
            class="select-customers"
            name="customerFormName" ngDefaultControl>
            <ng-option [value]="customer.name + '#' + customer.slug" *ngFor="let customer of customers">
                <img *ngIf="customer.image!==null" [src]="customer.image" width="30px" height="30px">
                <img *ngIf="customer.image===null" [src]="assets + 'img/user_default.svg'" width="30px" height="30px">
                {{customer.name}}
            </ng-option>
        </ng-select>
    </form>
    <div class="text-save">
        <span class="hidden">✓ {{translate.sections.customers.listCustomers.saved}}</span>
    </div>
</div>
