<section class="sectionFilters">
    <div class="wrapper-title">
        <h1 class="title">{{translate.sections.customers.filterCustomers.myFilters}}</h1>
        <ul class="actions">
            <li>
                <button class="btn-secondary-alternate" (click)="redirectTypes()">
                    <span>{{translate.sections.customers.filterCustomers.goTypeFilters}}</span>
                </button>
            </li>
            <li>
                <button class="btn-primary" (click)="openModal('popup-new-filter')">
                    <span>{{translate.sections.customers.filterCustomers.createNewFilter}}</span>
                    <i class="material-icons md-24">add_circle</i>
                </button>
            </li>
        </ul>
    </div>

    <div *ngIf="!existsFilters" class="emptyFilters">
        <h1>{{translate.sections.customers.filterCustomers.noFilters}}</h1>
        <h4>{{translate.sections.customers.filterCustomers.startMessage}}</h4>
    </div>

    <div *ngIf="existsFilters" class="boxFilters">
        <table class="main-table-content">
            <thead class="data-table-header">
                <tr class="data-table-row">
                    <td>{{translate.sections.customers.filterCustomers.name}}</td>
                    <td>{{translate.sections.customers.filterCustomers.type}}</td>
                    <td>{{translate.sections.customers.filterCustomers.mask}}</td>
                    <td>{{translate.sections.customers.filterCustomers.actions}}</td>
                </tr>
            </thead>
            <tbody class="data-table-content">
                <tr *ngFor="let filter of filters | paginate: { itemsPerPage: paginationFilters.pageSize, currentPage: paginationFilters.currentPage, totalItems: paginationFilters.totalItems, id: 'first' }"
                    class="data-table-row">
                    <td>
                        {{filter.name}}
                    </td>
                    <td>
                        {{filter.filter.displayName}}
                    </td>
                    <td>
                        {{filter.mask}}
                    </td>
                    <td>
                        <button class="btn-icon" (click)="openModal('popup-new-filter', filter)" title="Editar">
                            <i class="material-icons md-20">edit</i>
                        </button>
                        <button class="btn-icon" (click)="openModal('popup-filter-delete', filter.id)" title="Eliminar">
                            <i class="material-icons md-20">delete</i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <pagination-template #p2="paginationApi" (pageChange)="pageFiltersUpdate($event)" id="first">
            <ul class="pagination">
                <li [ngClass]="{'pagination-previous': true, 'disabled': p2.isFirstPage()}">
                    <a *ngIf="!p2.isFirstPage()" (click)="p2.previous()">
                        << {{translate.sections.customers.filterCustomers.previous}} </a>
                            <span *ngIf="p2.isFirstPage()">
                                <span>
                                    << {{translate.sections.customers.filterCustomers.previous}} </span>
                                </span>
                </li>

                <li *ngFor="let page of p2.pages" [class.current]="p2.getCurrent() === page.value">
                    <a (click)="p2.setCurrent(page.value)" *ngIf="p2.getCurrent() !== page.value">
                        <span>{{ page.label }}</span>
                    </a>
                    <span *ngIf="p2.getCurrent() === page.value">
                        <span>{{ page.label }}</span>
                    </span>
                </li>

                <li [ngClass]="{'pagination-next': true, 'disabled': p2.isLastPage()}">
                    <a *ngIf="!p2.isLastPage()" (click)="p2.next()">
                        {{translate.sections.customers.filterCustomers.next}} >>
                    </a>
                    <span *ngIf="p2.isLastPage()">
                        <span>{{translate.sections.customers.filterCustomers.next}} >></span>
                    </span>
                </li>
            </ul>
        </pagination-template>
    </div>
</section>

<!-- begin modal create filter -->
<jw-modal id="popup-new-filter">
    <a class="close_modal" (click)="closeModal('popup-new-filter');">×</a>
    <div class="createCampaign">
        <h2 class="title">{{translate.sections.customers.filterCustomers.newFilter}}</h2>

        <div>
            <div class="button-box">
                <label for="">{{translate.sections.customers.filterCustomers.selectTypeFilter}}</label>
                <button class="btn-secondary-invert" (click)="openModal('popup-filter-types')">
                    {{translate.sections.customers.filterCustomers.showOptions}}
                </button>
                <small *ngIf="!currentFilter?.filter?.displayName">{{translate.sections.customers.filterCustomers.noSelected}}</small>
                <small *ngIf="currentFilter?.filter?.displayName"> {{translate.sections.customers.filterCustomers.selected}}:
                    {{currentFilter?.filter?.displayName}}</small>
            </div>

            <div class="field-box">
                <label for="">{{translate.sections.customers.filterCustomers.name}}</label>
                <input [(ngModel)]="currentFilter.name" type="text" placeholder="Filtro 1">
            </div>

            <div class="field-box">
                <label for="">{{translate.sections.customers.filterCustomers.mask}}</label>
                <input [(ngModel)]="currentFilter.mask" type="text" placeholder="[[mask]]">
            </div>
        </div>


        <div class="modal-actions">
            <button (click)="closeModal('popup-new-filter')" class="btn-secondary">
                <span>{{translate.sections.customers.filterCustomers.cancel}}</span>
            </button>
            <button (click)="saveFilter()" class="btn-primary" [disabled]="!currentFilter?.filter?.displayName">
                <span>{{translate.sections.customers.filterCustomers.save}}</span>
            </button>
        </div>
    </div>

</jw-modal>
<!-- end modal create filter -->

<!-- begin modal filter types -->
<jw-modal id="popup-filter-types" [bodyStyles]="platformsBodyStyles">
    <a class="close_modal" (click)="closeModal('popup-filter-types');">×</a>
    <div class="createFilterType">
        <h2 class="title">{{translate.sections.customers.filterCustomers.selectTypeFilter}}</h2>

        <div class="filter-list-box">
            <div class="filter-item"
                *ngFor="let filterType of filterTypes  | paginate: { itemsPerPage: paginationFilterTypes.pageSize, currentPage: paginationFilterTypes.currentPage, totalItems: paginationFilterTypes.totalItems, id: 'second' }"
                (click)="selectFilter(filterType)" [ngClass]="{'active': filterType.id === currentFilter?.filter?.id}">
                <span class="filter-title">{{filterType.displayName}}</span>
                <br>
                <small class="filter-label">{{filterType.description}}</small>
            </div>
        </div>

        <div class="filter-paginate">
            <pagination-template #p="paginationApi" (pageChange)="pageFilterTypesUpdate($event)" id="second">
                <ul class="pagination">
                    <li [ngClass]="{'pagination-previous': true, 'disabled': p.isFirstPage()}">
                        <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
                            << </a>
                                <span *ngIf="p.isFirstPage()">
                                    <span>
                                        << </span>
                                    </span>
                    </li>

                    <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                            <span>{{ page.label }}</span>
                        </a>
                        <span *ngIf="p.getCurrent() === page.value">
                            <span>{{ page.label }}</span>
                        </span>
                    </li>

                    <li [ngClass]="{'pagination-next': true, 'disabled': p.isLastPage()}">
                        <a *ngIf="!p.isLastPage()" (click)="p.next()">
                            >>
                        </a>
                        <span *ngIf="p.isLastPage()">
                            <span>>></span>
                        </span>
                    </li>
                </ul>
            </pagination-template>
        </div>


        <div class="modal-actions">
            <button (click)="saveFilterType()" class="btn-primary">
                <span>{{translate.sections.customers.filterCustomers.continue}}</span>
            </button>
        </div>
    </div>

</jw-modal>
<!-- end modal filter types -->

<!-- begin modal confirm delete -->
<jw-modal id="popup-filter-delete">
    <a class="close_modal" (click)="closeModal('popup-filter-delete');">×</a>
    <h3>{{translate.sections.customers.filterCustomers.confirmationMessage}}</h3>

    <div class="modal-actions">
        <button (click)="closeModal('popup-filter-delete')" class="btn-secondary">{{translate.sections.customers.filterCustomers.cancel}}</button>
        <button (click)="deleteFilter()" class="btn-primary">{{translate.sections.customers.filterCustomers.confirm}}</button>
    </div>

</jw-modal>
<!-- end modal confirm delete -->
