<!-- begin modal confirm delete -->
<jw-modal id="popup-confirmDelete">
    <a class="close_modal" (click)="closeModal('popup-confirmDelete')">×</a>
    <h3>{{translate.sections.campaigns.statusApproval.confirmMessage}}</h3>

    <div class="modal-actions">
        <button (click)="functionCancel('popup-confirmDelete')" class="btn-secondary">
            {{translate.sections.campaigns.statusApproval.cancel}}
        </button>
        <button (click)="deleteMessage()" class="btn-primary">{{translate.sections.campaigns.statusApproval.confirm}}</button>
    </div>
</jw-modal>
<!-- end modal confirm delete -->

<!-- begin modal preview message -->
<jw-modal id="popup-preview">
    <a class="close_modal" (click)="closeModal('popup-preview')">×</a>
    <h3>{{translate.sections.campaigns.statusApproval.preview}}</h3>
    <app-preview-message [dataMessage]="dataMessageG"></app-preview-message>
</jw-modal>
<!-- end modal confirm delete -->

<section class="wrapperTemplates">
    <div class="wrapper-title sub">
        <h3 class="title">
            {{translate.sections.campaigns.statusApproval.statusMessage}}
        </h3>

        <ul class="actions ulChange">
            <li>
                <button (click)="redirectTemplate()" class="btn-secondary-alternate">
                    <span>{{translate.sections.campaigns.statusApproval.newTemplate}}</span>
                    <i class="material-icons md-20">add_circle</i>
                </button>
            </li>
        </ul>
    </div>

    <div *ngIf="!existsMessages" class="emptyMessages">
        <img src="/assets/img/empty_state_campaigns.png" alt="Campaigns" />
        <h1>{{translate.sections.campaigns.statusApproval.noMessages}}</h1>
        <h4>{{translate.sections.campaigns.statusApproval.startMessage}}</h4>
    </div>

    <div class="divTemplates" *ngIf="existsMessages">
        <div class="loadTemplates">
            <div class="tabs">
                <ul >
                    <li  *ngIf="channelSmooch == 'SMOOCH_SMS'" ><a><span></span></a></li>
                    <li  *ngIf="channelSmooch != 'SMOOCH_SMS'" >
                        <a (click)="showTab('ALL')" [ngClass]="{ active: tabActive == 'ALL' }">
                            {{translate.sections.campaigns.statusApproval.all}}
                            <span *ngIf="tabActive == 'ALL'" class="number">{{
                                total
                                }}</span>
                        </a>
                        <!-- <a class="active"> Todas </a> -->
                    </li>


                    <li  *ngIf="channelSmooch != 'SMOOCH_SMS'" >
                        <a (click)="showTab('NOT_SENT')" [ngClass]="{ active: tabActive == 'NOT_SENT' }">
                            {{translate.sections.campaigns.statusApproval.draftCopy}}
                            <span *ngIf="tabActive == 'NOT_SENT'" class="number">{{ total }}</span>
                        </a>
                    </li>

                    <li  *ngIf="channelSmooch != 'SMOOCH_SMS'" >
                        <a (click)="showTab('APPROVED')" [ngClass]="{ active: tabActive == 'APPROVED' }">
                            {{translate.sections.campaigns.statusApproval.aprroved}}
                            <span *ngIf="tabActive == 'APPROVED'" class="number">{{ total }}</span>
                        </a>
                    </li>

                    <li  *ngIf="channelSmooch != 'SMOOCH_SMS'" >
                        <a (click)="showTab('PENDING')" [ngClass]="{ active: tabActive == 'PENDING' }">
                            {{translate.sections.campaigns.statusApproval.pending}}
                            <span *ngIf="tabActive == 'PENDING'" class="number">{{ total }}</span>
                        </a>
                    </li>
                    <li  *ngIf="channelSmooch != 'SMOOCH_SMS'" >
                        <a (click)="showTab('REJECTED')" [ngClass]="{ active: tabActive == 'REJECTED' }">
                            {{translate.sections.campaigns.statusApproval.rejected}}
                            <span *ngIf="tabActive == 'REJECTED'" class="number">{{ total }}</span>
                        </a>
                    </li>
                </ul>

                <div class="buttons">
                    <!-- <input type="text" placeholder="Nombre" /> -->

                    <div class="divInput">
                        <i class="material-icons md-20 search-icon">search</i>
                        <input class="inputSearchName" style="
                                width: 100%;
                                text-indent: 25px;
                                padding-left: 35px;
                            " type="text" placeholder="Nombre"  (keyup.enter)="searchByName($event)"/>
                    </div>

                    <select id="selectDateT" #selectDateT (change)="searchByDate(selectDateT.value)" class="selectDate"  *ngIf="channelSmooch != 'SMOOCH_SMS'">
                        <!-- <option value="">Fecha</option> -->
                        <option value="">{{translate.sections.campaigns.statusApproval.select}}</option>
                        <option *ngFor="let date of arrayMonthsConverted" value="{{date}}">
                            {{date}}</option>
                    </select>
                    <select id="selectDateT" #selectDateT (change)="searchByDateSms(selectDateT.value)" class="selectDate"  *ngIf="channelSmooch == 'SMOOCH_SMS'">
                        <!-- SMOOCH_SMS CHANNEL -->
                        <option value="">{{translate.sections.campaigns.statusApproval.select}}</option>
                        <option *ngFor="let date of arrayMonthsConverted" value="{{date}}">
                            {{date}}</option>
                    </select>
                </div>
            </div>

            <div class="divButtons2" style="display: none">
                <div class="buttons2">
                    <div class="divInput">
                        <i class="material-icons md-20 search-icon">search</i>
                        <input class="inputSearchName" style="
                                width: 100% !important;
                                text-indent: 25px;
                                padding-left: 35px;
                            " type="text" placeholder="Nombre" (keyup.enter)="searchByName($event)" />
                    </div>

                   <!--  <select class="selectDate">
                        <option value="">Fecha</option>
                    </select> -->
                    <select id="selectDate" #selectDate (change)="searchByDate(selectDate.value)" class="selectDate">
                        <!-- <option value="">Fecha</option> -->
                        <option value="">{{translate.sections.campaigns.statusApproval.select}}</option>
                        <option *ngFor="let date of arrayMonthsConverted" value="{{date}}">
                            {{date}}</option>
                    </select>
                </div>
            </div>

            <section class="md-ui component-data-table">
                <div class="main-table-wrapper">
                    <table class="main-table-content">
                        <thead class="data-table-header">
                            <tr class="data-table-row">
                                <td>{{translate.sections.campaigns.statusApproval.name}}</td>
                                <td>{{translate.sections.campaigns.statusApproval.category}}</td>
                                <td>{{translate.sections.campaigns.statusApproval.status}}</td>
                                <td>{{translate.sections.campaigns.statusApproval.language}}</td>
                                <td>{{translate.sections.campaigns.statusApproval.lastUpdate}}</td>

                                <td>{{translate.sections.campaigns.statusApproval.actions}}</td>
                            </tr>
                        </thead>
                        <tbody class="data-table-content">
                            <tr *ngFor="
                                    let message of dataMessage
                                        | paginate
                                            : {
                                                  itemsPerPage: perPage,
                                                  currentPage: page,
                                                  totalItems: total
                                              }; let indexOfelement=index;
                                " class="data-table-row">
                                <!-- pageBoundsCorrection: pageChanged() -->
                                <td>
                                    {{ message.name }}
                                </td>
                                <td>
                                    {{ message.category }}
                                </td>

                                <td>
                                    <span [class]="'statusChip ' + message.authorizationStatus">
                                        {{ message.authorizationStatus }}
                                    </span>
                                </td>
                                <td>
                                    <span class="elipsis">
                                        {{
                                        getLanguageType(
                                        message.languageCode
                                        )
                                        }}
                                    </span>
                                </td>
                                <td>
                                    <span class="">{{
                                        convertDate(message.lastModifiedAt)
                                        }}</span>
                                </td>

                                <td>
                                    <button  (click)="copy(message?.id)" class="btn-icon" data-tippy-content="Copiar">
                                        <i class="material-icons md-20">
                                            content_copy
                                        </i>
                                        <!-- <i class="material-icons md-20">event</i> -->
                                    </button>
                                    <button  (click)="openModal('popup-preview', message.id)" class="btn-icon">
                                        <i class="material-icons md-20">
                                            visibility
                                        </i>
                                        <!-- <i class="material-icons md-20">event</i> -->
                                    </button>

                                    <button [disabled]="isDisabledEdit(message.authorizationStatus)" (click)="goto(message.id)" class="btn-icon" title={{translate.sections.campaigns.statusApproval.edit}}>
                                        <i class="material-icons md-20">edit</i>
                                    </button>

                                    <button (click)="forceApprove(message, indexOfelement)" title={{translate.sections.campaigns.statusApproval.forceApproved}} class="btn-icon" *ngIf="showForceApprove()">
                                        <i class="material-icons md-20">verified_user</i>
                                    </button>

                                    <button  (click)="openModal('popup-confirmDelete', message.id)" title={{translate.sections.campaigns.statusApproval.delete}} class="btn-icon">
                                        <i class="material-icons md-20">delete</i>
                                    </button>

                                    <button [disabled]="isDisabled(message.authorizationStatus, 'Aprobada')" class="btn-icon" title={{translate.sections.campaigns.statusApproval.sendNow}} (click)="goToSendCampaign(message.id)">
                                        <i class="material-icons md-20">send</i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <footer class="main-table-footer">
                    <pagination-template #p="paginationApi" (pageChange)="pageUpdate($event)">
                        <ul class="pagination">
                            <li [ngClass]="{
                                    'pagination-previous': true,
                                    disabled: p.isFirstPage()
                                }">
                                <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
                                    << {{translate.sections.campaigns.statusApproval.previous}} </a>
                                        <span *ngIf="p.isFirstPage()">
                                            <span>
                                                << {{translate.sections.campaigns.statusApproval.previous}} </span>
                                            </span>
                            </li>

                            <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                    <span>{{ page.label }}</span>
                                </a>
                                <span *ngIf="p.getCurrent() === page.value">
                                    <span>{{ page.label }}</span>
                                </span>
                            </li>

                            <li [ngClass]="{
                                    'pagination-next': true,
                                    disabled: p.isLastPage()
                                }">
                                <a *ngIf="!p.isLastPage()" (click)="p.next()">
                                    {{translate.sections.campaigns.statusApproval.next}} >>
                                </a>
                                <span *ngIf="p.isLastPage()">
                                    <span>{{translate.sections.campaigns.statusApproval.next}} >></span>
                                </span>
                            </li>
                        </ul>
                    </pagination-template>
                </footer>
            </section>
        </div>
    </div>
</section>
