import { Component, OnInit } from '@angular/core';
import { isArray } from 'lodash';
import { ModalService } from 'src/components/modal';
import { AlertService } from 'src/services/alert.service';
import { WabaService } from 'src/services/waba.service';
// import { LoaderService } from "src/services/loader.service";

@Component({
    selector: 'app-system-users',
    templateUrl: './system-users.component.html',
    styleUrls: ['./system-users.component.scss']
})
export class SystemUsersComponent implements OnInit {

    currentUser: any;

    systemUsers: any;
    assignedUsers: any;
    systemUsersPage = 0;
    assignedUsersPage = 0;
    systemUsersTotal = 0;
    assignedUsersTotal = 0;
    systemUsersPerPage = 10;
    assignedUsersPerPage = 10;

    constructor(
        // private loaderService: LoaderService,
        private alertService: AlertService,
        private modalService: ModalService,
        private wabaService: WabaService,
    ) { }

    ngOnInit() {
        this.wabaService.getSystemUserIds().subscribe(
            (data) => {
                // this.loaderService.close();
                console.log(data);
                // this.assignedUsers = data.data;
                this.systemUsers = [
                    {
                      "id": "1972555232742222",
                      "name": "My System User",
                      "role": "EMPLOYEE"
                    }
                ];
            },
            (error) => {
                // this.loaderService.close();
                console.error(error);
                this.alertService.error("No se ha podido obtener los usuarios de sistema");
            }
        );

        this.wabaService.getAssignedUsers().subscribe(
            (data) => {
                // this.loaderService.close();
                // this.systemUsers = data.data;
                this.assignedUsers = [
                    {
                      "id": "1972385232742142",
                      "name": "Anna Flex",
                      "tasks": [
                        "MANAGE"
                      ]
                    },
                    {
                      "id": "1972385232752545",
                      "name": "Jasper Brown",
                      "tasks": [
                        "DEVELOP"
                      ]
                    }
                  ];
            },
            (error) => {
                // this.loaderService.close();
                console.error(error);
                this.alertService.error("No se ha podido obtener los usuarios asignados");
            }
        );
    }

    getTasks(tasks) {
        if (Array.isArray(tasks)){
            return tasks.join(', ');
        }
        return '';
    }

    assignUser(permission) {
        const userId = this.currentUser.id;

        this.wabaService.addSystemUsersToWhatsappBusinessAccount(userId, permission).subscribe(
            (data) => {
                console.log(data);
            },
            (error) => {
                console.error(error);
                this.alertService.error("No se ha podido asignar el usuario");
            }
        );
    }

    closeModal(id) {
        this.modalService.close(id);
    }

    openModal(id: string, user: any = null) {
        this.modalService.open(id);
        if (user) {
            this.currentUser = user;
        }
    }

}
