import { Injectable, SecurityContext } from '@angular/core';
import { Entity } from 'src/models/entity';
import * as joint from '../../../vendor/rappid';
import { AlertService } from '../alert.service';
import { ModalService } from './modal.service';
import { DomSanitizer } from "@angular/platform-browser";

// declare var $: any;
declare var lastSelectionPhrase: any;

@Injectable({ providedIn: 'root' })
export class NlpExampleService {

    private alertService: AlertService;
    private modal: ModalService;
    private modal2: ModalService;
    private domSanitizer: DomSanitizer;
    fullEntities = [];
    customerSlug;
    cellId;

    constructor(customerSlug, cellId, alert, domSanitizer) {
        this.alertService = alert;
        this.customerSlug = customerSlug;
        this.cellId = cellId;
        this.modal = new ModalService();
        this.modal2 = new ModalService();
        this.phraseInitialize();
        this.domSanitizer = domSanitizer;
    }

    private phraseInitialize() {
        lastSelectionPhrase = {
            parent: null,
            selection: null,
            node: null,
            start: null,
            end: null,
            highlight: null,
            type: null
        }
    }

    private visualLength(html) {
        var ruler = $(".ruler");
        ruler.html(html);
        return ruler.outerWidth();
    }

    private createResponseNlpString(intentions) {
        const responseNlp = {
            intentions,
            type: 'FaqNlp',
            showOutputs: true
        };

        return JSON.stringify(responseNlp);
    }

    private saveModalEvent(saveBtn, field, modal) {
        saveBtn.on('click', (event) => {
            const nameIntention = $(event.target).parent().parent().find('.name-intention');
            const examplesIntention = $(event.target).parent().parent().find('.examples-list-box')[0].innerHTML;
            const examplesEvaluationIntention = $(event.target).parent().parent().find('.examples-evaluation-list-box')[0].innerHTML;
            const examples = this.htmlToArrayExamples(examplesIntention);
            const examplesEvaluation = this.htmlToArrayExamples(examplesEvaluationIntention);
            const entities = this.getEntitiesFromStr(examples.join(','));

            const intention = {
                name: nameIntention.val(),
                ejemplos: examples,
                ejemplosEval: examplesEvaluation,
                entidades: entities
            };

            const responseNlpStr = this.createResponseNlpString([intention]);
            field.val(responseNlpStr);

            modal.hide();
        });
    }

    private cancelModalEvent(cancelBtn, modal) {
        cancelBtn.on('click', () => {
            modal.hide();
            this.phraseInitialize();
        });
    }

    private getTextOffset(parent, index) {
        if (parent.nodeType === 3)
            return {'node': parent, 'offset': index};
        var e = parent.childNodes[0], i = index, prev = null;
        while (1) {
            while (e.nodeType !== 3 || e === prev)
                if (e.childNodes && e.childNodes.length)
                    e = e.childNodes[0];
                else if (e.nextSibling)
                    e = e.nextSibling;
                else {
                    while (!e.nextSibling)
                        if (!e.parentNode || e.parentNode === parent)
                            throw RangeError('Index out of range');
                        else
                            e = e.parentNode;
                    e = e.nextSibling;
                }
            if (e.data.length < i)
                i -= e.data.length, prev = e;
            else
                return {'node': e, 'offset': i};
        }
    }

    private highlight(node, start, end, type="entity") {
        const id = joint.util.uuid();
        var r, o, hi;
        r = document.createRange()
        o = this.getTextOffset(node, start);
        r.setStart(o.node, o.offset);
        o = this.getTextOffset(node, end);
        r.setEnd(o.node, o.offset);
        hi = document.createElement('span');
        hi.classList.add(type + '-chip');
        hi.id = id;
        hi.contentEditable = false;
        hi.appendChild(r.extractContents());
        r.insertNode(hi);
        r.detach();

        const dataWordAtt = document.createAttribute("data-word");
        dataWordAtt.value = hi.innerText;
        hi.setAttributeNode(dataWordAtt);
        lastSelectionPhrase.highlight = hi;
        lastSelectionPhrase.type = type;
    }

    private htmlToArrayExamples(htmlExamples) {
        const re = /<span class="entity-chip"[^>]*>(.*?)<\/span>/g;
        const matches = [...(htmlExamples.matchAll(re))];
        for (let index = 0; index < matches.length; index++) {
            const match = matches[index];
            const tempSpan = $(match[0]);
            const entity = tempSpan.data('entity');
            const word = tempSpan.data('word');
            htmlExamples = htmlExamples.replace(match[0], `[${word}](${entity})`);
        }
        if (htmlExamples.indexOf('<p') >= 0) {
            htmlExamples = htmlExamples.replace(/<p[^>]*>/g, '\n<p>');
            htmlExamples = htmlExamples.replace(/<p[^>]*><\/p>/g, '');
            htmlExamples = htmlExamples.replace(/<p[^>]*>/g, '');
            htmlExamples = htmlExamples.replaceAll('<\/p>', '\n');
        } else {
            htmlExamples += '\n';
        }
        const examples =  htmlExamples.split('\n');
        const regex = /(\[[^\[]+\])$|(\[[^\[]+\])[^\(]|(\[[^\[]+\]\(\))|(\[\]\(@[^\)]+\))|[^\]](\(@[^\)]+\))|^(\(@[^\)]+\))/g;
        const ex = examples.map((item) => {
            let str = item.replace(/<.*[^>]*>(.*)<\/.*[^>]*>/g, '$1').replace(/<br?\/?>/g, '').replace('&nbsp;', ' ');
            let m;

            while ((m = regex.exec(str)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                m.forEach((match, groupIndex) => {
                    if (match) {
                        str = str.replace(match, '');
                    }
                });
            }
            return str;
        });
        return ex.filter((item)=>item);
    }

    private arrayToHtmlExamples(examplesArr) {
        let result = '';
        const re = /\[([^\]]*)\]\((@[^\)]*)\)/g;
        for (let index = 0; index < examplesArr.length; index++) {
            let example = examplesArr[index];
            const matches = [...(example.matchAll(re))];
            for (let index = 0; index < matches.length; index++) {
                const id = joint.util.uuid();
                const match = matches[index][0];
                const word = matches[index][1]
                const entity = matches[index][2];
                const idSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, id);
                const wordSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, word);
                const entitySanitized = this.domSanitizer.sanitize(SecurityContext.HTML, entity);
                example = example.replace(match, `<span class="entity-chip" id="${idSanitized}" data-word="${wordSanitized}" data-entity="${entitySanitized}" contenteditable="false">${wordSanitized}: ${entitySanitized}</span>`);
            }
            result += `<p>${example}</p>`;
        }
        return result;
    }

    private getEntitiesFromStr(str) {
        const re = /\((@[^\)]*)\)/g;
        const matches = [...(str.matchAll(re))];
        const entities = [];
        for (let index = 0; index < matches.length; index++) {
            const match = matches[index][1].replace('(', '').replace(')', '');
            const res = entities.find(entityAux => entityAux === match);
            if (!res) {
                entities.push(match);
            }
        }
        return entities;
    }

    private selectEntityEvent(entityItem, entity) {
        entityItem.on('click', (event)=> {
            this.highlight(lastSelectionPhrase.node, lastSelectionPhrase.start, lastSelectionPhrase.end, lastSelectionPhrase.type);
            const dataEntityAtt = document.createAttribute("data-entity");
            dataEntityAtt.value = entity;
            lastSelectionPhrase.highlight.setAttributeNode(dataEntityAtt);
            lastSelectionPhrase.highlight.innerText += ': ' + entity
            $(event.target).parent().parent().parent().remove();
            this.phraseInitialize();
        });
    }

    private cancelNewEntityEvent(entityCancelBtn, modal2) {
        entityCancelBtn.on('click', (e) => {
            modal2.hide();
        });
    }
    private saveNewEntityEvent(entitySaveBtn, modal2) {
        entitySaveBtn.on('click', (e) => {
            const fullBox = $(e.target).parents('.new-entity-box');
            let entityName = fullBox.find('.entity-name').val().toString();
            if (entityName.indexOf('@') !== 0) {
                entityName = '@' + entityName;
            }
            const entity: Entity = {
                id: joint.util.uuid(),
                editing: false,
                entityType: 'EXTRACTOR',
                groups: [],
                groupsTxt: '',
                main: false,
                name: entityName,
                regex: ['##entidad'],
                regexTxt: '##entidad',
                state: 'trained',
                type: 'entity'
            };
            const entitiesStr = $('.nlp_entities').val().toString();
            let entities = [];
            if (entitiesStr) {
                entities = JSON.parse(entitiesStr);
                entities.push(entity);
            }
            this.fullEntities.push(entity.name);
            $('.nlp_entities').val(JSON.stringify(entities));
            $('.save_diagram').trigger( "click" );
            $('.entities-list').empty();
            this.setEntities($('.entities-list'));
            modal2.hide();
        });
    }

    private createNewEntityBox(modal2) {
        $('.new-entity-box').remove();
        const entityBox = $('<div class="new-entity-box" />');
        const entityTitle = $('<h2 class="title">Nueva entidad</h2>');
        const entityFullBox = $('<div class="full" />');
        const entityFieldBox1 = $('<div class="field-box" />');
        const entityNameLabel = $('<label for="">Nombre</label>');
        const entityNameInput = $('<input type="text" class="entity-name" />');
        const entityActionsBox = $('<div class="modal-actions" />');
        const entitySaveBtn = $('<button class="btn-primary">Guardar entidad</button>');
        const entityCancelBtn = $('<button class="btn-secondary">Cancelar</button>');

        this.saveNewEntityEvent(entitySaveBtn, modal2);
        this.cancelNewEntityEvent(entityCancelBtn, modal2);

        entityActionsBox.append([entityCancelBtn, entitySaveBtn]);
        entityFieldBox1.append([entityNameLabel, entityNameInput]);
        entityFullBox.append(entityFieldBox1);
        entityBox.append([entityTitle, entityFullBox, entityActionsBox]);

        modal2.box.append(entityBox);
        modal2.box.css({
            'width': '440px',
            'zIndex': '7',
            'maxHeight': 'initial'
        });
        modal2.blocker.css('zIndex', '6');
        modal2.show();
    }

    private newEntityEvent(entityNewAction, modal2) {
        entityNewAction.on('click', (e) => {
            this.createNewEntityBox(modal2);
        });
    }

    private getEntities(type, field) {
        let allEntities = [];
        let entities = [];
        const entitiesStr = $('.nlp_entities').val().toString();
        const value = field.val();

        if(entitiesStr) {
            allEntities = JSON.parse(entitiesStr);
            for (let index = 0; index < allEntities.length; index++) {
                const entity: Entity = allEntities[index];
                if (entity.type === type) {
                    entities.push(entity.name);
                }
            }
        }

        if (value) {
            const phrase = JSON.parse(value);
            const entities2 = phrase.intentions[0].entidades;
            entities = [...new Set([...entities, ...entities2])];
            this.fullEntities = entities;
        }

        return entities;
    }

    private setEntities(entitiesList) {
        this.fullEntities.forEach(entity => {
            const entityItem = $(`<li class="entity-item">${entity}</li>`);
            this.selectEntityEvent(entityItem, entity);
            entitiesList.append(entityItem);
        });
    }

    private cancelEntityEvent(entityCancelAction) {
        entityCancelAction.on('click', (event) => {
            $(event.target).parents('.example-options-box').remove();
        });
    }

    private manageModalEntities(textarea, field, type, modal2) {
        $('.example-options-box').remove();
        const fieldBox = textarea.parent();
        const exampleOptionsBox = $('<div class="example-options-box mako" />');
        const optionSelect = $('<select class="example-options"><option value="entity">Entidades</option></select>');
        const entitiesBox = $('<div class="entities-box" />');
        const entitiesList = $('<ul class="entities-list" />');
        const entityActions = $('<div class="actions" />');
        const entityCancelAction = $('<button class="btn-secondary" />');
        const entityCancelText = $('<span>Cancelar</span>');
        const entityNewAction = $('<button class="btn-primary" />');
        const entityNewText = $('<span>+ Entidad</span>');

        this.fullEntities = this.getEntities(type, field);
        this.setEntities(entitiesList);
        this.cancelEntityEvent(entityCancelAction);
        this.newEntityEvent(entityNewAction, modal2);

        entityCancelAction.append(entityCancelText);
        entityNewAction.append(entityNewText);
        entityActions.append([entityCancelAction, entityNewAction]);
        entitiesBox.append([entitiesList, entityActions]);
        exampleOptionsBox.append([optionSelect, entitiesBox]);

        fieldBox.append(exampleOptionsBox);
    }

    private setPositionModal(textarea, type, field, modal2) {
        const selection = lastSelectionPhrase.selection;
        let positionKey = selection.anchorOffset;
        if (selection.anchorOffset > selection.focusOffset) {
            positionKey = selection.focusOffset;
        }
        // const text = this.markupToString(textarea.html().toString());
        const text = selection.anchorNode.textContent;
        if (
            positionKey === 0 ||
            text.slice(positionKey - 1, positionKey) === ' ' ||
            text.slice(positionKey - 1, positionKey) === ' '
        ) {
            let position = $(selection.anchorNode.parentElement).offset();
            const visulaLengthAtSign = this.visualLength(text.slice(0, positionKey));
            // const left = position.left - (($('body').outerWidth() - $('.modal2').outerWidth()) / 2);
            const top = position.top - ($('body').outerHeight() - $('.modal2').outerHeight()) / 2;

            this.manageModalEntities(textarea, field, type, modal2);
            const modalMarginLeft = 48;
            const centerOptionsBox = 105;
            const textareaMarginLeft = 12;

            $('.example-options-box').css({
                top: top + 40,
                left: visulaLengthAtSign - centerOptionsBox + modalMarginLeft + textareaMarginLeft
            });
        }
    }

    private selectElementContents(el) {
        const sel = window.getSelection();
        const range = sel.getRangeAt(0);
        if (range.startOffset !== range.endOffset) {
            lastSelectionPhrase = {
                parent: el,
                selection: sel,
                node: sel.focusNode,
                start: range.startOffset,
                end: range.endOffset
            };
        }
    }

    private examplesSelectionEvent(textarea) {

        // textarea.on('mousedown', (e) => {
        //     this.selectElementContents(textarea.get(0));
        // });

        textarea.on('mouseup', (e) => {
            this.selectElementContents(textarea.get(0));
        });
    }

    private addEntitySlotEvent(btn, type, field, modal2) {
        btn.on('click', (e) => {
            // this.highlight(lastSelectionPhrase.node, lastSelectionPhrase.start, lastSelectionPhrase.end, type);
            this.setPositionModal($(lastSelectionPhrase.parent), type, field, modal2);

        });
    }

    private manageModalAddIntention(modal, modal2, field) {
        const examplesModalBox = $('<div class="add-intention" />');
        const examplesModalTitle = $('<h2 class="title">Frase</h2>');
        const examplesModalFullBox = $('<div class="full" />');
        const examplesModalFieldBox = $('<div class="field-box" />');
        const examplesModalNameLabel = $('<label for="">Nombre</label>');
        const examplesModalNameInput = $('<input type="text" class="name-intention" />');
        const examplesModalFieldBox2 = $('<div class="field-box" />');
        const examplesModalExamplesLabel = $('<label for="">Ejemplos para entrenamiento</label>');
        const examplesModalExamplesEntityBtn = $('<button class="btn-base entity">+ Entidad</button>');
        // const examplesModalExamplesSlotBtn = $('<button class="btn-base slot">+ Memoria</button>');
        // const examplesModalTextarea = $('<textarea hidden class="examples-list" />');
        const examplesModalTextareaBox = $('<div contenteditable="true" class="examples-list-box" />');
        const examplesModalFieldBox3 = $('<div class="field-box" />');
        const examplesEvaluationModalExamplesLabel = $('<label for="">Ejemplos para evaluación</label>');
        const examplesEvaluationModalTextareaBox = $('<div contenteditable="true" class="examples-evaluation-list-box" />');
        const examplesModalActionsBox = $('<div class="modal-actions" />');
        const examplesModalCancelBtn = $('<button class="btn-secondary">Cancelar</button>');
        const examplesModalTrainingLogBtn = $('<button class="btn-secondary">Registro de sistema</button>');
        const examplesModalSaveBtn = $('<button type="submit" class="btn-primary">Guardar frase</button>');

        this.cancelModalEvent(examplesModalCancelBtn, modal);
        this.saveModalEvent(examplesModalSaveBtn, field, modal);

        examplesModalTrainingLogBtn.on('click', () => {
            this.getDesambiguator(modal, examplesModalBox, examplesModalTextareaBox);
            examplesModalTrainingLogBtn.attr('disabled', 'true');
        });

        const value = field.val();

        if (value) {
            const phrase = JSON.parse(value);
            const intention = phrase.intentions[0];
            examplesModalNameInput.val(intention.name);
            const examplesHtml = this.arrayToHtmlExamples(intention.ejemplos);
            if(!intention.hasOwnProperty('ejemplosEval')){
                intention.ejemplosEval = [];
            }
            const examplesUsrHtml = this.arrayToHtmlExamples(intention.ejemplosEval);
            // deepcode ignore DOMXSS: <Estan sanitizados los valores con los que se genera en la función arrayToHtmlExamples>
            examplesModalTextareaBox.html(examplesHtml);
            // deepcode ignore DOMXSS: <Estan sanitizados los valores con los que se genera en la función arrayToHtmlExamples>
            examplesEvaluationModalTextareaBox.html(examplesUsrHtml);
        }

        examplesModalActionsBox.append([examplesModalCancelBtn, examplesModalTrainingLogBtn, examplesModalSaveBtn]);

        // examplesModalNameInput.val(intention?.name);
        examplesModalFieldBox.append([examplesModalNameLabel, examplesModalNameInput]);

        this.examplesSelectionEvent(examplesModalTextareaBox);
        examplesModalTextareaBox.on('paste', (e) => {
            e.preventDefault();
            const textHtml = e.originalEvent['clipboardData'].getData('text/HTML');
            const textPlain = e.originalEvent['clipboardData'].getData('text/plain');
            let text = '';
            let arr;
            if (textHtml.indexOf('<') > 0) {
                arr = this.htmlToArrayExamples(textHtml);
            } else {
                arr = textPlain.split('\n');
            }
            text = this.arrayToHtmlExamples(arr);
            document.execCommand("insertHTML", false, text);
        });
        examplesEvaluationModalTextareaBox.on('paste', (e) => {
            e.preventDefault();
            const textHtml = e.originalEvent['clipboardData'].getData('text/HTML');
            const textPlain = e.originalEvent['clipboardData'].getData('text/plain');
            let text = '';
            let arr;
            if (textHtml.indexOf('<') > 0) {
                arr = this.htmlToArrayExamples(textHtml);
            } else {
                arr = textPlain.split('\n');
            }
            text = this.arrayToHtmlExamples(arr);
            document.execCommand("insertHTML", false, text);
        });
        // examplesModalTextarea.val(intention.ejemplos.join('\n'));
        document.execCommand("DefaultParagraphSeparator", false, "p");
        // examplesModalTextareaBox.html('<p>' + intention.ejemplos.join('</p><p>') + '</p>');

        this.addEntitySlotEvent(examplesModalExamplesEntityBtn, 'entity', field, modal2);
        // this.addEntitySlotEvent(examplesModalExamplesEntityBtn, 'slot');

        examplesModalFieldBox2.append([
            examplesModalExamplesLabel,
            examplesModalExamplesEntityBtn,
            // examplesModalExamplesSlotBtn,
            examplesModalTextareaBox,
        ]);
        examplesModalFieldBox3.append([
            examplesEvaluationModalExamplesLabel,
            examplesEvaluationModalTextareaBox,
        ]);

        examplesModalFullBox.append([examplesModalFieldBox, examplesModalFieldBox2, examplesModalFieldBox3]);
        examplesModalBox.append([examplesModalTitle, examplesModalFullBox, examplesModalActionsBox]);

        modal.box.append(examplesModalBox);
        modal.box.css('width', '540px');
        modal.box.css('max-height', 'fit-content');
        modal.show();
    }

    getDesambiguator(modal, examplesModalBox, examplesModalTextareaBox) {
        const user = localStorage.getItem('id');
        const pass = localStorage.getItem('token');
        const auth = btoa(`${user}:${pass}`);

        fetch(`/coreapi/v2/flowSentence/disambiguator/${this.cellId}`, {
            method: 'GET',
            headers: {
                'Auth-Type': localStorage.getItem('Auth-Type'),
                Authorization: 'Basic ' + auth,
                Slug: this.customerSlug
            }
        })
            .then(response => response.json())
            .then(response => {
                if (!response.json) {
                    throw new Error("al cargar el desambiguador");
                } else {
                    modal.box.css('width', '1000px');
                    examplesModalBox.addClass('desa');
                    const desambiguatorBox = $('<div class="desambiguator" />');
                    const desambiguatorLabel = $(`<span class="label">Ejemplos para entrenamiento</span>`);
                    const desambiguatorList = $('<ul class="list" />');
                    const json = JSON.parse(response.json);
                    const examples = json.ExamplesTag;
                    const incidentCount = json.incidentCount;

                    examples.forEach((example, index) => {
                        const desambiguatorItem = $('<li class="item" />');
                        const percentSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, (incidentCount[index]*100).toFixed(2));
                        const desambiguatorPercent = $(`<span class="percent">${percentSanitized}% de atención</span>`);
                        const desambiguatorPhraseSpan = $('<span class="phrase"></span>');
                        const desambiguatorPhrase = $(this.arrayToHtmlExamples([example]));
                        const indexSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, index);
                        const desambiguatorButton = $(`<button class="add-phrase" data-index="${indexSanitized}"><i class="material-icons">add</i> <span>ejemplo</span></button>`);

                        // deepcode ignore DOMXSS: <Estan sanitizados los valores con los que se genera en la función arrayToHtmlExamples>
                        desambiguatorPhraseSpan.append(desambiguatorPhrase);

                        desambiguatorButton.on('click', (e) => {
                            desambiguatorItem.remove();
                            // deepcode ignore DOMXSS: <Estan sanitizados los valores con los que se genera en la función arrayToHtmlExamples>
                            examplesModalTextareaBox.append(desambiguatorPhrase.clone());
                        });

                        desambiguatorItem.append(desambiguatorPercent);
                        desambiguatorItem.append(desambiguatorPhraseSpan);
                        desambiguatorItem.append(desambiguatorButton);
                        desambiguatorList.append(desambiguatorItem);
                    });

                    desambiguatorBox.append([desambiguatorLabel, desambiguatorList]);
                    modal.box.append(desambiguatorBox);
                }
            })
            .catch(error => {
                console.error(error);
                this.alertService.error('No cuentas con datos generados para esta intención recuerda que estos se calculan hasta el día primero de cada mes y solo cuando cuentas con la función desambiguador  activada y se han recolectado datos suficientes para un entrenamiento');
            });
    }

    getNlpExample(field) {
        const id = joint.util.uuid();
        const containerDiv = $('<div class="intentions" />');
        const contentsDiv = $('<div class="content-intentions" />');
        const intentionBox = $(`<div id="${id}" class="intention" />`);
        const intentionBtn = $(`<button class="btn-secondary"><span>Configura la frase</span></button>`);
        const ruler = $('<span class="ruler"></span>');
        const modal = this.modal.create({
            onClose: ()=> {
                modal.box.find('.add-intention').remove();
                modal.box.find('.desambiguator').remove();
            }
        });
        const modal2 = this.modal2.create({});

        intentionBox.append(intentionBtn);

        intentionBtn.on('click', (eIntentionBtn) => {
            this.manageModalAddIntention(modal, modal2, field);
        });

        $('body').append([modal.box, modal.blocker, modal2.box, modal2.blocker]);
        contentsDiv.append(intentionBox);

        const nameClass = field.attr('name').replace('#', '-');
        containerDiv.addClass(nameClass);
        containerDiv.append([ruler, contentsDiv, field]);

        return containerDiv;
    }
}
