<section class="sectionUsers">

    <div class="wrapper-title sub">
        <h1 class="title">
            {{translate.sections.apiUsers.apiKeys}}
        </h1>

        <ul class="actions">
            <li>
                <button class="btn-primary" (click)="openNewPopup()">
                <!-- <button class="btn-primary" (click)="saveUser()"> -->
                    <span>{{translate.sections.apiUsers.newUser}}</span>
                    <i class="material-icons md-20">add_circle</i>
                </button>
            </li>
        </ul>
    </div>

    <div class="listUsers">
        <p>{{translate.sections.apiUsers.apiKeysMessage}}</p>
    </div>

    <div *ngIf="!existsUsers" class="emptyUsers">
        <h1>{{translate.sections.apiUsers.noUsers}}</h1>
        <h4>{{translate.sections.apiUsers.startMessage}}</h4>
    </div>

    <div class="listUsers" *ngIf="existsUsers">
        <div class="md-ui component-data-table">
            <div class="main-table-wrapper">
                <table class="main-table-content">
                    <thead class="data-table-header">
                        <tr class="data-table-row">
                            <td>ID</td>
                            <td>Token</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody class="data-table-content">
                        <tr *ngFor="let usr of users" class="data-table-row">
                            <td>
                                <button (click)="copy(usr.username)" class="btn-icon copy">
                                    <i class="material-icons md-20">content_copy</i>
                                </button>
                                <span>{{usr.username}}</span><br>
                                <small>Roles: {{usr?.rolesNames}}</small>
                            </td>
                            <td>
                                <button (click)="copyPass(usr)" class="btn-icon copy">
                                    <i class="material-icons md-20">content_copy</i>
                                </button>
                                <span class="token">
                                    {{usr.password||"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"}}
                                </span>
                            </td>
                            <td align="right">
                                <button (click)="copyBase64(usr)" class="btn-icon copy" title="Copiar base64 token">
                                    <i class="material-icons md-20">content_copy</i>
                                </button>
                                <button *ngIf="usr.password===undefined" (click)="getToken(usr.id)" title="Mostrar" class="btn-icon">
                                    <i class="material-icons md-20">remove_red_eye</i>
                                </button>
                                <button (click)="openDeletePopup(usr)" title="Eliminar" class="btn-icon">
                                    <i class="material-icons md-20">delete</i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</section>

<!-- begin modal create user -->
<jw-modal id="popup-new-user">
    <a class="close_modal" (click)="closeModal('popup-new-user');">×</a>
    <div class="createUser">
        <h2 class="title">{{translate.sections.apiUsers.newUser}}</h2>

        <div class="fileds">
            <div class="field-box">
                <!-- <label for="hasToken">{{translate.sections.apiUsers.createToken}}</label>
                <input [(ngModel)]="hasToken" id="hasToken" type="checkbox"> -->
                <label>{{translate.sections.apiUsers.role}}</label>
                <select class="months" [(ngModel)]="selectedRole" (change)="onChangeRole()">
                    <option value="" disabled>{{ translate.sections.apiUsers.selectRole }}</option>
                    <option *ngFor="let r of roles;" [value]="r.key">
                        {{ r.name }}
                    </option>
                </select>
            </div>
            <div class="modal-actions">
                <button (click)="cancelPopup()" class="btn-secondary">
                    {{translate.sections.apiUsers.cancel}}
                </button>
                <button (click)="saveUser()" class="btn-primary">
                    {{translate.sections.apiUsers.create}}
                </button>
            </div>
        </div>

    </div>

</jw-modal>
<!-- end modal create user -->

<!-- begin modal delete user -->
<jw-modal id="popup-delete-user">
    <a class="close_modal" (click)="closeModal('popup-delete-user');">×</a>
    <div class="createUser">
        <h2 class="title">{{translate.sections.apiUsers.deleteUser}}</h2>

        <div class="fileds">
            <p>
                {{translate.sections.apiUsers.deleteMessage}} <srtong>{{userDelete?.username}}</srtong>?
            </p>
            <div class="modal-actions">
                <button (click)="closeModal('popup-delete-user');" class="btn-secondary">
                    {{translate.sections.apiUsers.cancel}}
                </button>
                <button (click)="deleteUser()" class="btn-primary">
                    {{translate.sections.apiUsers.delete}}
                </button>
            </div>
        </div>

    </div>

</jw-modal>
<!-- end modal delete user -->
