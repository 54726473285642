import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataLayerService {

    constructor() { }

    setFlag(flagPath: string, flagName: string, options: any = null) {
        let obj = {
            'event': 'flag',
            'name': flagPath + flagName
        };
        if (localStorage.getItem('constructorUserId')) {
            obj['userId'] = localStorage.getItem('constructorUserId');
        }
        if (localStorage.getItem('email')) {
            obj['email'] = localStorage.getItem('email')
        }
        if (typeof options === 'object') {
            obj = { ...obj, ...options };
        }
        window['dataLayer'].push(obj);
    }

}
