import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ChannelsService } from '../../services/channels.service';
import { ChatService } from '../../services/chat.service';
import { CustomerService } from '../../services/customer.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Chat } from '../../models/chat';
import { environment } from '../../environments/environment';
import * as JSONEditor from '../../assets/libraries/jsoneditor.min.js';
declare var JSONEditor: any;
import tippy from 'tippy.js';
import { AlertService } from 'src/services/alert.service';
import { FileService } from 'src/services/file.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare var translate: any;
@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
    public Editor = ClassicEditor;
    slug: any;
    channelSelect: any;
    channelOwnerId: any;
    currentChat: Chat = new Chat();
    c: any;
    content: any;
    color: any = '';
    welcomeGreeting: any = '';
    nameChannel: any;
    nameChannelSub: any;
    chat_url: string = '';
    zchat_url: string;
    whatsapp: any;

    env_param: string;

    style: string;
    default_persistent_menu: any;
    editor: any;
    urlParameter: any;
    files: any;

    accountHandler: string;
    authType: string;
    translate: any;
    constructor(
        private activate: ActivatedRoute,
        private channelService: ChannelsService,
        private chatService: ChatService,
        private customerService: CustomerService,
        private alertService: AlertService,
        private router: Router,
        private fileService: FileService,
        private recaptchaV3Service: ReCaptchaV3Service
    ) {
        this.whatsapp = {
            vertical: ''
        };
        this.authType = localStorage.getItem('Auth-Type');
        this.accountHandler = '';
        if (environment.name !== 'prod') {
            this.env_param = '&env=' + environment.name;
        }
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    copyCode(str: string) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
                ? document.getSelection().getRangeAt(0)
                : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    }

    copyBySelector(selector) {
        const str = document.querySelector(selector).textContent;
        this.copyCode(str);
    }

    setTooltipConf() {
        setTimeout(() => {
            tippy('.copy', {
                content: translate.sections.chat.copied,
                trigger: 'click',
            });
        }, 2000);
    }

    ngOnInit() {
        this.urlParameter = window.location.href;
        this.setTooltipConf();

        this.chat_url = environment.chatUrl;
        this.zchat_url = environment.chatUrl.replace('//chat', '//zchat');

        const parameters = this.activate.snapshot.params;

        if (parameters.customer_slug) {
            this.slug = parameters.customer_slug;
            this.channelOwnerId = parameters.id;
            this.channelService.get(parameters.customer_slug).subscribe(
                (res: any) => {
                    this.channelSelect = res.filter(res => res.channelOwnerId === this.channelOwnerId);
                    this.nameChannel = this.channelSelect[0].channel;
                    this.nameChannelSub = this.channelSelect[0].channelSubtype;
                    if (this.channelSelect[0].channel === 'WHATSAPP') {
                        console.log(this.channelSelect[0]);
                        if (this.channelSelect[0].channelSubtype === 'CLOUD') {
                            const chunks = this.channelSelect[0].channelOwnerName.split('WHATSAPP ');
                            this.accountHandler = chunks[1];
                        } else {
                            this.accountHandler = this.channelSelect[0].channelOwnerId;
                        }
                    } else {
                        this.accountHandler = this.channelSelect[0].accountHandler;
                    }
                    this.getCurrentChat();

                    // For whatsapp
                    console.log(this.nameChannel);
                    if (this.nameChannel === 'WHATSAPP' && this.nameChannelSub === 'WHATSAPP') {
                        this.getProfilePicture();
                        this.getAbout();
                        this.getInfo();
                    } else if (this.nameChannel === 'WHATSAPP' && this.nameChannelSub === 'CLOUD') {
                        this.getWhatsappCloudBusinessProfile();
                    }
                },
                err => {
                    console.log(err);

                }
            );
        }
    }


    isChat() {
        let result = false;
        if (this.channelSelect[0].channel === 'WEBSITE' || this.channelSelect[0].channel === 'ZENDESK_CHAT') {
            result = true;
        }
        return result;
    }



    deletechat() {
        this.chatService.delete(this.slug, this.channelOwnerId, "15c60c87-0fa3-41f4-b545-81cc7ddbc6e2").subscribe(
            res => {
                console.log(res);

            },
            err => {
                console.log(err);

            }
        );
    }



    getCurrentChat() {
        this.currentChat.welcomeGreeting = '';
        this.chatService.getByOwnerId(this.channelOwnerId, this.slug).subscribe(
            (chat: any) => {
                this.c = chat;
                //console.log('value of chat', chat);


                // this.currentChat = this.c.content[0];
                // console.log('value of currentchat', this.currentChat);
                // console.log('value of currentchat', JSON.stringify(this.currentChat));

                if (this.nameChannel === 'WEBSITE' || this.nameChannel === 'ZENDESK_CHAT') {
                    if (typeof this.c.content !== "undefined") {
                        if (this.c.content.length > 0) {
                            this.currentChat = this.c.content[0];
                            // console.log('value currentchat', this.currentChat);

                            if (this.currentChat.welcomeGreeting != null) {
                                this.welcomeGreeting = this.currentChat.welcomeGreeting.trim();
                            }

                        } else {
                            this.alertService.warning(translate.sections.chat.saveMessage);
                        }

                        if (this.currentChat.headerColor == null)
                            this.currentChat.headerColor = "#160a8b";
                        if (this.currentChat.titleColor == null)
                            this.currentChat.titleColor = "#fff";
                        if (this.currentChat.msgBotTxtColor == null)
                            this.currentChat.msgBotTxtColor = "#606273";
                        if (this.currentChat.msgBotBoxColor == null)
                            this.currentChat.msgBotBoxColor = "#eff3f6";
                        if (this.currentChat.msgUsrTxtColor == null)
                            this.currentChat.msgUsrTxtColor = "#fff";
                        if (this.currentChat.msgUsrBoxColor == null)
                            this.currentChat.msgUsrBoxColor = "#3f2a4b";
                        if (this.currentChat.dotColor == null)
                            this.currentChat.dotColor = "#34d9c3";
                        if (this.currentChat.buttonColorBg == null)
                            this.currentChat.buttonColorBg = "#fff";

                    }



                    let r = "22";
                    let g = "10";
                    let b = "139";

                    if (this.currentChat.headerColor != null) {
                        if (this.currentChat.headerColor.length > 4) {
                            r = parseInt(this.currentChat.headerColor.substring(1, 3), 16).toString();
                            g = parseInt(this.currentChat.headerColor.substring(3, 5), 16).toString();
                            b = parseInt(this.currentChat.headerColor.substring(5, 7), 16).toString();
                        } else {
                            r = parseInt(this.currentChat.headerColor.substring(1, 2) + this.currentChat.headerColor.substring(1, 2), 16).toString();
                            g = parseInt(this.currentChat.headerColor.substring(2, 3) + this.currentChat.headerColor.substring(2, 3), 16).toString();
                            b = parseInt(this.currentChat.headerColor.substring(3, 4) + this.currentChat.headerColor.substring(3, 4), 16).toString();
                        }
                    }


                    this.style = '0 0 170px rgba(' + r + ', ' + g + ', ' + b + ', .4)';
                    // this.getChannel(this.channelOwnerId);


                    const options = {};
                    this.editor = new JSONEditor(document.querySelector('#jsoneditorMain'), options);

                    if (this.currentChat.persistentMenu != null) {
                        const jsonValue = JSON.parse(this.currentChat.persistentMenu);
                        this.editor.set(jsonValue);
                    }
                }


            }, (err: any) => {
                console.log(err);


            });


    }


    saveBotTitle() {
        this.saveByField({ "botTitle": this.currentChat.botTitle });
    }

    saveBotImage() {
        this.saveByField({ "botImage": this.currentChat.botImage });
    }
    saveHeaderColor() {
        this.saveByField({ "headerColor": this.currentChat.headerColor });
    }
    saveTitleColor() {
        this.saveByField({ "titleColor": this.currentChat.titleColor });
    }
    saveMsgBotTxtColor() {
        this.saveByField({ "msgBotTxtColor": this.currentChat.msgBotTxtColor });
    }
    saveMsgBotBoxColor() {
        this.saveByField({ "msgBotBoxColor": this.currentChat.msgBotBoxColor });
    }
    saveMsgUsrTxtColor() {
        this.saveByField({ "msgUsrTxtColor": this.currentChat.msgUsrTxtColor });
    }
    saveMsgUsrBoxColor() {
        this.saveByField({ "msgUsrBoxColor": this.currentChat.msgUsrBoxColor });
    }
    saveCloseImage() {
        this.saveByField({ "closeImage": this.currentChat.closeImage });
    }
    saveButtonImage() {
        this.saveByField({ "buttonImage": this.currentChat.buttonImage });
    }
    saveDotColor() {
        this.saveByField({ "dotColor": this.currentChat.dotColor });
    }

    saveButtonColorBg() {
        this.saveByField({ "buttonColorBg": this.currentChat.buttonColorBg });
    }

    saveBoxSize() {
        this.saveByField({ "size": this.currentChat.size });
    }

    saveZIndex() {
        this.saveByField({ "zindex": this.currentChat.zindex });

    }

    showCounterMessages() {

        this.currentChat.showCounterMessages = !this.currentChat.showCounterMessages;
        this.saveByField({ "showCounterMessages": this.currentChat.showCounterMessages });

    }
    showFloatingMessages() {
        this.currentChat.showFloatingMessages = !this.currentChat.showFloatingMessages;
        this.saveByField({ "showFloatingMessages": this.currentChat.showFloatingMessages });
    }

    showFloatingLastMessage() {
        this.currentChat.showFloatingLastMessage = !this.currentChat.showFloatingLastMessage;
        this.saveByField({ "showFloatingLastMessage": this.currentChat.showFloatingLastMessage });
    }

    showAllowTracking() {
        this.currentChat.allowTracking = !this.currentChat.allowTracking;
        this.saveByField({ "allowTracking": this.currentChat.allowTracking });
    }

    showAllowTrackingLocation() {
        this.currentChat.allowTrackingLocation = !this.currentChat.allowTrackingLocation;
        this.saveByField({ "allowTrackingLocation": this.currentChat.allowTrackingLocation });
    }



    saveInCookies() {
        this.currentChat.inCookies = !this.currentChat.inCookies;
        this.saveByField({ "inCookies": this.currentChat.inCookies });
    }
    saveWelcome() {
        this.currentChat.showWelcomeGreeting = !this.currentChat.showWelcomeGreeting;
        this.saveByField({ "showWelcomeGreeting": this.currentChat.showWelcomeGreeting });
    }
    saveWelcomeMessage() {
        this.saveByField({ "welcomeGreeting": this.currentChat.welcomeGreeting });
    }
    saveLeavePage() {
        this.currentChat.openChatOnLeavingPage = !this.currentChat.openChatOnLeavingPage;
        this.saveByField({ "openChatOnLeavingPage": this.currentChat.openChatOnLeavingPage });
    }
    saveShowWelcomeBotMsg() {
        this.currentChat.showWelcomeBotMsg = !this.currentChat.showWelcomeBotMsg;
        this.saveByField({ "showWelcomeBotMsg": this.currentChat.showWelcomeBotMsg });
    }
    saveShowWelcomeBotMsgAlways() {
        this.currentChat.showWelcomeBotMsgAlways = !this.currentChat.showWelcomeBotMsgAlways;
        this.saveByField({ "showWelcomeBotMsgAlways": this.currentChat.showWelcomeBotMsgAlways });
    }
    saveScroll() {
        this.saveByField({ "scrollPercentToOpen": +this.currentChat.scrollPercentToOpen });
    }
    saveRatio() {
        this.saveByField({ "ratioAssignedPercent": +this.currentChat.ratioAssignedPercent });
    }
    saveSeconds() {
        this.saveByField({ "secondsToOpen": +this.currentChat.secondsToOpen });
    }

    saveWelcomeMobile() {
        this.currentChat.showWelcomeGreetingMobile = !this.currentChat.showWelcomeGreetingMobile;
        this.saveByField({ "showWelcomeGreetingMobile": this.currentChat.showWelcomeGreetingMobile });
    }
    saveOnLoadD() {
        this.currentChat.openChatOnLoadDesktop = !this.currentChat.openChatOnLoadDesktop;
        this.saveByField({ "openChatOnLoadDesktop": this.currentChat.openChatOnLoadDesktop });
    }
    saveOnLoadM() {
        this.currentChat.openChatOnLoadMobile = !this.currentChat.openChatOnLoadMobile;
        this.saveByField({ "openChatOnLoadMobile": this.currentChat.openChatOnLoadMobile });
    }
    saveWebviewUrl() {
        this.saveByField({ "showWebviewOnClick": this.currentChat.showWebviewOnClick });
    }
    saveWelcomeAlways() {
        this.currentChat.showWelcomeBotMsgAlways = !this.currentChat.showWelcomeBotMsgAlways;
        this.saveByField({ "showWelcomeBotMsgAlways": this.currentChat.showWelcomeBotMsgAlways });
    }

    update() {
        let update = true;
        if (this.currentChat.id === undefined) {
            // console.log('not id');


            if (this.currentChat.botTitle !== undefined && this.currentChat.botImage !== undefined) {
                this.chatService.create(this.currentChat, this.channelOwnerId, this.slug).subscribe(
                    (chat: any) => {
                        // console.log('value de chat creado', chat);
                        this.currentChat = chat;
                        localStorage.setItem('step4', 'true');
                        this.router.navigate([], { queryParams: { steps: 'updated' } });
                    }

                    , (err: any) => {
                        console.log(err);

                    });

            }
            update = false;
        } else {

        }

        return update;
    }

    saveMainP() {
        // console.log('assigned menu persistente')
        let content: any = this.editor.get();
        // console.log(content);

        this.saveByField({ "persistentMenu": JSON.stringify(content) });
    }

    saveByField(obj: any) {
        // console.log('object to send', obj);


        if (this.update() === true) {


            this.chatService.update(obj, this.slug, this.channelOwnerId, this.currentChat.id).subscribe(
                (res: any) => {
                    // console.log(res);
                    // console.log('save');
                    this.currentChat = res;


                },
                err => {
                    console.log(err);

                }
            );
        }
    }

    handleFiles(event) {
        this.files = event.target.files;
        let fr = new FileReader();
        let chat = this;
        fr.onload = function () {
            chat.whatsapp.picture = fr.result;
        }
        fr.readAsDataURL(event.target.files[0]);
    }

    uploadFile(success, error) {
        const formFiles = new FormData();
        formFiles.append('document', this.files[0]);

        this.recaptchaV3Service.execute('uploadFile').subscribe((reToken) => {
            this.fileService.upload(this.slug, formFiles, reToken).subscribe({
                next: (res: any) => {
                    success(res);
                },
                error: err => {
                    error(err);
                }
            });
        });
    }

    updateProfilePicture() {
        this.uploadFile((resFile) => {
            this.channelService.updateWhatsappProfilePhoto(this.slug, this.channelOwnerId, resFile.url).subscribe(
                (res: any) => {
                    this.alertService.success('Se ha actualizado la imagen');
                },
                err => {
                    console.log(err);
                }
            );

        }, (error) => {
            console.log(error);
        });
    }

    updateInfo(subtype = null) {
        const obj = {
            address: this.whatsapp.address,
            description: this.whatsapp.description,
            email: this.whatsapp.email,
            vertical: this.whatsapp.vertical,
            websites: []
        };
        if (this.whatsapp.site1) {
            obj.websites.push(this.whatsapp.site1);
        }
        if (this.whatsapp.site2) {
            obj.websites.push(this.whatsapp.site2);
        }
        this.channelService.updateWhatsappInfo(this.slug, this.channelOwnerId, obj).subscribe(
            (res: any) => {
                this.alertService.success('Se ha actualizado la información');
            },
            err => {
                console.log(err);
            }
        );
    }
    updateAbout() {
        this.channelService.updateWhatsappAbout(this.slug, this.channelOwnerId, this.whatsapp.text).subscribe(
            (res: any) => {
                this.alertService.success('Se ha actualizado la información del acerca de');
            },
            err => {
                console.log(err);
            }
        );
    }

    getProfilePicture() {
        this.channelService.getWhatsappProfilePhoto(this.slug, this.channelOwnerId).subscribe(
            (res: any) => {
                this.whatsapp.url = res.settings.profile.photo.link;
                // this.alertService.success('Se ha actualizado foto de perfil');
            },
            err => {
                console.log(err);
            }
        );
    }

    getInfo() {
        this.channelService.getWhatsappInfo(this.slug, this.channelOwnerId).subscribe(
            (res: any) => {
                this.whatsapp = {
                    ...this.whatsapp,
                    ...res.settings.business.profile
                };
                if (this.whatsapp.websites.length === 2) {
                    this.whatsapp.site1 = this.whatsapp.websites[0];
                    this.whatsapp.site2 = this.whatsapp.websites[1];
                }
                if (this.whatsapp.websites.length === 1) {
                    this.whatsapp.site1 = this.whatsapp.websites[0];
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    getAbout() {
        this.channelService.getWhatsappAbout(this.slug, this.channelOwnerId).subscribe(
            (res: any) => {
                this.whatsapp.text = res.settings.profile.about.text;
            },
            err => {
                console.log(err);
            }
        );
    }

    getWhatsappCloudBusinessProfile() {
        this.channelService.getWhatsappCloudBusinessProfile(this.slug, this.channelOwnerId).subscribe(
            (res: any) => {
                if (res.hasOwnProperty('data') && res.data && res.data.length > 0) {
                    const data = res.data[0];
                    this.whatsapp.text = data.about;
                    this.whatsapp.address = data.address;
                    this.whatsapp.description = data.description;
                    this.whatsapp.email = data.email;
                    this.whatsapp.vertical = data.vertical;
                    this.whatsapp.websites = data.websites;
                    this.whatsapp.url = data.profile_picture_url;
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    updateWhatsappCloudBusiness(profile, success=null, error=null) {
        this.channelService.updateWhatsappCloudBusinessProfile(this.slug, this.channelOwnerId, profile).subscribe(
            (res: any) => {
                console.log(res);
                if (typeof(success) === 'function') {
                    success(res);
                }
            },
            err => {
                console.log(err);
                if (typeof(error) === 'function') {
                    error(err);
                }
            }
        );
    }
    updateWhatsappCloudBusinessProfile() {
        const profile = {
            about: this.whatsapp.text,
            address: this.whatsapp.address,
            description: this.whatsapp.description,
            email: this.whatsapp.email,
            messaging_product: 'whatsapp',
            websites: [],
            vertical: this.whatsapp.vertical
        };
        if (this.whatsapp.site1) {
            profile.websites.push(this.whatsapp.site1);
        }
        if (this.whatsapp.site2) {
            profile.websites.push(this.whatsapp.site2);
        }
        this.updateWhatsappCloudBusiness(profile,
            (res) => {
                // On success
                this.alertService.success('Se ha actualizado la información del perfil');
            }, (error) => {
                this.alertService.error('No se ha podido actualizar la información del perfil');
            }
        );
    }

    handleFilesCloud(event) {
        this.files = event.target.files;
        let fr = new FileReader();
        let chat = this;
        fr.onload = function () {
            chat.whatsapp.picture = fr.result;
        }
        fr.readAsDataURL(event.target.files[0]);
    }

    uploadProfilePictureMeta() {
        this.uploadFile((resFile) => {
            this.channelService.updateWhatsappProfilePhoto(this.slug, this.channelOwnerId, resFile.url).subscribe(
                (res: any) => {
                    this.alertService.success('Se ha actualizado la foto del perfil');
                },
                err => {
                    console.log(err);
                    this.alertService.error('No se ha podido actualizar la foto del perfil');
                }
            );

        }, (error) => {
            console.log(error);
        });
    }

}
