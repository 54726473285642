<section id="section-sso">
    <div class="absolute-center">
        <h1>{{translate['bienvenidoaFlux']}}</h1>

        <div class="whitebox">
            <h2>{{translate['iniciarSesionConSSO']}}</h2>
            <div class="email-box">
                <label>Email</label>
                <input [(ngModel)]="email" type="text" class="email">
                <!-- <button class="btn-primary start" (click)="redirectLogin()">
                    Iniciar
                </button> -->
                <button class="btn-primary start" (click)="login()">
                    {{translate['iniciar']}}
                </button>
            </div>
            <!-- <ul class="sso-list">
                <li *ngFor="let provider of providers">
                    <button class="btn-secondary btn-sso" (click)="redirectLogin(provider)">
                        <span class="icon"></span>
                        <span>Iniciar sesión con {{provider.name}}</span>
                    </button>
                </li>
            </ul> -->
        </div>
    </div>
</section>