import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart } from '@angular/router';
import { AuthServices } from '../../services/auth.guard.service';
import { BuildBotService } from '../../services/build-bot.service';
import { AlertService } from '../../services/alert.service';
import { DiagramService } from '../../services/diagram.service';
import { AllowedRolService } from '../../services/rol-service.service';
import { CustomerService } from '../../services/customer.service';
import { PlatformService } from "../../services/platform.service";
import { ExporterService } from "../../services/exporter.service";
import { environment } from '../../environments/environment';
import { ModalService } from '../modal/modal.service';
import tippy from 'tippy.js';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/services/account.service';
import {FlowPermission} from "../../models/flow-permission";
import {User} from "../../models/user";
import { DataLayerService } from 'src/services/data-layer.service';

declare var translate: any;

declare global {
    interface Window {
        canEdit: boolean ;
    }
}
@Component({
    selector: 'app-navbar-top',
    templateUrl: './navbar-top.component.html',
    styleUrls: ['./navbar-top.component.scss']
})
export class NavbarTopComponent implements OnInit {
    @ViewChild("newGuest") newGuest: ElementRef;
    @ViewChild("diagramSelect") diagramSelect: ElementRef;
    @ViewChild("fullDiagram") fullDiagram: ElementRef;
    routeSubscription: Subscription;
    isLogin = true;
    isCustomers = false;
    assets = environment.assets;
    existsSlug = false;
    existsDiagram = false;
    userImage = '';
    userName = '';
    scrWidth: any;
    mobile = false;
    showMenu = false;
    showBuild = false;
    showExport = false;
    slug: any = '';
    customerId: any = '';
    guests: User[] = [];
    guestsPending = [];
    guestsDeleted = [];
    guestsPendingDeleted = [];
    currentUserMail = '';
    superUserMail = '';
    constUserId = '';
    linkToBot = '';
    startFlowToken = '';
    botName;
    platformSelected;
    envLoaded;
    showPaymentNotification;
    flowSlug:string;
    /* enviroments = ['pre', 'dev', 'stage', 'prod'] */
    /*   pre -> Pre desarrollo
  dev -> Desarrollo
  stage -> Pre producción
  prod -> Producción */
    enviroments = [{
        name: 'Pre desarrollo',
        value: 'PRE'
    },
    {
        name: 'Desarrollo',
        value: 'DEV'
    },
    {
        name: 'Pre producción',
        value: 'STAGE'
    },
    {
        name: 'Producción',
        value: 'PROD'
    }
    ]
    status = false;
    showSelectBot = false;
    loadedBots = [];
    botChange;
    enviromentSelected;
    listPlatforms = [];
    selectedBot;
    selectedPlatform;
    diagrams: any[];
    currentDiagramId: string;
    translate: any;
    dataExportAdjustments: any[];

    canEdit = false;
    flagPath: string;

    constructor(
        private authService: AuthServices,
        private alertService: AlertService,
        private buildBotService: BuildBotService,
        private diagramService: DiagramService,
        private route: ActivatedRoute,
        private router: Router,
        private allowedRoleService: AllowedRolService,
        private customerService: CustomerService,
        private platformService: PlatformService,
        private modalService: ModalService,
        private exportService: ExporterService,
        private accountService: AccountService,
        private dataLayerService: DataLayerService
    ) {
        // this.showPaymentNotification = true;
        this.getScreenSize();
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // console.log('url: ', event.url);
                if (event.url.indexOf('/diagram') > 0) {
                    this.existsDiagram = true;

                } else {
                    this.existsDiagram = false;
                    localStorage.removeItem('canEdit');
                }
                if (
                    event.url !== '/' &&
                    event.url.indexOf('/?') === -1 &&
                    event.url.indexOf('/#') === -1 &&
                    event.url.indexOf('/sso') === -1
                ) {
                    this.existsSlug = true;
                } else {
                    this.existsSlug = false;
                }
                if (event.url === '/customers') {
                    this.isCustomers = true;
                } else {
                    this.isCustomers = false;
                }
                this.getUrlParam('customer_slug', (slug) => {
                    this.slug = slug;
                    // console.log('%c cambio el slug', 'background: orange; color white;');
                    // console.log(slug);
                    if (slug) {
                        localStorage.setItem('currentCustomerSlug', slug);
                    }
                    tippy('[data-tippy-content]');
                    if (slug) {
                        this.getBySlug(slug, 'false');
                    }
                });
                this.getUrlParam('platform_slug', (platform) => {
                    /* console.log('plataforma', platform); */
                    this.platformSelected = platform;
                    this.flowSlug = localStorage.getItem('flowSlug');

                });
                this.getUrlParam('diagram_id', (diagramId) => {
                    this.currentDiagramId = diagramId;
                });
            }
        });
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.flagPath = '';
    }
    async ngOnInit() {
        this.currentUserMail = localStorage.getItem('email');
        this.superUserMail = 'constructor@holagus.com';
        this.constUserId = localStorage.getItem('constructorUserId');
        this.userImage = localStorage.getItem('photo');
        this.userName = localStorage.getItem('name');
        this.flowSlug = localStorage.getItem('flowSlug');
        const currentRole = JSON.parse(localStorage.getItem('role'));
        this.canEdit = localStorage.getItem('canEdit') === '1'
        if (currentRole) {
            // if (currentRole.name === 'UX' || currentRole.name === 'ADMIN') {
            if (this.allowedRoleService.allowed(currentRole.name, 'UX')) {
                this.showExport = true;
            }
            // if (currentRole.name === 'DEV' || currentRole.name === 'ADMIN') {
            if (this.allowedRoleService.allowed(currentRole.name, 'DEV')) {
                this.showBuild = true;
            }
        }
        // if (await this.checkIfUserAuthenticated()) {
        //     // this.googleUser = this.authInstance.currentUser.get();
        // }
    }

    discard() {
        this.showPaymentNotification = false;
    }
    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
        if (this.scrWidth < 880) {
            this.mobile = true;
        } else {
            this.mobile = false;
        }
    }
    getAvatar() {
        let avatar = localStorage.getItem('photo');
        if (avatar === null) {
            avatar = '/assets/img/user_default.svg';
        }
        return avatar;
    }
    getAvatarError(event) {
        event.target.src = '/assets/img/user_default.svg';
    }
    toggleMenu() {
        if (this.showMenu) {
            this.showMenu = false;
        } else {
            this.showMenu = true;
        }
    }
    getUrlParam(param: string, success) {
        const paramInterval = setInterval(() => {
            if (this.route.firstChild !== null) {
                if (this.route.firstChild.paramMap !== null) {
                    clearInterval(paramInterval);
                    this.route.firstChild.paramMap.subscribe(params => {
                        const p = params.get(param);
                        success(p);
                    });
                }
            }
        }, 500);
    }
    back() {
        /*const paramInterval = setInterval(() => {
            if (this.route.firstChild !== null) {
                if (this.route.firstChild.url !== null) {
                    clearInterval(paramInterval);
                    this.route.firstChild.url.subscribe((url: any) => {
                        const redirectTo = [];
                        redirectTo.push('/');
                        for (let index = 0; index < url.length - 1; index++) {
                            const urlSegment: any = url[index];
                            redirectTo.push(urlSegment.path);
                        }
                        if (url[url.length - 1] === 'diagram') {
                            redirectTo.pop();
                        }
                        this.router.navigate(redirectTo);
                    });
                }
            }
        });*/
        window.history.back();
    }
    goProfile() {
        const userId = localStorage.getItem('id');
        this.router.navigate(['/user', userId]);
    }
    logOut() {
        const authType = localStorage.getItem('Auth-Type');
        if (authType === 'FACEBOOK') {
            this.accountService.logout();
        } else if (authType === 'GOOGLE') {
            // this.authInstance.signOut();
        }
        console.log('logout navbar');
        this.authService.logout();
    }
    generateSlug(name) {
        return name.toLowerCase().replace(/ /g, '-').replace(/[^0-9a-z_\-]/, '') + '-' + (new Date().getTime()).toString(36);
    }
    onErrorImage(event) {
        event.target.src = this.assets + 'img/user_default.svg';
        const maxAttempts = 5;
        let attempts = 0;
        const interval = setInterval(() => {
            if (localStorage.hasOwnProperty('photo')) {
                event.target.src = localStorage.getItem('photo');
                clearInterval(interval);
            }
            if (attempts === maxAttempts) {
                clearInterval(interval);
            }
            attempts += 1;
        }, 500);
    }
    addPermissionsByDiagramSlug(guest: User) {
        const payload = {
            assigneeId: guest.id
        }
        this.diagramService.addPermissionsBySlug(this.flowSlug, this.slug, this.selectedPlatform, payload).subscribe(
            (permission: FlowPermission) => {
                guest.hasPermission = true;
            }
        )
        ;
    }
    revokePermissionsByDiagramSlug(guest: User) {
        const payload = {
            assigneeId: guest.id
        }
        this.diagramService.deletePermissionsBySlug(this.flowSlug, this.slug, this.selectedPlatform, payload).subscribe(
            (permission: FlowPermission) => {
                guest.hasPermission = false;
            }
        )
        ;
    }

    deleteByDiagramSlug(modalId: string) {
        this.reloadFromLocalStorage();
        const payload = {
        }
        this.diagramService.deleteBySlug(this.flowSlug, this.slug, this.selectedPlatform, payload).subscribe(
            () => {
                this.alertService.success(this.translate.sections.navbarTop.diagramActions.deletionSuccessful);
                this.closeModal(modalId);
                const uri = `/customers/${this.slug}/platforms/${this.selectedPlatform}/diagram`;
                window.location.href = uri;

            },
            () => {
                this.alertService.error(this.translate.sections.navbarTop.diagramActions.deletionError);
                this.closeModal(modalId);
            }
        )
        ;
    }
    getAllPermissionsByDiagramSlug(diagramSlug: string, slug:string, platformSlug: string, guests: any[]) {
        this.diagramService.getAllPermissionsBySlug(diagramSlug, slug, platformSlug).subscribe(
            (flowPermissions: FlowPermission[] ) => {
                console.log(flowPermissions);
                for (const permission of flowPermissions) {
                    const user: User = permission.constructorUser;
                    for (let guest of guests) {
                        if (guest.id === user.id) {
                            guest.hasPermission = true;
                            break;
                        }
                    }
                }
                console.log(guests);
            },
            err => {
                this.modalService.close('popup-add-diagram-guests');
            }
        );
    }
    getBySlug(slug: string, showLoader='true', loadPermissions = false) {
        this.customerService.getBySlug(slug, showLoader).subscribe(
            (res: any) => {
                /* console.log(res); */
                this.botName = res.tenant.botName
                /* console.log(this.botName); */
                let addres = window.location.hostname;
                let env;
                if (addres.includes('localhost')) {
                    env = 'local'
                } else if (addres.split('-').length === 2) {
                    env = 'prod'
                } else {
                    env = addres.split('-')[2].split('.')[0]
                }
                /* console.log(env); */
                this.envLoaded = env;
                let email = localStorage.getItem('email');
                /* console.log(email); */
                this.showSelectBot = true;
                this.selectedPlatform = this.platformSelected;
                // if (email.includes('holagus.com') || email.includes('suramexico.com') || email.includes('kueski.com')) {
                //     this.showSelectBot = true;
                // }else {
                //     this.selectedPlatform = this.platformSelected;
                //     /* platformSelected */
                // }
                this.customerId = res.tenant.id;
                this.guests = res.tenant.constructorUsers;
                this.showPaymentNotification = !res.tenant.hasPaid;
                if (loadPermissions) {
                    this.getAllPermissionsByDiagramSlug(this.flowSlug, this.slug, this.platformSelected, this.guests);
                }
            },
            err => {
                this.modalService.close('popup-add-guests');
            }
        );
    }

    canEditDiagram(): boolean {
        return localStorage.getItem('canEdit') === '1';
    }
    isCurrentFlowPrimary() {
        return localStorage.getItem('isFlowPrimary') === '1';
    }
    openModal(id: string) {
        this.openSimpleModal(id);
        this.getBySlug(this.slug);
        this.getInvitedPendingBySlug(this.slug);
        if (id === 'popup-export') {
            this.diagrams = JSON.parse(localStorage.getItem('secondaryFlowPrebuild'));
        }
    }

    openSimpleModal(id: string) {
        this.modalService.open(id);
    }
    openDeleteDiagramModal(id: string) {
        if (!this.canEditDiagram()) {
            this.alertService.warning(this.translate.sections.navbarTop.diagramActions.permissions.notDeleteAllowed);
            return;
        }

        if (this.isCurrentFlowPrimary()) {
            this.alertService.warning(this.translate.sections.navbarTop.diagramActions.deletionErrorMain);
            return;
        }

        this.openSimpleModal(id);
    }

    openModalExport(id: string) {
        if (!this.canEditDiagram()) {
            this.alertService.warning(this.translate.sections.navbarTop.diagramActions.permissions.notEditAllowed);
            return;
        }
        this.openModal(id);
    }

    reloadFromLocalStorage() {
        this.flowSlug = localStorage.getItem('flowSlug');
        this.canEdit = localStorage.getItem('canEdit') === '1';
    }
    openDiagramPermissionModal(id: string) {
        this.reloadFromLocalStorage();
        if (!this.canEditDiagram()) {
            this.alertService.warning(this.translate.sections.navbarTop.diagramActions.permissions.notEditAllowed);
            return;
        }
        this.modalService.open(id);
        this.getBySlug(this.slug, 'true', true);

        if (id === 'popup-export') {
            this.diagrams = JSON.parse(localStorage.getItem('secondaryFlowPrebuild'));
        }
    }
    closeModal(id: string) {
        if (id === 'popup-add-guests') {
            this.guests = [];
            this.guestsPending = [];
        }
        this.modalService.close(id);
    }
    onEnterGuest(e) {
        this.addGuestView(e.target);
    }
    addGuests() {
        let countEmails = 0;
        let countEmailsErr = 0;
        const emails = [];
        const currentEmail: string = String(this.newGuest.nativeElement.value).toLowerCase();
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (currentEmail && re.test(currentEmail)) {
            this.addGuestView(this.newGuest.nativeElement);
        }
        for (const guest of this.guestsPending) {
            if (!guest.hasOwnProperty('id')) {
                emails.push(guest.email);
            }
        }
        if (emails.length > 0) {
            for (const email of emails) {
                this.customerService.addAdmin(email, this.slug).subscribe(
                    res => {
                        countEmails++;
                        // console.log(res);
                        // this.alertService.success('Se ha agregado el usuario');
                    },
                    err => {
                        countEmailsErr++;
                        console.log(err);
                        this.alertService.success(translate.sections.navbarTop.errorMessage + email);
                    }
                );
            }
        }
        const intervalGuests = setInterval(() => {
            if (countEmails === emails.length) {
                this.alertService.success(translate.sections.navbarTop.updatedList);
                clearInterval(intervalGuests);
            }
        }, 500);
        this.deleteGuests();
    }
    deleteGuests() {
        if (this.guestsDeleted.length > 0) {
            this.customerService.deleteAdmins(this.customerId, this.guestsDeleted, this.constUserId, this.slug).subscribe(
                res => {
                    // console.log(res);
                    this.guestsDeleted = [];
                },
                err => {
                    console.log(err);
                }
            );
        }
        if (this.guestsPendingDeleted.length > 0) {
            this.guestsPendingDeleted.forEach(element => {
                this.rejectInvitation(element.id);
            });
        }
    }
    rejectInvitation(invitationId) {
        this.customerService.rejectInvitation(invitationId).subscribe(
            res => {
                const index = this.guestsPendingDeleted.find(invite => invite.id === invitationId);
                this.guestsPendingDeleted.splice(index, 1);
                this.alertService.success(translate.sections.navbarTop.invitationMessage);
            },
            err => {
                console.log(err);
            }
        );
    }
    addGuestView(inputEmail) {
        if (inputEmail.value !== '' && !this.guests.some(el => el.email === inputEmail.value)) {
            this.guestsPending.push({ email: inputEmail.value });
        }
        inputEmail.value = '';
    }
    deleteGuestView(idx) {
        this.guestsDeleted.push(this.guests[idx].email);
        this.guests.splice(idx, 1);
    }
    deleteGuestViewInvite(idx) {
        this.guestsPendingDeleted.push(this.guestsPending[idx]);
        this.guestsPending.splice(idx, 1);
    }
    getInvitedPendingBySlug(slug: string) {
        this.customerService.getInvitedPendingBySlug(slug).subscribe(
            (res: any) => {
                this.guestsPending = res;
                // console.log(this.guestsPending);
            },
            err => {
                console.log(err);
                this.modalService.close('popup-add-guests-customers');
                // this.dataSlug.tenant = '';
            }
        );
    }
    copy(str: string) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
                ? document.getSelection().getRangeAt(0)
                : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    }
    selectEnviroment(env) {
        let data = localStorage.getItem('payload');
        let userData = JSON.parse(data);
        this.enviromentSelected = env.value;
        if (env.value != '') {
            this.status = true;
            this.authService.registerLoginEnviroment(userData, env.value).subscribe(
                (res: any) => {
                    /*   localStorage.setItem(
                          "constructorUserId",
                          res.id
                      ); */
                    this.customerService.getByUserIdEnv(
                        res.id,
                        'true', env.value
                    ).subscribe((customers: any) => {
                        this.loadedBots = customers.tenants;
                    });
                }
            )
        } else {
            this.status = false;
        }
    }
    selectBot() {
        /* console.log(this.selectedBot); */
        /* this.botChange = bot.value */
        this.platformService.getCatalogByEnviroment(this.selectedBot, this.enviromentSelected).subscribe(
            res => {
                /* console.log('listado', res); */
                this.listPlatforms = res;
            },
            err => {
                console.log(err);
            }
        )
    }
    export(enviroment) {
        this.reloadFromLocalStorage();
        const description = 'Exportado por: ' + localStorage.getItem('name') + " (" + localStorage.getItem('email') + ")";
        let payload = {
            description,
            source: {
                // flowPrebuildId: localStorage.getItem('flowId'),
                flowPrebuildId: this.diagramSelect.nativeElement.value,
                platform: this.platformSelected
            },
            target: {
                slug: this.selectedBot,
                platform: this.selectedPlatform,
                env: enviroment.value
            }
        }
        const payloadToken = JSON.parse(localStorage.getItem('payload'));
        if (payloadToken && payloadToken.id_token) {
            payload['id_token'] = payloadToken.id_token;
        }
        if (this.fullDiagram.nativeElement.checked) {
            payload['exportType'] = 'complete';
        }
        this.exportService.create(JSON.stringify(payload), this.slug).subscribe(
            (res: any) => {
                if (this.platformSelected != this.selectedPlatform) {
                    // this.alertService.warning(translate.sections.navbarTop.flowMessage);
                    this.alertService.warning(translate.sections.navbarTop.flowMessage);
                } else {
                    this.alertService.success(translate.sections.navbarTop.diagramMessage);
                }
                this.modalService.close('popup-export');
                if (res.hasOwnProperty('warnings')) {
                    // this.dataExportAdjustments = res.warnings;
                    this.dataExportAdjustments = res.warnings.map((w) => {
                        return w[Object.keys(w)[0]];
                    });
                    this.dataExportAdjustments = this.dataExportAdjustments.filter((d) => {
                        return d.items.length > 0;
                    });
                    this.modalService.open('popup-adjustments-export');
                }
            }, err => {
                console.log(err);
            }
        )
    }
    toggleFulldiagram(fullDiagram) {
        if (fullDiagram.checked) {
            this.diagramSelect.nativeElement.setAttribute('disabled', 'true');
        } else {
            this.diagramSelect.nativeElement.removeAttribute('disabled');
        }
    }
    // selectedDiagram (selectDiagram) {
    //     const diagramSelected = selectDiagram.value;
    // }

    redirectDemo() {
        this.dataLayerService.setFlag(this.flagPath, 'demo');
        window.location.href = 'https://gus.chat';
    }
    redirectHelp() {
        this.dataLayerService.setFlag(this.flagPath, 'help');
        window.location.href = 'https://help.gus.chat';
    }

    showTryBtn() {
        let show = true;
        if (this.platformSelected === 'USER_STORY') {
            show = false;
        }
        return show;
    }

    showValidateNLPBtn() {
        let show = true;
        if (this.platformSelected === 'USER_STORY') {
            show = false;
        }
        return show;
    }

    showStructureUpdateBtn() {
        let show = true;
        if (this.platformSelected === 'USER_STORY') {
            show = false;
        }
        return show;
    }

    showPublishBtn() {
        let show = true;
        if (this.platformSelected === 'USER_STORY') {
            show = false;
        }
        return show;
    }
}
