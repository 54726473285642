import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-preview-message',
    templateUrl: './preview-message.component.html',
    styleUrls: ['./preview-message.component.scss']
})
export class PreviewMessageComponent implements OnInit {

    @Input() dataMessage;
    messagesAuth;

    constructor() {
        // this.dataMessage = {};
        this.messagesAuth = {
            'en': {
                securityRecommendation: '<strong>123456</strong> is your verification code.',
                dateExpiryCode: 'For your security, do not share this code.',
                expiresIn: 'This code expires in $min minutes.'
            },
            'en_GB': {
                securityRecommendation: '<strong>123456</strong> is your verification code.',
                dateExpiryCode: 'For your security, do not share this code.',
                expiresIn: 'This code expires in $min minutes.'
            },
            'en_US': {
                securityRecommendation: '<strong>123456</strong> is your verification code.',
                dateExpiryCode: 'For your security, do not share this code.',
                expiresIn: 'This code expires in $min minutes.'
            },
            'es': {
                securityRecommendation: '<strong>123456</strong> es tu código de verificación.',
                dateExpiryCode: 'Por tu seguridad, no compartas este código.',
                expiresIn: 'Este código caduca en $min minutos.'
            },
            'es_MX': {
                securityRecommendation: '<strong>123456</strong> es tu código de verificación.',
                dateExpiryCode: 'Por tu seguridad, no compartas este código.',
                expiresIn: 'Este código caduca en $min minutos.'
            },
            'es_ES': {
                securityRecommendation: '<strong>123456</strong> es tu código de verificación.',
                dateExpiryCode: 'Por tu seguridad, no compartas este código.',
                expiresIn: 'Este código caduca en $min minutos.'
            },
            'pt_BR': {
                securityRecommendation: '<strong>123456</strong> é o seu código de verificação.',
                dateExpiryCode: 'Para sua segurança, não compartilhe este código.',
                expiresIn: 'Este código expira em $min minutos.'
            },
            'pt_PT': {
                securityRecommendation: '<strong>123456</strong> é o seu código de verificação.',
                dateExpiryCode: 'Para sua segurança, não compartilhe este código.',
                expiresIn: 'Este código expira em $min minutos.'
            },
            'it': {
                securityRecommendation: '<strong>123456</strong> è il tuo codice di verifica.',
                dateExpiryCode: 'Per la tua sicurezza, non condividere questo codice.',
                expiresIn: 'Questo codice scade tra $min minuti.'
            }
        };
    }

    ngOnInit() {
        if (this.dataMessage.codeExpirationMinutes) {
            this.dataMessage.codeExpiryDate = true;
        } else {
            this.dataMessage.codeExpiryDate = false;
        }
    }

    getFileType(fileUrl) {
        let extension = '';
        if (fileUrl && fileUrl !== '') {
            extension = fileUrl.split('.').pop();
        }
        return extension;
    }

    getFileName(fileUrl) {
        let fileName = '';
        if (fileUrl && fileUrl !== '') {
            fileName = fileUrl.split('/').pop();
        }
        return fileName;
    }

    messageToMarkup(text) {
        text = text.replace(' ', '');
        text = text.toString().replace(/\*([^\*].*?)\*/g, "<strong>$1</strong>");
        text = text.replace(/\s_([^_].*?)_\s/g, "<i>$1</i>");
        text = text.replace(/~([^~].*?)~/g, "<del>$1</del>");
        text = text.replace(/```([^`].*?)```/g, "<span class=''>$1</span>");
        text = text.replace(/\n/g, '<br>');
        return text;
    }

    getButtonsLength() {
        let count = 0;
        if (this.dataMessage.button1 && this.dataMessage.button2 && this.dataMessage.button3) {
            count = 3;
        } else if (this.dataMessage.button1 && this.dataMessage.button2) {
            count = 2;
        } else if (this.dataMessage.button1) {
            count = 1;
        }
        return count;
    }

    isEmpty(str) {
        return str === undefined || str ===  null || str === '';
    }

    getMessage() {
        let msg = '';
        if (this.dataMessage.category == 'AUTHENTICATION') {
            let langCode = 'es';
            if (this.dataMessage.languageCode in this.messagesAuth) {
                langCode = this.dataMessage.languageCode;
            }
            const msgObj = this.messagesAuth[langCode];
            msg = msgObj.securityRecommendation + ' ';
            if (this.dataMessage.addSecurityRecommendation) {
                msg += msgObj.dateExpiryCode;
            }
            if (this.dataMessage.codeExpiryDate) {
                msg += '<br><small>' + msgObj.expiresIn.replaceAll('$min', this.dataMessage.codeExpirationMinutes) + '</small>';
            }
        } else {
            msg = this.messageToMarkup(this.dataMessage.messageContent);
        }
        return msg;
    }

    getLabelButton1() {
        let btnLabel = this.dataMessage.button1;
        if (this.dataMessage.category == 'AUTHENTICATION') {
            btnLabel = this.dataMessage.button1Value;
        }
        return btnLabel;
    }

}
