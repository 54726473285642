import { Injectable } from '@angular/core';
import * as joint from '../../../vendor/rappid';
import { AlertService } from '../alert.service';
import { ModalService } from './modal.service';
import { FileService } from '../file.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare var $: any;
declare var Sortable: any;
declare var translate: any;
@Injectable({ providedIn: 'root' })
export class CarouselService {
    translate: any;

    private alertService: AlertService;
    private modal: ModalService;
    private fileService: FileService;
    private recaptchaV3Service: ReCaptchaV3Service;

    constructor(fileService, recaptchaV3Service) {
        this.recaptchaV3Service = recaptchaV3Service;
        this.fileService = fileService;
        this.modal = new ModalService();
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
    }

    getCarousel(field, req, value, customerSlug, typeCarousel = 'jsonS') {
        // $('.modal2').remove();
        let $spanButtonSlide: any;
        let typeSlider: any = '';
        let titleSlider: any = '';
        let titleWebview: any = '';
        let phoneValue: any = '';
        let titleURL: any = '';
        let selectRatioValue: any = '';
        let indexbtnSlider: any = 0;
        let index: any = 0;
        let indexCard: any = 0;
        let contentTextarea: any = '';
        let arrraybuttonsSlider: any = [];
        let cardTitleSlide: any = '';
        let cardDescriptionSlide: any = '';
        let coveredUrl: any;
        let cardSlider: any;
        let deleteCard: any;
        let imageCover: any;
        let inputCardTitleSlide: any;
        let inputCardDescriptionSlide: any;
        let addButtonsSlider: any;
        let inputFile: any;
        let imageFind: any;
        let imageURL: any;
        let labelUploadImage: any;
        let divButtonSlide: any;
        let $buttonSlider: any;
        let deleteButton: any;
        let lengthButtons: any;
        let slideIdInput: any;

        const arrayCarrusel: any = { elements: [] };
        const contenedorSlider = $(`<div class="contenedor"></div>`);

        // const $buttonMenuCarrusel = $('<button class="buttonMenuCarrusel">+ Menú carrusel</button>');
        // const $buttonAddCarrusel = $('<button class="buttonAddCarrusel">+</button>');
        const $containerDiv = $('<div class="type_visual_box carousel"/>');
        const $buttonAddCarrusel = $('<button class="buttonMenuCarrusel">+ item carousel</button>');

        const slideIdModalInput = $('<input type="hidden" class="slide_id">');

        const inputTitleSlider = $('<input type="text" class="titleSlider inputInspectorStyle" placeholder="' + translate.sections.services.title + '">');

        const divTitleSlider = $('<div class="mt-10"></div>');
        const labelTitle = $('<span>Título</span>');
        divTitleSlider.append([labelTitle, inputTitleSlider, slideIdModalInput]);



        const fieldSetModalCarrusel = $('<fieldset class="fieldset mt-10"></fieldset>');
        const textAreaCarousel = $('<textarea class="escape" placeholder="' + translate.sections.services.swipeTo + '" cols="2" rows="2"></textarea>');
        const divRadioURL = $('<div></div>');
        const radioURL = $('<input  class="web_url" type="radio" name="typeSlider" value="web_url">');
        const labelRadioURL = $('<label class="label-radio" for="web_url">URL</label>');
        divRadioURL.append([radioURL, labelRadioURL]);


        const divRadioWEB = $('<div></div>');
        const radioWebview = $('<input class="web_view"  type="radio" name="typeSlider" value="web_view">');

        const labelRadioWEB = $('<label class="label-radio" for="web_view">WebView</label>');
        divRadioWEB.append([radioWebview, labelRadioWEB]);


        const divRadioConexion = $('<div></div>');

        const radioConexion = $('<input class="postback"  type="radio" name="typeSlider" value="postback">');
        const labelRadioConexion = $('<label class="label-radio" for="conexion">'+translate.sections.services.conection+'</label>');
        divRadioConexion.append([radioConexion, labelRadioConexion]);


        const divRadioPhone = $('<div></div>');

        const radioPhone = $('<input class="phone_number"  type="radio" name="typeSlider" value="phone_number">');

        const labelRadioPhone = $('<label class="label-radio" for="phone_number">' + translate.sections.services.phone + '</label>');

        divRadioPhone.append([radioPhone, labelRadioPhone]);
        const inputURL = $('<input type="text" placeholder="' + translate.sections.services.siteUrl + '" class="inputURL">');
        const divUrlSlider = $('<div class="mt-10"></div>');
        const labelURL = $('<span>URL</span>');
        divUrlSlider.append([labelURL, inputURL]);

        divUrlSlider.hide();



        const inputWebview = $('<input type="text" placeholder="URL del webview" class="inputWebview">');

        const divWebSlider = $('<div class="mt-10"></div>');
        const labelWeb = $('<span>URL Webview</span>');
        divWebSlider.append([labelWeb, inputWebview]);

        divWebSlider.hide();


        const inputPhone = $('<input hidden type="text" placeholder="Teléfono" class="input_phone_number">');
        const divPhone = $('<div class="mt-10"></div>');
        const labelPhone = $('<span>' + translate.sections.services.phone + '</span>');
        divPhone.append([labelPhone, inputPhone]);

        divPhone.hide();


        const selectHeightRatio = $(`<select hidden class="select_height_ratio">
        <option value="">${translate.sections.services.Select}</option>
        <option value="compact">${translate.sections.services.compact}</option>
        <option value="tall">${translate.sections.services.high}</option>
        <option value="full">${translate.sections.services.complete}</option>
        </select>`);

        const divSelectRatio = $('<div class="mt-10"></div>');

        const labelHeightRatio = $('<span>Height Ratio</span>');

        divSelectRatio.append([labelHeightRatio, selectHeightRatio]);

        divSelectRatio.hide();




        const validateURL = (url) => {
            if (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(url)) {
                return true;
            }
            return false;
        };
        const ValidatePhone = (phone) => {
            if (/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(phone)) {
                return true;
            }
            return false;
        };

        textAreaCarousel.on('keyup', (e) => {
            arrayCarrusel.content = e.target.value;

            const responseCarousel = {
                elements: arrayCarrusel.elements,
                content: arrayCarrusel.content,
                type: typeCarousel,
                showOutputs: true
            };

            if (
                req.constraints &&
                typeof req.constraints === 'object' &&
                'type' in req.constraints
            ) {
                if ('noOutputs' === req.constraints.type) {
                    responseCarousel.showOutputs = false;
                }
            }

            field.val(JSON.stringify(responseCarousel));
        });
        inputURL.on('keyup', (e) => {
            if (validateURL(e.target.value)) {
                inputURL.removeClass('invalid-input');
                inputURL.addClass('validate-input');
            } else {
                inputURL.removeClass('validate-input');
                inputURL.addClass('invalid-input');
            }
        });

        inputWebview.on('keyup', (e) => {
            if (validateURL(e.target.value)) {
                inputWebview.removeClass('invalid-input');
                inputWebview.addClass('validate-input');
            } else {
                inputWebview.removeClass('validate-input');
                inputWebview.addClass('invalid-input');
            }
        });

        inputPhone.on('keyup', (e) => {
            // console.log(e.target.value);
            if (ValidatePhone(e.target.value)) {
                inputPhone.removeClass('invalid-input');
                inputPhone.addClass('validate-input');

            } else {
                // console.log('invalid input phone');
                inputPhone.removeClass('validate-input');
                inputPhone.addClass('invalid-input');
            }
        });



        let indiceCombination = 0;
        let indiceConditional = 0;
        let indiceNLP = 0;

        let arregloNlp: any = [];
        let arreglocombinations: any = [];
        let arregloConditionals: any = [];

        const arregloVariables: any = [];

        let selectEditNLP: any;
        let buttonModifyNLP: any;

        let Workspace: any;


        // function create modal
        const modal = this.modal.create({
            onClose: (eClose) => {
                eClose.preventDefault();
                eClose.stopPropagation();
                $('input[name="typeSlider"]').prop('checked', false);
                $(eClose.target).parent().find('.titleSlider').val('');
                $(eClose.target).parent().find('.inputURL').val('');
                $(eClose.target).parent().find('.inputWebview').val('');
                $(eClose.target).parent().find('.input_phone_number').val('');
                $(eClose.target).parent().find('.select_height_ratio').val('');
                $(eClose.target).parent().find('.slide_id').val('');

                inputWebview.hide();
                inputURL.hide();
                divUrlSlider.hide();
                inputPhone.hide();
                divPhone.hide();
                selectHeightRatio.hide();

            }
        });


        if (value !== undefined) {
            // console.log('%c value ', 'background: blue; color: white', value);
            Object.keys(value).forEach(key => {
                if ('req_name' !== key && '' !== key) {
                    const fieldValue = value[key];
                    if (fieldValue) {
                        Object.keys(fieldValue).forEach(key2 => {
                            const fv = fieldValue[key2];
                            let json;
                            try{
                                json = JSON.parse(fv.value);
                            } catch (e) { }

                            if (json && json.type === 'jsonS') {
                                // console.log('valor de json slider', json);
                                // if (json.type === 'jsonS') {
                                $buttonAddCarrusel.css('display', 'block');
                                textAreaCarousel.css('display', 'block');
                                textAreaCarousel.val(json.content);


                                if ('elements' in json && json.elements) {




                                    arrayCarrusel.elements = json.elements;
                                    arrayCarrusel.content = json.content;

                                    const responseCarousel = {
                                        elements: arrayCarrusel.elements,
                                        content: arrayCarrusel.content,
                                        type: typeCarousel,
                                        showOutputs: true
                                    };

                                    if (
                                        req.constraints &&
                                        typeof req.constraints === 'object' &&
                                        'type' in req.constraints
                                    ) {
                                        if ('noOutputs' === req.constraints.type) {
                                            responseCarousel.showOutputs = false;
                                        }
                                    }
                                    field.val(JSON.stringify(responseCarousel));
                                }

                                if (json.elements) {
                                    for (let index3 = 0; index3 < json.elements.length; index3++) {
                                        // console.log('url image', json.elements[index3].image_url);


                                        if (json.elements[index3].image_url !== '/assets/img/noimage.png') {
                                            coveredUrl = json.elements[index3].image_url;
                                        } else {
                                            coveredUrl = '/assets/img/noimage.png';
                                        }
                                        cardSlider = $(`<div class="card" data-card-idx="${index3}"></div>`);
                                        deleteCard = $('<a class="deleteCard">×</a>');
                                        inputFile = $(`<input name="document" type="file"/>`);
                                        labelUploadImage = $(`<label class="labelUploadImage">${translate.sections.services.uploadImage}</label>`);

                                        imageCover = $(`<img class="coverCard" src="${coveredUrl}" alt="">`);
                                        inputCardTitleSlide = $('<input \
                                                    class="cardTitleSlide" \
                                                    name="' + name + '_title[]" \
                                                    placeholder="' + translate.sections.services.title + '" \
                                                    type="text"/>');
                                        inputCardDescriptionSlide = $('<input \
                                                    class="cardDescriptionSlide" \
                                                    name="' + name + '_desc[]" \
                                                    placeholder="' + translate.sections.services.swipeTo + ' \
                                                    type="text" />');

                                        inputCardTitleSlide.val(json.elements[index3].title);
                                        inputCardDescriptionSlide.val(json.elements[index3].subtitle);

                                        inputCardTitleSlide.on('focusout', (e) => {
                                            // cardTitleSlide = $(e.target).val();
                                            const iValStr = field.val();
                                            if (iValStr) {
                                                const currentIndexCard = $(e.target).parent().data('cardIdx');
                                                const iVal = JSON.parse(iValStr);
                                                iVal.elements[currentIndexCard].title = $(e.target).val();
                                                console.log($(e.target).val());
                                                console.log(iVal);
                                                field.val(JSON.stringify(iVal));
                                            }
                                        });
                                        inputCardDescriptionSlide.on('focusout', (e) => {
                                            // cardTitleSlide = $(e.target).val();
                                            const iValStr = field.val();
                                            if (iValStr) {
                                                const currentIndexCard = $(e.target).parent().data('cardIdx');
                                                const iVal = JSON.parse(iValStr);
                                                iVal.elements[currentIndexCard].subtitle = $(e.target).val();
                                                console.log($(e.target).val());
                                                console.log(iVal);
                                                field.val(JSON.stringify(iVal));
                                            }
                                        });

                                        addButtonsSlider = $('<button class="addButtonsSlider">+ Botón</button>');

                                        labelUploadImage.append(inputFile);
                                        cardSlider.append(deleteCard);
                                        cardSlider.append(imageCover);
                                        cardSlider.append(labelUploadImage);
                                        cardSlider.append(inputCardTitleSlide);
                                        cardSlider.append(inputCardDescriptionSlide);
                                        cardSlider.append(addButtonsSlider);

                                        // $containerDiv.append(cardSlider);


                                        contenedorSlider.append(cardSlider);
                                        $containerDiv.append(contenedorSlider);
                                        $('body').append($containerDiv);

                                        inputFile.on('change', (eventImage: any) => {

                                            const indexCardLoad = +$(eventImage.target).parent().parent().index();
                                            const id = joint.util.uuid(); // Date.now();

                                            const formFiles = new FormData();
                                            formFiles.append('document', eventImage.target.files[0]);
                                            this.recaptchaV3Service.execute('uploadFile').subscribe((reToken) => {
                                                this.fileService.upload(customerSlug, formFiles, reToken).subscribe({
                                                    next: (response: any) => {
                                                        // console.log(response);
                                                        imageFind = $('.card').eq(indexCardLoad).find('.coverCard');
                                                        imageFind.attr('src', response.url);
                                                        const titleSlide = $('.card').eq(indexCardLoad).find('.cardTitleSlide').val();
                                                        const descriptionSlide = $('.card').eq(indexCardLoad).find('.cardDescriptionSlide').val();
                                                        const objectSliderButton: any = {
                                                            id,
                                                            title: titleSlide,
                                                            image_url: response.url === '/assets/img/noimage.png' ? '' : response.url,
                                                            subtitle: descriptionSlide,
                                                            buttons: arrraybuttonsSlider[indexCardLoad]
                                                        };
                                                        arrayCarrusel.elements[indexCardLoad] = objectSliderButton;
                                                        arrayCarrusel.content = textAreaCarousel.val();
                                                        const responseCarousel = {
                                                            elements: arrayCarrusel.elements,
                                                            content: arrayCarrusel.content,
                                                            type: typeCarousel,
                                                            showOutputs: true
                                                        };

                                                        if (
                                                            req.constraints &&
                                                            typeof req.constraints === 'object' &&
                                                            'type' in req.constraints
                                                        ) {
                                                            if ('noOutputs' === req.constraints.type) {
                                                                responseCarousel.showOutputs = false;
                                                            }
                                                        }

                                                        field.val(JSON.stringify(responseCarousel));
                                                        // console.log(JSON.stringify(arrayCarrusel));
                                                    },
                                                    error: (err) => {
                                                        this.alertService.error('Hubo un problema al subir el documento.');
                                                        console.error('Error:', err);
                                                    }
                                                });
                                            });
                                        });


                                        const btns = json.elements[index3].buttons;

                                        arrraybuttonsSlider[index3] = [];
                                        arrraybuttonsSlider[index3] = btns;


                                        for (let indexS = 0; indexS < btns.length; indexS++) {

                                            divButtonSlide = $('<div class="divButtonSlide"></div>');
                                            $buttonSlider = $('<a class="show_modal btnSlider"></a>');
                                            $spanButtonSlide = $(`<span hidden></span>`);
                                            deleteButton = $('<a class="deleteButtonSlider">×</a>');
                                            slideIdInput = $('<input type="hidden" class="slide_id">');
                                            slideIdInput.val(btns[indexS].id);

                                            divButtonSlide.append($buttonSlider);
                                            divButtonSlide.append(deleteButton);
                                            divButtonSlide.append($spanButtonSlide);
                                            divButtonSlide.append(slideIdInput);

                                            lengthButtons = $('.card').eq(index3).find('.btnSlider');

                                            $('.card').eq(index3).append(divButtonSlide);
                                            const loadTitleSliderr = btns[indexS].title;

                                            $('.card').eq(index3).find('.btnSlider').eq(indexS).text(loadTitleSliderr);

                                            $spanButtonSlide.text(lengthButtons.length);



                                            deleteButton.on('click', (eventDeleteButtonLoaded) => {

                                                const cardIndex = $(eventDeleteButtonLoaded.target).parent().parent().index();
                                                indexbtnSlider = +$(eventDeleteButtonLoaded.target).siblings().eq(1).text();

                                                // console.log('number card');
                                                // console.log(cardIndex);
                                                // console.log('number button');
                                                // console.log(indexbtnSlider);

                                                $(eventDeleteButtonLoaded.target).slideUp('fast', () => {
                                                    $(eventDeleteButtonLoaded.target).parent().remove();
                                                });

                                                // delete button slide of arraycarrusel
                                                arrayCarrusel.elements[cardIndex].buttons.splice(indexbtnSlider, 1);

                                                // console.log(arrayCarrusel);
                                                const responseCarousel = {
                                                    elements: arrayCarrusel.elements,
                                                    content: arrayCarrusel.content,
                                                    type: typeCarousel,
                                                    showOutputs: true
                                                };

                                                if (
                                                    req.constraints &&
                                                    typeof req.constraints === 'object' &&
                                                    'type' in req.constraints
                                                ) {
                                                    if ('noOutputs' === req.constraints.type) {
                                                        responseCarousel.showOutputs = false;
                                                    }
                                                }
                                                field.val(JSON.stringify(responseCarousel));

                                            });

                                            $buttonSlider.on('click', (eventButtonSliderLoaded) => {
                                                imageURL = $(eventButtonSliderLoaded.target).parent().siblings().eq(1).attr('src');
                                                // console.log('json');
                                                // console.log(json.elements);

                                                modal.show();

                                                indexbtnSlider = +$(eventButtonSliderLoaded.target).siblings().eq(1).text();

                                                // console.log('index button');
                                                // console.log(indexbtnSlider);

                                                indexCard = $(eventButtonSliderLoaded.target).parent().parent().index();
                                                // console.log('index card');
                                                // console.log(indexCard);
                                                const btns2 = json.elements[indexCard].buttons;
                                                // console.log('show datos"');
                                                // console.log(btns2);
                                                const loadTitleSlider = btns2[indexbtnSlider].title;
                                                // console.log('load title slider');

                                                // console.log(loadTitleSlider);
                                                // load data
                                                const radioSelected = btns2[indexbtnSlider].type;

                                                inputTitleSlider.val(loadTitleSlider);
                                                $(`.${radioSelected}`).prop('checked', true);

                                                typeSlider = ($('input:radio[name=typeSlider]:checked').val());
                                                // console.log('get radio');

                                                // console.log(typeSlider);

                                                const slideId2 = $(eventButtonSliderLoaded.target).parent().find('.slide_id').val();
                                                slideIdModalInput.val(slideId2);


                                                if (typeSlider === 'web_url') {
                                                    const loadTitleURL = btns2[indexbtnSlider].url;
                                                    selectHeightRatio.hide();
                                                    divSelectRatio.hide();
                                                    inputPhone.hide();
                                                    divPhone.hide();
                                                    inputWebview.hide();
                                                    divWebSlider.hide();
                                                    inputURL.show();
                                                    divUrlSlider.show();
                                                    inputURL.val(loadTitleURL);
                                                }
                                                if (typeSlider === 'web_view') {
                                                    const loadTitleWEB = btns2[indexbtnSlider].url;
                                                    const loadOptionRatio = btns2[indexbtnSlider].webview_height_ratio;
                                                    inputPhone.hide();
                                                    divPhone.hide();
                                                    divUrlSlider.hide();
                                                    inputURL.hide();
                                                    divWebSlider.show();
                                                    inputWebview.show();
                                                    divWebSlider.show();
                                                    divSelectRatio.show();
                                                    selectHeightRatio.show();
                                                    inputWebview.val(loadTitleWEB);
                                                    selectHeightRatio.val(loadOptionRatio);
                                                }
                                                if (typeSlider === 'postback') {
                                                    divUrlSlider.hide();
                                                    inputURL.hide();
                                                    inputWebview.hide();
                                                    divWebSlider.hide();
                                                    divSelectRatio.hide();
                                                    selectHeightRatio.hide();
                                                    inputPhone.hide();
                                                    divPhone.hide();
                                                    inputTitleSlider.val(loadTitleSlider);

                                                }
                                                if (typeSlider === 'phone_number') {
                                                    const loadPhoneNumber = btns2[indexbtnSlider].value;
                                                    inputURL.hide();
                                                    divUrlSlider.hide();
                                                    inputWebview.hide();
                                                    divWebSlider.hide();
                                                    divSelectRatio.hide();
                                                    selectHeightRatio.hide();
                                                    inputPhone.show();
                                                    divPhone.show();
                                                    inputPhone.val(loadPhoneNumber);
                                                }

                                            });



                                        }



                                        // begin
                                        addButtonsSlider.on('click', (ev) => {

                                            // corregir
                                            // index = $(ev.target).parent('.card').index();

                                            const cardBox = $(ev.target).parent();

                                            // const buttonAddHidden = cardBox.parent().find('.card').eq(index).find('.addButtonsSlider');
                                            const listCardBox = cardBox.parent().find('.card');
                                            index = listCardBox.index(cardBox);





                                            const buttonAddHidden = $('.card').eq(index).find('.addButtonsSlider');
                                            lengthButtons = $('.card').eq(index).find('.btnSlider');

                                            if (lengthButtons.length < 3) {
                                                // console.log('index  card ');
                                                // console.log(index);
                                                divButtonSlide = $('<div class="divButtonSlide"></div>');
                                                $buttonSlider = $('<a class="show_modal btnSlider"></a>');
                                                $spanButtonSlide = $(`<span hidden></span>`);
                                                deleteButton = $('<a class="deleteButtonSlider">×</a>');
                                                divButtonSlide.append($buttonSlider);
                                                divButtonSlide.append(deleteButton);
                                                divButtonSlide.append($spanButtonSlide);
                                                $('.card').eq(index).append(divButtonSlide);

                                                $buttonSlider.text(`Botón ${$(lengthButtons).length + 1}`);
                                                $spanButtonSlide.text($(lengthButtons).length);



                                                deleteButton.on('click', (eventDeleteButtonSliderLoaded) => {

                                                    const cardBox = $(ev.target).parent();
                                                    const listCardBox = cardBox.parent().find('.card');
                                                    const index = listCardBox.index(cardBox);
                                                    const lengthButtons = $('.card').eq(index).find('.btnSlider');

                                                    const idxBtnSlider = +$(eventDeleteButtonSliderLoaded.target).siblings().eq(1).text();

                                                    // console.log('card index');
                                                    // console.log(index);

                                                    // console.log('value button index');
                                                    // console.log(idxBtnSlider);

                                                    $(eventDeleteButtonSliderLoaded.target).slideUp('fast', () => {
                                                        $(eventDeleteButtonSliderLoaded.target).parent().remove();
                                                        if (lengthButtons.length - 1 <= 2) {
                                                            buttonAddHidden.show();
                                                        } else {
                                                            buttonAddHidden.hide();

                                                        }
                                                    });

                                                    // delete data arrayCarrusel
                                                    try {
                                                        const btns3 = arrayCarrusel.elements[index].buttons;
                                                        btns3.splice(idxBtnSlider, 1);
                                                        // console.log(arrayCarrusel);
                                                    } catch (error) {


                                                    }

                                                    const responseCarousel = {
                                                        elements: arrayCarrusel.elements,
                                                        content: arrayCarrusel.content,
                                                        type: typeCarousel,
                                                        showOutputs: true
                                                    };

                                                    if (
                                                        req.constraints &&
                                                        typeof req.constraints === 'object' &&
                                                        'type' in req.constraints
                                                    ) {
                                                        if ('noOutputs' === req.constraints.type) {
                                                            responseCarousel.showOutputs = false;
                                                        }
                                                    }
                                                    field.val(JSON.stringify(responseCarousel));

                                                });

                                                // TODO: check



                                                $buttonSlider.on('click', (eventButtonSlider) => {
                                                    modal.show();


                                                    // clear data
                                                    $('input[name="typeSlider"]').prop('checked', false);

                                                    $('.titleSlider').val('');
                                                    $('.inputURL').val('');
                                                    $('.inputWebview').val('');
                                                    $('.select_height_ratio').val('');
                                                    $('.input_phone_number').val('');

                                                    inputWebview.hide();
                                                    inputURL.hide();
                                                    divUrlSlider.hide();
                                                    divWebSlider.hide();

                                                    inputPhone.hide();
                                                    divPhone.hide();
                                                    divSelectRatio.hide();
                                                    selectHeightRatio.hide();

                                                    //

                                                    imageURL = $(eventButtonSlider.target).parent().siblings().eq(1).attr('src');
                                                    // console.log(imageURL);
                                                    indexbtnSlider = +$(eventButtonSlider.target).siblings().eq(1).text();

                                                    // console.log('index button');
                                                    // console.log(indexbtnSlider);

                                                    indexCard = $(eventButtonSlider.target).parent().parent().index();
                                                    // console.log('index card');
                                                    // console.log(indexCard);

                                                    try {
                                                        const dataBntSlider = arrayCarrusel.elements[indexCard].buttons[indexbtnSlider];
                                                        // console.log('datos cargados');

                                                        // console.log(dataBntSlider);

                                                        $('.titleSlider').val(dataBntSlider.title);
                                                        $(`.${dataBntSlider.type}`).prop('checked', 'true');

                                                        if (dataBntSlider.type === 'web_url') {
                                                            inputWebview.hide();
                                                            divWebSlider.hide();
                                                            divPhone.hide();
                                                            inputURL.show();
                                                            divUrlSlider.show();
                                                            inputURL.val(dataBntSlider.url);

                                                        } else if (dataBntSlider.type === 'web_view') {
                                                            divUrlSlider.hide();
                                                            inputURL.hide();
                                                            divPhone.hide();
                                                            inputWebview.show();
                                                            divWebSlider.show();
                                                            inputWebview.val(dataBntSlider.url);
                                                            divSelectRatio.show();
                                                            selectHeightRatio.show();
                                                            selectHeightRatio.val(dataBntSlider.webview_height_ratio);
                                                        } else if (dataBntSlider.type === 'phone_number') {
                                                            inputURL.hide();
                                                            divUrlSlider.hide();
                                                            inputWebview.hide();
                                                            divWebSlider.hide();
                                                            inputPhone.show();
                                                            divPhone.show();
                                                            inputPhone.val(dataBntSlider.value);
                                                        }

                                                    } catch (error) {

                                                    }

                                                });
                                            }

                                            if (lengthButtons.length === 2) {
                                                buttonAddHidden.hide();
                                            }
                                        });

                                        // end
                                        deleteCard.on('click', (eventDeleteCard) => {
                                            const indexDeleteCard = $(eventDeleteCard.target).parent().index();
                                            // console.log('index card to delete');
                                            // console.log(indexDeleteCard);
                                            // console.log('slide deleted');

                                            const cardParent = $(eventDeleteCard.target).parent();
                                            $(cardParent).slideUp('fast', () => {
                                                $(cardParent).remove();
                                            });

                                            arrayCarrusel.elements.splice(indexDeleteCard, 1);
                                            arrayCarrusel.content = json.content;
                                            // console.log(arrayCarrusel);

                                            const responseCarousel = {
                                                elements: arrayCarrusel.elements,
                                                content: arrayCarrusel.content,
                                                type: typeCarousel,
                                                showOutputs: true
                                            };

                                            if (
                                                req.constraints &&
                                                typeof req.constraints === 'object' &&
                                                'type' in req.constraints
                                            ) {
                                                if ('noOutputs' === req.constraints.type) {
                                                    responseCarousel.showOutputs = false;
                                                }
                                            }

                                            field.val(JSON.stringify(responseCarousel));
                                            // console.log('val json');
                                            // console.log(JSON.stringify(responseCarousel));



                                        });

                                    }


                                }
                                // }
                            }
                        });
                    }
                }
            });
        }

        const nameClass = field.attr('name').replace('#', '-');
        $containerDiv.addClass(nameClass);

        // append modal carrusel
        // $containerDiv.append($buttonMenuCarrusel);
        $containerDiv.prepend([textAreaCarousel, $buttonAddCarrusel, field]);




        // input inserted
        // modal.box.append(inputTitleSlider);

        // test

        const titleSliderModal = $('<p class="titleSliderModal">Slider</p>');
        modal.box.append(titleSliderModal);
        modal.box.append(divTitleSlider);
        //
        fieldSetModalCarrusel.append(divRadioURL);
        fieldSetModalCarrusel.append(divRadioWEB);
        fieldSetModalCarrusel.append(divRadioConexion);
        fieldSetModalCarrusel.append(divRadioPhone);

        modal.box.append(fieldSetModalCarrusel);
        // modal.box.append(inputURL);
        modal.box.append(divUrlSlider);

        // modal.box.append(inputWebview);
        modal.box.append(divWebSlider);
        // modal.box.append(inputPhone);
        modal.box.append(divPhone);
        modal.box.append(divSelectRatio);
        // modal.box.append(selectHeightRatio);


        const buttonSaveCarousel = $('<button class="btn-primary btn-saveC">Guardar</button>');
        modal.box.append(buttonSaveCarousel);

        // test button next slider

        const divButtonsGroup = $('<div style="margin-top:10px"></div>')
        // const buttonNext = $('<button class="btn-primary">Guardar y crear siguiente Botón</button>');


        // const buttonNextCardSlider = $('<button class="btn-primary ml-1">Siguiente Slider</button>');

        // divButtonsGroup.append([buttonNext,buttonNextCardSlider]);

        /* modal.box.append(buttonNext); */
        modal.box.append(divButtonsGroup);

        buttonSaveCarousel.on('click', (eClose) => {
            // console.log('onclose modal carousel');
            eClose.preventDefault();
            eClose.stopPropagation();
            // console.log(indexCard);
            // console.log('indice del buttons slider');

            // console.log(indexbtnSlider);

            const parentClose = $(eClose.target).parent();


            // const id = joint.util.uuid(); // Date.now();

            typeSlider = ($('input:radio[name=typeSlider]:checked').val());
            titleSlider = parentClose.find('.titleSlider').val();
            titleURL = parentClose.find('.inputURL').val();
            titleWebview = parentClose.find('.inputWebview').val();
            phoneValue = parentClose.find('.input_phone_number').val();
            selectRatioValue = parentClose.find('.select_height_ratio').val();
            contentTextarea = textAreaCarousel.val();
            cardTitleSlide = parentClose.parent().find('.card').eq(indexCard).find('.cardTitleSlide').val();
            cardDescriptionSlide = parentClose.parent().find('.card').eq(indexCard).find('.cardDescriptionSlide').val();
            const slideId = parentClose.find('.slide_id').val();

            // l$('.card').eq(indexCard);
            const findButtonS = $('.card').eq(indexCard).find('.btnSlider').eq(indexbtnSlider);
            // console.log(findButtonS);

            // console.log('type slider');
            // console.log(typeSlider);


            if (titleSlider !== '') {
                findButtonS.text(titleSlider);

                // replace data if exist
                const objectButton: any = {
                    type: typeSlider,
                    title: titleSlider,
                    id: slideId
                };

                if (typeSlider === 'web_url') {
                    objectButton.url = titleURL;

                } else if (typeSlider === 'web_view') {
                    objectButton.url = titleWebview;
                    objectButton.webview_height_ratio = selectRatioValue;
                    objectButton.messenger_extensions = true;
                    objectButton.fallback_url = titleWebview;
                } else if (typeSlider === 'phone_number') {
                    objectButton.value = phoneValue;

                } else if (typeSlider === 'postback') {
                    objectButton.value = '';
                    objectButton.block_id = slideId;
                }

                arrraybuttonsSlider[indexCard][indexbtnSlider] = objectButton;

                const objectSliderButton = {
                    // id: slideId,
                    title: cardTitleSlide,
                    image_url: imageURL === '/assets/img/noimage.png' ? '' : imageURL,
                    subtitle: cardDescriptionSlide,
                    buttons: arrraybuttonsSlider[indexCard]
                };


                // console.log(' %c arrayCarrusel', 'background: purple; color: white', arrayCarrusel);
                // console.log(' %c indexCard', 'background: purple; color: white', indexCard);
                arrayCarrusel.elements[indexCard] = objectSliderButton;
                arrayCarrusel.content = contentTextarea;
                lengthButtons = $('.card').eq(index).find('.btnSlider');


                const responseCarousel = {
                    elements: arrayCarrusel.elements,
                    content: arrayCarrusel.content,
                    type: typeCarousel,
                    showOutputs: true
                };

                if (
                    req.constraints &&
                    typeof req.constraints === 'object' &&
                    'type' in req.constraints
                ) {
                    if ('noOutputs' === req.constraints.type) {
                        responseCarousel.showOutputs = false;
                    }
                }

                // localStorage.setItem('lastPropertyValue', JSON.stringify(responseCarousel));

                field.val(JSON.stringify(responseCarousel));

                // console.log('value input carousel', field.val());

                // console.log('json array carousel');
                // console.log(JSON.stringify(arrayCarrusel));

                // end
            }
            $('input[name="typeSlider"]').prop('checked', false);
            $(eClose.target).parent().find('.titleSlider').val('');
            $(eClose.target).parent().find('.inputURL').val('');
            $(eClose.target).parent().find('.inputWebview').val('');
            $(eClose.target).parent().find('.input_phone_number').val('');
            $(eClose.target).parent().find('.select_height_ratio').val('');

            inputWebview.hide();
            inputURL.hide();
            divUrlSlider.hide();
            inputPhone.hide();
            divPhone.hide();
            selectHeightRatio.hide();
            modal.hide();
        });

        $('body').append([modal.box, modal.blocker]);


        labelRadioURL.on('click', (e) => {

            console.log('CLICK LABEL CAROUSEL');

            radioURL.prop('checked', true);
            // $(this).siblings(1).prop('checked', true);
            if (radioURL.is(':checked')) {
                divUrlSlider.show();
                inputURL.show();
                inputWebview.hide();
                divWebSlider.hide();
                inputPhone.hide();
                divPhone.hide();
                inputURL.val('');
                inputWebview.val('');
                inputPhone.val('');
                inputWebview.css('outline', 'none');
                inputPhone.css('outline', 'none');
                selectHeightRatio.hide();
                divSelectRatio.hide();
                selectHeightRatio.val('');
            }

        });

        radioURL.on('click', (e) => {
            radioURL.prop('checked', true);
            if (radioURL.is(':checked')) {
                divUrlSlider.show();
                inputURL.show();
                inputWebview.hide();
                divWebSlider.hide();
                inputPhone.hide();
                divPhone.hide();
                inputURL.val('');
                inputWebview.val('');
                inputPhone.val('');
                inputWebview.css('outline', 'none');
                inputPhone.css('outline', 'none');
                selectHeightRatio.hide();
                divSelectRatio.hide();
                selectHeightRatio.val('');
            }

        });

        labelRadioWEB.on('click', (e) => {
            radioWebview.prop('checked', true);
            // $(this).siblings(1).prop('checked', true);
            if (radioWebview.is(':checked')) {
                inputWebview.show();
                divWebSlider.show();
                divSelectRatio.show();
                selectHeightRatio.show();
                inputURL.hide();
                divUrlSlider.hide();
                inputPhone.hide();
                divPhone.hide();
                inputURL.val('');
                inputPhone.val('');
                inputURL.css('outline', 'none');
                inputPhone.css('outline', 'none');
            }

        });

        radioWebview.on('click', (e) => {
            radioWebview.prop('checked', true);
            if (radioWebview.is(':checked')) {
                inputWebview.show();
                divWebSlider.show();
                divSelectRatio.show();
                selectHeightRatio.show();
                inputURL.hide();
                divUrlSlider.hide();
                inputPhone.hide();
                divPhone.hide();
                inputURL.val('');
                inputPhone.val('');
                inputURL.css('outline', 'none');
                inputPhone.css('outline', 'none');
            }

        });


        labelRadioConexion.on('click', (e) => {
            radioConexion.prop('checked', true);
            if (radioConexion.is(':checked')) {
                inputWebview.hide();
                divWebSlider.hide();
                inputURL.hide();
                divUrlSlider.hide();
                inputPhone.hide();
                divPhone.hide();
                inputWebview.val('');
                inputURL.val('');
                inputPhone.val('');
                selectHeightRatio.val('');
                inputURL.css('outline', 'none');
                inputPhone.css('outline', 'none');
                inputWebview.css('outline', 'none');
                divSelectRatio.hide();
                selectHeightRatio.hide();
            }

        });

        radioConexion.on('click', (e) => {
            radioConexion.prop('checked', true);
            if (radioConexion.is(':checked')) {
                inputWebview.hide();
                divWebSlider.hide();
                inputURL.hide();
                divUrlSlider.hide();
                inputPhone.hide();
                divPhone.hide();
                inputWebview.val('');
                inputURL.val('');
                inputPhone.val('');
                selectHeightRatio.val('');
                inputURL.css('outline', 'none');
                inputPhone.css('outline', 'none');
                inputWebview.css('outline', 'none');
                divSelectRatio.hide();
                selectHeightRatio.hide();
            }

        });


        labelRadioPhone.on('click', (e) => {
            // $(this).siblings(1).prop('checked', true);
            radioPhone.prop('checked', true);
            if (radioPhone.is(':checked')) {
                inputURL.hide();
                divUrlSlider.hide();
                inputWebview.hide();
                divWebSlider.hide();
                inputPhone.show();
                divPhone.show();
                divSelectRatio.hide();
                selectHeightRatio.hide();
                inputURL.val('');
                inputWebview.val('');
                selectHeightRatio.val('');
                inputURL.css('outline', 'none');
                inputWebview.css('outline', 'none');
            }

        });

        radioPhone.on('click', (e) => {
            radioPhone.prop('checked', true);
            if (radioPhone.is(':checked')) {
                inputURL.hide();
                divUrlSlider.hide();
                inputWebview.hide();
                divWebSlider.hide();
                inputPhone.show();
                divPhone.show();
                divSelectRatio.hide();
                selectHeightRatio.hide();
                inputURL.val('');
                inputWebview.val('');
                selectHeightRatio.val('');
                inputURL.css('outline', 'none');
                inputWebview.css('outline', 'none');
            }

        });


        // const contenedorSlider = $(`<div class="contenedor"></div>`);

        $buttonAddCarrusel.on('click', () => {

            const indexCard2 = $('.card').length;


            indexCard = indexCard2;   // test

            arrraybuttonsSlider[indexCard2] = [];
            // console.log(' %c indexCard2', 'background: blue; color: white', indexCard2);

            coveredUrl = '/assets/img/noimage.png';
            cardSlider = $(`<div class="card" data-card-idx="${indexCard2}"></div>`);
            deleteCard = $('<a class="deleteCard">×</a>');
            inputFile = $(`<input id="file-upload" name="document" type="file"/>`);
            labelUploadImage = $(`<label class="labelUploadImage">`+ translate.sections.services.uploadImage+ `</label>`);
            imageCover = $(`<img class="coverCard" src="${coveredUrl}" alt="">`);
            inputCardTitleSlide = $('<input \
                class="cardTitleSlide" \
                name="' + name + '_title[]" \
                placeholder="'+ translate.sections.services.title+ '" \
                type="text"/>');
            inputCardDescriptionSlide = $(`<input \
                class="cardDescriptionSlide" \
                name="' + name + '_desc[]" \
                placeholder="`+ translate.sections.services.description+ `" \
                type="text" />`);
            addButtonsSlider = $('<button class="addButtonsSlider">+ '+ translate.sections.services.button+ '</button>');

            labelUploadImage.append(inputFile);

            cardSlider.append(deleteCard);
            cardSlider.append(imageCover);
            cardSlider.append(labelUploadImage);
            cardSlider.append(inputCardTitleSlide);
            cardSlider.append(inputCardDescriptionSlide);
            cardSlider.append(addButtonsSlider);



            if ($('.card').length < 10) {


                contenedorSlider.append(cardSlider);

                $containerDiv.append(contenedorSlider);
                // code update

                try {
                    const boxSlider = $containerDiv.find('.contenedor');

                    const sortable = Sortable.create(boxSlider.get(0), {
                        onEnd: (evt) => {
                            // console.log('onend slider');

                            const orderArraySlider = this.arraymove(arrayCarrusel.elements, evt.oldIndex, evt.newIndex);
                            arrayCarrusel.elements = orderArraySlider;
                            // console.log('value order array sliders ', arrayCarrusel.elements);


                            const orderArrayButtons = this.arraymove(arrraybuttonsSlider, evt.oldIndex, evt.newIndex);
                            arrraybuttonsSlider = orderArrayButtons;
                            // console.log('value order array buttons', arrraybuttonsSlider);
                            const responseCarousel = {
                                elements: arrayCarrusel.elements,
                                content: arrayCarrusel.content,
                                type: typeCarousel,
                                showOutputs: true
                            };

                            if (
                                req.constraints &&
                                typeof req.constraints === 'object' &&
                                'type' in req.constraints
                            ) {
                                if ('noOutputs' === req.constraints.type) {
                                    responseCarousel.showOutputs = false;
                                }
                            }

                            field.val(JSON.stringify(responseCarousel));

                            // console.log('value input hidden carousel', field.val());

                        }
                    });
                } catch (error) {

                }


                // $containerDiv.append(cardSlider);
            }

            $(inputFile).on('change', (eventLoadImage: any) => {
                const cardBox = $(eventLoadImage.target).parent().parent();
                const listCardBox = cardBox.parent().find('.card');
                const indexCardLoader = listCardBox.index(cardBox);
                const id = joint.util.uuid(); // Date.now();

                const formFiles = new FormData();
                formFiles.append('document', eventLoadImage.target.files[0]);

                this.recaptchaV3Service.execute('uploadFile').subscribe((reToken) => {
                    this.fileService.upload(customerSlug, formFiles, reToken).subscribe({
                        next: (response: any) => {
                            imageFind = $('.card').eq(indexCardLoader).find('.coverCard');
                            imageFind.attr('src', response.url);
                            const titleSlide = $('.card').eq(indexCardLoader).find('.cardTitleSlide').val();
                            const descriptionSlide = $('.card').eq(indexCardLoader).find('.cardDescriptionSlide').val();

                            const objectSliderButton: any = {
                                id,
                                title: titleSlide,
                                image_url: response.url === '/assets/img/noimage.png' ? '' : response.url,
                                subtitle: descriptionSlide,
                                buttons: arrraybuttonsSlider[indexCardLoader]
                            };
                            arrayCarrusel.elements[indexCardLoader] = objectSliderButton;
                            arrayCarrusel.content = textAreaCarousel.val();
                            const responseCarousel = {
                                elements: arrayCarrusel.elements,
                                content: arrayCarrusel.content,
                                type: typeCarousel,
                                showOutputs: true
                            };

                            if (
                                req.constraints &&
                                typeof req.constraints === 'object' &&
                                'type' in req.constraints
                            ) {
                                if ('noOutputs' === req.constraints.type) {
                                    responseCarousel.showOutputs = false;
                                }
                            }

                            field.val(JSON.stringify(responseCarousel));
                            // console.log('json carrusel');
                            // console.log(JSON.stringify(arrayCarrusel));
                        },
                        error: (err) => {
                            this.alertService.error('Hubo un problema al subir el documento.');
                            console.error('Error:', err);
                        }
                    });
                });
            });


            deleteCard.on('click', (e) => {
                const cardBox = $(e.target).parent();
                const listCardBox = cardBox.parent().find('.card');
                // const cardParent = $(e.target).parent();
                const indexDeleteCard = listCardBox.index(cardBox);

                // console.log('index card to delete');
                // console.log(indexDeleteCard);
                // console.log('slide deleted');

                arrayCarrusel.elements.splice(indexDeleteCard, 1);
                // console.log(arrayCarrusel);

                const responseCarousel = {
                    elements: arrayCarrusel.elements,
                    content: arrayCarrusel.content,
                    type: typeCarousel,
                    showOutputs: true
                };

                if (
                    req.constraints &&
                    typeof req.constraints === 'object' &&
                    'type' in req.constraints
                ) {
                    if ('noOutputs' === req.constraints.type) {
                        responseCarousel.showOutputs = false;
                    }
                }
                field.val(JSON.stringify(responseCarousel));

                cardBox.slideUp('fast', () => {
                    cardBox.remove();
                });

            });

            addButtonsSlider.on('click', (eventAddButtonsSlider) => {
                const cardBox = $(eventAddButtonsSlider.target).parent();
                const buttonAddHidden = cardBox.parent().find('.card').eq(index).find('.addButtonsSlider');
                const listCardBox = cardBox.parent().find('.card');
                index = listCardBox.index(cardBox);


                lengthButtons = $('.card').eq(index).find('.btnSlider');
                // console.log('index card', indexCard);

                if (lengthButtons.length < 3) {
                    // buttonNext.prop('disabled', false);
                    // console.log('index  card ');
                    // console.log(index);

                    divButtonSlide = $('<div class="divButtonSlide"></div>');
                    $buttonSlider = $('<a class="show_modal btnSlider"></a>');
                    $spanButtonSlide = $(`<span hidden class="spanButtonReply"></span>`);
                    deleteButton = $('<a class="deleteButtonSlider">×</a>');
                    slideIdInput = $('<input type="hidden" class="slide_id">');
                    slideIdInput.val(joint.util.uuid());

                    divButtonSlide.append($buttonSlider);
                    divButtonSlide.append(deleteButton);
                    divButtonSlide.append($spanButtonSlide);
                    divButtonSlide.append(slideIdInput);

                    $('.card').eq(index).append(divButtonSlide);

                    $buttonSlider.text(`Botón ${lengthButtons.length + 1}`);
                    $spanButtonSlide.text(lengthButtons.length);


                    deleteButton.on('click', (eventDeleteButtonSlider) => {
                        const cardBox2 = $(eventDeleteButtonSlider.target).parent().parent();
                        const listCardBox2 = cardBox.parent().find('.card');
                        const cardIndex = listCardBox2.index(cardBox2);
                        const indexButtonSlider: any = $(eventDeleteButtonSlider.target).siblings().eq(1).text();
                        // console.log('index card');
                        // console.log(cardIndex);
                        // console.log('value button slide');
                        // console.log(indexButtonSlider);


                        const lengthButtons = $('.card').eq(cardIndex).find('.btnSlider');

                        $(eventDeleteButtonSlider.target).slideUp('fast', () => {
                            $(eventDeleteButtonSlider.target).parent().remove();
                        });
                        if (lengthButtons.length - 1 <= 2) {

                            buttonAddHidden.show();
                        }

                        try {
                            arrayCarrusel.elements[cardIndex].buttons.splice(indexButtonSlider, 1);

                            // console.log(arrayCarrusel);

                        } catch (error) {

                        }
                        const responseCarousel = {
                            elements: arrayCarrusel.elements,
                            content: arrayCarrusel.content,
                            type: typeCarousel,
                            showOutputs: true
                        };

                        if (
                            req.constraints &&
                            typeof req.constraints === 'object' &&
                            'type' in req.constraints
                        ) {
                            if ('noOutputs' === req.constraints.type) {
                                responseCarousel.showOutputs = false;
                            }
                        }
                        field.val(JSON.stringify(responseCarousel));
                    });

                    $buttonSlider.on('click', (eventButtonSlide) => {
                        modal.show();
                        imageURL = $(eventButtonSlide.target).parent().siblings().eq(1).attr('src');
                        indexbtnSlider = +$(eventButtonSlide.target).siblings().eq(1).text();
                        const cardBox8 = $(eventButtonSlide.target).parent().parent();
                        const listCardBox9 = cardBox8.parent().find('.card');
                        indexCard = listCardBox9.index(cardBox8);

                        //console.log($(eventButtonSlide.target).parent().find('.slide_id'));
                        const slideId = $(eventButtonSlide.target).parent().find('.slide_id').val();
                        slideIdModalInput.val(slideId);



                        const cantidadbotones = $('.card').eq(indexCard).find('.divButtonSlide').length;
                        // console.log(cantidadbotones);

                        // if (cantidadbotones === 3) {
                        //     buttonNext.prop('disabled', true);

                        // }



                        // clear value
                        $('input[name="typeSlider"]').prop('checked', false);

                        $('.titleSlider').val('');
                        $('.inputURL').val('');
                        $('.inputWebview').val('');
                        $('.select_height_ratio').val('');
                        $('.input_phone_number').val('');

                        inputWebview.hide();
                        inputURL.hide();
                        divUrlSlider.hide();
                        divWebSlider.hide();

                        inputPhone.hide();
                        divPhone.hide();
                        divSelectRatio.hide();
                        selectHeightRatio.hide();

                        // console.log('index card', indexCard);


                        try {
                            const dataBtnSlider = arrayCarrusel.elements[indexCard].buttons[indexbtnSlider];
                            // console.log('datos cargados');

                            // console.log(dataBtnSlider);

                            $('.titleSlider').val(dataBtnSlider.title);
                            $(`.${dataBtnSlider.type}`).prop('checked', 'true');

                            if (dataBtnSlider.type === 'web_url') {
                                inputWebview.hide();
                                divWebSlider.hide();
                                divSelectRatio.hide();
                                divUrlSlider.show();
                                inputURL.show();
                                inputURL.val(dataBtnSlider.url);

                            } else if (dataBtnSlider.type === 'web_view') {
                                inputURL.hide();
                                divUrlSlider.hide();
                                inputWebview.show();
                                divWebSlider.show();
                                inputWebview.val(dataBtnSlider.url);
                                divSelectRatio.show();
                                selectHeightRatio.show();
                                selectHeightRatio.val(dataBtnSlider.webview_height_ratio);
                            } else if (dataBtnSlider.type === 'phone_number') {
                                inputURL.hide();
                                divSelectRatio.hide();
                                divUrlSlider.hide();
                                inputWebview.hide();
                                divWebSlider.hide();
                                inputPhone.show();
                                divPhone.show();
                                inputPhone.val(dataBtnSlider.value);
                            } else if (dataBtnSlider.type === 'postback') {
                                divWebSlider.hide();
                                divPhone.hide();
                                divUrlSlider.hide();
                                divSelectRatio.hide();

                            }

                        } catch (error) {

                        }
                    });

                }

                if (lengthButtons.length === 2) {
                    buttonAddHidden.hide();
                }
            });

        });

        try {
            const boxSlider = $containerDiv.find('.contenedor');

            const sortable = Sortable.create(boxSlider.get(0), {
                onEnd: (evt) => {
                    // console.log('onend slider');

                    const orderArraySlider = this.arraymove(arrayCarrusel.elements, evt.oldIndex, evt.newIndex);
                    arrayCarrusel.elements = orderArraySlider;
                    // console.log('value order array sliders ', arrayCarrusel.elements);


                    const orderArrayButtons = this.arraymove(arrraybuttonsSlider, evt.oldIndex, evt.newIndex);
                    arrraybuttonsSlider = orderArrayButtons;
                    // console.log('value order array buttons', arrraybuttonsSlider);
                    const responseCarousel = {
                        elements: arrayCarrusel.elements,
                        content: arrayCarrusel.content,
                        type: typeCarousel,
                        showOutputs: true
                    };

                    if (
                        req.constraints &&
                        typeof req.constraints === 'object' &&
                        'type' in req.constraints
                    ) {
                        if ('noOutputs' === req.constraints.type) {
                            responseCarousel.showOutputs = false;
                        }
                    }

                    field.val(JSON.stringify(responseCarousel));

                    // console.log('value input carousel', field.val());

                }
            });
        } catch (error) {

        }

        return $containerDiv;
    }

    getCarouselDynamic(alert, fieldJM, req, value) {
        this.alertService = alert;
        let result;

        let nameFigure;


        const removeCharacters = (stringP) => {

            const del1 = stringP.replace('{{', '');
            const del2 = del1.replace('}}', '');
            return del2;
        };

        const removeEach = (stringParameter) => {

            const remove1 = stringParameter.replace(/{{#each.*/, '');
            const divide = stringParameter.split('}}');
            divide.splice(0, 1);

            const joinString = divide.join('}}').toString();
            const concate = remove1 + joinString;
            const stringDelEach = concate.replace('{{/each}}', '');

            const returnJson = JSON.parse(stringDelEach);
            return returnJson;

        };

        const validateImage = (nameImage) => {
            // if (/([a-zA-Z0-9\s_\\.\-\(\):])+(.png|.jpg|.jpeg)$/i.test(nameImage)) {
            //     return true;
            // }
            if (true) {
                return true;
            }
            return false;
        };

        const convertJsonTree = (resService) => {
            const arrayTree = [];
            const arrayTree2 = [];
            const arrayMain = [];
            $.each(resService, (key, valueService) => {
                if (typeof (valueService) === 'object') {
                    arrayMain.push(valueService, key);
                }
            });
            if (arrayMain[1] === 0) {
                arrayTree[0] = { name: '', children: [] };
            } else {
                if (Array.isArray(arrayMain[0])) {
                    arrayTree[0] = { name: `${arrayMain[1]}【 】`, key: arrayMain[1], children: [] };
                } else {
                    arrayTree[0] = { name: arrayMain[1], key: arrayMain[1], children: [] };
                }
            }
            if (arrayMain[1] === 0) {
                $.each(arrayMain[0], (key, valueService) => {
                    // console.log(valueService);
                    if (typeof (valueService) === 'string' || typeof (valueService) === 'number') {
                        arrayTree2.push({ name: key + ': ' + valueService, key, value: valueService });
                    } else {
                        if (!Array.isArray(valueService)) {
                            // TEST BEGIN
                            const objeto4 = {
                                children: [],
                                name: '',
                                key: ''
                            };
                            $.each(valueService, (key4, valueService4) => {
                                // console.log(key);
                                // console.log(valueService);
                                if (typeof (valueService4) === 'string' || typeof (valueService4) === 'number') {
                                    // arrayTree3.push({ name: key + ': ' + valueService, key, value: valueService });
                                    objeto4.children.push({ name: key4 + ': ' + valueService4, key: key4, value: valueService4 });
                                } else if (Array.isArray(valueService)) {
                                } else {
                                    const objeto5 = {
                                        children: [],
                                        name: '',
                                        key: ''
                                    };
                                    $.each(valueService4, (key5, valueService5) => {
                                        // console.log(key);
                                        if (typeof (valueService5) === 'string' || typeof (valueService5) === 'number') {
                                            // arrayTree3.push({ name: key + ': ' + valueService, key, value: valueService });
                                            objeto5.children.push({ name: key5 + ': ' + valueService5, key: key5, value: valueService5 });
                                        }
                                    });
                                    objeto5.name = `${key4}`;
                                    objeto5.key = key4;
                                    objeto4.children.push(objeto5);
                                }
                            });
                            objeto4.name = `${key}`;
                            objeto4.key = key;
                            arrayTree2.push(objeto4);
                            // TEST END
                        } else {
                            arrayTree2.push({
                                name: `${key}【 】`, key: key,
                                children: [{ name: valueService, value: valueService }]
                            });
                        }
                    }
                });
            } else {
                if (Array.isArray(arrayMain[0])) {
                    // arrayTree[0] = { name: `${arrayMain[1]}【 】`, key: arrayMain[1], children: [] };
                    $.each(arrayMain[0][0], (key, valueService) => {
                        // console.log(key);
                        // console.log(valueService);
                        if (typeof (valueService) === 'string' || typeof (valueService) === 'number') {
                            arrayTree2.push({ name: key + ': ' + valueService, key, value: valueService });
                        } else if (Array.isArray(valueService)) {
                            // console.log(valueService);
                            // console.log(valueService[0]);
                            const objeto = {
                                children: [],
                                name: '',
                                key: ''
                            };
                            $.each(valueService[0], (key2, valueService2) => {
                                // console.log(key);
                                // console.log(valueService);
                                objeto.children.push({ name: key2 + ': ' + valueService2, key: key2, value: valueService2 });
                            });
                            objeto.name = `${key} 【 】 `;
                            objeto.key = key;
                            // console.log(objeto);
                            arrayTree2.push(objeto);
                        } else {
                            const objeto2 = {
                                children: [],
                                name: '',
                                key: ''
                            };
                            $.each(valueService, (key2, valueService2) => {
                                // console.log(key);
                                // console.log(valueService);
                                objeto2.children.push({ name: key2 + ': ' + valueService2, key: key2, value: valueService2 });
                            });
                            objeto2.name = `${key}`;
                            objeto2.key = key;
                            arrayTree2.push(objeto2);
                        }
                    });
                } else {
                    // console.log('validate test');
                    /* test */
                    /* console.log(arrayMain[0]); */
                    $.each(arrayMain[0], (key, valueServiceObject) => {
                        // console.log('key arreglo', key);
                        // console.log('valuen arreglo ', valueServiceObject);
                        /* console.log(valueServiceObject); */
                        if (typeof (valueServiceObject) === 'string' || typeof (valueServiceObject) === 'number') {
                            arrayTree2.push({ name: key + ': ' + valueServiceObject, key, value: valueServiceObject });
                        }
                        else if (Array.isArray(valueServiceObject) && valueServiceObject.length > 0) {
                            /* console.log(valueServiceObject); */
                            // console.log(valueService[0]);
                            const objeto = {
                                children: [],
                                name: '',
                                key: ''
                            };
                            $.each(valueServiceObject, (key2, valueService2) => {
                                if (typeof (valueService2) === 'string' || typeof (valueService2) === 'number') {
                                    // arrayTree2.push({ name: key + ': ' + valueServiceObject, key, value: valueServiceObject });
                                    objeto.children.push({ name: key2 + ': ' + valueService2, key: key2, value: valueService2 });
                                }
                                else {
                                    const objetoN = {
                                        children: [],
                                        name: '',
                                        key: ''
                                    };
                                    $.each(valueService2, (key3, valueService3) => {
                                        objetoN.children.push({ name: key3 + ': ' + valueService3, key: key3, value: valueService3 });
                                    });
                                    objetoN.name = `${key2} `;
                                    objetoN.key = key2;
                                    // console.log(objeto);
                                    // arrayTree2.push(objeto);
                                    objeto.children.push(objetoN);
                                }
                                // console.log(key);
                                // console.log(valueService);
                            });
                            objeto.name = `${key} 【 】 `;
                            objeto.key = key;
                            // console.log(objeto);
                            arrayTree2.push(objeto);
                        }
                        else {
                            // console.log('key del objeto');
                            // begin test
                            const objeto = {
                                children: [],
                                name: '',
                                key: ''
                            };
                            $.each(valueServiceObject, (key2, valueService2) => {
                                if (typeof (valueService2) === 'string' || typeof (valueService2) === 'number') {
                                    // arrayTree2.push({ name: key + ': ' + valueServiceObject, key, value: valueServiceObject });
                                    objeto.children.push({ name: key2 + ': ' + valueService2, key: key2, value: valueService2 });
                                }
                                else {
                                    const objetoN = {
                                        children: [],
                                        name: '',
                                        key: ''
                                    };
                                    $.each(valueService2, (key3, valueService3) => {
                                        objetoN.children.push({ name: key3 + ': ' + valueService3, key: key3, value: valueService3 });
                                    });
                                    objetoN.name = `${key2} `;
                                    objetoN.key = key2;
                                    // console.log(objeto);
                                    // arrayTree2.push(objeto);
                                    objeto.children.push(objetoN);
                                }
                                // console.log(key);
                                // console.log(valueService);
                            });
                            objeto.name = `${key}`;
                            objeto.key = key;
                            // console.log(objeto);
                            arrayTree2.push(objeto);
                            // end test
                        }
                    });
                    /* end test */
                    /* arrayTree[0] = { name: arrayMain[1], key: arrayMain[1], children: [] }; */
                }
            }
            arrayTree[0].children = arrayTree2;
            // console.log('VALUE ARRAYTREE');
            // console.log(arrayTree);
            return arrayTree;
        };
        const getCursorPosition = (oField) => {

            // Initialize
            let iCaretPos = 0;


            if (oField.selectionStart || oField.selectionStart === '0') {
                iCaretPos = oField.selectionDirection === 'backward' ? oField.selectionStart : oField.selectionEnd;
            }

            return iCaretPos;
        };


        const functionInsertEach = (json, keyEach) => {

            const json1 = [json];
            let eachOpen;

            if (keyEach !== '') {
                eachOpen = `{{#each ${keyEach}}}`;

            } else {
                eachOpen = `{{#each}}`;
            }

            const eachClose = '{{/each}}';

            const convertToJson = JSON.stringify(json1);
            const findCharacterOpen = convertToJson.indexOf('[');
            const text1 = convertToJson.substring(0, findCharacterOpen + 1);
            const text2 = convertToJson.substring(findCharacterOpen + 1, convertToJson.length);
            const stringEachInsertOpen = text1 + eachOpen + text2;
            const findCharacterClose = stringEachInsertOpen.lastIndexOf(']');
            const text1endClose = stringEachInsertOpen.substring(0, findCharacterClose);
            const text2endClose = stringEachInsertOpen.substring(findCharacterClose, stringEachInsertOpen.length);

            const stringWithEach = text1endClose + eachClose + text2endClose;

            return stringWithEach;
        };

        const containerTree = $('<div class="type_visual_box"></div>');
        const selectFigure = $(`<select name="" class="figureLoad">
            <option value="">`+ translate.sections.services.select+ `r</option>
            <option value="qr">QR</option>
            <option value="carousel">Carousel</option>
            <option value="buttons">Buttons</option>
        </select>`);
        const inputURL = $(`<input class="mt-10" name="" hidden class="urlService" type="text" placeholder="URL">`);
        const buttonSend = $(`<button disabled class="save-button btn-primary" hidden>`+ translate.sections.services.create+ `</button>`);

        const buttonvalue = $(`<button class="btn-primary btn-small btn-complete">`+ translate.sections.services.edit+ `r</button>`);
        buttonvalue.css('display', 'none');

        const divParentTree = $('<div class="parentTree"></div>');
        const divTree = $('<div hidden class="tree1 scroll-container"></div>');

        const buttonLoadData = $('<button class="btn-primary btn-load">'+ translate.sections.services.loadData+ '</button>');
        buttonLoadData.hide();


        const buttonSaveD = $('<button class="buttonSaveD">'+ translate.sections.services.save+ '</button>');

        // pruebas
        // containerTree.append(divParentTree)


        divParentTree.append(divTree);
        // controls QR
        const divSpanValue = $('<div class="divControl" hidden></div>');
        const spanInputValue = $('<span>valor:</span>');
        const inputValueQR = $('<input name="" class="controls valueProperty" type="text" placeholder="'+ translate.sections.services.value+ '">');

        divSpanValue.append(spanInputValue, inputValueQR);


        // div value button 2
        const divSpanValue2 = $('<div class="divControl"></div>');
        const spanInputValue2 = $('<span>valor:</span>');
        const inputValueQR2 = $('<input name="" class="controls valueProperty" type="text" placeholder="'+ translate.sections.services.value+ '">');

        divSpanValue2.append(spanInputValue2, inputValueQR2);

        divSpanValue2.hide();

        // end

        // div value button 3
        const divSpanValue3 = $('<div class="divControl"></div>');
        const spanInputValue3 = $('<span>valor:</span>');
        const inputValueQR3 = $('<input name="" class="controls valueProperty" type="text" placeholder="'+ translate.sections.services.value+ '">');

        divSpanValue3.append(spanInputValue3, inputValueQR3);

        divSpanValue3.hide();

        // end

        // div input url button 2
        const divURL2 = $('<div class="divControl"></div>');
        const spanInputURL2 = $('<span>url:</span>');
        const inputURL2 = $('<input name="" class="controls urlProperty2" type="text" placeholder="URL">');

        divURL2.append(spanInputURL2, inputURL2);

        divURL2.hide();

        // end


        // div input url button 3
        const divURL3 = $('<div class="divControl"></div>');
        const spanInputURL3 = $('<span>url:</span>');
        const inputURL3 = $('<input name="" class="controls urlProperty2" type="text" placeholder="URL">');

        divURL3.append(spanInputURL3, inputURL3);

        divURL3.hide();
        // end

        const divSelectorType = $('<div class="divControl" hidden></div>');
        const spanTypeButton = $('<span>tipo de botón:</span>');
        const selectTypeButton = $(`<select class="SelectorType selectInspectorStyle" name="">
            <option value="">` + translate.sections.services.select + `</option>
            <option value="user_phone_number">` + translate.sections.services.phone + `</option>
            <option value="user_email">email</option>
            <option value="text">` + translate.sections.services.conection + `</option>
        </select>`);

        divSelectorType.append(spanTypeButton, selectTypeButton);

        const divSelectorTypeHeight = $('<div class="divControl" hidden></div>');
        const spanTypeHeight = $('<span>tamaño de Imagen:</span>');
        const selectHeightRatio = $(`<select class="selectHeightRatio" name="">
            <option value="">` + translate.sections.services.select + `</option>
            <option value="compact">` + translate.sections.services.compact + `</option>
            <option value="tall">` + translate.sections.services.high + `</option>
            <option value="full">` + translate.sections.services.complete + `</option>
        </select>`);

        divSelectorTypeHeight.append(spanTypeHeight, selectHeightRatio);


        // div height ratio 2
        const divSelectorTypeHeight2 = $('<div class="divControl"></div>');
        const spanTypeHeight2 = $('<span>tamaño de Imagen:</span>');
        const selectHeightRatio2 = $(`<select class="selectHeightRatio" name="">
        <option value="">` + translate.sections.services.select + `</option>
        <option value="compact">` + translate.sections.services.compact + `</option>
        <option value="tall">` + translate.sections.services.high + `</option>
        <option value="full">` + translate.sections.services.complete + `</option>
        </select>`);

        divSelectorTypeHeight2.append(spanTypeHeight2, selectHeightRatio2);
        divSelectorTypeHeight2.hide();
        // end


        // div height ratio 3
        const divSelectorTypeHeight3 = $('<div class="divControl"></div>');
        const spanTypeHeight3 = $('<span>tamaño de Imagen:</span>');
        const selectHeightRatio3 = $(`<select class="selectHeightRatio" name="">
        <option value="">` + translate.sections.services.select + `</option>
        <option value="compact">` + translate.sections.services.compact + `</option>
        <option value="tall">` + translate.sections.services.high + `</option>
        <option value="full">` + translate.sections.services.complete + `</option>
        </select>`);

        divSelectorTypeHeight3.append(spanTypeHeight3, selectHeightRatio3);
        divSelectorTypeHeight3.hide();

        // end

        // controls SLIDER

        const divSelectorTypeCarousel = $('<div class="divControl" hidden></div>');
        const spanTypeButtonCarousel = $('<span>tipo de botón:</span>');
        const selectTypeButtonCarousel = $(`<select class="SelectorTypeCarousel" name="">
        <option value="">` + translate.sections.services.select + `</option>
            <option value="web_url">url</option>
            <option value="web_view">webview</option>
            <option value="phone_number">` + translate.sections.services.phone + `</option>
            <option value="postback">` + translate.sections.services.conection+ `</option>
        </select>`);

        divSelectorTypeCarousel.append(spanTypeButtonCarousel, selectTypeButtonCarousel);


        // div selector type button create 2
        const divSelectorTypeCarousel2 = $('<div class="divControl"></div>');
        const spanTypeButtonCarousel2 = $('<span>tipo de botón:</span>');
        const selectTypeButtonCarousel2 = $(`<select class="SelectorTypeCarousel" name="">
        <option value="">` + translate.sections.services.select + `</option>
        <option value="web_url">url</option>
        <option value="web_view">webview</option>
        <option value="phone_number">` + translate.sections.services.phone + `</option>
        <option value="postback">` + translate.sections.services.conection+ `</option>
        </select>`);

        divSelectorTypeCarousel2.append(spanTypeButtonCarousel2, selectTypeButtonCarousel2);

        // end


        // div selector type button create 3
        const divSelectorTypeCarousel3 = $('<div class="divControl"></div>');
        const spanTypeButtonCarousel3 = $('<span>tipo de botón:</span>');
        const selectTypeButtonCarousel3 = $(`<select class="SelectorTypeCarousel" name="">
        <option value="">` + translate.sections.services.select + `</option>
        <option value="web_url">url</option>
        <option value="web_view">webview</option>
        <option value="phone_number">` + translate.sections.services.phone + `</option>
        <option value="postback">` + translate.sections.services.conection+ `</option>
        </select>`);

        divSelectorTypeCarousel3.append(spanTypeButtonCarousel3, selectTypeButtonCarousel3);

        // end


        const divTitle = $('<div class="divControl" hidden></div>');
        const spanTitle = $('<span>título:</span>');
        const inputTitle = $('<input name="" class="controls titleProperty" type="text" placeholder="título">');

        divTitle.append(spanTitle, inputTitle);

        const divDescription = $('<div class="divControl" hidden></div>');
        const spanDescription = $('<span>' + translate.sections.services.description+ '</span>');
        const inputDescription = $(`<input name="" class="controls descriptionProperty" type="text" placeholder="descripción">`);
        divDescription.append(spanDescription, inputDescription);

        const divImage = $('<div class="divControl" hidden></div>');
        const spanImage = $('<span>' + translate.sections.services.urlImage+ ':</span>');
        const inputImage = $('<input name="" class="controls imageProperty"  type="text" placeholder="URL">');
        divImage.append(spanImage, inputImage);

        const divTitleButton = $('<div class="divControl" hidden></div>');
        const spanTitleButton = $('<span>' + translate.sections.services.title+ '+ del botón 1:</span>');
        const inputTitleButton = $(`<input name="" value='' class="controls titleButton"  type="text" placeholder="título">`);
        divTitleButton.append(spanTitleButton, inputTitleButton);

        // component title button 2
        const divTitleButton2 = $('<div class="divControl"></div>');
        const spanTitleButton2 = $('<span>' + translate.sections.services.title+ ' del botón 2:</span>');
        const inputTitleButton2 = $(`<input name="" value='' class="controls titleButton"  type="text" placeholder="título del botón">`);
        divTitleButton2.append(spanTitleButton2, inputTitleButton2);

        // end


        // component title button 3
        const divTitleButton3 = $('<div class="divControl"></div>');
        const spanTitleButton3 = $('<span>' + translate.sections.services.title+ 'del botón 3:</span>');
        const inputTitleButton3 = $(`<input name="" value='' class="controls titleButton"  type="text" placeholder="título del botón">`);
        divTitleButton3.append(spanTitleButton3, inputTitleButton3);

        // end

        const QRpreview = $('<button hidden class="buttonQrPreview">' + translate.sections.services.button+ '</button>');

        const SliderPreview = $('<div hidden class="cardPreview"></div>');

        const imagePreview = $('<img class="coverCard" src="../assets/img/noimage.png" alt="">');
        const titlePreview = $('<p class="titleSliderPreview">título</p>');
        const descriptionPreview = $('<p class="descriptionSliderPreview">Descripción</p>');

        const buttonPreviewSlider = $('<a class="show_modal btnSlider">Botón 1</a>');

        const buttonPreviewSlider2 = $('<a class="show_modal btnSlider">Botón 2</a>');
        const buttonPreviewSlider3 = $('<a class="show_modal btnSlider">Botón 3</a>');
        const textareaControl = $('<textarea hidden placeholder="valor" cols="2" rows="2" class="escape controls"></textarea>');


        // div textarea
        const divTextareaContent = $('<div class="divControl"></div>');
        const spanTextAreaContent = $('<span></span>');

        divTextareaContent.append(spanTextAreaContent, textareaControl);

        divTextareaContent.hide();
        // end


        let jsonSaved: any;
        let figureSelectedO: any;
        let valueTypeButtonQr: any;
        let valueTypeButtonCarousel: any;
        let valueTypeButtonCarousel2: any;
        let valueTypeButtonCarousel3: any;
        let valueHeightRatio: any;
        let valueHeightRatio2: any;
        let valueHeightRatio3: any;
        let responseButtonsD: any;

        SliderPreview.append(imagePreview);
        SliderPreview.append(titlePreview);
        SliderPreview.append(descriptionPreview);
        SliderPreview.append([buttonPreviewSlider, buttonPreviewSlider2, buttonPreviewSlider3]);


        containerTree.append([selectFigure, inputURL, buttonSend, buttonvalue, divParentTree, fieldJM]);



        // test
        // $('body').append(containerTree);





        const modal = this.modal.create({
            onClose: (eClose) => {

                eClose.preventDefault();
                eClose.stopPropagation();
                const parentClose = $(eClose.target).parent();
                $(this).width('60%');
                buttonvalue.css('display', 'block');

                const valueTitle = parentClose.find('.titleButton').val();
                const valueTitleP = parentClose.find('.titleProperty').val();


                buttonSend.css('display', 'none');
            }
        });

        const divParentCarousel = $('<div class="divParentControls"></div>');

        const divButtonCreate1 = $('<fieldset class="fieldset1"> <legend class="legend1">botón 1</legend></fieldset>');

        const divButtonCreate2 = $('<fieldset class="fieldset1"> <legend>botón 2</legend></fieldset>');

        const divButtonCreate3 = $('<fieldset class="fieldset1"> <legend>botón 3</legend></fieldset>');

        divButtonCreate2.hide();
        divButtonCreate3.hide();


        // test
        divButtonCreate1.append(divSelectorTypeCarousel);
        divButtonCreate1.append(divSelectorTypeHeight);
        divButtonCreate1.append(divTitleButton);
        divButtonCreate1.append(divSpanValue);


        divButtonCreate2.append(divSelectorTypeCarousel2);
        divButtonCreate2.append(divTitleButton2);
        divButtonCreate2.append(divSelectorTypeHeight2);
        divButtonCreate2.append(divSpanValue2);
        divButtonCreate2.append(divURL2);


        divButtonCreate3.append(divSelectorTypeCarousel3);
        divButtonCreate3.append(divTitleButton3);
        divButtonCreate3.append(divSelectorTypeHeight3);
        divButtonCreate3.append(divSpanValue3);
        divButtonCreate3.append(divURL3);


        const NextButton = $('<button class="next-button-create btn-primary">+ Botón</button>');

        NextButton.hide();

        // end

        // add create more buttons

        divParentCarousel.append(divSelectorType);
        // divParentCarousel.append(divSelectorTypeCarousel);
        divParentCarousel.append(divTitle);
        divParentCarousel.append(divDescription);
        divParentCarousel.append(divTextareaContent);
        divParentCarousel.append(divImage);
        divParentCarousel.append(NextButton);
        // divParentCarousel.append(divSelectorTypeCarousel);
        // divParentCarousel.append(divSelectorTypeHeight);
        // divParentCarousel.append(divTitleButton);
        // divParentCarousel.append(divSpanValue);
        divParentCarousel.append(divButtonCreate1);
        divParentCarousel.append(divButtonCreate2);
        divParentCarousel.append(divButtonCreate3);
        divParentCarousel.append(QRpreview);

        modal.box.append(divParentTree);
        modal.box.append(divParentCarousel);
        modal.box.append(SliderPreview);
        modal.box.append(buttonLoadData);
        modal.box.append(buttonSaveD);
        $('body').append([modal.box, modal.blocker]);


        // insertar model en container tree




        // event click next button

        let contadorButtons = 0;
        NextButton.on('click', (eventNext) => {
            // console.log('click event button next');


            divButtonCreate2.show();
            // jsonSaved[0].buttons.push({});

            try {
                if (jsonSaved[0].buttons.length === 1) {
                    divButtonCreate2.show();

                } else if (jsonSaved[0].buttons.length === 2) {
                    divButtonCreate3.show();
                    if (divButtonCreate3.is(':visible')) {
                        NextButton.prop('disabled', true);
                    }

                }

            } catch (error) {
            }

            if (contadorButtons === 1) {
                divButtonCreate3.show();
                // jsonSaved[0].buttons.push({});
                // contadorButtons = 0;
                NextButton.prop('disabled', true);
            }

            contadorButtons++;
        });


        const functionButtonSend = (figura: any) => {
            // console.log('value figura', figura);

            $(buttonSend).off();

            buttonSend.on('click', (eventSendService) => {

                eventSendService.preventDefault();
                eventSendService.stopPropagation();

                modal.show();

                if (figura !== '') {
                    figureSelectedO = figura;
                }


                if (figureSelectedO === 'qr') {
                    NextButton.hide();
                    $('.legend1').hide();
                    divButtonCreate2.hide();
                    divButtonCreate3.hide();
                    divButtonCreate1.css('border', 'none');
                    buttonLoadData.hide();
                    spanTextAreaContent.text('texto:');


                    modal.box.css('width', '60%');
                    // $('.modal2').width('60%');
                    jsonSaved = [{ content_type: '', title: '', value: '' }];
                } else {
                    divButtonCreate1.css('border', '1px solid silver');
                    $('.legend1').show();
                    divButtonCreate2.hide();
                    divButtonCreate3.hide();
                    NextButton.show();
                    spanTextAreaContent.text('contenido:');
                    // $('.modal2').width('90%');
                    modal.box.css('width', '90%');
                    // console.log('%c FIGURE IS CAROUSEL', 'background:purple;color:white');
                    jsonSaved = [{
                        title: '',
                        image_url: '',
                        subtitle: '',
                        buttons: [{
                            type: '',
                            title: '',
                        },
                        ]
                    }];

                }
                divTree.show();
                divTree.tree('destroy');


                selectHeightRatio.val($('option[value = ""] option[selected]').val());
                selectTypeButton.val($('option[value = ""] option[selected]').val());

                selectHeightRatio2.val($('option[value = ""] option[selected]').val());
                selectTypeButtonCarousel2.val($('option[value = ""] option[selected]').val());

                selectHeightRatio3.val($('option[value = ""] option[selected]').val());
                selectTypeButtonCarousel3.val($('option[value = ""] option[selected]').val());

                selectTypeButtonCarousel.val($('option[value = ""] option[selected]').val());

                inputTitle.val('');
                inputURL2.val('');
                inputURL3.val('');
                inputValueQR.val('');
                inputValueQR2.val('');
                inputValueQR3.val('');
                inputImage.val('');
                textareaControl.val('');
                inputTitleButton.val('');
                inputTitleButton2.val('');
                inputTitleButton3.val('');
                inputDescription.val('');
                imagePreview.attr('src', '../assets/img/noimage.png');
                titlePreview.text('Título');
                descriptionPreview.text('Descripción');
                buttonPreviewSlider.text('Botón 1');
                buttonPreviewSlider2.text('Botón 2');
                buttonPreviewSlider3.text('Botón 3');

                const assignURL = inputURL.val();

                let targetCollisionDiv: any;

                $('.controls').on('focus', (eventFocusInput) => {
                    targetCollisionDiv = $(eventFocusInput.target);
                    // console.log(targetCollisionDiv);
                });

                const handleMove = (node, e) => {
                };

                const handleStop = (node: any) => {
                    // node root
                    const keyRoot = divTree.tree('getTree');
                    // console.log('value end of keyroort', keyRoot.children[0].name);


                    let path: any;
                    const levelTree = node.getLevel();

                    // console.log('nivel del tree', levelTree);

                    if (levelTree === 2) {
                        if (node.parent.name === '') {
                            path = `{{${node.key}}}`;
                        } else {
                            if (node.parent.name.includes('【 】')) {
                                path = `{{${node.key}}}`;
                            } else {
                                path = `{{${node.parent.key}.${node.key}}}`;
                            }

                        }

                    } else if (levelTree === 3) {
                        // test denegate drop
                        // path = '';
                        if (node.parent.parent.name === '') {
                            path = `{{${node.parent.key}.${node.key}}}`;
                        } else {
                            path = `{{${node.parent.parent.key}.${node.parent.key}.${node.key}}}`;
                            // path = `{{${node.parent.key}.${node.key}}}`;
                        }

                    } else if (levelTree === 4) {
                        if (node.parent.parent.parent.name === '') {
                            path = `{{${node.parent.parent.key}.${node.parent.key}.${node.key}}}`;
                        } else {
                            path = `{{${node.parent.parent.parent.key}.${node.parent.parent.key}.${node.parent.key}.${node.key}}}`;
                        }
                    }

                    // console.log('path service: ', path);
                    // load path

                    try {
                        const getClass = targetCollisionDiv[0].className.split(' ')[1];
                        // console.log('%c value of getclass', 'background: cyan; color: black', getClass);
                        // console.log('%c value of targetcollision 0', 'background: cyan; color: black', targetCollisionDiv[0]);



                        if (targetCollisionDiv[0] === inputImage[0]) {
                            if (validateImage(node.value)) {
                                targetCollisionDiv.val(path);
                            } else {
                                targetCollisionDiv.val('');
                            }
                        } else {
                            if (targetCollisionDiv.val() !== '') {

                                const valueInput = targetCollisionDiv.val();
                                const stringPart1 = valueInput.substring(
                                    0, getCursorPosition(document.querySelector(`.${getClass}`)));
                                const stringPart2 = valueInput.substring(
                                    getCursorPosition(document.querySelector(`.${getClass}`)), valueInput.length);

                                targetCollisionDiv.val(stringPart1 + path + stringPart2);
                            } else {
                                targetCollisionDiv.val(path);
                            }
                        }

                        jsonSaved[0].url = assignURL;

                        if (figureSelectedO === 'qr') {
                            buttonLoadData.hide();
                            $('.legend1').hide();
                            NextButton.hide();
                            jsonSaved[0].text = textareaControl.val();
                            jsonSaved[0].content_type = valueTypeButtonQr;
                            // spanTextAreaContent.text('text:');

                            if (targetCollisionDiv[0] === inputTitle[0]) {
                                QRpreview.text(node.value);
                                jsonSaved[0].title = targetCollisionDiv.val();
                                // jsonSaved.titleQrPreview = node.value;

                            } else if (targetCollisionDiv[0] === inputValueQR[0]) {
                                jsonSaved[0].value = targetCollisionDiv.val();
                            } else if (targetCollisionDiv[0] === textareaControl[0]) {
                                jsonSaved[0].text = targetCollisionDiv.val();
                            }



                        } else {
                            NextButton.show();
                            // spanTextAreaContent.text('content:');
                            jsonSaved[0].content = textareaControl.val();
                            jsonSaved[0].buttons[0].type = valueTypeButtonCarousel;
                            // console.log('%c VALUE TYPE BUTTON CAROUSEL', 'background:blue;color:white', valueTypeButtonCarousel);

                            if (targetCollisionDiv[0] === inputTitle[0]) {
                                titlePreview.text(node.value);
                                // jsonSaved.titlePreview = node.value;

                                jsonSaved[0].title = targetCollisionDiv.val();

                            } else if (targetCollisionDiv[0] === inputURL3[0]) {
                                jsonSaved[0].buttons[2].webview_height_ratio = valueHeightRatio3;
                                jsonSaved[0].buttons[2].url = targetCollisionDiv.val();
                                jsonSaved[0].buttons[2].fallback_url = targetCollisionDiv.val();

                            } else if (targetCollisionDiv[0] === inputURL2[0]) {
                                jsonSaved[0].buttons[1].webview_height_ratio = valueHeightRatio2;
                                jsonSaved[0].buttons[1].url = targetCollisionDiv.val();
                                jsonSaved[0].buttons[1].fallback_url = targetCollisionDiv.val();

                            } else if (targetCollisionDiv[0] === inputValueQR2[0]) {
                                jsonSaved[0].buttons[1].value = targetCollisionDiv.val();

                            } else if (targetCollisionDiv[0] === inputValueQR3[0]) {
                                jsonSaved[0].buttons[2].value = targetCollisionDiv.val();

                            } else if (targetCollisionDiv[0] === inputDescription[0]) {
                                descriptionPreview.text(node.value);
                                // jsonSaved.descriptionPreview = node.value;
                                jsonSaved[0].subtitle = targetCollisionDiv.val();
                            } else if (targetCollisionDiv[0] === inputImage[0]) {
                                if (validateImage(node.value)) {
                                    imagePreview.attr('src', node.value);
                                    // jsonSaved.imagePreview = node.value;
                                    jsonSaved[0].image_url = targetCollisionDiv.val();
                                }
                            } else if (targetCollisionDiv[0] === inputTitleButton[0]) {
                                jsonSaved[0].buttons[0].title = targetCollisionDiv.val();
                                // jsonSaved.titleButtonPreview = node.value;
                                buttonPreviewSlider.text(node.value);


                            } else if (targetCollisionDiv[0] === inputTitleButton2[0]) {
                                jsonSaved[0].buttons[1].title = targetCollisionDiv.val();
                                // jsonSaved.titleButtonPreview = node.value;
                                buttonPreviewSlider2.text(node.value);


                            } else if (targetCollisionDiv[0] === inputTitleButton3[0]) {
                                jsonSaved[0].buttons[2].title = targetCollisionDiv.val();
                                // jsonSaved.titleButtonPreview = node.value;
                                buttonPreviewSlider3.text(node.value);


                            } else if (valueTypeButtonCarousel2 === 'postback') {
                                jsonSaved[0].buttons[1].value = '';
                            } else if (valueTypeButtonCarousel2 === 'phone_number') {
                                jsonSaved[0].buttons[1].value = targetCollisionDiv.val();
                            } else if (valueTypeButtonCarousel2 === 'web_view') {
                                jsonSaved[0].buttons[1].webview_height_ratio = valueHeightRatio2;
                                jsonSaved[0].buttons[1].url = targetCollisionDiv.val();
                                jsonSaved[0].buttons[1].fallback_url = targetCollisionDiv.val();
                            } else if (valueTypeButtonCarousel2 === 'web_url') {
                                jsonSaved[0].buttons[1].url = targetCollisionDiv.val();
                            } else if (valueTypeButtonCarousel3 === 'postback') {
                                jsonSaved[0].buttons[2].value = '';
                            } else if (valueTypeButtonCarousel3 === 'phone_number') {
                                jsonSaved[0].buttons[2].value = targetCollisionDiv.val();
                            } else if (valueTypeButtonCarousel3 === 'web_view') {
                                // console.log('entro en el caso 3', targetCollisionDiv.val());
                                jsonSaved[0].buttons[2].webview_height_ratio = valueHeightRatio3;
                                jsonSaved[0].buttons[2].url = targetCollisionDiv.val();
                                jsonSaved[0].buttons[2].fallback_url = targetCollisionDiv.val();
                            } else if (valueTypeButtonCarousel3 === 'web_url') {
                                jsonSaved[0].buttons[2].url = targetCollisionDiv.val();
                            } else if (targetCollisionDiv[0] === inputValueQR[0]) {
                                if (valueTypeButtonCarousel === 'postback') {
                                    jsonSaved[0].buttons[0].value = '';
                                } else if (valueTypeButtonCarousel === 'phone_number') {
                                    jsonSaved[0].buttons[0].value = targetCollisionDiv.val();
                                } else if (valueTypeButtonCarousel === 'web_view') {
                                    jsonSaved[0].buttons[0].webview_height_ratio = valueHeightRatio;
                                    jsonSaved[0].buttons[0].url = targetCollisionDiv.val();
                                    jsonSaved[0].buttons[0].fallback_url = targetCollisionDiv.val();
                                } else if (valueTypeButtonCarousel === 'web_url') {
                                    jsonSaved[0].buttons[0].url = targetCollisionDiv.val();
                                }
                            } else if (targetCollisionDiv[0] === textareaControl[0]) {
                                jsonSaved[0].content = targetCollisionDiv.val();
                            }

                        }
                    } catch (error) {

                    }


                    // console.log('%c key drop', 'background:deepskyblue;color:black', node.key);
                    // console.log('%c input onfocus', 'background:deepskyblue;color:black', targetCollisionDiv);

                    // console.log('%c JSON SAVED buttons', 'background:red;color:white', JSON.stringify(jsonSaved[0]));

                    try {

                        // var newArray = jsonSaved[0].buttons.filter(value => Object.keys(value).length !== 0);

                        // jsonSaved[0].buttons = newArray;


                        if (figureSelectedO !== 'qr') {
                            const removeEmptyObjects = jsonSaved[0].buttons.filter(value2 => Object.keys(value2).length !== 0);
                            jsonSaved[0].buttons = removeEmptyObjects;
                        }


                        // if (jsonSaved[0].buttons.length === 4) {
                        //     jsonSaved[0].buttons.length = 3;

                        // }
                        const StringInsertEach = Object.assign({}, jsonSaved[0]);

                        delete StringInsertEach.url;
                        delete StringInsertEach.figure;
                        delete StringInsertEach.imagePreview;
                        delete StringInsertEach.titlePreview;
                        delete StringInsertEach.titleQrPreview;
                        delete StringInsertEach.descriptionPreview;
                        delete StringInsertEach.titleButtonPreview;
                        delete StringInsertEach.text;
                        delete StringInsertEach.content;

                        // console.log('%c string no elements', 'background:purple;color:white', StringInsertEach);

                        // console.log('%c value of node root', 'background:yellow;color:white', keyRoot.children[0].name);


                        if (keyRoot.children[0].name.includes('【 】')) {

                            // validar figura
                            result = functionInsertEach(StringInsertEach, keyRoot.children[0].key);
                            // console.log('%c string with each', 'background:purple;color:white', result);
                        } else {
                            // console.log('%c string  each', 'background:purple;color:white', JSON.stringify(jsonSaved));
                            // validar figura
                            // result = StringInsertEach;
                            result = functionInsertEach(StringInsertEach, '');
                        }
                    } catch (error) {

                    }

                    // console.log('%c value of result', 'background:deepskyblue;color:black', result);



                    // jsonSaved
                    responseButtonsD = {
                        url: jsonSaved[0].url,
                        type: 'json',
                        showOutputs: true
                    };



                    if (spanTextAreaContent.text() === 'texto:') {
                        // console.log('add pro text:', jsonSaved[0].text);
                        responseButtonsD.buttons = result;
                        responseButtonsD.text = jsonSaved[0].text;


                    } else {
                        responseButtonsD.items = result;
                        // console.log('add pro content:', jsonSaved[0].content);
                        responseButtonsD.content = jsonSaved[0].content;

                    }

                    if (
                        req.constraints &&
                        typeof req.constraints === 'object' &&
                        'type' in req.constraints
                    ) {
                        if ('noOutputs' === req.constraints.type) {
                            responseButtonsD.showOutputs = false;
                        }
                    }

                    // console.log('reponse', JSON.stringify(responseButtons))
                    // original save json
                    fieldJM.val(JSON.stringify(responseButtonsD));

                    // console.log('%c JSON SEND END1', 'background:red;color:white', fieldJM.val());

                    // console.log('value input', fieldJM);


                };


                // test service

                $.ajax({
                    url: assignURL,
                    success: (myJson) => {
                        // console.log(myJson);
                        const jsonConvertTree = convertJsonTree(myJson);
                        // console.log(jsonConvertTree);


                        divTree.tree({
                            animationSpeed: 'fast',
                            data: jsonConvertTree,
                            autoOpen: true,
                            keyboardSupport: true,
                            dragAndDrop: true,
                            buttonLeft: false,
                            onDragMove: handleMove,
                            onDragStop: handleStop,
                            selectable: true,
                            closedIcon: $('<img style="width:20px;height:20px" src="../assets/img/openFolder.svg" alt="">'),
                            openedIcon: $('<img style="width:20px;height:20px" src="../assets/img/closeFolder.svg" alt="">')
                        });

                        divTree.on(
                            'tree.move',
                            (event) => {
                                return false;
                            }
                        );


                        // divTree.on(
                        //     'tree.open',
                        //     (e) => {
                        //         const heightTree = divTree.css('height');
                        //         if (+heightTree.replace('px', '') > 400) {
                        //             divTree.addClass('scroll-container');
                        //         }
                        //     }
                        // );

                        // divTree.on(
                        //     'tree.close',
                        //     (e) => {
                        //         const heightTree = divTree.css('height');
                        //         if (+heightTree.replace('px', '') < 400) {
                        //             divTree.removeClass('scroll-container');
                        //         }
                        //     }
                        // );

                        $('span.jqtree_common').each(function (e) {
                            const itemTree = $(this).text().length;

                            if (itemTree > 40) {
                                $(this).addClass('loadEllipsis');
                            }
                        });
                    },
                    error: () => {
                        console.log('No se ha podido obtener la información');
                    }
                });

                // end
            });
        };


        selectFigure.on('change', (eventChangeFigure) => {

            inputValueQR.off('keyup');
            inputTitle.off('keyup');
            textareaControl.off('keyup');

            selectHeightRatio.val($('option[value = ""] option[selected]').val());
            selectTypeButton.val($('option[value = ""] option[selected]').val());
            selectTypeButtonCarousel.val($('option[value = ""] option[selected]').val());

            figureSelectedO = eventChangeFigure.target.value;

            inputURL.show();
            buttonSend.show();
            textareaControl.show();
            divTextareaContent.show();


            const validateURL = (url) => {
                if (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(url)) {
                    return true;
                }
                return false;
            };

            inputURL.on('keyup', (eventURL) => {
                if (validateURL(eventURL.target.value)) {
                    buttonSend.prop('disabled', false);
                } else {
                    buttonSend.prop('disabled', true);
                }
            });

            QRpreview.text('Botón');
            inputTitle.val('');
            inputTitleButton.val('');
            inputTitleButton2.val('');
            inputTitleButton3.val('');
            inputDescription.val('');
            inputImage.val('');
            textareaControl.val('');
            inputValueQR.val('');
            imagePreview.attr('src', '../assets/img/noimage.png');
            titlePreview.text('Título');
            descriptionPreview.text('Descripción');
            buttonPreviewSlider.text('Botón 1');

            if (eventChangeFigure.target.value === 'qr') {
                buttonLoadData.hide();
                $('.legend1').hide();
                divButtonCreate1.css('border', 'none');
                NextButton.hide();
                modal.box.css('width', '60%');
                // $('.modal2').width('60%');
                spanTextAreaContent.text('texto:');
                // console.log('%c FIGURE IS QR', 'background:purple;color:white');
                textareaControl.attr('placeholder', 'text');
                // spanTextAreaContent.text('text');
                spanInputValue.text('value');
                inputValueQR.attr('placeholder', 'value');

                divSelectorType.css('display', 'block');
                divTitle.css('display', 'block');
                QRpreview.css('display', 'block');

                divSelectorTypeCarousel.hide();
                divSelectorTypeHeight.hide();
                divSpanValue.hide();
                // divTitle.hide();
                divDescription.hide();
                divImage.hide();
                divTitleButton.hide();
                SliderPreview.hide();

                selectTypeButton.on('change', (eventSelectButton) => {

                    jsonSaved = [{ content_type: '', title: '', value: '' }];

                    inputValueQR.val('');
                    inputTitle.val('');
                    QRpreview.text('Botón');
                    // textareaControl.val('');

                    valueTypeButtonQr = eventSelectButton.target.value;
                    jsonSaved[0].content_type = valueTypeButtonQr;

                    // divTitle.show();
                    if (eventSelectButton.target.value === 'user_phone_number' || eventSelectButton.target.value === 'user_email') {
                        divTitle.show();
                        divSpanValue.show();
                    } else if (eventSelectButton.target.value === 'text') {
                        divTitle.show();
                        divSpanValue.hide();
                    } else {
                        divTitle.hide();
                        divSpanValue.hide();
                    }

                });

                jsonSaved = [{ content_type: '', title: '', value: '' }];

                textareaControl.on('keyup', (eventTextArea) => {
                    jsonSaved[0].text = eventTextArea.target.value;
                });

                inputTitle.on('keyup', (event) => {
                    jsonSaved[0].title = event.target.value;
                    // jsonSaved.titleQrPreview = event.target.value;
                    QRpreview.text(event.target.value);
                });

                inputValueQR.on('keyup', (event) => {
                    jsonSaved[0].value = event.target.value;
                });

                // test view json keyup  qr
                // QRpreview.on('click', () => {
                //     console.log(jsonSaved);
                // });

            } else {
                NextButton.show();
                // $('.modal2').width('90%');
                modal.box.css('width', '90%');
                divButtonCreate1.css('border', '1px solid silver');
                $('.legend1').show();
                spanTextAreaContent.text('contenido:');
                // console.log('%c FIGURE IS CAROUSEL', 'background:purple;color:white');
                textareaControl.attr('placeholder', 'content');
                divSelectorTypeCarousel.css('display', 'block');
                SliderPreview.css('display', 'inline-block');
                divTitle.css('display', 'block');
                divDescription.css('display', 'block');
                divImage.css('display', 'block');
                divTitleButton.css('display', 'block');

                divSelectorType.css('display', 'none');
                QRpreview.css('display', 'none');
                divSpanValue.css('display', 'none');

                selectTypeButtonCarousel.on('change', (eventSelectButtonCarousel) => {

                    valueTypeButtonCarousel = eventSelectButtonCarousel.target.value;
                    selectHeightRatio.on('change', (eventSelectHeightRatio) => {
                        valueHeightRatio = eventSelectHeightRatio.target.value;
                        jsonSaved[0].buttons[0].webview_height_ratio = eventSelectHeightRatio.target.value;
                    });

                    jsonSaved[0].buttons[0] = {
                        type: '',
                        title: ''
                    };

                    jsonSaved[0].buttons[0].type = valueTypeButtonCarousel;
                    // console.log(jsonSaved);


                    // test view json keyup
                    buttonPreviewSlider.off('click');
                    buttonPreviewSlider.on('click', (event) => {
                        // console.log(JSON.stringify(jsonSaved));
                    });

                    if (eventSelectButtonCarousel.target.value === 'web_url') {
                        jsonSaved[0].buttons[0].url = '';
                        spanInputValue.text('url');
                        inputValueQR.attr('placeholder', 'url');
                        divTitle.css('display', 'block');
                        divDescription.css('display', 'block');
                        divImage.css('display', 'block');
                        divSpanValue.css('display', 'block');
                        divTitleButton.css('display', 'block');
                        divSelectorTypeHeight.css('display', 'none');

                        // test keyup inputs web url
                        inputValueQR.on('keyup', (event) => {
                            jsonSaved[0].buttons[0].url = event.target.value;
                        });

                        // end
                    } else if (eventSelectButtonCarousel.target.value === 'phone_number') {
                        jsonSaved[0].buttons[0].value = '';
                        spanInputValue.text('value');
                        inputValueQR.attr('placeholder', 'value');
                        divSpanValue.css('display', 'block');
                        divSelectorTypeHeight.css('display', 'none');

                        inputValueQR.on('keyup', (event) => {
                            jsonSaved[0].buttons[0].value = event.target.value;

                        });
                    } else if (eventSelectButtonCarousel.target.value === 'web_view') {
                        spanInputValue.text('url');
                        inputValueQR.attr('placeholder', 'url');
                        jsonSaved[0].buttons[0].url = '';
                        jsonSaved[0].buttons[0].webview_height_ratio = '';
                        jsonSaved[0].buttons[0].messenger_extensions = true;
                        jsonSaved[0].buttons[0].fallback_url = '';

                        divSpanValue.css('display', 'block');
                        divSelectorTypeHeight.css('display', 'block');

                        inputValueQR.on('keyup', (event) => {
                            jsonSaved[0].buttons[0].url = event.target.value;
                            jsonSaved[0].buttons[0].fallback_url = event.target.value;
                        });

                    } else if (eventSelectButtonCarousel.target.value === 'postback') {
                        spanInputValue.text('value');
                        inputValueQR.attr('placeholder', 'value');
                        jsonSaved[0].buttons[0].value = '';
                        divSpanValue.css('display', 'none');
                        divSelectorTypeHeight.css('display', 'none');
                    } else {
                        divTitle.css('display', 'none');
                        divDescription.css('display', 'none');
                        divImage.css('display', 'none');
                        divTitleButton.css('display', 'none');
                        divSpanValue.css('display', 'none');
                        divSelectorTypeHeight.css('display', 'none');
                    }

                });

                // event change select2

                selectTypeButtonCarousel2.on('change', (eventSelectButtonCarousel2) => {
                    jsonSaved[0].buttons[1] = {};

                    valueTypeButtonCarousel2 = eventSelectButtonCarousel2.target.value;

                    selectHeightRatio2.on('change', (eventSelectHeightRatio2) => {
                        valueHeightRatio2 = eventSelectHeightRatio2.target.value;
                        jsonSaved[0].buttons[1].webview_height_ratio = eventSelectHeightRatio2.target.value;
                    });

                    jsonSaved[0].buttons[1] = {
                        type: '',
                        title: ''
                    };

                    jsonSaved[0].buttons[1].type = valueTypeButtonCarousel2;
                    // console.log(jsonSaved);


                    // test view json keyup
                    buttonPreviewSlider2.off('click');
                    buttonPreviewSlider2.on('click', (event) => {
                        // console.log(JSON.stringify(jsonSaved));
                    });

                    if (eventSelectButtonCarousel2.target.value === 'web_url') {
                        jsonSaved[0].buttons[1].url = '';

                        divSelectorTypeHeight2.hide();
                        divURL2.show();
                        divSpanValue2.hide();

                        // test keyup inputs web url
                        inputURL2.on('keyup', (event) => {
                            jsonSaved[0].buttons[1].url = event.target.value;
                        });

                        // end
                    } else if (eventSelectButtonCarousel2.target.value === 'phone_number') {
                        jsonSaved[0].buttons[1].value = '';

                        divSpanValue2.show();
                        divSelectorTypeHeight2.hide();
                        divURL2.hide();

                        inputValueQR2.on('keyup', (event) => {
                            jsonSaved[0].buttons[1].value = event.target.value;

                        });
                    } else if (eventSelectButtonCarousel2.target.value === 'web_view') {

                        jsonSaved[0].buttons[1].url = '';
                        jsonSaved[0].buttons[1].webview_height_ratio = '';
                        jsonSaved[0].buttons[1].messenger_extensions = true;
                        jsonSaved[0].buttons[1].fallback_url = '';

                        divURL2.show();
                        divSelectorTypeHeight2.show();
                        divSpanValue2.hide();

                        inputURL2.on('keyup', (event) => {
                            jsonSaved[0].buttons[1].url = event.target.value;
                            jsonSaved[0].buttons[1].fallback_url = event.target.value;
                        });

                    } else if (eventSelectButtonCarousel2.target.value === 'postback') {

                        jsonSaved[0].buttons[1].value = '';
                        divSpanValue2.hide();
                        divSelectorTypeHeight2.hide();
                        divURL2.hide();
                    } else {
                        divTitle.css('display', 'none');
                        divDescription.css('display', 'none');
                        divImage.css('display', 'none');
                        divTitleButton2.css('display', 'none');
                        divSpanValue2.hide();
                        divSelectorTypeHeight2.hide();
                    }

                });

                // end


                // event change select3

                selectTypeButtonCarousel3.on('change', (eventSelectButtonCarousel3) => {
                    jsonSaved[0].buttons[2] = {};

                    valueTypeButtonCarousel3 = eventSelectButtonCarousel3.target.value;

                    selectHeightRatio3.on('change', (eventSelectHeightRatio3) => {
                        valueHeightRatio3 = eventSelectHeightRatio3.target.value;
                        jsonSaved[0].buttons[2].webview_height_ratio = eventSelectHeightRatio3.target.value;
                    });

                    jsonSaved[0].buttons[2] = {
                        type: '',
                        title: ''
                    };
                    jsonSaved[0].buttons[2].type = valueTypeButtonCarousel3;
                    // console.log(jsonSaved);


                    // test view json keyup
                    buttonPreviewSlider3.off('click');
                    buttonPreviewSlider3.on('click', (event) => {
                        // console.log(JSON.stringify(jsonSaved));
                    });

                    if (eventSelectButtonCarousel3.target.value === 'web_url') {
                        jsonSaved[0].buttons[2].url = '';

                        divSelectorTypeHeight3.hide();
                        divURL3.show();
                        divSpanValue3.hide();

                        // test keyup inputs web url
                        inputURL3.on('keyup', (event) => {
                            jsonSaved[0].buttons[2].url = event.target.value;
                        });

                        // end
                    } else if (eventSelectButtonCarousel3.target.value === 'phone_number') {
                        jsonSaved[0].buttons[2].value = '';

                        divSpanValue3.show();
                        divSelectorTypeHeight3.hide();
                        divURL3.hide();

                        inputValueQR3.on('keyup', (event) => {
                            jsonSaved[0].buttons[2].value = event.target.value;

                        });
                    } else if (eventSelectButtonCarousel3.target.value === 'web_view') {

                        jsonSaved[0].buttons[2].url = '';
                        jsonSaved[0].buttons[2].webview_height_ratio = '';
                        jsonSaved[0].buttons[2].messenger_extensions = true;
                        jsonSaved[0].buttons[2].fallback_url = '';

                        divURL3.show();
                        divSelectorTypeHeight3.show();
                        divSpanValue3.hide();

                        inputURL3.on('keyup', (event) => {
                            jsonSaved[0].buttons[2].url = event.target.value;
                            jsonSaved[0].buttons[2].fallback_url = event.target.value;
                        });

                    } else if (eventSelectButtonCarousel3.target.value === 'postback') {

                        jsonSaved[0].buttons[2].value = '';
                        divSpanValue3.hide();
                        divSelectorTypeHeight3.hide();
                        divURL3.hide();
                    } else {
                        divTitle.css('display', 'none');
                        divDescription.css('display', 'none');
                        divImage.css('display', 'none');
                        divTitleButton2.css('display', 'none');
                        divSpanValue2.hide();
                        divSelectorTypeHeight2.hide();
                        divSpanValue3.hide();
                        divSelectorTypeHeight3.hide();
                    }

                });

                // end


                jsonSaved = [{
                    title: '',
                    image_url: '',
                    subtitle: '',
                    buttons: [{
                        type: '',
                        title: '',
                    }]
                }];

                inputTitle.on('keyup', (event) => {
                    jsonSaved[0].title = event.target.value;
                    // jsonSaved.titlePreview = event.target.value;
                    titlePreview.text(event.target.value);
                });

                textareaControl.on('keyup', (eventTextArea) => {
                    jsonSaved[0].content = eventTextArea.target.value;
                });
                inputDescription.on('keyup', (event) => {
                    jsonSaved[0].subtitle = event.target.value;
                    // jsonSaved.descriptionPreview = event.target.value;
                    descriptionPreview.text(event.target.value);
                });
                inputImage.on('keyup', (event) => {
                    jsonSaved[0].image_url = event.target.value;
                    // jsonSaved.imagePreview = event.target.value;
                    imagePreview.attr('src', event.target.value);
                });
                inputTitleButton.on('keyup', (event) => {
                    jsonSaved[0].buttons[0].title = event.target.value;
                    buttonPreviewSlider.text(event.target.value);
                    // jsonSaved[0].titleButtonPreview = event.target.value;
                });
                inputTitleButton2.on('keyup', (event) => {
                    jsonSaved[0].buttons[1].title = event.target.value;
                    buttonPreviewSlider2.text(event.target.value);
                    // jsonSaved[0].titleButtonPreview2 = event.target.value;
                });
                inputTitleButton3.on('keyup', (event) => {
                    jsonSaved[0].buttons[2].title = event.target.value;
                    buttonPreviewSlider3.text(event.target.value);
                    // jsonSaved[0].titleButtonPreview = event.target.value;
                });

                // console.log('%c value JSON STRUCTURE', 'background:purple;color:white', jsonSaved);
            }

            functionButtonSend('');

        });


        // LOAD VALUE JSON

        // button load edit
        buttonvalue.on('click', () => {

            modal.show();
        });


        function isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        buttonSaveD.on('click', (eClose) => {
            eClose.preventDefault();
            eClose.stopPropagation();
            const parentClose = $(eClose.target).parent();
            $(this).width('60%');
            buttonvalue.css('display', 'block');

            const valueTitle = parentClose.find('.titleButton').val();
            const valueTitleP = parentClose.find('.titleProperty').val();


            buttonSend.css('display', 'none');
            // console.log(figureSelectedO);
            const responseButtons: any = {
                url: inputURL.val(),
                type: 'json',
                showOutputs: true,
            };

            if (figureSelectedO === 'qr') {
                if (valueTitleP === '') {
                    this.alertService.warning('Al menos debe cargar un boton');
                }
            } else if (figureSelectedO === 'carousel') {

                if (valueTitle === '') {
                    this.alertService.warning('Al menos debe cargar un boton');
                }

            }

            // console.log('reponse', JSON.stringify(responseButtons))
            // original save json
            fieldJM.val(JSON.stringify(responseButtonsD));

            // console.log('%c JSON SEND END1', 'background:red;color:white', fieldJM.val());

            // console.log('value input', fieldJM);
        });

        if (value !== undefined) {


            // console.log('%c req ', 'background: red; color: white', req);

            const chunks = fieldJM.attr('name').split('#');
            const key = chunks[0];
            const key2 = chunks[1];

            // console.log('%c key ', 'background: red; color: white', key);
            // console.log('%c key2 ', 'background: red; color: white', key2);
            // console.log('%c value[key] ', 'background: red; color: white', value[key]);

            if (key in value && key2 in value[key]) {

                let json;
                let jsonCheckType;


                if ('req_name' !== key && '' !== key) {

                    const fieldValue = value[key];
                    const subreq = fieldValue[key2];


                    const jsonValue = subreq.value;


                    if (isJson(subreq.value)) {

                        // console.log('%c valor de subreq ', 'background: silver; color: white', jsonValue);
                        jsonCheckType = JSON.parse(jsonValue);
                        json = JSON.parse(jsonValue);


                        if (jsonCheckType.items) {
                            nameFigure = 'carousel';
                            jsonSaved = [json.items];

                            try {
                                if (json.items.includes('each')) {
                                    divButtonCreate1.css('border', '1px solid silver');
                                    $('.legend1').show();
                                    // remover eachs
                                    json = removeEach(json.items);
                                    jsonSaved = json;

                                    // console.log('%c each deleted ', 'background: white; color: blue', json);

                                    if (jsonCheckType.items) {
                                        nameFigure = 'carousel';
                                    } else {
                                        nameFigure = 'qr';
                                    }

                                }
                            } catch (error) {

                            }
                        } else {
                            nameFigure = 'qr';
                            divButtonCreate1.css('border', 'none');
                            $('.legend1').hide();
                            jsonSaved = [json.buttons];

                            try {
                                if (json.buttons.includes('each')) {

                                    // remover eachs
                                    json = removeEach(json.buttons);

                                    jsonSaved = json;
                                    // console.log('%c each deleted en buttons ', 'background: white; color: blue', json);

                                    if (jsonCheckType.items) {
                                        nameFigure = 'carousel';
                                    } else {
                                        nameFigure = 'qr';
                                    }

                                }
                            } catch (error) {

                            }
                        }
                        // console.log('%c valor de json with parse ', 'background: deepskyblue; color: white', json);

                        // console.log('%c valor de json with buttons ', 'background: deepskyblue; color: white', json.items);


                        if (jsonCheckType.type === 'json') {

                            if (jsonCheckType) {
                                nameFigure === 'qr' ? buttonLoadData.hide() : buttonLoadData.show();
                                // modal.box.append(buttonLoadData);

                                buttonLoadData.on('click', (eventLoadData) => {

                                    fetch(jsonCheckType.url)
                                        .then((response) => {
                                            return response.json();
                                        })
                                        .then((jsonData) => {
                                            // console.log(jsonData);


                                            const arrayKey = [];
                                            const arrayKey2 = [];

                                            $.each(jsonData, (key4, valueService) => {

                                                if (typeof (valueService) === 'object') {
                                                    arrayKey.push(key4);
                                                }
                                            });

                                            const array1 = jsonData[arrayKey[0]][0];


                                            $.each(array1, (key5, valueService) => {

                                                if (typeof (valueService) === 'object') {
                                                    arrayKey2.push(key5);
                                                }
                                            });

                                            // value second array
                                            // const level2 = array1[arrayKey2[0]];

                                            // console.log('%c value of arraykey1', 'background: cyan; color: black', array1);
                                            // console.log('%c value of arraykey2', 'background: cyan; color: black', arrayKey2[0]);


                                            if (nameFigure === 'qr') {

                                                const valueTitleButtonQR = removeCharacters(inputTitle.val());
                                                QRpreview.text(array1[valueTitleButtonQR]);
                                                divButtonCreate1.css('border', 'none');
                                                $('.legend1').hide();

                                            } else {
                                                const valueImage = removeCharacters(inputImage.val());
                                                const valueTitleCard = removeCharacters(inputTitle.val());
                                                const valueDescriptionCard = removeCharacters(inputDescription.val());
                                                const valueTitleButton1 = removeCharacters(inputTitleButton.val());
                                                const valueTitleButton2 = removeCharacters(inputTitleButton2.val());
                                                const valueTitleButton3 = removeCharacters(inputTitleButton3.val());


                                                const dotTitle = valueTitleCard.split('.').length - 1;
                                                const dotImage = valueImage.split('.').length - 1;
                                                const dotDescription = valueDescriptionCard.split('.').length - 1;
                                                const dotTitleButton1 = valueTitleButton1.split('.').length - 1;
                                                const dotTitleButton2 = valueTitleButton2.split('.').length - 1;
                                                const dotTitleButton3 = valueTitleButton3.split('.').length - 1;

                                                if (dotTitle === 2) {
                                                    const lastPropierty = valueTitleCard.split('.');
                                                    titlePreview.text(array1[arrayKey2[0]][lastPropierty[lastPropierty.length - 1]]);
                                                }
                                                if (dotImage === 2) {
                                                    const lastPropiertyImage = valueImage.split('.');
                                                    imagePreview.attr
                                                        ('src', array1[arrayKey2[0]][lastPropiertyImage[lastPropiertyImage.length - 1]]);
                                                }
                                                if (dotDescription === 2) {
                                                    const lastPropiertyDescription = valueDescriptionCard.split('.');
                                                    descriptionPreview.text
                                                        (array1[arrayKey2[0]][lastPropiertyDescription[lastPropiertyDescription.length - 1]]);
                                                }
                                                if (dotTitleButton1 === 2) {
                                                    const lastPropiertyButton1 = valueTitleButton1.split('.');
                                                    buttonPreviewSlider.text(
                                                        array1[arrayKey2[0]][lastPropiertyButton1[lastPropiertyButton1.length - 1]]);
                                                }
                                                if (dotTitleButton2 === 2) {
                                                    const lastPropiertyButton2 = valueTitleButton2.split('.');
                                                    buttonPreviewSlider2.text(
                                                        array1[arrayKey2[0]][lastPropiertyButton2[lastPropiertyButton2.length - 1]]);
                                                }

                                                if (dotTitleButton3 === 2) {
                                                    const lastPropiertyButton3 = valueTitleButton1.split('.');
                                                    buttonPreviewSlider3.text(
                                                        array1[arrayKey2[0]][lastPropiertyButton3[lastPropiertyButton3.length - 1]]);
                                                }

                                                try {
                                                    imagePreview.attr('src', array1[valueImage]);
                                                    titlePreview.text(array1[valueTitleCard]);
                                                    descriptionPreview.text(array1[valueDescriptionCard]);
                                                    buttonPreviewSlider.text(array1[valueTitleButton1]);
                                                    buttonPreviewSlider2.text(array1[valueTitleButton2]);
                                                    buttonPreviewSlider3.text(array1[valueTitleButton3]);
                                                } catch (error) {

                                                }
                                            }

                                        });

                                });

                                // console.log('%c value assigned jsonsaved', 'background: green; color: white', jsonSaved);
                                buttonSend.prop('disabled', false);
                                inputURL.css('display', 'block');
                                buttonSend.css('display', 'block');
                                buttonvalue.css('display', 'block');
                                textareaControl.css('display', 'block');
                                divTextareaContent.css('display', 'block');


                                if (nameFigure === 'qr') {
                                    spanTextAreaContent.text('texto:');
                                    textareaControl.val(jsonCheckType.text);
                                } else {
                                    spanTextAreaContent.text('contenido:');
                                    textareaControl.val(jsonCheckType.content);
                                    if (jsonSaved[0].buttons.length === 3) {
                                        NextButton.show();
                                        NextButton.prop('disabled', true);

                                    } else {
                                        NextButton.show();
                                    }
                                }

                                selectFigure.val(nameFigure);
                                inputURL.val(jsonCheckType.url);
                                divTree.css('display', 'block');
                                buttonSend.css('display', 'none');


                                // console.log('%c value JSON URL', 'background:cyan;color:black', jsonCheckType.url);


                                buttonvalue.on('click', () => {
                                    nameFigure === 'qr' ? modal.box.css('width', '60%') : modal.box.css('width', '90%');
                                    modal.show();


                                });


                                // crear

                                const validateURL = (url) => {
                                    if (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(url)) {
                                        return true;
                                    }
                                    return false;
                                };

                                inputURL.on('keyup', (e) => {
                                    if (validateURL(e.target.value)) {
                                        buttonSend.prop('disabled', false);
                                    } else {
                                        buttonSend.prop('disabled', true);
                                    }
                                });


                                // orig
                                // functionButtonSend(json.elements.figure);
                                functionButtonSend(nameFigure);

                                let targetCollisionDiv: any;

                                $('.controls').on('focus', (eventFocusInput) => {
                                    targetCollisionDiv = $(eventFocusInput.target);
                                });

                                const handleMove = (node, e) => {
                                };


                                const handleStop = (node, eventHandleStop) => {

                                    const keyRoot = divTree.tree('getTree');
                                    let path: any;
                                    const levelTree = node.getLevel();
                                    jsonSaved.url = inputURL.val();
                                    const getClass = targetCollisionDiv[0].className.split(' ')[1];

                                    if (levelTree === 2) {
                                        if (node.parent.name === '') {
                                            path = `{{${node.key}}}`;
                                        } else {
                                            if (node.parent.name.includes('【 】')) {
                                                path = `{{${node.key}}}`;
                                            } else {
                                                path = `{{${node.parent.key}.${node.key}}}`;
                                            }
                                        }

                                    } else if (levelTree === 3) {
                                        if (node.parent.parent.name === '') {
                                            path = `{{${node.parent.key}.${node.key}}}`;
                                        } else { path = `{{${node.parent.parent.key}.${node.parent.key}.${node.key}}}`; }

                                    } else if (levelTree === 4) {
                                        if (node.parent.parent.parent.name === '') {
                                            path = `{{${node.parent.parent.key}.${node.parent.key}.${node.key}}}`;
                                        } else {
                                            path = `{{${node.parent.parent.parent.key}.${node.parent.parent.key}.${node.parent.key}.${node.key}}}`;
                                        }

                                    }

                                    if (targetCollisionDiv[0] === inputImage[0]) {
                                        if (validateImage(node.value)) {
                                            targetCollisionDiv.val(path);
                                        } else {
                                            targetCollisionDiv.val('');
                                        }
                                    } else {
                                        try {
                                            if (targetCollisionDiv.val() !== '') {
                                                const valueInput = targetCollisionDiv.val();
                                                const texto1 = valueInput.substring(
                                                    0, getCursorPosition(document.querySelector(`.${getClass}`)));
                                                const texto2 = valueInput.substring(
                                                    getCursorPosition(document.querySelector(`.${getClass}`)), valueInput.length);
                                                targetCollisionDiv.val(texto1 + path + texto2);
                                            } else {
                                                targetCollisionDiv.val(path);
                                            }
                                        } catch (error) {

                                        }
                                    }


                                    // console.log('element dropped', eventHandleStop.target.textContent);
                                    // console.log('element selected', targetCollisionDiv);
                                    // console.log('%c value json elements.figure', 'background:purple;color:white', nameFigure);


                                    try {
                                        if (selectFigure.val() === 'qr') {
                                            jsonSaved[0].content_type = valueTypeButtonQr;

                                            if (targetCollisionDiv[0] === inputTitle[0]) {
                                                QRpreview.text(node.value);
                                                jsonSaved[0].title = targetCollisionDiv.val();
                                                // jsonSaved.titleQrPreview = node.value;

                                            } else if (targetCollisionDiv[0] === inputValueQR[0]) {
                                                jsonSaved[0].value = targetCollisionDiv.val();
                                            } else if (targetCollisionDiv[0] === textareaControl[0]) {
                                                jsonSaved[0].text = targetCollisionDiv.val();
                                            }

                                        } else {

                                            NextButton.show();
                                            // spanTextAreaContent.text('content:');
                                            jsonSaved[0].content = textareaControl.val();
                                            jsonSaved[0].buttons[0].type = valueTypeButtonCarousel;

                                            if (targetCollisionDiv[0] === inputTitle[0]) {
                                                titlePreview.text(node.value);
                                                // jsonSaved.titlePreview = node.value;

                                                jsonSaved[0].title = targetCollisionDiv.val();

                                            } else if (targetCollisionDiv[0] === inputURL3[0]) {
                                                jsonSaved[0].buttons[2].webview_height_ratio = valueHeightRatio3;
                                                jsonSaved[0].buttons[2].url = targetCollisionDiv.val();
                                                jsonSaved[0].buttons[2].fallback_url = targetCollisionDiv.val();

                                            } else if (targetCollisionDiv[0] === inputURL2[0]) {
                                                jsonSaved[0].buttons[1].webview_height_ratio = valueHeightRatio2;
                                                jsonSaved[0].buttons[1].url = targetCollisionDiv.val();
                                                jsonSaved[0].buttons[1].fallback_url = targetCollisionDiv.val();

                                            } else if (targetCollisionDiv[0] === inputValueQR2[0]) {
                                                jsonSaved[0].buttons[1].value = targetCollisionDiv.val();

                                            } else if (targetCollisionDiv[0] === inputValueQR3[0]) {
                                                jsonSaved[0].buttons[2].value = targetCollisionDiv.val();

                                            } else if (targetCollisionDiv[0] === inputDescription[0]) {
                                                descriptionPreview.text(node.value);
                                                // jsonSaved.descriptionPreview = node.value;
                                                jsonSaved[0].subtitle = targetCollisionDiv.val();
                                            } else if (targetCollisionDiv[0] === inputImage[0]) {
                                                if (validateImage(node.value)) {
                                                    imagePreview.attr('src', node.value);
                                                    // jsonSaved.imagePreview = node.value;
                                                    jsonSaved[0].image_url = targetCollisionDiv.val();
                                                }
                                            } else if (targetCollisionDiv[0] === inputTitleButton[0]) {
                                                jsonSaved[0].buttons[0].title = targetCollisionDiv.val();
                                                // jsonSaved.titleButtonPreview = node.value;
                                                buttonPreviewSlider.text(node.value);


                                            } else if (targetCollisionDiv[0] === inputTitleButton2[0]) {
                                                jsonSaved[0].buttons[1].title = targetCollisionDiv.val();
                                                // jsonSaved.titleButtonPreview = node.value;
                                                buttonPreviewSlider2.text(node.value);


                                            } else if (targetCollisionDiv[0] === inputTitleButton3[0]) {
                                                jsonSaved[0].buttons[2].title = targetCollisionDiv.val();
                                                // jsonSaved.titleButtonPreview = node.value;
                                                buttonPreviewSlider3.text(node.value);


                                            } else if (valueTypeButtonCarousel2 === 'postback') {
                                                jsonSaved[0].buttons[1].value = '';
                                            } else if (valueTypeButtonCarousel2 === 'phone_number') {
                                                jsonSaved[0].buttons[1].value = targetCollisionDiv.val();
                                            } else if (valueTypeButtonCarousel2 === 'web_view') {
                                                jsonSaved[0].buttons[1].webview_height_ratio = valueHeightRatio2;
                                                jsonSaved[0].buttons[1].url = targetCollisionDiv.val();
                                                jsonSaved[0].buttons[1].fallback_url = targetCollisionDiv.val();
                                            } else if (valueTypeButtonCarousel2 === 'web_url') {
                                                jsonSaved[0].buttons[1].url = targetCollisionDiv.val();
                                            } else if (valueTypeButtonCarousel3 === 'postback') {
                                                jsonSaved[0].buttons[2].value = '';
                                            } else if (valueTypeButtonCarousel3 === 'phone_number') {
                                                jsonSaved[0].buttons[2].value = targetCollisionDiv.val();
                                            } else if (valueTypeButtonCarousel3 === 'web_view') {
                                                // console.log('entro en el caso 3', targetCollisionDiv.val());
                                                jsonSaved[0].buttons[2].webview_height_ratio = valueHeightRatio3;
                                                jsonSaved[0].buttons[2].url = targetCollisionDiv.val();
                                                jsonSaved[0].buttons[2].fallback_url = targetCollisionDiv.val();
                                            } else if (valueTypeButtonCarousel3 === 'web_url') {
                                                jsonSaved[0].buttons[2].url = targetCollisionDiv.val();
                                            } else if (targetCollisionDiv[0] === inputValueQR[0]) {
                                                if (valueTypeButtonCarousel === 'postback') {
                                                    jsonSaved[0].buttons[0].value = '';
                                                } else if (valueTypeButtonCarousel === 'phone_number') {
                                                    jsonSaved[0].buttons[0].value = targetCollisionDiv.val();
                                                } else if (valueTypeButtonCarousel === 'web_view') {
                                                    jsonSaved[0].buttons[0].webview_height_ratio = valueHeightRatio;
                                                    jsonSaved[0].buttons[0].url = targetCollisionDiv.val();
                                                    jsonSaved[0].buttons[0].fallback_url = targetCollisionDiv.val();
                                                } else if (valueTypeButtonCarousel === 'web_url') {
                                                    jsonSaved[0].buttons[0].url = targetCollisionDiv.val();
                                                }
                                            } else if (targetCollisionDiv[0] === textareaControl[0]) {
                                                jsonSaved[0].content = targetCollisionDiv.val();
                                            }
                                        }

                                    } catch (error) {
                                        console.log(error);
                                    }


                                    try {


                                        if (selectFigure.val() !== 'qr') {
                                            const removeEmptyObjects = jsonSaved[0].buttons.filter
                                                (value4 => Object.keys(value4).length !== 0);
                                            jsonSaved[0].buttons = removeEmptyObjects;

                                        }



                                        const StringInsertEach = Object.assign({}, jsonSaved[0]);

                                        delete StringInsertEach.url;
                                        delete StringInsertEach.figure;
                                        delete StringInsertEach.imagePreview;
                                        delete StringInsertEach.titlePreview;
                                        delete StringInsertEach.titleQrPreview;
                                        delete StringInsertEach.descriptionPreview;
                                        delete StringInsertEach.titleButtonPreview;
                                        delete StringInsertEach.text;
                                        delete StringInsertEach.content;

                                        if (keyRoot.children[0].name.includes('【 】')) {
                                            result = functionInsertEach(StringInsertEach, keyRoot.children[0].key);
                                            // console.log('%c string with each', 'background:purple;color:white', result);
                                        } else {
                                            // console.log('%c string  each', 'background:purple;color:white', JSON.stringify(StringInsertEach));
                                            // result = StringInsertEach;
                                            result = functionInsertEach(StringInsertEach, '');
                                        }
                                    } catch (error) {

                                    }

                                    const responseButtons: any = {
                                        url: jsonCheckType.url,
                                        type: 'json',
                                        showOutputs: true,
                                    };

                                    if (spanTextAreaContent.text() === 'texto:') {
                                        // console.log('add pro text:', jsonSaved.text);
                                        responseButtons.text = jsonSaved[0].text;
                                        responseButtons.buttons = result;

                                    } else {
                                        // console.log('add pro content:', jsonSaved.content);
                                        responseButtons.content = jsonSaved[0].content;
                                        responseButtons.items = result;
                                    }


                                    if (
                                        req.constraints &&
                                        typeof req.constraints === 'object' &&
                                        'type' in req.constraints
                                    ) {
                                        if ('noOutputs' === req.constraints.type) {
                                            responseButtons.showOutputs = false;
                                        }
                                    }
                                    fieldJM.val(JSON.stringify(responseButtons));
                                    // console.log('%c value JSON FIELDJM', 'background:red;color:white', fieldJM.val());
                                };

                                $.ajax({
                                    url: jsonCheckType.url,
                                    success: (myJson) => {
                                        const resJsonConverted = convertJsonTree(myJson);

                                        divTree.tree({
                                            animationSpeed: 'fast',
                                            data: resJsonConverted,
                                            autoOpen: true,
                                            keyboardSupport: true,
                                            dragAndDrop: true,
                                            buttonLeft: false,
                                            onDragMove: handleMove,
                                            onDragStop: handleStop,
                                            selectable: true,
                                            closedIcon: $('<img style="width:20px;height:20px" src="../assets/img/openFolder.svg" alt="">'),
                                            openedIcon: $('<img style="width:20px;height:20px" src="../assets/img/closeFolder.svg" alt="">')
                                        });


                                        divTree.on(
                                            'tree.move',
                                            (event) => {
                                                return false;
                                            }
                                        );

                                        $('span.jqtree_common').each(function (e) {
                                            const itemTree = $(this).text().length;

                                            if (itemTree > 40) {
                                                $(this).addClass('loadEllipsis');
                                            }
                                        });
                                    },
                                    error: () => {
                                    }
                                });

                                // origin json.elements.figure
                                if (nameFigure === 'qr') {
                                    // $('.modal2').width('60%');
                                    modal.box.css('width', '60%');
                                    // console.log('figure is qr');
                                    divSelectorType.css('display', 'block');
                                    divTitle.css('display', 'block');
                                    QRpreview.css('display', 'block');
                                    divDescription.css('display', 'none');
                                    divImage.css('display', 'none');
                                    divTitleButton.css('display', 'none');
                                    divSelectorTypeCarousel.css('display', 'none');
                                    divSelectorTypeHeight.css('display', 'none');
                                    SliderPreview.css('display', 'none');
                                    textareaControl.attr('placeholder', 'value');

                                    inputTitle.on('keyup', (event) => {
                                        QRpreview.text(event.target.value);
                                        jsonSaved[0].title = event.target.value;
                                        jsonSaved[0].titleQrPreview = event.target.value;
                                    });
                                    textareaControl.on('keyup', (event) => {
                                        jsonSaved[0].text = event.target.value;
                                    });


                                    // textareaControl.val(jsonSaved.text);

                                    // QRpreview.text(jsonSaved.titleQrPreview);
                                    inputTitle.val(jsonSaved[0].title);
                                    selectTypeButton.val(jsonSaved[0].content_type);


                                    selectTypeButton.on('change', (event) => {
                                        jsonSaved = [{ content_type: '', title: '', value: '' }];
                                        textareaControl.val('');
                                        inputValueQR.val('');
                                        inputTitle.val('');
                                        QRpreview.text('Botón');

                                        valueTypeButtonQr = event.target.value;
                                        jsonSaved[0].content_type = event.target.value;

                                        if (event.target.value === 'user_phone_number' || event.target.value === 'user_email') {
                                            divSpanValue.css('display', 'block');
                                            divTitle.css('display', 'block');
                                        } else if (event.target.value === 'text') {
                                            divSpanValue.css('display', 'none');
                                        } else if (event.target.value === '') {
                                            divSpanValue.css('display', 'none');
                                            divTitle.css('display', 'none');
                                        }
                                    });

                                    if (jsonSaved[0].content_type === 'user_phone_number' ||
                                        jsonSaved[0].content_type === 'user_email') {
                                        divSpanValue.css('display', 'block');
                                        inputValueQR.val(jsonSaved[0].value);
                                    } else {
                                        divSpanValue.css('display', 'none');
                                    }

                                } else {

                                    // load components carousel
                                    // $('.modal2').width('90%');
                                    modal.box.css('width', '90%');
                                    divSelectorTypeCarousel.css('display', 'block');
                                    divTitle.css('display', 'block');
                                    divDescription.css('display', 'block');
                                    divImage.css('display', 'block');
                                    divTitleButton.css('display', 'block');
                                    SliderPreview.css('display', 'inline-block');

                                    QRpreview.css('display', 'none');
                                    divSelectorTypeHeight.css('display', 'none');
                                    // textareaControl.val(jsonSaved.content);
                                    textareaControl.attr('placeholder', 'content');

                                    selectTypeButtonCarousel.val(jsonSaved[0].buttons[0].type);

                                    // begin reload fieldset
                                    try {
                                        if (jsonSaved[0].buttons[1].type !== '') {
                                            divButtonCreate2.show();
                                            selectTypeButtonCarousel2.val(jsonSaved[0].buttons[1].type);
                                            inputTitleButton2.val(jsonSaved[0].buttons[1].title);
                                            if (json[0].buttons[1].type === 'web_url') {
                                                divURL2.show();
                                                inputURL2.val(json[0].buttons[1].url);
                                            } else if (json[0].buttons[1].type === 'web_view') {
                                                divURL2.show();
                                                divSelectorTypeHeight2.show();
                                                selectHeightRatio2.val(json[0].buttons[1].webview_height_ratio);
                                                inputURL2.val(json[0].buttons[1].url);

                                            } else if (json[0].buttons[1].type === 'phone_number') {
                                                divSpanValue2.show();
                                                inputValueQR2.val(json[0].buttons[1].value);
                                            } else if (json[0].buttons[1].type === 'postback') {
                                            }
                                        }
                                        if (jsonSaved[0].buttons[2].type !== '') {
                                            divButtonCreate3.show();
                                            selectTypeButtonCarousel3.val(jsonSaved[0].buttons[2].type);
                                            inputTitleButton3.val(jsonSaved[0].buttons[2].title);
                                            if (json[0].buttons[2].type === 'web_url') {
                                                divURL3.show();
                                                inputURL3.val(json[0].buttons[2].url);
                                            } else if (json[0].buttons[2].type === 'web_view') {
                                                divURL3.show();
                                                divSelectorTypeHeight3.show();
                                                selectHeightRatio3.val(json[0].buttons[2].webview_height_ratio);
                                                inputURL3.val(json[0].buttons[2].url);

                                            } else if (json[0].buttons[2].type === 'phone_number') {
                                                divSpanValue3.show();
                                                inputValueQR3.val(json[0].buttons[2].value);
                                            } else if (json[0].buttons[2].type === 'postback') {
                                            }
                                        }
                                    } catch (error) {

                                    }

                                    // end

                                    textareaControl.on('keyup', (event) => {
                                        jsonSaved[0].content = event.target.value;
                                    });
                                    inputTitle.on('keyup', (event) => {
                                        jsonSaved[0].title = event.target.value;
                                        // jsonSaved.titlePreview = event.target.value;
                                        titlePreview.text(event.target.value);
                                    });

                                    inputDescription.on('keyup', (event) => {
                                        jsonSaved[0].subtitle = event.target.value;
                                        // jsonSaved.descriptionPreview = event.target.value;
                                        descriptionPreview.text(event.target.value);
                                    });
                                    inputImage.on('keyup', (event) => {
                                        jsonSaved[0].image_url = event.target.value;
                                        // jsonSaved.imagePreview = event.target.value;
                                        imagePreview.attr('src', event.target.value);
                                    });
                                    inputTitleButton.on('keyup', (event) => {
                                        jsonSaved[0].buttons[0].title = event.target.value;
                                        buttonPreviewSlider.text(event.target.value);
                                        // jsonSaved.titleButtonPreview = event.target.value;
                                    });
                                    inputTitleButton2.on('keyup', (event) => {
                                        jsonSaved[0].buttons[1].title = event.target.value;
                                        buttonPreviewSlider2.text(event.target.value);
                                        // jsonSaved[0].titleButtonPreview2 = event.target.value;
                                    });
                                    inputTitleButton3.on('keyup', (event) => {
                                        jsonSaved[0].buttons[2].title = event.target.value;
                                        buttonPreviewSlider3.text(event.target.value);
                                        // jsonSaved[0].titleButtonPreview = event.target.value;
                                    });

                                    selectTypeButtonCarousel.on('change', (event) => {
                                        valueTypeButtonCarousel = event.target.value;

                                        buttonPreviewSlider.text('Botón 1');
                                        jsonSaved[0].buttons[0] = {
                                            type: '',
                                            title: ''
                                        };

                                        jsonSaved[0].buttons[0].type = valueTypeButtonCarousel;

                                        if (event.target.value === 'web_url') {
                                            divSpanValue.css('display', 'block');
                                            spanInputValue.text('url');
                                            inputValueQR.attr('placeholder', 'url');
                                            divSelectorTypeHeight.css('display', 'none');

                                        } else if (event.target.value === 'web_view') {

                                            divSpanValue.css('display', 'block');
                                            spanInputValue.text('url');
                                            inputValueQR.attr('placeholder', 'url');
                                            divSelectorTypeHeight.css('display', 'block');

                                            selectHeightRatio.on('change', (eventSelectHeightRatio) => {
                                                valueHeightRatio = eventSelectHeightRatio.target.value;
                                                jsonSaved[0].buttons[0].webview_height_ratio = valueHeightRatio;
                                                inputValueQR.on('keyup', (eventValueQR1) => {
                                                    jsonSaved[0].buttons[0].url = eventValueQR1.target.value;
                                                });

                                            });

                                        } else if (event.target.value === 'phone_number') {
                                            divSpanValue.css('display', 'block');
                                            spanInputValue.text('value');
                                            inputValueQR.attr('placeholder', 'value');
                                            divSelectorTypeHeight.css('display', 'none');

                                            inputValueQR.on('keyup', (eventValueQR) => {
                                                jsonSaved[0].buttons[0].value = eventValueQR.target.value;
                                            });

                                        } else if (event.target.value === 'postback') {
                                            divSpanValue.css('display', 'none');
                                            divSelectorTypeHeight.css('display', 'none');
                                        } else {
                                            divSpanValue.css('display', 'none');
                                            divSelectorTypeHeight.css('display', 'none');
                                            divSpanValue.css('display', 'none');
                                            divDescription.css('display', 'none');
                                            divImage.css('display', 'none');
                                            divTitleButton.css('display', 'none');
                                            divTitle.css('display', 'none');
                                        }
                                    });


                                    // load event change selectTypeButtonCarousel2

                                    selectTypeButtonCarousel2.on('change', (eventSelectButtonCarousel2) => {
                                        jsonSaved[0].buttons[1] = {};

                                        valueTypeButtonCarousel2 = eventSelectButtonCarousel2.target.value;

                                        selectHeightRatio2.on('change', (eventSelectHeightRatio2) => {
                                            valueHeightRatio2 = eventSelectHeightRatio2.target.value;
                                            jsonSaved[0].buttons[1].webview_height_ratio = eventSelectHeightRatio2.target.value;
                                        });

                                        jsonSaved[0].buttons[1] = {
                                            type: '',
                                            title: ''
                                        };

                                        jsonSaved[0].buttons[1].type = valueTypeButtonCarousel2;
                                        // console.log(jsonSaved);


                                        // test view json keyup
                                        buttonPreviewSlider.off('click');
                                        buttonPreviewSlider.on('click', (event) => {
                                            // console.log(JSON.stringify(jsonSaved));
                                        });

                                        if (eventSelectButtonCarousel2.target.value === 'web_url') {
                                            jsonSaved[0].buttons[1].url = '';

                                            divSelectorTypeHeight2.hide();
                                            divURL2.show();
                                            divSpanValue2.hide();

                                            // test keyup inputs web url
                                            inputURL2.on('keyup', (event) => {
                                                jsonSaved[0].buttons[1].url = event.target.value;
                                            });

                                            // end
                                        } else if (eventSelectButtonCarousel2.target.value === 'phone_number') {
                                            jsonSaved[0].buttons[1].value = '';

                                            divSpanValue2.show();
                                            divSelectorTypeHeight2.hide();
                                            divURL2.hide();

                                            inputValueQR2.on('keyup', (event) => {
                                                jsonSaved[0].buttons[1].value = event.target.value;

                                            });
                                        } else if (eventSelectButtonCarousel2.target.value === 'web_view') {

                                            jsonSaved[0].buttons[1].url = '';
                                            jsonSaved[0].buttons[1].webview_height_ratio = '';
                                            jsonSaved[0].buttons[1].messenger_extensions = true;
                                            jsonSaved[0].buttons[1].fallback_url = '';

                                            divURL2.show();
                                            divSelectorTypeHeight2.show();
                                            divSpanValue2.hide();

                                            inputURL2.on('keyup', (event) => {
                                                jsonSaved[0].buttons[1].url = event.target.value;
                                                jsonSaved[0].buttons[1].fallback_url = event.target.value;
                                            });

                                        } else if (eventSelectButtonCarousel2.target.value === 'postback') {

                                            jsonSaved[0].buttons[1].value = '';
                                            divSpanValue2.hide();
                                            divSelectorTypeHeight2.hide();
                                            divURL2.hide();
                                        } else {
                                            divTitle.css('display', 'none');
                                            divDescription.css('display', 'none');
                                            divImage.css('display', 'none');
                                            divTitleButton2.css('display', 'none');
                                            divSpanValue2.hide();
                                            divSelectorTypeHeight2.hide();
                                        }

                                    });

                                    // end



                                    // load event change selectTypeButtonCarousel3

                                    selectTypeButtonCarousel3.on('change', (eventSelectButtonCarousel3) => {
                                        jsonSaved[0].buttons[2] = {};
                                        valueTypeButtonCarousel3 = eventSelectButtonCarousel3.target.value;

                                        selectHeightRatio3.on('change', (eventSelectHeightRatio3) => {
                                            valueHeightRatio3 = eventSelectHeightRatio3.target.value;
                                            jsonSaved[0].buttons[2].webview_height_ratio = eventSelectHeightRatio3.target.value;
                                        });

                                        jsonSaved[0].buttons[2] = {
                                            type: '',
                                            title: ''
                                        };
                                        jsonSaved[0].buttons[2].type = valueTypeButtonCarousel3;
                                        // console.log(jsonSaved);


                                        // test view json keyup
                                        buttonPreviewSlider.off('click');
                                        buttonPreviewSlider.on('click', (event) => {
                                            // console.log(JSON.stringify(jsonSaved));
                                        });

                                        if (eventSelectButtonCarousel3.target.value === 'web_url') {
                                            jsonSaved[0].buttons[2].url = '';

                                            divSelectorTypeHeight3.hide();
                                            divURL3.show();
                                            divSpanValue3.hide();

                                            // test keyup inputs web url
                                            inputURL3.on('keyup', (event) => {
                                                jsonSaved[0].buttons[2].url = event.target.value;
                                            });

                                            // end
                                        } else if (eventSelectButtonCarousel3.target.value === 'phone_number') {
                                            jsonSaved[0].buttons[2].value = '';

                                            divSpanValue3.show();
                                            divSelectorTypeHeight3.hide();
                                            divURL3.hide();

                                            inputValueQR3.on('keyup', (event) => {
                                                jsonSaved[0].buttons[2].value = event.target.value;

                                            });
                                        } else if (eventSelectButtonCarousel3.target.value === 'web_view') {

                                            jsonSaved[0].buttons[2].url = '';
                                            jsonSaved[0].buttons[2].webview_height_ratio = '';
                                            jsonSaved[0].buttons[2].messenger_extensions = true;
                                            jsonSaved[0].buttons[2].fallback_url = '';

                                            divURL3.show();
                                            divSelectorTypeHeight3.show();
                                            divSpanValue3.hide();

                                            inputURL3.on('keyup', (event) => {
                                                jsonSaved[0].buttons[2].url = event.target.value;
                                                jsonSaved[0].buttons[2].fallback_url = event.target.value;
                                            });

                                        } else if (eventSelectButtonCarousel3.target.value === 'postback') {

                                            jsonSaved[0].buttons[2].value = '';
                                            divSpanValue3.hide();
                                            divSelectorTypeHeight3.hide();
                                            divURL3.hide();
                                        } else {
                                            divTitle.css('display', 'none');
                                            divDescription.css('display', 'none');
                                            divImage.css('display', 'none');
                                            divTitleButton2.css('display', 'none');
                                            divSpanValue2.hide();
                                            divSelectorTypeHeight2.hide();
                                            divSpanValue3.hide();
                                            divSelectorTypeHeight3.hide();
                                        }

                                    });

                                    // end

                                    if (jsonSaved[0].buttons[0].type === 'web_url') {
                                        divSpanValue.css('display', 'block');
                                        spanInputValue.text('url');
                                        inputValueQR.attr('placeholder', 'url');
                                        inputValueQR.val(jsonSaved[0].buttons[0].url);

                                    } else if (jsonSaved[0].buttons[0].type === 'web_view') {
                                        divSpanValue.css('display', 'block');
                                        divSelectorTypeHeight.css('display', 'block');
                                        spanInputValue.text('url');
                                        inputValueQR.attr('placeholder', 'url');
                                        selectHeightRatio.val(jsonSaved[0].buttons[0].webview_height_ratio);
                                        inputValueQR.val(jsonSaved[0].buttons[0].url);
                                    } else if (jsonSaved[0].buttons[0].type === 'phone_number') {
                                        divSpanValue.css('display', 'block');
                                        spanInputValue.text('value');
                                        inputValueQR.attr('placeholder', 'value');
                                        inputValueQR.val(jsonSaved[0].buttons[0].value);

                                    } else if (jsonSaved[0].buttons[0].type === 'postback') {
                                        divSpanValue.css('display', 'none');
                                    }

                                    inputTitle.val(jsonSaved[0].title);
                                    inputDescription.val(jsonSaved[0].subtitle);
                                    inputImage.val(jsonSaved[0].image_url);
                                    inputTitleButton.val(jsonSaved[0].buttons[0].title);

                                }
                            }
                        }
                    }

                }
            }

        }

        // END

        return containerTree;
    }

    private arraymove(arr, OldIndex, NewIndex) {
        const element = arr[OldIndex];
        arr.splice(OldIndex, 1);
        arr.splice(NewIndex, 0, element);
        return arr;
    }

}
