import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private http: HttpClient) { }

    upload(slug: string, file: any, reToken: string) {
        const url = '/coreapi/v2/document';

        const headers = new HttpHeaders({
            Slug: slug,
            'x-meta-acl': 'public-read',
            'Re-Token': reToken
        });

        return this.http.post(url, file, { headers });
    }

}
