import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Diagram } from '../models/diagram';
import {FlowPermission} from '../models/flow-permission';

@Injectable({
    providedIn: 'root'
})
export class DiagramService {

    constructor(private http: HttpClient) { }

    getAllBySlug(diagramSlug: string, customerSlug: string, platformSlug): Observable<Diagram[]> {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        // const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild?outputType=verySimpleMap`;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/bySlug/${diagramSlug}`;

        const headers = new HttpHeaders({
            Slug: customerSlug,
            'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            Pragma: 'no-cache',
            Expires: '0'
        });

        return this.http.get<Diagram[]>(url, { headers });
    }

    getLast(customerSlug: string, platformSlug: string): Observable<Diagram[]> {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/last?outputType=withSecondary`;

        const headers = new HttpHeaders({
            Slug: customerSlug,
            'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            Pragma: 'no-cache',
            Expires: '0'
        });

        return this.http.get<Diagram[]>(url, { headers });
    }

    getLastMinimal(customerSlug: string, platformSlug: string): Observable<Diagram[]> {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/lastMinimal?outputType=withSecondary`;

        const headers = new HttpHeaders({
            Slug: customerSlug,
            'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            Pragma: 'no-cache',
            Expires: '0'
        });

        return this.http.get<Diagram[]>(url, { headers });
    }

    getJsonById(diagramId: string, customerSlug: string, platformSlug: string) {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/${diagramId}?outputType=simpleMap`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.get<any>(url, { headers });
    }

    getById(diagramId: string, customerSlug: string, platformSlug: string): Observable<Diagram[]> {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/${diagramId}?outputType=withSecondary`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.get<Diagram[]>(url, { headers });
    }

    getAllPermissionsBySlug(diagramSlug: string, customerSlug: string, platformSlug: string): Observable<FlowPermission[]> {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/${diagramSlug}/allPermissions`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.get<FlowPermission[]>(url, { headers });
    }
    getPermissionsBySlug(diagramSlug: string, platformSlug: string, customerSlug: string): Observable<FlowPermission> {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/${diagramSlug}/permissions/bySlug`;

        const headers = new HttpHeaders({
            Slug: customerSlug,
            'Show-Error': 'false'
        });

        return this.http.get<FlowPermission>(url, { headers });
    }

    addPermissionsBySlug(diagramSlug: string, customerSlug: string, platformSlug: string, payload: {}): Observable<FlowPermission> {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/${diagramSlug}/permissions`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.post<FlowPermission>(url, payload, { headers });
    }

    deletePermissionsBySlug(diagramSlug: string, customerSlug: string, platformSlug: string, payload: {}) {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/${diagramSlug}/permissions`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        const options = {
            headers,
            body: payload
        };

        return this.http.delete(url, options);
    }

    deleteBySlug(diagramSlug: string, customerSlug: string, platformSlug: string, payload: {}){
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/${diagramSlug}/bySlug`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });
        const options = {
            headers,
            body: payload
        };
        return this.http.delete(url, options);
    }



    add(diagram: Diagram, customerSlug: string, platformSlug: string) {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.post(
            url,
            diagram,
            { headers }
        );
    }

    delete(diagramId: string, customerSlug: string, platformSlug: string) {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/${diagramId}`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.delete(
            url,
            { headers }
        );
    }

    updateJsonById(json: string, diagramId: string, customerSlug: string, platformSlug: string) {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/${diagramId}/json`;
        // revisar kitchen-service, también ahí se guarda

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.patch(
            url,
            { json },
            { headers }
        );
    }

    changeVersion(diagramId: string, customerSlug: string, platformSlug: string) {
        platformSlug = platformSlug === 'APP' ? 'SMOOCH_APP' : platformSlug;
        const url = `/coreapi/v2/platforms/${platformSlug}/flowPrebuild/${diagramId}/lastModifiedAt`;

        const headers = new HttpHeaders({
            Slug: customerSlug
        });

        return this.http.patch(
            url,
            {},
            { headers }
        );
    }
}
