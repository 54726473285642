import { Injectable, SecurityContext } from '@angular/core';
import { InteractiveMessages, Button } from 'src/models/interactive-message';
import { AlertService } from '../alert.service';
import * as joint from "../../../vendor/rappid";
import { DomSanitizer } from "@angular/platform-browser";
import { FileService } from '../file.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare var translate: any;
@Injectable({
    providedIn: 'root'
})
export class ReplyButtonsService {
    translate: any;
    private field: any;
    private fieldName: string;
    private alertService: AlertService;
    private replyButtons: InteractiveMessages;
    private customerSlug: string;
    private maxSize: number;
    private allowedFileExtensions: any;
    private domSanitizer: DomSanitizer;
    headerType: string;
    showOutputs: boolean;
    private fileService: FileService;
    private recaptchaV3Service: ReCaptchaV3Service;

    constructor(customerSlug, alert, domSanitizer, fileService, recaptchaV3Service) {
        this.headerType = '';
        this.fileService = fileService;
        this.recaptchaV3Service = recaptchaV3Service;
        this.replyButtons = new InteractiveMessages('button', this.alertService);
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this.customerSlug = customerSlug;
        this.alertService = alert;
        this.maxSize = 16000000; // 16MB
        this.showOutputs = true;
        this.domSanitizer = domSanitizer;
        this.allowedFileExtensions = {
            image: [
                'jpeg',
                'jpg',
                'png',
                'webp'
            ],
            video: [
                'mp4'
            ],
            document: [
                'pdf',
                'doc',
                'docx',
                'xls',
                'xlsx',
                'csv'
            ],
        }
    }

    // Begin multimedia

    dropHandler(event, previewImage, previewVideo, previewLink, previewLoaderImage, dropZoneDiv, previewDiv, acceptedFiles, constraints, chunkText) {
        event.preventDefault();
        const ev = event.originalEvent;

        if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    const file = ev.dataTransfer.items[i].getAsFile();
                    let accepted: any = true;
                    if (constraints[0].key !== 'all'){
                        const chunks = file.name.split('.');
                        const extension: string = chunks.pop().toLowerCase();
                        if (acceptedFiles.find(element => element == '.' + extension) === undefined) {
                            accepted = false;
                        }
                    }

                    if (accepted){
                        // 16MB maximo
                        if (file.size <= this.maxSize) {
                            previewLoaderImage.fadeIn();
                            dropZoneDiv.fadeOut();
                            previewDiv.fadeIn();
                            previewLink.text(file.name);
                            previewLink.removeAttr('href');
                            const type = this.getTypeFileByExtension(file.name);
                            this.uploadFile(file, previewImage, previewVideo, previewLoaderImage, type);
                        } else {
                            this.removeDragData(ev);
                            this.alertService.error(`` + translate.sections.services.lessMb + ``);
                        }
                    } else {
                        this.removeDragData(ev);
                        this.alertService.error(`` + translate.sections.services.noFileSelected + ` ${chunkText}`);
                    }
                }
            }
        } else {
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                let accepted: any = true;
                if (constraints[0].key !== 'all'){
                    const chunks = ev.dataTransfer.files[i].name.split('.');
                    const extension = chunks.pop();
                    if (acceptedFiles.find(element => element == '.' + extension) === undefined) {
                        accepted = false;
                    }
                }

                if (accepted){
                    if (ev.dataTransfer.files[i].size <= this.maxSize) {
                        previewLoaderImage.fadeIn();
                        dropZoneDiv.fadeOut();
                        previewDiv.fadeIn();
                        previewLink.text(ev.dataTransfer.files[i].name);
                        previewLink.removeAttr('href');
                        const type = this.getTypeFileByExtension(ev.dataTransfer.files[i].name);
                        this.uploadFile(ev.dataTransfer.files[i], previewImage, previewVideo, previewLoaderImage, type);
                    } else {
                        this.removeDragData(ev);
                        this.alertService.error(`` + translate.sections.services.lessMb + ``);
                    }
                } else {
                    this.removeDragData(ev);
                    this.alertService.error(`` + translate.sections.services.noFileSelected + ` ${chunkText}`);
                }
            }
        }
        // this.removeDragData(ev)
    }

    dragOverHandler(ev) {
        ev.preventDefault();
    }

    removeDragData(ev) {
        if (ev.dataTransfer.items) {
            ev.dataTransfer.items.clear();
        } else {
            ev.dataTransfer.clearData();
        }
    }

    removeFile(previewImage, previewVideo, previewLink, dropZoneDiv, previewDiv) {
        previewImage.removeAttr('src');
        previewLink.text('');
        previewLink.removeAttr('href');
        previewVideo.removeAttr('src');
        previewImage.hide();
        previewVideo.hide();
        previewDiv.fadeOut();
        dropZoneDiv.fadeIn();
        delete this.replyButtons.header;
        this.saveOnField();
    }

    uploadFile(file, previewImage, previewVideo, previewLoaderImage, type) {
        const formFiles = new FormData();
        formFiles.append('document', file);
        this.recaptchaV3Service.execute('uploadFile').subscribe((reToken) => {
            this.fileService.upload(this.customerSlug, formFiles, reToken).subscribe({
                next: (response: any) => {
                    if (type === 'image') {
                        previewImage.attr('src', response.url);
                        previewImage.show();
                        previewVideo.hide();
                    } else if (type === 'video') {
                        previewVideo.attr('src', response.url);
                        previewImage.hide();
                        previewVideo.show();
                    }
                    previewLoaderImage.fadeOut();

                    if (type === 'image') {
                        this.replyButtons.header = {
                            type: {
                                type
                            },
                            image: {
                                link: response.url
                            }
                        }
                    }
                    if (type === 'video') {
                        this.replyButtons.header = {
                            type: {
                                type
                            },
                            video: {
                                link: response.url
                            }
                        }
                    }
                    if (type === 'document') {
                        this.replyButtons.header = {
                            type: {
                                type
                            },
                            document: {
                                link: response.url,
                                // filename: ''
                            }
                        }
                    }
                    // this.replyButtons.header[type].link = response.url;
                    // console.log(this.replyButtons);
                    this.saveOnField();
                },
                error: (err) => {
                    this.alertService.error('' + translate.sections.services.uploadError + '');
                    console.error('Error:', err);
                }
            });
        });
    }

    getAcceptedFiles(constraints) {
        const acceptedFiles = [];

        constraints.forEach(constaint => {
            for (const typeName in this.allowedFileExtensions) {
                if (Object.prototype.hasOwnProperty.call(this.allowedFileExtensions, typeName)) {
                    if (typeName === constaint.key) {
                        const type = this.allowedFileExtensions[typeName];
                        type.forEach(extension => {
                            acceptedFiles.push('.' + extension);
                        });
                    }
                }
            }
        });

        return acceptedFiles;
    }

    getTypeFileByExtension(file) {
        const extension = file.split('.').pop();
        let fileType = '';

        for (const typeName in this.allowedFileExtensions) {
            if (Object.prototype.hasOwnProperty.call(this.allowedFileExtensions, typeName)) {
                const type = this.allowedFileExtensions[typeName];
                type.forEach(ext => {
                    if (extension === ext) {
                        fileType = typeName;
                    }
                });
            }
        }

        return fileType;
    }

    getIconFile(constraints) {
        let icon = 'attachment';
        if (constraints.length == 1) {
            switch(constraints[0].key) {
                case 'image':
                    icon = 'image';
                    break;
                case 'video':
                    icon = 'play_circle_outline';
                    break;
                case 'document':
                    icon = 'description';
                    break;
            }
        }

        return icon;
    }

    getTextFile(constraints) {
        // console.log(constraints);
        const texts = [];
        constraints.forEach(constaint => {
            switch(constaint.key) {
                case 'image':
                    texts.push('una imagen');
                    break;
                case 'video':
                    texts.push('un video');
                    break;
                case 'document':
                    texts.push('un documento');
                    break;
            }
        });
        return texts.join(' o ');
    }

    openFileSelector(previewImage, previewVideo, previewLink, previewLoaderImage, dropZoneDiv, previewDiv, acceptedFiles, constraints) {
        let input = document.createElement('input');
        input.type = 'file';
        if (constraints[0].key !== 'all') {
            const accept = acceptedFiles.join(', ');
            $(input).attr('accept', accept);
        }
        input.onchange = _ => {
            let files = Array.from(input.files);
            if (files[0].size <= this.maxSize) {
                previewLoaderImage.fadeIn();
                dropZoneDiv.fadeOut();
                previewDiv.fadeIn();
                previewLink.text(files[0].name);
                previewLink.removeAttr('href');
                const type = this.getTypeFileByExtension(files[0].name);
                this.uploadFile(files[0], previewImage, previewVideo, previewLoaderImage, type);
            } else {
                this.alertService.error(`` + translate.sections.services.lessMb + ``);
            }
        };
        input.click();
    }

    getMultimedia() {
        const multimediaDiv = $('<div class="multimedia" />');
        const dropZoneDiv = $('<div class="drop-zone" />');
        const iconImg = $('<i class="material-icons" />');
        const dragStrong = $('<strong />');
        const oStrong = $('<strong>o</strong>');
        const clickSpan = $('<span>Da </span>');
        const uploadButton = $('<button class="button-upload-file">' + translate.sections.services.lessMb + '</button>');
        const previewDiv = $('<div class="preview" />');
        const previewImage = $('<img class="image" />');
        const previewVideo = $('<video class="video" controls />');
        const previewLoaderImage = $('<img class="loader" src="/assets/img/loading_dark.svg" />');
        const previewLink = $('<a target="_blank" />');
        const previewButton = $('<button />');
        const previewBtnIcon = $('<i class="material-icons">delete_forever</i>');

        const constraintsAllowed = {image: {name:'Imagen', key:'image'}, video: {name:'Video', key: 'video'}, document: {name:'Documento', key: 'document'}};
        const constraints = [constraintsAllowed[this.headerType]];
        const acceptedFiles = this.getAcceptedFiles(constraints);
        const icon = this.getIconFile(constraints);
        const chunkText = this.getTextFile(constraints);
        const text = `Arrastra y suelta ${chunkText} aquí`;

        iconImg.text(icon);
        dragStrong.text(text);
        previewImage.hide();
        previewVideo.hide();

        dropZoneDiv.on('drop', (event: any) => {
            this.dropHandler(event, previewImage, previewVideo, previewLink, previewLoaderImage, dropZoneDiv, previewDiv, acceptedFiles, constraints, chunkText);
        });

        dropZoneDiv.on('dragover', (event: any) => {
            this.dragOverHandler(event);
        });

        uploadButton.on('click', (event: any) => {
            this.openFileSelector(previewImage, previewVideo, previewLink, previewLoaderImage, dropZoneDiv, previewDiv, acceptedFiles, constraints);
        });

        previewButton.on('click', (event: any) => {
            this.removeFile(previewImage, previewVideo, previewLink, dropZoneDiv, previewDiv);
        });

        previewButton.append(previewBtnIcon);

        dropZoneDiv.append([iconImg, dragStrong, oStrong, clickSpan, uploadButton]);
        previewDiv.append([previewLoaderImage, previewImage, previewVideo, previewLink, previewButton]);
        multimediaDiv.append([dropZoneDiv, previewDiv]);

        const value = this.field.val();
        if (value) {
            const jsonValue = JSON.parse(value).replyButtons;
            if (jsonValue.header) {
                if (jsonValue.header.type.type === 'image' && jsonValue.header.image.link.trim()) {
                    dropZoneDiv.hide();
                    previewDiv.css('display', 'block');
                    previewLoaderImage.hide();
                    // const fileType = this.getTypeFileByExtension(jsonValue.header.image.link);
                    previewLink.text(jsonValue.header.image.link);
                    previewLink.attr('href', jsonValue.header.image.link);
                    previewImage.attr('src', jsonValue.header.image.link);
                    previewImage.show();
                    previewVideo.hide();
                    this.disableFixed(jsonValue.header.image, previewButton);
                } else if (jsonValue.header.type.type === 'video' && jsonValue.header.video.link.trim()) {
                    dropZoneDiv.hide();
                    previewDiv.css('display', 'block');
                    previewLoaderImage.hide();
                    // const fileType = this.getTypeFileByExtension(jsonValue.header.video.link);
                    previewLink.text(jsonValue.header.video.link);
                    previewLink.attr('href', jsonValue.header.video.link);
                    previewVideo.attr('src', jsonValue.header.video.link);
                    previewImage.hide();
                    previewVideo.show();
                    this.disableFixed(jsonValue.header.video, previewButton);
                } else if (jsonValue.header.type.type === 'document' && jsonValue.header.document.link.trim()) {
                    dropZoneDiv.hide();
                    previewDiv.css('display', 'block');
                    previewLoaderImage.hide();
                    // const fileType = this.getTypeFileByExtension(jsonValue.header.document.link);
                    previewImage.hide();
                    previewVideo.hide();
                    previewLink.attr('href', jsonValue.header.document.link);
                    previewLink.text(jsonValue.header.document.link);
                    this.disableFixed(jsonValue.header.document, previewButton);
                }
            }
        }
        return multimediaDiv;
    }

    // End multimedia

    getIndexById(list, id) {
        let idx = -1;
        const listArr = list.children();
        listArr.each(i => {
            const currentId = $(listArr[i]).data('id');
            if (id === currentId) {
                idx = i;
            }
        });
        return idx;
    }

    saveOnField() {
        const val = JSON.stringify({
            replyButtons: this.replyButtons.getJson(),
            type: 'replyButtons',
            showOutputs: this.showOutputs
        });
        const field = $('[name="' + this.fieldName + '"]');
        field.val(val);
        this.field.val(val);
    }

    addButton(buttonList, buttonIdx) {
        let button: Button = {
            type: 'reply',
            reply: {
                id: joint.util.uuid(),
                title: ''
            }
        };

        if (buttonIdx < this.replyButtons.action.buttons.length) {
            button.reply.id = this.replyButtons.action.buttons[buttonIdx].reply.id;
            if (this.replyButtons.action.buttons[buttonIdx]) {
                button = this.replyButtons.action.buttons[buttonIdx];
            }
        } else {
            this.replyButtons.action.buttons.push(button);
        }

        const bodyListItem = $(`<li data-id="${button.reply.id}" />`);
        const writeTextButtonSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, translate.sections.services.writeTextButton);
        const buttonTitleMAxLenSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, this.replyButtons.buttonTitleMAxLen);
        const buttonTitleInput = $(`<input class="title-button" type="text" placeholder="${writeTextButtonSanitized}"  maxlength="${buttonTitleMAxLenSanitized}" />`);
        const buttonSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, translate.sections.services.button);
        const deleteButton = $('<button class="delete-item">✕ <span>' + buttonSanitized + '</span></button>');

        deleteButton.on('click', (e) => {
            const dataId = $(e.delegateTarget).parent().data('id');
            const idx = this.getIndexById(buttonList, dataId);
            if (this.replyButtons.action.buttons.length > this.replyButtons.buttonsMinLen) {
                this.replyButtons.action.buttons.splice(idx, 1);
                bodyListItem.remove();
                this.saveOnField();
            } else {
                this.alertService.warning(`Los botones tienen un mínimo de ${this.replyButtons.buttonsMinLen} elemento(s)`);
            }
        });

        buttonTitleInput.on('focusout', (e) => {
            const dataId = $(e.delegateTarget).parent().data('id');
            const idx = this.getIndexById(buttonList, dataId);
            this.replyButtons.action.buttons[idx] = {
                type: 'reply',
                reply: {
                    id: this.replyButtons.action.buttons[idx].reply.id,
                    title: buttonTitleInput.val().toString()
                }
            }
            this.saveOnField();
        });

        this.disableFixed(button.reply, buttonTitleInput);
        this.disableFixed(button.reply, deleteButton);

        buttonTitleInput.val(button.reply.title);
        // deepcode ignore DOMXSS: <Estan sanitizados los valores con los que se genera>
        bodyListItem.append([buttonTitleInput, deleteButton]);
        // deepcode ignore DOMXSS: <Estan sanitizados los valores con los que se genera>
        buttonList.append(bodyListItem);
    }

    convertConstraintsToValue(constraints) {
        const constraintsAux = {
            replyButtons: {
                body: {
                    text: constraints.replyButtons.body.text,
                    constraintType: constraints.replyButtons.body.constraintType
                },
                action: {
                    buttons: []
                }
            }
        };
        if (constraints.replyButtons.hasOwnProperty('header')) {
            constraintsAux.replyButtons['header'] = {
                type: {
                    type: constraints.replyButtons.header.type.type,
                    constraintType: constraints.replyButtons.header.type.constraintType
                }
            }

            switch (constraints.replyButtons.header.type.type) {
                case 'text':
                    constraintsAux.replyButtons['header']['text'] = {
                        text: constraints.replyButtons.header.text.text,
                        constraintType: constraints.replyButtons.header.text.constraintType
                    };
                    break;
                case 'image':
                    constraintsAux.replyButtons['header']['image'] = {
                        link: constraints.replyButtons.header.image.link,
                        constraintType: constraints.replyButtons.header.image.constraintType
                    };
                    break;
                case 'video':
                    constraintsAux.replyButtons['header']['video'] = {
                        link: constraints.replyButtons.header.video.link,
                        constraintType: constraints.replyButtons.header.video.constraintType
                    };
                    break;
                case 'document':
                    constraintsAux.replyButtons['header']['document'] = {
                        link: constraints.replyButtons.header.document.link,
                        constraintType: constraints.replyButtons.header.document.constraintType
                    };
                    break;
            }
        }
        if (constraints.replyButtons.hasOwnProperty('footer')) {
            constraintsAux.replyButtons['footer'] = {
                text: constraints.replyButtons.footer.text,
                constraintType: constraints.replyButtons.footer.constraintType
            }
        }

        constraints.replyButtons.buttons.forEach((btn, idx) => {
            const button = {
                type: 'reply',
                reply: {
                    // id: joint.util.uuid(),
                    title: btn.title,
                    constraintType: btn.constraintType
                }
            };
            console.log(constraints.replyButtons.buttons[idx]);
            if (constraints.replyButtons.buttons[idx].hasOwnProperty('id')) {
                button.reply['id'] = constraints.replyButtons.buttons[idx].id;
            } else {
                button.reply['id'] = joint.util.uuid();
            }
            console.log(button);
            constraintsAux.replyButtons.action.buttons.push(button);
        });
        return constraintsAux;
    }

    /**
     * Deshabilita las entradas del elemento seleccionado
     * @param replyButtonsAttr attributo de listMessage
     * @param element un elemento input o button del dom
     */
    disableFixed(replyButtonsAttr, element) {
        if (replyButtonsAttr.hasOwnProperty('constraintType') && replyButtonsAttr.constraintType === 'fixed') {
            element.prop('disabled', true);
        }
    }

    fillValue(val, headerTextInput, headerTypeSelector, headerDiv, bodyInput, footerInput, buttonList) {
        const lm: InteractiveMessages = val.replyButtons as InteractiveMessages;
        this.replyButtons.action = lm.action;
        this.replyButtons.header = lm.header;
        this.replyButtons.body = lm.body;
        this.replyButtons.footer = lm.footer;

        this.saveOnField();

        if (this.replyButtons.header) {
            if (this.replyButtons.header.text && this.replyButtons.header.text.text) {
                let headerTextSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, this.replyButtons.header.text.text);
                headerTextSanitized = headerTextSanitized.replaceAll('&#191;', '¿');
                headerTextSanitized = headerTextSanitized.replaceAll('&#161;', '¡');
                headerTextSanitized = headerTextSanitized.replaceAll('&amp;', '&');
                headerTextInput.val(headerTextSanitized);
                this.disableFixed(this.replyButtons.header.text, headerTextInput);
            }
            headerTypeSelector.val(this.replyButtons.header.type.type);
            this.headerType = this.replyButtons.header.type.type;

            if (this.replyButtons.header.type.type === 'text') {
                // deepcode ignore DOMXSS: <Estan sanitizados los valores con los que se genera>
                headerDiv.prepend(headerTextInput);
            } else {
                headerDiv.prepend(this.getMultimedia());
            }
        } else {
            headerDiv.prepend(headerTextInput);
        }

        bodyInput.val(this.replyButtons.body.text);
        this.disableFixed(this.replyButtons.body, bodyInput);

        if (this.replyButtons.footer && this.replyButtons.footer.text) {
            footerInput.val(this.replyButtons.footer.text);
            this.disableFixed(this.replyButtons.footer, footerInput);
        }

        if (this.replyButtons.action.buttons.length > 0) {
            this.replyButtons.action.buttons.forEach((section, index) => {
                this.addButton(buttonList, index);
            });
        } else {
            const idx = this.replyButtons.action.buttons.length;
            this.addButton(buttonList, idx);
        }
    }

    getReplyButtons(field, constraints) {
        this.fieldName = field.attr('name');
        // FIXME: inicia prueba
        // constraints = {
        //     "replyButtons": {
        //         "header": {
        //             "type": {
        //                 "type": "image",
        //                 "constraintType": "fixed"
        //             },
        //             "image": {
        //                 "link": "https://nywolf.org/wp-content/uploads/2023/01/valentia_run_edit_logo_sm.jpg",
        //                 "constraintType": "suggested"
        //             }
        //         },
        //         "body": {
        //             "text": "cuerpo fijo",
        //             "constraintType": "suggested"
        //         },
        //         "footer": {
        //             "text": "pie fijo",
        //             "constraintType": "fixed"
        //         },
        //         "buttons": [
        //             {
        //                 "id": 1,
        //                 "title": "boton fijo",
        //                 "constraintType": "fixed"
        //             }
        //         ]
        //     }
        // };
        // fin de prueba
        const containerDiv = $('<div class="type_visual_box reply-buttons" />');
        const cardDiv = $('<div class="wa-card" />');
        const headerTypeSelector = $('<select class="media-type"><option value="text">Texto</option><option value="image">Imagen</option><option value="video">Video</option><option value="document">Documento</option></select>');
        const headerDiv = $('<div class="wa-card-header" />');
        const writeTitleMessageSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, translate.sections.services.writeTitleMessage);
        const headerTextMaxLenSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, this.replyButtons.headerTextMaxLen);
        const headerTextInput = $(`<input type="text" class="wa-card-header-text" placeholder="${writeTitleMessageSanitized}" maxlength="${headerTextMaxLenSanitized}" />`);
        const writeBodyMessageSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, translate.sections.services.writeBodyMessage);
        const bodyTextMaxLenSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, this.replyButtons.bodyTextMaxLen);
        const bodyInput = $(`<input type="text" class="wa-card-body" placeholder="${writeBodyMessageSanitized}" maxlength="${bodyTextMaxLenSanitized}" />`);
        const writeFooterMessageSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, translate.sections.services.writeBodyMessage);
        const footerTextMaxLenSanitized = this.domSanitizer.sanitize(SecurityContext.HTML, this.replyButtons.bodyTextMaxLen);
        const footerInput = $(`<input type="text" class="wa-card-footer" placeholder="${writeFooterMessageSanitized}" maxlength="${footerTextMaxLenSanitized}" />`);
        const hourSpan = $('<span class="message-hour">12:00</span>');
        const buttonList = $('<ul class="wa-card-buttons" />');
        const addButton = $('<button class="add-button"><i class="material-icons" title="Agregar un botón">add</i> <span>botón</span></button>');

        this.replyButtons.action = {
            buttons: []
        };

        headerTypeSelector.on('change', (e) => {
            const headerType = headerTypeSelector.val().toString();
            this.headerType = headerType;
            this.replyButtons.header = null;
            delete this.replyButtons.header;

            headerDiv.empty();

            if (headerType === 'text') {
                this.replyButtons.header = {
                    type: {
                        type: headerType
                    },
                    text: {
                        text: ''
                    }
                }
            }

            this.saveOnField();

            if (headerType === 'text') {
                headerDiv.prepend(headerTextInput);
            } else {
                headerDiv.prepend(this.getMultimedia());
            }
        });

        headerTextInput.on('focusout', (e) => {
            const text = headerTextInput.val().toString();
            if (text) {
                this.replyButtons.header = {
                    type: {
                        type: 'text'
                    },
                    text: {
                        text
                    }
                };
            } else {
                this.replyButtons.header = null;
                delete this.replyButtons.header;
            }
            this.saveOnField();
        });

        bodyInput.on('focusout', (e) => {
            const text = bodyInput.val().toString();
            this.replyButtons.body = { text };
            this.saveOnField();
        });

        footerInput.on('focusout', (e) => {
            const text = footerInput.val().toString();
            if (text) {
                this.replyButtons.footer = { text: footerInput.val().toString() };
            } else {
                this.replyButtons.footer = null;
                delete this.replyButtons.footer;
            }
            this.saveOnField();
        });

        addButton.on('click', (e) => {
            if (this.replyButtons.action.buttons.length < this.replyButtons.buttonsMaxLen) {
                const buttonIdx = this.replyButtons.action.buttons.length;
                this.addButton(buttonList, buttonIdx);
            } else {
                this.alertService.warning(`Los botones tienen un máximo de ${this.replyButtons.buttonsMaxLen} elementos`);
            }
        });

        this.field = field;

        const value = field.val();
        if (value) {
            const valJson = JSON.parse(value);
            const val = this.convertOldToNew(valJson);
            this.fillValue(val, headerTextInput, headerTypeSelector, headerDiv, bodyInput, footerInput, buttonList);
        } else if (constraints && constraints.hasOwnProperty('replyButtons')) {
            if ('type' in constraints && 'noOutputs' === constraints.type) {
                this.showOutputs = false;
            }
            const val = this.convertConstraintsToValue(constraints);
            this.fillValue(val, headerTextInput, headerTypeSelector, headerDiv, bodyInput, footerInput, buttonList);
            this.disableFixed(constraints.replyButtons.header.type, headerTypeSelector);
        } else {
            // console.log(this.replyButtons);
            const idx = this.replyButtons.action.buttons.length;
            this.addButton(buttonList, idx);
            headerDiv.append(headerTextInput);
        }

        cardDiv.append([headerDiv, bodyInput, footerInput, hourSpan]);
        const nameClass = field.attr('name').replace('#', '-');
        containerDiv.addClass(nameClass);
        containerDiv.append([headerTypeSelector, cardDiv, buttonList, addButton, field]);

        return containerDiv;
    }

    convertOldToNew(val) {
        if (val.replyButtons.header && typeof val.replyButtons.header.type === 'string') {
            val.replyButtons.header.type = {
                type: val.replyButtons.header.type
            };
        }
        if (val.replyButtons.header && typeof val.replyButtons.header.text === 'string') {
            val.replyButtons.header.text = {
                text: val.replyButtons.header.text
            };
        }
        const lm: InteractiveMessages = val.replyButtons as InteractiveMessages;
        this.replyButtons.action = lm.action;
        this.replyButtons.header = lm.header;
        this.replyButtons.body = lm.body;
        this.replyButtons.footer = lm.footer;

        this.saveOnField();
        return val;
    }
}
