<div class="chat clearfix" *ngIf="nameChannel==='WEBSITE' || nameChannel==='ZENDESK_CHAT' ">

    <div class="config">
        <div class="form-chat" *ngIf="currentChat !=null">
            <h2>{{translate.sections.chat.chatSetting}} ({{nameChannel}})</h2>
            <small>* {{translate.sections.chat.requiredFields}}</small>

            <h3>{{translate.sections.chat.style}}</h3>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.botName}}</label>
                <input type="text" [(ngModel)]="currentChat.botTitle" (blur)="saveBotTitle()" (keyup.enter)="saveBotTitle()"
                    placeholder={{translate.sections.chat.botName}} class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.botImage}} {{translate.sections.chat.square}}*</label>
                <input type="text" [(ngModel)]="currentChat.botImage" (blur)="saveBotImage()" placeholder={{translate.sections.chat.botImageRequired}}
                    class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.headerColor}}</label>
                <input type="text" [(colorPicker)]="currentChat.headerColor" [style.background]="currentChat.headerColor"
                    [value]="currentChat.headerColor" [cpFallbackColor]="'#160a8b'" (cpToggleChange)="saveHeaderColor()"
                    (keyup.enter)="saveHeaderColor()" placeholder={{translate.sections.chat.headerColor}} class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.titleTextColor}}</label>
                <input type="text" [(colorPicker)]="currentChat.titleColor" [style.background]="currentChat.titleColor"
                    [value]="currentChat.titleColor" (cpToggleChange)="saveTitleColor()"
                    (keyup.enter)="saveTitleColor()" placeholder={{translate.sections.chat.titleTextColor}}
                    class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.messageTextColor}}</label>
                <input type="text" [(colorPicker)]="currentChat.msgBotTxtColor" [style.background]="currentChat.msgBotTxtColor"
                    [value]="currentChat.msgBotTxtColor" [cpFallbackColor]="'#606273'"
                    (cpToggleChange)="saveMsgBotTxtColor()" (keyup.enter)="saveMsgBotTxtColor()"
                    placeholder={{translate.sections.chat.messageTextColor}} class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.textFieldColor}}</label>
                <input type="text" [(colorPicker)]="currentChat.msgBotBoxColor" [style.background]="currentChat.msgBotBoxColor"
                    [value]="currentChat.msgBotBoxColor" [cpFallbackColor]="'#eff3f6'"
                    (cpToggleChange)="saveMsgBotBoxColor()" (keyup.enter)="saveMsgBotBoxColor()"
                    placeholder={{translate.sections.chat.textFieldColor}} class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.userTextColor}}</label>
                <input type="text" [(colorPicker)]="currentChat.msgUsrTxtColor" [style.background]="currentChat.msgUsrTxtColor"
                    [value]="currentChat.msgUsrTxtColor" (cpToggleChange)="saveMsgUsrTxtColor()"
                    (keyup.enter)="saveMsgUsrTxtColor()" placeholder={{translate.sections.chat.userTextColor}}
                    class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.userTextFieldColor}}</label>
                <input type="text" [(colorPicker)]="currentChat.msgUsrBoxColor" [style.background]="currentChat.msgUsrBoxColor"
                    [value]="currentChat.msgUsrBoxColor" [cpFallbackColor]="'#3f2a4b'"
                    (cpToggleChange)="saveMsgUsrBoxColor()" (keyup.enter)="saveMsgUsrBoxColor()"
                    placeholder={{translate.sections.chat.userTextFieldColor}} class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.closeImage}} {{translate.sections.chat.square}}</label>
                <input [(ngModel)]="currentChat.closeImage" (blur)="saveCloseImage()" (keyup.enter)="saveCloseImage()"
                    placeholder={{translate.sections.chat.closeImage}} class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.launchImage}} {{translate.sections.chat.square}}</label>
                <input type="text" [(ngModel)]="currentChat.buttonImage" (blur)="saveButtonImage()"
                    (keyup.enter)="saveButtonImage()" placeholder={{translate.sections.chat.launchImage}}
                    class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.point}}</label>
                <input type="text" [(colorPicker)]="currentChat.dotColor" [style.background]="currentChat.dotColor"
                    [value]="currentChat.dotColor" [cpFallbackColor]="'#34D9C3'" (cpToggleChange)="saveDotColor()"
                    (keyup.enter)="saveDotColor()" placeholder={{translate.sections.chat.pointColor}} class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.bgColor}}</label>
                <input type="text" [(colorPicker)]="currentChat.buttonColorBg" [style.background]="currentChat.buttonColorBg"
                    [value]="currentChat.buttonColorBg" [cpFallbackColor]="'#ffffff'" (cpToggleChange)="saveDotColor()"
                    (keyup.enter)="saveButtonColorBg()" placeholder={{translate.sections.chat.bgColor}}
                    class="inputInspectorStyle">
            </div>



            <!-- <button (click)="deletechat()">delete chat</button> -->
            <hr>

            <h3>{{translate.sections.chat.behavior}}</h3>

            <h4 *ngIf="nameChannel == 'ZENDESK_CHAT'">{{translate.sections.chat.notConfigured}}</h4>
            <p>{{translate.sections.chat.botMessage}}</p>

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.showHistory}}</label>

                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.inCookies' #allow (click)="saveInCookies()" type="checkbox"
                            name="onoffswitch" class="onoffswitch-checkbox switchI" id="1" tabindex="0">
                        <label class="onoffswitch-label" for="1"></label>
                    </div>
                    <!-- end -->
                    <!-- <button [ngClass]="{'check': true, 'active': currentChat.inCookies}" (click)="saveInCookies()"><span class="circle"></span></button> -->
                </div>
            </div>

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.sendMessage}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.showWelcomeBotMsg' #allow (click)="saveShowWelcomeBotMsg()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="2" tabindex="0">
                        <label class="onoffswitch-label" for="2"></label>
                    </div>
                    <!-- end -->
                    <!-- <button [ngClass]="{'check': true, 'active': currentChat.showWelcomeBotMsg}" (click)="saveShowWelcomeBotMsg()"><span class="circle"></span></button> -->
                </div>
            </div>

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.alwaysSendMessage}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.showWelcomeBotMsgAlways' #allow
                            (click)="saveShowWelcomeBotMsgAlways()" type="checkbox" name="onoffswitch"
                            class="onoffswitch-checkbox switchI" id="3" tabindex="0">
                        <label class="onoffswitch-label" for="3"></label>
                    </div>
                    <!-- end -->
                    <!-- <button [ngClass]="{'check': true, 'active': currentChat.showWelcomeBotMsgAlways}" (click)="saveShowWelcomeBotMsgAlways()"><span class="circle"></span></button> -->
                </div>
            </div>

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.sendChatLeavePage}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.openChatOnLeavingPage' #allow (click)="saveLeavePage()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="4" tabindex="0">
                        <label class="onoffswitch-label" for="4"></label>
                    </div>
                    <!-- end -->
                    <!-- <button [ngClass]="{'check': true, 'active': currentChat.openChatOnLeavingPage}" (click)="saveLeavePage()"><span class="circle"></span></button> -->
                </div>
            </div>

            <!-- <div class="form-group clearfix">
                <label>Lanzar chat cuando los operadores no asignados superen el <input
                        [(ngModel)]="currentChat.ratioAssignedPercent" (blur)="saveRatio()" (keyup.enter)="saveRatio()"
                        placeholder="Porcentaje de asignados" class="percent inputInspectorStyle"> % de los asignados
                </label>

            </div> -->

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.sendChatTo}} <input [(ngModel)]="currentChat.scrollPercentToOpen"
                        (blur)="saveScroll()" (keyup.enter)="saveScroll()"
                        placeholder={{translate.sections.chat.scrollMessagePage}} class="percent inputInspectorStyle"> {{translate.sections.chat.scrollPage}}
                    </label>

            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.sendChatTo2}}<input [(ngModel)]="currentChat.secondsToOpen" (blur)="saveSeconds()"
                        (keyup.enter)="saveSeconds()" placeholder={{translate.sections.chat.secondsMessage}}
                        class="seconds inputInspectorStyle"> {{translate.sections.chat.seconds}}</label>
            </div>

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.openChat}}:</label>
                </div>
            </div>
            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.desktop}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.openChatOnLoadDesktop' #allow (click)="saveOnLoadD()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="5" tabindex="0">
                        <label class="onoffswitch-label" for="5"></label>
                    </div>
                    <!-- end -->
                    <!-- <button [ngClass]="{'check': true, 'active': currentChat.openChatOnLoadDesktop}" (click)="saveOnLoadD()"><span class="circle"></span></button> -->
                </div>
            </div>
            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.mobile}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.openChatOnLoadMobile' #allow (click)="saveOnLoadM()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="6" tabindex="0">
                        <label class="onoffswitch-label" for="6"></label>
                    </div>
                    <!-- end -->
                    <!-- <button [ngClass]="{'check': true, 'active': currentChat.openChatOnLoadMobile}" (click)="saveOnLoadM()"><span class="circle"></span></button> -->
                </div>
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.openWebView}}</label>
                <input type="text" [(ngModel)]="currentChat.showWebviewOnClick" (blur)="saveWebviewUrl()"
                    (keyup.enter)="saveWebviewUrl()" placeholder={{translate.sections.chat.webview}} class="form-control inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.showWelcomeMessage}}:</label>
                </div>
            </div>
            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.desktop}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.showWelcomeGreeting' #allow (click)="saveWelcome()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="7" tabindex="0">
                        <label class="onoffswitch-label" for="7"></label>
                    </div>
                    <!-- end -->

                    <!-- <button [ngClass]="{'check': true, 'active': currentChat.showWelcomeGreeting}" (click)="saveWelcome()"><span class="circle"></span></button> -->
                </div>
            </div>
            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.mobile2}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.showWelcomeGreetingMobile' #allow (click)="saveWelcomeMobile()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="8" tabindex="0">
                        <label class="onoffswitch-label" for="8"></label>
                    </div>
                    <!-- end -->
                    <!-- <button [ngClass]="{'check': true, 'active': currentChat.showWelcomeGreetingMobile}" (click)="saveWelcomeMobile()"><span class="circle"></span></button> -->
                </div>
            </div>



            <p>{{translate.sections.chat.welcomeMessage}}:</p>
            <ckeditor (blur)="saveWelcomeMessage()" [(ngModel)]="currentChat.welcomeGreeting" [editor]="Editor">
            </ckeditor>
            <!-- <ckeditor [(ngModel)]="currentChat.welcomeGreeting" [config]="{skin: 'minimalist,/js/minimalist/', smiley_columns: 6, language: 'es', toolbar: [ [ 'Smiley', '-', 'Bold', 'Italic',  'Image', '-', 'Source', '-', 'Maximize' ] ], extraPlugins: 'smiley'}" (blur)="saveWelcomeMessage()" debounce="500">
            </ckeditor> -->

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.showWelcomeMessageByClicking}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.showWelcomeBotMsgAlways' #allow (click)="saveWelcomeAlways()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="9" tabindex="0">
                        <label class="onoffswitch-label" for="9"></label>
                    </div>
                    <!-- end -->
                    <!-- <button [ngClass]="{'check': true, 'active': currentChat.showWelcomeBotMsgAlways}" (click)="saveWelcomeAlways()"><span class="circle"></span></button> -->
                </div>
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.boxSize}}</label>
                <input type="text" [(ngModel)]="currentChat.size" (blur)="saveBoxSize()" (keyup.enter)="saveBoxSize()"
                    placeholder={{translate.sections.chat.boxSize}} class="inputInspectorStyle">
            </div>

            <div class="form-group clearfix">
                <label>{{translate.sections.chat.zLevel}}</label>
                <input type="text" [(ngModel)]="currentChat.zindex" (blur)="saveZIndex()" (keyup.enter)="saveZIndex()"
                    placeholder="1" class="inputInspectorStyle">
            </div>


            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.showCounter}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.showCounterMessages' (click)="showCounterMessages()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="10"
                            tabindex="0">
                        <label class="onoffswitch-label" for="10"></label>
                    </div>
                    <!-- end -->

                </div>
            </div>

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.showFloatingMessage}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.showFloatingMessages' (click)="showFloatingMessages()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="11"
                            tabindex="0">
                        <label class="onoffswitch-label" for="11"></label>
                    </div>
                    <!-- end -->

                </div>
            </div>

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.showLastFloatingMessage}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.showFloatingLastMessage' (click)="showFloatingLastMessage()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="12"
                            tabindex="0">
                        <label class="onoffswitch-label" for="12"></label>
                    </div>
                    <!-- end -->

                </div>
            </div>

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.enableTracking}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.allowTracking' (click)="showAllowTracking()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="13"
                            tabindex="0">
                        <label class="onoffswitch-label" for="12"></label>
                    </div>
                    <!-- end -->

                </div>
            </div>

            <div class="form-group clearfix">
                <div class="status">
                    <label>{{translate.sections.chat.enableLocationTracking}}</label>
                    <!-- test -->
                    <div class="onoffswitch">
                        <input [checked]='currentChat.allowTrackingLocation' (click)="showAllowTrackingLocation()"
                            type="checkbox" name="onoffswitch" class="onoffswitch-checkbox switchI" id="14"
                            tabindex="0">
                        <label class="onoffswitch-label" for="12"></label>
                    </div>
                    <!-- end -->

                </div>
            </div>




            <div>
                <p>{{translate.sections.chat.persistentMenu}}</p>
                <div (blur)="saveMainP()" #loadDate id="jsoneditorMain"></div>
            </div>

            <div class="form-group">
                <button type="button" class="btn-primary btn-accept" (click)="saveMainP()">
                    <span>{{translate.sections.chat.savePersistentMenu}}</span>
                </button>
            </div>




        </div>
    </div>


    <div class="view" *ngIf="currentChat !=null">
        <a *ngIf="nameChannel == 'WEBSITE'"
            [href]="chat_url + 'test.html?channel_owner_id=' + channelOwnerId + '&guschat=open_box'"
            target="_blank" class="test_button btn-primary" title={{translate.sections.chat.testChat}}>
            <span>{{translate.sections.chat.testChat}}</span>
            <i class="material-icons">chat</i>
        </a>
        <a *ngIf="nameChannel == 'ZENDESK_CHAT'"
            [href]="zchat_url + 'index.html?channel_owner_id=' + channelOwnerId + env_param"
            target="_blank" class="test_button btn-primary" title={{translate.sections.chat.testChat}}>
            <span>{{translate.sections.chat.testChat}}</span>
            <i class="material-icons">chat</i>
        </a>
        <div id="chatgus-container-view">
            <div class="chatgus-app chatgus-app-launcher-enabled" style="display: block;">
                <div class="chatgus-messenger-frame">
                    <div class="chatgus-messenger">
                        <div class="chatgus-conversation">
                            <div class="chatgus-header-buttons">
                                <div *ngIf="currentChat.closeImage!=null" class="chatgus-header-buttons-close"
                                    [style.background-image]="'url(' + currentChat.closeImage + ')'">
                                    <div class="chatgus-header-buttons-close-contents"></div>
                                </div>
                                <div *ngIf="currentChat.closeImage==null||currentChat.closeImage==''"
                                    class="chatgus-header-buttons-close">
                                    <div class="chatgus-header-buttons-close-contents"></div>
                                </div>
                            </div>
                            <div class="chatgus-conversation-body-container">
                                <div class="chatgus-conversation-backgrounds">
                                    <div class="chatgus-conversation-background chatgus-conversation-background-1">
                                    </div>
                                </div>
                                <div class="chatgus-conversation-body">
                                    <div class="chatgus-conversation-body-profile"
                                        [style.background-color]="currentChat.headerColor"
                                        [style.background-image]="'none'">
                                        <div class="chatgus-conversation-profile">
                                            <div class="chatgus-bot-profile chatgus-bot-profile-collapsed">
                                                <div class="chatgus-bot-profile-compact">
                                                    <div class="chatgus-bot-profile-compact-contents">
                                                        <div class="chatgus-bot-profile-compact-avatar">
                                                            <div class="chatgus-avatar">
                                                                <img *ngIf="currentChat.botImage!=null"
                                                                    src="{{currentChat.botImage}}" alt="bot">
                                                                <img *ngIf="currentChat.botImage==null"
                                                                    src="https://gus.chat/blog/logo_gus_chat.jpeg"
                                                                    alt="bot">
                                                            </div>
                                                        </div>
                                                        <div class="chatgus-bot-profile-compact-body">
                                                            <div *ngIf="currentChat.botTitle!=null"
                                                                class="chatgus-bot-profile-compact-bot-name"
                                                                [style.color]="currentChat.titleColor">
                                                                {{currentChat.botTitle}}</div>
                                                            <div *ngIf="currentChat.botTitle==null"
                                                                class="chatgus-bot-profile-compact-bot-name"
                                                                [style.color]="currentChat.titleColor">{{translate.sections.chat.bot}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chatgus-conversation-body-parts">
                                        <div class="chatgus-conversation-parts chatgus-conversation-parts-scrolled">
                                            <div class="chatgus-conversation-part chatgus-conversation-part-user">
                                                <div class="chatgus-comment-container chatgus-comment-container-user">
                                                    <div class="chatgus-comment"
                                                        [style.background-color]="currentChat.msgUsrBoxColor">
                                                        <div class="chatgus-blocks">
                                                            <div class="chatgus-block chatgus-block-paragraph"
                                                                [style.color]="currentChat.msgUsrTxtColor">{{translate.sections.chat.hi}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="chatgus-conversation-part chatgus-conversation-part-bot chatgus-conversation-part">
                                                <div class="chatgus-comment-container chatgus-comment-container-bot">
                                                    <div class="chatgus-comment-container-bot-avatar">
                                                        <div class="chatgus-avatar">
                                                            <img *ngIf="currentChat.botImage!=null"
                                                                src="{{currentChat.botImage}}" alt="bot">
                                                            <img *ngIf="currentChat.botImage==null"
                                                                src="https://gus.chat/blog/logo_gus_chat.jpeg"
                                                                alt="bot">
                                                        </div>
                                                    </div>
                                                    <div class="chatgus-comment"
                                                        [style.background-color]="currentChat.msgBotBoxColor">
                                                        <div class="chatgus-blocks">
                                                            <div class="chatgus-block chatgus-block-paragraph"
                                                                [style.color]="currentChat.msgBotTxtColor">{{translate.sections.chat.hiBot}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chatgus-conversation-actions">
                                <div class="chatgus-composer">
                                    <textarea placeholder={{translate.sections.chat.typeHere}}></textarea>
                                    <button class="chatgus-composer-emoji-button"></button>
                                    <button class="chatgus-composer-send-button"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="chatgus-launcher"
                [ngStyle]="{'box-shadow': style, 'background-color': currentChat.headerColor, 'background-image': 'none'}">
                <div *ngIf="currentChat.buttonImage!=null&&currentChat.buttonImage!=''"
                    class="chatgus-launcher-open-icon uno" [style.background-color]="currentChat.buttonColorBg"
                    [style.background-image]="'url(' + currentChat.buttonImage + ')'"></div>
                <div *ngIf="currentChat.buttonImage==null||currentChat.buttonImage==''"
                    [style.background-color]="currentChat.buttonColorBg" class="chatgus-launcher-open-icon dos"></div>
                <div class="chatgus-launcher-close-box">
                    <div class="chatgus-launcher-close-icon">
                        <svg viewBox="0 0 47.971 47.971" width="40px" height="40px">
                            <path
                                d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
                                [attr.fill]="currentChat.headerColor" />
                        </svg>
                    </div>
                </div>
                <span [style.color]="currentChat.titleColor">{{translate.sections.chat.onLine}}</span>
                <div class="chatgus-launcher-dot" [style.background-color]="currentChat.dotColor"></div>
            </div>
        </div>

    </div>


    <!-- seccion integracion -->
    <div *ngIf="nameChannel === 'WEBSITE'" class="full">
        <div class="config">
            <div class="form-chat">
                <hr>
                <h3>{{translate.sections.chat.integration}}</h3>
                <p>
                    {{translate.sections.chat.integrationMessage}}:
                </p>
                <pre class="simple-code">&#x3C;script type=&#x22;text/javascript&#x22;&#x3E;
var chatGusMessages = &#123;
    leaveMessage: "#leaveMessage",
    secondsMessage: "#secondsMessage",
    scrollMessage: "#scrollMessage",
    ratioMessage: "#ratioMessage",
    welcomeBotMessage: "#welcomeBotMessage"
&#125;;

!function()&#123;var e=document.createElement(&#x22;script&#x22;);
e.src=&#x22;{{chat_url}}js/all_chatgus.js?channel={{channelOwnerId}}{{env_param}}&#x22;,
e.id=&#x22;chatgusScript&#x22;,
document.getElementsByTagName(&#x22;head&#x22;)[0].appendChild(e)&#125;();
&#x3C;/script&#x3E;</pre>
                <button class="btn-primary copy" (click)="copyBySelector('.simple-code')">
                    <span>{{translate.sections.chat.copy}}</span>
                    <i class="material-icons md-20">content_copy</i>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="nameChannel === 'WEBSITE'" class="full">
        <div class="config">
            <div class="form-chat">
                <hr>
                <h3>{{translate.sections.chat.integrationWithoutCROSS}}</h3>
                <p>
                    {{translate.sections.chat.integrationMessage}}:
                </p>
                <pre class="vars-code">&#x3C;script type=&#x22;text/javascript&#x22;&#x3E;
var chatGusProperties = &#123;
    botImage: "{{currentChat?.botImage}}",
    botTitle: "{{currentChat?.botTitle}}",
    buttonImage: "{{currentChat?.buttonImage}}",
    closeImage: "{{currentChat?.closeImage}}",
    headerColor: "{{currentChat?.headerColor}}",
    msgBotBoxColor: "{{currentChat?.msgBotBoxColor}}",
    msgBotTxtColor: "{{currentChat?.msgBotTxtColor}}",
    msgUsrBoxColor: "{{currentChat?.msgUsrBoxColor}}",
    msgUsrTxtColor: "{{currentChat?.msgUsrTxtColor}}",
    titleColor: "{{currentChat?.titleColor}}",
    inCookies: {{currentChat?.inCookies}},

    openChatOnLeavingPage: {{currentChat?.openChatOnLeavingPage}},
    secondsToOpen: {{currentChat?.secondsToOpen}},
    scrollPercentToOpen: {{currentChat?.scrollPercentToOpen}},
    ratioAssignedPercent: {{currentChat?.ratioAssignedPercent}},
    showWelcomeGreeting: {{currentChat?.showWelcomeGreeting}},
    showWelcomeGreetingMobile: {{currentChat?.showWelcomeGreetingMobile}},
    welcomeGreeting: "{{welcomeGreeting}}",
    dotColor: "{{currentChat?.dotColor}}",
    buttonColorBg: "{{currentChat?.buttonColorBg}}",
    showWelcomeBotMsg: {{currentChat?.showWelcomeBotMsg}},
    openChatOnLoadDesktop: {{currentChat?.openChatOnLoadDesktop}},
    openChatOnLoadMobile: {{currentChat?.openChatOnLoadMobile}},
    showWelcomeBotMsgAlways: {{currentChat?.showWelcomeBotMsgAlways}},
    showWebviewOnClick: "{{currentChat?.showWebviewOnClick}}",
    allowTracking: {{currentChat?.allowTracking}},
    allowTrackingLocation: {{currentChat?.allowTrackingLocation}}

&#125;;

var chatGusMessages = &#123;
    leaveMessage: "#leaveMessage",
    secondsMessage: "#secondsMessage",
    scrollMessage: "#scrollMessage",
    ratioMessage: "#ratioMessage",
    welcomeBotMessage: "#welcomeBotMessage"
&#125;;

!function()&#123;var e=document.createElement(&#x22;script&#x22;);
e.src=&#x22;{{chat_url}}js/all_chatgus.js?channel={{channelOwnerId}}{{env_param}}&#x22;,
e.id=&#x22;chatgusScript&#x22;,
document.getElementsByTagName(&#x22;head&#x22;)[0].appendChild(e)&#125;();
&#x3C;/script&#x3E;</pre>
                <button class="btn-primary copy" (click)="copyBySelector('.vars-code')">
                    <span>{{translate.sections.chat.copy}}</span>
                    <i class="material-icons md-20">content_copy</i>
                </button>
                <p>
                    {{translate.sections.chat.copyMessage}}
                </p>
            </div>
        </div>
    </div>


    <div *ngIf="nameChannel== 'ZENDESK_CHAT'" class="full">
        <div class="config">
            <div class="form-chat">
                <hr>
                <h3>{{translate.sections.chat.integration}}</h3>
                <p>
                    {{translate.sections.chat.integrationMessage}}:
                </p>
                <pre>&#x3C;script type=&#x22;text/javascript&#x22;&#x3E;
var ref=window.document.getElementsByTagName(&#x22;script&#x22;)[0],script=window.document.createElement(&#x22;script&#x22;);script.id=&#x22;chatgusScript&#x22;,script.src=&#x22;{{chat_url}}js/all_chatgus.js?channel={{channelOwnerId}}{{env_param}}&#x22;,ref.parentNode.insertBefore(script,ref);
&#x3C;/script&#x3E;</pre>
            </div>
        </div>
    </div>
</div>


<!-- code initial -->

<!-- end -->

<section class="sectionUsers" *ngIf="nameChannel==='WHATSAPP' || nameChannelSub === 'WHATSAPP'">

    <div class="wrapper-title sub">
        <h1 class="title">
            {{translate.sections.chat.setting}}
        </h1>
    </div>

    <div class="container-fluid">
        <h2>{{translate.sections.chat.joinToPage}}</h2>
        <p>
            {{translate.sections.chat.joinMessage}}:
        </p>
        <div class="containerCode">
            <div  #userinput>&#x3C;script type=&#x22;text/javascript&#x22;&#x3E;var guschatWaPhone=&#x22;{{accountHandler}}&#x22;,guschatWaMessage=&#x22;Hola&#x22;;!function()&#123;var a=document.createElement(&#x22;script&#x22;);a.src=&#x22;{{chat_url}}/whatsapp/button.min.js?phone=&#x22;+guschatWaPhone+&#x22;&#x26;message=&#x22;+guschatWaMessage,a.id=&#x22;chatgusWhatsapp&#x22;,document.getElementsByTagName(&#x22;body&#x22;)[0].appendChild(a)&#125;();&#x3C;/script&#x3E;</div>
        </div>
        <div class="actions">
            <button (click)="copyBySelector('.containerCode')" class="btn-primary copy">
                <span>{{translate.sections.chat.copy}}</span>
                <i class="material-icons md-20">content_copy</i>
            </button>
        </div>
    </div>


    <div class="container-fluid config clearfix" *ngIf="nameChannel==='WHATSAPP' && nameChannelSub === 'WHATSAPP'">
        <div class="account">
            <div class="profile-picture">
                <h2>{{translate.sections.chat.accountPhoto}}</h2>
                <div class="form-group">
                    <label>{{translate.sections.chat.selectImage}}</label>
                    <input [(ngModel)]="whatsapp.url" type="file" accept="image/png, image/jpeg" (change)="handleFiles($event)" class="inputInspectorStyle file">
                </div>
                <div class="form-group whatsapp-picture" *ngIf="whatsapp.picture">
                    <img [src]="whatsapp.picture" alt="avatar">
                </div>
                <div class="form-group whatsapp-picture" *ngIf="!whatsapp.picture">
                    <img [src]="whatsapp.url" alt="avatar">
                </div>
                <div class="form-group">
                    <button type="button" class="btn-primary btn-accept" (click)="updateProfilePicture()">
                        <span>{{translate.sections.chat.updatePhoto}}</span>
                    </button>
                </div>
            </div>
            <div class="about">
                <h2>{{translate.sections.chat.accountDescription}}</h2>
                <div class="form-group">
                    <label>{{translate.sections.chat.description}}</label>
                    <input [(ngModel)]="whatsapp.text" type="text"  class="inputInspectorStyle" maxlength="139">
                </div>
                <div class="form-group">
                    <button type="button" class="btn-primary btn-accept" (click)="updateAbout()">
                        <span>{{translate.sections.chat.updateDescription}}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="info">
            <h2>{{translate.sections.chat.companyInfo}}</h2>
            <div class="form-group">
                <label>{{translate.sections.chat.description}}</label>
                <input [(ngModel)]="whatsapp.description" type="text" class="inputInspectorStyle" maxlength="256">
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.address}}</label>
                <input [(ngModel)]="whatsapp.address" type="text" class="inputInspectorStyle" maxlength="256">
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.email}}</label>
                <input [(ngModel)]="whatsapp.email" type="text" class="inputInspectorStyle" maxlength="128">
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.industry}}</label>
                <select [(ngModel)]="whatsapp.vertical" type="text" class="inputInspectorStyle">
                    <option value="">{{translate.sections.chat.none}}</option>
                    <option value="Automotive">{{translate.sections.chat.automotive}}</option>
                    <option value="Beauty, Spa and Salon">{{translate.sections.chat.beauty}}</option>
                    <option value="Clothing and Apparel">{{translate.sections.chat.clothing}}</option>
                    <option value="Education">{{translate.sections.chat.education}}</option>
                    <option value="Entertainment">{{translate.sections.chat.entertainment}}</option>
                    <option value="Event Planning and Service">{{translate.sections.chat.eventPlanning}}</option>
                    <option value="Finance and Banking">{{translate.sections.chat.finance}}</option>
                    <option value="Food and Grocery">{{translate.sections.chat.foodGrocery}}</option>
                    <option value="Public Service">{{translate.sections.chat.publicServices}}</option>
                    <option value="Hotel and Lodging">{{translate.sections.chat.hotel}}</option>
                    <option value="Medical and Health">{{translate.sections.chat.medical}}</option>
                    <option value="Non-profit">{{translate.sections.chat.nonProfit}}</option>
                    <option value="Professional Services">{{translate.sections.chat.professionalServices}}</option>
                    <option value="Shopping and Retail">{{translate.sections.chat.shopping}}</option>
                    <option value="Travel and Transportation">{{translate.sections.chat.travel}}</option>
                    <option value="Restaurant">{{translate.sections.chat.restaurant}}</option>
                    <option value="Other">{{translate.sections.chat.other}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.site}} 1</label>
                <input [(ngModel)]="whatsapp.site1" type="text" class="inputInspectorStyle" maxlength="256">
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.site}} 2</label>
                <input [(ngModel)]="whatsapp.site2" type="text" class="inputInspectorStyle" maxlength="256">
            </div>
            <div class="form-group">
                <button type="button" class="btn-primary btn-accept" (click)="updateInfo()">
                    <span>{{translate.sections.chat.updateInfo}}</span>
                </button>
            </div>
        </div>
    </div>

    <div class="container-fluid config clearfix cloud" *ngIf="nameChannel==='WHATSAPP' && nameChannelSub === 'CLOUD'">
        <div class="info">
            <h2>{{translate.sections.chat.companyInfo}}</h2>
            <div class="form-group">
                <label>{{translate.sections.chat.about}}</label>
                <input [(ngModel)]="whatsapp.text" type="text"  class="inputInspectorStyle" maxlength="139">
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.description}}</label>
                <input [(ngModel)]="whatsapp.description" type="text" class="inputInspectorStyle" maxlength="256">
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.address}}</label>
                <input [(ngModel)]="whatsapp.address" type="text" class="inputInspectorStyle" maxlength="256">
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.email}}</label>
                <input [(ngModel)]="whatsapp.email" type="text" class="inputInspectorStyle" maxlength="128">
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.industry}}</label>
                <select [(ngModel)]="whatsapp.vertical" type="text" class="inputInspectorStyle">
                    <option value="" disabled *ngIf="whatsapp.vertical === ''">{{translate.sections.chat.UNDEFINED}}</option>
                    <option value="UNDEFINED" disabled *ngIf="whatsapp.vertical === 'UNDEFINED'">{{translate.sections.chat.UNDEFINED}}</option>
                    <option value="AUTO">{{translate.sections.chat.AUTO}}</option>
                    <option value="BEAUTY">{{translate.sections.chat.BEAUTY}}</option>
                    <option value="APPAREL">{{translate.sections.chat.APPAREL}}</option>
                    <option value="EDU">{{translate.sections.chat.EDU}}</option>
                    <option value="ENTERTAIN">{{translate.sections.chat.ENTERTAIN}}</option>
                    <option value="EVENT_PLAN">{{translate.sections.chat.EVENT_PLAN}}</option>
                    <option value="FINANCE">{{translate.sections.chat.FINANCE}}</option>
                    <option value="GROCERY">{{translate.sections.chat.GROCERY}}</option>
                    <option value="GOVT">{{translate.sections.chat.GOVT}}</option>
                    <option value="HOTEL">{{translate.sections.chat.HOTEL}}</option>
                    <option value="HEALTH">{{translate.sections.chat.HEALTH}}</option>
                    <option value="NONPROFIT">{{translate.sections.chat.NONPROFIT}}</option>
                    <option value="PROF_SERVICES">{{translate.sections.chat.PROF_SERVICES}}</option>
                    <option value="RETAIL">{{translate.sections.chat.RETAIL}}</option>
                    <option value="TRAVEL">{{translate.sections.chat.TRAVEL}}</option>
                    <option value="RESTAURANT">{{translate.sections.chat.RESTAURANT}}</option>
                    <option value="NOT_A_BIZ">{{translate.sections.chat.NOT_A_BIZ}}</option>
                    <option value="OTHER">{{translate.sections.chat.OTHER2}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.site}} 1</label>
                <input [(ngModel)]="whatsapp.site1" type="text" class="inputInspectorStyle" maxlength="256">
            </div>
            <div class="form-group">
                <label>{{translate.sections.chat.site}} 2</label>
                <input [(ngModel)]="whatsapp.site2" type="text" class="inputInspectorStyle" maxlength="256">
            </div>
            <div class="form-group">
                <button type="button" class="btn-primary btn-accept" (click)="updateWhatsappCloudBusinessProfile()">
                    <span>{{translate.sections.chat.updateInfo}}</span>
                </button>
            </div>
        </div>
        <div class="account">
            <div class="profile-picture">
                <h2>{{translate.sections.chat.accountPhoto}}</h2>
                <div class="form-group">
                    <label>{{translate.sections.chat.selectImage}}</label>
                    <input [(ngModel)]="whatsapp.url" type="file" accept="image/png, image/jpeg" (change)="handleFilesCloud($event)" class="inputInspectorStyle file">
                </div>
                <div class="form-group whatsapp-picture" *ngIf="whatsapp.picture">
                    <img [src]="whatsapp.picture" alt="avatar">
                </div>
                <div class="form-group whatsapp-picture" *ngIf="!whatsapp.picture">
                    <img [src]="whatsapp.url" alt="avatar">
                </div>
                <div class="form-group">
                    <button type="button" class="btn-primary btn-accept" (click)="uploadProfilePictureMeta()">
                        <span>{{translate.sections.chat.updatePhoto}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>


</section>
