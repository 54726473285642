import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BillingService {

    constructor(private http: HttpClient) { }

    getTotalConversations(tz: string, slug: string, month: string, year: string) {
        // ¿cuántos usuarios han conversado con el bot?
        const url = `/coreapi/v2/billing/totalConversations`;
        const queryParams = {
            tz,
            month,
            year
        };
        const params = new HttpParams({
            fromObject: queryParams
        });
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers, params });
    }

    getTotalConversationsCurrentMonth(tz: string, slug: string) {
        // ¿cuántos usuarios han conversado con el bot?
        const url = `/coreapi/v2/billing/totalConversationsCurrentMonth`;
        const queryParams = {
            tz
        };
        const params = new HttpParams({
            fromObject: queryParams
        });
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers, params });
    }

    getActiveUsers(tz: string, slug: string, month: string, year: string) {
        // ¿cuántos usuarios activos tiene el bot?
        const url = `/coreapi/v2/billing/totalActiveUsers`;
        const queryParams = {
            tz,
            month,
            year
        };
        const params = new HttpParams({
            fromObject: queryParams
        });
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers, params });
    }

    getWabaInfo(slug: string) {
        const url = `/coreapi/v2/billing/wabaInfo`;
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers });
    }

    getWabaConversationAnalytics(dateStart:number, dateEnd: number, slug: string) {
        // Dates with epoch format
        const url = `/coreapi/v2/billing/wabaConversationAnalytics`;
        const queryParams = {
            date_start: dateStart.toString(),
            date_end: dateEnd.toString()
        };
        const params = new HttpParams({
            fromObject: queryParams
        });
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers, params });
    }

    getWabaConversationAnalyticsCurrentMonth(wabaId: string, token: string, phone: string, slug: string) {
        const url = `/coreapi/v2/billing/wabaConversationAnalyticsCurrentMonth/${wabaId}`;
        const queryParams = {
            token,
            phone
        };
        const params = new HttpParams({
            fromObject: queryParams
        });
        const headers = new HttpHeaders({
            Slug: slug
        });
        return this.http.get(url, { headers, params });
    }

}
