import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FunctionalityService {

    constructor(private http: HttpClient) { }

    getCatalogByPlatformId(platformCatalogId, slug: string) {
        const lang = localStorage.getItem('lang');
        const url = `/coreapi/v2/platformCatalogs/${platformCatalogId}/functionalityInventory?outputType=constructorMinimalMap&languageCode=${lang}`;

        const headers = new HttpHeaders({
            Slug: slug
        });

        return this.http.get(url, { headers });
    }

    getTemplateFunctionality(name: string, type: string, slug: string) {
        const url = `/coreapi/v2/functionalityTemplate/${type}?name=${name}`;

        const headers = new HttpHeaders({
            Slug: slug
        });

        return this.http.get(url, { headers });
    }

}
