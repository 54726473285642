<div class="preview-message">
    <div class="header-whats">
        <div class="top"></div>
        <div class="bottom"></div>
    </div>
    <div class="content">
        <div class="date">Today</div>
        <div class="message">

            <div class="header" *ngIf="dataMessage?.headerText || dataMessage?.mediaContent">
                <div class="text" *ngIf="dataMessage?.mediaType === 'TEXT'">
                    <strong *ngIf="dataMessage?.headerText">
                        {{dataMessage?.headerText}}
                    </strong>
                    <!-- <strong *ngIf="isEmpty(dataMessage?.headerText)">
                        Encabezado: un saludo o frase corta que resume el contenido del mensaje
                    </strong> -->
                </div>
                <div class="image" *ngIf="dataMessage.mediaType === 'IMAGE' && dataMessage.mediaContent && dataMessage?.mediaContent !== ''">
                    <img [src]="dataMessage?.mediaContent" alt="Imagen">
                </div>
                <div class="video" *ngIf="dataMessage.mediaType === 'VIDEO' && dataMessage.mediaContent && dataMessage?.mediaContent !== ''">
                    <video controls [src]="dataMessage?.mediaContent"></video>
                </div>
                <div class="document" *ngIf="dataMessage?.mediaType === 'DOCUMENT'">
                    <div [ngClass]="['file-icon', getFileType(dataMessage?.mediaContent)]"></div>
                    <span class="ellipsis">{{getFileName(dataMessage?.mediaContent)}}</span>
                </div>
            </div>

            <div class="body" *ngIf="dataMessage?.messageContent">
                <p [innerHTML]="getMessage()"></p>
            </div>
            <!-- <div class="body" *ngIf="isEmpty(dataMessage?.messageContent)">
                <p>Cuerpo del mensaje:<br>Así es como se vería tu <strong>plantilla de mensaje</strong>.</p>
            </div> -->

            <div class="footer" *ngIf="dataMessage?.footerText">
                <p>{{dataMessage?.footerText}}</p>
            </div>

            <!-- <div class="footer" *ngIf="isEmpty(dataMessage?.footerText)">
                <p>Pie de página: agrega información adicional de interés para tu usuario</p>
            </div> -->


            <div class="hour clearfix">
                <span>14:25</span>
            </div>
        </div>
        <div class="buttons" *ngIf="getButtonsLength() > 0">
            <div [ngClass]="[dataMessage?.buttonSetType, 'btn' + getButtonsLength()]">
                <button *ngIf="dataMessage?.button1" [innerHTML]="getLabelButton1()"></button>
                <button *ngIf="dataMessage?.button2" [innerHTML]="dataMessage?.button2"></button>
                <button *ngIf="dataMessage?.button3" [innerHTML]="dataMessage?.button3"></button>
            </div>
        </div>
    </div>
</div>
