import { Component, Input, OnInit } from "@angular/core";
import { ModalService } from "src/components/modal";
import { AlertService } from "src/services/alert.service";
// import { LoaderService } from "src/services/loader.service";
import { WabaService } from 'src/services/waba.service';
import { codes } from 'country-calling-code';
import { environment } from "src/environments/environment";
declare var translate: any;
@Component({
    selector: "app-phones",
    templateUrl: "./phones.component.html",
    styleUrls: ["./phones.component.scss"],
})
export class PhonesComponent implements OnInit {

    _wabaId: string;
    translate: any;

    @Input()
    set wabaId(wabaId: string) {
        this.translate = translate;
        setTimeout(() => { this.translate = translate; }, 1000);
        this._wabaId = (wabaId && wabaId.trim()) || null;

        if(this._wabaId){
            this.wabaService.setWhatsappBusinessAccountId(this.wabaId);
            this.getPhones();
        }
    }
    get wabaId() {
        return this._wabaId;
    };
    // @Input() currentBusinessId: string;

    phones: any;
    phonesPage = 0;
    phonesTotal = 0;
    phonesPerPage = 10;

    regionNames: any;
    currentPhone: any;

    registerCertMethod: string = 'SMS';
    twoFactorsActive: boolean = false;
    twoFactorsPin: string;

    pin: string;

    constructor(
        private alertService: AlertService,
        // private loaderService: LoaderService,
        private modalService: ModalService,
        private wabaService: WabaService,
    ) {}

    ngOnInit() {}

    getPhones() {
        const fields = [
            "display_phone_number",
            "certificate",
            "name_status",
            "new_certificate",
            "new_name_status",
            "account_mode",
            "quality_rating",
            "quality_score",
            "verified_name",
            "code_verification_status",
            "country_code",
            "status",
        ];
        this.wabaService.getPhonesStatusAndCertificate(fields).subscribe(
            (data) => {
                this.phones = data.data;
                let phonesStr = '';
                this.phones.forEach((phone, index) => {
                    // phonesStr += (index === 0 ? '' : ',') + this.cleanePhone(phone.display_phone_number);
                    phonesStr += (index === 0 ? '' : ',') + phone.id;
                });
                this.getCoreDataPhone(phonesStr);
            },
            (error) => {
                console.error(error);
                this.alertService.error("No se ha podido obtener los números");
            }
        );
    }

    getCoreDataPhone(phones) {
        console.log(phones);
        const newWhatsappChannelStr = localStorage.getItem('newWhatsappChannel');
        console.log(newWhatsappChannelStr);
        if (newWhatsappChannelStr) {
            const newWhatsappChannel = JSON.parse(newWhatsappChannelStr);
            console.log(newWhatsappChannel);
            this.wabaService.getCoreDataPhone(phones, newWhatsappChannel.customerSlug).subscribe(
                (data: any) => {
                    // this.phones = data.data;
                    for (let i = 0; i < this.phones.length; i++) {
                        for (let j = 0; j < data.length; j++) {
                            if ( this.phones[i].id === data[j].channelOwnerId) {
                                this.phones[i] = {...this.phones[i], ...data[j]};
                            }
                        }

                    }
                },
                (error) => {
                    console.error(error);
                    this.alertService.error("No se ha podido obtener los datos de registro de los números");
                }
            );
        }
        console.log(this.phones);
    }

    phonesPageUpdate(page) {
        this.phonesPage = page;
        this.getPhones();
        // TODO: manage pagination for fb
    }

    getCountry(countryCode: string) {
        if (countryCode) {
            return codes.find(code => code.isoCode2 === countryCode).country;
        }
        return '';
    }

    getFlagEmoji(countryCode) {
        if (countryCode) {
            return countryCode.toUpperCase().replace(/./g, char =>
                String.fromCodePoint(127397 + char.charCodeAt())
            );
        }
        return '';
    }

    registerPhone() {
        // if (this.currentPhone.certificate){
        //     const country = this.getCountry(this.currentPhone.country_code);
        //     const phone_number = this.getPhoneWithoutCountryCode(this.currentPhone.display_phone_number, country.countryCodes[0]);

        //     const registerCertData = {
        //         cc: country.countryCodes[0],
        //         phone_number,
        //         method: this.registerCertMethod,
        //         cert: this.currentPhone.certificate
        //     };
        //     // TODO: llamar el servicio de rueben
        //     if (this.twoFactorsPin) {
        //         registerCertData['pin'] = this.twoFactorsPin;
        //     }

        //     console.log(registerCertData);
        // } else {
        //     this.alertService.error('Tu teléfono aún no cuenta con un certificado, por favor intenta después')
        // }

        if (!this.pin || this.pin.length !== 6 || !/[0-9]{6}/.test(this.pin)) {
            this.alertService.error('El pin debe tener 6 números');
        } else {
            const newWhatsappChannel: any = JSON.parse(localStorage.getItem('newWhatsappChannel'));

            const payload = {
                "phone_number_id": this.currentPhone.id,
                "phone_number": this.cleanePhone(this.currentPhone.display_phone_number),
                "token": localStorage.getItem('token'),
                "waba_id": this.wabaId,
                "fb_app_name": environment.facebook.internalAppName,
                "pin": this.pin
            }

            this.wabaService.registerPhone(payload, newWhatsappChannel.customerSlug).subscribe(
                data => {
                    console.log(data);
                    this.closeModal('popup-confirm-certificate');
                    const channelOwnerId = this.currentPhone.channelOwnerId;
                    const slug = newWhatsappChannel.customerSlug;
                    const fbAppName = environment.facebook.internalAppName;
                    this.wabaService.setCreditLineExtension(slug, channelOwnerId, fbAppName).subscribe(
                        data => {
                            this.alertService.success('Se ha registrado el número');
                        }, error => {
                            console.error(error);
                            this.alertService.error('No se ha podido establecer la extensión de crédito');
                        }
                    );

                }, error => {
                    console.error(error);
                    this.alertService.error('No se ha podido registrar el número');
                }
            );
        }

    }

    getPhoneWithoutCountryCode(phone: string, countryCode: string) {
        const ccRegex = new RegExp(`^${countryCode}`, 'g');
        phone  = phone.replaceAll('+', '').replaceAll(' ', '').replace(ccRegex, '');
        return phone;
    }

    cleanePhone(phone: string) {
        phone  = phone.replaceAll('+', '').replaceAll(' ', '');
        return phone;
    }

    verifyRegistrationCode(code) {
        // TODO: lanzar modal con un input para el código, llamar el servicio de rueben
        // this.wabaService.verifyRegistrationCode(code).subscribe(
        //     data => {
        //         console.log(data);
        //     }, error => {

        //     }
        // );
    }

    getIconQualityRating(status: string) {
        if (!status) {
            status = 'GREY';
        }
        const icons = {
            UNKNOWN: {
                icon: 'error_outline',
                name: 'No disponible',
                key: 'unknown'
            },
            NA: {
                icon: 'pending_actions',
                name: 'pendiente',
                key: 'na'
            },
            GREEN: {
                icon: 'thumb_up_alt',
                name: 'Alta',
                key: 'green'
            },
            RED: {
                icon: 'thumb_down_alt',
                name: 'Media',
                key: 'red'
            },
            YELLOW: {
                icon: 'thumb_down_alt',
                name: 'Baja',
                key: 'yellow'
            },
            GREY: {
                icon: 'thumb_down_alt',
                name: 'No disponible',
                key: 'grey'
            }
        };
        status = status.toString().toUpperCase();
        return icons[status];
    }

    getIconQualityScore(status: string) {
        if (!status) {
            status = 'GREY';
        }
        const icons = {
            UNKNOWN: {
                icon: 'error_outline',
                name: 'No disponible',
                key: 'unknown'
            },
            NA: {
                icon: 'pending_actions',
                name: 'pendiente',
                key: 'na'
            },
            GREEN: {
                icon: 'thumb_up_alt',
                name: 'Alta',
                key: 'green'
            },
            RED: {
                icon: 'thumb_down_alt',
                name: 'Media',
                key: 'red'
            },
            YELLOW: {
                icon: 'thumb_down_alt',
                name: 'Baja',
                key: 'yellow'
            },
            GREY: {
                icon: 'thumb_down_alt',
                name: 'No disponible',
                key: 'grey'
            }
        };
        status = status.toString().toUpperCase();
        return icons[status];
    }

    getIconByStatusNamePhone(status: string) {
        if (!status) {
            status = 'NONE';
        }
        const icons = {
            AVAILABLE_WITHOUT_REVIEW: {
                icon: 'pending_actions',
                name: 'Disponible sin revisión',
                key: 'pending'
            },
            PENDING_REVIEW: {
                icon: 'pending_actions',
                name: 'Pendiente',
                key: 'pending'
            },
            APPROVED: {
                icon: 'thumb_up_alt',
                name: 'Aprobado',
                key: 'approved'
            },
            DECLINED: {
                icon: 'thumb_down_alt',
                name: 'Rechazado',
                key: 'declined'
            },
            EXPIRED: {
                icon: 'thumb_down_alt',
                name: 'Expirado',
                key: 'expired'
            },
            NONE: {
                icon: 'thumb_down_alt',
                name: 'No disponible',
                key: 'none'
            }
        };
        status = status.toString().toUpperCase();
        return icons[status];
    }

    getIconByStatusPhone(status: string) {
        if (!status) {
            status = 'NONE';
        }
        const icons = {
            DISCONNECTED: {
                icon: 'phone_disabled',
                name: 'Desconectado',
                key: 'disconnected'
            },
            CONNECTED: {
                icon: 'phone_enabled',
                name: 'Conectado',
                key: 'connected'
            },
            PENDING: {
                icon: 'pending',
                name: 'Pendiente',
                key: 'pending'
            },
            NONE: {
                icon: 'thumb_down_alt',
                name: 'No disponible',
                key: 'none'
            }
        };
        status = status.toString().toUpperCase();
        return icons[status];
    }

    closeModal(id) {
        this.modalService.close(id);
    }

    openModal(id: string, phone: any = null) {
        this.modalService.open(id);
        if (phone) {
            this.currentPhone = phone;
        }
    }
}
